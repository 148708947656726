<template>
 
  <div class="galpan">

    <WeekwideViewWeek
      v-for="(week, index) in props.diary.items_week"    
      :week="week"
      :diary="props.diary"
      :week-active="props.weekActive"
      :problems="props.diary.items_problem?.filter((p: Question) => p.item_week?.id == week.id)"
      :comments="props.comments"
      @remove-week="handleRemoveWeek"
      :ref="(childExpose) => { 
        if (childExpose) setWeekRef(childExpose.rootEl, index) 
      }"
      />

  </div>
 
  
</template>

<script setup lang="ts">

import type { Diary, Week } from '@/types/diary'
import type { Question } from '@/types/question'
import { useIntersectionObserver, watchArray, type Stoppable } from '@vueuse/core'

const emits = defineEmits(['interactWeek', 'removeWeek'])

interface PageProps {
  diary: Diary,
  weekActive: Week | null,
  comments: any,
  isModal: boolean
}
const props = defineProps<PageProps>()

const weekRefs = ref<Element[]>([])
const observers: Stoppable[] = []
const isObserverDisabled = ref(false);
const isScrolledViewWeek = ref(false);
 

const setWeekRef = (el: HTMLElement, index: number) => {
  weekRefs.value[index] = el
}

const handleRemoveWeek = (week: Week) => {
  emits('removeWeek', week)
}


onMounted(() => {
  weekRefs.value.forEach((weekEl, index) => {
    const stopObserver = useIntersectionObserver(
      () => weekRefs.value[index] as HTMLElement,
      ([{ isIntersecting }]) => {
        if (isObserverDisabled.value) return;
        if (isIntersecting) {
          emits('interactWeek', props.diary.items_week[index])
          isScrolledViewWeek.value = true;
          setTimeout(() => isScrolledViewWeek.value = false, 1000);
        }
      },
      { 
        root: null,
        rootMargin: '0px 0px -50% 0px', 
        threshold: 0.3
      }
    )
    observers.push(stopObserver) 
  })
})

onBeforeUnmount(() => {
  observers.forEach(observer => observer.stop())
})

 
watchArray([props], ([new_props]) => {

  isObserverDisabled.value = true; 

  props.diary.items_week.forEach((w,i) => {
    
    if(isScrolledViewWeek.value) return;

    if(new_props?.weekActive?.id && new_props.weekActive.id == w.id){

      if(props.isModal){
        let scrollableContainer = useModalsDiaryModalRef().value;

        scrollableContainer.scrollTo({
          top: weekRefs.value[i].offsetTop + 160, 
          behavior: 'smooth'
        });
      }else{
        const elementRect = weekRefs.value[i].getBoundingClientRect();
        const elementTop = elementRect.top + window.pageYOffset;
        window.scrollTo({
          top: elementTop - 50, 
          behavior: 'smooth'
        });
      }
 
    }
  });

  setTimeout(() => {
    isObserverDisabled.value = false; 
  }, 20);

}, {deep: true})

</script>

<style scoped>
 
.galpan{

  display: flex;
  flex-direction: column;
  gap: 3rem;
  position: relative;
  width: 100%;  
}

  
 

 

</style>

<template>
 

    <div :class="{'comment': true, 'preloaded': props.data?.preloaded,  'gloading': is_loading}">
      
      <NuxtLink class="avatar" :to="props.data.item_user.link">
        <img loading="lazy" class="img" :src="props.data.item_user.avatar_little" :alt="props.data.item_user.name">
        <div class="user_range u_range_1"></div>
        <div class="round"></div>
      </NuxtLink>

      <div class="content">
 

        <template v-if="!edit_form">

          <div class="mt">
            <NuxtLink class="author" :to="props.data.item_user.link">{{ props.data.item_user.name }}</NuxtLink>


            <span class="sub">{{ props.data.is_pin ? $t('comments_view_pinned') : $t('comments_view_commented') }}</span>



            <NuxtLink 
              v-if="props.data.diary_week && !props.parentId" 
              :to="'/diaries/' + props.data.diary_link + '/week/' + props.data.post_id" 
              @click.native="clickWeek"
              :class="'label_week faza_' + props.data.diary_faza"
              >{{ $t('comments_view_week_badge') }} {{ props.data.diary_week }}</NuxtLink>      


            <span class="sub">{{  $dayjs.utc(props.data.add_date).fromNow() }}</span>


          </div>

          <div class="text" ref="refCommentText" v-html="formatCommonText(props.data.text)"></div>        

          <div class="actions" v-if="props.showActions">

            <template v-if="useAuth().isOwner(props.data.item_user.id)">
              <div :class="{'like': true, 'active': isLikedEdit, 'gloading': likeLoading}">
                <i class="icon-leaf"></i>
                {{ $plurals.get('like', props.data.cnt_likes) }} 
                {{ props.data.cnt_likes ? props.data.cnt_likes : '' }}
              </div>
              <div @click="edit"><i class="icon-pencil"></i> {{ $t('comments_view_edit') }}</div>
              <div @click="remove"><i class="icon-remove"></i> {{ $t('comments_view_remove') }}</div>
              <GeneralTranslateButton
                v-if="props.data.text && refCommentText"
                :el="refCommentText"
                :text="props.data.text"
                :id="props.data.id"
                :type="'comment'"
                :locale="$i18n.locale"
                />
            </template>
  
            <template v-if="!useAuth().isOwner(props.data.item_user.id)">            
              <div :class="{'like': true, 'active': isLikedEdit, 'gloading': likeLoading}" @click="like(data)">
                <i class="icon-leaf"></i> 
                {{ $plurals.get('like', props.data.cnt_likes) }} 
                {{props.data.cnt_likes ? props.data.cnt_likes : ''}}
              </div>
              <div v-if="useAuth().isAuth()" @click="reply"><i class="icon-reply"></i> {{ $t('comments_view_reply') }}</div>
              <div v-if="useAuth().isAuth()" @click="complain(props.data.id)"><i class="icon-complain"></i> {{ $t('comments_view_complain') }}</div> 
              <GeneralTranslateButton
                v-if="props.data.text && refCommentText"
                :el="refCommentText"
                :text="props.data.text"
                :id="props.data.id"
                :type="'comment'"
                :locale="$i18n.locale"
                @click="$ga.eventGA('comment_translate')"
                />
            </template> 

            <div v-if="useAuth().isAuth() && useAuth().getAccess('pin_comment')" @click="pin()"><i class="icon-pin"></i> {{ props.data.is_pin ? 'unPin' : 'Pin' }}</div> 
            
          </div>
        </template>



        <CommentForm
          v-if="useAuth().isAuth() && edit_form"
          :content="props.data.content_id"
          :edited-id="props.data.id"
          :reply-id="props.data.id"
          :type="props.data.s_type"
          :text="props.data.text"
          @updated="props.data.text = $event"
          @closed="edit_form=false"
          />  
         
      </div>


    
     

  </div>
     

  <div :class="{'replies': true, 'no-margin': props.parentId}">

    <CommentForm
      v-if="useAuth().isAuth() && reply_form"
      :content="props.data.content_id"
      :type="props.data.s_type"
      :reply-id="props.data.id"
      :reply-to-user-name="props.data.item_user.name"
      :parent-id="props.parentId ?? props.data.id"
      @closed="reply_form=false"
      @created="replies.unshift($event); reply_form=false; "
      />  

    <template
      v-for="(opt, opt_key) in replies"      
      >
      <CommentItem 
        :data="opt"
        :type="props.type"
        :reply-id="props.data.reply_id"
        :reply-to-user-name="props.data.item_user.name"
        :parent-id="props.data.id"
        :likes="likes"
        :is-liked="likes?.includes(opt.id)"
        :content="props.content"
        @createdcomment="replies.unshift($event)"
        @remove="removeReply"
        />              
    </template>



      <div 
        v-if="props.data.cnt_childs > replies.length && !isLoadedChilds"
        @click="loadMore"
        :class="{'loadreplies': true, 'gloading': repliesLoading}"
        >
        Load more ({{ props.data.cnt_childs - replies.length }})
      </div>


  </div>

</template>


<script setup lang="ts">

import { formatCommonText } from '@/types/other';


const { t } = useI18n();
const { $api, $popup, $likes, $complains, $ga } = useNuxtApp();

const props = defineProps({
  data: {
    type: Object
  },
  type: {
    type: String
  },
  content: {
    type: Number
  },
  replyId: {
    type: Number
  }, 
  parentId: {
    type: Number
  }, 
  likes: {
    type: Array,
    default: () => []
  },
  isLiked: {
    type: Boolean,
    default: false
  },
  replyToUserName: {
    type: String,
    default: ''
  },
  sort: {
    type: String,
    default: 'desc'
  },
  showActions: {
    type: Boolean,
    default: true
  }
})

const emits = defineEmits(['createdcomment', 'remove', 'removereply']);

const replies = ref(props.data.replies?.items_comment ? props.data.replies?.items_comment : []);
// const replies = computed(() => {
//   let arr = [];
//   if(repliesList?.length)
//     arr = repliesList;      
//   return arr;
// })

const isLoadedChilds = ref(false);
const likeLoading = ref(false);
const repliesLoading = ref(false);
const isLikedEdit = ref(props.isLiked);
const replyId = ref(props.data.id);
const reply_form = ref(false);
const edit_form = ref(false);
const is_loading = ref(false);
const refCommentText = ref(null);

const complain = (id) => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();

    // Lang.get('chat_complain_confirm'),Lang.get('chat_complain_confirm_text')

  if(!confirm(t('chat_complain_confirm_text')))
    return;
  
  is_loading.value = true;
  $complains.add('comment', id)
  .then((res) => {       
    is_loading.value = false;
    $popup.success('Complained');
    $ga.eventGA('comment_complain');
  })
  .catch((res) => {       
    is_loading.value = false;
    $popup.error('Error');
  });
}

const clickWeek = () => {
  if(import.meta.client){        
    window.scrollTo(0,0);
  }
} 

const loadMore = (data) => {
  
  repliesLoading.value = true;
  new Promise(async (resolve, reject) => {
      
    const response = await $api.getCommentsReplies(
      props.content, 
      props.type,
      props.data.id, 
      replies.value.length, 
      400,
      props.sort
    )  
    return resolve(response);  
  }).then((res) => {
    replies.value = [...replies.value, ...res.items_comment];
    isLoadedChilds.value = true;
    repliesLoading.value = false;
  }).catch((res) => {
    console.log(res);
    repliesLoading.value = false;
  })
}

const like = (data) => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  likeLoading.value = true;

  $likes.toggle('comment', data.id, isLikedEdit.value)
  .then((res) => {   
    likeLoading.value = false;
    isLikedEdit.value = !isLikedEdit.value;
    data.cnt_likes += (isLikedEdit.value ? 1 :-1);
    data.cnt_likes = data.cnt_likes < 0 ? 0 : data.cnt_likes;
    if(isLikedEdit.value)
      $ga.eventGA('comment_like');
  })
  .catch((res) => {       
    likeLoading.value = false;
    $popup.error('Error');
  });
}

const removeReply = (id) => {
  emits('removereply', props.data.id, id);
}

const remove = () => {


  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  is_loading.value = true;
  $api.deleteComment(props.data.id, props.type, props.content)
  .then((res) => {       
    is_loading.value = false;
    $popup.success('Removed');
    emits('remove', props.data.id);
  })
  .catch((res) => {       
    is_loading.value = false;
    $popup.error('Error');
  });
  emits('remove');
}

const pin = (s_type,content_id,id) => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  is_loading.value = true;
  $api.pathComment(props.data.id, props.data.s_type, props.data.content_id, {
    pin: true
  })
  .then((res) => {       
    is_loading.value = false;
    $popup.success('Pined');
    props.data.is_pin = props.data.is_pin ? 0 : 1;
  })
  .catch((res) => {       
    is_loading.value = false;
    $popup.error('Error');
  });

}

const reply = () => {
  reply_form.value = !reply_form.value;
}

const edit = () => {
  edit_form.value = !edit_form.value;
}


watch(() => props.isLiked, (val) => {
  isLikedEdit.value = val;
})

watch(() => props.data, (val) => {
  props.data = val;
})

onUnmounted(() => {
  isLikedEdit.value = false;
})

</script>
   

<style scoped>
   
.comment{
  display: flex;
  align-items: flex-start;
  width: 100%;
  content-visibility: auto;
  margin-bottom: 1rem;
  contain: paint;
}

.avatar{    
  position: relative;
  flex-shrink: 0;
  width: 40px;
  margin-right: 1rem;
}

.avatar img{
  border-radius: 100rem;
  width: 100%;
}

.user_range {
  float: left;
  font-size: 1.3rem;
  margin-right: 15px;
  position: absolute; 
  bottom: -3px;
  right: -19px;
  display: none;
}

.content{
  width: 100%;
}
.content .mt{
  display: flex;
  gap: 0.4rem;
}
.content .author{
  font-weight: bold;
}
.content .sub{

  color: var(--gd-comment-text-gray-color);
} 
.content .date{
  display: inline-block;
}
.content .label_week{
  color: #F2711C;
  border: 1px #F2711C solid;
  border-radius: 5px;
  font-size: 0.7rem;
  padding: 2px 6px;
  margin-right: 5px;
  display: inline-block;
}
.content .label_week.faza_-1{
  color: var(--gd-faza-ger);
  border: 1px var(--gd-faza-ger) solid;
}
.content .label_week.faza_0{
  color: var(--gd-faza-veg);
  border: 1px var(--gd-faza-veg) solid;
}
.content .label_week.faza_1{
  color: var(--gd-faza-flo);
  border: 1px var(--gd-faza-flo) solid;
}
.content .label_week.faza_2{
  color: var(--gd-faza-har);
  border: 1px var(--gd-faza-har) solid;
}
.content .text{
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
    white-space: pre-wrap;
}
.content .actions{
  display: flex;
  gap: 5px 10px;
  flex-wrap: wrap;
}
.content .actions > *{
  cursor: pointer;
  display: flex;
  color: var(--gd-button-light-small-color);
  font-size: 0.75rem;
  text-transform: capitalize;
  position: relative;
  align-items: center;
  gap: 5px;
}
.content .actions .active{
  color: var(--gd-button-small-active-color);
}
  
.is_link_load {
  opacity: 0.5;
  padding: 5px;
  background-color: white;
  border-radius: 3px;
  display: table;
  margin-bottom: 11px;
  margin-left: 42px;
}
.preloaded {
  background-color: rgba(116, 173, 71, 0.23);
  padding: 10px!important;
  border-radius: 3px;
}


.replies{
  margin-top: 1rem;
  margin-left: 3.4rem;
}

.replies.no-margin{
  margin-left: 0;
}

.replies .comment:last-child{
  padding-bottom: 12px;
}

.like.liked{
  color: #96BE2A!important;
}
.like.disabled{
  opacity: 0.5;
  pointer-events: none;
}

.removed_user{
  text-decoration: line-through;
  opacity: 0.5;
}

.loadreplies{
  position: relative;
  font-weight: bold;
  margin-bottom: 1rem;
  cursor: pointer;
  widows: fit-content;
}


@media (max-width: 768px) {
   
}


</style>

<template>
 
<div class="tags">
    <div 
        class="tag"
        v-for="tag in selectedTags"
        @click="onSelect(tag.id)"
    >
        <i class="icon-remove"></i>
        <div class="tag__label">
            {{ tag.label }}
        </div>  
    </div>
</div>
  
</template>

<script setup>

import { ref, computed, onMounted, onBeforeUnmount } from 'vue'

const emits = defineEmits(['update:selected'])


const props = defineProps({
  filterCategories: {
    type: Array,
    required: true
  },
  selected: {
    type: Array,
    default: () => []
  }
})

const selectedTags = computed(() => {
    return props.filterCategories.map((cat) => cat.tags).flat().filter((tag) => props.selected.includes(tag.id))    
})
  

const isSelected = (tagId) => {
    return props.selected.includes(tagId)
}

const onSelect = (tagId) => {
    let newSelected = props.selected
    if (isSelected(tagId)) {
        newSelected = props.selected.filter(id => id !== tagId)
    } else {
        newSelected.push(tagId)
    }
    emits('update:selected', newSelected)
}

</script>

<style scoped>

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  position: relative;
}

.tag {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.4rem 0.8rem;
  border-radius: 7px;
  background-color: #f5f5f5;
  color: #333;
  /* font-size: 0.9rem; */
  cursor: pointer;

  background-color: var(--gd-stag-active-back-color);
color: var(--gd-stag-active-color);
}
.tag i{
    font-size: 0.7rem;
}
  

</style>
<template>
  

  <div class="feed_tags">


      <UiHorizontalScroll
        :align="'center'"
        :arrows="true"
        :arrows-show-hover="true"
        >
    
        <template
          v-for="(tag, tag_key) in list"
          >
          <div 
            @click="handleChoose(tag)" 
            :class="'feed_tag' + (tag.filter == selected ? ' active ' : '')" 
            >
            <span class="name">{{ tag.lng_key ? $t(tag.lng_key) : tag.name }}</span>
          </div>
        </template>

      </UiHorizontalScroll>

  </div>
  
</template>

<script setup lang="ts">

import type { FeedTag }  from "@/types/other"

interface Props {
  list: FeedTag[];
  selected: string;
}

const props = defineProps<Props>();

const emits = defineEmits(['choose'])


const handleChoose = (tag: FeedTag) => {
  emits('choose', tag);
}



</script>



<style scoped>

 
 
/* FEED TAGS */

.feed_tags {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  position: relative;
}



.feed_tags .arrow-left,
.feed_tags .arrow-right{
  position: absolute;
  font-size: 1.4rem;
  height: 27px;
  width: 50px;
  display: flex;
  align-items: center;
  color: gray;
  cursor:pointer;
}
.feed_tags .arrow-left i,
.feed_tags .arrow-right i{display: contents;}

.feed_tags .arrow-left{
  background: rgb(255,255,255);
  background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);
}
.feed_tags .arrow-right{
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);
  right: 0px;
  justify-content: flex-end;
}
.feed_tags .arrow-left:hover,
.feed_tags .arrow-right:hover{
  color: #67ad3c;
}
.feed_tags .list{
  display: flex;
  align-items: flex-start; 
  flex-wrap: nowrap;
  overflow-x: hidden;
}
.feed_tags.loading {
  pointer-events: none;
  opacity: 0.7;
}

.feed_tags .feed_tag {
  display: flex;
  border-radius: 30px;
  padding: 5px 12px 5px 12px;
  color: gray;
  /* margin-bottom: 5px; */
  margin-right: 5px;
  text-align: center;
  background-color: var(--gd-stag-back-color);
  cursor: pointer;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
  position: relative;
}

.feed_tags .feed_tag .avatar {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  margin-right: 4px;
}

.feed_tags .feed_tag .avatar.user {
  border-radius: 25px;
}

.feed_tags .feed_tag .name {}

.feed_tags .feed_tag.active {
  background-color: var(--un-background-color-second);
  color: var(--un-text-color-invert);
}

@media (max-width: 768px) {
  .feed_tags {
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  .feed_tags::-webkit-scrollbar {
    display: none;
  }
}

</style>

<template>
  <div class="pbf" v-if="props.user">    

     <BrandTabDiaries
      :grower-id="props.user.id"      
      :owner="useAuth().getId() === props.user.id"
      :inurl="'/users/' + props.id + '/diaries'"
      :intags="tags"
      />

  </div>
</template>



<script setup lang="ts">

import type { User } from '@/types/user'

const route = useRoute();
const { $api, $ga, $helper, $tagsUtil, $head } = useNuxtApp();
var tags = ref($tagsUtil.tagsGrowerDiaries);

interface Props {
  user: User;
  id: number;
}

const props = defineProps<Props>();

</script>







<style scoped>
 

</style>

import { ProductCategoryType } from '../product';

export enum WeekFaza {
  Germination = -1,
  Vegetation = 0,
  Flowering = 1,
  Harvest = 2,
}

export enum WeekFazaLetter {
  Germination = 'g',
  Vegetation = 'v',
  Flowering = 'f',
  Harvest = 'h',
}


export enum DiaryTypeMethod {
  Lst = 2,
  Hst = 3,
  Sog = 4,
  Scrog = 5,
  Topping = 6,
  Fiming = 7,
  MainLining = 8,
  Defoliation = 9,
  Manifolding = 10,
  TwelveTwelve = 11,
  Transplantation = 12,
}


export enum DiaryTypeMedium {
  Soil = 1,
  Perlite = 2,
  Vermiculite = 3,
  ExpandedClay = 4,
  CocoCoir = 5,
  MineralWool = 6,
  Other = 7,
}



export enum DiaryTypeRoom {
  Indoor = 1,
  Outdoor = 2,
  Greenhouse = 3
}

export enum DiaryTypeWatering {
  Manual = 1,
  Drip = 2,
  Hydroponic = 3,
  Aeroponic = 4
}


export const DiaryTypeRoomImage: Record<DiaryTypeRoom, string> = {
  [DiaryTypeRoom.Indoor]: '/images/setup_diary/setup_diary_icons-01.svg',
  [DiaryTypeRoom.Outdoor]: '/images/setup_diary/setup_diary_icons-02.svg',
  [DiaryTypeRoom.Greenhouse]: '/images/setup_diary/setup_diary_icons-19.svg'
};

 
export const DiaryTypeRoomInfo: Record<DiaryTypeRoom, { tr: string, img: string, desc: string }> = {
  [DiaryTypeRoom.Indoor]: {
    tr: 'universal_type_room_indoor',
    img: '/images/setup_diary/setup_diary_icons-01.svg',
    desc: 'Indoor – Suitable for interior spaces, providing a controlled and comfortable environment for various activities.',
  },
  [DiaryTypeRoom.Outdoor]: {
    tr: 'universal_type_room_outdoor',
    img: '/images/setup_diary/setup_diary_icons-02.svg',
    desc: 'Outdoor – Ideal for exterior areas, designed for open-air use and interactions with nature.',
  },
  [DiaryTypeRoom.Greenhouse]: {
    tr: 'universal_type_room_greenhouse',
    img: '/images/setup_diary/setup_diary_icons-19.svg',
    desc: 'Greenhouse – Perfect for cultivating plants in a protected, climate-controlled environment to optimize growth.',
  },
};

 

export const DiaryTypeWateringInfo: Record<DiaryTypeWatering, { tr: string, img: string, desc: string }> = {
  [DiaryTypeWatering.Manual]: {
    tr: 'universal_type_watering_manual',
    img: '/images/setup_diary/watering_manual.svg',
    desc: 'Manual Watering – Traditional method involving manually delivering water to plants, allowing precise control over moisture levels.',
  },
  [DiaryTypeWatering.Drip]: {
    tr: 'universal_type_watering_drip',
    img: '/images/setup_diary/watering_drip.svg',
    desc: 'Drip Watering – An irrigation system that delivers water directly to plant roots through a network of tubes and emitters, ensuring efficient and consistent moisture distribution.',
  },
  [DiaryTypeWatering.Hydroponic]: {
    tr: 'universal_type_watering_hydroponics',
    img: '/images/setup_diary/watering_hydroponics.svg',
    desc: 'Hydroponics – Soilless cultivation method where plants grow in a nutrient-rich water solution, enabling faster growth and higher yields.',
  },
  [DiaryTypeWatering.Aeroponic]: {
    tr: 'universal_type_watering_aeroponics',
    img: '/images/setup_diary/watering_aeroponics.svg',
    desc: 'Aeroponics – Advanced irrigation technique where plant roots are suspended in the air and misted with a nutrient solution, promoting rapid growth and efficient nutrient absorption.',
  },
};


export const DiaryProductCategoriesMinInfo = [
  ProductCategoryType.Lamp,
  ProductCategoryType.Tent,
  ProductCategoryType.GrowBox,
  ProductCategoryType.VentilationFan,
  ProductCategoryType.AirFilter,
  ProductCategoryType.AirConditioner,
  ProductCategoryType.DripSystem,
  ProductCategoryType.HydroponicSystem,
  ProductCategoryType.Controller,
  ProductCategoryType.CO2Generator,
]





export const TabsDiary = [ 
  {
    id: 'setup',
    name: 'Setup',
  }, 
  {
    id: 'nutrients',
    name: 'Nutrients',
  }, 
  {
    id: 'comments',
    name: 'Comments',
  },
]

export const TabsDiaryHar = [ 
  {
    id: 'setup',
    name: 'Setup',
  }, 
  {
    id: 'reviews',
    name: 'Reviews',
  },  
  {
    id: 'comments',
    name: 'Comments',
  },
]





export enum ReviewEffectPositiveEnum {
  Creative = 1,
  Energetic = 2,
  Euphoric = 3,
  Giggly = 4,
  Happy = 5,
  Hungry = 6,
  Relaxed = 7,
  Sleepy = 8,
  Talkative = 9,
  Uplifted = 10,
}


export const ReviewEffectPositiveEnumInfo: Record<ReviewEffectPositiveEnum, { tr: string, img: string, key: string }> = {
  [ReviewEffectPositiveEnum.Creative]:
  {
    tr: 'strain_view_tastes_effects_creative',
    img: '/images/effect/creative.jpg',
    key: 'creative',
  },
  [ReviewEffectPositiveEnum.Energetic]:
  {
    tr: 'strain_view_tastes_effects_energetic',
    img: '/images/effect/energetic.jpg',
    key: 'energetic',
  },
  [ReviewEffectPositiveEnum.Euphoric]:
  {
    tr: 'strain_view_tastes_effects_euphoric',
    img: '/images/effect/euphoric.jpg',
    key: 'euphoric',
  },
  [ReviewEffectPositiveEnum.Giggly]:
  {
    tr: 'strain_view_tastes_effects_giggly',
    img: '/images/effect/giggly.jpg',
    key: 'giggly',
  },
  [ReviewEffectPositiveEnum.Happy]:
  {
    tr: 'strain_view_tastes_effects_happy',
    img: '/images/effect/happy.jpg',
    key: 'happy',
  },
  [ReviewEffectPositiveEnum.Hungry]:
  {
    tr: 'strain_view_tastes_effects_hungry',
    img: '/images/effect/hungry.jpg',
    key: 'hungry',
  },
  [ReviewEffectPositiveEnum.Relaxed]:
  {
    tr: 'strain_view_tastes_effects_relaxed',
    img: '/images/effect/relaxed.jpg',
    key: 'relaxed',
  },
  [ReviewEffectPositiveEnum.Sleepy]:
  {
    tr: 'strain_view_tastes_effects_sleepy',
    img: '/images/effect/sleepy.jpg',
    key: 'sleepy',
  },
  [ReviewEffectPositiveEnum.Talkative]:
  {
    tr: 'strain_view_tastes_effects_talkative',
    img: '/images/effect/talkative.jpg',
    key: 'talkative',
  },
  [ReviewEffectPositiveEnum.Uplifted]:
  {
    tr: 'strain_view_tastes_effects_uplifted',
    img: '/images/effect/uplifted.jpg',
    key: 'uplifted',
  },
}



export enum ReviewEffectNegativeEnum {
  DryEyes = 11,
  DryMouth = 12,
  Insomnia = 13,
  Paranoia = 14,
}

export const ReviewEffectNegativeEnumInfo: Record<ReviewEffectNegativeEnum, { tr: string, img: string, key: string }> = {
  [ReviewEffectNegativeEnum.DryEyes]:
  {
    tr: 'strain_view_tastes_effects_dry_eyes',
    img: '/images/effect/dry_eyes.jpg',
    key: 'dry_eyes',
  },
  [ReviewEffectNegativeEnum.DryMouth]:
  {
    tr: 'strain_view_tastes_effects_dry_mouth',
    img: '/images/effect/dry_mouth.jpg',
    key: 'dry_mouth',
  },
  [ReviewEffectNegativeEnum.Insomnia]:
  {
    tr: 'strain_view_tastes_effects_insomnia',
    img: '/images/effect/insomnia.jpg',
    key: 'insomnia',
  },
  [ReviewEffectNegativeEnum.Paranoia]:
  {
    tr: 'strain_view_tastes_effects_paranoia',
    img: '/images/effect/paranoia.jpg',
    key: 'paranoia',
  },
}

export enum ReviewEffectMedicalEnum {
  Stress = 15,
  Pain = 16,
  Insomnia = 17,
  Anxiety = 18,
  Depression = 19,
}

export const ReviewEffectMedicalEnumInfo: Record<ReviewEffectMedicalEnum, { tr: string, img: string, key: string }> = {
  [ReviewEffectMedicalEnum.Stress]:
  {
    tr: 'strain_view_tastes_effects_stress',
    img: '/images/effect/medical/stress.jpg',
    key: 'stress',
  },
  [ReviewEffectMedicalEnum.Pain]:
  {
    tr: 'strain_view_tastes_effects_pain',
    img: '/images/effect/medical/pain.jpg',
    key: 'pain',
  },
  [ReviewEffectMedicalEnum.Insomnia]:
  {
    tr: 'strain_view_tastes_effects_insomnia',
    img: '/images/effect/medical/insomnia.jpg',
    key: 'insomnia',
  },
  [ReviewEffectMedicalEnum.Anxiety]:
  {
    tr: 'strain_view_tastes_effects_anxiety',
    img: '/images/effect/medical/anxiety.jpg',
    key: 'anxiety',
  },
  [ReviewEffectMedicalEnum.Depression]:
  {
    tr: 'strain_view_tastes_effects_depression',
    img: '/images/effect/medical/depression.jpg',
    key: 'depression',
  },
}

export enum ReviewTasteEnum {
  Berries = 1,
  Cheese = 2,
  Citrus = 3,
  Diesel = 4,
  Earthy = 5,
  Flowery = 6,
  Mint = 7,
  Nutty = 8,
  Sweet = 9,
  Tropical = 10,
  Caramel = 12,
  Chocolate = 13,
  Cream = 14,
  Fruity = 15,
  Herbs = 16,
  Pine = 17,
  Woody = 11,
  Pungent = 18,
  Sour = 19,
}

export const ReviewTasteEnumInfo: Record<ReviewTasteEnum, { tr: string, img: string, key: string }> = {
  [ReviewTasteEnum.Berries]: 
  {
    tr: 'strain_view_tastes_effects_berries',
    img: '/images/taste/berries.jpg',
    key: 'berries',
  },
  [ReviewTasteEnum.Cheese]:
  {
    tr: 'strain_view_tastes_effects_cheese',
    img: '/images/taste/cheese.jpg',
    key: 'cheese',
  },
  [ReviewTasteEnum.Citrus]:
  {
    tr: 'strain_view_tastes_effects_citrus',
    img: '/images/taste/citrus.jpg',
    key: 'citrus',
  },
  [ReviewTasteEnum.Diesel]:
  {
    tr: 'strain_view_tastes_effects_diesel',
    img: '/images/taste/diesel.jpg',
    key: 'diesel',
  },
  [ReviewTasteEnum.Earthy]:
  {
    tr: 'strain_view_tastes_effects_earthy',
    img: '/images/taste/earthy.jpg',
    key: 'earthy',
  },
  [ReviewTasteEnum.Flowery]:
  {
    tr: 'strain_view_tastes_effects_flowery',
    img: '/images/taste/flowery.jpg',
    key: 'flowery',
  },
  [ReviewTasteEnum.Mint]:
  {
    tr: 'strain_view_tastes_effects_mint',
    img: '/images/taste/mint.jpg',
    key: 'mint',
  },
  [ReviewTasteEnum.Nutty]:
  {
    tr: 'strain_view_tastes_effects_nutty',
    img: '/images/taste/nutty.jpg',
    key: 'nutty',
  },
  [ReviewTasteEnum.Sweet]:
  {
    tr: 'strain_view_tastes_effects_sweet',
    img: '/images/taste/sweet.jpg',
    key: 'sweet',
  },
  [ReviewTasteEnum.Tropical]:
  {
    tr: 'strain_view_tastes_effects_tropical',
    img: '/images/taste/tropical.jpg',
    key: 'tropical',
  },
  [ReviewTasteEnum.Caramel]:
  {
    tr: 'strain_view_tastes_effects_caramel',
    img: '/images/taste/caramel.jpg',
    key: 'caramel',
  },
  [ReviewTasteEnum.Chocolate]:
  {
    tr: 'strain_view_tastes_effects_chocolate',
    img: '/images/taste/chocolate.jpg',
    key: 'chocolate',
  },
  [ReviewTasteEnum.Cream]:
  {
    tr: 'strain_view_tastes_effects_cream',
    img: '/images/taste/cream.jpg',
    key: 'cream',
  },
  [ReviewTasteEnum.Fruity]:
  {
    tr: 'strain_view_tastes_effects_fruity',
    img: '/images/taste/fruity.jpg',
    key: 'fruity',
  },
  [ReviewTasteEnum.Herbs]:
  {
    tr: 'strain_view_tastes_effects_herbs',
    img: '/images/taste/herbs.jpg',
    key: 'herbs',
  },
  [ReviewTasteEnum.Pine]:
  {
    tr: 'strain_view_tastes_effects_pine',
    img: '/images/taste/pine.jpg',
    key: 'pine',
  },
  [ReviewTasteEnum.Woody]:
  {
    tr: 'strain_view_tastes_effects_woody',
    img: '/images/taste/woody.jpg',
    key: 'woody',
  },
  [ReviewTasteEnum.Pungent]:
  {
    tr: 'strain_view_tastes_effects_pungent',
    img: '/images/taste/pungent.jpg',
    key: 'pungent',
  },
  [ReviewTasteEnum.Sour]:
  {
    tr: 'strain_view_tastes_effects_sour',
    img: '/images/taste/sour.jpg',
    key: 'sour',
  },
}


export enum WeekHarvestReviewSeedPropsGrowingEnum {
  Easy = 1,
  Medium = 2,
  Hard = 3,
}


export enum WeekHarvestReviewSeedPropsResistanceEnum {
  Weak = 1,
  Neurtal = 2,
  Strong = 3,
}


 




// localization keys



export const WeekFazaNameTr: Record<WeekFaza, string> = {
  [WeekFaza.Germination]: 'universal_type_faza_ger',
  [WeekFaza.Vegetation]: 'universal_type_faza_veg',
  [WeekFaza.Flowering]: 'universal_type_faza_flo',
  [WeekFaza.Harvest]: 'universal_type_faza_har',
};

export const WeekFazaNameShortTr: Record<WeekFaza, string> = {
  [WeekFaza.Germination]: 'universal_type_faza_short_ger',
  [WeekFaza.Vegetation]: 'universal_type_faza_short_veg',
  [WeekFaza.Flowering]: 'universal_type_faza_short_flo',
  [WeekFaza.Harvest]: 'universal_type_faza_short_har',
};


export const DiaryTypeMethodTr: Record<DiaryTypeMethod, string> = {
  [DiaryTypeMethod.Lst]: 'universal_type_method_lst',
  [DiaryTypeMethod.Hst]: 'universal_type_method_hst',
  [DiaryTypeMethod.Sog]: 'universal_type_method_sog',
  [DiaryTypeMethod.Scrog]: 'universal_type_method_scrog',
  [DiaryTypeMethod.Topping]: 'universal_type_method_topping',
  [DiaryTypeMethod.Fiming]: 'universal_type_method_fiming',
  [DiaryTypeMethod.MainLining]: 'universal_type_method_main_lining',
  [DiaryTypeMethod.Defoliation]: 'universal_type_method_defoliation',
  [DiaryTypeMethod.Manifolding]: 'universal_type_method_manifolding',
  [DiaryTypeMethod.TwelveTwelve]: 'universal_type_method_12_12',
  [DiaryTypeMethod.Transplantation]: 'universal_type_method_transplantation',
};

export const DiaryTypeMethodShortTr: Record<DiaryTypeMethod, string> = {
  [DiaryTypeMethod.Lst]: 'universal_type_method_short_lst',
  [DiaryTypeMethod.Hst]: 'universal_type_method_short_hst',
  [DiaryTypeMethod.Sog]: 'universal_type_method_short_sog',
  [DiaryTypeMethod.Scrog]: 'universal_type_method_short_scrog',
  [DiaryTypeMethod.Topping]: 'universal_type_method_short_topping',
  [DiaryTypeMethod.Fiming]: 'universal_type_method_short_fiming',
  [DiaryTypeMethod.MainLining]: 'universal_type_method_short_main_lining',
  [DiaryTypeMethod.Defoliation]: 'universal_type_method_short_defoliation',
  [DiaryTypeMethod.Manifolding]: 'universal_type_method_short_manifolding',
  [DiaryTypeMethod.TwelveTwelve]: 'universal_type_method_short_12_12',
  [DiaryTypeMethod.Transplantation]: 'universal_type_method_short_transplantation',
};
 

 

export const DiaryTypeMediumTr: Record<DiaryTypeMedium, string> = {
  [DiaryTypeMedium.Soil]: 'universal_type_medium_soil',
  [DiaryTypeMedium.Perlite]: 'universal_type_medium_perlite',
  [DiaryTypeMedium.Vermiculite]: 'universal_type_medium_vermiculite',
  [DiaryTypeMedium.ExpandedClay]: 'universal_type_medium_expanded_clay',
  [DiaryTypeMedium.CocoCoir]: 'universal_type_medium_coco_coir',
  [DiaryTypeMedium.MineralWool]: 'universal_type_medium_mineral_wool',
  [DiaryTypeMedium.Other]: 'universal_type_medium_other',
};

<template>


  <!-- HERO -->

  <div class="hr">

    <div class="hri">


      <h1 class="sh">
        The
        <br>
        Grow
        <br>
        <div class="nom">
          award {{ props.year }}
        </div>        
      </h1>

      <div class="ds" v-if="props.caption">
        {{ props.caption }}
      </div>


    </div>


    </div>

  
</template>


<script setup>

const props = defineProps({
  year: {
    type: String,
    required: true
  },
  caption: {
    type: String,
    required: true
  }
})


</script>
 


<style scoped>
 
.hr {
  display: flex;
    align-items: flex-start;
    padding-top: 2rem;
    background-image: url(/images/awards/winner.webp);
    background-repeat: no-repeat;
    background-position-x: center;
    min-height: 25rem;
    background-position-y: -10rem;
    background-size: cover;
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 2rem;
}

.hr .hri {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  /* margin-top: 4rem; */
}

.hr .hri .sb {
  color: #ff8506;
  font-size: 2rem;
  font-weight: bold;
}

.hr .hri .sh {
  color: #000000 !important;
  font-size: 5.5rem !important;
  line-height: 5rem;
  text-transform: uppercase;
  text-shadow: 2px 2px 0px #ffe72d, -2px -2px 0px #d6d6d6, 2px -2px 0px #030303, -2px 2px 0px #acd0bc;
  margin-top: 9rem;
}

.hr .hri .sh .nom {
  font-size: 3rem;
  line-height: 3rem;
}

.hr .hri .ds {
  font-weight: bold;
    color: #000000;
    max-width: 360px;
    margin: 0 auto;
    line-height: 1.3rem;
    font-size: 1rem;
    text-shadow: 0px 2px 8px #f5db98, 0px 2px 5px #f5db98, 0px 2px 11px #f5db98;
}

.hr .hri .ln {
  margin-top: 2rem;
}

.hr .hri .ln .lni {
  text-decoration: underline;
  margin: 0 11px 0 0px;
}

.hr .hri .ln .lnib {
  background-color: #5aa62a;
  color: white;
  border-radius: 5px;
  padding: 7px 20px;
  margin-right: 10px;
}

.hr .lf {
  width: 50%;
}

@container pc (max-width: 600px) {
 
  .hr {
    flex-direction: column;

    background-position-y: -2rem;
  }

  .hr .hri {
    width: 100%;
  }

  .hr .hri .sb {}

  .hr .hri .sh {
    font-size: 4.8rem !important;
    line-height: 4.1rem;
  }

  .hr .hri .ds {}

  .hr .hri .ln {}

  .hr .hri .ln .lnib {}

  .hr .hri .ln .lni {}

  .hr .lf {
    width: 100%;
    display: none;
  }
}

</style>
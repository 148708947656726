<template>
    <div class="accordion">

      <div
        :class="{'accordion-item': true, 'active': isOpen(item.id), 'highlight': highlightItems.includes(item.id)}"
        v-for="(item, index) in items"
        :key="item.id"
      >
        <div 
          class="accordion-trigger" 
          @click="toggleItem(item.id)"
        >
          <span>{{ item.name }}</span>
  

          <i class="icon-caret-down caret" 
             :class="{ 'open': isOpen(item.id) }"
          ></i>
        </div>
  
        <div 
          class="accordion-content"
          v-show="isOpen(item.id)"
        >
          <slot :item="item" />
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, watchEffect } from 'vue'
  
 
  const props = defineProps({
    items: {
      type: Array,
      default: () => []
    },
    isMultiActive: {
      type: Boolean,
      default: false
    },
    activeId: {
      type: [String, Number, Array, null],
      default: null
    },
    highlightItems: {
      type: Array,
      default: () => []
    }
  })
  
  const emits = defineEmits(['update:active-id'])
  

  const openIds = ref([])
  

  watchEffect(() => {
    if (Array.isArray(props.activeId)) {
      openIds.value = [...props.activeId]
    } else if (props.activeId || props.activeId === 0) {
      openIds.value = [props.activeId]
    } else {
      openIds.value = []
    }
  })
  

  function isOpen(id) {
    return openIds.value.includes(id)
  }
  
  function toggleItem(id) {
    if (isOpen(id)) {
      openIds.value = openIds.value.filter(el => el !== id)
    } else {
      if (props.isMultiActive) {
        openIds.value.push(id)
      } else {
        openIds.value = [id]
      }
    }
  
    if (props.isMultiActive) {
      emits('update:active-id', openIds.value)
    } else {
      emits('update:active-id', openIds.value.length ? openIds.value[0] : null)
    }
  }
  </script>
  
  <style scoped>
  .accordion {
    /* border: 1px solid #ccc; */
    /* border-radius: 6px; */
    /* overflow: hidden; */
  }
  
  .accordion-item + .accordion-item {
    border-top: 1px dotted var(--un-element-color-gray)    
  }

  .accordion-item.highlight .caret {
    background-color: var(--un-firm);
    color: white;
    border-radius: 50%;
    display: inline-flex
;
    aspect-ratio: 1 / 1;
    width: 15px;
    justify-content: center;
  }
  

  .accordion-trigger {
    width: 100%;
    text-align: left;
    /* background: #f5f5f5; */
    border: none;
    padding: 0.75rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
  }
  

  .accordion-trigger .icon-caret-down {
    transition: transform 0.2s;
  }
  .accordion-trigger .icon-caret-down.open {
    transform: rotate(180deg);
  }
  

  .accordion-content {
    padding: 0.75rem 1rem;
    background: #fff;
  }
  </style>
<template>
  <div class="info">      

    <SkGrowerOpenedInfo
      v-if="statusUserInfoData === 'loading'"
    />
    <UserInfoStat 
      v-else
      :data="userInfoData"
    />
  </div>
</template>


<script setup lang="ts">

import type { User } from '@/types/user'

const route = useRoute();
const { $api, $ga, $helper, $head } = useNuxtApp();

interface Props {
  user: User;
  id: number;
}

const props = defineProps<Props>();

const loadData = async (id: number) => {
  return await $api.getGrowerInfo(id);  
}

const { status: statusUserInfoData,  data: userInfoData } = await useLazyAsyncData('userInfoData', async () => await loadData(props.id))



</script>




<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>

<template>

  <div class="rw">



    <NuxtLink :to="brand.link" class="logo">
      <img :src="brand.logo_s" class="logo_img">
    </NuxtLink>



    <NuxtLink 
      class="name" 
      :to="brand.link"
    >
      {{ brand.name }}
      <div class="cat">
        {{ $t('reviews_' + props.brand.category) }}
      </div>
    </NuxtLink>
      
    


    <div class="comment"> 

      <GeneralShowMore        
        :desktop-max-height="65"
        :mobile-max-height="80" 
        >
        <div 
          class="text"
          v-if="review.text"
          ref="refNutrientText"
        >{{ review.text }}</div> 

        <!-- <GeneralTranslateButton
          v-if="review.text && refNutrientText"
          :el="refNutrientText"
          :text="review.text"
          :id="review.ref_id"
          :type="props.type"
          :locale="$i18n.locale"
        /> -->

        <div v-if="items" class="nuts_items">
          <div
            v-for="(review_c, review_c_key) in items"
            :key="review_c_key"
            class="nuts_item"
          >
            <div class="photo">
              <NuxtLink :to="review_c.item_product.link">
                <img :src="review_c.item_product.cover_s" class="photo_img">
              </NuxtLink>
            </div>

            <div class="info">
              
              <NuxtLink :to="review_c.item_product.link">{{ review_c.item_product.name }}</NuxtLink>

              <UiRating
                :cnt="10"
                :vl="review_c.n_general"
                :editable="false"
                :show-rate="true"
                :of="10"
              />

            </div>
          </div>
        </div>


      </GeneralShowMore>

    </div>

  </div>
</template>

<script setup>
const props = defineProps({
  brand: {
    type: Object,
    required: true
  },
  review: {
    type: Object,
    required: true
  },
  items: {
    type: Array,
    required: false
  },
  type: {
    type: String,
    required: true
  }
})

const refNutrientText = ref(null)



</script>

<style scoped>
.rw {
  background-color: var(--un-background-color-gray);
  border-radius: 10px;
  padding: 1.2rem;
  margin-top: 0.5rem;
  width: 320px;
  display: grid;
  grid-template-columns: 1fr 30px;
  grid-template-areas: 
    "name logo"
    "comment comment";
  grid-template-rows: min-content;
  min-height: 150px;
}
.rw:deep(.toggler){
  margin-bottom: 0px;
}
 
.rw .logo {
  display: inline-block;
  vertical-align: top;
  text-align: center;
  grid-area: logo;
  width: 100%;
}

.rw .logo .logo_img {
  width: 100%;
  max-width: 50px;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.rw .name {
  grid-area: name;  
  font-weight: bold;
  display: inline-block;
  align-self: center;
}
.rw .cat {
  display: inline-block;
  color: var(--un-text-color-gray);
  font-weight: normal;
}
.rw .name > a {
  font-weight: bold;
  color: var(--un-text-color);
  font-size: 1.1rem;
}
.rw .comment > .text{
  color: var(--un-text-color);
}
.rw .comment > p:before {
  content: "\A";
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--un-background-color-gray) transparent transparent;
  position: absolute;
  left: -10px;
  top: 21px;
}
.rw .comment .btn_translate{
  margin-top:-10px;
  margin-bottom:10px;
  display:block;
}
.rw .comment{

}
.rw .comment .text{
  white-space: pre-wrap;
}

.rw .comment .nuts_items{
  margin-top: 0.5rem;
  display: block;
}
.rw .comment .nuts_items .nuts_item{
  display: inline-block;
  width: 100%;
  margin-bottom: 9px;
}
.rw .comment .nuts_items .nuts_item .photo{
  display: inline-block;
  vertical-align: top;
  max-height: 46px;
  width: 50px;
  text-align: center;
}
.rw .comment .nuts_items .nuts_item .photo .photo_img{
  max-height: 35px;
  aspect-ratio: 1/1;
  object-fit: contain;
  border-radius: 5px;
}
.rw .comment .nuts_items .nuts_item .info{
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 50px);
}
.rw .comment .nuts_items .nuts_item .info > a{
  color: var(--un-text-color);
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

@container pb (max-width: 600px) {
  
  
}



</style>
<template>

  <div class="pros" v-if="list && list.length">

    <h2 class="divider-sec">
      {{ title }} 
      <a class="h2_show_all" :href="link">{{ showmore }}</a>
    </h2>

    <div class="proslist" v-if="list">
      <template v-for="pro in list">
        <div class="last_update" v-if="pro.show_last_update">
          <i class="icon minus"></i> 
          {{ $dayjs.utc(pro.last_update).fromNow() }} 
          <i class="icon minus"></i>
        </div>
        <UserActivityItem
          :data="pro"
          />
      </template>
    </div>
  </div>

</template>

<script>
 

export default {   
  components: {   
    
  },
  props: [
    'title',
    'showmore',
    'limit',
    'list',
    'link'
  ],
  data () {
    return {  
      last_update: ''
    }
  },
  computed:{
  },
  created() {    
    // this.query = this.$route.query.q;  
    //this.last_update = this.list && this.list.length ? this.list[0].last_update : '';
    for(var l of this.list) {
      if(this.last_update != l.last_update){
        this.last_update = l.last_update;
        l.show_last_update = true;
      }else{
        l.show_last_update = false;
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped>
 
 
 

.proslist {

}


.proslist.centered {
}

.proslist > .last_update{
    text-align: center;
    padding: 0rem 1.5rem 1.5rem 1.5rem;
    color: #cacaca;
    font-weight: bold;
    margin-top: 20px;
}

</style>


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as diariesNkTdU9NuTMMeta } from "/app/pages/air-conditioners/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryrz2QZhPkWOMeta } from "/app/pages/air-conditioners/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growers5M5R832tEQMeta } from "/app/pages/air-conditioners/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvestsH5AqGwlUNlMeta } from "/app/pages/air-conditioners/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexsclSuJef11Meta } from "/app/pages/air-conditioners/[id]/[id2]/index/index.vue?macro=true";
import { default as reviewsnr2ottFDfoMeta } from "/app/pages/air-conditioners/[id]/[id2]/index/reviews.vue?macro=true";
import { default as indexc189rDnLLXMeta } from "/app/pages/air-conditioners/[id]/[id2]/index.vue?macro=true";
import { default as diariesN1ySvEKUbVMeta } from "/app/pages/air-conditioners/[id]/index/diaries.vue?macro=true";
import { default as growerszOHCeKtL0WMeta } from "/app/pages/air-conditioners/[id]/index/growers.vue?macro=true";
import { default as index58EFHSxX4vMeta } from "/app/pages/air-conditioners/[id]/index/index.vue?macro=true";
import { default as productsGfU0EA1xxsMeta } from "/app/pages/air-conditioners/[id]/index/products.vue?macro=true";
import { default as reviewsY67rkCQ0LzMeta } from "/app/pages/air-conditioners/[id]/index/reviews.vue?macro=true";
import { default as indexHX3S3f8kTmMeta } from "/app/pages/air-conditioners/[id]/index.vue?macro=true";
import { default as indexU9ANNUM2MBMeta } from "/app/pages/air-conditioners/index.vue?macro=true";
import { default as diariesA0t8M1ONV6Meta } from "/app/pages/air-filters/[id]/[id2]/index/diaries.vue?macro=true";
import { default as gallerySlTWT1py4rMeta } from "/app/pages/air-filters/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersf59QFXw4CdMeta } from "/app/pages/air-filters/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvestsEjeL3mLo6AMeta } from "/app/pages/air-filters/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexmhgYhRHRRiMeta } from "/app/pages/air-filters/[id]/[id2]/index/index.vue?macro=true";
import { default as reviewsy46mmKK1VyMeta } from "/app/pages/air-filters/[id]/[id2]/index/reviews.vue?macro=true";
import { default as indexiYrdNxvfkJMeta } from "/app/pages/air-filters/[id]/[id2]/index.vue?macro=true";
import { default as diariesz4ML7HnyDzMeta } from "/app/pages/air-filters/[id]/index/diaries.vue?macro=true";
import { default as growersYVZ28ziUPpMeta } from "/app/pages/air-filters/[id]/index/growers.vue?macro=true";
import { default as index1vYc2C3moGMeta } from "/app/pages/air-filters/[id]/index/index.vue?macro=true";
import { default as productscHZhvRn6h6Meta } from "/app/pages/air-filters/[id]/index/products.vue?macro=true";
import { default as reviews2enu6TetPkMeta } from "/app/pages/air-filters/[id]/index/reviews.vue?macro=true";
import { default as indexohLgsNMoFmMeta } from "/app/pages/air-filters/[id]/index.vue?macro=true";
import { default as indexCkBx2ck90bMeta } from "/app/pages/air-filters/index.vue?macro=true";
import { default as confirm_45emailS6FiQziIL6Meta } from "/app/pages/auth/confirm-email.vue?macro=true";
import { default as externalo5PyW4oMNFMeta } from "/app/pages/auth/external.vue?macro=true";
import { default as forgot_45passwordPFs5wuK5FuMeta } from "/app/pages/auth/forgot-password.vue?macro=true";
import { default as reset_45password5ABZjlJW61Meta } from "/app/pages/auth/reset-password.vue?macro=true";
import { default as signinFK2lTWFqnvMeta } from "/app/pages/auth/signin.vue?macro=true";
import { default as signup_32copy3TfDbZsSHKMeta } from "/app/pages/auth/signup copy.vue?macro=true";
import { default as signupHJ1GDnyyMkMeta } from "/app/pages/auth/signup.vue?macro=true";
import { default as unsubscribeVr01Xf6unlMeta } from "/app/pages/auth/unsubscribe.vue?macro=true";
import { default as indexKXbeZclZV6Meta } from "/app/pages/awards/[year]/index.vue?macro=true";
import { default as indexTJzcNtPM4TMeta } from "/app/pages/awards/index.vue?macro=true";
import { default as indexwxYeiK76VZMeta } from "/app/pages/brands-manager/banners/edit/box/[id]/index.vue?macro=true";
import { default as indexo4aGk3Y5NCMeta } from "/app/pages/brands-manager/banners/edit/cpm/[id]/index.vue?macro=true";
import { default as indexqjpUCVjQRKMeta } from "/app/pages/brands-manager/banners/edit/header/[id]/index.vue?macro=true";
import { default as ChartetBDPV5PssMeta } from "/app/pages/brands-manager/components/Chart.vue?macro=true";
import { default as ChartModal9hbAXDA7EyMeta } from "/app/pages/brands-manager/components/ChartModal.vue?macro=true";
import { default as ChartNewsletterspTudHOOMUuMeta } from "/app/pages/brands-manager/components/ChartNewsletters.vue?macro=true";
import { default as ChartPackageModalrWjtrTHguuMeta } from "/app/pages/brands-manager/components/ChartPackageModal.vue?macro=true";
import { default as EditAirConditionerF9IGKFqM2IMeta } from "/app/pages/brands-manager/components/EditAirConditioner.vue?macro=true";
import { default as EditAirFilterQSyvqg7dFrMeta } from "/app/pages/brands-manager/components/EditAirFilter.vue?macro=true";
import { default as EditBoxBanner1WzMhdDlpyMeta } from "/app/pages/brands-manager/components/EditBoxBanner.vue?macro=true";
import { default as EditBrandIYLJmMnd9RMeta } from "/app/pages/brands-manager/components/EditBrand.vue?macro=true";
import { default as EditCo2GeneratornWiwUbq4NmMeta } from "/app/pages/brands-manager/components/EditCo2Generator.vue?macro=true";
import { default as EditController3mxGycUYEuMeta } from "/app/pages/brands-manager/components/EditController.vue?macro=true";
import { default as EditCpmBannerx5jV3jetTpMeta } from "/app/pages/brands-manager/components/EditCpmBanner.vue?macro=true";
import { default as EditDripSystemMwnL1n8razMeta } from "/app/pages/brands-manager/components/EditDripSystem.vue?macro=true";
import { default as EditGrowBoxUyFKQ1xWiNMeta } from "/app/pages/brands-manager/components/EditGrowBox.vue?macro=true";
import { default as EditHeaderBannerchA6qgDOhSMeta } from "/app/pages/brands-manager/components/EditHeaderBanner.vue?macro=true";
import { default as EditHydroponicSystemoCSCdDfXAcMeta } from "/app/pages/brands-manager/components/EditHydroponicSystem.vue?macro=true";
import { default as EditLampIykS2l6DdQMeta } from "/app/pages/brands-manager/components/EditLamp.vue?macro=true";
import { default as EditNutrientaL6ONXN6zsMeta } from "/app/pages/brands-manager/components/EditNutrient.vue?macro=true";
import { default as EditNutrientCompositionkvx2ZqBcDfMeta } from "/app/pages/brands-manager/components/EditNutrientComposition.vue?macro=true";
import { default as EditNutrientSchemeTTkuGV9OoRMeta } from "/app/pages/brands-manager/components/EditNutrientScheme.vue?macro=true";
import { default as EditSeedbYUMxck9DDMeta } from "/app/pages/brands-manager/components/EditSeed.vue?macro=true";
import { default as EditTentHnceNDvxewMeta } from "/app/pages/brands-manager/components/EditTent.vue?macro=true";
import { default as EditVentilationFan1tdO37rlrgMeta } from "/app/pages/brands-manager/components/EditVentilationFan.vue?macro=true";
import { default as SelectJ0XkgopNI8Meta } from "/app/pages/brands-manager/components/Select.vue?macro=true";
import { default as TabAccountsSxMPFUYDuGMeta } from "/app/pages/brands-manager/components/TabAccounts.vue?macro=true";
import { default as TabBanners8GM6pmQ8BtMeta } from "/app/pages/brands-manager/components/TabBanners.vue?macro=true";
import { default as TabNewsletterskEfDP7uLkdMeta } from "/app/pages/brands-manager/components/TabNewsletters.vue?macro=true";
import { default as TabProductsRekKeSYd1XMeta } from "/app/pages/brands-manager/components/TabProducts.vue?macro=true";
import { default as TabSchemesRq2FxdpOqeMeta } from "/app/pages/brands-manager/components/TabSchemes.vue?macro=true";
import { default as TabStatisticsftOLDE9LtiMeta } from "/app/pages/brands-manager/components/TabStatistics.vue?macro=true";
import { default as TabSubscriptionirNilHvydXMeta } from "/app/pages/brands-manager/components/TabSubscription.vue?macro=true";
import { default as TabTestersWIPUYfpkUeMeta } from "/app/pages/brands-manager/components/TabTesters.vue?macro=true";
import { default as editBanner0t94w0bJSYMeta } from "/app/pages/brands-manager/func/editBanner.js?macro=true";
import { default as editBrandaRb0xn95LEMeta } from "/app/pages/brands-manager/func/editBrand.js?macro=true";
import { default as editProductJD5hau6tGIMeta } from "/app/pages/brands-manager/func/editProduct.js?macro=true";
import { default as editSchemeNG5imUmcPxMeta } from "/app/pages/brands-manager/func/editScheme.js?macro=true";
import { default as utils1vjjdOcC5VMeta } from "/app/pages/brands-manager/func/utils.js?macro=true";
import { default as bannershvN5ejPSMnMeta } from "/app/pages/brands-manager/index/banners.vue?macro=true";
import { default as edit02jVV7cuZ5Meta } from "/app/pages/brands-manager/index/edit.vue?macro=true";
import { default as index9rfEIZgZrKMeta } from "/app/pages/brands-manager/index/index.vue?macro=true";
import { default as newsletterswLOvEWqGSfMeta } from "/app/pages/brands-manager/index/newsletters.vue?macro=true";
import { default as productszyNGsDzrfPMeta } from "/app/pages/brands-manager/index/products.vue?macro=true";
import { default as schemesoX5lxvSjL6Meta } from "/app/pages/brands-manager/index/schemes.vue?macro=true";
import { default as statisticswqCEbfnTT6Meta } from "/app/pages/brands-manager/index/statistics.vue?macro=true";
import { default as subscriptioneHQO89qQBRMeta } from "/app/pages/brands-manager/index/subscription.vue?macro=true";
import { default as testersClgdT0jBAxMeta } from "/app/pages/brands-manager/index/testers.vue?macro=true";
import { default as welcome_45oldhMtzzDYyQ8Meta } from "/app/pages/brands-manager/index/welcome-old.vue?macro=true";
import { default as welcomebwY4XNdjLUMeta } from "/app/pages/brands-manager/index/welcome.vue?macro=true";
import { default as index0ywThHofGgMeta } from "/app/pages/brands-manager/index.vue?macro=true";
import { default as indexsyYt57uu0sMeta } from "/app/pages/brands-manager/products/edit_composition/[id]/index.vue?macro=true";
import { default as index0RoQwAQUVFMeta } from "/app/pages/brands-manager/products/edit/[id]/index.vue?macro=true";
import { default as indexel5menBYseMeta } from "/app/pages/brands-manager/schemes/edit/[id]/index.vue?macro=true";
import { default as Headyjs804lD0mMeta } from "/app/pages/brands-manager/view/Head.vue?macro=true";
import { default as diariesxbFvvQ3NZeMeta } from "/app/pages/co2-generators/[id]/[id2]/index/diaries.vue?macro=true";
import { default as gallery66Sg63aMfrMeta } from "/app/pages/co2-generators/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersLjoZ64NUOqMeta } from "/app/pages/co2-generators/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvestsQ7tS58rb5HMeta } from "/app/pages/co2-generators/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexxjB5rt1mY4Meta } from "/app/pages/co2-generators/[id]/[id2]/index/index.vue?macro=true";
import { default as reviewsMfmL7SMsc0Meta } from "/app/pages/co2-generators/[id]/[id2]/index/reviews.vue?macro=true";
import { default as indexWz0NxZIN18Meta } from "/app/pages/co2-generators/[id]/[id2]/index.vue?macro=true";
import { default as diariesHkulhUwQOuMeta } from "/app/pages/co2-generators/[id]/index/diaries.vue?macro=true";
import { default as growersoSVciG3DsqMeta } from "/app/pages/co2-generators/[id]/index/growers.vue?macro=true";
import { default as indexkKBx2HpFFYMeta } from "/app/pages/co2-generators/[id]/index/index.vue?macro=true";
import { default as productswZpudqZ4aBMeta } from "/app/pages/co2-generators/[id]/index/products.vue?macro=true";
import { default as reviewskWasCo2Ak8Meta } from "/app/pages/co2-generators/[id]/index/reviews.vue?macro=true";
import { default as indexcaUpJviLkSMeta } from "/app/pages/co2-generators/[id]/index.vue?macro=true";
import { default as indexrhOBhNSfICMeta } from "/app/pages/co2-generators/index.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as diarieshjgrXMiPzZMeta } from "/app/pages/controllers/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryl6uAR7ptISMeta } from "/app/pages/controllers/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersG08y77Ui8wMeta } from "/app/pages/controllers/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvestsUJEi8JzEu7Meta } from "/app/pages/controllers/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexPBpZTfJjJ7Meta } from "/app/pages/controllers/[id]/[id2]/index/index.vue?macro=true";
import { default as reviewsuQ9xm50jJRMeta } from "/app/pages/controllers/[id]/[id2]/index/reviews.vue?macro=true";
import { default as indexDBrtxvltsbMeta } from "/app/pages/controllers/[id]/[id2]/index.vue?macro=true";
import { default as diariestv4aV4dknOMeta } from "/app/pages/controllers/[id]/index/diaries.vue?macro=true";
import { default as growersoNi1UqGP9KMeta } from "/app/pages/controllers/[id]/index/growers.vue?macro=true";
import { default as indexKkP3Pj87lUMeta } from "/app/pages/controllers/[id]/index/index.vue?macro=true";
import { default as productsdd3HIg8QdaMeta } from "/app/pages/controllers/[id]/index/products.vue?macro=true";
import { default as reviewsTkVKAZgPZtMeta } from "/app/pages/controllers/[id]/index/reviews.vue?macro=true";
import { default as indexm4I9ZG48ZBMeta } from "/app/pages/controllers/[id]/index.vue?macro=true";
import { default as index5yINJoC2SYMeta } from "/app/pages/controllers/index.vue?macro=true";
import { default as _91week_93UIhc4GNrpjMeta } from "/app/pages/diaries/[id]/index/edit/smoke/[week].vue?macro=true";
import { default as _91week_93zliC3wimmfMeta } from "/app/pages/diaries/[id]/index/edit/week/[week].vue?macro=true";
import { default as indexIr8y0AideMMeta } from "/app/pages/diaries/[id]/index/index.vue?macro=true";
import { default as _91week_93oGyw1highBMeta } from "/app/pages/diaries/[id]/index/week/[week].vue?macro=true";
import { default as edit_45smokekym3wzdjdtMeta } from "/app/pages/diaries/[id]/index/weeks/[week]/edit-smoke.vue?macro=true";
import { default as edit3kH6XSopalMeta } from "/app/pages/diaries/[id]/index/weeks/[week]/edit.vue?macro=true";
import { default as indexyuk4MFLE81Meta } from "/app/pages/diaries/[id]/index.vue?macro=true";
import { default as _91id_93PlBuzlBSQPMeta } from "/app/pages/diaries/edit/[id].vue?macro=true";
import { default as diariesizWJWdCVRDMeta } from "/app/pages/drip-systems/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryfDP6SBhBm7Meta } from "/app/pages/drip-systems/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersSZBFj7Q1YPMeta } from "/app/pages/drip-systems/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvestsMFMrJUydehMeta } from "/app/pages/drip-systems/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexR503iRePuJMeta } from "/app/pages/drip-systems/[id]/[id2]/index/index.vue?macro=true";
import { default as reviewsq5HN2z8we3Meta } from "/app/pages/drip-systems/[id]/[id2]/index/reviews.vue?macro=true";
import { default as indexgXW9wL9esHMeta } from "/app/pages/drip-systems/[id]/[id2]/index.vue?macro=true";
import { default as diariesGKACeJFdtRMeta } from "/app/pages/drip-systems/[id]/index/diaries.vue?macro=true";
import { default as growersRHbm5qmPukMeta } from "/app/pages/drip-systems/[id]/index/growers.vue?macro=true";
import { default as indexI7QPbKxEMdMeta } from "/app/pages/drip-systems/[id]/index/index.vue?macro=true";
import { default as productsGqDiumCgCsMeta } from "/app/pages/drip-systems/[id]/index/products.vue?macro=true";
import { default as reviewsyrnPV5m5s8Meta } from "/app/pages/drip-systems/[id]/index/reviews.vue?macro=true";
import { default as indexxZe0NfwJxEMeta } from "/app/pages/drip-systems/[id]/index.vue?macro=true";
import { default as index0plKeoOATjMeta } from "/app/pages/drip-systems/index.vue?macro=true";
import { default as diaries9GwPJLraGhMeta } from "/app/pages/equipment/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryPfCrsSkQClMeta } from "/app/pages/equipment/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersptgRa4AADyMeta } from "/app/pages/equipment/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvestsztbde1hQfPMeta } from "/app/pages/equipment/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexwvCG8EMKKXMeta } from "/app/pages/equipment/[id]/[id2]/index/index.vue?macro=true";
import { default as reviewsY9zrCxJb0eMeta } from "/app/pages/equipment/[id]/[id2]/index/reviews.vue?macro=true";
import { default as indexlsMMXLBkHVMeta } from "/app/pages/equipment/[id]/[id2]/index.vue?macro=true";
import { default as diariesZThtNjMEuMMeta } from "/app/pages/equipment/[id]/index/diaries.vue?macro=true";
import { default as growerscV58guxEtxMeta } from "/app/pages/equipment/[id]/index/growers.vue?macro=true";
import { default as indexAFBBZiiHUuMeta } from "/app/pages/equipment/[id]/index/index.vue?macro=true";
import { default as productsOaBuqM1QZIMeta } from "/app/pages/equipment/[id]/index/products.vue?macro=true";
import { default as reviewsA7xLTpobB0Meta } from "/app/pages/equipment/[id]/index/reviews.vue?macro=true";
import { default as indexdqa3kGkFADMeta } from "/app/pages/equipment/[id]/index.vue?macro=true";
import { default as indexNApCtHoymHMeta } from "/app/pages/equipment/index.vue?macro=true";
import { default as _91_46_46_46tag_93huJTNvGKEZMeta } from "/app/pages/explore/[...tag].vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as index6TIfwwZpPIMeta } from "/app/pages/giveaways/[var]/index.vue?macro=true";
import { default as indexv74NJNVT9cMeta } from "/app/pages/giveaways/index.vue?macro=true";
import { default as diariesEmgSG5yUcnMeta } from "/app/pages/grow-boxes/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryFrOYXmdP30Meta } from "/app/pages/grow-boxes/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersYHLDHZXbvYMeta } from "/app/pages/grow-boxes/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvestszADBLvuSXOMeta } from "/app/pages/grow-boxes/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexbrmgvExbxdMeta } from "/app/pages/grow-boxes/[id]/[id2]/index/index.vue?macro=true";
import { default as reviewsbaOfmxoSmMMeta } from "/app/pages/grow-boxes/[id]/[id2]/index/reviews.vue?macro=true";
import { default as indexbRAha9LWHuMeta } from "/app/pages/grow-boxes/[id]/[id2]/index.vue?macro=true";
import { default as diariesJxqXOE5A3MMeta } from "/app/pages/grow-boxes/[id]/index/diaries.vue?macro=true";
import { default as growersU01QdRU1ZiMeta } from "/app/pages/grow-boxes/[id]/index/growers.vue?macro=true";
import { default as indextEz5NYJT2sMeta } from "/app/pages/grow-boxes/[id]/index/index.vue?macro=true";
import { default as productsknBnPYYm3sMeta } from "/app/pages/grow-boxes/[id]/index/products.vue?macro=true";
import { default as reviews0Mbkgnh64RMeta } from "/app/pages/grow-boxes/[id]/index/reviews.vue?macro=true";
import { default as indexUyPRQpGA6ZMeta } from "/app/pages/grow-boxes/[id]/index.vue?macro=true";
import { default as indexDEHY0jmKGgMeta } from "/app/pages/grow-boxes/index.vue?macro=true";
import { default as _91_46_46_46strain_93l1wRH5RlogMeta } from "/app/pages/grow-journals/[...strain].vue?macro=true";
import { default as indexU7DNJdJ0FRMeta } from "/app/pages/grow-journals/index.vue?macro=true";
import { default as diariesAk6NmQl9SmMeta } from "/app/pages/grow-lights/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryxjweX4I6aFMeta } from "/app/pages/grow-lights/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growerswEyVF5ZrAzMeta } from "/app/pages/grow-lights/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvestseBpZCl7tIqMeta } from "/app/pages/grow-lights/[id]/[id2]/index/harvests.vue?macro=true";
import { default as index0KpghO2031Meta } from "/app/pages/grow-lights/[id]/[id2]/index/index.vue?macro=true";
import { default as reviewshXNaCC05qpMeta } from "/app/pages/grow-lights/[id]/[id2]/index/reviews.vue?macro=true";
import { default as indexuekBHNx8vQMeta } from "/app/pages/grow-lights/[id]/[id2]/index.vue?macro=true";
import { default as diariesm2YFnxFPa9Meta } from "/app/pages/grow-lights/[id]/index/diaries.vue?macro=true";
import { default as growers7XV22QhQSwMeta } from "/app/pages/grow-lights/[id]/index/growers.vue?macro=true";
import { default as indexxb1fbYN82uMeta } from "/app/pages/grow-lights/[id]/index/index.vue?macro=true";
import { default as productsp0yGU1GyNvMeta } from "/app/pages/grow-lights/[id]/index/products.vue?macro=true";
import { default as reviewsg0CmGZY7zTMeta } from "/app/pages/grow-lights/[id]/index/reviews.vue?macro=true";
import { default as index5PMeBs3PZhMeta } from "/app/pages/grow-lights/[id]/index.vue?macro=true";
import { default as index_32copySIKjrWkCQLMeta } from "/app/pages/grow-lights/index copy.vue?macro=true";
import { default as indexALRejU3RGhMeta } from "/app/pages/grow-lights/index.vue?macro=true";
import { default as _91_46_46_46tag_934xOnsDsx4ZMeta } from "/app/pages/grow-questions/[...tag].vue?macro=true";
import { default as indexRFIJCx3aMpMeta } from "/app/pages/grow-questions/[id]/index.vue?macro=true";
import { default as _91id_93txNwIKZtUpMeta } from "/app/pages/grow-questions/edit/[id].vue?macro=true";
import { default as diaries03eJiof1pPMeta } from "/app/pages/grow-tents/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryIf3SGMMonTMeta } from "/app/pages/grow-tents/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersgJPlqbzXJ3Meta } from "/app/pages/grow-tents/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvests5YuUuQ79JzMeta } from "/app/pages/grow-tents/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexEobxaRIWRVMeta } from "/app/pages/grow-tents/[id]/[id2]/index/index.vue?macro=true";
import { default as reviews95dfEidDeUMeta } from "/app/pages/grow-tents/[id]/[id2]/index/reviews.vue?macro=true";
import { default as indexSOswOoe42xMeta } from "/app/pages/grow-tents/[id]/[id2]/index.vue?macro=true";
import { default as diariesw7EBleemsBMeta } from "/app/pages/grow-tents/[id]/index/diaries.vue?macro=true";
import { default as growers1zbouaXBQ3Meta } from "/app/pages/grow-tents/[id]/index/growers.vue?macro=true";
import { default as indexLLUWx686KuMeta } from "/app/pages/grow-tents/[id]/index/index.vue?macro=true";
import { default as products0MWBGfBhhqMeta } from "/app/pages/grow-tents/[id]/index/products.vue?macro=true";
import { default as reviewsiofCwieNg0Meta } from "/app/pages/grow-tents/[id]/index/reviews.vue?macro=true";
import { default as index5enWd4LLy6Meta } from "/app/pages/grow-tents/[id]/index.vue?macro=true";
import { default as indexCDRYaimgneMeta } from "/app/pages/grow-tents/index.vue?macro=true";
import { default as activityuP26AwSHaxMeta } from "/app/pages/grower/[id]/index/activity.vue?macro=true";
import { default as diariesR2iwNjHmCBMeta } from "/app/pages/grower/[id]/index/diaries.vue?macro=true";
import { default as followersx60elYYqlDMeta } from "/app/pages/grower/[id]/index/followers.vue?macro=true";
import { default as followingdPhRoHY9kLMeta } from "/app/pages/grower/[id]/index/following.vue?macro=true";
import { default as index6SEiAiEXIxMeta } from "/app/pages/grower/[id]/index/index.vue?macro=true";
import { default as reviewsnZIstk27pRMeta } from "/app/pages/grower/[id]/index/reviews.vue?macro=true";
import { default as index4SqB1uWisQMeta } from "/app/pages/grower/[id]/index.vue?macro=true";
import { default as bannedg8VKNYCo4jMeta } from "/app/pages/grower/[id]/settings/index/banned.vue?macro=true";
import { default as cookiesCqmVZ7BB2wMeta } from "/app/pages/grower/[id]/settings/index/cookies.vue?macro=true";
import { default as deletenS6cK40VPpMeta } from "/app/pages/grower/[id]/settings/index/delete.vue?macro=true";
import { default as indexmEqw46CS7BMeta } from "/app/pages/grower/[id]/settings/index/index.vue?macro=true";
import { default as password8ZtNzPkrXUMeta } from "/app/pages/grower/[id]/settings/index/password.vue?macro=true";
import { default as socialsN8Q0GFj4IVMeta } from "/app/pages/grower/[id]/settings/index/socials.vue?macro=true";
import { default as indexUZ2tz6pmC2Meta } from "/app/pages/grower/[id]/settings/index.vue?macro=true";
import { default as indexdmBb6HGadyMeta } from "/app/pages/growers/index.vue?macro=true";
import { default as diaries6Lo2b0s3l3Meta } from "/app/pages/hydroponic-systems/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryZ2OcySvkluMeta } from "/app/pages/hydroponic-systems/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersE6pxuLgpMMMeta } from "/app/pages/hydroponic-systems/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvestsrFpQFye3ePMeta } from "/app/pages/hydroponic-systems/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexvVe7uRfuY8Meta } from "/app/pages/hydroponic-systems/[id]/[id2]/index/index.vue?macro=true";
import { default as reviewsFI2bFBgzzNMeta } from "/app/pages/hydroponic-systems/[id]/[id2]/index/reviews.vue?macro=true";
import { default as indexmbNoCilz2QMeta } from "/app/pages/hydroponic-systems/[id]/[id2]/index.vue?macro=true";
import { default as diariesY2NtqonYznMeta } from "/app/pages/hydroponic-systems/[id]/index/diaries.vue?macro=true";
import { default as growersExliwsCo7FMeta } from "/app/pages/hydroponic-systems/[id]/index/growers.vue?macro=true";
import { default as indexU9aTgrTWwgMeta } from "/app/pages/hydroponic-systems/[id]/index/index.vue?macro=true";
import { default as productspz0dDHsvJiMeta } from "/app/pages/hydroponic-systems/[id]/index/products.vue?macro=true";
import { default as reviewsxRSggStUryMeta } from "/app/pages/hydroponic-systems/[id]/index/reviews.vue?macro=true";
import { default as indexlvMAUuRMl1Meta } from "/app/pages/hydroponic-systems/[id]/index.vue?macro=true";
import { default as indexl2g74lZWgNMeta } from "/app/pages/hydroponic-systems/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexPCnZMpplCXMeta } from "/app/pages/journal/[var]/index.vue?macro=true";
import { default as indexdg4MhH4999Meta } from "/app/pages/journal/category/[var]/index.vue?macro=true";
import { default as index2jae7yDrE4Meta } from "/app/pages/journal/index.vue?macro=true";
import { default as indexhimDPd2CPFMeta } from "/app/pages/journal/tags/[var]/index.vue?macro=true";
import { default as indexuwkj90DH6EMeta } from "/app/pages/journal/voting/[var]/index.vue?macro=true";
import { default as indexco8adXiQ3uMeta } from "/app/pages/journal/voting/index.vue?macro=true";
import { default as indexj88iZwExVdMeta } from "/app/pages/notifications/index.vue?macro=true";
import { default as diariesyUM9h0ba8WMeta } from "/app/pages/nutrients/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryWCmb9f88fiMeta } from "/app/pages/nutrients/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersFon0IWXCwCMeta } from "/app/pages/nutrients/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvestsuvFmOVtPraMeta } from "/app/pages/nutrients/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexmBjaj9jl2MMeta } from "/app/pages/nutrients/[id]/[id2]/index/index.vue?macro=true";
import { default as reviews47ffEnLYLPMeta } from "/app/pages/nutrients/[id]/[id2]/index/reviews.vue?macro=true";
import { default as indexmFPzrILEPCMeta } from "/app/pages/nutrients/[id]/[id2]/index.vue?macro=true";
import { default as diaries7upJxVbySZMeta } from "/app/pages/nutrients/[id]/index/diaries.vue?macro=true";
import { default as growers4ts7dDGWDLMeta } from "/app/pages/nutrients/[id]/index/growers.vue?macro=true";
import { default as indexRXsUUYyahLMeta } from "/app/pages/nutrients/[id]/index/index.vue?macro=true";
import { default as products8U5ksqg12WMeta } from "/app/pages/nutrients/[id]/index/products.vue?macro=true";
import { default as reviewsqoDwtgXcKzMeta } from "/app/pages/nutrients/[id]/index/reviews.vue?macro=true";
import { default as schemesl7YkgBgbVKMeta } from "/app/pages/nutrients/[id]/index/schemes.vue?macro=true";
import { default as index2POX36WO4UMeta } from "/app/pages/nutrients/[id]/index.vue?macro=true";
import { default as indexY5VCq18v3cMeta } from "/app/pages/nutrients/index.vue?macro=true";
import { default as partnergBQ3WL5IwFMeta } from "/app/pages/partner.vue?macro=true";
import { default as policyhDmBrXN4qOMeta } from "/app/pages/policy.vue?macro=true";
import { default as premiumEP1PXaA2PJMeta } from "/app/pages/premium.vue?macro=true";
import { default as diaries1rfIw4ZTu5Meta } from "/app/pages/seedbank/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryohu5mhgEUJMeta } from "/app/pages/seedbank/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growers8yGXztPej9Meta } from "/app/pages/seedbank/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvestsh9qelQwiRKMeta } from "/app/pages/seedbank/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexnm3IX5kfzlMeta } from "/app/pages/seedbank/[id]/[id2]/index/index.vue?macro=true";
import { default as reviews7SQZ6gOTEbMeta } from "/app/pages/seedbank/[id]/[id2]/index/reviews.vue?macro=true";
import { default as indexlbCkz15KfRMeta } from "/app/pages/seedbank/[id]/[id2]/index.vue?macro=true";
import { default as diariesoCnCiutZmXMeta } from "/app/pages/seedbank/[id]/index/diaries.vue?macro=true";
import { default as gallerybXPmyqwBSKMeta } from "/app/pages/seedbank/[id]/index/gallery.vue?macro=true";
import { default as growerskqygtm4U6BMeta } from "/app/pages/seedbank/[id]/index/growers.vue?macro=true";
import { default as harvestsbDvvy7ld6sMeta } from "/app/pages/seedbank/[id]/index/harvests.vue?macro=true";
import { default as indexHne92h1iAeMeta } from "/app/pages/seedbank/[id]/index/index.vue?macro=true";
import { default as reviewsIHFOofc18wMeta } from "/app/pages/seedbank/[id]/index/reviews.vue?macro=true";
import { default as strainsvdNLzGrIDbMeta } from "/app/pages/seedbank/[id]/index/strains.vue?macro=true";
import { default as indexKD40KTzJYyMeta } from "/app/pages/seedbank/[id]/index.vue?macro=true";
import { default as indexadw40MeOhwMeta } from "/app/pages/seedbank/index.vue?macro=true";
import { default as indextMawA1UKyNMeta } from "/app/pages/shorts/[id]/index.vue?macro=true";
import { default as indexfwTWBoZzPQMeta } from "/app/pages/shorts/index.vue?macro=true";
import { default as stu0oTkfac7nMeta } from "/app/pages/st.vue?macro=true";
import { default as strains_45treeQcboDCwdvPMeta } from "/app/pages/strains-tree.vue?macro=true";
import { default as _91_46_46_46tag_93NMTKmxqAAEMeta } from "/app/pages/strains/[...tag].vue?macro=true";
import { default as diariesR9NFRvTqOvMeta } from "/app/pages/strains2/[id]/index/diaries.vue?macro=true";
import { default as galleryOlRYFxz6JfMeta } from "/app/pages/strains2/[id]/index/gallery.vue?macro=true";
import { default as growersReRXoSYapSMeta } from "/app/pages/strains2/[id]/index/growers.vue?macro=true";
import { default as harvestspoQPFfOMpoMeta } from "/app/pages/strains2/[id]/index/harvests.vue?macro=true";
import { default as indexlRVYJa2SdbMeta } from "/app/pages/strains2/[id]/index/index.vue?macro=true";
import { default as productsgdcg9y3eJDMeta } from "/app/pages/strains2/[id]/index/products.vue?macro=true";
import { default as reviewsEtEaM4W320Meta } from "/app/pages/strains2/[id]/index/reviews.vue?macro=true";
import { default as indexlI73DG2yXgMeta } from "/app/pages/strains2/[id]/index.vue?macro=true";
import { default as _91val_93w8Fnh2J3DIMeta } from "/app/pages/strains2/category/[category]/[val].vue?macro=true";
import { default as indexs5R0Tp7QjLMeta } from "/app/pages/strains2/index.vue?macro=true";
import { default as termsy18ZESLqu6Meta } from "/app/pages/terms.vue?macro=true";
import { default as diariesvflKfpUXg1Meta } from "/app/pages/ventilation-fans/[id]/[id2]/index/diaries.vue?macro=true";
import { default as galleryiXAD7YTaj1Meta } from "/app/pages/ventilation-fans/[id]/[id2]/index/gallery.vue?macro=true";
import { default as growersG870hWUqYIMeta } from "/app/pages/ventilation-fans/[id]/[id2]/index/growers.vue?macro=true";
import { default as harvestsojXOKpPUbEMeta } from "/app/pages/ventilation-fans/[id]/[id2]/index/harvests.vue?macro=true";
import { default as indexkfxAwLtmS4Meta } from "/app/pages/ventilation-fans/[id]/[id2]/index/index.vue?macro=true";
import { default as reviewsgCuBZaM5G5Meta } from "/app/pages/ventilation-fans/[id]/[id2]/index/reviews.vue?macro=true";
import { default as index02NvNpUxDfMeta } from "/app/pages/ventilation-fans/[id]/[id2]/index.vue?macro=true";
import { default as diariesvCbveO6r5aMeta } from "/app/pages/ventilation-fans/[id]/index/diaries.vue?macro=true";
import { default as growersqboPMLZuuZMeta } from "/app/pages/ventilation-fans/[id]/index/growers.vue?macro=true";
import { default as indexNNj6BRzsCVMeta } from "/app/pages/ventilation-fans/[id]/index/index.vue?macro=true";
import { default as products70PKHX2SYjMeta } from "/app/pages/ventilation-fans/[id]/index/products.vue?macro=true";
import { default as reviewsZqy0qkNGpBMeta } from "/app/pages/ventilation-fans/[id]/index/reviews.vue?macro=true";
import { default as indexYF8ONQqKmEMeta } from "/app/pages/ventilation-fans/[id]/index.vue?macro=true";
import { default as index3k0ZwcAobBMeta } from "/app/pages/ventilation-fans/index.vue?macro=true";
import { default as indexkpICEk8tfZMeta } from "/app/pages/video/[id]/index.vue?macro=true";
import { default as widgetzMSPHa954TMeta } from "/app/pages/widget.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/app/pages/about.vue")
  },
  {
    name: indexc189rDnLLXMeta?.name,
    path: "/air-conditioners/:id()/:id2()",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index.vue"),
    children: [
  {
    name: "air-conditioners-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "air-conditioners-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "air-conditioners-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index/growers.vue")
  },
  {
    name: "air-conditioners-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "air-conditioners-id-id2-index",
    path: "",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index/index.vue")
  },
  {
    name: "air-conditioners-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/air-conditioners/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: indexHX3S3f8kTmMeta?.name,
    path: "/air-conditioners/:id()",
    component: () => import("/app/pages/air-conditioners/[id]/index.vue"),
    children: [
  {
    name: "air-conditioners-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/air-conditioners/[id]/index/diaries.vue")
  },
  {
    name: "air-conditioners-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/air-conditioners/[id]/index/growers.vue")
  },
  {
    name: "air-conditioners-id-index",
    path: "",
    component: () => import("/app/pages/air-conditioners/[id]/index/index.vue")
  },
  {
    name: "air-conditioners-id-index-products",
    path: "products",
    component: () => import("/app/pages/air-conditioners/[id]/index/products.vue")
  },
  {
    name: "air-conditioners-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/air-conditioners/[id]/index/reviews.vue")
  }
]
  },
  {
    name: "air-conditioners",
    path: "/air-conditioners",
    component: () => import("/app/pages/air-conditioners/index.vue")
  },
  {
    name: indexiYrdNxvfkJMeta?.name,
    path: "/air-filters/:id()/:id2()",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index.vue"),
    children: [
  {
    name: "air-filters-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "air-filters-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "air-filters-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index/growers.vue")
  },
  {
    name: "air-filters-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "air-filters-id-id2-index",
    path: "",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index/index.vue")
  },
  {
    name: "air-filters-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/air-filters/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: indexohLgsNMoFmMeta?.name,
    path: "/air-filters/:id()",
    component: () => import("/app/pages/air-filters/[id]/index.vue"),
    children: [
  {
    name: "air-filters-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/air-filters/[id]/index/diaries.vue")
  },
  {
    name: "air-filters-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/air-filters/[id]/index/growers.vue")
  },
  {
    name: "air-filters-id-index",
    path: "",
    component: () => import("/app/pages/air-filters/[id]/index/index.vue")
  },
  {
    name: "air-filters-id-index-products",
    path: "products",
    component: () => import("/app/pages/air-filters/[id]/index/products.vue")
  },
  {
    name: "air-filters-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/air-filters/[id]/index/reviews.vue")
  }
]
  },
  {
    name: "air-filters",
    path: "/air-filters",
    component: () => import("/app/pages/air-filters/index.vue")
  },
  {
    name: "auth-confirm-email",
    path: "/auth/confirm-email",
    meta: confirm_45emailS6FiQziIL6Meta || {},
    component: () => import("/app/pages/auth/confirm-email.vue")
  },
  {
    name: "auth-external",
    path: "/auth/external",
    meta: externalo5PyW4oMNFMeta || {},
    component: () => import("/app/pages/auth/external.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordPFs5wuK5FuMeta || {},
    component: () => import("/app/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45password5ABZjlJW61Meta || {},
    component: () => import("/app/pages/auth/reset-password.vue")
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: signinFK2lTWFqnvMeta || {},
    component: () => import("/app/pages/auth/signin.vue")
  },
  {
    name: "auth-signup copy",
    path: "/auth/signup%20copy",
    meta: signup_32copy3TfDbZsSHKMeta || {},
    component: () => import("/app/pages/auth/signup copy.vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupHJ1GDnyyMkMeta || {},
    component: () => import("/app/pages/auth/signup.vue")
  },
  {
    name: "auth-unsubscribe",
    path: "/auth/unsubscribe",
    meta: unsubscribeVr01Xf6unlMeta || {},
    component: () => import("/app/pages/auth/unsubscribe.vue")
  },
  {
    name: "awards-year",
    path: "/awards/:year()",
    component: () => import("/app/pages/awards/[year]/index.vue")
  },
  {
    name: "awards",
    path: "/awards",
    component: () => import("/app/pages/awards/index.vue")
  },
  {
    name: "brands-manager-banners-edit-box-id",
    path: "/brands-manager/banners/edit/box/:id()",
    component: () => import("/app/pages/brands-manager/banners/edit/box/[id]/index.vue")
  },
  {
    name: "brands-manager-banners-edit-cpm-id",
    path: "/brands-manager/banners/edit/cpm/:id()",
    component: () => import("/app/pages/brands-manager/banners/edit/cpm/[id]/index.vue")
  },
  {
    name: "brands-manager-banners-edit-header-id",
    path: "/brands-manager/banners/edit/header/:id()",
    component: () => import("/app/pages/brands-manager/banners/edit/header/[id]/index.vue")
  },
  {
    name: "brands-manager-components-Chart",
    path: "/brands-manager/components/Chart",
    component: () => import("/app/pages/brands-manager/components/Chart.vue")
  },
  {
    name: "brands-manager-components-ChartModal",
    path: "/brands-manager/components/ChartModal",
    component: () => import("/app/pages/brands-manager/components/ChartModal.vue")
  },
  {
    name: "brands-manager-components-ChartNewsletters",
    path: "/brands-manager/components/ChartNewsletters",
    component: () => import("/app/pages/brands-manager/components/ChartNewsletters.vue")
  },
  {
    name: "brands-manager-components-ChartPackageModal",
    path: "/brands-manager/components/ChartPackageModal",
    component: () => import("/app/pages/brands-manager/components/ChartPackageModal.vue")
  },
  {
    name: "brands-manager-components-EditAirConditioner",
    path: "/brands-manager/components/EditAirConditioner",
    component: () => import("/app/pages/brands-manager/components/EditAirConditioner.vue")
  },
  {
    name: "brands-manager-components-EditAirFilter",
    path: "/brands-manager/components/EditAirFilter",
    component: () => import("/app/pages/brands-manager/components/EditAirFilter.vue")
  },
  {
    name: "brands-manager-components-EditBoxBanner",
    path: "/brands-manager/components/EditBoxBanner",
    component: () => import("/app/pages/brands-manager/components/EditBoxBanner.vue")
  },
  {
    name: "brands-manager-components-EditBrand",
    path: "/brands-manager/components/EditBrand",
    component: () => import("/app/pages/brands-manager/components/EditBrand.vue")
  },
  {
    name: "brands-manager-components-EditCo2Generator",
    path: "/brands-manager/components/EditCo2Generator",
    component: () => import("/app/pages/brands-manager/components/EditCo2Generator.vue")
  },
  {
    name: "brands-manager-components-EditController",
    path: "/brands-manager/components/EditController",
    component: () => import("/app/pages/brands-manager/components/EditController.vue")
  },
  {
    name: "brands-manager-components-EditCpmBanner",
    path: "/brands-manager/components/EditCpmBanner",
    component: () => import("/app/pages/brands-manager/components/EditCpmBanner.vue")
  },
  {
    name: "brands-manager-components-EditDripSystem",
    path: "/brands-manager/components/EditDripSystem",
    component: () => import("/app/pages/brands-manager/components/EditDripSystem.vue")
  },
  {
    name: "brands-manager-components-EditGrowBox",
    path: "/brands-manager/components/EditGrowBox",
    component: () => import("/app/pages/brands-manager/components/EditGrowBox.vue")
  },
  {
    name: "brands-manager-components-EditHeaderBanner",
    path: "/brands-manager/components/EditHeaderBanner",
    component: () => import("/app/pages/brands-manager/components/EditHeaderBanner.vue")
  },
  {
    name: "brands-manager-components-EditHydroponicSystem",
    path: "/brands-manager/components/EditHydroponicSystem",
    component: () => import("/app/pages/brands-manager/components/EditHydroponicSystem.vue")
  },
  {
    name: "brands-manager-components-EditLamp",
    path: "/brands-manager/components/EditLamp",
    component: () => import("/app/pages/brands-manager/components/EditLamp.vue")
  },
  {
    name: "brands-manager-components-EditNutrient",
    path: "/brands-manager/components/EditNutrient",
    component: () => import("/app/pages/brands-manager/components/EditNutrient.vue")
  },
  {
    name: "brands-manager-components-EditNutrientComposition",
    path: "/brands-manager/components/EditNutrientComposition",
    component: () => import("/app/pages/brands-manager/components/EditNutrientComposition.vue")
  },
  {
    name: "brands-manager-components-EditNutrientScheme",
    path: "/brands-manager/components/EditNutrientScheme",
    component: () => import("/app/pages/brands-manager/components/EditNutrientScheme.vue")
  },
  {
    name: "brands-manager-components-EditSeed",
    path: "/brands-manager/components/EditSeed",
    component: () => import("/app/pages/brands-manager/components/EditSeed.vue")
  },
  {
    name: "brands-manager-components-EditTent",
    path: "/brands-manager/components/EditTent",
    component: () => import("/app/pages/brands-manager/components/EditTent.vue")
  },
  {
    name: "brands-manager-components-EditVentilationFan",
    path: "/brands-manager/components/EditVentilationFan",
    component: () => import("/app/pages/brands-manager/components/EditVentilationFan.vue")
  },
  {
    name: "brands-manager-components-Select",
    path: "/brands-manager/components/Select",
    component: () => import("/app/pages/brands-manager/components/Select.vue")
  },
  {
    name: "brands-manager-components-TabAccounts",
    path: "/brands-manager/components/TabAccounts",
    component: () => import("/app/pages/brands-manager/components/TabAccounts.vue")
  },
  {
    name: "brands-manager-components-TabBanners",
    path: "/brands-manager/components/TabBanners",
    component: () => import("/app/pages/brands-manager/components/TabBanners.vue")
  },
  {
    name: "brands-manager-components-TabNewsletters",
    path: "/brands-manager/components/TabNewsletters",
    component: () => import("/app/pages/brands-manager/components/TabNewsletters.vue")
  },
  {
    name: "brands-manager-components-TabProducts",
    path: "/brands-manager/components/TabProducts",
    component: () => import("/app/pages/brands-manager/components/TabProducts.vue")
  },
  {
    name: "brands-manager-components-TabSchemes",
    path: "/brands-manager/components/TabSchemes",
    component: () => import("/app/pages/brands-manager/components/TabSchemes.vue")
  },
  {
    name: "brands-manager-components-TabStatistics",
    path: "/brands-manager/components/TabStatistics",
    component: () => import("/app/pages/brands-manager/components/TabStatistics.vue")
  },
  {
    name: "brands-manager-components-TabSubscription",
    path: "/brands-manager/components/TabSubscription",
    component: () => import("/app/pages/brands-manager/components/TabSubscription.vue")
  },
  {
    name: "brands-manager-components-TabTesters",
    path: "/brands-manager/components/TabTesters",
    component: () => import("/app/pages/brands-manager/components/TabTesters.vue")
  },
  {
    name: "brands-manager-func-editBanner",
    path: "/brands-manager/func/editBanner",
    component: () => import("/app/pages/brands-manager/func/editBanner.js")
  },
  {
    name: "brands-manager-func-editBrand",
    path: "/brands-manager/func/editBrand",
    component: () => import("/app/pages/brands-manager/func/editBrand.js")
  },
  {
    name: "brands-manager-func-editProduct",
    path: "/brands-manager/func/editProduct",
    component: () => import("/app/pages/brands-manager/func/editProduct.js")
  },
  {
    name: "brands-manager-func-editScheme",
    path: "/brands-manager/func/editScheme",
    component: () => import("/app/pages/brands-manager/func/editScheme.js")
  },
  {
    name: "brands-manager-func-utils",
    path: "/brands-manager/func/utils",
    component: () => import("/app/pages/brands-manager/func/utils.js")
  },
  {
    name: index0ywThHofGgMeta?.name,
    path: "/brands-manager",
    component: () => import("/app/pages/brands-manager/index.vue"),
    children: [
  {
    name: "brands-manager-index-banners",
    path: "banners",
    component: () => import("/app/pages/brands-manager/index/banners.vue")
  },
  {
    name: "brands-manager-index-edit",
    path: "edit",
    component: () => import("/app/pages/brands-manager/index/edit.vue")
  },
  {
    name: "brands-manager-index",
    path: "",
    component: () => import("/app/pages/brands-manager/index/index.vue")
  },
  {
    name: "brands-manager-index-newsletters",
    path: "newsletters",
    component: () => import("/app/pages/brands-manager/index/newsletters.vue")
  },
  {
    name: "brands-manager-index-products",
    path: "products",
    component: () => import("/app/pages/brands-manager/index/products.vue")
  },
  {
    name: "brands-manager-index-schemes",
    path: "schemes",
    component: () => import("/app/pages/brands-manager/index/schemes.vue")
  },
  {
    name: "brands-manager-index-statistics",
    path: "statistics",
    component: () => import("/app/pages/brands-manager/index/statistics.vue")
  },
  {
    name: "brands-manager-index-subscription",
    path: "subscription",
    component: () => import("/app/pages/brands-manager/index/subscription.vue")
  },
  {
    name: "brands-manager-index-testers",
    path: "testers",
    component: () => import("/app/pages/brands-manager/index/testers.vue")
  },
  {
    name: "brands-manager-index-welcome-old",
    path: "welcome-old",
    component: () => import("/app/pages/brands-manager/index/welcome-old.vue")
  },
  {
    name: "brands-manager-index-welcome",
    path: "welcome",
    component: () => import("/app/pages/brands-manager/index/welcome.vue")
  }
]
  },
  {
    name: "brands-manager-products-edit_composition-id",
    path: "/brands-manager/products/edit_composition/:id()",
    component: () => import("/app/pages/brands-manager/products/edit_composition/[id]/index.vue")
  },
  {
    name: "brands-manager-products-edit-id",
    path: "/brands-manager/products/edit/:id()",
    component: () => import("/app/pages/brands-manager/products/edit/[id]/index.vue")
  },
  {
    name: "brands-manager-schemes-edit-id",
    path: "/brands-manager/schemes/edit/:id()",
    component: () => import("/app/pages/brands-manager/schemes/edit/[id]/index.vue")
  },
  {
    name: "brands-manager-view-Head",
    path: "/brands-manager/view/Head",
    component: () => import("/app/pages/brands-manager/view/Head.vue")
  },
  {
    name: indexWz0NxZIN18Meta?.name,
    path: "/co2-generators/:id()/:id2()",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index.vue"),
    children: [
  {
    name: "co2-generators-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "co2-generators-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "co2-generators-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index/growers.vue")
  },
  {
    name: "co2-generators-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "co2-generators-id-id2-index",
    path: "",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index/index.vue")
  },
  {
    name: "co2-generators-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/co2-generators/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: indexcaUpJviLkSMeta?.name,
    path: "/co2-generators/:id()",
    component: () => import("/app/pages/co2-generators/[id]/index.vue"),
    children: [
  {
    name: "co2-generators-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/co2-generators/[id]/index/diaries.vue")
  },
  {
    name: "co2-generators-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/co2-generators/[id]/index/growers.vue")
  },
  {
    name: "co2-generators-id-index",
    path: "",
    component: () => import("/app/pages/co2-generators/[id]/index/index.vue")
  },
  {
    name: "co2-generators-id-index-products",
    path: "products",
    component: () => import("/app/pages/co2-generators/[id]/index/products.vue")
  },
  {
    name: "co2-generators-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/co2-generators/[id]/index/reviews.vue")
  }
]
  },
  {
    name: "co2-generators",
    path: "/co2-generators",
    component: () => import("/app/pages/co2-generators/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/app/pages/contact.vue")
  },
  {
    name: indexDBrtxvltsbMeta?.name,
    path: "/controllers/:id()/:id2()",
    component: () => import("/app/pages/controllers/[id]/[id2]/index.vue"),
    children: [
  {
    name: "controllers-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/controllers/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "controllers-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/controllers/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "controllers-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/controllers/[id]/[id2]/index/growers.vue")
  },
  {
    name: "controllers-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/controllers/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "controllers-id-id2-index",
    path: "",
    component: () => import("/app/pages/controllers/[id]/[id2]/index/index.vue")
  },
  {
    name: "controllers-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/controllers/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: indexm4I9ZG48ZBMeta?.name,
    path: "/controllers/:id()",
    component: () => import("/app/pages/controllers/[id]/index.vue"),
    children: [
  {
    name: "controllers-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/controllers/[id]/index/diaries.vue")
  },
  {
    name: "controllers-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/controllers/[id]/index/growers.vue")
  },
  {
    name: "controllers-id-index",
    path: "",
    component: () => import("/app/pages/controllers/[id]/index/index.vue")
  },
  {
    name: "controllers-id-index-products",
    path: "products",
    component: () => import("/app/pages/controllers/[id]/index/products.vue")
  },
  {
    name: "controllers-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/controllers/[id]/index/reviews.vue")
  }
]
  },
  {
    name: "controllers",
    path: "/controllers",
    component: () => import("/app/pages/controllers/index.vue")
  },
  {
    name: indexyuk4MFLE81Meta?.name,
    path: "/diaries/:id()",
    component: () => import("/app/pages/diaries/[id]/index.vue"),
    children: [
  {
    name: "diaries-id-index-edit-smoke-week",
    path: "edit/smoke/:week()",
    component: () => import("/app/pages/diaries/[id]/index/edit/smoke/[week].vue")
  },
  {
    name: "diaries-id-index-edit-week-week",
    path: "edit/week/:week()",
    component: () => import("/app/pages/diaries/[id]/index/edit/week/[week].vue")
  },
  {
    name: "diaries-id-index",
    path: "",
    component: () => import("/app/pages/diaries/[id]/index/index.vue")
  },
  {
    name: "diaries-id-index-week-week",
    path: "week/:week()",
    component: () => import("/app/pages/diaries/[id]/index/week/[week].vue")
  },
  {
    name: "diaries-id-index-weeks-week-edit-smoke",
    path: "weeks/:week()/edit-smoke",
    component: () => import("/app/pages/diaries/[id]/index/weeks/[week]/edit-smoke.vue")
  },
  {
    name: "diaries-id-index-weeks-week-edit",
    path: "weeks/:week()/edit",
    component: () => import("/app/pages/diaries/[id]/index/weeks/[week]/edit.vue")
  }
]
  },
  {
    name: "diaries-edit-id",
    path: "/diaries/edit/:id()",
    component: () => import("/app/pages/diaries/edit/[id].vue")
  },
  {
    name: indexgXW9wL9esHMeta?.name,
    path: "/drip-systems/:id()/:id2()",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index.vue"),
    children: [
  {
    name: "drip-systems-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "drip-systems-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "drip-systems-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index/growers.vue")
  },
  {
    name: "drip-systems-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "drip-systems-id-id2-index",
    path: "",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index/index.vue")
  },
  {
    name: "drip-systems-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/drip-systems/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: indexxZe0NfwJxEMeta?.name,
    path: "/drip-systems/:id()",
    component: () => import("/app/pages/drip-systems/[id]/index.vue"),
    children: [
  {
    name: "drip-systems-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/drip-systems/[id]/index/diaries.vue")
  },
  {
    name: "drip-systems-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/drip-systems/[id]/index/growers.vue")
  },
  {
    name: "drip-systems-id-index",
    path: "",
    component: () => import("/app/pages/drip-systems/[id]/index/index.vue")
  },
  {
    name: "drip-systems-id-index-products",
    path: "products",
    component: () => import("/app/pages/drip-systems/[id]/index/products.vue")
  },
  {
    name: "drip-systems-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/drip-systems/[id]/index/reviews.vue")
  }
]
  },
  {
    name: "drip-systems",
    path: "/drip-systems",
    component: () => import("/app/pages/drip-systems/index.vue")
  },
  {
    name: indexlsMMXLBkHVMeta?.name,
    path: "/equipment/:id()/:id2()",
    component: () => import("/app/pages/equipment/[id]/[id2]/index.vue"),
    children: [
  {
    name: "equipment-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/equipment/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "equipment-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/equipment/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "equipment-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/equipment/[id]/[id2]/index/growers.vue")
  },
  {
    name: "equipment-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/equipment/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "equipment-id-id2-index",
    path: "",
    component: () => import("/app/pages/equipment/[id]/[id2]/index/index.vue")
  },
  {
    name: "equipment-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/equipment/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: indexdqa3kGkFADMeta?.name,
    path: "/equipment/:id()",
    component: () => import("/app/pages/equipment/[id]/index.vue"),
    children: [
  {
    name: "equipment-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/equipment/[id]/index/diaries.vue")
  },
  {
    name: "equipment-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/equipment/[id]/index/growers.vue")
  },
  {
    name: "equipment-id-index",
    path: "",
    component: () => import("/app/pages/equipment/[id]/index/index.vue")
  },
  {
    name: "equipment-id-index-products",
    path: "products",
    component: () => import("/app/pages/equipment/[id]/index/products.vue")
  },
  {
    name: "equipment-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/equipment/[id]/index/reviews.vue")
  }
]
  },
  {
    name: "equipment",
    path: "/equipment",
    component: () => import("/app/pages/equipment/index.vue")
  },
  {
    name: "explore-tag",
    path: "/explore/:tag(.*)*",
    component: () => import("/app/pages/explore/[...tag].vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/app/pages/faq.vue")
  },
  {
    name: "giveaways-var",
    path: "/giveaways/:var()",
    component: () => import("/app/pages/giveaways/[var]/index.vue")
  },
  {
    name: "giveaways",
    path: "/giveaways",
    component: () => import("/app/pages/giveaways/index.vue")
  },
  {
    name: indexbRAha9LWHuMeta?.name,
    path: "/grow-boxes/:id()/:id2()",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index.vue"),
    children: [
  {
    name: "grow-boxes-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "grow-boxes-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "grow-boxes-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index/growers.vue")
  },
  {
    name: "grow-boxes-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "grow-boxes-id-id2-index",
    path: "",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index/index.vue")
  },
  {
    name: "grow-boxes-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grow-boxes/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: indexUyPRQpGA6ZMeta?.name,
    path: "/grow-boxes/:id()",
    component: () => import("/app/pages/grow-boxes/[id]/index.vue"),
    children: [
  {
    name: "grow-boxes-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grow-boxes/[id]/index/diaries.vue")
  },
  {
    name: "grow-boxes-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/grow-boxes/[id]/index/growers.vue")
  },
  {
    name: "grow-boxes-id-index",
    path: "",
    component: () => import("/app/pages/grow-boxes/[id]/index/index.vue")
  },
  {
    name: "grow-boxes-id-index-products",
    path: "products",
    component: () => import("/app/pages/grow-boxes/[id]/index/products.vue")
  },
  {
    name: "grow-boxes-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grow-boxes/[id]/index/reviews.vue")
  }
]
  },
  {
    name: "grow-boxes",
    path: "/grow-boxes",
    component: () => import("/app/pages/grow-boxes/index.vue")
  },
  {
    name: "grow-journals-strain",
    path: "/grow-journals/:strain(.*)*",
    component: () => import("/app/pages/grow-journals/[...strain].vue")
  },
  {
    name: "grow-journals",
    path: "/grow-journals",
    component: () => import("/app/pages/grow-journals/index.vue")
  },
  {
    name: indexuekBHNx8vQMeta?.name,
    path: "/grow-lights/:id()/:id2()",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index.vue"),
    children: [
  {
    name: "grow-lights-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "grow-lights-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "grow-lights-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index/growers.vue")
  },
  {
    name: "grow-lights-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "grow-lights-id-id2-index",
    path: "",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index/index.vue")
  },
  {
    name: "grow-lights-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grow-lights/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: index5PMeBs3PZhMeta?.name,
    path: "/grow-lights/:id()",
    component: () => import("/app/pages/grow-lights/[id]/index.vue"),
    children: [
  {
    name: "grow-lights-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grow-lights/[id]/index/diaries.vue")
  },
  {
    name: "grow-lights-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/grow-lights/[id]/index/growers.vue")
  },
  {
    name: "grow-lights-id-index",
    path: "",
    component: () => import("/app/pages/grow-lights/[id]/index/index.vue")
  },
  {
    name: "grow-lights-id-index-products",
    path: "products",
    component: () => import("/app/pages/grow-lights/[id]/index/products.vue")
  },
  {
    name: "grow-lights-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grow-lights/[id]/index/reviews.vue")
  }
]
  },
  {
    name: "grow-lights-index copy",
    path: "/grow-lights/index%20copy",
    component: () => import("/app/pages/grow-lights/index copy.vue")
  },
  {
    name: "grow-lights",
    path: "/grow-lights",
    component: () => import("/app/pages/grow-lights/index.vue")
  },
  {
    name: "grow-questions-tag",
    path: "/grow-questions/:tag(.*)*",
    component: () => import("/app/pages/grow-questions/[...tag].vue")
  },
  {
    name: "grow-questions-id",
    path: "/grow-questions/:id()",
    component: () => import("/app/pages/grow-questions/[id]/index.vue")
  },
  {
    name: "grow-questions-edit-id",
    path: "/grow-questions/edit/:id()",
    component: () => import("/app/pages/grow-questions/edit/[id].vue")
  },
  {
    name: indexSOswOoe42xMeta?.name,
    path: "/grow-tents/:id()/:id2()",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index.vue"),
    children: [
  {
    name: "grow-tents-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "grow-tents-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "grow-tents-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index/growers.vue")
  },
  {
    name: "grow-tents-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "grow-tents-id-id2-index",
    path: "",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index/index.vue")
  },
  {
    name: "grow-tents-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grow-tents/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: index5enWd4LLy6Meta?.name,
    path: "/grow-tents/:id()",
    component: () => import("/app/pages/grow-tents/[id]/index.vue"),
    children: [
  {
    name: "grow-tents-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grow-tents/[id]/index/diaries.vue")
  },
  {
    name: "grow-tents-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/grow-tents/[id]/index/growers.vue")
  },
  {
    name: "grow-tents-id-index",
    path: "",
    component: () => import("/app/pages/grow-tents/[id]/index/index.vue")
  },
  {
    name: "grow-tents-id-index-products",
    path: "products",
    component: () => import("/app/pages/grow-tents/[id]/index/products.vue")
  },
  {
    name: "grow-tents-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grow-tents/[id]/index/reviews.vue")
  }
]
  },
  {
    name: "grow-tents",
    path: "/grow-tents",
    component: () => import("/app/pages/grow-tents/index.vue")
  },
  {
    name: index4SqB1uWisQMeta?.name,
    path: "/grower/:id()",
    component: () => import("/app/pages/grower/[id]/index.vue"),
    children: [
  {
    name: "grower-id-index-activity",
    path: "activity",
    component: () => import("/app/pages/grower/[id]/index/activity.vue")
  },
  {
    name: "grower-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/grower/[id]/index/diaries.vue")
  },
  {
    name: "grower-id-index-followers",
    path: "followers",
    component: () => import("/app/pages/grower/[id]/index/followers.vue")
  },
  {
    name: "grower-id-index-following",
    path: "following",
    component: () => import("/app/pages/grower/[id]/index/following.vue")
  },
  {
    name: "grower-id-index",
    path: "",
    component: () => import("/app/pages/grower/[id]/index/index.vue")
  },
  {
    name: "grower-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/grower/[id]/index/reviews.vue")
  }
]
  },
  {
    name: indexUZ2tz6pmC2Meta?.name,
    path: "/grower/:id()/settings",
    component: () => import("/app/pages/grower/[id]/settings/index.vue"),
    children: [
  {
    name: "grower-id-settings-index-banned",
    path: "banned",
    component: () => import("/app/pages/grower/[id]/settings/index/banned.vue")
  },
  {
    name: "grower-id-settings-index-cookies",
    path: "cookies",
    component: () => import("/app/pages/grower/[id]/settings/index/cookies.vue")
  },
  {
    name: "grower-id-settings-index-delete",
    path: "delete",
    component: () => import("/app/pages/grower/[id]/settings/index/delete.vue")
  },
  {
    name: "grower-id-settings-index",
    path: "",
    component: () => import("/app/pages/grower/[id]/settings/index/index.vue")
  },
  {
    name: "grower-id-settings-index-password",
    path: "password",
    component: () => import("/app/pages/grower/[id]/settings/index/password.vue")
  },
  {
    name: "grower-id-settings-index-socials",
    path: "socials",
    component: () => import("/app/pages/grower/[id]/settings/index/socials.vue")
  }
]
  },
  {
    name: "growers",
    path: "/growers",
    component: () => import("/app/pages/growers/index.vue")
  },
  {
    name: indexmbNoCilz2QMeta?.name,
    path: "/hydroponic-systems/:id()/:id2()",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index.vue"),
    children: [
  {
    name: "hydroponic-systems-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "hydroponic-systems-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "hydroponic-systems-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index/growers.vue")
  },
  {
    name: "hydroponic-systems-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "hydroponic-systems-id-id2-index",
    path: "",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index/index.vue")
  },
  {
    name: "hydroponic-systems-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/hydroponic-systems/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: indexlvMAUuRMl1Meta?.name,
    path: "/hydroponic-systems/:id()",
    component: () => import("/app/pages/hydroponic-systems/[id]/index.vue"),
    children: [
  {
    name: "hydroponic-systems-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/hydroponic-systems/[id]/index/diaries.vue")
  },
  {
    name: "hydroponic-systems-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/hydroponic-systems/[id]/index/growers.vue")
  },
  {
    name: "hydroponic-systems-id-index",
    path: "",
    component: () => import("/app/pages/hydroponic-systems/[id]/index/index.vue")
  },
  {
    name: "hydroponic-systems-id-index-products",
    path: "products",
    component: () => import("/app/pages/hydroponic-systems/[id]/index/products.vue")
  },
  {
    name: "hydroponic-systems-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/hydroponic-systems/[id]/index/reviews.vue")
  }
]
  },
  {
    name: "hydroponic-systems",
    path: "/hydroponic-systems",
    component: () => import("/app/pages/hydroponic-systems/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "journal-var",
    path: "/journal/:var()",
    component: () => import("/app/pages/journal/[var]/index.vue")
  },
  {
    name: "journal-category-var",
    path: "/journal/category/:var()",
    component: () => import("/app/pages/journal/category/[var]/index.vue")
  },
  {
    name: "journal",
    path: "/journal",
    component: () => import("/app/pages/journal/index.vue")
  },
  {
    name: "journal-tags-var",
    path: "/journal/tags/:var()",
    component: () => import("/app/pages/journal/tags/[var]/index.vue")
  },
  {
    name: "journal-voting-var",
    path: "/journal/voting/:var()",
    component: () => import("/app/pages/journal/voting/[var]/index.vue")
  },
  {
    name: "journal-voting",
    path: "/journal/voting",
    component: () => import("/app/pages/journal/voting/index.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/app/pages/notifications/index.vue")
  },
  {
    name: indexmFPzrILEPCMeta?.name,
    path: "/nutrients/:id()/:id2()",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index.vue"),
    children: [
  {
    name: "nutrients-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "nutrients-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "nutrients-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index/growers.vue")
  },
  {
    name: "nutrients-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "nutrients-id-id2-index",
    path: "",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index/index.vue")
  },
  {
    name: "nutrients-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/nutrients/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: index2POX36WO4UMeta?.name,
    path: "/nutrients/:id()",
    component: () => import("/app/pages/nutrients/[id]/index.vue"),
    children: [
  {
    name: "nutrients-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/nutrients/[id]/index/diaries.vue")
  },
  {
    name: "nutrients-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/nutrients/[id]/index/growers.vue")
  },
  {
    name: "nutrients-id-index",
    path: "",
    component: () => import("/app/pages/nutrients/[id]/index/index.vue")
  },
  {
    name: "nutrients-id-index-products",
    path: "products",
    component: () => import("/app/pages/nutrients/[id]/index/products.vue")
  },
  {
    name: "nutrients-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/nutrients/[id]/index/reviews.vue")
  },
  {
    name: "nutrients-id-index-schemes",
    path: "schemes",
    component: () => import("/app/pages/nutrients/[id]/index/schemes.vue")
  }
]
  },
  {
    name: "nutrients",
    path: "/nutrients",
    component: () => import("/app/pages/nutrients/index.vue")
  },
  {
    name: "partner",
    path: "/partner",
    component: () => import("/app/pages/partner.vue")
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("/app/pages/policy.vue")
  },
  {
    name: "premium",
    path: "/premium",
    component: () => import("/app/pages/premium.vue")
  },
  {
    name: indexlbCkz15KfRMeta?.name,
    path: "/seedbank/:id()/:id2()",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index.vue"),
    children: [
  {
    name: "seedbank-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "seedbank-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "seedbank-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index/growers.vue")
  },
  {
    name: "seedbank-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "seedbank-id-id2-index",
    path: "",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index/index.vue")
  },
  {
    name: "seedbank-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/seedbank/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: indexKD40KTzJYyMeta?.name,
    path: "/seedbank/:id()",
    component: () => import("/app/pages/seedbank/[id]/index.vue"),
    children: [
  {
    name: "seedbank-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/seedbank/[id]/index/diaries.vue")
  },
  {
    name: "seedbank-id-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/seedbank/[id]/index/gallery.vue")
  },
  {
    name: "seedbank-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/seedbank/[id]/index/growers.vue")
  },
  {
    name: "seedbank-id-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/seedbank/[id]/index/harvests.vue")
  },
  {
    name: "seedbank-id-index",
    path: "",
    component: () => import("/app/pages/seedbank/[id]/index/index.vue")
  },
  {
    name: "seedbank-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/seedbank/[id]/index/reviews.vue")
  },
  {
    name: "seedbank-id-index-strains",
    path: "strains",
    component: () => import("/app/pages/seedbank/[id]/index/strains.vue")
  }
]
  },
  {
    name: "seedbank",
    path: "/seedbank",
    component: () => import("/app/pages/seedbank/index.vue")
  },
  {
    name: "shorts-id",
    path: "/shorts/:id()",
    component: () => import("/app/pages/shorts/[id]/index.vue")
  },
  {
    name: "shorts",
    path: "/shorts",
    component: () => import("/app/pages/shorts/index.vue")
  },
  {
    name: "st",
    path: "/st",
    component: () => import("/app/pages/st.vue")
  },
  {
    name: "strains-tree",
    path: "/strains-tree",
    component: () => import("/app/pages/strains-tree.vue")
  },
  {
    name: "strains-tag",
    path: "/strains/:tag(.*)*",
    component: () => import("/app/pages/strains/[...tag].vue")
  },
  {
    name: indexlI73DG2yXgMeta?.name,
    path: "/strains2/:id()",
    component: () => import("/app/pages/strains2/[id]/index.vue"),
    children: [
  {
    name: "strains2-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/strains2/[id]/index/diaries.vue")
  },
  {
    name: "strains2-id-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/strains2/[id]/index/gallery.vue")
  },
  {
    name: "strains2-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/strains2/[id]/index/growers.vue")
  },
  {
    name: "strains2-id-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/strains2/[id]/index/harvests.vue")
  },
  {
    name: "strains2-id-index",
    path: "",
    component: () => import("/app/pages/strains2/[id]/index/index.vue")
  },
  {
    name: "strains2-id-index-products",
    path: "products",
    component: () => import("/app/pages/strains2/[id]/index/products.vue")
  },
  {
    name: "strains2-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/strains2/[id]/index/reviews.vue")
  }
]
  },
  {
    name: "strains2-category-category-val",
    path: "/strains2/category/:category()/:val()",
    component: () => import("/app/pages/strains2/category/[category]/[val].vue")
  },
  {
    name: "strains2",
    path: "/strains2",
    component: () => import("/app/pages/strains2/index.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/app/pages/terms.vue")
  },
  {
    name: index02NvNpUxDfMeta?.name,
    path: "/ventilation-fans/:id()/:id2()",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index.vue"),
    children: [
  {
    name: "ventilation-fans-id-id2-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index/diaries.vue")
  },
  {
    name: "ventilation-fans-id-id2-index-gallery",
    path: "gallery",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index/gallery.vue")
  },
  {
    name: "ventilation-fans-id-id2-index-growers",
    path: "growers",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index/growers.vue")
  },
  {
    name: "ventilation-fans-id-id2-index-harvests",
    path: "harvests",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index/harvests.vue")
  },
  {
    name: "ventilation-fans-id-id2-index",
    path: "",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index/index.vue")
  },
  {
    name: "ventilation-fans-id-id2-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/ventilation-fans/[id]/[id2]/index/reviews.vue")
  }
]
  },
  {
    name: indexYF8ONQqKmEMeta?.name,
    path: "/ventilation-fans/:id()",
    component: () => import("/app/pages/ventilation-fans/[id]/index.vue"),
    children: [
  {
    name: "ventilation-fans-id-index-diaries",
    path: "diaries",
    component: () => import("/app/pages/ventilation-fans/[id]/index/diaries.vue")
  },
  {
    name: "ventilation-fans-id-index-growers",
    path: "growers",
    component: () => import("/app/pages/ventilation-fans/[id]/index/growers.vue")
  },
  {
    name: "ventilation-fans-id-index",
    path: "",
    component: () => import("/app/pages/ventilation-fans/[id]/index/index.vue")
  },
  {
    name: "ventilation-fans-id-index-products",
    path: "products",
    component: () => import("/app/pages/ventilation-fans/[id]/index/products.vue")
  },
  {
    name: "ventilation-fans-id-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/ventilation-fans/[id]/index/reviews.vue")
  }
]
  },
  {
    name: "ventilation-fans",
    path: "/ventilation-fans",
    component: () => import("/app/pages/ventilation-fans/index.vue")
  },
  {
    name: "video-id",
    path: "/video/:id()",
    meta: indexkpICEk8tfZMeta || {},
    component: () => import("/app/pages/video/[id]/index.vue")
  },
  {
    name: "widget",
    path: "/widget",
    component: () => import("/app/pages/widget.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/src/external/reviews/js/growdiaries.js",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/src/external/rating/js/growdiaries.js",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/seedbank/fastbuds/wedding-cheesecake",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/seedbank/kannabia/kritic-70-auto",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/seedbank/zkittlez",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/diaries/57665-royal-queen-seeds-quick-one-grow-journal-by-frisca01",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/diaries/52487-humboldt-seeds-bubba-cheese-auto-grow-journal-by-highndry",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/diaries/82626-stardawg-grapefruit-auto-grow-journal-by-vadibul",
    component: component_45stubnO9MU04yTU
  }
]
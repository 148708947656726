<template>
  
  <div class="pb">

    <div class="journal_page">


      <div
        v-if="journalItemData"
        class="page_journal_item">
      
        <UiBread
          :list="[
            {name: $t('journal_view_link_text'), link: '/journal'},
            {name: journalItemData?.title, journalItemData: journalItemData?.link},
          ]"
          position="left"
          /> 

        <div class="blog_item">

          <div v-if="journalItemData.is_promo" class="pr_label">Sponsored post</div>

          <div class="title">
            <h1>{{ journalItemData.title }}</h1>
          </div>

          <div class="tags">
            <i class="tags icon"></i>
            <NuxtLink v-for="(opt,ind) in journalItemData.items_tag" class="tag" :to="'/journal/tags/' + opt.link" :key="ind">{{ opt.name + (ind == journalItemData.items_tag.length - 1 ? '' : ', ') }}</NuxtLink>
          </div>

          <div v-if="journalItemData.item_author && journalItemData.item_author.id" class="ud">
            <div class="author">

              <div class="by">
                Created by 
              </div>
              <NuxtLink class="av" :to="journalItemData.item_author.link">
                <img loading="lazy" :src="journalItemData.item_author.avatar_small" :alt="journalItemData.item_author.name">
              </NuxtLink>
              <NuxtLink class="nm" :to="journalItemData.item_author.link">
                {{ journalItemData.item_author.name }}
              </NuxtLink>

            </div>

            <div class="com" v-if="journalItemData.cnt_comments">
              {{ journalItemData.cnt_comments }} {{ $plurals.get('comment', journalItemData.cnt_comments) }}
            </div>

            <div class="vvw" v-if="journalItemData.cnt_view && useAuth().getAccess('view_extra')">
              {{ journalItemData.cnt_view }} {{ $plurals.get('view', journalItemData.cnt_view) }}
            </div>

            <div class="published">
              {{ $t('journal_view_post_added') }} 
              {{ $dayjs.utc(journalItemData.view_date).format('D MMMM YYYY') }}
            </div>
          </div>

          <div v-html="journalItemData.text" class="body"></div>

          <br/>



          <div v-if="journalItemData.items_blog_related && journalItemData.items_blog_related.length" class="related">
            <h4 class="rtitle">{{ $t('journal_view_post_button_read_more') }}</h4>

            <BlogBoxItems 
              v-if="journalItemData?.items_blog_related?.length"
              :data="journalItemData.items_blog_related"
              class-list="community small_boxes"
              class-item="box_small"
              :columns="1"
              />

          </div>

          <br/>

          <div v-if="journalItemData.is_hidden_ads" class="">
            <NuxtLink class="" to="/contact">
              <div class="btn">{{ $t('main_menu_contact_us') }}</div>
            </NuxtLink>
          </div>

          <br/>

          <CommentWidget v-if="journalItemData && journalItemData.id"
            :title="$t('journal_view_post_comments')"
            :type="'blog'"
            :content="journalItemData.id"
            />

        </div>
      </div>


    </div>
  </div>

</template>



<script setup lang="ts">

import type { Blog }  from "@/types/blog"

import { watchArray } from '@vueuse/core'

const route = useRoute();
const { $api, $ga, $helper, $head } = useNuxtApp();
const journalLink = ref(route.params['var']);

const loadData = async function() {   
  const response = await $api.getJournal(journalLink.value)  
  return response;  
}


const { status: journalItemDataStatus,  data: journalItemData } = await useLazyAsyncData('journalItemData', async () => await loadData() as Blog)


useHead(useNuxtApp().$head.getJournal(journalItemData.value));

watchArray([journalItemData], async ([new_journalItemData], added, removed) => {
  
  useHead(useNuxtApp().$head.getJournal(journalItemData.value));

}, {deep: true})

onBeforeUnmount(() => {
  journalItemData.value = null;
})


const addImpression = function() {

  if(import.meta.server)
    return;

  useBlog().addImpression(journalItemData.value?.id);
  
}

addImpression();

watch(() => journalItemData.value, (newVal) => {
  if(newVal) {
    addImpression();
  }
});


</script>

<style>

.journal_page {
    max-width: 700px;
    /* margin: 2rem calc(59% - 350px) 2rem auto; */
    margin: 2rem auto;
}
 

.page_journal_item .bd{
  display: flex;
  flex-wrap: wrap; 
}
.page_journal_item .bd .lst{
  width: calc(100% - 2rem - 17rem);
  margin-right: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}  




/*  */
/*  */
/*  */
/*  */

 
.page_journal_item{
  margin-bottom: 25px; 
  padding-bottom: 22px;
}
.page_journal_item .blog_item{
  width: 100%;
}
.page_journal_item .ddate{
    margin-bottom: 10px;
    font-size: 0.71428571rem;

    line-height: 1;
    vertical-align: baseline;
    background-image: none;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    font-weight: bold;
}
.page_journal_item .label_new{
  display: inline-block;
  background-color: #68ae3c;
  color: white;
  margin-right: 7px;
  vertical-align: middle;
  margin-top: -4px;
}
.page_journal_item .title{    
    margin-top: 2rem;
}
.page_journal_item .title h1{
  font-size: 2.5rem!important;
  line-height: 2.9rem;
  font-family: 'Poppins', 'Work Sans', sans-serif;

}
.page_journal_item .title a{

    display: inline-block;
}
.page_journal_item .body{
  display: block;
}
.page_journal_item .body:hover{
  color: inherit;
}
.page_journal_item .body h2{
  font-size: 2.1rem!important;
  line-height: 2.3rem;
  /* font-family: 'Poppins'; */
  font-weight: 500;
  /* color: black!important; */
  margin-top: 2.5rem;
}

.page_journal_item .body h3{
  font-size: 1.9rem!important;
  line-height: 2rem;
  font-family: 'Poppins';
  font-weight: 500;
  color: black!important;
  margin-top: 2rem;
}
.page_journal_item .share_box, 
.page_journal_item .tags{
    margin-bottom: 10px;
 
}  
.page_journal_item .tags{
  font-weight: 500;
}  
.page_journal_item .body{
  margin-bottom: 10px;
  font-size: 1rem;
} 
.page_journal_item .body > p{
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.page_journal_item .body li{  
  margin-bottom: 0.5rem;
}
.page_journal_item .body li > span{  
  /* color: black; */
  /* line-height: 2.1rem; */
}
.page_journal_item .body b, 
.page_journal_item .body strong {
  font-weight: 500!important;
}
.page_journal_item .body img{
    max-width: 100%;
    height: auto;
    margin-left: calc(100%/2 * -1 + 100%/2);
}
.page_journal_item .body iframe{
    max-width: 100%;
}
.page_journal_item .body *:not(.embed) a,
.page_journal_item .body a:not(.report_item){
  color: #5bb523;
  font-weight: 500;
  text-decoration: underline;
}
.page_journal_item .body h1,
.page_journal_item .body h2,
.page_journal_item .body h4,
.page_journal_item .body h5,
.page_journal_item .body b{
    /* font-weight: bold; */
}
@container pb (max-width: 700px) {
  .page_journal_item .body li > span,
  .page_journal_item .body > p{
    line-height: 1.6rem;
  }
}
/* COMMENT */

#comments_post{
    max-width: 100%;
}
#comments_post .select_user_comm{

}
#comments_post .select_user_comm img{
        width: auto;
    height: auto;
    margin: 0;
    display: inline-block;
    margin-right: 5px;
}
#comments_post .select_user_comm .text{
    vertical-align: middle;
}
#comments_post .replies_loader{
    margin-left: 30px;
    color: #4183C4;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer;
}
#comments_post .replies .comment:last-child{
    border-bottom: 1px #dddede solid;
    padding-bottom: 12px;
}
#comments_post .box_form_reply{
    margin-left: 3.5em;
}
 
.settings_comments{
  margin-top: 1rem;
}


.page_journal_item .other{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-left: -2px;
}




@container pb (max-width: 700px) {
 
  .journal_page{
    padding: 0;
    margin: 0rem 0;
  }
  .page_journal_item .body{ 
    font-size: 1rem;
  }
  .page_journal_item .title h1 {
    font-size: 1.8rem!important;
    line-height: 2rem;
  }
  .page_journal_item .body h2{
    
    font-size: 1.3rem!important;
    line-height: 1.6rem;
  }
  .page_journal_item .body img {
    max-width: calc(100% + 4rem);
    height: auto;
    margin-left: -2rem;
  }

  .page_journal_item .other{
  flex-wrap: wrap;
  }
}


/* CAPTIONS */
 
.imgcap {
  color: #909090;
  font-style: italic;
  margin-top: -27px;
  border-top: 1px #d0d0d0 solid;
  font-size: 0.85rem;
}
@container pb (max-width: 700px) {
  
.imgcap {
  margin-top: -25px;
  font-size: 1.1rem;
}
}


/* SHARE */

.share{ 
  margin-right: auto; 
}
@container pb (max-width: 700px) {

  .share{ 
    margin-bottom: 1rem;
  }
}

/* RELATED */
.related{
  /* background-color: #f7f7f7; */
  border-radius: 5px;
  /* padding: 1.5rem 2rem; */
  /* border-top: 2px #7d7d7d solid; */
  margin-top: 2rem;
  margin-bottom: 0rem;
}
.related .rtitle{
  /* font-size: 1.4rem; */
  /* text-transform: uppercase; */
  margin-bottom: 1rem;
  /* color: #ababab; */
  font-size: 1.28rem;
}
.related .rlist{
  
}
.related .rlist .ritem{
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.related .rlist .ritem .rimg{
  width: 100px;
  height: 60px;
  margin-right: 1rem;
    flex-shrink: 0;
}
.related .rlist .ritem .rimg img{
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 5px; 
}
.related .rrelate{
  display: block;
  /* color: black; */
  /* font-weight: 500; */
  /* line-height: 1.6rem;
  font-size: 1.4rem; */
  /* text-decoration: underline; */
}
.related .rrelate span{
  border-bottom: 1px #68ae3c solid;
  line-height: 1.8rem;
}
.related .rrelate:not(:last-child){
  border-bottom: 1px #dededf solid;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

@container pb (max-width: 700px) {
 .related{
   border-radius: 0;
   /* width: calc(100% + 4rem); */
   /* margin-left: -2rem; */
 }
 .related .rrelate{ 
  /* line-height: 1.4rem;
  font-size: 1.2rem; */
 }
}
/*  */




/* COMMENTS */


.reviews_list{  
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Noto Sans,Ubuntu,Droid Sans,Helvetica Neue,sans-serif;
  font-size: 1.2rem!important;
}

.reviews_list .add_comment_buttons .button{    
  font-size: 1.2rem!important;
}
.reviews_list .date{
  font-weight: normal!important;
}

.reviews_list .comment_area{
  font-size: 1.2rem!important;
}

.reviews_list .author{
  font-size: 1.2rem!important;
}
.reviews_list .text{
  font-size: 1.2rem!important;
}
.reviews_list .comments_post{

}

@container pb (max-width: 700px) {

  .reviews_list{
    margin-left: -2rem;
    width: calc(100% + 4rem);
  }
  .reviews_list .comments_post{
    margin-left: 2rem;
    margin: 2rem;
  }
  .reviews_list .author{
    font-size: 1.2rem!important;
  }
  .reviews_list .text{
    font-size: 1.2rem!important;
  }
  .settings_comments{
    margin: 0 2rem;
    margin-top: 1rem;
  }

}








/* TABLES */

.blog_item .body table{
  /* display: block; */
  overflow: auto;
  max-width: 100%!important;
  /* border: 0px !important; */
  background-color: transparent!important;
  height: auto!important;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid rgba(34,36,38,.15);
  border: 0;
  border-spacing: 0;
border-collapse: collapse;
} 
.blog_item .body table td{
  padding: 0.6rem 1rem;
  border: 1px solid rgba(34,36,38,.1);
  /* border: 0; */
}
.blog_item .body table tr:nth-child(even):last-child td{ 
  border-bottom: 1px #eeeeee solid!important;
}
.blog_item .body table thead td{
  font-weight:500;
}
.blog_item .body table tbody tr:nth-child(even){
  background-color: #eeeeee0a;
}
.blog_item .body table tbody tr:nth-child(odd){
  background-color:#eeeeee8f;
}
.blog_item .body table td ul{
  padding-left: 18px;
  margin-left: 0;
  line-height: 1.4rem;
  margin: 0;
}
.blog_item .body table td ul ul{
  padding-left: 0px;}
/*  */

/* AUTHOR DATE */
.blog_item .ud{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 0.5rem;
}
 
.blog_item .ud > *:not(:last-child)::after{
  content: '·';
  margin-left: 0.5rem;
}
.blog_item .author{
    display: flex;
    align-items: center;
    /* margin-right: auto; */
}
.blog_item .author .by{
  margin-right: 0.5rem;
}
.blog_item .author .nm{}
.blog_item .author .av{
    width: 23px;
    margin-right: 0.5rem;
    height: 23px;
}
.blog_item .author .av img{
    width: 100%;
    border-radius: 100px;
}
 

.blog_item .published{
  /* margin-left: auto; */
}



/* CONTENT */


.blog_item .body .content_box{
  border-top: 1px var(--un-element-color-gray) solid;
  padding-top: 1rem;
}
.blog_item .body .content_text{
  list-style-type:none;
  padding-left: 1rem;
  margin-top: 0;
}
.blog_item .body .content_text li{
    margin: 0px 0;
    display: flex;
    align-items: center;
}
.blog_item .body .content_text li .num{
  opacity: 0.5;
  flex-shrink: 0;
  margin-right: 5px;
}
.blog_item .body .content_text li .num .bll{
  min-width:18px;
  display:inline-block;
}
.blog_item .body .content_text li a{
  color: var(--un-text-color);
  text-decoration:underline;
  flex-shrink: 1;
  width: auto;
  overflow: hidden;
  white-space: normal;
  cursor: pointer;
  font-weight: normal;
  text-decoration: none;
}
.content_box{
  
}
@container pb (max-width: 700px) {
  
}

/* SPONSORED */

.blog_item .pr_label{
  color: #6d6001;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.55rem;
  margin-bottom: -1rem;
  margin-top: 2rem;
  background-color: #dec400;
  padding: 3px 10px 1px 10px;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
}

@container pb (max-width: 700px) {
  .pr_label{
    margin-bottom: -1rem;
  }
}

/* POLL */

.drag_border{
  background-color: #f2f2f2;
  border-radius: 5px;
}

.drag_num{
  position: relative;
  left: -10px;
}

.page_journal_item .blog_item label{    
  font-weight: normal!important;
  font-size: 1.4rem!important;
}



</style>


<style>

/* embed_report  */

.report_item {
    width: 100%;
    display: table;
    position: relative;
    border-radius: 3px;
    margin-bottom: 2rem;
    border: none;
    border-radius: 0.28571429rem;
    box-shadow: 0px 1px 3px 0px #D4D4D5, 0px 0px 0px 1px #D4D4D5;
    color: #212121;
} 
.report_item:hover {
    color: #212121;
}

.report_item .cover {
    display: table-cell;
    width: 17%;
    vertical-align: top;
    border-radius: 5px 0px 0px 5px;
    position: relative;
}

.report_item .user_box {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 1.5rem;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
}
.report_item .user_box .avatar{
    width: 30px;
    margin-right: 10px;
    height: 30px;
} 
.report_item .user_box .avatar img{
    width: 100%;
    border-radius: 50px;
} 
.report_item .user_box .user_range{
    position: absolute;
    left: 33px;
    top: 13px;
}
.report_item .user_box .info{}
.report_item .user_box .info .uname{
    line-height: 1.4rem;
}
.report_item .user_box .info .status{
    font-size: 1rem;
    color: gray;
}

.report_item .award{
    color: #cccccc;
}
.report_item .award.gold{
    color: gold;
}
 
.report_item .cnt_update.cnt_update_follow {
    background-color: #db2828;
}
.report_item .cnt_update {
    background-color: #999999;
    padding: 4px 4px 3px 4px;
    color: white;
    border-radius: 20px;
    font-size: 0.7rem;
    min-width: 20px;
    display: inline-block;
    position: absolute;
    right: -9px;
    top: -9px;
    text-align: center;
    z-index: 1;
}
 
.report_item .cover img {
    width: 100% !important;
    margin: 0px;
    height: auto !important;
    display: block;
    border-radius: 5px 0px 0px 5px;
    /* vertical-align: middle; */ 
}

.report_item .content {
    display: table-cell;
    width: 63%;
    vertical-align: top;
}

.report_item .content .name span {
     position: absolute;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     width: 100%;
     display: inline-block;
}

.report_item .content .name {
    position: relative;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0.6rem 1.5rem 0rem 1.5rem;
    /* border-bottom: 1px gainsboro solid; */
    /* margin-bottom: 0.6rem; */
    min-height: 3.2rem;
}

.report_item .cover .label_stars{
        position: absolute;
    display: inline-block;
    background-color: #68ae3c;
    right: 11px;
    top: 11px;
    border-radius: 43px;
    color: white;
    font-size: 2rem;
    height: 33px;
    text-align: center;
    width: 33px;
    line-height: 2.3rem;
} 

.report_item .cover .label_stars.is_awards{
    background-color: rgba(255, 255, 255, 0.43);
    height: 40px;
    width: 40px;
}
.report_item .grow.is-awards{ 
    width: 100%;
    height: 100%;
}
.report_item .content .setup {

}

.report_item .content .param {
    padding-left: 1.5rem;
    padding-bottom: 0.4rem;
    /* padding-top: 0.3rem; */
    width: 100%;
    line-height: 1.5rem;
}

.report_item .content .param .title {
    font-weight: bold;
    display: inline-block;
    width: 6rem;
}

.report_item .content .param .items {
    display: inline-block;
    width: calc(100% - 7.5rem);
    overflow: hidden;
    vertical-align: middle;
    height: 1.6rem;
}

.report_item .content .param .items .item {
    display: inline-block;
    margin-right: 17px; 
}

.report_item .content .param .items .more_strains {
    display: none;
}
.report_item .content .param .items .item .bank_name {
    display: none;
}
.report_item .content .param .items .item .logo {
    width: auto;
    height: 1.4rem;
    vertical-align: text-bottom;
    margin-right: 0.4rem;
}

.report_item .content .week_list {
    display: inline-block;
    width: calc(100% - 7.5rem);
    overflow: hidden;
    vertical-align: middle;
    height: 26px;
}

.report_item .content .week_list .item {
    display: inline-block;
    width: 21px;
    border: 1px #e0e1e2 solid;
    text-align: center;
    color: #e0e1e2;
    border-radius: 3px;
    height: 22px;
    font-size: 0.9rem;
    margin-bottom: 10px;
    border-top: 3px #e0e1e2 solid;
}

.report_item .content .week_list .item.harvest {
    color: white;
    background-color: #e35149;
    width: auto;
    padding: 0.2rem 0.4rem 0.1rem 0.4rem;
    border: 0px;
}

.report_item .content .week_list .item.faza_0 {
    color: #505050;
    border: 1px #a2da7a solid;
    border-top: 3px #a2da7a solid;
}

.report_item .content .week_list .item.faza_1 {
    color: #505050;
    border: 1px #ff8855 solid;
    border-top: 3px #ff8855 solid;
}

.report_item .content .week_list .item.faza_2 {
    color: #505050;
    border: 1px #e35149 solid;
    border-top: 3px #e35149 solid;
}

.report_item .statistic {
    display: table-cell;
    width: 20%;
    vertical-align: top;
    background-color: #f4f4f4;
    border-radius: 0px 5px 5px 0px;
    vertical-align: middle; 
}

.report_item .statistic .rw b{
    font-weight: bold;
    color: #757575;
    border: 1px #757575 solid;
    border-radius: 50px; 
    padding: 1px 4px;
    font-size: 0.65rem;
    vertical-align: text-top;
    text-align: center;
}
.report_item .statistic .rw {
    padding: 0.4rem 1.5rem;
    color: #9c9c9c;
}

.report_item .statistic .user {
    padding: 0.4rem 1.5rem;
    position: relative;
}

.report_item .statistic .user .avatar {
    display: inline-block;
    width: 38px;
    vertical-align: baseline; /* padding: 0.4rem 1.5rem; */
}

.report_item .statistic .user .avatar img {
    border-radius: 50%; /* width: 100%; */
}


.report_item .statistic .user .user_range {
    float: left;
    font-size: 1.6rem;
    margin-top: 6px;
    margin-right: 15px;
    position: absolute;
    margin-left: -26px;
    bottom: 6px;
}


.report_item .statistic .user .info {
    display: inline-block;
    width: calc(100% - 47px);
    margin-left: 5px;
}

.report_item .statistic .user .info .name {
    /* font-weight: bold; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.report_item .statistic .user .info .status {
    color: #9c9c9c;
    font-size: 0.85rem;
    margin-top: -2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.report_item .stage{
    display: none;
}

.report_item .mobile_stage{
    display: none;
    padding: 0.3rem 0.5rem 0.3rem 0.3rem;
    margin-left: 1.5rem;
    border-radius: 3px;
    height: 31px;
    margin-top: 5px;
}



.report_item .stage_is_0 {
    border: 1px #a2da7a solid;
    color: #a2da7a;
}

.report_item .stage_is_1 {
    border: 1px #ff8855 solid;
    color: #ff8855;
}

.report_item .stage_is_2 {
    border: 1px #e35149 solid;
    color: #e35149;
}

 .report_item .mobile_stage .box {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-right: 3px;
}

.report_item .setup .ico_type {
    -webkit-filter: brightness(10);
    filter: brightness(10);
    width: 20px;
    height: 20px;
    background-size: 100%;
    background-repeat: no-repeat;
    display: inline-block;
    float: left;
    margin-right: 10px;
    -webkit-filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white) drop-shadow(1px -1px 0 white) drop-shadow(-1px 1px 0 white) drop-shadow(0px 0px 5px white);

}

.report_item .setup .ico_type.room_1 {
    background-image: url("/images/setup_diary/setup_diary_icons-01.svg")
}

.report_item .setup .ico_type.room_2 {
    background-image: url("/images/setup_diary/setup_diary_icons-01.svg")
}

.report_item .setup .ico_type.method {
    background-image: url("/images/setup_diary/setup_diary_icons-13.svg")
}

.report_item .setup .ico_type.light {
    background-image: url("/images/setup_diary/setup_diary_icons-06.svg")
}
  
 
@media only screen and (max-width: 600px) {

    .report_item .content .param.setup,
    .report_item .content .param.nutrients {
        display: none;
    }
    .report_item .mobile_stage{
        border: 0px;
        padding: 0px;

    }
    .report_item .mobile_stage .box:first-child{
        display: none;

    }
    .report_item .user_box .info .status{
        display:none;
    }
    .report_item .user_box .avatar img{
        margin: 0;
    }
    .report_item .user_box .user_range{
        left: 27px;
        top: 8px;
        width: 14px;
    }
    .report_item .user_box .avatar{
        width:20px;
        height: 20px;
    }
    .report_item .content .name{
        min-height: 2.6rem;
    }
}







/* ISSIDE */


.embed-diaries{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: fit-content;
  max-width: 100%;
}

.embed-diaries .diary{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.embed-diaries .diary .cover{
  width: 100px;
  aspect-ratio: 1/1;
  border-radius: 10px;
}
 

.embed-diaries .diary .title{
  margin: 0;
}
 



@media only screen and (max-width: 600px) {

  .embed-diaries  .diary .cover{
    width: 36%;
  }
}
 
 
/* embed_vote */


.blog_item .vit{
    background-color: white;
    box-shadow: 0px 0px 10px #00000040;
    display: flex;
    /* max-width: 180px; */
    flex-direction: row;
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    margin-right: auto;
    margin-left: 0;
    width: fit-content;
    align-items: center;
}
.blog_item .vit.voted{}
.blog_item .vit .nm{
    margin-right: 2rem;
}
.blog_item .vit .btn{
    display: flex;
    margin-left: auto;
    border: 1px #68ae3c solid;
    border-radius: 5px;
    /* padding: 3px 7px; */
}
.blog_item .vit .btn .cnt{
    padding: 3px 7px;
    color: #68ae3c;
    border-right: 1px #68ae3c solid;
}
.blog_item .vit .btn .tx{
    padding: 3px 13px;
    color: #68ae3c;
    cursor:pointer;
}
.blog_item .vit.voted .btn .tx,
.blog_item .vit .btn .tx:hover{

    color: #ffffff;
    background-color: #68ae3c;
}



/* EMBED POST */

.embed_post {
  /* padding: 0px 4rem; */
  display: flex;
  flex-direction: row;
  margin: 2rem 0px 2rem 0rem;
  /* border-left: 2px #d0d0d0 dashed; */
  /* border-radius: 5px; */
  align-items: center;
}

.embed_post  .name .title {
color: gray;
width: 100%;
margin-top: 0;
}


.embed_post .photo {
  margin-right: 2.5rem;
}

.embed_post .photo>a {
  display: block;
}

.embed_post .photo>a>img {
  margin: 0px !important;
  max-width: 80px!important;
  border-radius: 3px;
  height: 80px;
  object-fit: cover;
}

.embed_post .name {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: -0.5rem;
}

.embed_post .name>a {
  font-weight: 500!important;
  width: 100%!important;
  border-bottom: 0!important;
  display: block!important;
  line-height: normal;
  text-decoration: none!important;
  color: black!important;
}




@media only screen and (max-width: 500px) {

  .embed_post .photo>a>img {
    margin: 0px !important;
    width: 70px;
    max-width: 250px;
    height: 70px;
  }
  .embed_post {
    /* padding: 0px 20px; */
    display: flex;
    /* flex-direction: column; */
  }
  .embed_post .photo {
    margin-right: 1rem;
  }

}

/*  embed_product */


 
.embed_product{
  display: flex;
  align-items: center;
  max-width: 600px;
}

.embed_product .photo{
  max-width: 180px;
  flex-shrink: 0;
  margin-right: 2rem;
}
.embed_product .photo img{
  width: 100%;
  margin-left: 0;
}
.embed_product .info{}
.embed_product .info .name{
  font-size: 1.3rem;
  color: black!important;
  font-weight: 600;
}
.embed_product .info .brand{
  color: gray;
  font-size: 1.2rem;
}
.embed_product .info .brand a{
  color: gray;
}
.embed_product .info .ship{
  cursor: pointer;
  /* font-weight: 500; */
  color: #2589e0;
  font-size: 1.2rem;
}
.embed_product .info .desc{
  margin: 0.5rem 0;
}
.embed_product .info .desc .params{
    font-size: 1.2rem;
}
.embed_product .info .desc .params .param_item{
    display: flex;
    align-items: center;
}
.embed_product .info .desc .params .param_item .param_title{
    margin-right: 10px;
    /* font-weight: normal; */
}
.embed_product .info .desc .params .param_item .icn_par{
    margin-right: 10px;
    min-width: 16px;
    display: inline-block;
    flex-shrink: 0;
}
.embed_product .info .desc .params .param_item .param_vl{}
.embed_product .info .opt{

    font-size: 14px;
    margin-right: 1rem;
}
.embed_product .info .opt select{
    padding: 3px;
    background-color: #eaeaea;
    border-radius: 3px;
    font-weight: 500; 
}
.embed_product .info .price{
  display: flex;
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.embed_product .info .price .old{
  color: gray;
  margin-right: 0.6rem;
  text-decoration: line-through;
}
.embed_product .info .price .current{
  color: #e00000;
}
.embed_product .info .rate{}
.embed_product .info .action{
  display: flex;
  align-items: flex-start;
  margin-top: 0.5rem;
}
.embed_product .info .action .add_to_cart{
  background-color: orange;
  border-radius: 3px;
  padding: 5px 15px;
  text-align: center;
  /* font-weight: 500; */
  color: black;
  cursor: pointer;
  position: relative;
  font-size: 1.2rem;
}
.embed_product .info .action .add_to_cart .icon{}
.embed_product .info .action .buy_now{
  background-color: #da5e07;
  border-radius: 3px;
  padding: 5px 15px;
  text-align: center;
  /* font-weight: 500; */
  margin-left: 0.7rem;
  color: black;
  cursor: pointer;
  position: relative;
  font-size: 1.2rem;
}
.embed_product .info .action .buy_now .icon{}

/*  */

.pprs.multi{ 
  display: flex;
  flex-wrap: wrap;
}
.pprs.multi.brand{ 
  justify-content: flex-start;  
  height: 240px;
  overflow: hidden;
}
.pprs.multi .ppi{ 
  margin: 0 1rem 2rem 1rem;
}
.pprs.multi .ppi .desc{ 
  display: none;
}
.pprs.multi .ppi .info .price{
  margin-top: 1rem;
  flex-direction: column;
}
.pprs.multi .ppi .action{
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.pprs.multi .ppi .action .buy_now{
  margin-left: 0;
  margin-top: 0.5rem;
} 
.pprs.multi .ppi .info .opt{
  margin-right: 100%;
  margin-bottom: 0.5rem;
}
/*  */

.pprs.single .ppi{
  max-width: 100%;
}

.pprs.single .ppi .desc{
 
}

.pprs.single .ppi .desc .abt{
 /* overflow-y: hidden; */
 /* max-height: 79px; */
 margin-bottom: 0.5rem;
 /* border: 1px solid #000; */
 /* padding: 5px; */
 display: block;
 display: -webkit-box;
 /* max-width: 400px; */
 /* font-size: 20px; */
 -webkit-line-clamp: 3;
 -webkit-box-orient: vertical;
 overflow: hidden;
 text-overflow: ellipsis;
}
.pprs.single .ppi .desc .abt p{
  margin:0;
}
.pprs.single .ppi .info .desc .params{
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
  height: 24px;
}
.embed_product .info .desc .params .param_item{
  margin-right: 2rem;
}
@container pb (max-width: 700px) {
  .embed_product{ 
    width: 100%;
  }
  .embed_product .photo{
    max-width: 104px;
    margin-right: 1rem;
  }
  .embed_product .info .desc{
    display: none;
  }
  .embed_product .info .price{
    margin-top: 0.5rem;
    font-size: inherit;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
  }
  .embed_product .info .opt{
    margin-right: 100%;
    margin-bottom: 5px;
  }
  .embed_product .info .action {
    /* flex-wrap: wrap; */
  }
  .embed_product .info .action .icon{
    display: none;
  }
  .embed_product .info .action > div{
    padding: 5px 9px!important;
  }

  /* multi */
  .pprs.multi .ppi .info .price{
    align-items: flex-start;
  }
  /*  */
}

@media (max-width: 350px) {
  .embed_product { 
    align-items: flex-start;
  }
  .embed_product .photo {
    max-width: 64px; 
  }
}





/* COUNTRY MODAL */

#select_country_modal_content{

}

#select_country_modal_content .country_list{

    display: flex;
    flex-flow: wrap;
}

#select_country_modal_content .country_list .country_item{
    width: 33%;
    padding: 0.3rem 1rem;
    cursor: pointer;
}
#select_country_modal_content .country_list .country_item:hover{
    background-color: #e0e1e2;
}

/*  */






</style>
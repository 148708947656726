<template>


  <div class="summary">

    <!-- <div class="summary__title">
      <h2>Advice from assistant</h2>
    </div> -->


    <div class="dot-ttl">
        Grow Assistant
    </div>
  

    <img src="/images/assistant.svg" alt="Assistant" class="summary__image">
    <div v-if="requested && adviceData" class="summary__content">
      <AssistantProblem
        v-if="props.type === 'problem'"
        :data="adviceData.result"
      />
      <AssistantDiary
        v-if="props.type === 'diary'"
        :data="adviceData.result"
      />
    </div>
    <div v-else class="summary__content_req">    
      <UiButton
        :name="'Request Assistant Advice'"
        :type="'primary wide'"
        @click="loadData"
        :is-loading="isLoding"
      />      
    </div>

  </div>


</template>

<script setup>

const { $api } = useNuxtApp()

const props = defineProps({
  content: {
    type: Number,
    // required: true
  },
  type: {
    type: String,
    // required: false
  }, 
})

const requested = ref(false);
const adviceData = ref(null);
const isLoding = ref(false);


const loadData = async function() {
  requested.value = true;
  isLoding.value = true;
  let url = '';
  
  if(props.type === 'problem'){
    url = '/ai/problems/' + props.content;
  }
  if(props.type === 'diary'){
    url = '/ai/diaries/' + props.content;
  }
  if(props.type === 'brand'){
    url = '/ai/brands/' + props.content;
  }

  if(!url){
    return null;
  }
  adviceData.value = await $api.getUrl(url)  

  isLoding.value = false;

}

// const { pending: isLoading,  data: adviceData } = await useLazyAsyncData('adviceData' + props.content, async () => await loadData())



</script>

<style scoped>

.summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3rem auto;
  max-width: 500px;
  width: 100%;
}

.summary__image{
  max-width: 300px;
}

.summary__content_req{
  margin-top: -2rem;
  display: flex;
}


</style>
<template>

  <div v-if="data && data.role_name && data.role_name!='other_b2b'" class="accounts_content">

      <div v-if="data.role_name !== 'manager'" class="create_container">

        <div class="input_item">
          <ClientOnly>
          <n-select
            v-model:value="selected"
            filterable
            :placeholder="$t('brand_manager_view_accounts_type_username')"
            :options="data_search"
            :loading="isFetching"
            clearable
            remote
            @search="searchAccounts"
            value-field="id"
            label-field="name"
          />
        </ClientOnly>
        </div>

        <div class="button_item">
          <UiButton
            @click="addAccount()"
            :name="$t('brand_manager_view_accounts_add_manager_button')"
            />
        </div>

      </div>

      <div style="clear:both">&nbsp;</div>

      <n-table :single-column="false" :single-line="true" class="accounts_table">
      
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th></th>
          </tr>
        </thead>
  
        <tr v-for="item in data_accounts">

          <td class="nm">
             <a class="item user_ava" :href="'/grower/' + item.name">
               <img class="avatar_user ui avatar image" :src="item.avatar_little" alt="" data-not-lazy>
             </a>
             <a :href="'/grower/' + item.name">
               {{ item.name }}
             </a>
          </td>

          <td class="ac">
            {{ item.type == 'owner' ? $t('brand_manager_view_accounts_owner') : $t('brand_manager_view_accounts_manager') }}
          </td>

          <td class="rl">
            <UiButton
              :ico="'icon-remove'"
              :type="'red'"
              v-if="data.role_name !== 'manager' && item.type == 'manager'"
              @click="removeAccount(item.u_id)"
              />
          </td>

        </tr>
      
      </n-table>

    </div>
  
</template>

<script setup>

import { NButton, NSelect, NTable, useMessage, NMessageProvider } from 'naive-ui'
import { useI18n } from "vue-i18n"
import debounce from 'lodash/debounce'

const {$api} = useNuxtApp()
const { t } = useI18n()
const message = useMessage()

const isFetching = ref(false)
const selected = ref(null)
const data_search = ref([])

const props = defineProps({
  data: {
    type: Object    
  }
});


const bank_info = ref(props.data.bank_info);

const searchAccounts = (query) => {
  if(!query.length || isFetching.value){
      data_search.value = []
      return []
  }
  isFetching.value = true;
  
  $api.getB2BSearchAccounts(query).then(res => {
    if(res){
      data_search.value = res;
      isFetching.value = false;
      return data_search;
    }
  })
}


const addAccount = () => {
  if(!selected.value){
    return
  }
  $api.postB2BAddAccount({
    brand_id: bank_info.value.id,
    user_id: parseInt(selected.value)
  }).then(res => {
    loadAccounts();
  }).catch(function (error) {
     message.error(t('brand_manager_view_generic_error'));
  });
}

const removeAccount = (id) => {
  if(!id){
    return
  }
  $api.postB2BRemoveAccount({
    brand_id: bank_info.value.id,
    remove_id: parseInt(id)
  }).then(res => {
    loadAccounts();
  }).catch(function (error) {
     message.error(t('brand_manager_view_generic_error'));
  });
}



const loadAccounts = async () => {
  const response = await $api.getB2BInfo();
  data_accounts.value = response.items_account
  return response.items_account;
}

const { pending: is_loading,  data: data_accounts } = await useLazyAsyncData('data_accounts', async () => await loadAccounts())


/*  
export default { 
  props: {
    data: {
      type: Object
    }
  },
  components: {
    NButton,
    NSelect,
    NTable
  },
  data () {
    return {
      data_accounts: [],
      data_search : [],
      selected: null,
      isFetching: false      
    }
  },
  created() {  
    this.loadAccounts();  
  },
  methods: {   
    async loadAccounts(){
      let res = await this.$api.getB2BInfo();
      if(res) 
        this.data_accounts = res.items_account;
    },
    async searchAccounts(query){
        if(!query.length){
            this.data_search = []
            return
        }
        if(this.isFetching) return;
        this.isFetching = true;
        let res = await this.$api.getB2BSearchAccounts(query);
        if(res){ 
          this.data_search = res;
          this.isFetching = false;
        }
    },
    async addAccount(){
      if(!this.selected){
        return
      }
      let res = await this.$api.postB2BAddAccount({
        brand_id: this.data.bank_info.id,
        user_id: parseInt(this.selected)
      });
      if(res && res == 'success')
        this.loadAccounts();
    },
    async removeAccount(id){
      if(!id){
        return
      }
      let res = await this.$api.postB2BRemoveAccount({
        bank_id: this.data.bank_info.id,
        remove_id: parseInt(id)
      });
      if(res && res == 'success')
        this.loadAccounts();
    }
  }
}*/
</script>

<style scoped>

/**/
 
</style>

<style>
 
/* accounts */

.accounts_content{

}
.accounts_content .input_item{
  width: 340px;
  margin-right: 10px;
}
.accounts_content .create_container{
    display: flex;
    align-items: center;
}

.accounts_content .button_item{
  width: 160px;
}

.accounts_content table td .user_ava img{
  width: 28px;
  height: 28px;
  margin-right: 4px;
  float: left;
  border-radius: 100%;
}
.accounts_content table td.nm a{
  vertical-align: middle;
}
.accounts_content table td.rl{
  width: 150px;
  vertical-align: middle;
  text-align: right;
}
.accounts_content table td.ac{
  width: 50px;
}


@media (max-width: 768px) {
  .accounts_content .input_item{
    width: 160px;
    margin-right: 5px;
  }
  .accounts_content table td.user_ava{
    display: none;
  }
  .accounts_content table td.rl{
    width: auto;
  }
}

</style>
<template>
  <div :class="['power', props.class]">
    <!-- <label class="power__label">{{ props.placeholder }}</label> -->

    <div class="btns bl" @click="decrement"><i class="icon-minus"/></div>

    <input v-bind="$attrs" type="text" v-model="model" :step="props.step" :placeholder="props.placeholder" />

    <div class="btns br" @click="increment"><i class="icon-plus"/></div>
  </div>
</template>

<script setup lang="ts">
import { defineModel, defineProps } from 'vue'

interface Props {
  max: number;
  min: number;
  placeholder?: string;
  step: number;
  class: string;
}

const model = defineModel<any>()
const props = defineProps<Props>()
const emits = defineEmits([
  'change'
])

watch(() => model, () => {
  let value = parseInt(model.value)
  if (isNaN(value)) {
    model.value = ''
    return
  }
  model.value =  value
  emits('change')
}, { deep: true })

const increment = () => {
  // we need to parse string to number as we receive text, not a number
  model.value = model.value || 0
  const modelValue = parseInt(model.value)

  emits('change')

  if (modelValue + props.step > props.max) {
    model.value = props.max
    return
  }

  if (modelValue + props.step < props.min) {
    model.value = props.min
    return
  }

  if (modelValue < props.max) {
    model.value = modelValue + props.step
  }
}

const decrement = () => {
  // we need to parse string to number as we receive text, not a number
  model.value = model.value || 0
  const modelValue = parseInt(model.value)

  emits('change')

  if (modelValue - props.step < props.min) {
    model.value = props.min
    return
  }

  if (modelValue - props.step > props.max) {
    model.value = props.max
    return
  }

  if (modelValue > props.min) {
    model.value = modelValue - props.step
  }
}

</script>

<style scoped>
.frm .back {
  cursor: pointer;
  flex-shrink: 0;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.frm .power_type {
  width:100%;
  color: var(--un-text-color);
  font-weight: bold;
}

@media (max-width: 768px) {
  .frm{
    height: calc(100dvh - 140px);
  }

  .frm .list {
    max-height: calc(100% - 70px);
  }

  .frm.lamp .list {
    max-height: calc(100% - 100px);
  }
}

/* filter */
.frm .filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 10px;
  gap: 10px;
}

/* filter power */
.power {
  display: flex;
  height: 38px;
  position: relative;
  width: calc((100%  / 3) - 7px);
}
.power .btns {
  background-color: var(--un-primary-back-color);
  color: white;
  text-align: center;
  line-height: 3rem;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.power.disabled .btns {
  background-color: #f1f1f1;
  color: #494949;
  width: 40px;
}
.power .btns * {
  margin-right: 0;
  cursor: pointer;
}
.power .btns *:hover {
  opacity: 0.8;
}
.power input {
  padding: 10px;
  text-align: center;
  width: calc(100% - 80px);
  /* max-width: 100px; */
}
.power .btns.bl {
  border-radius: 33px 0 0 33px;
}
.power .btns.br {
  border-radius: 0 33px 33px 0;
}

.power__label {
  font-size: .8em;
  padding: 0 0 .2em;
  opacity: .7;
  bottom: 100%;
  left: 0;
  position: absolute;
}

@media (max-width: 768px) {
  .power{
    width: 100%;
    margin-right: 0;
  }
  .power input{
    max-width: none;
  }
}


/* wide */


.power.wide {
  width: 100%;
}

.power.wide input {
  width: calc(100% - 80px);

}

</style>
<template>
<!-- {{ selected }} -->
  <div class="radb">    

    <div 
      v-for="(item, itemKey) in DiaryTypeMethodShortTr"
      :key="itemKey"
      :class="'ritem ' + (isSelected(itemKey) ? ' checked' : '')"
      @click="handleToggle(itemKey)"
      >
      {{ $t(item) }}
    </div>

 

    <template 
      v-for="(item, itemKey) in props.selected" 
      :key="itemKey"
    >
        
      <div       
        v-if="item.method_id < 0 && !item.is_remove"
        class="ritem checked"
        @click="handleRemoveCustom(itemKey)"
        >
        {{ item.custom }}
      </div>

    </template>
      
    
    <div class="custom_form">
      <input type="text" v-model="custom_method" placeholder="Add method" @keypress.enter="handleAddCustom">
      <i class="icon-plus" @click="handleAddCustom"></i>
    </div>
  
  </div>
  
</template>


<script setup lang="ts">


import type { WeekMethod }  from "@/types/diary"
import { DiaryTypeMethod, DiaryTypeMethodShortTr }  from "@/types/diary"

var custom_method = ref('');

const emits = defineEmits(['add', 'remove'])


interface PageProps {
  selected: WeekMethod[]
}

const props = defineProps<PageProps>();

const handleAddCustom = () => {
  if(custom_method.value){        
    emits('add', -1, custom_method.value)
    custom_method.value = '';
  }
}

const handleRemoveCustom = function(itemKey: number){      
  emits('remove', -1, itemKey)
}

const isSelected = function(method_id: number){
  var selected = false;
  for(var s in props.selected){
    if(props.selected[s].method_id == method_id && !props.selected[s].is_remove){                     
      selected = true;
    }
  }
  return selected;
}


const handleToggle = function(method_id: number){
  method_id = parseInt(method_id.toString())
  if(isSelected(method_id)){
    emits('remove', method_id)
  }else{
    emits('add', method_id)
  }
}



</script>
 


<style scoped>
   
.radb{
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
}
.custom_form{
  width: 100%;
  display: flex;
  justify-content: center;
}
.custom_form input{
  max-width: 300px;  
}
.custom_form i{
    background-color: var(--un-primary-back-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    border-radius: 5px;
    margin-left: -40px;
    cursor: pointer;
}

.ritem {
  padding: 10px 13px;
  background-color: var(--un-background-color-gray);
  border-radius: 53px;
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  color: var(--un-text-color);
  
}

.ritem:hover {
  background-color: var(--un-background-color-gray-light);
}

.ritem.checked {
  background-color: var(--un-primary-back-color);
  color: white;
}

</style>
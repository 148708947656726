<template>

  <div :class="props.classList">

    <div class="ptl" v-if="props.title">{{ props.title }} 
      <NuxtLink v-if="props.link" class="ptla" :to="props.link">{{ $t('universal_see_more') }}</NuxtLink>
    </div>

    <div class="list">
      <BlogBox v-for="(opt,ind) in props.data" 
        :key="ind"
        :data="opt"
        :columns="props.columns"
      />
    </div>

  </div>

</template>

<script setup lang="ts">

import type { Blog }  from "@/types/blog"

interface Props {
  data: Blog[],
  classList: string,
  classItem: string,
  title?: string,
  link?: string,
  columns?: number
}

const props = withDefaults(defineProps<Props>(), {
  columns: 2
})
   
</script>

<style scoped>
 
/* LATEST */
 
.journal .ptl{
  width: 100%;
  /* text-transform: uppercase; */
  margin-bottom: 1.2rem;
  font-size: 1.1rem;
  font-weight: bold;
}
.journal .ptla{
text-transform: initial;
color: darkgray;
text-decoration: underline;
font-size: 0.9rem;
margin-left: 7px;
display: inline-block;
cursor: pointer;
font-weight: normal;
}

.list{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

 
/*  */
 
  
 

</style>

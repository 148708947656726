<template>
  <div class="pb">
  <div class="journal">

    <div class="posts">


      <BlogBoxItems 
        v-if="journalsData?.items_blog?.filter(b => {return b.category === 'update'}).length"
        :data="journalsData.items_blog.filter(b => {return b.category === 'update'}).slice(0,4)"
        :title="$t('journal_view_updates')"
        link="/journal/category/update"
        class-list="updates small_boxes"
        class-item="box_small"
      />
      
      <BlogBoxItems 
        v-if="journalsData?.items_blog?.filter(b => {return b.category === 'latests'}).length"
        :data="journalsData?.items_blog.filter(b => {return b.category === 'latests'}).slice(0,6)"
        :title="$t('journal_view_latest_posts')"
        link="/journal/category/latests"
        class-list="latests small_boxes"
        class-item="box_small"
      />

      <BlogBoxItems  
        v-if="journalsData?.items_blog?.filter(b => {return b.category === 'top'}).length"
        :data="journalsData.items_blog.filter(b => {return b.category === 'top'}).slice(0,3)"
        :title="$t('journal_view_popular')"
        link="/journal/category/popular"
        class-list="popular small_boxes"
        class-item="box_small"
      />

      <BlogBoxItems 
        v-if="journalsData?.items_blog?.filter(b => {return b.category === 'howto'}).length"
        :data="journalsData.items_blog.filter(b => {return b.category === 'howto'}).slice(0,3)"
        :title="$t('journal_view_grow_guide')"
        link="/journal/category/grow-guide"
        class-list="howto small_boxes"
        class-item="box_small"
      />

      <BlogBoxItems 
        v-if="journalsData?.items_blog?.filter(b => {return b.category === 'interview'}).length"
        :data="journalsData.items_blog.filter(b => {return b.category === 'interview'}).slice(0,6)"
        :title="$t('journal_view_interview')"
        link="/journal/category/interview"
        class-list="interview small_boxes"
        class-item="box_small"
      />

      <BlogBoxItems 
        v-if="journalsData?.items_blog?.filter(b => {return b.category === 'community'}).length"
        :data="journalsData.items_blog.filter(b => {return b.category === 'community'}).slice(0,3)"
        :title="$t('journal_view_community')"
        link="/journal/category/community"
        class-list="community small_boxes"
        class-item="box_small"
      />

      <ClientOnly>     
                    
        <InfinityScroll 
          v-if="journalsData?.items_blog?.length >= limit"
          :is-loading="journalsDataStatus === 'loading'"
          ref="elInfinityScroll"/>

      </ClientOnly> 

    </div>
     

  </div>
  </div>
</template>



<script setup lang="ts">

import type { Blog }  from "@/types/blog"

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const start = ref(0)
const limit = ref(20)
const isEnded = ref(false)
const {$api, $ga} = useNuxtApp()
const elInfinityScroll = ref(null)

const clearPagination = function(){     
  start.value = 0;  
}

const loadData = async function() {     
  const response = await $api.getJournals()  
  return response;  
}

interface JournalData {
  items_blog: Blog[];
}

const { status: journalsDataStatus,  data: journalsData } = await useLazyAsyncData('journalsData', async () => await loadData() as JournalData)


watchArray([start], async ([new_start], added, removed) => {

  if(new_start == 0){
    journalsData.value.items_blog = [];
    isEnded.value = false;
  }
  journalsDataStatus.value = 'loading';
  var dt = await loadData();

    
  if(dt.length == 0)
    isEnded.value = true;  

  journalsData.value.items_blog = [...journalsData.value.items_blog, ...dt.items_blog]

  journalsDataStatus.value = 'success';

  $adv.apply();

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!journalsDataStatus.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)


useHead(useNuxtApp().$head.getJournals());


 

</script>





<style scoped>

/* @import '@/assets/css/journal-list.css'; */

</style>

<template>
  <Teleport to="#app">
    <UiModal
      :title="titleModal"
      primary-button-name="Add"
      close-button-name="Cancel"
      :width="'100%'"
      :max-width="'500px'"
      :height="'fit-content'"
      :is-auto-height="true"
      :is-auto-height-min-mobile="true"
      @primary="chooseItem"
      @close="close"
    >


      <div :class="'frm ' + cat">
        <div class="search">
          <img v-if="props.prechoosed?.avatar" data-not-lazy :src="props.prechoosed.avatar" class="logo_selected" :alt="props.brand_name">

          <div class="inpvl">

            <UiInput
              :text="keyword"
              :placeholder="inputPlaceholder"
              :focus-on-mount="true"
              type="text"
              @change:text="keyword = $event"
              @keyup.enter="chooseItem"
              />

          </div>

        </div>

        <div v-if="cat === ProductCategoryType.Lamp && props.type == 'product'" class="filter">

          <div class="gs">

            <UiInputNumber
              :max="100000"
              :min="1"
              class="wide"
              :placeholder="$t('watt')"
              :step="10"
              v-model="filters.power"
              @change="onChangeLampPower"
            />
            <div class="lb">
              Wattage of the bulb. Determines brightness and energy use. Look for the wattage label on the bulb.
            </div>

          </div>

          <!-- <div class="types_wrap">
            <div class="types_wrap__label"> {{ $t('type') }}</div>

            <div class="types">
              <div
                v-for="(item, index) in allProductsLists.lampTypeList"
                :key="index"
                :class="'it' + (filters?.type == index+1 ? ' active ' : '')"
                @click="chooseCategory('lampTypeList', item, index)"
              >
                {{ $t(item.name) }}
              </div>
            </div>
          </div> -->


          <DiaryEditSelectRadioImgBox
            @choose="chooseLampType"
            :list="ProductLampTypeInfo"
            :selected="filters?.type"
          />


        </div>
  
 
      </div>
    </UiModal>
  </Teleport>
</template>

<script setup lang="ts">

import { ProductCategoryType, ProductLampFaza, ProductLampTypeInfo }  from "@/types/product";
  
const emits = defineEmits(['close', 'choose'])
const { t } = useI18n()
const { $api, $constants, $popup } = useNuxtApp()

const props = defineProps({
  showed: Boolean,
  cat: String,
  titleModal: String,
  inputPlaceholder: String,
  faza: ProductLampFaza,
  type: {
    type: String,
    default: 'brand'
  },
  prechoosed: {
    type: Object,
    default: null
  },
  refId: {
    type: Number,
    default: null
  },
  brandId: {
    type: Number,
    default: null
  },
  brandName: {
    type: String,
    default: null
  },
  brandAvatar: {
    type: String,
    default: null
  }
});
 

const brand_id = ref(props.brandId);
const brand_name = ref(props.brandName);
const brand_avatar = ref(props.brandAvatar);
 




const id = ref(null);
const isEnded = ref(false);
const keyword = ref('');
const filters = ref({});
const limit = ref(30);
const name = ref(null);
const power = ref(null);
const start = ref(0);
const type = ref('brand');
const isActive = ref(false);
 


const chooseLampType = (type: number) => {
  filters.value.type = type;
}

const chooseCategory = (list: string, item: any, index: number) => {
  start.value = 0
  type.value = 'product'

  // if (categoryType === EToggleClassType.Single) {
  //   allProductsLists[list].forEach((el) => {
  //     el.active = el.name === item.name
  //   })
  // }

  // if (categoryType === EToggleClassType.Multi) {
  //   item.active = !item.active
  // }

  if (filters.value.type === item)
    filters.value.type = 0
  else
    filters.value.type = index + 1
}

const onChangeLampPower = () => {
  start.value = 0
  type.value = 'product'
}

const changePower = (vl) => {
  start.value = 0;
  filters.value.power = vl;
  type.value = 'product';
}

const clear = () => {
  id.value = null;
  name.value = '';
  brand_id.value = null;
  brand_name.value = null;
  brand_avatar.value = null;
  power.value = null;
  filters.value.type = null;
  start.value = 0;
  isEnded.value = false;
};

const close = () => {
  clear();
  emits('close');
};
 

const isValid = () => {
  
  if (!keyword.value?.trim() || !/[^\s]/.test(keyword.value)) {
    $popup.error('Please enter a valid name');
    return false;
}

  if(props.cat == ProductCategoryType.Lamp && props.type == 'product'){
    if(!filters.value?.power){
      $popup.error('Please enter power');
      return false;
    }
    if(!filters.value?.type){
      $popup.error('Please choose type');
      return false;
    }
  }


  return true;
}

const chooseItem = () => {

  if(!isValid()){
    return;
  }

  if(props.type === 'brand'){

    id.value = null;
    name.value = null;
    brand_id.value = null;
    brand_name.value = keyword.value;
    brand_avatar.value = useDiary().getEquipCover(keyword.value, props.cat);
  }else{
    id.value = null;
    name.value = keyword.value;
    brand_avatar.value = useDiary().getEquipCover(keyword.value, props.cat, filters.value?.type);
  }

  

  keyword.value = '';
  eventChoose();
 
}
 

const eventChoose = () => {
  const item = {
    ref_id: props.refId,
    is_remove: 0,
    id: id.value,
    name: name.value,
    category: props.cat,
    item_brand: {
      id: brand_id.value,
      name: brand_name.value,
      logo_s: brand_avatar.value
    },
    props: {}
  }

  if (props.cat === ProductCategoryType.Medium) {
    item.value = 0
  }

  if (props.cat === ProductCategoryType.Lamp) {
    item.props.type = parseInt(filters.value.type)
    item.props.power = filters.value.power
    item.props.faza = props.faza
  }

  if (props.cat === ProductCategoryType.Seed) {
    item.props.cnt = 1
    item.props.type = 1
  }

  if (props.cat === ProductCategoryType.Nutrient) {
    item.unit = useAuth().getNutrient()
  }

  console.log('name.value', name.value);
  console.log('item', item);

  emits('choose', item);
}

const chooseKeyword = (val) => {
  keyword.value = val;
  console.log('keyword.value', keyword.value);
};
 


</script>

<style scoped>
.square__title {
  white-space: nowrap;
}

.frm {
  height: 100%;
}

.frm .search {
  display: flex;
  align-items: center;
}

.frm .search .logo_selected {
  width: 25px;
  margin-right: 10px;
  height: 25px;
  border: 0;
  object-fit: contain;
  flex-shrink: 0;
}

.frm .search .inpvl {
  width: 100%;
}

.frm .search .inpvl .inp {
  margin-bottom: 0;
}

.frm .search .inpvl input {
  width: 100%;
}

.frm .filter {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
  row-gap: 20px;
  width: 100%;
}
.frm .filter .gs .lb{
  margin-top: 10px;  
  color: var(--un-text-color-gray);

}
.frm .filter .power {
  display: flex;
  width: 100%;
  height: 38px;
}

.frm .filter .power.disabled .btns {
  background-color: #f1f1f1;
  color: #494949;
  width: 40px;
}

.frm .filter .power input {
  padding: 10px;
  text-align: center;
  width: calc(100% - 80px);
  max-width: 100px;
}

.frm .filter .power .btns {
  background-color: var(--un-primary-back-color);
  color: white;
  text-align: center;
  line-height: 3rem;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frm .filter .power .btns * {
  margin-right: 0;
  cursor: pointer;
}

.frm .filter .power .btns *:hover {
  opacity: 0.8;
}

.frm .filter .power .btns.bl {
  border-radius: 33px 0 0 33px;
}

.frm .filter .power .btns.br {
  border-radius: 0 33px 33px 0;
}

.frm .filter .types {
  display: flex;
  align-items: center;
}

.frm .filter .types .it {
  padding: 0.6rem 1rem;
  background-color: var(--un-element-color-gray);
  cursor: pointer;
}

.frm .filter .types .it:hover {
  opacity: 0.8;
}

.frm .filter .types .it:first-child {
  border-radius: 30px 0 0 30px;
}

.frm .filter .types .it:last-child {
  border-radius: 0 30px 30px 0;
}

.frm .filter .types .it.active {
  background-color: var(--un-primary-back-color);
  color: #fff;
}

@media (max-width: 768px) {
  .frm .filter .power {
    width: 100%;
    margin-right: 0;
  }

  .frm .filter .power input {
    max-width: none;
  }

  .frm .filter .types .it {
    text-align: center;
    width: 100%;
  }
}

.types_wrap {
  position: relative;
}

.types_wrap__label {
  font-size: 0.8em;
  padding: 0 0 0.2em;
  opacity: 0.7;
  bottom: 100%;
  left: 0;
  position: absolute;
}
</style>
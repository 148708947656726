<template>

  <div 
    :class="{sort: true, showed: showed, position: position}" 
    :title="$t('universal_sort_sort_by')"
    v-click-outside="hide">

    <div class="ched" @click="toggle">

      <span v-if="enablename">{{ selectedOption.name }}</span>     
      <i class="icon-sort-desc" v-if="selectedOption.icon == 'desc'"></i>
      <i class="icon-sort-asc" v-else-if="selectedOption.icon == 'asc'"></i>
      <i class="icon-sort-any ic-sort" v-else></i>
    </div>
    
    <div class="opts">
      <template
        v-for="option in options"
        >
        <div 
        @click="clickOption(option)"
          :class="'opt' + (option.id == selected ? ' active ' : '')"
          >
          {{ option.name }}
          <i class="icon-sort-desc" v-if="option.icon == 'desc'"></i>
          <i class="icon-sort-asc" v-else-if="option.icon == 'asc'"></i>
        </div>        
      </template>
    </div>
 

  </div>
  
</template>

<script setup>

const emits = defineEmits(['choose'])

const props = defineProps({
  val: {
    type: String
  },
  align: {
    type: String
  },
  options: {
    type: Array
  },
  enablehint: {
    type: Boolean,
    default: true
  },
  enablename: {
    type: Boolean,
    default: true
  },
})

const position = ref(props.align);
const selected = ref(props.val);
const showed = ref(false);

const selectedOption = computed(() => {
  var sel = {id: '', name: ''};
  for(var option of props.options){
    if(option.id == selected.value) {
      sel = option;
    }
  }
  return sel;
})

const hide = () => {
  if(showed.value) 
    toggle();
}

const toggle = () => {
  showed.value = !showed.value;
}

const clickOption = (option) => {
  choose(option.id);
  toggle();
}

const choose = (id) => {
  selected.value = id;
  emits('choose', selected.value);
}
 


</script>

<style scoped>


.sort{
  position: relative;
  display: flex;
  align-content: center;
  color: var(--gd-dropdown-color);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  min-height: 30px;
  justify-content: center;
  align-items: center;
}
.sort.right{
  justify-content: flex-end;
}
.sort .hnt{

}
.sort .ched{  
  font-weight: bold;
  margin-left: 5px;
  position: relative;
  display: flex;
  align-content: center;
  cursor: pointer;
  gap: 0.5rem;
}
.sort .ched span{ 
  text-transform: capitalize;
 }
.sort .ic-sort{
  margin-left: 5px;
}
.sort .opts{
  display: none;
  position: absolute;
  right: 0;
  top: 25px;
  background-color: var(--gd-dropdown-back-color);
  z-index: 10;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0px 2px 3px 0px rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  min-width: 140px;

}
.sort .opts .opt{
  display: flex;
  align-content: flex-end;
  justify-content: flex-start;
  text-transform: capitalize;
  gap: 0.5rem;
}
.sort.showed .opts{
  display: initial;
}

.sort .opts .opt{
  padding: 5px 15px;
  cursor: pointer;
}
.sort .opts .opt:hover{
  background-color: var(--gd-dropdown-back-active-color);
}
.sort .opts .opt.active{
  opacity: 0.5;
  pointer-events: none;
}
</style>

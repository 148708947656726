<template>
  
  <ClientOnly>
    <Teleport to="#app">

      <div v-if="props.photos" :class="{'lb': true, 'black-background': blackBackground}">
        <div :class="{'lc': true, 'zoom': zoomed}">
          <iframe
            v-if="photo.type == 'iframe'"
            class="iframe"
            :src="photo.iframe">
          </iframe>

          <!-- v-touch:tap="clickEvent" -->
          <img 
            v-else
            :class="{'image': true, 'is-loading': !isloadedImg, 'is-loading-error': !isLoadedErrorImg}"
            data-not-lazy
            :src="zoomed ? photo.base : photo.zoom" 
            v-touch:tap.stop.prevent="clickEvent"
            v-touch:drag.once="startDrag"
            v-touch:drag.stop="onDrag"
            v-touch:release="endDrag"
            @load="isloadedImg = true; isLoadedErrorImg = false"
            @error="isLoadedErrorImg = true"


          :style="{ transform: `translate(${currentTranslateX}px, ${currentTranslateY}px) scale(` + (zoomed ? 2 : 1) + `)` }"
          >

          <div class="gloading" v-if="!isLoadedErrorImg"></div>
          <i class="errored-loading icon-not-eye" v-else="!isLoadedErrorImg"></i>
        </div>

        <div v-if="current > 0" class="toggler left" @click.stop.prevent="prevPhoto">
          <i class="icon-angle-left"></i>
        </div>
        <div v-if="current+1 < props.photos.length" class="toggler right" @click.stop.prevent="nextPhoto">
          <i class="icon-angle-right"></i>
        </div> 
        <NuxtLink v-if="photo.type != 'iframe'" target="_black" :to="photo.zoom" class="external-btn">
          <i class="icon-download"></i>
        </NuxtLink>
        <div class="close-btn" @click="closeLightbox">
          <i class="icon-close"></i>
        </div>
        <div class="info">
          <div class="cur">{{ current+1 }} / {{ props.photos.length }}</div>
          <div class="caption">
            {{ photo['caption'] ? photo['caption'] : '' }}
          </div>
        </div>
        <div class="bglb" 
          @click="closeLightbox"
          ></div>
      </div>
    </Teleport>
  </ClientOnly>
</template>

<script setup>


const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  photos: {
    type: Array,
    required: true
  },
  blackBackground: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(['close']);
const { $noscroll } = useNuxtApp();

const zoomed = ref(false);
const wasDraging = ref(false);
const current = ref(props.index);
const photo = ref(props.photos[props.index]);
const clickEventTime = ref(0);
const isloadedImg = ref(false);
const isLoadedErrorImg = ref(false);

const closeLightbox = () => {
  console.log('backgroundClick');
  if(isEndDrag){
    emits('close');
  }
};

const toggleZoom = () => {
  if(photo.type == 'iframe'){
    return;
  }
  console.log('zoom');
  zoomed.value = !zoomed.value;
};
 

const nextPhoto = () => {
  isloadedImg.value = false;
  isLoadedErrorImg.value = false;
  if (current.value < props.photos.length - 1) {
    current.value++;
    photo.value = props.photos[current.value];
  } else {
    closeLightbox();
    // emit('swipeLeftEnd');
  }
};

const prevPhoto = () => {
  isloadedImg.value = false;
  isLoadedErrorImg.value = false;
  if (current.value > 0) {
    current.value--;
    photo.value = props.photos[current.value];
  } else {
    closeLightbox();
    // emit('swipeRightEnd');
  }
};

const clickEvent = () => {
  

  if(wasDraging.value)
    return;

    console.log('clickEvent');

  if (zoomed.value) {
    zoomed.value = false;
    return;
  }else{
    // return
  }
  

  toggleZoom();

  clickEventTime.value = Date.now();
};

const swipePrevPhoto = () => {
  console.log('swipePrevPhoto')
  currentTranslateX.value = window.innerWidth;
  setTimeout(() => {

    const imgElement = document.querySelector(".lc img");
    imgElement.style.transitionDuration = "0s";
    imgElement.style.visibility = "hidden"; 

    prevPhoto();

    currentTranslateX.value = -window.innerWidth;
    imgElement.style.transitionDuration = "0s";

    setTimeout(() => {
      imgElement.style.visibility = "visible"; 
      currentTranslateX.value = 0;
      lastTranslateX.value = 0;
      isEndDrag.value = true;
    }, 100);

  }, 300);
};

const swipeNextPhoto = () => {
  console.log('swipeNextPhoto')
  currentTranslateX.value = -window.innerWidth;
  setTimeout(() => {

    const imgElement = document.querySelector(".lc img");
    imgElement.style.transitionDuration = "0s";
    imgElement.style.visibility = "hidden"; 
    
    nextPhoto();

    currentTranslateX.value = window.innerWidth;
    imgElement.style.transitionDuration = "0s";

    setTimeout(() => {
    imgElement.style.visibility = "visible"; 
      currentTranslateX.value = 0;
      lastTranslateX.value = 0;
      isEndDrag.value = true;
    }, 100);

    // currentTranslateX.value = 0;
    // lastTranslateX.value = 0;
  }, 300);
  
};

 
// drag 


const isDragging = ref(false);
const startX = ref(0);
const startY = ref(0);
const currentTranslateX = ref(0);
const currentTranslateY = ref(0);
const lastTranslateX = ref(0);
const lastTranslateY = ref(0);
const isEndDrag = ref(true);

const startDrag = (event) => {
  
  if(isDragging.value) return;

  console.log('startDrag');
  console.log('isDragging.value', isDragging.value);


  isDragging.value = true;

  if (zoomed.value) {

    startX.value = event.type === "touchmove" ? event.touches[0].clientX : event.clientX;
    startY.value = event.type === "touchmove" ? event.touches[0].clientY : event.clientY;
    
    const imgElement = document.querySelector(".lc img");
    imgElement.style.transitionDuration = "0.2s";
    return;
  }


  startX.value = event.type === "touchmove" ? event.touches[0].clientX : event.clientX;
  startY.value = event.type === "touchmove" ? event.touches[0].clientY : event.clientY;
  // document.addEventListener("mousemove", onDrag);
  // document.addEventListener("mouseup", endDrag);
  console.log(event);

  isEndDrag.value = false;

};

const onDrag = (event) => {
 
  console.log('onDrag');

  if (zoomed.value) {

    const currentX = event.type === "touchmove" ? event.touches[0].clientX : event.clientX;
    const currentY = event.type === "touchmove" ? event.touches[0].clientY : event.clientY;

    const diffX = (currentX - startX.value) * 1.3;
    const diffY = (currentY - startY.value) * 1.3;

    currentTranslateX.value = lastTranslateX.value + diffX;
    currentTranslateY.value = lastTranslateY.value + diffY; 
    console.log('drag zoom');
    console.log(currentX, currentY);
    console.log(startX.value, startY.value);
    return;

  }

  if (!isDragging.value) return;

  const imgElement = document.querySelector(".lc img");
  imgElement.style.transitionDuration = "0.2s";
  
  
  const currentX = event.type === "touchmove" ? event.touches[0].clientX : event.clientX;
  const diffX = currentX - startX.value;
  
  currentTranslateX.value = lastTranslateX.value + diffX;
};



const endDrag = (event) => {


  if(!isDragging.value)
      return;
 
  // wasDraging.value = true;
  // setTimeout(() => {
  //   wasDraging.value = false;
  // }, 300);

  event.stopPropagation();
  event.preventDefault();

  if (zoomed.value) {
    const imgElement = document.querySelector(".lc img");
    imgElement.style.transitionDuration = "0.2s";
    currentTranslateX.value = 0;
    lastTranslateX.value = 0;
    currentTranslateY.value = 0;
    lastTranslateY.value = 0;
    return;
  }

  console.log('endDrag');

  isDragging.value = false;
  lastTranslateX.value = currentTranslateX.value;

  console.log('isDragging.value');
  console.log(isDragging.value);

  const imgElement = document.querySelector(".lc img");
  imgElement.style.transitionDuration = "0.2s";


  if (currentTranslateX.value > window.innerWidth / 10) {
    // prevPhoto();
    swipePrevPhoto();
  } else if (currentTranslateX.value < -window.innerWidth / 10) {
    // nextPhoto();
    swipeNextPhoto();
  }else{
    currentTranslateX.value = 0;
    lastTranslateX.value = 0; 
  }

  // document.removeEventListener("mousemove", onDrag);
  // document.removeEventListener("mouseup", endDrag);

};



// const updateStyles = () => {
//   const imgElement = document.querySelector(".lc img");
//   if (imgElement) {
//     imgElement.style.setProperty("--translateX", `${currentTranslateX.value}px`);
//     imgElement.style.setProperty("--translateY", `${currentTranslateY.value}px`);

//   }
// };

// watch(currentTranslateX, () => {
//   updateStyles();
// });


onMounted(() => {
  $noscroll.on();
  disableZoom();
});

onUnmounted(() => {
  $noscroll.off();
  enableZoom();
});

const enableZoom = () => {
  let viewport = document.querySelector("meta[name=viewport]");
  viewport.content = viewport.content.replace(", user-scalable=no", "");
  console.log(viewport.content);
};

const disableZoom = () => {
  let viewport = document.querySelector("meta[name=viewport]");
  if(viewport.content.indexOf("user-scalable=no") == -1){
    viewport.content = viewport.content + ", user-scalable=no";
  }
};




// keys 

const leftKeyListener = function(e) {
  if(e.key == 'ArrowLeft'){
    prevPhoto();
  }
}

const rightKeyListener = function(e) {
  if(e.key == 'ArrowRight'){
    nextPhoto();
  }
}

const escapeKeyListener = function(e) {
  if(e.key == 'Escape'){
    closeLightbox()
  }
}


onMounted(() => {
  if(import.meta.client){
    document.addEventListener('keydown', escapeKeyListener);
    document.addEventListener('keydown', leftKeyListener);
    document.addEventListener('keydown', rightKeyListener);
  }
})

onUnmounted(() => {
  if(import.meta.client){
    document.removeEventListener('keydown', escapeKeyListener);
    document.removeEventListener('keydown', leftKeyListener);
    document.removeEventListener('keydown', rightKeyListener);
  }
})

 


// const enableZoom = () => {
//   let viewport = document.querySelector("meta[name=viewport]");
//   viewport.parentNode.removeChild(viewport);

//   let newViewport = document.createElement('meta');
//   newViewport.name = "viewport";
//   newViewport.content = "width=device-width, initial-scale=1";
//   document.getElementsByTagName('head')[0].appendChild(newViewport);
// };

// const disableZoom = () => {
//   let viewport = document.querySelector("meta[name=viewport]");
//   viewport.parentNode.removeChild(viewport);

//   let newViewport = document.createElement('meta');
//   newViewport.name = "viewport";
//   newViewport.content = "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no";
//   document.getElementsByTagName('head')[0].appendChild(newViewport);
// };

 
</script>

<style scoped>
.lb {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
}
.bglb {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.lc {
  position: relative;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lc .gloading::after{
  display: none;
}
.lc img {
  max-height: 80vh;
  max-width: 80vw;
  object-fit: cover;
  cursor: pointer;
  transform: translateX(var(--translateX));
  user-drag: none;  
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: 11;
  cursor: zoom-in;
}
.lc.zoom img {
  cursor: zoom-out;
}
.lc img.is-loading {
  display: none;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  font-size: 1.1rem;
  z-index: 1;
}
.external-btn {
  position: absolute;
  top: 10px;
  right: 70px;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  font-size: 1.1rem;
  z-index: 1;
}

.info{
  position: absolute;
  bottom: 10px;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  font-size: 1.1rem;
  width: 100%;
  max-width: 580px;
  font-size: 0.8rem;
  z-index: 1;
}
.info .cur{

}
.info .caption{

}
/* zoomed */

.lc.zoom{
  height: 100vh;
  width: 100vw;
  max-width: none;
  max-height: none;
  overflow: hidden;
}
.lc.zoom content {
  cursor: grab;
  object-fit: contain;
}
.lc.zoom .info{
  display: none;
}

/* iframe */

.lc iframe{

  z-index: 11;
border: 0;
  width: 80vw;
    height: 80vh;

}


.errored-loading{
  color: white;
  font-size: 4rem;
  z-index: 1;
}
/* toggler */

.toggler {
  position: absolute;
  z-index: 11111;
  top: calc(50% - 30px);
  font-size: 2rem;
  color: white;
  background-color: #0000005c;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  cursor: pointer;
}
.toggler.left {
  left: 20px;
}
.toggler.right {
  right: 20px;
}
.toggler i{

  margin-top: 4px;

}


/* black-background */

.black-background .bglb{
  background-color: black;
}

</style>

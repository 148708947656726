<template>

  <div class="nutrient" v-if="props.nutrient?.is_remove != 1">
    
    <div class="nm">      

  
      <div class="lg">
        <i v-if="props.nutrient.id == '-1'" class="icon-nutrient"></i> 
        <img 
          v-else-if="props.nutrient.avatar_little"
          :src="props.nutrient.avatar_little" 
          :alt="props.nutrient.name + ' - ' + props.nutrient.item_brand.name" 
          >            
        <img 
          v-else-if="props.nutrient.item_brand?.logo_s"
          :src="props.nutrient.item_brand.logo_s" 
          :alt="props.nutrient.name + ' - ' + props.nutrient.item_brand.name" 
          >     
      </div>
      <div class="nut_info">
        <div class="nut_nm">
          {{ props.nutrient.name }}
          <div v-if="!props.nutrient?.name" class="act-add" @click="props.nutrient.item_brand?.id ? showed = true : showedCustom = true">
            + Add Name
          </div>
        </div>
        <div class="nut_brand">{{ props.nutrient.item_brand.name }}</div>                      
      </div>


    </div>

    <div class="nut_val">      
      <input class="nut_input" type="text" maxlength="6" v-model="edited_val" @keyup="changeVal">
    </div>
    
    <div class="nut_unit">

      <WeekEditNutrientSelectUnit
        :val="edited_measure"
        :def="props.default_measure"
        :options="units"
        @choose:val="changeMeasure" 
        />
 
    </div>
    <div class="nut_remove">
      <div class="remove_label" @click="remove"><i class="icon-remove"></i></div>
    </div>


        
  </div>



  <GeneralSelectBoxModal
    v-if="showed"
    :cat="'nutrient'"
    :input-placeholder="'Search...'"
    :title-modal="'Add nutrient product'"
    :showed="showed"
    :ref-id="props.nutrient?.ref_id"
    :brand-id="props.nutrient?.item_brand?.id"
    :brand-name="props.nutrient?.item_brand?.name"
    :brand-avatar="props.nutrient?.item_brand?.logo_s"
    :type="'product'"
    @choose="chooseItem"
    @prechoose="prechooseItem"
    @close="showed = false"
    @customize="showedCustom = true"
  />

  <GeneralSelectBoxModalCustom
      v-if="showedCustom"
      :cat="'nutrient'"
      :type="'product'"
      :input-placeholder="'Custom  Product Name'"
      :title-modal="'Add Custom Nutrient Product'"
      :showed="showedCustom"
      :ref-id="props.nutrient?.ref_id"
      :brand-id="props.nutrient?.item_brand?.id"
      :brand-name="props.nutrient?.item_brand?.name"
      :brand-avatar="props.nutrient?.item_brand?.logo_s"
      :prechoosed="prechoosed"
      @choose="chooseItem"
      @close="showedCustom = false"
    />

  
</template>


<script setup>

const { $convert } = useNuxtApp();

const props = defineProps({
  default_measure: {
    type: String
  },
  measure: {
    type: String,
    default: 'mll'
  },
  unit: {
    type: String,
    default: 'nutrient'
  },
  val: {
    type: Number
  },
  nutrient: {
    type: Object
  },
  isPresaved: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['remove', 'update:val', 'update:measure', 'choose'])

const units = ref([
  {id: 'mll', name: 'ml/l'},
  {id: 'mlgal', name: 'ml/gal'},
  {id: 'tspgal', name: 'tsp/gal'},
]);


const showed = ref(props.nutrient.showProduct)
const showedCustom = ref(false)
const prechoosed = ref(null)

const edited_measure = ref(props.measure);
const edited_val = ref(props.val);

const changeVal = () => {

  if(edited_val?.value && typeof edited_val.value == 'string')
  edited_val.value = edited_val.value.replace(',','.')


  // emits('update:val', $convert.legitimed(props.unit, props.measure, edited_val.value));
  emits('update:val', Number(edited_val.value));
}

const changeMeasure = (val) => {
  edited_measure.value = val;
  emits('update:measure', edited_measure.value);
}

const remove = () => {
  emits('remove');
}



const chooseItem = (data) => {
  console.log('data', data);
  emits('choose', data)
  showed.value = false
  showedCustom.value = false
}

const prechooseItem = (data) => {
  prechoosed.value = data
}

onMounted(() => {
  // if(!props.isPresaved)
    // edited_val.value = $convert.universal(props.unit, props.measure, edited_val.value);
    edited_val.value = edited_val.value ? edited_val.value : 
  emits('update:val', edited_val.value);
})


// watch(() => props.week, (val) => {
//   edited_num.value = val
// });
 

</script>
 

 
<style scoped>


.nutrient {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 50px;
  margin-bottom: 10px;
  gap: 10px;
}


.nutrient .remove_label{

  background-color: #DB2828 !important;
  border-color: #DB2828 !important;
  color: #FFFFFF !important;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 25px; 
  gap: 10px;
}


.nutrient .nm{

  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.nutrient .nm .lg{
  height: 35px;
  width: 60px;
  text-align: center;
}
.nutrient .nm .lg img{
  object-fit: contain;
  height: 100%;
}
.nutrient .nm .lg i{
  font-size: 1.6rem;
  color: #96be2a;
}
.nutrient .nm .lg .nut_lg{
  width: 100%;
}
.nutrient .nm .nut_info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.nutrient .nm .nut_info .act-add{

  color: var(--un-text-color-firm);
}
.nutrient .nm .nut_info .nut_nm{
  font-weight: bold;
}
.nutrient .nm .nut_info .nut_brand{}


.nutrient .nut_graph{
  width: 38%;
  margin-left: auto;
}
.nutrient .nut_graph .ui.progress{
  margin: 0px;
  background-color: #f9f9f9;
}
.nutrient .nut_graph .ui.progress .bar{
  height: 28px;
}
.nutrient .nut_val{
  width: 70px;
}
.nutrient .nut_val .nut_input {
    border: 0px;
    text-align: center;
    width: 100%;
    background-color: rgba(226, 226, 226, 0.2);
    border-radius: 3px;
    padding: 5px 10px;
    border-bottom: 1px var(--un-primary-back-color) solid;
}
.nutrient .nut_unit{
  text-align: left;
  width: 126px;
}

.nutrient .nut_remove{    
    width: 30px;
} 


.nutrient .nut_self{
    background: url(/images/product-categories/nutrient.svg);
    background-size: 100%;
    background-repeat: no-repeat;
    width: 24px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    opacity: 0.8;
    margin-right: 5px;
}

.nutrient .nut_graph .nut_sel_vl{
    display: inline-block;
    position: absolute;
    margin-left: 5px;
    margin-top: 4px;
    color: white;

}
 

@container pb (max-width: 768px) {
  .nutrient{
    height: auto;
    margin-bottom: 10px;
    text-align: left;
    border-bottom: 1px var(--un-element-color-gray) solid;
    padding-bottom: 15px;
  }
  .nutrient .nm .lg{
    margin-left: 0px;
  }

  .nutrient .nut_items .nut_graph{display: block;margin-left: 0;width: calc(100% - 190px);}
  .nutrient .nut_val{   
    margin-right: 10px;
    width: 100px;
  }
  .nutrient .nut_unit{display: inline-block;width: 80px;}
  .nutrient .nut_remove{        
      display: inline-block;
      padding-left: 0px;
      margin-left: auto;
  }
 
}


</style>
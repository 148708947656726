export enum ProgressStep {
  Bad = 'bad',
  Normal = 'normal',
  Good = 'good',
};

export enum UnitLength {
  Metric = 'metric',
  English = 'english',
};

export enum UnitHeight {
  Metric = 'metric',
  English = 'english',
};

export enum UnitSquare {
  Metric = 'metric',
  English = 'english',
};

export enum UnitWeight {
  Metric = 'metric',
  English = 'english',
};

export enum UnitVolume {
  L = 'l',
  Gal = 'gal',
};

export enum UnitTds {
  Ec = 'ec',
  Ppm = 'ppm',
};

export enum UnitTemperature {
  C = 'c',
  F = 'f',
};

export enum UnitNutrient {
  Mll = 'mll',
  Mlgal = 'mlgal',
  Tspgal = 'tspgal',
};

  

export const ColorPalette: string[] = ['#C91F37','#DC3023','#9D2933','#CF000F','#E68364','#F22613','#CF3A24','#C3272B','#8F1D21','#D24D57','#F08F907','#F47983','#DB5A6B','#C93756','#FCC9B9','#FFB3A7','#F62459','#F58F84','#875F9A','#5D3F6A','#89729E','#763568','#8D608C','#A87CA0','#5B3256','#BF55EC','#8E44AD','#9B59B6','#BE90D4','#4D8FAC','#5D8CAE','#22A7F0','#19B5FE','#59ABE3','#48929B','#317589','#89C4F4','#4B77BE','#1F4788','#003171','#044F67','#264348','#7A942E','#8DB255','#5B8930','#6B9362','#407A52','#006442','#87D37C','#26A65B','#26C281','#049372','#2ABB9B','#16A085','#36D7B7','#03A678','#4DAF7C','#D9B611','#F3C13A','#F7CA18','#E2B13C','#A17917','#F5D76E','#F4D03F','#FFA400','#E08A1E','#FFB61E','#FAA945','#FFA631','#FFB94E','#E29C45','#F9690E','#CA6924','#F5AB35'];

export const ColorPaletteRandom: string[] = ['#CA6924', '#049372', '#6B9362', '#C91F37', '#1F4788', '#4B77BE', '#DB5A6B', '#F3C13A', '#16A085', '#317589', '#22A7F0', '#8D608C', '#26A65B', '#F22613', '#5D3F6A', '#19B5FE', '#FFB61E', '#5D8CAE', '#407A52', '#875F9A', '#8E44AD', '#F4D03F', '#FAA945', '#F08F907', '#E29C45', '#BE90D4', '#CF000F', '#5B3256', '#59ABE3', '#BF55EC', '#87D37C', '#FFA400', '#7A942E', '#DC3023', '#D9B611', '#F9690E', '#FCC9B9', '#5B8930', '#F47983', '#C93756', '#4DAF7C', '#FFA631', '#F5AB35', '#F58F84', '#03A678', '#FFB94E', '#F5D76E', '#C3272B', '#8F1D21', '#CF3A24', '#003171', '#264348', '#006442', '#F62459', '#36D7B7', '#F7CA18', '#9B59B6', '#4D8FAC', '#26C281', '#48929B', '#A17917', '#E2B13C', '#E08A1E', '#044F67', '#89729E', '#2ABB9B', '#9D2933', '#FFB3A7', '#8DB255', '#E68364', '#89C4F4', '#763568', '#D24D57', '#A87CA0', '#7fbb5a', '#00515f', '#c1c74c', '#db71c4', '#8a7a60', '#3f2f7b', '#3726fa', '#45b8a9', '#e4993a', '#199da1', '#71819a', '#1ba315', '#77a813', '#1f7d39', '#58de39', '#cd1afb', '#b16afd', '#03adf1', '#34f4c9', '#07713c', '#f918c3', '#11284d', '#8dd78c', '#821706', '#b51f00', '#7054b0'];


export const AllowedDomainLinks = [
  'growdiaries.com', 
  'facebook.com',
  'twitter.com',
  'instagram.com',
  'linkedin.com',
  'pinterest.com',
  'reddit.com',
  'www.youtube.com',
  'm.youtube.com',
  'youtube.com',
  'vimeo.com',
  'dailymotion.com',
  'medium.com',
  'wikipedia.org',
];


export const GradeStarName: Record<number, string> = {
  10: 'Excellent',
  9: 'Excellent',
  8: 'Excellent',
  7: 'Great',
  6: 'Great',
  5: 'Good',
  4: 'Good',
  3: 'Average',
  2: 'Average',
  1: 'Average',
};


// Localization keys

export const UnitLengthTr: Record<UnitLength, string> = {
  [UnitLength.Metric]: 'set_cm',
  [UnitLength.English]: 'set_inch',
};

export const UnitHeightTr: Record<UnitHeight, string> = {
  [UnitHeight.Metric]: 'set_cm',
  [UnitHeight.English]: 'set_inch',
};

export const UnitSquareTr: Record<UnitSquare, string> = {
  [UnitSquare.Metric]: 'set_sqm',
  [UnitSquare.English]: 'set_sqft',
};

export const UnitWeightTr: Record<UnitWeight, string> = {
  [UnitWeight.Metric]: 'set_gram_full',
  [UnitWeight.English]: 'set_ounce_full',
};

export const UnitVolumeTr: Record<UnitVolume, string> = {
  [UnitVolume.L]: 'set_lilers',
  [UnitVolume.Gal]: 'set_gallons',
};

export const UnitTdsTr: Record<UnitTds, string> = {
  [UnitTds.Ec]: 'set_ms_cm',
  [UnitTds.Ppm]: 'set_ppm',
};

export const UnitTemperatureTr: Record<UnitTemperature, string> = {
  [UnitTemperature.C]: 'set_celsius',
  [UnitTemperature.F]: 'set_fahrenheit',
};

export const UnitNutrientTr: Record<UnitNutrient, string> = {
  [UnitNutrient.Mll]: 'set_mll',
  [UnitNutrient.Mlgal]: 'set_mlgal',
  [UnitNutrient.Tspgal]: 'set_tspgal',
};
<template>

  <div class="cats"> 

    <div :class="'cat positive cnt_' + positiveItems.length" v-if="positiveItems.length">
      <div class="title">Positive Effects:</div>
      <div class="list">
        <!-- <div class="imgs">
          <img v-for="(item, kitem) in positiveItems" :src="testImgsPositive[kitem]"/> 
        </div> -->
        <div class="names">
          <NuxtLink :to="RStrainCategoriesLinks['positive-effects:' + item]" class="item link-green" v-for="(item, itemKey) in positiveItems">{{ getStrainName('positive-effects:' + item) }}<template v-if="positiveItems.length-1 > itemKey">,</template></NuxtLink>
        </div>
      </div>
    </div>

    <div :class="'cat negative cnt_' + negativeItems.length" v-if="negativeItems.length">
      <div class="title">Negative Effects:</div>
      <div class="list">
        <!-- <div class="imgs">
          <img v-for="(item, kitem) in negativeItems" :src="testImgsNegative[kitem]"/> 
        </div> -->
        <div class="names">
          <NuxtLink :to="RStrainCategoriesLinks['negative-effects:' + item]" class="item link-green" v-for="(item, itemKey) in negativeItems">{{ getStrainName('negative-effects:' + item) }}<template v-if="negativeItems.length-1 > itemKey">,</template></NuxtLink>
        </div>
      </div>
    </div>

    <div :class="'cat medical cnt_' + medicalItems.length" v-if="medicalItems.length">
      <div class="title">Conditions:</div>
      <div class="list">
        <!-- <div class="imgs">
          <img v-for="(item, kitem) in medicalItems" :src="testImgsMedical[kitem]"/> 
        </div> -->
        <div class="names">
          <NuxtLink :to="RStrainCategoriesLinks['medical:' + item]" class="item link-green" v-for="(item, itemKey) in medicalItems">{{ getStrainName('medical:' + item) }}<template v-if="medicalItems.length-1 > itemKey">,</template></NuxtLink>
        </div>
      </div>
    </div> 

    <div :class="'cat tastes cnt_' + tasteItems.length" v-if="tasteItems.length">
      <div class="title">Tastes</div>
      <div class="list">
        <!-- <div class="imgs">
          <img v-for="(item, kitem) in tasteItems" :src="testImgsTaste[kitem]"/> 
        </div> -->
        <div class="names">
          <NuxtLink :to="RStrainCategoriesLinks['tastes:' + item]" class="item link-green" v-for="(item, itemKey) in tasteItems">{{ getStrainName('tastes:' + item) }}<template v-if="tasteItems.length-1 > itemKey">,</template></NuxtLink>
        </div>
      </div>
    </div>

    <StrainHeadGenesSlider 
      v-model="props.genes"
      />

  </div>
 
</template>

<script setup>


import { strains } from '~/lang/strains/en.js'
import { EStrainCategories, RStrainCategoriesImages, RStrainCategoriesNames, RStrainCategoriesLinks, RPreferredStrainCategories } from '@/types/strain'

const props = defineProps({
  positive: {
    type: String,
    default: ''
  },
  negative: {
    type: String,
    default: ''
  },
  medical: {
    type: String,
    default: ''
  },
  tastes: {
    type: String,
    default: ''
  },
  genes: {
    type: Number,
    default: null
  }
})

const positiveItems = ref(props.positive ? props.positive.split(',') : [])
const negativeItems = ref(props.negative ? props.negative.split(',') : [])
const medicalItems = ref(props.medical ? props.medical.split(',') : [])
const tasteItems = ref(props.tastes ? props.tastes.split(',') : [])


const getStrainName = (key) => {
  let el = strains?.find(item => item.id == key);
  return el?.name ?? '';
}


const testImgsPositive = ref([
  '/images/effect/paranoia.jpg',
  '/images/effect/dry_mouth.jpg',
  '/images/effect/insomnia.jpg',
])

const testImgsNegative = ref([
  '/images/effect/paranoia.jpg',
  '/images/effect/dry_mouth.jpg',
  '/images/effect/insomnia.jpg',
])

const testImgsMedical = ref([
  '/images/effect/help_stress.jpg',
  '/images/effect/help_pain.jpg',
  '/images/effect/help_depression.jpg',
])

const testImgsTaste = ref([
  '/images/taste/sweet.jpg',
  '/images/taste/citrus.jpg',
  '/images/taste/fruity.jpg',
])

</script>




<style scoped>


.cats {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: auto;
  white-space: nowrap;
  color: var(--un-text-color);
  margin-top: 1rem;
  gap: 0.5rem;
  width: fit-content;

  background-color: var(--un-background-color-gray);
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
}

.cat {
    min-width: 0;
    flex-direction: row;
    display: flex;
    gap: 1rem;
}

.cats-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: auto; 
  white-space: nowrap;
  color: var(--un-text-color);
  padding: 0 10px; 

  box-sizing: border-box;
  max-width: 100%;
}

.cat .title {
  font-weight: bold;
}

.cat .list {
  display: flex;
  gap: 0.5rem;
}

.cat .list .imgs{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 70px;
  gap: 2px 5px;
  flex-shrink: 0;
}
.cat .list .imgs img{
  width: 28px;
  height: 28px;
}
.cat.negative .list .imgs img{
  filter: hue-rotate(330deg);
}

/* 1 item */

.cat.cnt_1 .list .imgs img{
  width: 100%;
  object-fit: contain;
  height: 65px;
  padding: 10px;
}

/* 2 items */

.cat.cnt_2 .list .imgs img:nth-child(1){
  width: 100%;
  object-fit: contain;
  padding-right: 20px;
}
.cat.cnt_2 .list .imgs img:nth-child(2){
  width: 100%;
  object-fit: contain;
  padding-left: 20px;
}

/* 3 items */
.cat.cnt_3 .list .imgs img:nth-child(1){
  width: 100%;
  object-fit: contain;
}
.cat.cnt_3 .list .imgs img:nth-child(2){

}
.cat.cnt_3 .list .imgs img:nth-child(3){

}
.cat .list .names{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
}

.cat .item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cat .item img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}



@container pb (max-width: 650px) {
  .cats {
    overflow-x: auto;
    width: 100%;
    max-width: calc(100vw - 40px);
    padding: 1.5rem;
  }
  .cats::-webkit-scrollbar {
    display: none;
  }

  .cat {
      gap: 0.5rem;
  }
  
}


  
</style>

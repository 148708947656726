<template>

<!-- {{data}} -->
  <div v-if="props.data" :class="{'review': true, 'short': isShortReview}">




      <div class="brand-product">

        <img class="logo" :src="props.data.item_review_seed.item_brand.logo_s" :alt="props.data.item_review_seed.item_brand.name">
        

        <div class="product">{{ props.data.item_review_seed.item_product.name }}</div>
        <div class="brand">{{ props.data.item_review_seed.item_brand.name }} </div>






    </div>
    
    <div class="txt">



      <div class="rating">
        <div class="stars">
          <UiRating
            :cnt="10"
            :vl="props.data.item_review_seed.n_general"
            :editable="false"
          /> 
        </div>

        <div class="rate">
          <span class="val">{{ props.data.item_review_seed.n_general }}</span>
          <span class="of">/10</span>
        </div>


      </div>


      <GeneralShowMore
        :desktop-max-height="85"
        :mobile-max-height="80" 
        >
        <div class="text">{{ props.data.item_review_seed.text }}</div> 
      </GeneralShowMore>
    </div>

 
  </div>


  <div v-if="props.data" class="params">
    <WeekwideViewCommentSetupBig
      :setup="props.setup"
      /> 
  </div>
 
  
</template>

<script setup>
  
const { t } = useI18n();
const { $constants, $convert } = useNuxtApp();



const props = defineProps({
  data: {
    type: Object,
    required: false
  },
  setup: {
    type: Object,
    required: false
  }
})



const isShortReview = computed(() => {
  return props.data?.item_review_seed?.text?.length < 100
})
  

</script>




<style scoped>
  

.review {
  grid-template-columns: 200px 1fr;
  background-color: #d4e6ae87;
  border-radius: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  display: grid;
  grid-template-areas:
    "brand-product txt"
    "brand-product params";
  grid-gap: 0 1rem;
}


.brand-product {
  display: grid;
  grid-template-areas:
    "logo"
    "product"
    "brand"
    "rating";
  grid-gap: 0;
  grid-area: brand-product;
  align-self: flex-start;
  grid-gap: 0.1rem;
}

.logo {
  grid-area: logo;
  max-width: 80px;
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: contain;
}

.product {
  grid-area: product;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: normal;
  align-self: flex-end;
}

.brand {
  grid-area: brand;

}

.rating {
  grid-area: rating;
  display: flex;
  align-items: center;
}
.rate {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-left: 10px;
}
.val {
  font-size: 1.2rem;
  margin-bottom: 0.2rem;
}
.of {

}

.stars {
  
}
.stars .icon-star {
  color: #f1c40f;
}
.stars .icon-star-empty {
  color: gray;
}

.txt {
  grid-area: txt;  
}
.text{
  /* font-size: 1.2rem; */
}

.params {
  grid-area: params;
  margin: 1rem 0;
}

   
@container pc (max-width: 600px) {
 
  
  .review {
    grid-template-columns: 1fr;
    background-color: #d4e6ae87;
    border-radius: 1rem;
    margin-bottom: 1rem;
    padding: 1.5rem;
    display: grid;
    grid-template-areas:
      "brand-product"
      "txt"
      "params";
    grid-gap: 0 1rem;
  }


  .brand-product {
    display: grid;
    grid-template-areas:
      "product logo"
      "brand logo";
    grid-gap: 0;
    grid-area: brand-product;
    align-self: flex-start;
    grid-template-columns: 1fr 80px;
    margin-bottom: 1rem;
  }
  .rating{
    margin-top: 0;
  }

}


/* short  */

.review.short{
  grid-template-columns: 270px 1fr;
}
.review.short .brand-product{
  grid-template-areas:
    "logo product"
    "logo brand";

  grid-gap: 0.3rem 0.6rem;
  grid-template-columns: 69px 1fr;
}

@container pc (max-width: 600px) {
  .review.short{
    grid-template-columns: 1fr;
  }
}

</style>

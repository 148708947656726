<template>


  <UiHorizontalScroll
    :arrows="true"
    :arrows-show-hover="true"
  >
    <div 
      v-if="data"
      v-for="(items, items_category) in aggrData"
      class="product_review" 
    >
      <WeekwideViewHarvestProductReviewItem
        v-for="(item_review) in items"
        :review="item_review"
        :brand="item_review.item_brand"
        :items="item_review.items_review"
        type="harvest_nutrient"
      />
    </div>
  </UiHorizontalScroll>

</template>

<script setup lang="ts">


import { ProductCategorySort }  from "@/types/product"
 

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const sortedData = computed(() => {
  const result = props.data

  result.sort((a, b) => {
    return ProductCategorySort.indexOf(a.item_brand.category) - ProductCategorySort.indexOf(b.item_brand.category);
  })

  return result
})

const sortByCategory = (a, b) => {
  return ProductCategorySort.indexOf(a.item_brand.category) - ProductCategorySort.indexOf(b.item_brand.category);
}

const aggrData = computed(() => {

  const aggregated = props.data.reduce((acc, item) => {
    if (!acc[item.item_brand.category]) {
      acc[item.item_brand.category] = []
    }
    acc[item.item_brand.category].push(item)
    return acc
  }, {})

  // Sort the aggregated data by category
  const sortedAggregated = {}
  ProductCategorySort.forEach(category => {
    if (aggregated[category]) {
      sortedAggregated[category] = aggregated[category]
    }
  })

  return sortedAggregated
})

</script>

<style scoped>

.product_review{
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  align-items: flex-start;
  margin-right: 0.5rem;
}


@container pb (max-width: 600px) {
 
}

</style>
<template>


  <!-- <ClientOnly>
    <Teleport v-if="active" to="#app"> -->

    <UiModal
      v-if="props.diary"
      :title="'New week type'"   
      :close-button-name="'Close'"
      :is-transparent="true"
      :is-auto-height="true"
      :width="'70%'"
      :max-width="'400px'"
      @close="handleClose"         
      >        
        
        <UiButton
          tag="NuxtLink"       
          v-if="!existsGermination"
          :name="$t($constants.typeFaza[WeekFaza.Germination])"    
          type="ger float"      
          @click.native="handleClose"
          :to="'/diaries/' + props.diary.link + '/weeks/gnew/edit'"
          />
        

        <UiButton
          tag="NuxtLink"             
          :name="$t($constants.typeFaza[WeekFaza.Vegetation])"    
          type="veg float"      
          @click.native="handleClose"
          :to="'/diaries/' + props.diary.link + '/weeks/vnew/edit'"
          />
      

        <UiButton
          tag="NuxtLink"             
          :name="$t($constants.typeFaza[WeekFaza.Flowering])"    
          type="flo float"      
          @click.native="handleClose"
          :to="'/diaries/' + props.diary.link + '/weeks/fnew/edit'"
          />
         
        <template
          v-for="(seed) in props.diary.items_equip.filter((item) => item.category == 'seed')"          
          >

          <UiButton
            tag="NuxtLink"             
            :name="$t($constants.typeFaza[WeekFaza.Harvest]) + ' ' + (seed.item_brand.name ?? '') + ' - ' + ( seed.name )"    
            type="har float"      
            :disabled="seed.enable_harvest ? true : false"
            @click.native="handleClose"
            :to="'/diaries/' + props.diary.link + '/weeks/h' + seed.ref_id + '/edit'"
            />
           
        </template>
      
      </UiModal>
<!-- 
    </Teleport>
  </ClientOnly> -->
  
</template>

<script setup lang="ts">

import type { Diary } from '@/types/diary'
import { WeekFaza } from '@/types/diary'

interface PageProps {
  diary: Diary
}

const props = defineProps<PageProps>()

const emits = defineEmits(['close'])
   
const existsGermination = computed(() => {
  if(props.diary.items_week)
  for (const [wkey, w] of props.diary.items_week.entries()) {
    if(w.faza == -1)
      return true;
  }
  return false;
})


const handleClose = () => {
  emits('close');
}
 


</script>

<style scoped>
 
  

</style>

<template>

  <!-- RULES -->

  <a name="rules"></a>

  <div class="rl wd">

    <h2>Rules</h2>

    <div class="ds desc">
      Follow these easy steps and help us decide on the winners.
    </div>

    <div class="ls">
      <div class="lsi" v-for="(rule, index) in props.rules" :key="index">
        <img loading="lazy" class="ic" :src="AwardRuleTypeImage[rule.type]" alt="Number">
        <div class="tx">{{ rule.text }}</div>
      </div>
    </div>

  </div>
  
</template>


<script setup lang="ts">

import type { AwardCategoryRule } from '@/types/award'
import { AwardRuleTypeImage } from '@/types/award'

interface Props {
  rules: AwardCategoryRule[]
}

const props = defineProps<Props>()

</script>
 


<style scoped>
 
.rl {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.rl .ds {
  margin-bottom: 0rem;
}

.rl .ls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.rl .ls .ic {
  max-width: 120px;
  width: 100%;
  aspect-ratio: 1/1;
}

.rl .ls .lsi {
  display: flex;
  align-items: center;
  width: 180px;
  flex-direction: column;
  gap: 0.5rem;
}

.rl .ls .lsi .tx {
  text-align: center;
  font-weight: bold;
}


@container pc (max-width: 600px) {

  .rl {}

  .rl .ds {}

  .rl .ls {
    gap: 1.5rem;
  }

  .rl .ls .lsi {
    width: calc(50% - 2rem);
  }

  .rl .ls .lsi .nm {}

  .rl .ls .lsi .tx {}
}
</style>
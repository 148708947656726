import type { Product } from './Product.interface'
import { createBrand } from '../brand/Brand.factory'


export function createProduct(): Product {
  return {
    id: null,
    name: null,
    section: null,
    link: null,
    item_brand: createBrand(),
  }
}

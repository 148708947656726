

export default defineNuxtPlugin(nuxtApp => {  
   
  const observer = (element, callbackHide, callbackVisible, threshold) => {
    var callbackHide = callbackHide || function() {};
    var callbackVisible = callbackVisible || function() {};
    var threshold = threshold || 0.1;
    const options = {
      root: null, 
      threshold: threshold, 
    };

    if(import.meta.client && typeof element !== 'undefined' && element !== null) {

      if(typeof IntersectionObserver === 'undefined') {
        // callbackVisible(element);
        return;
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {


            if (!entry.isIntersecting) {
              callbackVisible(entry.target);
            }

            if (entry.isIntersecting) {
              callbackHide(entry.target);
            }

          
        });
      }, options);

      observer.observe(element);

    }
  };

  return {
    provide: {
      observer: observer,
    },
  };

})
<template>

  <div class="popup_secure"> 

    <div class="tx">
      {{$t('main_menu_popup_secure_text')}}
    </div>

    <div class="btn secondary inverted" @click="agree">{{$t('main_menu_popup_secure_button_accept')}}</div>
            
  </div> 

</template>

<script setup>

const { $ga } = useNuxtApp()
const generalAgree = useGeneralAgree();
const cookieAgree = useCookie('general_agree',{maxAge: 60 * 60 * 24 * 31 * 6});

const agree = () => {
  generalAgree.value = true;
  cookieAgree.value = true;
  $ga.eventGA('user_gdpr_consent');
}
 
</script>


<style scoped>
 
 
.popup_secure{
  position: fixed;
  bottom: 0px;
  background-color: #636363ed;
  z-index: 10100;
  text-align: center;
  padding: 10px 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 30%);
  border-radius: 0px 0px 3px 3px;
  font-size: 0.9rem;
  color: white;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.popup_secure a{
  color: white;
  text-decoration: underline;
}
.popup_secure .tx{
  text-align: left;
  font-size: 0.8rem;
}
.popup_secure .btn{
  margin-left: 10px;
  flex-shrink: 0;
} 
</style>

export default defineNuxtPlugin(async (nuxtApp) => {  

  // if(import.meta.client) {

  // console.log('nuxtApp.$i18n');
  // const response = await useFetch(`https://bucket.growdiaries.com/web/locale/en.json`)
  // console.log(response);
  // nuxtApp.$i18n.setLocaleMessage('en', response.data);


  // nuxtApp.$i18n.onLanguageSwitched = async (oldLocale, newLocale) => {

  //   console.log('nuxtApp.$i18n');
  //   console.log(newLocale);
  //   console.log(nuxtApp.$i18n);
  //   console.log(nuxtApp.$i18n.getLocaleMessage(newLocale));

    

  //   if (!nuxtApp.$i18n.getLocaleMessage(newLocale)) {

      
  //     try {
  //       const response = await useCustomFetch(`https://bucket.growdiaries.com/web/locale/${newLocale}.json`)
  //       nuxtApp.$i18n.setLocaleMessage(newLocale, response.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // }
  // }
});

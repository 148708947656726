<template>

  <div class="question_box">
    <div class="data">
  
      <div :class="'top_status' + (!props.question.is_resolved ? ' opened ' : ' closed ')">
        <i v-if="!props.question.is_resolved" class="icon-warning-triangle"></i> 
        <i v-if="props.question.is_resolved" class="icon-success-round"></i>         
        {{ props.question.is_resolved ? $t('grow_questions_view_category_solved') : $t('grow_questions_view_category_opened') }}
      </div>
      
      <NuxtLink 
        v-if="props.question.item_user"
        class="user" 
        :to="props.question.item_user.link ? props.question.item_user.link : ''">
        <img loading="lazy" 
            :src="props.question.item_user.avatar_little" 
            srcset=""             
            class="avatar"            
            :alt="props.question.item_user.name">
        <div class="nk">
          {{ props.question.item_user.name }}        
        </div>
      </NuxtLink>

      <NuxtLink :to="props.question.link ? props.question.link : ''" class="ttl">
        {{ props.question.title }}   
      </NuxtLink> 
    
      <NuxtLink :to="props.question.link ? props.question.link : ''" 
        class="txt" 
      >
        {{ props.question.text }}
      </NuxtLink>

      <NuxtLink 
        class="glr" 
        :to="props.question.link ? props.question.link : ''"
        v-if="props.question.items_problem_photo && props.question.items_problem_photo[0]"
        >        
        <img loading="lazy" :src="props.question.items_problem_photo[0].size_m" class="avatar" alt="">        
      </NuxtLink>

      
      <div class="tgs">

        <NuxtLink 
          v-if="props.question.item_week?.link && props.question.item_week?.days"
          :to="props.question.item_week.link ? props.question.item_week.link : ''" 
          class="tg"
          >
          {{ $t('grow_questions_view_week') }} 
          {{ props.question.item_week.days }}
        </NuxtLink>
        
        <template
          v-for="(symptom, symptom_key) in props.question.items_problem_symptom"
          :key="symptom_key"
          >
        <div           
          v-if="symptom.type"
          :title="$t('universal_plant_symptoms_type_' + symptom.type) + '. ' + $t('universal_plant_symptom_' + symptom.id)"
          class="tg">
          {{ $t('universal_plant_symptoms_type_' + symptom.type) }}. 
          {{ $t('universal_plant_symptom_' + symptom.id) }}
        </div>
      </template>

      </div>

      <div class="cnts">
        <div class="date">{{ $dayjs.utc(props.question.add_date).fromNow() }}</div>
        <div class="cnt" v-if="props.question.cnt_likes">
          <i class="icon-leaf"></i> {{ props.question.cnt_likes }}
        </div>
        <div class="cnt" v-if="props.question.cnt_solutions">
          <i class="icon-comment"></i> {{ props.question.cnt_solutions }}
        </div>
      </div>
    </div>

  </div>
  
</template>

<script setup lang="ts">


// import type { Diary, Week } from '@/types/diary'
// import type { User } from '@/types/user'
import type { Question } from '@/types/question'

interface Props {
  question: Question;
}

const props = defineProps<Props>()
 
</script>

<style scoped>
 
.question_box{
    display: flex;
    align-items: center;
    /* margin-bottom: 1rem; */
    padding-bottom: 2rem;
    /* width: calc(32% - 1rem); */
    /* min-width: 300px; */
    border-radius: 10px;
    /* box-shadow: 0px 0px 15px #00000021; */
    background-color: var(--un-background-color-gray);
    padding: 1.5rem;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    border: 1px #93939312 solid;

  contain: layout paint;

}
.question_box_full{
  /* width: 100%!important; */
  box-shadow: none!important;
  border: 0!important;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.question_box .data{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.question_box .data .date{
    color: gray;
    margin-right: auto;
    font-size: 0.7rem;
}
.question_box .data .user{
    margin-right: auto;
    display: flex;
}

.question_box .data .user .avatar{border-radius: 30px;width: 20px;height: 20px;margin-right: 5px;overflow: hidden;}
.question_box .data .user .nk{

}
.question_box .data .ttl{
    font-weight: bold;
    display: block;
    width: 100%;
    margin-top: 0.5rem;
    word-wrap: break-word;
}
.question_box .data .txt{
  width: 100%; 
  word-wrap: break-word;
  margin-top: 0.5rem;
}

.question_box .data .translate{
    color: gray;
    /* margin-top: 5px; */
    margin-top: 10px;
    cursor: pointer;
}
.question_box .data .tgs{
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    width: 100%;
}
.question_box .data .tgs .tg{
    background-color: var(--un-background-color-gray-dark);
    border-radius: 30px;
    color: gray;
    padding: 2px 12px;
    margin-right: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis; 
}
.question_box .data .tgs .tg.status.opened{
    background-color: var(--un-background-color-red-light);
    color: var(--un-text-color-red);
}
.question_box .data .tgs .tg.status.closed{
    background-color: var(--un-background-color-firm-light);
    color: var(--un-text-color-firm);
}
.question_box .data .top_status{ 
    margin-right: 5px; 
    border-radius: 30px; 
    padding: 2px 12px;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-top: -2px;
    height: fit-content;
}
.question_box .data .top_status.opened{ 
    background-color: var(--un-background-color-red-light);
    color: var(--un-text-color-red);
}
.question_box .data .top_status.closed{ 
    background-color: var(--un-background-color-firm-light);
    color: var(--un-text-color-firm);
}
.question_box .glr{
    width: 100%;
    display: flex;
    overflow-y: hidden;
    align-items: center;
    margin-left: auto;
    border-radius: 5px;
    /* background-color: red; */
    width: 100%;
    padding-top: 75%; /* 1:1 Aspect Ratio */
    position: relative;
    margin-top: 10px;
}
.question_box .glr .avatar{
    width: 100%;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}



.question_box .data .cnts{
    display: flex;
    margin-top: 0.5rem;
    flex-wrap: wrap;
    width: 100%;
}
.question_box .data .cnts .cnt{
  color: gray;
  margin-right: 10px;
  font-size: 0.7rem;
}



@media (max-width: 768px){ 
    .catalog_questions .search_tags .tags {
        text-align: center; 
    }
    .catalog_questions .search_custom{
        position: relative;
        top: 0;
        width: 100%;
    }
}
</style>


<style>
.masonry-item .question_box{
  width: 100%;
}
</style>

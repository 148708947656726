<template>


<!-- {{ props }} -->
    <div 
      v-if="props.category && list?.length"
      class="cti"
      >

      <div class="title">
        <div class="br">{{ props.category.sub }}</div>
        <div class="nam">{{ props.category.name }}</div>
      </div>
      <div class="listbx">
        <div class="list">

          <div
            class="item"
            
            v-for="(vote, kvote) in list"
            :key="kvote"
            >

            <template v-if="props.category.type == 'brand'">
              <img :src="vote.item_brand?.logo_s" :alt="vote.item_brand.name">
              <div class="cname">
                {{ vote.item_brand.name }}
              </div>
            </template>
            <template v-else>
              <img :src="vote.item_product?.cover_s" :alt="vote.item_product.name">
              <div class="cname">
                <NuxtLink :to="vote.item_product.link">
                  {{ vote.item_product.name }}
                </NuxtLink>
                -
                <NuxtLink :to="vote.item_product.item_brand.link">
                  {{ vote.item_product.item_brand.name }}
                </NuxtLink>
                

              </div>
            </template>
            <div class="wn" v-if="props.showWinners && kvote < 3">
              <div class="cup">Winner {{ kvote + 1 }}{{ $plurals.getPlaceEN(kvote + 1) }} </div>
            </div>
            <div class="vts">
              <!-- Winner {{ kvote + 1 }} place -->
              {{ vote.votes }} {{ $t($plurals.get('vote', vote.votes)) }}
            </div>
          </div>
          <!-- <div v-else class="no-votes">
            No votes
          </div> -->
        </div>


        <div :class="{'more': true, 'gloading': isLoading}"
          @click="loadMore"
          v-if="!isEnd && list.length >= 3"
          >
          Load more
        </div>
      </div>

    </div>
  
</template>


<script setup>

import { watchArray } from '@vueuse/core'

const {$api, $popup} = useNuxtApp()

const props = defineProps({
  cupId: {
    type: Number,
    required: true
  },
  category: {
    type: Object,
    required: true
  },
  votes: {
    type: Array,
    required: true
  },
  showWinners: {
    type: Boolean,
    default: false
  }
})


const showed = ref(false)
 
const start = ref(0)
const limit = ref(10)
const isEnd = ref(false)
const list = ref(props.votes)

const loadMore = () => {
  if(!start.value){
    start.value = 3;
  }else if(start.value < 10){
    start.value = limit.value;
  }else{
    start.value += limit.value;
  }
}

const loadResults = async () => {
  return await $api.getAwardCategoryVotes(props.cupId, props.category.id, {
    start: start.value,
    limit: limit.value
  });
}

// const {
//   pending: isLoading,
//   data: votesData
// } = useLazyAsyncData('votesData_' + props.cupId + '_' + props.category.id, async () => await loadResults())



watchArray([
  start
], async ([new_start], added, removed) => {

  if (new_start === 0){
    list.value = [];
  }

  isLoading.value = true;

  var dt = await loadResults();

  if (new_start === 0)
    isEnd.value = false;

  if (dt?.items?.length !== limit.value)
    isEnd.value = true;

  if (dt?.items?.length > 0){
    list.value = [...list.value, ...dt?.items]
  }

  isLoading.value = false;
}, {deep: true})



</script>
 


<style scoped>
 
 
.cti{
  border-radius: 10px;
  background-color: var(--un-background-color-gray);
  width: 100%;
  padding: 2rem;
  display: flex;
  gap: 1rem;
}
.cti .title{
  display: flex;
  gap: 0.3rem;
  flex-direction: column;
  width: 200px;
  flex-shrink: 0;
}
.cti .br{
    color: #a1a1a1;
}
.cti .nam{
  font-weight: bold;
  font-size: 1.1rem;
  line-height: normal;
}
.cti .ds{
    margin-top: 1rem;
    display: none;
}

.listbx{
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
}
.list{
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
}
.item{
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.item img{
  border-radius: 3px;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.item .cname{
  font-weight: bold;
  margin-right: auto;
}

.item .wn{
  flex-shrink: 0;
}

.item .wn .cup{
  background-color: gold;
  height: 23px;
  padding: 0px 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  white-space: nowrap;
}
.item .vts{
  width: 70px;
  flex-shrink: 0;
}

.no-votes{
  padding: 2rem 0;
  text-align: center;
}

.more{
  padding: 5px 0px;
  cursor: pointer;
  position: relative;
    width: fit-content;
    text-decoration: underline;
}
 
@container pc (max-width: 600px) {
 
  .cti{
    width: 100%;
    flex-direction: column;
  }
  .cti .br{}
  .cti .nam{}
  .cti .ds{}
  .cti .bt{}
  .cti .bt .btn{}
  .cti .bt .cnt{}
}

</style>
<template>

  <div :class="'uploader ' + (btnWide ? ' btn-wide ' : ' ')"
    @dragover.prevent="dragOver"
    @drop.prevent="onFileDrop"
    >
    <div class="up-button">
      <div class="name"><i class="icon-plus"></i> {{ btnName }}</div>
    </div>

    <input type="file" ref="file" :multiple="isMultiple" allow @change="changeFiles">

    <div v-if="enableDragDrop" :class="{'drag-field': true, 'active' : dragging}">
      <i class="icon-to-cloud"></i>
      Drag and drop files here
    </div>

  </div>


</template>


<script setup lang="ts">


import { getRandomString } from '@/types/other'


const { $api } = useNuxtApp();

const props = defineProps({
  url: {
    type: String,
    default: null
  },
  btnWide: {
    type: Boolean,
    default: false
  },
  btnName: {
    type: String,
    default: 'Add Photos & Videos'
  },
  isMultiple: {
    type: Boolean,
    default: true
  },
  enableDragDrop: {
    type: Boolean,
    default: false
  } 
})

const emits = defineEmits([
  'uploaded',
  'errored',
  'changed',
  'progress'
])

const files = ref([])
const thumbs = ref([])
const queue = ref([])
const queueChecker = ref(null)
const isUploading = ref(false)
const file = ref(null)
const dragging = ref(false);
 


const dragOver = (e) => {
  e.preventDefault();
  dragging.value = true;
}

const onFileDrop = (e) => {
  dragging.value = false;
  const droppedFiles = e.dataTransfer.files;
  if (droppedFiles.length > 0) {
    file.value.files = droppedFiles;
    changeFiles();
  }
}

const changeFiles = () => {  
  for( var i = 0; i < file.value.files.length; i++ ){                
    file.value.files[i].uploading_id = getRandomString(10);
    files.value.push(file.value.files[i])    
    
    const thumbnailURL = URL.createObjectURL(file.value.files[i]);
    thumbs.value.push(thumbnailURL); 

    // console.log(file.value.files[i].uploading_id);
    
    emits('changed', file.value.files[i].uploading_id, thumbnailURL); 
    // uploadFile(file.value.files[i]);
  }
  submitFiles();
}

const clearThumbnails = () => {
  thumbs.value.forEach((thumbURL) => {
    URL.revokeObjectURL(thumbURL);
  });
  thumbs.value = [];
}

const submitFiles = () => {  
  files.value.forEach(f => addQueueItem(f));
  files.value = [];
  nextStepQueue();
}

const nextStepQueue = () => { 
  if(queue.value.length > 0){
    uploadFile(queue.value[0]);
  }
}

const deleteQueueItem = (currentFile) => { 
  queue.value = queue.value.filter(f => f.uploading_id !== currentFile.uploading_id).slice();
}

const addQueueItem = (currentFile) => { 
  queue.value.push(currentFile);   
}

const uploadFile = (currentFile) => { 
  let formData = new FormData();  
  formData.append('file', currentFile, currentFile.name); 
 
  isUploading.value = true;

  $api.postMedia(formData, (progressEvent) => {
    emits('progress', Math.round((progressEvent.loaded * 100) / progressEvent.total), currentFile.uploading_id);
  }, props.url) 
  .then(response => { 
    console.log('uploadFile - response');
    // console.log(response.data.data);
    // console.log('Received response for file with ID:', currentFile.uploading_id, response);
    clearThumbnails();
    uploadedEvent(currentFile.uploading_id, response.data);
    deleteQueueItem(currentFile);
    isUploading.value = false;
    // nextStepQueue();
  })
  .catch(error => {
    errorEvent(currentFile.uploading_id);
    deleteQueueItem(currentFile);
    // nextStepQueue();
    isUploading.value = false;
  });
}


const uploadedEvent = (uploading_id, data) => {      
  emits('uploaded', data, uploading_id); 
}

const errorEvent = (uploading_id, data) => {      
  emits('errored', uploading_id); 
}


const createQueueChecker = () => {
  queueChecker.value =  setInterval(() => {
    console.log('checker');
    if(queue.value.length > 0 && !isUploading.value){
      nextStepQueue();
    }
  }, 1000);
}

const destroyQueueChecker = () => {
  if(queueChecker.value)
    clearInterval(queueChecker.value);
}

onMounted(() => {
  createQueueChecker();
})

onUnmounted(() => {
  destroyQueueChecker();
})


</script>
   

<style scoped>

.uploader{

  position: relative;
}
.up-button{
  background-color: #767676;
  color: #FFFFFF;
  display: inline-block;
  padding: 0.78571429em 1.5em 0.78571429em;
  font-weight: bold;
  line-height: 1em;
  cursor: pointer;
  font-size: 0.75rem;
  border-radius: 20px;
}
input[type="file"]{
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';  
  direction: ltr;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.name{
  width: 100%;
  text-align: center;
}

.drag-field{
  border: 2px dashed #767676;
  width: 100%;
  height: 200px;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  flex-direction: column;
  font-weight: bold;
}
.drag-field.active{
  background: rgb(227, 227, 227);
}
.drag-field i{
  font-size: 3rem;
}
/* wide */


.uploader.btn-wide{
  width: 100%;
} 
.uploader.btn-wide .up-button{
  width: 100%;
}
 
</style>

<template>

    <UiHorizontalScroll
        :arrows="true"
        :arrows-show-hover="true"
    >  
        <nav class="filters-tabs">

            <div class="ftrs">
                <div class="ftrs__name">
                    Filters
                </div>
                <i class="icon-filters"></i>
            </div>

            
            <div 
                :class="{'ftrs__keyword': true, 'active': showKeywordSearch}"
                v-if="!isMobile && existsKeywordSearch"
                
            >
                <div 
                    class="ftrs__name"
                    @click="toggleKeywordSearch"
                >
                    Search <i class="icon-search"></i>
                </div>

                <GeneralFilterTabsKeyword
                    v-show="showKeywordSearch"
                    ref="keywordEl"
                    placeholder="Start typing to search..."
                    v-model:keyword="selectedKeyword"
                    @close="closeKeywordSearch"
                />   
                    
            </div>

            <div
                v-for="(category, index) in filterCategories"
                :key="category.id"
                :ref="el => (triggerRefs[index] = el)"
                :class="{'filters-tabs__item': true, 'active': selectedCategory(category.id)}"
                @click="toggleDropdown(category, index)"
            >
                <span class="filters-tabs__title">
                    {{ category.name }}
                    <i class="icon-caret-down"></i>
                </span>
            </div>
        </nav>

    </UiHorizontalScroll>

  
<ClientOnly>
    <Teleport to="#teleport">

        <div 
            v-if="!isMobile && showDropdown" 
            class="filters-dropdown-portal"
            :style="dropdownStyle"
            @click.self="closeDropdown"
        >
            <div class="filters-dropdown-content">
                <div class="filters-dropdown">
                    <GeneralFilterTabsIconButtons
                        v-if="activeCategory.type === 'icon-buttons'"
                        :name="activeCategory.name"
                        :tags="activeCategory.tags"
                        v-model:selected="selectedTags"
                        @close="closeDropdown"
                    />
                    <GeneralFilterTabsCheckboxSearch
                        v-else-if="activeCategory.type === 'checkboxes'"
                        :name="activeCategory.name"
                        :tags="activeCategory.tags"
                        v-model:selected="selectedTags"
                        @close="closeDropdown"
                    />
                </div>
            </div>
        </div>
        


        <UiModal
        v-if="isMobile && showDropdown"
        :width="'100%'"
        :height="'100%'"
        title="Filters"
        :is-auto-height="false"
        :is-rounded="false"
        :is-mobile-margin="false"        
        :close-button-name="'Close'"
        @close="closeDropdown"
        >
            <div class="modal-backdrop">


                <GeneralFilterTabsKeyword
                    placeholder="Search by Strain Name"
                    v-model:keyword="selectedKeyword"
                />   

                <UiAccordion
                    :items="filterCategories"
                    :isMultiActive="false"
                    :highlight-items="selectedCategories"
                    v-model:active-id="openSections" 
                >                   
                    <template #default="{ item }">
                    <!-- <p>Content for: {{ item.title }}</p> -->
                    <GeneralFilterTabsIconButtons
                        v-if="item.type === 'icon-buttons'"
                        :name="item.name"
                        :tags="item.tags"
                        v-model:selected="selectedTags"
                    />
                    <GeneralFilterTabsCheckboxSearch
                        v-else-if="item.type === 'checkboxes'"
                        :name="item.name"
                        :tags="item.tags"
                        v-model:selected="selectedTags"
                    />
                    </template>
                </UiAccordion>
                
                    
                <!-- <div
                    v-for="cat in filterCategories"
                    :key="cat.id"
                    :class="{
                        'filters-modal-content': true,
                        'active': openCategories.includes(cat.id)
                    }"
                >
                    <GeneralFilterTabsIconButtons
                        v-if="cat.type === 'icon-buttons'"
                        :name="cat.name"
                        :tags="cat.tags"
                        v-model:selected="selectedTags"
                    />
                    <GeneralFilterTabsCheckboxSearch
                        v-else-if="cat.type === 'checkboxes'"
                        :name="cat.name"
                        :tags="cat.tags"
                        v-model:selected="selectedTags"
                    />
                </div> -->
            </div>
        </UiModal>
    
        
        <div 
            class="click-close"
            v-if="showDropdown"
            @click="closeDropdown"
        ></div>

    </Teleport>
</ClientOnly>


 
</template>

<script setup>

import { ref, computed, onMounted, onBeforeUnmount } from 'vue'

const emits = defineEmits(['update:selected', 'update:keyword'])


const props = defineProps({
    filterCategories: {
        type: Array,
        required: true
    },
    selected: {
        type: Array,
        default: () => []
    },
    keyword: {
        type: String,
        default: ''
    },
    existsKeywordSearch: {
        type: Boolean,
        default: false
    }
})


const isMobile = ref(false)
const selectedTags = ref(props.selected)
const selectedKeyword = ref(props.keyword)
const triggerRefs = ref([])
const activeTriggerRef = ref(null)
const activeCategory = ref(null)
const activeCategoryIndex = ref(null)
const showDropdown = ref(false)
const dropdownPosition = ref({ top: 0, left: 0 })
const showKeywordSearch = ref(false)

const openCategories = ref([])
const openSections = ref([])

const keywordEl = ref(null)

watch(selectedTags, (newVal) => {    
    emits('update:selected', newVal)
    console.log(1111111);
    if(!isMobile.value){
        closeDropdown()   
    }
}, { deep: true })

watch(selectedKeyword, (newVal) => {
    emits('update:keyword', newVal)
})

watch(props, (newVal) => {
    if(selectedTags.value !== newVal.selected)
        selectedTags.value = newVal.selected
    if(selectedKeyword.value !== newVal.keyword)
        selectedKeyword.value = newVal.keyword
}, { deep: true })


const toggleKeywordSearch = () => {
    showKeywordSearch.value = !showKeywordSearch.value

    if (showKeywordSearch.value && keywordEl.value) {
        keywordEl.value.focusInput()
    }
}

const closeKeywordSearch = () => {
    showKeywordSearch.value = false
}

const selectedCategory = (tagId) => {
    return props.filterCategories.find((cat) => cat.id === tagId).tags.some((tag) => selectedTags.value.includes(tag.id))
}

const selectedCategories = computed(() => {
    return props.filterCategories.filter((cat) => cat.tags.some((tag) => selectedTags.value.includes(tag.id))).map((cat) => cat.id)
})

const toggleDropdown = (category, index) => {

    if (activeCategory.value === category && showDropdown.value) {
        showDropdown.value = false
        openSections.value = []
    } else {
    
        activeCategory.value = category
        activeCategoryIndex.value = index
        showDropdown.value = true

        openSections.value = [category.id]


        positionDropdown(index)
    }
}


const closeDropdown = () => {
    showDropdown.value = false
    activeCategory.value = null
    activeCategoryIndex.value = null
    openSections.value = []
}


const dropdownStyle = computed(() => ({
  top: dropdownPosition.value.top + 'px',
  left: dropdownPosition.value.left + 'px',
  position: 'absolute'
}))


const positionDropdown = (index) => {


  activeTriggerRef.value = triggerRefs.value[index]

  if (!activeTriggerRef.value) return

  const rect = activeTriggerRef.value.getBoundingClientRect()
  dropdownPosition.value.top = rect.bottom + window.scrollY
  dropdownPosition.value.left = rect.left + window.scrollX

//   console.log('activeTriggerRef.value', activeTriggerRef.value)
//   console.log('dropdownPosition', dropdownPosition.value)


}


// const handleWindowEvents = () => {
//   if (showDropdown.value && activeCategoryIndex.value !== null) {
//     positionDropdown(activeCategoryIndex.value)
//   }
// }


onMounted(() => {
  if (typeof window === 'undefined') return

  isMobile.value = window.innerWidth < 768
  window.addEventListener('resize', () => {
    isMobile.value = window.innerWidth < 768
  })


  // window.addEventListener('scroll', handleWindowEvents, true)
  // window.addEventListener('resize', handleWindowEvents)
})


// onBeforeUnmount(() => {
//   if (typeof window === 'undefined') return
//   window.removeEventListener('scroll', handleWindowEvents, true)
//   window.removeEventListener('resize', handleWindowEvents)
// })
</script>

<style scoped>
.filters-tabs {
  display: flex;
  gap: 1rem;
  position: relative;
}


.filters-tabs__title {
  cursor: pointer;
}


.modal-backdrop {
  padding: 0rem;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  scroll-behavior: smooth;
  gap: 1.5rem;
  font-size: 1rem;
  height: 100%;
}

.modal-backdrop:deep(.head) {
  display: none;
}

.filters-modal-content {}

.filters-modal-content:deep(i) {
  display: none;
}

.filters-dropdown {
  position: absolute;
  top: -3.4rem;
  left: -1.6rem;
  border-radius: 10px;
  background: white;
  border: 1px solid var(--un-element-color-gray-light);
  padding: 1.5rem;
  z-index: 1003;
  min-width: calc(80px* 3 + 2.7rem);
  box-shadow: 0 3px 6px rgba(25, 25, 25, .16);
}


.click-close {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.ftrs {
  color: var(--un-text-color-gray);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ftrs i {
  line-height: 0.5rem;
}

@container pb (max-width: 650px) {
  .filters-tabs {
    gap: 0.5rem;
  }
}

/* search */
.ftrs__keyword {
  /* color: var(--un-text-color-gray); */
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.ftrs__keyword .ftrs__name {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
}

.ftrs__keyword i {
  line-height: 0.5rem;
}

.ftrs__keyword.active .ftrs__name {
  display: none;
}

/*  */



.filters-tabs>.filters-tabs__item,
.filters-tabs>.ftrs {
  min-height: 40px;
  align-items: center;
  display: flex;
  flex-shrink: 0;
}

.filters-tabs>.filters-tabs__item.active i {
  background-color: var(--un-firm);
  color: white;
  border-radius: 50%;
  display: inline-flex;
  aspect-ratio: 1 / 1;
  width: 15px;
  justify-content: center;

}
</style>
<template>
  
  <div class="pb giveaways_page">

    <div class="giveaways_content gw_content" v-if="giveaway_data?.id">
 
      <UiBread
        :list="[
          {name: $t('giveaways_view_link_text'), link: '/giveaways'},
          {name: giveaway_data.name, journal_data: data?.link},
        ]"
        position="left"
        /> 

      <br/>

      <div class="top_info">

        <div class="cover_box">

          <NuxtLink class="cover" :to="'/giveaways/' + giveaway_data.link" :style="'background-image: url(' + giveaway_data.cover_back + ')'"></NuxtLink>

          <div class="actions">

            <div class="rules">



              <div v-if="giveaway_data.is_join && giveaway_data.enable_join && !is_participating">


                <UiButtonDropdown
                  :name="$t('giveaways_view_button_join_now')"
                  :ico="'icon-rocket'"
                  :type="'wide center'"
                  :options="joinActions"
                  @choose="modalAddress" 
                  />

              </div>

              <div v-else-if="!giveaway_data.is_close">

                <div v-if="giveaway_data.is_join && giveaway_data.enable_join && giveaway_data.multi_join">

                  <UiButtonDropdown
                    :name="$t('giveaways_view_button_join_now')"
                    :ico="'icon-rocket'"
                    :type="'wide center'"
                    :options="joinActions"
                    @choose="modalAddress" 
                    />
                 

                </div>

                <div v-if="is_participating">

                  <UiButton type="secondary wide" @click="withdraw()">
                    <i class="icon check circle"></i>
                    {{ $t('giveaways_view_button_withdraw') }}
                  </UiButton>

                </div>
                
                <div v-else>

                  <div class="not_part">
                    <i class="icon remove circle"></i>
                    {{ $t('giveaways_view_button_not_participating') }}
                  </div>

                </div>

              </div>

              <div v-html="giveaway_data.rules"></div>

            </div>

          </div>

        </div>

        <div class="info">

          <div class="desc">

            <h1>{{ giveaway_data.name }}</h1>

            <div class="info_row">
              <div class="info_item">
                <i :class="'world_ico' + (giveaway_data.shipping_warning ? '_warning' : '')"></i>
                <div class="info_name">{{ giveaway_data.shipping }}</div>
              </div>
              <div class="info_item">
                <i :class="giveaway_data.count_contest_ico"></i>
                <div class="info_name">{{ _replace(giveaway_data.count_contest_text) }}</div>
              </div>
              <div class="info_item">
                <i class="timer_ico"></i>
                <div class="info_name">{{ giveaway_data.is_vote ? 'Results to be announced soon' : $dayjs.utc(giveaway_data.timer_date).fromNow() }}</div>
              </div>
              <div class="info_item" v-if="giveaway_data.cnt_comments">
                <i class="icon comment"></i>
                <div class="info_name">{{ giveaway_data.cnt_comments }} {{ $plurals.get('comment', giveaway_data.cnt_comments) }}</div>
              </div>
              <a v-if="giveaway_data.info_name" :href="giveaway_data.url" target="blank" class="info_item">
                <i class="external alternate icon"></i>
                <div class="info_name">{{ giveaway_data.info_name }}</div>
              </a>
            </div>

            <div class="give_desc_info">
              <div v-html="giveaway_data.info"></div>
            </div>

            <div v-if="giveaway_data.prize">
              <h2>{{ $t('giveaways_view_prizes') }}</h2>
              <a class="prizes" :href="giveaway_data.prize" @click.stop.prevent="openGallery(0)">
                <img :src="giveaway_data.prize_small"/>
              </a>
            </div>

            <div v-if="giveaway_data.jury">
              <h2>{{ $t('giveaways_view_jury') }}</h2>
              <a class="prizes" :href="giveaway_data.jury" @click.stop.prevent="openGallery(1)">
                <img :src="giveaway_data.jury"/>
              </a>
            </div>


            <GeneralLightbox 
              v-if="lightboxShow"
              :index="lightboxIndex" 
              :photos="lightboxPhotos" 
              @close="lightboxShow = false" 
              />
        

          </div>

        </div>

      </div>

      <GiveawayTimer
        v-if="giveaway_data.is_vote"
        :timeunix="giveaway_data.countdown"
        />






      <br>

      <UiTabs 
        :tabs="tabsCategories" 
        :acttab="activeTabCategory"
        :centered="true"
        @choose="toggleTabsCategory"
        />    

        
      <div class="content">
 
        <div 
          v-for="(item_category, item_key) in giveaway_data.items_category" 
          :key="item_key"
          class="item_content" 
          :data-id="item_category.id"
          >

          <div v-if="item_key == activeTabCategory">

            <GiveawayParticipantDiaries v-if="item_category.type_content == 1"
              :list="item_category.items"
              :type_content="item_category.type_content"
              :max_winners="item_category.max_winners"
              :enable_vote="giveaway_data.enable_vote"
              :is_vote="giveaway_data.is_vote"
              :is_join="giveaway_data.is_join"
              :is_start="giveaway_data.is_start"
              :is_close="giveaway_data.is_close"
              :game_id="giveaway_data.id"
              :category_id="item_category.id"
              />


            <GiveawayParticipantUsers 
              v-else-if="item_category.type_content == 2 && giveaway_data.type == 2"
              />


            <!-- growers problems-solutions -->
            <GiveawayParticipantUsers v-else-if="item_category.type_content == 2 && (giveaway_data.type == 2 || giveaway_data.type == 6)"
              :list="item_category.items"
              :type="giveaway_data.type"
              :type_content="item_category.type_content"
              :enable_vote="giveaway_data.enable_vote"
              :is_vote="giveaway_data.is_vote"
              :is_start="giveaway_data.is_start"
              :is_close="giveaway_data.is_close"
              :game_id="giveaway_data.id"
              :category_id="item_category.id"
              />


            <GiveawayParticipantUsers v-else-if="item_category.type_content == 2 && (giveaway_data.type != 2 && giveaway_data.type != 6)"
              :list="item_category.items"
              :type="giveaway_data.type"
              :type_content="item_category.type_content"
              :enable_vote="giveaway_data.enable_vote"
              :is_vote="giveaway_data.is_vote"
              :is_start="giveaway_data.is_start"
              :is_close="giveaway_data.is_close"
              :game_id="giveaway_data.id"
              :category_id="item_category.id"
              />


            <GiveawayParticipantPhotos v-else-if="item_category.type_content == 3 || item_category.type_content == 5"
              :list="item_category.items"
              :type_content="item_category.type_content"
              :enable_vote="giveaway_data.enable_vote"
              :is_vote="giveaway_data.is_vote"
              :is_start="giveaway_data.is_start"
              :game_id="giveaway_data.id"
              :category_id="item_category.id"
              />


            <GiveawayParticipantVideos v-else-if="(item_category.type_content == 4 || item_category.type_content == 6)"
              :list="item_category.items"
              :type_content="item_category.type_content"
              :enable_vote="giveaway_data.enable_vote"
              :is_vote="giveaway_data.is_vote"
              :is_start="giveaway_data.is_start"
              :game_id="giveaway_data.id"
              :category_id="item_category.id"
              />

          </div>
        </div>


        <div class="right_panel">


          <GiveawayBoxCompactList
            v-if="giveaway_data.items_completed?.length"
            :list="giveaway_data.items_completed"   
            :gw_title="$t('giveaways_view_more_giveaways')" 
            />


          <CommentWidget v-if="data && giveaway_data.id"
            :title="$t('breeder_view_subtitle_latest_comments')"
            :type="'game'"
            :content="giveaway_data.id"
            />

        </div>

      </div>

    </div>



    <ClientOnly>
      <Teleport to="#app">


        <GiveawayJoin
          :data="giveaway_data"
          :category="category"
          :active="active_modal_join"
          @close="closeAddressModalJoin()"
          @hide="closeAddressModal()"
          />
          
      </Teleport>
    </ClientOnly>


  </div>

</template>

<script setup>
const route = useRoute();
const router = useRouter();
const { t } = useI18n()
const { $api, $popup } = useNuxtApp() 



const data = ref({});
const category = ref({});
const active_modal_join = ref(false);
const activeTabCategory = ref(0);


var id = ref(route.params['var']);


const is_participating = computed(() => {
  return giveaway_data.value?.addon?.participant_categories?.length;
})

const modalAddress = function(event, cat){

  category.value = cat.category;

  if(!useAuth().isAuth()){
    $popup.error(t('giveaways_view_please_login'));
    return false;
  }
  active_modal_join.value = true;
  
}

const withdraw = function(){
  if(!confirm(t('giveaways_view_button_withdraw') + '?')){
    return false;
  }
  $api.postGiveawayWithdraw(giveaway_data.value.id)
  .then((res) => {
    $popup.success('Saved');
    giveaway_data.value.addon.participant_categories = [];
  })
  .catch((error) => {
    console.log('error');
  })
}

const closeAddressModalJoin = function(){   
  active_modal_join.value = false;
}

const closeAddressModal = function(){   
  active_address.value = false;
}

const toggleTabsCategory = function(id){
  activeTabCategory.value = id;
}
 
const defaultTabCategory = computed(() => {
  return giveaway_data.value.items_category[0].id
})


const tabsCategories = computed(() => {
  var items = [];

  giveaway_data.value.items_category.forEach((item_category, item_key) => {
    items.push({
      id: item_key,
      name: item_category.name,
    });
  });

  return items;
})

const _replace = function(text){
  return text ? text.replace(
    /{(.+?)}/g,
    (match, lng_key) =>  t(lng_key)
  ) : '';
}

const joinActions = computed(() => {
  var items = [];

  giveaway_data.value.items_category.forEach((item_category, item_key) => {
    items.push({
      name: item_category.name,
      event: 'choose',
      category: item_category
    });
  });

  return items;
})


// GALLERY

var lightboxShow = ref(false);
var lightboxIndex = ref(0);


const lightboxPhotos = computed(() => {
  var items = [];

  if(giveaway_data.value.prize){
    let photo = {}; 
    photo.base = giveaway_data.value.prize;
    photo.zoom = giveaway_data.value.prize;
    photo.caption = '';
    items.push(photo);   
  }

  if(giveaway_data.value.jury){
    let photo2 = {}; 
    photo2.base = giveaway_data.value.jury;
    photo2.zoom = giveaway_data.value.jury;
    photo2.caption = '';
    items.push(photo2);   
  }
  
  return items;
})

const openGallery = function(key){
  lightboxShow.value = true;
  lightboxIndex.value = key;
}

// end GALLERY





const loadData = async (id) => {
  return await $api.getGiveaway(id);  
}



const { pending: is_loading,  data: giveaway_data } = await useLazyAsyncData('giveaway_data_' + id.value, async () => await loadData(id.value))

 
// useHead(useNuxtApp().$head.getGiveawayItem(giveaway_data.value));


watch( () => giveaway_data, () => {
  useHead(useNuxtApp().$head.getGiveawayItem(giveaway_data.value));
}, {immediate: true, deep: true});

watch( () => router.currentRoute, () => {
  useHead(useNuxtApp().$head.getGiveawayItem(giveaway_data.value));
}, {immediate: true, deep: true});


onUnmounted(() => {
  giveaway_data.value = null;
})


</script>

<style scoped>


/* GW ITEM PAGE */
.gw_content {}

.gw_content .coming_soon_giveaway {
  background-color: whitesmoke;
  text-align: center;
  padding: 60px;
  color: gray;
  margin-top: 18px;
}

.gw_content .top_info {display: flex;width: 100%;flex-direction: row;align-items: flex-start;}


.gw_content .top_info .cover_box {width: 400px;margin-right: 40px;}

.gw_content .top_info .cover_box .cover {
  display: flex;
  background-repeat: no-repeat;
  border-radius: 10px;
  background-size: cover;
  background-position-x: center;
  height: 420px;
  width: 100%;
  margin-bottom: 1rem;
}

.gw_content .top_info .cover_box .actions {

}


@media (max-width: 1000px) {

  .gw_content .top_info .cover_box{
    width: 310px;
  }

}

@media (max-width: 768px) {

  .gw_content .top_info{
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .gw_content .top_info .cover_box{
    margin-right: 0;
    width: 100%;

  }

}



/* DESC */


.gw_content .top_info .info {width: calc(100% - 440px);}

.info {}

.gw_content .top_info .info .row {
  display: flex;
}

.gw_content .top_info .info .row .title {
  min-width: 92px;
  color: gray;
}

.gw_content .top_info .info .name {
  font-size: 1.4rem !important;
  font-weight: bold;
  color: #484848 !important;
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px #dedede solid;
  margin-top: 5px;
}

.gw_content .info .desc .prizes {}

.gw_content .info .desc .prizes img {
  width: 100%;
  max-width: 540px;
}

.gw_content .info .desc img {
  width: 100%;
  max-width: 540px;
  height: auto;
}

.gw_content .info h1 {
  font-size: 1.7rem;
  margin-bottom: 1rem;
}

.gw_content .info .desc {
  width: 100%;
}

.gw_content .info .desc table {}

.gw_content .info .desc table td {
  padding: 8px 10px;
  border: 1px white solid;
}


.gw_content .info .prize_country {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.gw_content .info .prize_country .shipping_name {}

.gw_content .info .prize_country i {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.gw_content .info .rules {
  background-color: var(--un-background-color-gray);
  border-radius: 5px;
  padding: 20px;
  font-size: 0.85rem;
  margin-bottom: 15px;
}

.gw_content .info .rules .btn_action {
  margin-bottom: 15px;
}

.gw_content .rules .not_part {
  margin-bottom: 15px;
  background-color: var(--un-background-color-gray);
  color: gray;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  justify-content: center;

}
.gw_content .info .actions ul {
  padding-left: 15px;
  margin: 0px;
}

.gw_content .info {
  display: flex;
  width: 100%;
  align-items: flex-start;
}



.gw_content .info .info_row {
  position: relative;
  display: flex;
  flex-direction: row;
  /* margin-top: 10px; */
  justify-content: flex-start;
  margin-bottom: 22px;
  margin-top: -7px;
  flex-wrap: wrap;
  gap: 10px 20px;
}

.gw_content .info .info_row .info_item {
  text-align: left;
  display: flex;
  align-items: center;
}

.gw_content .info .info_row .info_name {
  font-size: 0.75rem; 
  margin-left: 5px;
  width: calc(100% - 30px);
  white-space: nowrap;
}

.gw_content .info .info_row i {
  width: 20px;
  height: 20px;
}
.gw_content .info .give_desc_info {
  overflow-wrap: break-word;
}

@media (max-width: 1000px) {

  .gw_content .top_info .info {
    width: calc(100% - 350px);
  }

}
@media (max-width: 768px) {

  .gw_content .top_info .info {
    width: 100%;
    margin-top: 1rem;
  }

  .gw_content .top_info .info .desc {
    width: 100%;
    margin-right: 0px;
  }


}



/* CONTEST LIST */
.gw_content>.content .btn {
  display: inline-block;
  min-height: 38px;
  outline: none;
  border: none;
  vertical-align: baseline;
  /* font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif; */
  margin: 0em 0.25em 0em 0em;
  padding: 0.78571429em 0.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  /* font-weight: bold; */
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  width: 90px;
  background-color: #c7c7c7;
  color: white;
  vertical-align: middle;
  cursor: pointer;
}

.gw_content>.content .btn:hover {
  background-color: #eabf2f;
  color: white;
}

.gw_content>.content .likes.active .btn {
  background-color: #eabf2f;
  color: white;
}

.gw_content>.content .show_votes {
  cursor: pointer;
}

.gw_content>.content .btn_cnt {
  display: inline-block;
  min-height: 38px;
  outline: none;
  border: none;
  vertical-align: baseline;
  /* font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif; */
  margin: 0em 0.25em 0em -11px;
  padding: 0.78571429em 0.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  /* font-weight: bold; */
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0 0.28571429rem 0.28571429rem 0;
  width: 50px;
  background-color: #c7c7c7;
  color: white;
  cursor: pointer;
  vertical-align: middle;
  border-left: 1px white solid;
}

.gw_content>.content .btn_cnt:hover {
  background-color: gray;
  color: white;
}

.settings_comments {
  align-items: center;
  width: 100%;
  margin-top: 25px;
  border-bottom: 1px #dededf solid;
  padding-bottom: 11px;
  margin-bottom: 30px;
}

.settings_comments .type_view_comments_sort {
  text-align: right;
}











.touch_slider{
    position: relative;
    overflow: hidden;
}

.touch_slider_scroll{
    overflow-x: auto;
    text-align: center;
}

.touch_slider_scroll::-webkit-scrollbar {
display: none;
}

.touch_slider_content{
    white-space: nowrap!important;
    display: inline-block
}


.touch_slider .shadow_left .shadow_inner,
.touch_slider .shadow_right .shadow_inner{
       background-color: white;
    padding: 7px 0px 17px 0px;
    border-radius: 30px;
    height: 47px;
    width: 47px;
    cursor: pointer;
    text-align: center;
}

.touch_slider .shadow_right i,
.touch_slider .shadow_left i{
    margin: 0px;
}

.touch_slider .shadow_left:hover .shadow_inner,
.touch_slider .shadow_right:hover .shadow_inner{
    background-color: white;
}
.touch_slider .shadow_left{
    position: absolute;
    left: -1px;
    top:0;
    z-index: 10;
    display: none;
    font-size: 1.7em;
    padding: 3px 0px 9px 0px;

}
.touch_slider .shadow_right{
    position: absolute;
    right: -1px;
    top:0;
    z-index: 10;
    display: none;
    font-size: 1.7em;
    padding: 3px 0px 9px 0px;

}

@media (min-width: 700px) {
    .touch_slider .shadow_right
    .touch_slider .shadow_left{
        display: none;
    }
}

</style>
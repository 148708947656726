<template>

  <!-- https://nuxt.com/modules/nuxt-social-share -->
 
  <Teleport to="#app">
    <UiModal
      :title="props.title"   
      close-button-name="Close"
      :width="'100%'"
      :max-width="'600px'"
      :is-auto-height="true"
      @close="close()"  
      >        

      <UiHorizontalScroll
        :arrows="true"
        >

        <div class="list">

          <SocialShare
            v-for="network in ['whatsapp','facebook', 'twitter', 'email', 'linkedin', 'reddit', 'pinterest', 'telegram', 'telegram']"
            :key="network"
            :network="network"
            :styled="true"
            :label="false"
            class="item rounded"
            />
            
        </div>
      </UiHorizontalScroll>


      <div class="share-input-box">
        <input class="share-input" type="text" v-model="linkForShare" readonly>
        <UiButton 
          type="primary" 
          @click="copyLink" 
          :name="'Copy'"
          />
      </div>

    </UiModal>
  </Teleport>
</template>


<script setup>

const emits = defineEmits(['close']);

import { useClipboard } from '@vueuse/core'

const { text: copyText, copy, copied: copyCopied, isSupported: copyIsSupported } = useClipboard()

const { t } = useI18n();
const { $api, $popup } = useNuxtApp();

const props = defineProps({
  showed: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: 'Share',
  },
  link: {
    type: String,
    default: '',
  },
});

// const elInfinityScroll = ref(null) 
const linkForShare = ref(props.link ? props.link : document.location.href);


const copyLink = function(){
  copy(linkForShare.value);
  $popup.success('Link copied');
}
 
const close = () => {
  emits('close');
};

 
</script>
 


<style scoped>
   
.list{
  display: flex;
  gap: 1rem;
}
.item{
  width: 65px;
  aspect-ratio: 1/1;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.rounded{
  border-radius: 50px;
}

.share-input-box{
  position: relative;
  margin-top: 1rem;
}
.share-input-box .share-input{
  padding-right: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 50px;
  background-color: var(--un-background-color-gray);
}
.share-input-box:deep(.btn){
  position: absolute;
  top: 5px;
  right: 10px;
}

@media (max-width: 768px) {
  

}

 

</style>
<template>

  <div class="product_review" v-for="(items, items_category) in aggrData">

    <div class="divider">{{ $t('reviews_title') }}.  {{ $t('reviews_' + items_category) }}</div>
  
    <div 
      v-for="(review, review_key) in items"
      :key="review_key"
      class="row_nutrient">

        <div class="logo">
          <img :src="review.item_brand.logo_s " class="logo_img">
        </div>
        <div class="name">
          <b>{{ review.item_brand.name }}</b>
        </div>
        <div class="comment">            
 
          <UiTextArea 
            :text="review.text"
            @change:text="review.text = $event"
            />
                     
          <div class="nuts_items">
            
              <div 
                v-for="(review_c, review_c_key) in review.items_review"
                :key="review_c_key"
                class="nuts_item">
                
                <div class="photo">
                  <img :src="review_c.item_product.cover_s" class="photo_img">
                </div>
                <div class="info">
                  <b>{{ review_c.item_product.name }}</b>
                  <div>                    
                    <UiRating
                      :cnt="10"
                      :vl="review_c.n_general"
                      :editable="true"
                      @change="review_c.n_general = $event"
                      />
                  </div>
                </div>
              </div>
 
          </div>

      </div>
    </div>
 
  </div>
  
</template>

<script setup>


import { ProductCategorySort }  from "@/types/product"

const props = defineProps({
  reviews: {
    type: Array,
    default: []
  },  
})
 
 


const sortByCategory = (a, b) => {
  return ProductCategorySort.indexOf(a.item_brand.category) - ProductCategorySort.indexOf(b.item_brand.category);
}

const aggrData = computed(() => {

  const aggregated = props.reviews.reduce((acc, item) => {
    if (!acc[item.item_brand.category]) {
      acc[item.item_brand.category] = []
    }
    acc[item.item_brand.category].push(item)
    return acc
  }, {})

  // Sort the aggregated data by category
  const sortedAggregated = {}
  ProductCategorySort.forEach(category => {
    if (aggregated[category]) {
      sortedAggregated[category] = aggregated[category]
    }
  })

  return sortedAggregated
})


 


</script>

<style scoped>
  
.row_nutrient{

    margin-bottom: 1rem;
}
.row_nutrient .logo{
    display: inline-block;
    vertical-align: top;
    width: 90px;
    text-align: center;

}
.row_nutrient .logo .logo_img{
    max-height: 70px;
    max-width: 70px;
}
.row_nutrient .name{
    display: inline-block;
    width: 120px;
    vertical-align: top;
    margin-top: 19px;
    margin-left: 20px;
    line-height: 1.1rem;
}
.row_nutrient .name > a{
    font-weight: bold;
    color: var(--un-text-color);
    font-size: 1.1rem;
} 
.row_nutrient .comment > p{
    background-color: rgb(247, 247, 247);
    padding: 20px;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    width: 100%;
}
.row_nutrient .comment > p:before {
    content: "\A";
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent rgb(247, 247, 247) transparent transparent;
    position: absolute;
    left: -10px;
    top: 21px;
}
.row_nutrient .comment .btn_translate{
  margin-top:-10px;
  margin-bottom:10px;
  display:block;
}
.row_nutrient .comment{
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 260px);
    margin-left: 20px;
}
.row_nutrient .comment .nuts_items{
  margin-top: 1rem;
}
.row_nutrient .comment .nuts_items .nuts_item{
  display: flex;
  margin-bottom: 9px;
  gap: 10px;
}
.row_nutrient .comment .nuts_items .nuts_item .photo{
    display: inline-block;
    vertical-align: top;
    max-height: 46px;
    max-width: 43px;
    width: 50px;
    text-align: center;
}
.row_nutrient .comment .nuts_items .nuts_item .photo .photo_img{
    max-height: 46px;
    max-width: 43px;
}
.row_nutrient .comment .nuts_items .nuts_item .info{
    /* display: inline-block;
    vertical-align: top;
    width: calc(100% - 50px); */
}
.row_nutrient .comment .nuts_items .nuts_item .info > a{
    color: var(--un-text-color);
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}


@container pb (max-width: 600px) {

  .row_nutrient{ 
  display: grid; 
  grid-template-columns: 60px 1fr; 
  grid-template-rows: auto auto;
  gap: 0px 0px; 
  grid-template-areas: 
    ". ."
    "d1 d1";  
  }
  .row_nutrient .logo img{
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .row_nutrient .name{

    margin-top: 5px;
    margin-left: 0;
    width: 100%;
  }
  .row_nutrient .nuts_items{
    display:flex;
    align-items: flex-start;
    justify-self: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .row_nutrient .logo{
    width: 40px;
  }

  .row_nutrient .comment{
    grid-area: d1;
      width: calc(100%);
      margin-left: 0;
  }
  .row_nutrient .comment .nuts_items .nuts_item{
    width: fit-content;
  }
} 
 
</style>

<template>

  <div class="context_list">

    <div class="row">
      <div class="label">
        {{ $t('profile_menu_change_password_old_pass') }} 
      </div>
      <div class="field_input">
        <input type="password" v-model="oldPassword" placeholder="">
      </div>
    </div>

    <div class="row">
      <div class="label">
        {{ $t('profile_menu_change_password_new_pass') }} 
      </div>
      <div class="field_input">
        <input type="password" v-model="newPassword" placeholder="">
      </div>
    </div>

    <div class="row">
      <div class="label">
        {{ $t('profile_menu_change_password_new_pass2') }} 
      </div>
      <div class="field_input">
        <input type="password" v-model="repeatPassword" placeholder="">
      </div>
    </div>

    <div class="btn primary" @click="handleSavePassword" type="is-primary">{{ $t('universal_button_save') }}</div>  


  </div> 

</template>


<script setup lang="ts">
 
 import type { User } from '@/types/user'

const emits = defineEmits(['close']);

const { $popup, $api } = useNuxtApp();
const { t } = useI18n();

interface Props {
  user: User,
  userId: number,
}

const props = defineProps<Props>();

const isSaving = ref<boolean>(false)
const oldPassword = ref<string>('')
const newPassword = ref<string>('')
const repeatPassword = ref<string>('')

const handleSavePassword = function(){

  oldPassword.value = oldPassword.value || '';
  newPassword.value = newPassword.value || '';
  repeatPassword.value = repeatPassword.value || '';

  if(!oldPassword.value.length || !newPassword.value.length || !repeatPassword.value.length){
    $popup.error(t('profile_menu_change_password_error_fill_in_all'));
    return false;
  }

  if(newPassword.value != repeatPassword.value){
    $popup.error(t('profile_menu_change_password_error_passwords'));
    return false;
  }

  isSaving.value = true;

  $api.patchProfilePassword(props.userId, {
    password_old: oldPassword.value,
    password_new: newPassword.value,
    password_new2: repeatPassword.value
  })
  .then((res) => {
    isSaving.value = false;
    $popup.success(t('profile_menu_change_password_message_save_success'));
  })
  .catch(function (error) {
    $popup.error('Wrong password');
    isSaving.value = false;
  });

}
 

</script>

<style scoped>
  .ctx{        
    min-width: 59px;
    display: flex;
    justify-content: center;
  }
  .ctx > i{    
    padding: 14px 20px 14px 25px;
    cursor: pointer;
  }


  /*  */

  .context_list{ 
    max-width: 400px;
    /* margin: 0 auto; */
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .context_list .row,
  .context_list .field_input,
  .context_list input{
    width: 100%;

  }
 
</style>

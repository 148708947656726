<template>

  <div class="gstat">

    <div class="divider">
      {{ $t('universal_grow_germ_method_title') }}      
    </div>
 


    <div class="success_cont">

      <div class="seeds">
          
        <div class="method" v-if="data.item_germination.germ_method?.id">
          

          <div class="nm">
            {{ $constants.typeGermMethodShort[data.item_germination.germ_method.id] ? $t($constants.typeGermMethodShort[data.item_germination.germ_method.id]) : '' }}          
          </div>
          <div class="used">
            {{ $t('week_view_germ_success_used1') }}
            {{ $t('week_view_germ_success_used2') }} 
          </div>

          <i :class="'ic ic-germ-method-' + data.item_germination.germ_method.id"></i>

          <div class="stat">
            <div class="ttl">
              {{ $t('week_view_germ_success_stat_title') }}    
            </div>
            <div class="st_item" v-if="$constants.typeGermMethodSuccess[data.item_germination.germ_method.id]">
              <div class="ln">
                <div :class="'lnin ' + classColorPercent($constants.typeGermMethodSuccess[data.item_germination.germ_method.id])" :style="'width: ' + $constants.typeGermMethodSuccess[data.item_germination.germ_method.id] + '%'"></div>
              </div>
              <div class="ht">
                {{ $t('week_view_germ_success_stat_avg_rate') }}  - {{ $constants.typeGermMethodSuccess[data.item_germination.germ_method.id] }}%
              </div>
            </div>
            <div class="st_item" v-if="$constants.typeGermMethodPopularity[data.item_germination.germ_method.id]">
              <div class="ln">

                <div :class="'lnin ' + classColorPercent($constants.typeGermMethodPopularity[data.item_germination.germ_method.id])" :style="'width: ' + $constants.typeGermMethodPopularity[data.item_germination.germ_method.id] + '%'"></div>
              </div>
              <div class="ht">
                {{ $t('week_view_germ_success_stat_popularity') }}  - {{ $constants.typeGermMethodPopularity[data.item_germination.germ_method.id] }}%
              </div>
            </div>
          </div>

        </div>


        
        <div class="arrow" v-if="data.item_germination.germ_method?.id">
          <i class="angle right icon"></i>
        </div>

        <div 
          v-for="(seed, seed_key) in data.item_germination.items_seed"
          :key="seed_key"
          class="seed">

          <NuxtLink class="sed" :to="seed.link" v-if="seed.id">{{ seed.name }}</NuxtLink>
          <div class="sed" v-else>{{ seed.name }}</div>

          <div class="bnk" seed.item_brand.name v-if="seed.item_brand.id">
            <NuxtLink class="photo" :to="seed.link">
              <img :src="seed.item_brand.avatar_little" class="photo_img">
            </NuxtLink>
            <NuxtLink class="bnk" :to="seed.item_brand.link">{{ seed.item_brand.name }}</NuxtLink>
          </div>

          <div class="bnk" seed.item_brand.name v-else>
            <div class="photo">
              <img :src="seed.item_brand.avatar_little" class="photo_img">
            </div>
            <div class="bnk">Custom</div>
          </div>
 
          <div class="chrt">

            <WeekViewGermSeedsChart 
              :total="seed.props.cnt"
              :success="seed.cnt_germ"
              :hint="'{success} of {total}'"
              />
  
          </div>
 
          <div class="stat">
            <div class="ttl">
              {{ $t('week_view_germ_success_stat_avg_rate') }}      
            </div>
            <div class="st_item">
              <div class="ln">
                <div :class="'lnin ' + classColorPercent(seed.avg_success_bank)" :style="'width:  ' + seed.avg_success_bank + '%'"></div>
              </div>
              <div class="ht">
                {{ seed.item_brand.name }} - {{ seed.avg_success_bank }}%
              </div>
            </div>
            <div class="st_item">
              <div class="ln">
                <div :class="'lnin ' + classColorPercent(seed.avg_success_bank)" :style="'width:  ' + seed.avg_success_seed + '%'"></div>
              </div>
              <div class="ht">
                {{ seed.name }} - {{ seed.avg_success_seed }}%
              </div>
            </div>
          </div>
          

        </div>

        
      </div>

      
    </div>

 
  </div>

  
</template>

<script setup>
 
const props = defineProps({
  data: {
    type: Object,
    required: true
  }
});

const classColorPercent = (percent) => {
  if(percent >= 80){
    return 'germ_green';
  }else if(percent >= 50){
    return 'germ_orange';
  }else{
    return 'germ_red';
  }
}
  
</script>

<style scoped>
 
 
 
.success_cont{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
}
.gstat .method{
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 4rem;
    margin-left: 1rem;
}
.gstat .method .nm{
    font-size: 1.3rem;
    font-weight: bold;
}
.gstat .method .used{
    margin-bottom: 1rem;
}
.gstat .method .ic{
    width: 160px;
    height: 160px;
}
.gstat .arrow{
    width: 30px;
    height: 30px;
    background-color: #e0e1e2;
    border-radius: 30px;
    margin: 5em 0 0 0;
    color: white;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gstat .arrow i{
  margin-top: -0.85rem;
  margin-left: 0.4rem;
}
.gstat .seeds{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    align-items: flex-start;
}
.gstat .seeds .seed{
    text-align: center;
    margin-right: 1rem;
    min-height: 310px;
    margin-bottom: 4rem;
    margin-left: 1rem;
    max-width: 190px;
}
.gstat .seeds .seed .sed{
    font-size: 1.3rem;
    font-weight: bold;
    white-space: nowrap; 
    overflow: hidden; 
    display: block;
    width: 100%;
    text-overflow: ellipsis;
}
.gstat .seeds .seed .bnk{
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gstat .seeds .seed .bnk .photo{
  width: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gstat .seeds .seed .bnk .photo .photo_img{
    max-width: 100%;
    aspect-ratio: 1/1;
}
.gstat .seeds .seed .bnk .bnk{}
.gstat .seeds .seed .chrt{
    max-width: 150px;
    margin: 0 auto;
    margin-top: 1.3rem;
    aspect-ratio: 1/1;
    width: 100%;
} 
.gstat .seeds .seed .chrt > *{
  resize:both;
  display:inline-block;
  width: 100%;
  height: auto;
  width: 150px;
  height: 150px;
}  
.gstat .stat{
    text-align: center;
    margin-top: 0.5rem;
}
.gstat .stat .ttl{
    /* font-weight: bold; */
}
.gstat .stat .st_item{
    margin-top: 0.5rem;
}
.gstat .stat .st_item .ln{
    background-color: #e0e1e2;
    height: 5px;
    border-radius: 10px;
    margin-bottom: 0.1rem;
}
.gstat .stat .st_item .ln .lnin.germ_red{
  background-color: #ff7849;
}
.gstat .stat .st_item .ln .lnin.germ_orange{
  background-color: #ffad5a;
} 
.gstat .stat .st_item .ln .lnin.germ_green{
  background-color: #67ad3c;
}
.gstat .stat .st_item .ln .lnin{
    width: 50%;
    height: 100%;
    
}
.gstat .stat .st_item .ht{
    /* font-size: 0.8rem; */
    color: gray;
}

.gstat .ic{
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
  }
  
  .gstat .ic-germ-method-1{ background-image: url(/images/ico_germ_method/paper.svg); }
  .gstat .ic-germ-method-2{ background-image: url(/images/ico_germ_method/glass.svg); }
  .gstat .ic-germ-method-3{ background-image: url(/images/ico_germ_method/chamber.svg); }
  .gstat .ic-germ-method-4{ background-image: url(/images/ico_germ_method/rockwool.svg); }
  .gstat .ic-germ-method-5{ background-image: url(/images/ico_germ_method/peat.svg); }
  .gstat .ic-germ-method-6{ background-image: url(/images/ico_germ_method/substrate.svg); }
  .gstat .ic-germ-method-7{ background-image: url(/images/ico_germ_method/other.svg); }
  
@container pb (max-width: 768px) {
  .gstat .success_cont{

  }
  .gstat .arrow{
    display: none;
  }
}

</style>

<template>

  <div :class="{'pb': true, 'dia': true, 'is-modal': props.modalId}">  

    <template v-if="diaryData && diaryData.id && diaryData.item_user?.id">

      <div class="dat_head">
        
        <DiarywideViewHead 
          :diary="diaryData"
          @remove-diary="handleRemoveDiary"
          @unpublish-diary="handleUnpublishDiary"
          @publish-diary="handlePublishDiary"
          @follow="handleFollowDiary"
          @unfollow="handleUnfollowDiary"
          />

        <DiaryViewConditions 
          :diary="diaryData"
        /> 
      </div>


      <NuxtPage
        v-if="isEditMode"
        :diary="diaryData"
        @create-week-modal="handleCreatingWeekModal"
        @remove-week="handleRemoveWeek"
        @update-week="handleUpdateWeek"
        @cancel-edit-week="handleCancelEditWeek"
      />


      <template v-else>

        <ClientOnly>
          <Teleport to="#teleport">
            <div :class="{'dat_weeks': true, 'is-modal': props.modalId}">
              <DiarywideViewWeeks 
                :weeks="diaryData.items_week"   
                :owner="diaryData.item_user.id"   
                :link="diaryData.link"
                :active-week-id="weekActive?.id"
                :root-week-id="rootWeekId"
                @choose="chooseWeek" 
                @create="handleCreatingWeekModal()"
              />
            </div>
          </Teleport>
        </ClientOnly>
 




        <DiarywideViewGalleryWeeks
          :diary="diaryData"
          :week-active="weekActive"
          :comments="commentsData"
          :is-modal="props.modalId ? true : false"
          @interactWeek="interactWeek"
          @click.comment="toggleComment"
          @remove-week="handleRemoveWeek"
        />


        <AssistantWidget 
          v-if="useAuth().getAccess('view_extra')"
          :content="diaryData.id"
          :type="'diary'"
        />
    
    



        <div 
          v-if="diaryData?.id && weekActive && useAuth().isOwner(diaryData.item_user.id)"
          class="problems"
        >

          <div class="dot-ttl">
            New Grow Question
          </div>

          <QuestionShortForm
            v-if="useAuth().isOwner(diaryData.item_user.id)"
            :diary="diaryData.id"
            :week="lastWeekId"
          />

        </div>

        <!-- <DiarywideViewSimilarDiaries
          :class="{'hidden': !showComments}"
          v-if="diaryData?.id"
          :diary-id="diaryData?.id"
        /> -->

        <ClientOnly>
          <Teleport to="#teleport">
            <DiarywideViewChooseWeekFaza 
              v-if="showCreateWeekModal"
              :diary="diaryData"
              @close="closeCreateWeekModal()"
            />
          </Teleport>
        </ClientOnly>

      </template>




    </template>

    <template v-else>
      <SkDiaryOpened />
    </template> 

    <WeekwideViewContextMenu 
      :diary="diaryData"
      :week="weekActive" 
      @removediary="handleRemoveDiary"
      @publishdiary="handlePublishDiary"
      @unpublishdiary="handleUnpublishDiary"
      @addweek="handleCreatingWeekModal"
      @likeweek="handleLikeWeek"
      @unlikeweek="handleUnlikeWeek"
      @followdiary="handleFollowDiary"
      @unfollowdiary="handleUnfollowDiary"
      />
 
      

  </div>
</template>




<script setup lang="ts">

import { provide } from 'vue';
import type { Diary, Week } from '@/types/diary'


const route = useRoute();
const router = useRouter();
const { $api, $ga, $helper, $popup, $head , $follow} = useNuxtApp();

interface PageProps {
  modalId?: number,
  activeWeekChoose?: string
}
const props = defineProps<PageProps>()

const link = ref(props.modalId ?? route.params.id);
const isLoaded = ref(false);
const commentShow = ref(false);
const commentShowWeekId = ref(null);
const nutrientsShow = ref(false);
const setupShow = ref(false);
const reviewShow = ref(false);
const weekActive = ref<Week | null>(null);
const showCreateWeekModal = ref<Boolean>(props.activeWeekChoose ? true : false);
const diaryId = ref(props.modalId ?? $helper.extractLinkId(route.params['id']));
const routeWeekId = ref(route.params['week'] ?? null);
const light = ref(route.query['light'] ?? null);
 
const isEditMode = computed(() => {
  return route.matched.some((m) => m.path.indexOf('/edit') >= 0);
});

// watch(props, (new_props, old_modalId) => {
//   link.value = new_props.modalId ?? route.params.id;
//   diaryId.value = new_props.modalId ?? $helper.extractLinkId(route.params['id']);
//   routeWeekId.value = route.params['week'] ?? null;
//   weekActive.value = null;
//   diaryData.value = loadData(diaryId.value);
// }, {deep: true})


const toggleSetup = function() {
  setupShow.value = !setupShow.value;
}
provide('actionsToggleSetupEvent', toggleSetup);


const toggleReview = function() {
  reviewShow.value = !reviewShow.value;
}
provide('actionsToggleReviewEvent', toggleReview);


const toggleComment = function(data) {
  commentShow.value = !commentShow.value;
  if(data){ 
    commentShowWeekId.value = data.weekId;
  }
}
provide('actionsToggleCommentEvent', toggleComment);


const toggleNutrients = function() {
  nutrientsShow.value = !nutrientsShow.value;
}
provide('actionsToggleNutrientsEvent', toggleNutrients);


watch(route, (new_route, old_route) => {
  // nextTick(() => {
    // console.log('new_route.params');
    // console.log(new_route.params);
    routeWeekId.value = new_route.params['week'] ?? null;
    // setActiveWeek(routeWeekId.value);
  // })
}, {deep: true})


const rootWeekId = computed(() => {
  var id = null;
  if(diaryData.value?.items_week){
    for (const [wkey, w] of diaryData.value.items_week.entries()) {
      if(wkey == 0)
        id = w.id;
    }
  }
  return id;
})


const lastWeekId  = computed(() => {
  if(diaryData.value?.items_week)
    return diaryData.value.items_week[diaryData.value.items_week.length - 1].id;
  return null;
})

const showComments = computed(() => {
  for (const m of route.matched)
    if (m.path.indexOf('edit') >= 0)
      return false;   
  return true;
});

const interactWeek = function(week: Week) {
  // router.push({
  //   path: '/diaries/' + diaryData.value.link + '/week/' + week.id
  // });
  weekActive.value = week;
}
 
 

function closeCreateWeekModal() {
  showCreateWeekModal.value = false;
}

function chooseWeek(week: Week) {
  weekActive.value = week;
}
 

function redirectToWeek(editWeekId: number) {



  if(!editWeekId || editWeekId == rootWeekId.value){
    router.push({
      path: '/diaries/' + diaryData.value.link
    });
  }else{
    router.push({
      path: '/diaries/' + diaryData.value.link //+ '/weeks/' + editWeekId
    });
  }

}

// function likeWeek(id) {

  

//   if(diaryData.value.addon.likes.indexOf(id) >= 0){
//     diaryData.value.addon.likes.splice(diaryData.value.addon.likes.indexOf(id), 1);
//     weekActive.value.cnt_likes--;
//   }else{
//     diaryData.value.addon.likes.push(id);
//     weekActive.value.cnt_likes++;
//   }
// }

// const follow = function(state) {  
//   $follow.toggle('diary', diaryData.value.id, state, {
//     type: 'diary',
//     id: diaryData.value.id,
//     avatar: diaryData.value.avatar_small,
//     name: diaryData.value.name,
//     link: diaryData.value.link
//   })
//   .then((response) => {    
//     diaryData.value.addon.follow = state; 
//     diaryData.value.cnt_followers += state ? 1 : -1;
//   })
//   .catch((error) => {
//     $popup.error('Error')
//   });
// }
 
// const diaryData = ref(null);
// const diary_addon = ref(null);

const loadData = async (id) => {
  console.log('load diary id:', id)
  let data = await $api.getDiary(id);  
  isLoaded.value = true;
  // console.log('set addon')
  // console.log(data.addon)
  // diary_addon.value = data.addon;
  return data;
}

const loadComments = async (id) => {
  const response = await $api.getCommentsSummary(
    diaryId.value,
    'diary'
  )  
  return response;  
}



const { status: statusDiaryData,  data: diaryData } = await useLazyAsyncData('diaryData', async () => await loadData(diaryId.value) as Diary, {
  deep: true,
  immediate: true
})


const { status: statusCommentsData,  data: commentsData } = await useLazyAsyncData('commentsData', async () => await loadComments(diaryId.value))


// watch(diaryData_object, ( new_diaryData_object) => {

//   if(new_diaryData_object)
//     diaryData.value = new_diaryData_object.data;
//   // diaryData.value = diaryData_object.value.data;
//   // console.log(diaryData_object);
//   // console.log(diaryData);
// })


// if(diaryData.value)
//   setActiveWeek(routeWeekId.value);


// watch(diaryData, async ( new_diaryData) => {
  // console.log('routeWeekId');
  // console.log(routeWeekId.value);

  // setActiveWeek(routeWeekId.value);
  // useHead(useNuxtApp().$head.getForDiary(diaryData.value, weekActive.value));
// })




useHead(useNuxtApp().$head.getForDiary(diaryData.value, weekActive.value));

onUnmounted(() => {
  if(!diaryData.value)
    return;
  diaryData.value = null;
})



// theme margins
onMounted(() => {
  usePageBottomMargin().value = 90;
})

onUnmounted(() => {
  usePageBottomMargin().value = 0;
})


const escapeKey = (event) => {
  // alert(event.key);
  if(event.key === "Escape") {
    useModalsDiaryShow().value = false;
    useModalsDiary().value = null;
  }
}

const initExcapeKey = () => {
  document.addEventListener('keydown', escapeKey);
}

onMounted(() => {
  if(import.meta.client){
    initExcapeKey();
  }
})

onUnmounted(() => {
  document.removeEventListener('keydown', escapeKey);
})




// NEW 


function handleCreatingWeekModal() {
  showCreateWeekModal.value = true;
}

async function handleUpdateWeek(editWeekId: number) {
  diaryData.value = await $api.getDiary(diaryData.value.id)  
  redirectToWeek(editWeekId); 
}
 
function handleCancelEditWeek(editWeekId: number) {
  redirectToWeek(editWeekId);
}


const handleRemoveDiary = () => {
  useDiary().remove(diaryData.value.id);
}

const handleUnpublishDiary = () => {
  useDiary().unpublish(diaryData.value);
  // diaryData.value.is_hidden = 1;
}

const handlePublishDiary = () => {
  useDiary().publish(diaryData.value);
  // diaryData.value.is_hidden = 0;
}

const handleLikeWeek = () => {

}

const handleUnlikeWeek = () => {

}

const handleFollowDiary = () => {
  useDiary().follow(diaryData.value);
}

const handleUnfollowDiary = () => {
  useDiary().unfollow(diaryData.value);
}


const handleRemoveWeek = (week: Week) => {
  useDiary().removeWeek(diaryData.value, week);
}


 


</script>


<style scoped>

.scrl{
  overflow-y: auto;
  max-height: calc(100vh - 130px);
}

.dia{
  display: flex;
  align-items: flex-start;
  gap: 0 1rem;
  flex-wrap: wrap;
  max-width: 935px!important;
  margin-bottom: 10rem!important;
}
.gal{ 
  width: calc(100%);
  margin-bottom: 100px;
}
.dat_head{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0 1rem;
  margin-bottom: 2rem;
}

.dat_weeks{
  position: fixed;
  bottom: 5px;
  max-width: 70%;
  width: 100%;
  left: 0;
  padding: 10px;
  z-index: 101;
  right: 0;
  left: 40%;
  transform: translateX(-40%);
}

.offnav .dat_weeks{
  margin-left: 60px;
}

.onnav .dat_weeks{
  margin-left: 180px;
}

.onnav .dat_weeks.is-modal{
  margin-left: 40px;
}

.is-modal .dat_weeks{
  margin-left: 0!important;
  left: 50%;
  transform: translateX(-50%);
}

.week-box{
  position: sticky;
  top:50px;
  max-height: calc(100vh - 30px);  
}

.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.gal .report_days_sticky{
  display: none;
}

.gal .report_header{
  display: none;
}

  
@container pb (max-width: 600px) {

  .gal{

    width: 100%;
  }

  .gal .report_days_sticky{
    display: flex;
  }

  .gal .report_days_sticky{
    margin-left: -2rem;
    width: calc(100% + 4rem);
    padding: 0.5rem 2rem;
  }

  .gal .report_header{
    display: flex;
  }
  .dat{
    display: none
  }
}

@media (max-width: 768px) {

  .dat_weeks{

    bottom: 5px;
    max-width: 100%;
    left: 0;
    padding: 10px;
    z-index: 2;
    right: 0;
    transform: translateX(0%);
    margin-left: 0!important;
  }

}
 
/* overlay-setup */

.overlay-setup{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--un-background-color);
  z-index: 999999;  
  max-height: 60vh;
  padding: 1rem;
  box-shadow: 0 0 20px #00000059;
}

.overlay-setup :deep(.comments){
  max-height: 400px;
}

/* overlay-setup */

.overlay-nutrients{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--un-background-color);
  z-index: 999999;  
  max-height: 60vh;
  padding: 1rem;
  box-shadow: 0 0 20px #00000059;
}

.overlay-nutrients :deep(.comments){
  max-height: 400px;
}



.problems{
  width: 100%;
}
</style>

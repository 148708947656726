<template>

  <div class="head"> 
    <div v-if="props.brand_package && props.background" class="bg empty_bg" :style="'background-image: url(' + props.background + ')'"></div>
  </div>

  <div class="head pb grid_width "> 


      

      <div class="bgbr">

        <UiBread
          :list="props.bread"
          :position="'right'"
          /> 

      </div>

      <div class="phead">

        <div class="ava">
          <template v-if="props.itemsPhoto?.length > 1">

            <img             
            :src="props.avatar"
            class="avai" 
            :alt="props.nmalt"
            @click="openGallery()"
            > 
            <div class="nums">1 / {{ props.itemsPhoto?.length }}</div>

          </template>
          <template v-else>
            <img             
            :src="props.avatar"
            class="avai lg" 
            :alt="props.nmalt"
            @click="openGallery()"
            > 
          </template>
        </div>

   
        <div class="p_name">

          <div class="brand">
            <img :src="props.logo" class="blogo" :alt="props.nmalt"> 
            <NuxtLink class="cname" :to="props.categoryLink">{{ props.categoryName }}</NuxtLink>
            <h1 class="bname">{{ nm }}</h1> 
          </div>


          

        </div>

        <!--  -->


        <div class="space1"></div>
        <div class="space2"></div>


        <!--  -->


        <div class="actions"> 
<!-- 
          <template v-if="props.officalUser">
            <NuxtLink class="user_official" :to="props.officalUser.link"> 
              <img class="avatar" :src="props.officalUser.avatar_little" :alt="props.officalUser.name">
              <div class="name">{{ props.officalUser.name }}</div> 
              <div class="status">Official account</div> 
            </NuxtLink>  
          </template> -->


          <UiButton        
            @click="toChat"
            name="Message"
            ico="icon-message"        
            />
    
             
          <UiButton
            :name="$t('universal_button_follow')"
            :cnt="props.cntFollowers"
            :type="'primary ' + (useAuth().isAuth() ? '' : 'outline')"
            v-if="!props.isfollow"
            @click.native="follow" 
            />

          <UiButton
            :name="$t('universal_button_unfollow')"
            :cnt="props.cntFollowers"
            type=""
            v-if="props.isfollow"
            @click.native="unfollow" 
            />


          </div>  


        <!--  -->

        <div class="stats mob">

          <div class="stat">
            <div class="vl">#{{ props.pos }}</div>
            <div class="tx">Global pos.</div>
          </div>

          <div class="stat">
            <div class="vl"><span class="star_rate"></span><span>{{ props.rate }}</span></div>
            <div class="tx">{{ props.cntHarvests }} harvests</div>
          </div>
          
        </div>

        <div class="stats total">

          <NuxtLink :to="props.catalogLink" class="stat rate" v-if="props.pos">
            <div class="vl">#{{ props.pos }}</div>
            <div class="tx">Global pos.</div>
          </NuxtLink>

          <NuxtLink :to="props.harvestsLink" class="stat stars" v-if="props.rate">
            <div class="vl"><span class="star_rate"></span><span>{{ props.rate }}</span></div>
            <div class="tx">{{ props.cntHarvests }} harvests</div>
          </NuxtLink>

          <!-- <div class="stat stat_gp">
            <div class="vl">
              <i class="gp"></i>
              {{ props.weightPlant }}
            </div>
            <div class="tx">g/plant</div>
          </div> -->

          <NuxtLink :to="props.diariesLink" class="stat">
            <div class="vl">
              <i class="icon-diary" title="diaries"></i>
              {{ props.cntReports }}
            </div>
            <div class="tx">Diaries</div>
          </NuxtLink>

          <!-- <div class="stat" v-if="props.fromYear">
            <div class="vl">
              {{ cntYears }} years
            </div>
            <div class="tx">From {{ props.fromYear }}</div>
          </div> -->

          <div class="stat" v-if="props.country">
            <div class="vl">
              <img class="logo_country" :src="'/images/country/small/' + getCountryFile(props.country) + '.png'">
              {{ props.countryIso }}
            </div>
            <div class="tx">{{ props.country }}</div>
          </div>

          <!-- <div class="stat official" v-if="props.officalUser">
            <NuxtLink :to="props.officalUser.link" class="vl">
              <img class="img round" :src="props.officalUser.avatar_little" :alt="props.officalUser.name">
              {{ props.officalUser.name }}
            </NuxtLink>
            <div class="tx">Official account</div>
          </div> -->


<!-- 
 

          <div class="stat" v-if="props.category == 'seed'">
            <div class="vl">
              <template v-if="props.seedPercentAuto >= props.seedPercentPhoto">
                <span class="shp auto" title="Autoflower">A</span>  
                {{ props.seedPercentAuto }}%
              </template>
              <template v-else>
                <span class="shp photo" title="Photoperiod">P</span>
                {{ props.seedPercentPhoto }}%
              </template>                          
            </div>
            <div class="tx cap">
              <template v-if="props.seedPercentAuto >= props.seedPercentPhoto">
                <span class="shp photo" title="Photoperiod">P</span>
                Photo
                {{ props.seedPercentPhoto }}%
              </template>
              <template v-else>
                <span class="shp auto" title="Autoflower">A</span>  
                Auto
                {{ props.seedPercentAuto }}%
              </template>   
            </div>
          </div>
 

  -->

          <div class="stat" v-if="props.category == 'seed' && props.seedPercentAuto">
            <div class="vl">
              <span class="shp auto" title="Autoflower">A</span>  
              {{ props.seedPercentAuto }}%
            </div>
            <div class="tx cap">
                Autoflower
            </div>
          </div>
  

          <div class="stat" v-if="props.category == 'seed' && props.seedPercentPhoto">
            <div class="vl">
              <span class="shp photo" title="Photoperiod">P</span>  
              {{ props.seedPercentPhoto }}%
            </div>
            <div class="tx cap">
              Photoperiod
            </div>
          </div>
  
  

        </div>

        <!--  -->

        <div class="desc">
          
          <GeneralShowMore
            :desktop-max-height="60"
            :mobile-max-height="80" 
            >
            <div v-if="props.info" v-html="props.info"></div> 
          </GeneralShowMore>

          <a :href="props.web" target="_blank" rel="nofollow" class="web" v-if="(props.brand_package == 3 || props.brand_package == 6) && props.web">
            <i class="icon-external"></i>
            {{ getFormattedUrl }}
          </a>
          
        </div>

        <!--  -->

    


      </div>
 
       
  </div>



  <GeneralLightboxVertical 
      v-if="lightboxShow"
      :photo-id="lightboxId" 
      :photos="lightboxPhotos" 
      @close="lightboxShow = false" 
      />

</template>

<script setup>


const { hooks } = useNuxtApp()
const { t } = useI18n();
const emits = defineEmits(['follow', 'unfollow'])

const props = defineProps({
  bread: {
    type: Array,
    default: [],
  },
  id: {
    type: Number,
    default: null,
  },
  brand_package: {
    type: Number,
    default: null,
  },
  linkShop: {
    type: String,
    default: null,
  },
  background: {
    type: String,
    default: null,
  }, 
  catalogLink: {
    type: String,
    default: '/',
  }, 
  harvestsLink: {
    type: String,
    default: '/',
  }, 
  diariesLink: {
    type: String,
    default: '/',
  }, 
  nm: {
    type: String,
    default: null,
  },
  cntHarvests: {
    type: Number,
    default: null,
  }, 
  fromYear: {
    type: Number,
    default: null,
  },
  info: {
    type: String,
    default: '',
  },
  cntReports: {
    type: Number,
    default: null,
  },
  weightPlant: {
    type: Number,
    default: null,
  },
 
  typeGender: {
    type: String,
    default: null,
  },

  typeFlowering: {
    type: String,
    default: null,
  },

  pos: {
    type: Number,
    default: null,
  },

  cbdMin: {
    type: Number,
    default: null,
  },

  cbdMax: {
    type: Number,
    default: null,
  },

  thcMin: {
    type: Number,
    default: null,
  },

  thcMax: {
    type: Number,
    default: null,
  },  
  web: {
    type: String,
    default: null,
  },
  nmalt: {
    type: String,
    default: null,
  },
  slogan: {
    type: String,
    default: null,
  },
  avatar: {
    type: String,
    default: null,
  },
  logo: {
    type: String,
    default: null,
  },
  link: {
    type: String,
    default: null,
  },
  rate: {
    type: Number,
    default: null,
  },
  type: {
    type: String,
    default: null,
  },
  rateof: {
    type: Number,
    required: false,
    default: 10,
  },
  isfollow: {
    type: Boolean,
    default: null,
  },
  cntFollowers: {
    type: Number,
    default: null,
  },
  officalUser: {
    type: Object,
    default: null,
  },
  category: {
    type: String,
    default: null,
  }, 
  categoryName: {
    type: String,
    default: null,
  }, 
  categoryLink: {
    type: String,
    default: null,
  }, 
  country: {
    type: String,
    default: null,
  },
  countryIso: {
    type: String,
    default: null,
  },
  itemsPhoto: {
    type: Array,
    default: null,
  },
  seedPercentAuto: {
    type: Number,
    default: null,
  }, 
  seedPercentPhoto: {
    type: Number,
    default: null,
  },
})


var lightboxShow = ref(false);
var lightboxId = ref(null);


const follow = () => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();
  
  emits('follow')
}

const unfollow = () => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  emits('unfollow')
}



const getCountryFile = function(country) {
  return props.country.toLowerCase().replace(/ /g, '-');
}

const cntYears = computed(() => {
  return new Date().getFullYear() - props.fromYear;
});



const toChat = function(e) {
  e.preventDefault();
  e.stopPropagation();
  hooks.callHook('chat:start', props.officalUser.id).catch(() => {
    console.log('chat:start hook');    
  })

}


const lightboxPhotos = computed(() => {
  
    var items = []; 

    for(var i of props.itemsPhoto){
      let photo = {}; 
      photo.base = i.url_m;
      photo.zoom = i.url_l; 
      items.push(photo);        
    }
    return items;
})

const openGallery = function(key){
  lightboxShow.value = true;
  lightboxId.value = key ?? 0;
}


const getFormattedUrl = computed(() => {
  return props.web.indexOf('?')>0 ? props.web.substring(0, props.web.indexOf('?')) : props.web;
})
 

</script>




<style scoped>
 
.pghd_gr3{

}
.head{
  padding: 0!important;
}
.head .head_bg{
  /* background-color: var(--un-background-color-gray); */
  
}

.head .head_bg .page_content {
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.head .head_bg .head_info {
  display: flex;
  align-items: center;
  width: 100%;
}
.head .head_bg .item_name {
  display: flex;
  align-items: center;
  z-index: 12;
  position: relative;
}
.head .head_bg .item_name .item_logo {
  width: 130px;
  /* background-color: var(--un-background-color-gray); */
  background-color: white;
  padding: 2px;
  border-radius: 200px;
  margin-right: 1rem;
  margin-top: -43px;
  border: 4px var(--un-element-color-gray) solid;
  box-shadow: 0px 0px 20px #00000024;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.head .bg{
  height: 230px;
  margin-bottom: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
} 

.cap {
  text-transform: capitalize;
} 
/*  */

.phead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 1rem 2rem;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-areas: 
    "ava space1 space1"
    "ava name actions"
    "ava stats_total stats_total"
    "ava desc desc"
    "ava space2 space2"
  ;
}

.phead .ava {
  width: 100%;
  background-color: white;
  padding: 2px;
  border-radius: 15px; 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  grid-area: ava;
  position: relative;
}
.phead .ava .nums{
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
  padding: 2px 11px;
  border-radius: 60px;
  font-weight: bold;
  box-shadow: 0px 0px 10px #00000038;
}

.phead .ava .avai{
  width: 100%;
  border-radius: 15px;
  aspect-ratio: 191/267;
  object-fit: cover;
}
 
.phead .ava .avai.lg{ 
  object-fit: contain;

}
.phead .space1 {
  grid-area: space1;
}

.phead .space2 {
  grid-area: space2;
}

.phead .p_name {
  display: flex;
  flex-direction: column;
  grid-area: name;
}
 

.phead .p_name .brand{
  display: grid;
  align-items: center;
  gap: 0 10px;
  grid-template-columns: 100px 1fr;
  grid-template-areas: 
    "logo cname"
    "logo bname"
  ;

}
.phead .p_name .brand .cname{
  font-size: 0.9rem;
  grid-area: cname;
  margin-top: auto;
  align-self: flex-end;
}
.phead .p_name .brand .bname{

  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.4rem;
  grid-area: bname;
  margin-bottom: auto;
  align-self: flex-start;
}

.phead .p_name .brand .blogo {
  width: 100%;
  padding: 2px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  grid-area: logo;
}

.phead .p_name .brand .blogo .logo {
  
}

.phead .stat.stat_gp .gp {
  width: 20px;
}

.phead .stats.total {
  grid-area: stats_total;
}
.phead .stats.mob {
  grid-area: stats_mob;
  display: none;
}
.phead .stats {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;

}
.phead .actions{
  display: flex;
  gap: 0 0.5rem;
  grid-area: actions;
 
}
.phead .stats .stat {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  min-height: 45px;
}

.phead .stats .stat .vl {
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
}
.phead .stats .stat .vl .img{
  aspect-ratio: 1/1;
  width: 25px;
  object-fit: cover;
}
.phead .stats .stat .vl .img.round{
  border-radius: 50%;
}
.phead .stats .stat .icon-female {
  color: #ff0067;
} 
.phead .stats .stat .icon-male {
  color: #0068ff;
}
.phead .stats .stat .shp{
  font-size: 1.4rem; 
  font-family: Courier;
  text-transform: uppercase;
  font-weight: normal;
    line-height: 1.1rem;
}
.phead .stats .stat .shp.auto{
  color: #1eb353;
}
.phead .stats .stat .shp.photo{
  color: #a913d9;
}
.phead .stats .stat .vl .shp{  
  font-size: 1.6rem; 
}
.phead .stats .stat .tx .shp{  
  font-size: 1rem; 
}
.phead .stats .stat .tx {
  color: #898989;
  font-size: 0.8rem;
}


.phead .stats .stat.taste .vl {
  display: flex;
  align-items: center;
}

.phead .stats .stat.taste .vl .ico:nth-child(1) {
  width: 25px;
  height: 25px;
}

.phead .stats .stat.taste .vl .ico:nth-child(2) {
  width: 20px;
  height: 20px;
}

.phead .stats .stat.taste .vl .ico:nth-child(3) {
  width: 15px;
  height: 15px;
}

.phead .desc {
  color: #7c7c7c;
  max-width: 660px;
  grid-area: desc;
}
.phead .desc :deep(p){
  margin: 0;
}
.phead .desc a {
  color: var(--un-primary-color);
}


@container pb (max-width: 650px) {

  .head .breadcrumbs{
    display: none;
  }
 

  .phead{
    margin-top: 1rem;
    gap: 0.6rem 1rem;
    grid-template-columns: 48% auto;
    grid-template-areas: 
      "ava space1"
      "ava name"
      "ava stats_mob"
      "ava actions"
      "ava space2"
      "stats_total stats_total"
      "desc desc"
    ;
  }
  .phead .ava{
    width: 100%;
  }
  .phead{

  }
  .phead .actions{
    flex-wrap: wrap;
  }
  .phead .actions :deep(.btn){
    width: 100%;
  }


  .phead .desc {

  }


  .phead .stats.total {

  }
  .phead .stats.mob {
    display: flex;
    gap: 0.5rem;
  }

  .phead .stats .stat.stars {
    display: none;
  }
  .phead .stats .stat.rate {
    display: none;
  }

  .phead .stats.mob .vl{
    font-size: 1.3rem;
  }
  .phead .stats.mob .star_rate{
    width: 25px;
    height: 25px;
  }
}


/* BACKGROUND */

.head .bgbr{
margin-top: 1rem;
}


@media (max-width: 650px) {

  .head .bg{
    height: 180px;

    background-repeat: no-repeat;
    background-size: auto 140%;
    background-position-y: center;
    background-position-x: center;
  } 
}

@container pb (max-width: 600px) {

  .head .bgbr{

  }
  
}



/* brand_cover  */


.brand_cover{

}
.brand_cover .bc{
    width: 100%;
    border-radius: 15px;    
}
.brand_cover .bcm{
  display: none;
}




@container pb (max-width: 650px) {
  .brand_cover{

  }
  .brand_cover .bc{
      display: none; 
  }
  .brand_cover .bcm{ 
    max-width: none;
    width: calc(100% + 4rem);
    display: block;
    margin-left: -2rem;
    margin-top: -2rem;
    margin-bottom: -20px;
  }
}

/*  */





/* user_official */



.user_official {
  display: grid;
  grid-template-columns: 35px 1fr;
  grid-template-areas: 
    "u_avatar u_name"
    "u_avatar u_status"
  ;
  gap: 0 10px;
  align-items: center;

}

.user_official .avatar {
  border-radius: 50%;
  width: 100%;
  border: 2px white solid;
  grid-area: u_avatar;
}
 
/* .user_official .user_range {
    float: left;
    font-size: 1.6rem;
    margin-right: 15px;
    position: absolute;
    left: 20px;
    bottom: 2px;
} */

.user_official .name {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #494949;
    grid-area: u_name;
}

.user_official .status {
    color: #9c9c9c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    grid-area: u_status;
}


</style>

<template>
  <div class="network" ref="network"></div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import { Network } from 'vis-network/standalone/esm/vis-network'

const emits = defineEmits(['click'])


const props = defineProps({
  nodes: {
    type: Array,
    required: true
  },
  edges: {
    type: Array,
    required: true
  },
  options: {
    type: Object,
    default: () => ({})
  },
  clusterOptions: {
    type: Object,
    default: () => ({
      joinCondition: (child) => child.group === 'default', 
      clusterNodeProperties: {
        label: 'Cluster'
      }
    })
  }
})


const network = ref(null)
let networkInstance = null


onMounted(() => {
  if (network.value) {
    const data = {
      nodes: props.nodes,
      edges: props.edges
    }
    networkInstance = new Network(network.value, data, props.options)


    networkInstance.on('click', params => {
      if (params.nodes.length > 0) {
        const nodeId = params.nodes[0]
        const node = props.nodes.find(n => n.id === nodeId)
        if (node) {
          emits('click', node.id, node.label)
        }
      }
    })


    applyClustering()
  }
})


const applyClustering = () => {
  if (!networkInstance) return

  networkInstance.cluster(props.clusterOptions)
  

}


watch(
  () => [props.nodes, props.edges, props.options],
  ([newNodes, newEdges, newOptions]) => {
    if (networkInstance) {
      networkInstance.setData({
        nodes: newNodes,
        edges: newEdges
      })
      networkInstance.setOptions(newOptions)
      

      applyClustering()
    }
  },
  { deep: true }
)


onBeforeUnmount(() => {
  if (networkInstance) {
    networkInstance.destroy()
  }
})
</script>

<style scoped>
.network{
  height: 100%;
  width: 100%;
}
</style>
<template>

  <Explore
    v-if="strainData?.id"
    :strain="strainData"
    :strain-folder="'grow-journals/' + strainData.section"
  />

</template>

<script setup lang="ts">

import type { Strain }  from "@/types/strain"

import Explore from '@/pages/explore/[...tag].vue';
import { createError } from 'h3';

const {$api, $adv, $tagsUtil, $ga, $popup, $patcher} = useNuxtApp()

const route = useRoute();
const strainLink = ref<string>(route.params['strain']);

const loadData = async function(str: string) {     
  const response = await $api.getUrl('/ref/strains/' + str, {
  })  
  return response;  
}

const { status: strainDataStatus,  data: strainData } = await useLazyAsyncData('strainData' + strainLink.value, async () => await loadData(strainLink.value) as Strain)


if (!strainData.value && strainDataStatus.value === 'success') {
  throw createError({ statusCode: 404, message: 'Strain not found' });
}

</script>

<style scoped>
 
</style>

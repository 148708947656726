<template>

  <div     
    ref="elVideoContainer"
    @click.stop.prevent="toggleContextMenu(false)"
    :class="{
      'lc': true, 
      'is-playing': playing, 
      'is-current': props.isActive,
      'is-sidebar-showed': commentShow,
      'is-muted': isMuted
    }"
    >
    
    <div class="video_box">

      <div v-if="errored" class="errored-state">
        <i 
          class="icon-not-eye" 
          @click="togglePlayerPlay()"></i>
      </div>


      <div class="header-state">

        <div class="back" @click="backToReferenceUrl">
          <i class="icon-back"></i>
        </div>

        <div class="play-state-small" @click="togglePlayerPlay()">
          <i v-if="!playing" class="icon-play"></i>
          <i v-else class="icon-pause"></i>
        </div>

        <div class="sound-state" @click="togglePlayerMute()">
          <i v-if="isMuted" 
            class="icon-volume" 
            ></i>
          <i v-else
            class="icon-volume-2" 
            ></i>
        </div>

      </div>


      <div class="actions">
        <div :class="{'act-btn': true, 'active': currentVideo.is_liked}" @click="like">
          <div class="act">
            <i class="icon-like"></i>
          </div>
          <div class="name">{{ currentVideo.cnt_likes ? currentVideo.cnt_likes : 'Likes' }}</div>
        </div>
        <div class="act-btn" @click="toggleComment(video)">
          <div class="act">
            <i class="icon-comment"></i>
          </div>
          <div class="name">{{ currentVideo.item_diary.cnt_comments ? currentVideo.item_diary.cnt_comments : 'Comments' }}</div>
        </div>
        <div class="act-btn" @click="shareShow = true">
          <div class="act">
            <i class="icon-share"></i>
          </div>
          <div class="name">Share</div>
        </div>
        <div class="act-btn" @click.stop.prevent="toggleContextMenu(!isShowContextMenu)">
          <div class="act">
            <i class="icon-dots-more"></i>
          </div>
          <div class="context-menu" v-show="isShowContextMenu">
            <div class="context-item" @click="complain(video)">
              <i class="icon-warning-round"></i>
              <span>Complain</span>
            </div>
          </div>
        </div>
        <div class="act-btn">
          <NuxtLink :to="currentVideo.item_diary.link + '/week/' + currentVideo.item_week.id" class="act act-diary">
            <img :src="currentVideo.item_diary.avatar_little" :title="currentVideo.item_diary.name">
          </NuxtLink>
        </div>
      </div>

      <div class="anim anim-play">
        <i class="icon-play"></i>
      </div>

      <div class="anim anim-pause">
        <i class="icon-pause"></i>
      </div>

      <div class="video_box_touch" @click="togglePlayerPlay()"></div>
    
      <video 
        class="vjs-tech" 
        :data-next="props.isNext"
        :preload="props.isNext || props.isActive ? 'auto' : 'none'"
        playsinline="playsinline"                  
        ref="elVideo"
        >
        <source :src="currentVideo.video_converted" type="video/mp4" @error="errored">      
      </video>

      <div class="cover_box">
        <img class="cover" :src="currentVideo.size_m">
      </div>

      <div class="player-progress">
        <div class="player-progress-current" :style="'width: ' + progress + '%'"></div>
      </div>

      <!-- <div class="player-duration" v-if="duration">
        {{ duration }}
      </div> -->

      <div :class="{'info': true, 'active': info}">
        <!-- {{ currentVideo.source }} -->
        <div class="user">
          <img class="ava" :src="currentVideo.item_user.avatar_little">
          <div class="source">                      
            <NuxtLink class="link" :to="currentVideo.item_user.link">@{{ currentVideo.item_user.name }}</NuxtLink>
            <UiButton
              :name="currentVideo.item_user.is_follow ? 'Unfollow' : 'Follow'"
              :type="'white outline small'"
              @click="toggleFollow(video)"
              :is-loading="isFollowLoading"
              />      
          </div>
        </div>
        <div class="caption" @click="toggleInfo(video)">
          <div><NuxtLink class="link" :to="currentVideo.item_diary.link + '/week/' + currentVideo.item_week.id">{{ currentVideo.item_diary.name + ' - Week ' + currentVideo.item_week.days }}</NuxtLink></div>
          
          <!-- 
          <div v-if="currentVideo.items_seed"  class="seeds-list">
            <span class="seeds-name">Seeds of diary: </span>
            <NuxtLink 
              v-for="(seed, seed_index) in currentVideo.items_seed" 
              :key="seed_index"
              :to="seed.link"
              >
              <div class="seed">{{ seed.name }}</div>
            </NuxtLink>
          </div> -->

          <div>{{ currentVideo.item_week.text }}</div>
        </div>

          
          <div v-if="currentVideo.items_seed"  class="seeds-tags">
            <NuxtLink 
              v-for="(seed, seed_index) in currentVideo.items_seed" 
              :key="seed_index"
              :to="seed.link"
              class="seed"
              >
              <img class="ava" :src="seed.item_brand.avatar_little">
              <div class="nm">
                <template v-if="seed.name">
                  {{ seed.name }} ·
                </template>
                {{ seed.item_brand.name }}  
              </div>
              
            </NuxtLink>
          </div>

      </div>

    </div>


    <div 
      class="message_sidebar"
      v-if="commentShow && currentVideo.item_week.id">

      <CommentWidgetFlow
        :title="$t('journal_view_post_comments')"
        :type="'diary'"
        :content="currentVideo.item_week.id"
        @close="toggleComment(video)"
        />
        
    </div>


    <GeneralShareModal
      v-if="shareShow"
      :showed="shareShow"
      @close="shareShow = false"
      />
      

  </div>   

</template>
  
<script setup>
  
  import { watchArray } from '@vueuse/core'
  
  const props = defineProps({
    video: {
      type: Object,
      default: {},
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isNext: {
      type: Boolean,
      default: false,
    },
    closeType: {
      type: String,
      default: '',
    },
    isMuted: {
      type: Boolean,
      default: false,
    },
  });
  
  
  
  // const { text: copyText, copy, copied: copyCopied, isSupported: copyIsSupported } = useClipboard()
  
  const emits = defineEmits(['observe', 'unobserve', 'prev', 'next', 'play', 'pause', 'mute', 'unmute', 'close']);
  const { t } = useI18n();
  const { $noscroll, $observer, $popup, $likes, $follow, $complains, $head } = useNuxtApp();
  const router = useRouter();

  const elInfinityScroll = ref(null)
  const playerPlay = ref(false);
  const playerCurrentProgress = ref(0);
  const currentProgressInterval = ref(null);
  const videoContainerRefs = ref([])

  const currentVideo = ref(props.video);
  const videoRefs = ref([])
  const scrl = ref(null);
  const isStopObserver = ref(false);
  const currentObservedVideo = ref(null);
  const isFollowLoading = ref(false);
  const isMuted = ref(props.isMuted)
  const isShowContextMenu = ref(false)
  const referenceUrl = ref(null)
  const elAnimPlay = ref(null);
  const elAnimPause = ref(null);
  const elVideoContainer = ref(null);
  const elVideo = ref(null);

  const commentShow = ref(false);
  const shareShow = ref(false);
  const playing = ref(false);
  const progress = ref(0);
  const duration = ref(0);
  const info = ref(false);
  const errored = ref(false);


  // defineExpose({
  //   elVideoContainer
  // })
  



  const initObserve = function(){
    console.log('initObserve') 
    $observer(elVideoContainer.value, (element) => {
      emits('observe');
    }, (element) => {
      emits('unobserve');
    });
  }
    

  const runAnimate = function(el) {  
    console.log('el')
    console.log(el)
    el.classList.add('animated');
    setTimeout(()=> {
      el.classList.remove('animated');
    }, 500)
  }

  const runAnimatePlay = function() {  
    console.log('elVideoContainer.value');
    console.log(elVideoContainer);
    runAnimate(elVideoContainer.value.querySelector('.anim-play'));
  }

  const runAnimatePause = function() {  
    runAnimate(elVideoContainer.value.querySelector('.anim-pause'));
  }

  const toggleFollow = function() {  
    currentVideo.value.item_user.is_follow = !currentVideo.value.item_user.is_follow;
    $follow.toggle('user', currentVideo.value.item_user.id, currentVideo.value.item_user.is_follow, {
      type: 'user',
      id: currentVideo.value.item_user.id,
      avatar: currentVideo.value.item_user.avatar_little, 
      name: currentVideo.value.item_user.name, 
      link: currentVideo.value.item_user.link, 
    })
    .then((response) => {    

    })
    .catch((error) => {
      currentVideo.value.item_user.is_follow = !currentVideo.value.item_user.is_follow;
      $popup.error('Error')
    });
  }
  

  const like = () => {
    if(!useAuth().isAuth())
      return useAuth().goToLogin();

    // likeLoading.value = true;

    currentVideo.value.is_liked = !currentVideo.value.is_liked;

    currentVideo.value.cnt_likes += currentVideo.value.is_liked ? 1 : -1;

    $likes.toggle('video', currentVideo.value.video_id, !currentVideo.value.is_liked)
    .then((res) => {   

      // likeLoading.value = false;
      // isLikedEdit.value = !isLikedEdit.value;
      // data.cnt_likes += (isLikedEdit.value ? 1 :-1);
      // data.cnt_likes = data.cnt_likes < 0 ? 0 : data.cnt_likes;
    })
    .catch((res) => {       
      currentVideo.value.is_liked = !currentVideo.value.is_liked;
      currentVideo.value.cnt_likes += currentVideo.value.is_liked ? 1 : -1;
      // likeLoading.value = false;
      $popup.error('Error');
    });
  }
  
  const toggleComment = function(){
    commentShow.value = !commentShow.value;
  }
  
  

  const togglePlayerMute = (state) => {

    console.log('togglePlayerMute');

    if(state == '-1'){
      isMuted.value = true;
    }else if (state == '1'){
      isMuted.value = false;
    }else{
      isMuted.value = !isMuted.value;
    }

    elVideo.value.muted = isMuted.value ? 0.6 : 0;

    emits(isMuted.value ? 'mute' : 'unmute');
  }
   
  
  const togglePlayerProgressInterval = (state) => {

    console.log('togglePlayerProgressInterval') 
    if(!elVideo.value) {
      state = false;
    }

    if(!elVideo.value){
      state = false
    }
 
      
    if(state){

      if(currentProgressInterval.value)
        clearInterval(currentProgressInterval.value);

      currentProgressInterval.value = setInterval(() => {
        setPlayerCurrentProgress();
      }, 300);
    }else{
      clearInterval(currentProgressInterval.value);
    }
  }
    
  const pausePlayerVideo = () => {
    console.log('pausePlayerVideo');
    // if(!video.source.playing)
    //   return false;
    console.log('video pause')
    elVideo.value.pause();
    playing.value = false;
    togglePlayerProgressInterval(false);
    console.log('paused');
  }
    
  const stopPlayerVideo = () => {
    console.log('stopPlayerVideo');

    togglePlayerProgressInterval(false);

    if(!playing.value)
      return false;

    if(elVideo.value){
      elVideo.value.pause();
      elVideo.value.currentTime = 0;
      elVideo.value.src = '';
      elVideo.value.querySelector('source').src = '';
      playing.value = false;
    }
    
  }

  
  const setSource = () => {
    if(!elVideo.value.querySelector('source').src || elVideo.value.querySelector('source').src == document.location.href){
      elVideo.value.setAttribute("src", currentVideo.value.video_converted); 
      elVideo.value.querySelector('source').setAttribute("src", currentVideo.value.video_converted);
      elVideo.value.load(); 
    }
  }

  const playPlayerVideo = (not_autoplay) => {
    console.log('playPlayerVideo') 
    // elVideo.value.muted = true;

    // if(playing.value)
    //   return false;
   
    setUrl();
    setSeoHeader();
    setSource();

    if(import.meta.server) {
      return;
    }

 
    


   
    
    // console.log(elVideo.value.querySelector('video'));
    elVideo.value.play().then(() => {
      playing.value = true;
    })
    .catch(error => {

    });
    elVideo.value.volume = !isMuted.value ? 0.6 : 0;

    // setTimeout(() => {
    //   elVideo.value.muted = false;
    // }, 1000);
  
  
    // fetchNextVideo(currentVideo.value.id);
    
  
    togglePlayerProgressInterval(true);
  }
   

 
  const backToReferenceUrl = () => {
    if(props.closeType == 'close'){
      emits('close');
    }else{
      // router.go(-1);
      router.push('/shorts');
      // if(referenceUrl.value){
      //   // router.push(referenceUrl.value);
      // }
    }
    
  }

  const togglePlayerPlay = () => {
    console.log('togglePlayerPlay') 

    if(playing.value){
      runAnimatePause();
      playerPlay.value = false;
      playing.value = false;
      pausePlayerVideo()
    }else{
      runAnimatePlay();
      playerPlay.value = true;
      playing.value = true;
      playPlayerVideo()
    }
  
  };
   
  const formatSeconds = (seconds) => {
    return Math.round(seconds) + 's';
  }
  
  const setPlayerCurrentProgress = () => {

    console.log('setPlayerCurrentProgress') 

    var cur = (elVideo.value.currentTime / elVideo.value.duration) * 100;
    progress.value = cur;
    duration.value = formatSeconds(elVideo.value.duration - elVideo.value.currentTime);
  
    if(progress.value >= 100){
      // stopPlayerVideo();
      nextVideo('next');
    }
   
  }
  
  const toggleInfo = () => {
    console.log('toggleInfo');
    info.value = !info.value;
  }
  
  const nextVideo = (pos) => {
    console.log('getNextVideo');
    
    if(pos == 'prev'){
      emits('prev');
    }else{
      emits('next');
    }    
  };
   
  
  
  const setSeoHeader = () => {   
    console.log('setSeoHeader') 


    var data = {};
    data.source_name = currentVideo.value.item_diary.name;
    data.name = currentVideo.value.item_user.name;

    console.log('$head.getShorts(data.info)');
    console.log($head.getShorts(data));
    
    var headData = $head.getShorts(data);
    useHead(headData);


    useSeoMeta({
      title: headData.title,
      ogTitle: headData.title,
      description: currentVideo.value.item_week.text.substr(0,100),
      ogDescription: currentVideo.value.item_week.text.substr(0,100),
      ogImage: currentVideo.value.size_m,
      twitterCard: 'summary_large_image',
      twitterTitle: headData.title,
      twitterDescription: currentVideo.value.item_week.text.substr(0,100),
      twitterImage: currentVideo.value.size_m,
    })
      
    
  }
  
  const setUrl = () => {
    if(import.meta.server) return;
    
    var link = '/shorts/' + currentVideo.value.video_id;

    if(link == window.location.pathname) return;

    window.history.pushState({}, null, link);

    // router.push(link);
  }   
   
    
  const toggleContextMenu = (state) => {
    console.log(state)
    isShowContextMenu.value = state;
  }

  const complain = () => {

    if(!useAuth().isAuth())
      return useAuth().goToLogin();


    if(!confirm(t('chat_complain_confirm_text')))
      return;

    useLoadingIndicator().start();

    $complains.add('video', currentVideo.value.video_id)
    .then((res) => {       
      useLoadingIndicator().finish();
      $popup.success('Complained');
    })
    .catch((res) => {       
      useLoadingIndicator().finish();
      $popup.error('Error');
    });
  }


  
  // const fetchNextVideo = () => {
  //   console.log('fetchNextVideo');
  
   

  //   console.log('elVideo.value');
  //   console.log(elVideo.value);
  //   var playPromise = elVideo.value.load();

 


  
  // }

  
  
  // onMounted(() => {
  //   useSeoMeta({
  //     twitterCard: 'summary_large_image' + videos.value.length,
  //   })
  // });

  // if(import.meta.server){
  //   playPlayerVideo(currentVideo.value, false);
  // }



  if(props.isActive){
    setUrl();
    setSeoHeader();
  }
  
  onMounted(() => {
    console.log('onMounted')

 
    if(props.isActive){
      playPlayerVideo();
    }

    if(props.isNext){
      setSource();
    }

    $noscroll.on();   

    initObserve();

  })
   



  onBeforeUnmount(() => {
    console.log('onUnmounted')
    // stopPlayerVideo();
    togglePlayerProgressInterval(false);
  });
   

  onUnmounted(() => {
    console.log('onUnmounted')
    // stopPlayerVideo();
    togglePlayerProgressInterval(false);
  });
   
        

  watch(() => props.isMuted, (newMuted, oldMuted) => {
    console.log('watcher muted');
    togglePlayerMute(newMuted ? -1 : 1);
  })
        

  watch(() => props.isActive, (newActive, oldActive) => {
    console.log('watcher active');

    if(newActive){
      togglePlayerProgressInterval(true);
      playPlayerVideo();
    }else{
      togglePlayerProgressInterval(false);
      stopPlayerVideo();
    }
  
  }, {deep: true})



  watch(() => props.isNext, (newNext, oldNext) => {
    console.log('watcher next');
    console.log(newNext);
    console.log(currentVideo.value.video_converted);

    if(newNext){
      setSource();
    }else{

    }
  
  }, {deep: true})
    
  // watchArray([props], async ([new_list], added, removed) => {
  //   console.log('watcher');
  
  // }, {deep: true})
  
  
</script>
   
  
<style scoped>
  
  
  .lc {
    position: relative;
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100dvh; 
    max-height: var(--shorts-height-item);
    margin-top: 10px;
    margin-bottom: 10px;
    scroll-snap-stop: always;
    scroll-snap-align: center;
    contain: layout;
  }
   
  
  .video_box{
    aspect-ratio: 9/16;
    height: var(--shorts-height-item);
    margin: 0 auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .video_box_touch{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 200;
  }
  .video_box .play-state{
    width: 40px;
      height: 40px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 5rem;
      left: calc(50% - 1.5rem);
      top: calc(50% - 1.5rem);
      text-shadow: 0 0 30px #00000082;
      z-index: 4;
  }
   
  .cover_box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; 
    border-radius: 15px;
    pointer-events: none;
    transition: all 1s ease-in-out;
    background-color: black;
  }
  .is-playing .cover_box img{
    opacity: 0.3;
  }
  .cover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    cursor: pointer;
    pointer-events: none;
    filter: blur(10px);
    
  }
  .video_box .errored-state{
    width: 40px;
    height: 40px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 2rem;
    left: calc(50% - 1.5rem);
    top: calc(50% - 1.5rem);
    text-shadow: 0 0 30px #00000082;
  }
  
  .header-state{
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 1002;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    text-shadow: 0 0 15px #000000a3;
  }
  
  .video_box .back{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.4rem;
    cursor: pointer;
  }
  
  
  .video_box .seeds-top{
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
    transition: all 1s ease-in-out;
    opacity: 0;
    top: 30px;
    position: relative;
  }
  .is-current .video_box .seeds-top{
    opacity: 1;
    top: 0px;
  }
  .video_box .seeds-top .ava,
  .video_box .seeds-top .ava-img{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    aspect-ratio: 1/1;
  }
  .video_box .seeds-top .seed{
    max-width: 200px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding: 10px;
    font-weight: bold;
    text-shadow: 0 0 10px #000000a3;
  }
  .video_box .play-state-small{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.4rem;
    cursor: pointer;
  }
  
  .video_box .sound-state{
    margin-left: auto;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.4rem;
    cursor: pointer;
  }
  

  .video_box video{
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* height: fit-content; */
    border-radius: 15px;
    cursor: pointer;
    pointer-events: none;
    position: relative;
    z-index: 1;
    max-height: 100%;
    object-fit: cover;
  }
  
  .is-playing video{
    object-fit: cover;
  }
  
  
  .player-progress{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    z-index: 1001;
  }
  
  .player-progress-current{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 3px;
    background-color: var(--un-firm);
    transition: all 0.3s linear;
  }
  
  .player-duration{
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: white;
    font-size: 0.8rem;
  }
  
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    padding: 15px;
    font-size: 1.1rem;
    z-index: 1;
  }
  
  .info{
    position: absolute;
    bottom: 0px;
    color: white;
    border: none;
    padding: 15px;
    font-size: 1.1rem;
    width: 100%;
    max-width: 580px;
    font-size: 0.8rem;
    z-index: 1000;
  }
  .info .user{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    z-index: 1;
    position: relative;
  }
  .info .ava{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
    aspect-ratio: 1/1;
  }
  .info .source{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
  }
  .info .source:deep(.btn){
    color: white;
    border: 1px white solid;
  }
  .info .link{
    font-weight: bold;
    color: white;
  }
  .info .caption{
    color: rgb(197, 197, 197);
    overflow-y: hidden;
    max-height: 71px;
    position: relative;
    z-index: 1;
  }
  .seeds-list{
    margin-bottom: 5px;
  }
  .seeds-list .seeds-name{}
  .seeds-list .seed{
    font-weight: bold;
    text-decoration: underline;
    display: inline-block;
  }


  .seeds-tags{
    margin-bottom: 5px;
    position: relative;
    z-index: 11;
    display: flex;
    margin-top: 10px;
    width: 100%;
    overflow-x: hidden;
  }
  .seeds-tags .seed .ava{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-right: 5px;
    aspect-ratio: 1/1;
  }
  .seeds-tags .seed{
    display: inline-block;
    background-color: var(--un-background-color-gray-transparent-50);
    border-radius: 20px;
    padding: 2px 9px;
    display: flex;
    align-items: center;
    color: white;
    gap: 5px;
    margin-right: 5px;
    flex-shrink: 0;
  }
  .seeds-tags .seed .nm{
    white-space: nowrap;
    /* text-overflow: ellipsis; */
  }

  .info::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 120px;
    background: linear-gradient(rgba(0,0,0,0), rgb(24 24 24));
    pointer-events: none;
  }
  
  
  .info.active{
    height: fit-content;
    background-color: var(--un-background-color-gray);
  }
  
  .info.active .caption{
    overflow-y: inherit;
    max-height: inherit;
  }
  
  .is-sidebar-showed .info{
      padding-right: 70px;
  }
  
  @container pc (max-width: 500px) {
    .lc {
      min-height: auto;
      height: auto;
      width: 100%;
    }
    .lc img {
     
    } 
  
    .video_box{
      max-width: 100dvw;
      width: 100dvw;
    }
    .video_box video{
      border-radius: 0;
    }
    .cover_box{
      border-radius: 0;
    }
    .is-playing .cover_box{
      opacity: 1;
    }
    .info{
      /* max-width: calc(100% - 70px); */
      padding-right: 70px;
    }
  }
  
  
  /* actions */
  
  
  .actions{
    position: absolute;
    right: -80px;
    bottom: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    
    z-index: 1001;
  }
  
  .actions .context-menu{
    position: absolute;
    right: 0;
    bottom: 55px;
    min-width: 200px;
    background-color: var(--un-background-color-gray);
    border-radius: 10px;
  }
  .actions .context-menu .context-item{
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .actions .context-menu .context-item i{
    font-size: 1.3rem;
    margin-right: 0.6rem;
  }
  .actions .context-menu .context-item span{}
  .actions .context-menu .context-item:hover{
    background-color: var(--un-background-color-gray-light);
  }

  .actions .act-btn{
    display: flex;
    flex-direction: column;
    width: 50px;
    position: relative;
  }
  .actions .act-btn.active .act{
    background-color: var(--un-firm);
  }
  
  .actions .name{
    text-align: center;
    width: 100%;
    margin-top: 4px;
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
  }
  
  .actions .act{
    border-radius: 50%;
    background-color: var(--gd-shorts-act-back-color);
    aspect-ratio: 1/1;
    width: 50px;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .actions .act:hover{
    background-color: var(--gd-shorts-act-back-hover-color);
  }
  .actions .act i{}
  .actions .act-btn .act img{
    border-radius: 50%;
    aspect-ratio: 1/1;
    width: 100%;
    border: 5px var(--un-background-color-gray) solid;
  }
  .actions .act.act-diary img{
    border-radius: 5px;
    border: none;
    padding: 3px;
  }
  .is-sidebar-showed .actions{
    right: 10px;
    bottom: 10px;
  }
  
  @container pc (max-width: 500px) {
    .actions{
      right: 10px;
      bottom: 10px;
    }
  }
  
  
  /* actions video */
  
  .actions-video{
    position: absolute;
    right: 10px;
    bottom: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    z-index: 100;
  }
  .actions-video .act{
    border-radius: 50%;
    background-color: var(--gd-shorts-act-back-color);
    aspect-ratio: 1/1;
    width: 60px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .actions-video .act:hover{
    background-color: var(--gd-shorts-act-back-hover-color);
  }
  .actions-video .act i{
  
  }
  
  .is-sidebar-showed .actions-video{
    display: none;
  }
  
  @container pc (max-width: 900px) {
    .actions-video{
      display: none;
    }
  }
  
  /*  */

  .message_sidebar{
    width: 410px;
    height: 100%;
    padding: 0 1rem;
    background-color: var(--un-background-color-gray);
    z-index: 1002;
  } 
  .message_sidebar .close{
    /* background-color: var(--un-background-color-gray-dark); */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1003;
    right: 5px;
    top: 5px;
    cursor: pointer;
    display: none;
  }
  @container pc (max-width: 900px) {
    .message_sidebar{
      position: absolute;
      bottom: 0;
      max-height: 80dvh;
      width: 100%;
    }
    .message_sidebar .close{
      display: flex;
    }
  }


  /* anim */

  .anim{
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    aspect-ratio: 1/1;
    background-color: black;
    color: white;
    display: flex;
    border-radius: 50%;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
    z-index: 1003;
    pointer-events: none;
    opacity: 0;
  }
  .anim.animated{
    animation: scaleUp 0.5s;
  }

  @keyframes scaleUp {
    0% {
      transform: scale(1);
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  
  </style>
  
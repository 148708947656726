<template>

  <div class="actions">
    <UiButtonDropdown
      :name="'Actions'"
      :ico="'icon-dots-context'"
      :type="'link'"
      :options="actions"
      @choose="handleChoose"
    />
  </div> 
  
</template>

<script setup lang="ts">


const router = useRouter()
import type { Diary, Week } from '@/types/diary'
const emits = defineEmits(['removeWeek', 'editWeek', 'shareWeek'])


interface PageProps {
  diaryLink: string,
  owner: number,
  week: Week,
  isHarvest: boolean
}

const props = withDefaults(
  defineProps<PageProps>(),
  {
    isHarvest: false, 
  }
)


const actions = computed(() => {
  let ret = [];

  if(useAuth().getId() == props.owner){

    if(props.isHarvest){
      ret.push({id:'edit', event: 'editWeek', name: 'Edit Harvest'});
      ret.push({id:'edit', event: 'editWeekSmoke', name: 'Edit Smoke'});
    }else{
      ret.push({id:'edit', event: 'editWeek', name: 'Edit week'});
    }

    ret.push({id:'remove', event: 'removeWeek', name: 'Remove week'});

  }

  ret.push({id:'share', event: 'shareWeek', name: 'Share'});

  return ret;   
})
  


const editWeek = () => {
  router.push('/diaries/' + props.diaryLink + '/weeks/' + props.week.id + '/edit');
}

const editWeekSmoke = () => {
  router.push('/diaries/' + props.diaryLink + '/weeks/' + props.week.id + '/edit-smoke');
}


const handleChoose = (event: string) => {
  switch(event){
    case 'editWeek':
      editWeek();
      break;
    case 'editWeekSmoke':
      editWeekSmoke();
      break;
    case 'removeWeek':
      emits('removeWeek', props.week);
      break;
    case 'shareWeek':
      emits('shareWeek', props.week);
      break;
  }
}

</script>

<style scoped>
 
.actions:deep(.btn){
  padding-right: 0;
}
 
.actions:deep(.icon-dots-context){
  font-size: 1.2rem;
  line-height: 1rem;
  margin-left: 0.5rem;
}

@container pb (max-width: 768px) {
  
}
</style>

<template>
  <template v-if="data">
    <div class="product_review" v-for="(items, items_category) in aggrData">

      <div class="divider">{{ $t('reviews_title') }}.  {{ $t('reviews_' + items_category) }}</div>

      <WeekViewHarvestProductReviewItem
        v-for="(item_review) in items"
        :review="item_review"
        :brand="item_review.item_brand"
        :items="item_review.items_review"
        type="harvest_nutrient"
      />
    </div>
  </template>
</template>

<script setup lang="ts">


import { ProductCategorySort }  from "@/types/product"
 

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const sortedData = computed(() => {
  const result = props.data

  result.sort((a, b) => {
    return ProductCategorySort.indexOf(a.item_brand.category) - ProductCategorySort.indexOf(b.item_brand.category);
  })

  return result
})

const sortByCategory = (a, b) => {
  return ProductCategorySort.indexOf(a.item_brand.category) - ProductCategorySort.indexOf(b.item_brand.category);
}

const aggrData = computed(() => {

  const aggregated = props.data.reduce((acc, item) => {
    if (!acc[item.item_brand.category]) {
      acc[item.item_brand.category] = []
    }
    acc[item.item_brand.category].push(item)
    return acc
  }, {})

  // Sort the aggregated data by category
  const sortedAggregated = {}
  ProductCategorySort.forEach(category => {
    if (aggregated[category]) {
      sortedAggregated[category] = aggregated[category]
    }
  })

  return sortedAggregated
})

</script>

<style scoped>
.row_nutrient {
  margin-bottom: 1rem;
}

.row_nutrient .logo {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 90px;
}
.row_nutrient .logo .logo_img{
  max-height: 70px;
  max-width: 70px;
}
.row_nutrient .name{
  display: inline-block;
  width: 120px;
  vertical-align: top;
  margin-top: 19px;
  margin-left: 20px;
  line-height: 1.1rem;
}
.row_nutrient .name > a{
  font-weight: bold;
  color: var(--un-text-color);
  font-size: 1.1rem;
}
.row_nutrient .comment > .text{
  background-color: var(--un-background-color-gray);
  padding: 20px;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  width: 100%;
}
.row_nutrient .comment > p:before {
  content: "\A";
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--un-background-color-gray) transparent transparent;
  position: absolute;
  left: -10px;
  top: 21px;
}
.row_nutrient .comment .btn_translate{
  margin-top:-10px;
  margin-bottom:10px;
  display:block;
}
.row_nutrient .comment{
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 260px);
  margin-left: 20px;
}
.row_nutrient .comment .text{
  white-space: pre-wrap;
}

.row_nutrient .comment .nuts_items{
  margin-top: 1rem;
}
.row_nutrient .comment .nuts_items .nuts_item{
  display: inline-block;
  width: 49%;
  margin-bottom: 9px;
}
.row_nutrient .comment .nuts_items .nuts_item .photo{
  display: inline-block;
  vertical-align: top;
  max-height: 46px;
  max-width: 43px;
  width: 50px;
  text-align: center;
}
.row_nutrient .comment .nuts_items .nuts_item .photo .photo_img{
  max-height: 30px;
}
.row_nutrient .comment .nuts_items .nuts_item .info{
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 50px);
}
.row_nutrient .comment .nuts_items .nuts_item .info > a{
  color: var(--un-text-color);
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}


@container pb (max-width: 600px) {

  .row_nutrient{
    display: grid;
    grid-template-columns: 43px 1fr;
    grid-template-rows: auto auto;
    gap: 0px 0px;
    grid-template-areas:
    ". ."
    "d1 d1";
  }
  .row_nutrient .logo img{
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .row_nutrient .name{

    margin-top: 5px;
    margin-left: 0;
    width: 100%;
  }
  .row_nutrient .nuts_items{
    display:flex;
    align-items: flex-start;
    justify-self: flex-start;
    flex-wrap: wrap;
  }
  .row_nutrient .logo{
    width: 30px;
    margin: 0 auto;
  }

  .row_nutrient .comment{
    grid-area: d1;
    width: calc(100%);
    margin-left: 0;
  }
  .row_nutrient .comment .nuts_items .nuts_item{
    width: fit-content;
  }
}

</style>
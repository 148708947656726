<template>

  <NuxtLink class="seed" :to="props.link">


    <div class="photo">
      <img :src="avatar ? avatar : '/images/product-empty-avatar.png'" :alt="nmalt">

      <div class="rate_seed" v-if="rate">
        <span class="star_rate"></span>{{ rate }}<span class="max">/{{ rateof }}</span>
      </div>
  
    </div>
    <div class="info">
      <div class="name_seed">{{ nm }}</div>

      <div class="bottom_info" v-if="itemBrand">
        by {{ itemBrand.name }}
      </div>
      <div class="bottom_info">

        <div class="rw" v-if="cnt_diaries">{{ cnt_diaries }} {{ $t('universal_plurals_diaries') }}</div>
        <div class="rw" v-if="cnt_harvests">{{ cnt_harvests }} {{ $t('universal_plurals_harvests') }}</div>
        <div class="rw" v-if="cnt_reviews">{{ cnt_reviews }} {{ $t('universal_plurals_reviews') }}</div>

      </div>
    </div>
    
  </NuxtLink>

</template>

<script setup>
 
const props = defineProps({
  nm: {
    type: String,
    required: true
  },
  nmalt: {
    type: String,
    required: true
  },
  avatar: {
    type: String,
    required: false,
    default: null
  },
  section: {
    type: String,
    required: true
  },
  link: {
    type: String,
    required: true
  },
  rate: {
    type: Number,
    required: false
  },
  rateof: {
    type: Number,
    required: false,
    default: 10
  },
  itemBrand: {
    type: Object,
    required: false,
    default: null
  },
  cnt_harvests: {
    type: Number,
  },
  cnt_diaries: {
    type: Number,
  },
  cnt_reviews: {
    type: Number,
  }
})


</script>

<style scoped>
 
  

.seed {
    margin-right: 20px;
    overflow: hidden;
    min-width: 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100%/7 - 20px);
}

.group_page .products_list.centered .seed {
    margin: 10px;
    margin-bottom: 2rem;
}

@container pb (max-width: 1200px) {
    .seed {
        width: calc(100%/6 - 20px);
    }
}

@container pb (max-width: 1100px) {

    .seed {
        width: calc(100%/5 - 20px);
    }
}

@container pb (max-width: 1000px) {
    .seed {
        width: calc(100%/5 - 20px);
    }
}

@container pb (max-width: 650px) {
    .seed {
      width: calc(100%/3 - 20px);

    }
}
@container pb (max-width: 550px) {
    .seed {
        width: calc(50% - 20px);
    }
}


.seed .photo {
    width: 100%;
    display: block;
    vertical-align: top;
    position: relative;
    display: flex;
    align-items: center;
    aspect-ratio: 170/237;
    /* margin-top: auto; */
    /* margin-bottom: auto; */
}

.seed .type {
    color: gray;
}

.seed .photo img {
    width: 100%;
    border-radius: 3px;
}

.seed .info {
    display: block;
    /* height: 58px; */
    width: 100%;
    /* background-color: rgba(244, 244, 244, 0.34); */
    border-radius: 0px 0px 3px 3px;
}

.seed .info .name_seed {

    font-size: 0.85rem;
    padding-top: 6px;
    font-weight: bold;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    text-align: center;
    /* height: 43px; */
    display: flex;
    align-items: center;
    justify-content: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.seed .info .stat {
    display: none;
}

.seed .info .stat .type {
    display: block;
    text-align: left;
}

.seed .info .stat .type .type_item {
    color: gray;
    margin-bottom: 6px;
    display: inline-block;
    margin-right: 10px;
    /* float: left; */
}

.seed .info .stat .stat_item {
    /* display: inline-block; */
    vertical-align: top;
    /* width: 132px; */
    text-align: center;
    /* float: right; */
    display: block;
    height: 21px;
}

.seed .info .stat .stat_item .value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* font-size: 1.4rem; */
    color: #4e4e4e;
    display: inline-block;
}

.seed .info .stat .stat_item .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: gray;
    font-size: 1rem;
    display: inline-block;
    margin-left: 11px;
}


.seed .info .stat .stat_item.rate_value .star_rate {
    float: left;
    margin-right: 6px;
    width: 17px;
    height: 17px;
}

.seed .info .stat .stat_item.rate_value {
    text-align: left;
}


.seed .info .text {
    display: none;
}

.seed .small_rate {
    display: block;
    color: gray;
    min-height: 28px;
}


.seed .rate_seed {
    color: var(--un-text-color);
    position: absolute;
    bottom: 10px;
    right: 5px;
    width: auto;
    padding: 4px 15px 2px 10px;
    background-color: var(--un-background-color-gray);
    font-weight: bold;
    border-radius: 20px;
    box-shadow: 0px 1px 3px 0px var(--un-element-color-gray), 0px 0px 0px 1px var(--un-element-color-gray);
}

.seed .rate_seed .max {
    font-size: 0.85rem;
}

.seed .star_rate {
    margin-right: 6px;
    width: 16px;
    display: inline-block;
    vertical-align: top;
    height: 16px;
}

.seed .bottom_info {
    display: block;
    width: 100%;
    /* padding-left: 1rem; */
    overflow: hidden;
    white-space: nowrap;
    /* padding-right: 1rem; */
    /* background-color: #f4f4f4; */
    color: #9c9c9c;
    font-size: 0.75rem;
    border-radius: 0px 0px 3px 3px;
    text-align: center;
}
.seed .bottom_info .rw:not(:first-child)::before {
    content: " • ";
}
.seed .bottom_info .rw {
    display: inline-block;
    padding: 0rem 0.3rem 0.4rem 0rem;
    font-size: 0.75rem;
    color: #9c9c9c;
}

.seed .dot {
    display: inline-block;
    width: 3px;
    height: 3px;
    background-color: #b5b5b5;
    margin: 0 6px 0 4px;
    margin-bottom: 2px;
}




</style>

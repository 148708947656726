<template>
  <div class="pb grid_width">
 
    <div class="headerow">

      <h1>
        {{ $t('nutrients_view_title') }} 
      </h1>
  

      <GeneralSearchShort
        @search="changeKeyword"
        :val="keyword"
        :placeholder="$t('nutrients_search_box')"
        />  

      
      <UiSort 
        @choose="changeSort"
        :val="sort"    
        :options="[
          {id:'rate_asc', name: $t('universal_sort_reviews2')},    
          {id:'az_asc', name: $t('universal_sort_az2')},        
          {id:'gplant_desc', name: $t('universal_sort_gplant')},      
          {id:'harvests_desc', name: $t('universal_sort_harvests2')},      
          {id:'diaries_desc', name: $t('universal_sort_diaries2')},      
          {id:'growers_desc', name: $t('universal_sort_growers2')},       
        ]"
        />
      
    </div>
      

    <SkGrowerRows
      v-if="is_loading && start == 0"
      />

    <div 
      v-else-if="brand_nutrient_data?.items.length"
      class="catalog_content brands_boxs results two_column">
      <template 
        v-for="item in brand_nutrient_data.items"        
        >     
        <GeneralCatalogRow
          :rating="item.item_stat.rate"
          :logo="item.avatar_small"
          :link="'/nutrients/' + item.section"
          :nm="item.name"
          :country="item.country"
          :item_stat="item.item_stat"
          :products="item.item_stat.cnt_items"
          :gplant="item.item_stat.avg_weight_plant"
          :diaries="item.item_stat.cnt_reports"
          :growers="item.item_stat.cnt_growers"
          :harvests="item.item_stat.cnt_harvests"
          :rate="item.item_stat.avg_rate"
          :chat-user="item.offical_user_id"
          :types="item.spec_props"
        />
      </template>
     
      <ClientOnly>
        <InfinityScroll 
          :is-loading="is_loading"
          ref="elInfinityScroll"/>
      </ClientOnly>
              
    </div>


    <SkGrowerRows
      v-else-if="is_loading && !brand_nutrient_data?.length"
      />

    <UiEmpty
      v-else
      />
  

  </div>
</template>

<script setup>
 



import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'
import InfinityScroll from '~/components/InfinityScroll.vue';


const start = ref(0)
const limit = ref(20)
const isEnded = ref(false)
const {$api, $tagsUtil, $ga} = useNuxtApp()
const route = useRoute();
const elInfinityScroll = ref(null)
const sort = ref('harvests_desc')
const keyword = ref(route.query['keyword']?.length ? route.query['keyword'] : '')
const tagscount = ref([]);
const tagsselected = ref(route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : []))
const tagsdefault = ref(null);
const tags = ref($tagsUtil.tagsBrands)
const tagspermanent = ref([]);


tagspermanent.value.push('cg:nutrient');

const changeSort = function(val) {  
  start.value = 0;
  sort.value = val;
}  

const changeKeyword = function(val) {  
  start.value = 0;
  keyword.value = val;
}  

const loadData = async function() {        
  const response = await $api.getBrands({
    keyword: keyword.value,
    sortable: sort.value,
    start: start.value,
    limit: limit.value,
    tagsall: $tagsUtil.getTagsKeys(tags.value), 
    tags: $tagsUtil.getTagsSimple(tags.value, tagsselected.value),
    tagsper: $tagsUtil.getTagsSimple(tags.value, tagspermanent.value)
  })  
  return response;  
}



const { pending: is_loading,  data: brand_nutrient_data } = await useLazyAsyncData('brand_nutrient_data', async () => await loadData())



watchArray([start, sort, keyword], async ([new_start, new_sort, keyword], added, removed) => {
  console.log('watcher');
  is_loading.value = true;
  
  var dt = await loadData();

  if(new_start == 0){
    brand_nutrient_data.value.items = [];
    isEnded.value = false;
  }  

  if(dt.length == 0)
    isEnded.value = true;
  
  brand_nutrient_data.value.items = [...brand_nutrient_data.value.items, ...dt.items]  

  is_loading.value = false;
}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

 
useHead(useNuxtApp().$head.getBrandNutrientList());

watchArray([route], async ([new_route], added, removed) => {
  
  useHead(useNuxtApp().$head.getBrandNutrientList());

}, {deep: true})


 
</script>

<style scoped>


h1{
  margin-right: auto;
}

.add_filter_box{
  display: flex;
  margin-left: auto;
}

.headerow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

@container pb (max-width: 700px) {
  .headerow{

  }
  .headerow > .search{
    order: 1;
    width: 100%;
  }
  .headerow > .stags{
    order: 0;
    margin: 0;
  }
  .headerow > .sort{
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }
}


</style>

import { useNuxtApp } from '#app'
import type { Diary, Week, WeekNutrient } from '@/types/diary'
import { WeekFazaLetter, WeekFaza } from '@/types/diary'

/**
 * useDiary — logic (CRUD, likes, subscriptions) for the "Diary" entity
 */
export function useWeek() {
  const { $api, $popup, $follow, $convert } = useNuxtApp()

  /**
   * remove week
   * @param diaryId ID of the diary
   * @param weekId ID of the week
   */
  async function remove(diaryId: number, weekId: number): Promise<boolean> {

    if (!confirm('Delete week?')) return false
    try {
      await $api.deleteWeek(diaryId, weekId)
      $popup.success('Success')
      return true 
    } catch (error) {
      $popup.error('Error')
      throw error
    }
  }

  /**
   * remove week from items
   * @param diary object of the diary
   * @param week object of the week
   * @example fdowpr - Flowering
   * @example hdowpr - Harvest
   */

  function getWeekFazaFromLink(link: string | null): number | null {
    if (!link) return null;
    let letter= link.substr(0,1);
    if (letter === 'g') return WeekFaza.Germination as unknown as number;
    if (letter === 'v') return WeekFaza.Vegetation as unknown as number;
    if (letter === 'f') return WeekFaza.Flowering as unknown as number;
    if (letter === 'h') return WeekFaza.Harvest as unknown as number;
    return null;
  }
  
  /**
   * Like or unlike a week
   * @param diary object of the diary
   * @param week object of the week
   */
  function like(diary: Diary, week: Week) {
    const index = diary.addon.likes.indexOf(week.id)
    if (index >= 0) {
      diary.addon.likes.splice(index, 1)
      week.cnt_likes--
    } else {
      diary.addon.likes.push(week.id)
      week.cnt_likes++
    }
  }




  function fillWeekFromPrev(weeks: Week[], week: Week) {
    if (!weeks.length) return week

    const lastWeek = weeks
      .filter(w => w.faza === WeekFaza.Vegetation || w.faza === WeekFaza.Flowering)
      .sort((a, b) => b.days - a.days)[0]

    if (!lastWeek) return

    const lastWeekData = lastWeek.faza === WeekFaza.Vegetation ? lastWeek.item_vegetation : lastWeek.item_flowering
    if (!lastWeekData) return
    week.days = lastWeek.days + 1
    
    if (lastWeek.props) {
      week.props = lastWeek.props
    }
    
    if (lastWeekData.items_nutrient) {
      const lastNutrients = JSON.parse(JSON.stringify(lastWeekData.items_nutrient))
      lastNutrients.forEach((item: WeekNutrient) => {
        item.ref_id = null
      })
      week.items_nutrient = lastNutrients
    }

    convertUnitsForEdit(week)
  }



  function convertUnitsForEdit (w: Week) {

    if(w.faza === WeekFaza.Vegetation || w.faza === WeekFaza.Flowering){
      var wd = w.faza === WeekFaza.Vegetation  ? w.item_vegetation : w.item_flowering;
      w.props.height = w.props.height == 0 ? null :  $convert.universal('length', useAuth().getLength(), w.props.height);
      w.props.air_temp = w.props.air_temp == 0 ? null : $convert.universal('temperature', useAuth().getTemperature(), w.props.air_temp);
      w.props.ppm = w.props.ppm == 0 ? null : $convert.universal('tds', useAuth().getTds(), w.props.ppm);
      w.props.solution_temp = w.props.solution_temp == 0 ? null : $convert.universal('temperature', useAuth().getTemperature(), w.props.solution_temp);
      w.props.substrate_temp = w.props.substrate_temp == 0 ? null : $convert.universal('temperature', useAuth().getTemperature(), w.props.substrate_temp);
      w.props.night_air_temp = w.props.night_air_temp == 0 ? null : $convert.universal('temperature', useAuth().getTemperature(), w.props.night_air_temp); 
      w.props.pot_size = w.props.pot_size == 0 ? null : $convert.universal('volume', useAuth().getVolume(), w.props.pot_size);
      w.props.lamp_distance = w.props.lamp_distance == 0 ? null : $convert.universal('length', useAuth().getLength(), w.props.lamp_distance);
      w.props.watering_volume = w.props.watering_volume == 0 ? null : $convert.universal('volume', useAuth().getVolume(), w.props.watering_volume);

      if(wd.items_nutrient){
        wd.items_nutrient.map((item) => {
          item.val = $convert.universal('nutrient', item.unit, item.val);
        });
      }

    }else if(w.faza == WeekFaza.Harvest){
      var wd = w.item_harvest;
      w.props.harvest_weight = $convert.universal('weight', useAuth().getWeight(), w.props.harvest_weight);
      w.props.harvest_wet_weight = $convert.universal('weight', useAuth().getWeight(), w.props.harvest_wet_weight); 
      w.props.harvest_space = $convert.universal('length', useAuth().getLength(), w.props.harvest_space);
    }else if(w.faza == WeekFaza.Germination){
      var wd = w.item_germination;
      if(wd.items_nutrient){
        wd.items_nutrient.map((item) => {
          item.val = $convert.universal('nutrient', item.unit, item.val);
        });
      }
    }



  }



  const convertUnitsForSave = (w: Week) => {


    w.props.height = $convert.legitimed('length', useAuth().getLength(), w.props.height, 5);
    w.props.air_temp = $convert.legitimed('temperature', useAuth().getTemperature(), w.props.air_temp);
    w.props.ppm = $convert.legitimed('tds', useAuth().getTds(), w.props.ppm, 5);
    w.props.solution_temp = $convert.legitimed('temperature', useAuth().getTemperature(), w.props.solution_temp);
    w.props.substrate_temp = $convert.legitimed('temperature', useAuth().getTemperature(), w.props.substrate_temp);
    w.props.night_air_temp = $convert.legitimed('temperature', useAuth().getTemperature(), w.props.night_air_temp); 
    w.props.pot_size = $convert.legitimed('volume', useAuth().getVolume(), w.props.pot_size, 5);
    w.props.lamp_distance = $convert.legitimed('length', useAuth().getLength(), w.props.lamp_distance, 5);
    w.props.watering_volume = $convert.legitimed('volume', useAuth().getVolume(), w.props.watering_volume, 5);
    w.props.harvest_weight = $convert.legitimed('weight', useAuth().getWeight(), w.props.harvest_weight, 5);
    w.props.harvest_wet_weight = $convert.legitimed('weight', useAuth().getWeight(), w.props.harvest_wet_weight, 5); 
    w.props.harvest_space = $convert.legitimed('length', useAuth().getLength(), w.props.harvest_space, 5);
    
    if(w.faza == WeekFaza.Vegetation || w.faza == WeekFaza.Flowering){
      var wd = w.faza == 0 ? w.item_vegetation : w.item_flowering;

      if(wd.items_nutrient){
        wd.items_nutrient.map((item) => {
          console.log('item.unit-', item.unit, item.val);
          item.val = $convert.legitimed('nutrient', item.unit, item.val, 5);
          console.log('item.unit=', item.unit, item.val);
        });
      }

    }else if(w.faza == WeekFaza.Harvest){
      var wd = w.item_harvest;
    }else if(w.faza == WeekFaza.Germination){
      var wd = w.item_germination;
      if(wd.items_nutrient){
        wd.items_nutrient.map((item) => {
          item.val = $convert.legitimed('nutrient', item.unit, item.val, 5);
        });
      }
    }


  }
  





  function validateWeek (weeks: Week[], editWeek: Week, isWeekSmoke: boolean = false) {  
    if(editWeek.faza == WeekFaza.Germination){

      if(!editWeek.items_photo?.length){
        $popup.error('Empty photos');
        return false;
      }


      if(editWeek.item_germination?.items_seed?.length){
        
        var emptyGermSeeds = editWeek.item_germination.items_seed.filter((item) => {
          // if(item.category != 'seed') return false;

          if(item.cnt_germ === null){
            return item;
          }else{
            return false;
          }
        });

        if(emptyGermSeeds.length){
          $popup.error('Some of strain success field is empty');
          return false;
        }


      }

      if(!editWeek.text){
        $popup.error('Empty comment field');
        return false;
      }
    

    }else{

      if(!editWeek.days){
        $popup.error('Empty week number');
        return false;
      }

      if(editWeek.faza == WeekFaza.Harvest){
        
        if(!editWeek.item_harvest.item_review_seed.n_general){
          $popup.error('Empty strain score');
          return false;
        }
 

        console.log('editWeek.item_harvest?.items_review-', editWeek.item_harvest?.items_review);
 
        if(editWeek.item_harvest?.items_review?.length && !isWeekSmoke){

          var notRatedProduct = editWeek.item_harvest.items_review.filter((item) => {
            return item.items_review.filter((item) => !item.n_general)?.length;
          });

          if(notRatedProduct.length){
            $popup.error('Not all products are rated');
            return false;
          } 

        }
 

      }


      if(editWeek.faza == WeekFaza.Vegetation || editWeek.faza == WeekFaza.Flowering){

        var duplicates = weeks.filter((item) => {
          if(item.days == editWeek.days && item.id != editWeek.id && (item.faza == WeekFaza.Vegetation || item.faza == WeekFaza.Flowering)){
            return item;
          }
        });

        if(duplicates.length){
          $popup.error('Week with this number ' + editWeek.days + ' already exists');
          return false;
        }
      }

      if(!editWeek.items_photo?.length){
        $popup.error('Empty photos');
        return false;
      }
      if(!editWeek.text){
        $popup.error('Empty comment field');
        return false;
      }
    }
    return true;
  } 





  return {
    remove,
    getWeekFazaFromLink,
    fillWeekFromPrev,
    convertUnitsForEdit,
    convertUnitsForSave,
    validateWeek,
    like
  }
}
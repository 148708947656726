import { useNuxtApp } from '#app'
// import type { Blog } from '@/types/blog'

export function useBlog() {
  // const { t } = useI18n()
  const { $api, $popup, $follow } = useNuxtApp()
 
  function addImpression(id: number | null | undefined) {
    if (!id) return
    $api.postJournalImpressions(id)
    .then(response => {

    })
    .catch(error => {

    });
  }



 
  return {

    addImpression,
  }
}
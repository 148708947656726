<template>


  <div class="slider" v-if="props.week?.items_photo">


    <div class="photos" :size="props.week.items_photo.length">


      <UiCarousel :slides="props.week.items_photo.slice(0, 6)">
        <template #slide="{ data, index }">
          <div class="photo">

            <div v-if="data.is_video" class="video_play"></div>

            <picture>
              <source
                media="(max-width: 600px)"
                :srcset="`${data.size_m} 320w, ${data.size_l} 640w`"
                sizes="(max-width: 600px) 100vw"
              />
              <source
                v-if="props.week.items_photo.length === 1"
                :srcset="`${data.size_l} 640w, ${data.size_xl} 1280w`"
                sizes="(min-width: 601px) 50vw"
              />
              <source
                v-else
                :srcset="`${data.size_m} 320w, ${data.size_l} 640w`"
                sizes="(min-width: 601px) 33vw"
              />

              <img loading="lazy"
                :src="props.week.items_photo.length === 1 ? data.size_l : data.size_m"
                @click="openGallery(data.id)"
              >
            </picture>          
          </div>

          <div class="more" v-if="props.week.items_photo.length > 6 && 5 == index">
            More photos +{{ props.week.items_photo.length - 6 }}
          </div>

        </template>
      </UiCarousel>

    
    </div>
  
 
  </div>

  <GeneralLightboxVertical 
    v-if="lightboxShow"
    :photo-id="lightboxId" 
    :photos="lightboxPhotos" 
    @close="lightboxShow = false" 
  />
  
</template>

<script setup lang="ts">

import type { Diary, Week } from '@/types/diary';
import type { PhotoGallery } from '@/types/photo';
import { ref, computed, onMounted, onBeforeUnmount, defineProps, defineEmits } from 'vue';

interface Props {
  diary: Diary;
  week: Week;
}
const props = defineProps<Props>();

const lightboxShow = ref(false);
const lightboxId = ref<number | null>(null);

const lightboxPhotos = computed(() => {
  const items: Array<PhotoGallery> = [];

  if (!props.week.items_photo) return items;

  for (const p of props.week.items_photo) {
    const photo: PhotoGallery = {
      id: p.id,
      width: p.width,
      height: p.height,
      base: p.size_big,
      zoom: p.size_big,
      caption: p.comment,
    };

    if (p.is_video) {
      photo.type = 'iframe';
      photo.iframe = '/video/' + p.video_id;
    }

    items.push(photo);
  }
  return items;
});

const openGallery = (key: number) => {
  lightboxShow.value = true;
  lightboxId.value = key;
};
 
</script>



<style scoped>

 .slider{
  /* margin-bottom: 2rem; */
 }
.galpan{

  display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}
.cover{
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 4/3;
    border-radius: 5px;
}

/* week */

.week{
  font-weight: bold;
  padding-bottom: 4px;
  position: absolute;
  z-index: 1;
  background-color: #ffffffe6;
  border-radius: 5px;
  padding: 4px 12px;
  position: sticky;
  top: 60px;
  width: fit-content;
  margin-top: 12px;
  margin-bottom: -40px;
  font-size: 1rem;
  margin-left: 10px;
}
/* photos */
.photos:deep(.dots-wrapper){
  display: none!important;
}
.photos:deep(.slides){
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  position: relative;
  /* aspect-ratio: 1 / 1; */
  aspect-ratio: none;
} 
.photos .more{
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ffffffe6;
  padding: 4px 8px;
  border-radius: 5px;
  cursor: pointer;
  /* color: var(--un-text-color-gray); */
  /* font-weight: bold; */
  z-index: 1;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: 0.3s;
  pointer-events: none;
} 
.photos:deep(.slides .slide){
  display: flex;
  flex-wrap: wrap;
  aspect-ratio: 4/3;
  flex: 1 1 calc(100%/3 - 4px);
  /* remove id need 1 big photo */
  max-width: calc(100%/3 - 4px);
}
.photos .photo {
  width: 100%;
  height: 100%;
}

.video_play {
  width: 56px;
  height: 56px;
  z-index: 1;
  position: absolute;
  background-image: url(/images/play_button.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 62px;
  left: calc(50% - 28px);
  top: calc(50% - 28px);
  pointer-events: none;
}
.photos .photo picture{
  width:100%;
  height:100%;
}
.photos .photo img{
  width:100%;
  height:100%;
  object-fit: cover;
  border-radius: 5px;
}

.photos img:only-child {
  flex-basis: 100%;
}


/* .photos .photo:nth-last-child(2):first-child,
.photos .photo:nth-last-child(2):last-child {
    flex-basis: calc(100%/3 - 4px);
} */

@container pb (max-width: 600px) {

  .photos:deep(.dots-wrapper){
    display: flex!important;
  }
  .photos:deep(.slides){
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
    margin-left: -20px;
    width: calc(100% + 40px);
    gap: 0;
  }
  .photos::-webkit-scrollbar {
    display: none;
  }
  .photos:deep(.slides .slide){
    aspect-ratio: 1 / 1;
    flex-shrink: 0;
    width: 100% !important;
    flex: none;
    max-width: none;
    align-items: center;
    contain: layout;
    justify-content: center;
    position: relative;
    scroll-snap-align: center;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }
  .photos .photo img{
    aspect-ratio: 1 / 1;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    border-radius: 0;
  } 
  .week{
    margin-left: 0;
  }
}


/* SIZES GALLERY */
@container pb (min-width: 600px) {
  .photos[size="1"] :deep(.slides .slide){
    max-width: 100%;
    flex: 1 1 100%;
  }
  .photos[size="2"] :deep(.slides .slide){
    max-width: calc(100%/2 - 4px);
    flex: 1 1 calc(100%/2 - 4px);
  }
  .photos[size="3"] :deep(.slides .slide){
    max-width: calc(100%/3 - 4px);
    flex: 1 1 calc(100%/3 - 4px);
  }
  .photos[size="4"] :deep(.slides .slide){
    max-width: calc(100%/2 - 4px);
    flex: 1 1 calc(100%/2 - 4px);
  }
  .photos[size="5"] :deep(.slides .slide):nth-child(1),
  .photos[size="5"] :deep(.slides .slide):nth-child(1),
  .photos[size="5"] :deep(.slides .slide):nth-child(3){
    max-width: calc(100%/3 - 4px);
    flex: 1 1 calc(100%/3 - 4px);
  }
  .photos[size="5"] :deep(.slides .slide):nth-child(4),
  .photos[size="5"] :deep(.slides .slide):nth-child(5){
    max-width: calc(100%/2 - 4px);
    flex: 1 1 calc(100%/2 - 4px);
  }
}



.dots{
  display: flex;
  gap: 4px;
  position: relative;
  margin-top: -20px;
  margin-bottom: 20px;
  
  justify-content: center;
  z-index: 1;
}

/* .dot{
  width: 7px;
  height: 7px;
  background-color: rgb(159, 159, 159);
  border-radius: 50%;
}

@container pb (min-width: 600px) {
  .dots{
    display: none;
  }
} */

</style>

<template>

  <div class="actions">


    <UiButtons>

      <template v-if="useAuth().isOwner(props.owner)">
  
        <NuxtLink class="btn" :to="props.link + '/settings'">
          {{ $t('profile_menu_button_settings') }}
        </NuxtLink>

      </template>

      <template v-if="!useAuth().isOwner(props.owner)">
        
        <UiButton        
          @click="complain(props.owner)"
          ico="icon-warning-round"    
          :title="$t('universal_button_complain')"    
          />

        
        <UiButton        
          @click="toChat"
          name="Message"
          ico="icon-message"        
          />
  
        <UiButton
          v-if="!isFollow"
          @click="emits('follow')"
          name="Follow"
          type="primary"
          :cnt="props.followers"
          />
  

        <UiButton
          v-if="isFollow"
          @click="emits('unfollow')"
          name="Unfollow"        
          :cnt="props.followers"
          />
        
      </template>

    </UiButtons>

  </div>
  
</template>

<script setup lang="ts">

const { hooks, $complains, $popup } = useNuxtApp()
const { t } = useI18n();
const emits = defineEmits(['follow', 'unfollow']);

interface Props {
  owner: number;
  link: string;
  id: number;
  followers: number;
  isFollow?: boolean | null;
  chatUser?: number;
}

const props = defineProps<Props>();

// const props = defineProps({
//   owner: {
//     type: Number
//   },
//   link: {
//     type: String
//   }, 
//   id: {
//     type: Number
//   }, 
//   followers: {
//     type: Number
//   },
//   isFollow: {
//     type: Boolean,
//     default: false
//   },
//   chatUser: {
//     type: Number,
//     required: false
//   }
// });

// const actionsEditDiary = computed(() => {
//   var actions = [];

//   actions.push({
//     name: 'Edit diary',
//     link: '/diaries/edit/' + this.diary
//   });

//   if(this.diaryhidden)
//     actions.push({
//       name: 'Publish diary',
//       event: 'publish'
//     });
//   else
//     actions.push({
//       name: 'Hide diary',
//       event: 'unpublish'
//     });

//   actions.push({
//     name: 'Remove diary',
//     event: 'remove'
//   });

//   return actions;
// });



const toChat = function(e) {
  e.preventDefault();
  e.stopPropagation();
  hooks.callHook('chat:start', props.chatUser).catch(() => {
    console.log('chat:start hook');    
  })

}

const complain = (id) => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();

    // Lang.get('chat_complain_confirm'),Lang.get('chat_complain_confirm_text')

  if(!confirm(t('chat_complain_confirm_text')))
    return;

    useLoadingIndicator().start();
  $complains.add('user', id)
  .then((res) => {       
    useLoadingIndicator().finish();
    $popup.success('Complained');
  })
  .catch((res) => {       
    useLoadingIndicator().finish();
    $popup.error('Error');
  });
}

</script>

<style scoped>
 
 .actions{
   display: flex;
   justify-content: center;
  white-space: nowrap;
  flex-shrink: 0;
 }


 @media screen and (max-width: 768px) {
  .actions{
    display: none;
  }
}
</style>

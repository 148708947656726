<template>
  <div class="pbf">
     
     <BrandTabReviews
      :inurl="'/users/' + props.id + '/reviews'"   
      :grower-id="props.id"  
      :sort-options="[
        {id:'rate', name: $t('universal_sort_review')},    
        {id:'create', name: $t('universal_sort_creation_date')},
        {id:'az', name: $t('universal_sort_az')},
        {id:'gwatt', name: $t('universal_sort_gwatt')},
        {id:'gplant', name: $t('universal_sort_gplant')},
      ]" 
      />

  </div>
</template>

<script setup lang="ts">

const route = useRoute();
const { $api, $ga, $helper, $tagsUtil, $head } = useNuxtApp();


interface Props {
  user: User;
  id: number;
}

const props = defineProps<Props>();

useHead(useNuxtApp().$head.getGrowerReviews(props.user));

</script>

<style scoped>
 

</style>

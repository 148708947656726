<template>
 
  <div class="mk2">
    
    <div class="hd">
      <div class="nf">
        <div class="hnt">
          {{ $t('partner_landing_subtitle') }}
        </div>
        <h1 class="ttl">
          {{ $t('partner_landing_title_1') }} <span>{{ $t('partner_landing_title_2') }}</span>
          <br/>
          {{ $t('partner_landing_title_3') }}
        </h1>
      </div>

      <img loading="lazy" src="/partner/mediakit/hero_img.gif" alt="" class="psn">

    </div>

    <div class="cont">

      <PartnerPlansv2 
        @change="checkout_order = $event"
        />

      <div class="act">
        <div class="row">
          <UiInput 
            :text="checkout_company" 
            @change:text="checkout_company = $event"
            label="Company name"
            />
        </div>

        <div class="row">
          <UiInput 
            :text="checkout_email" 
            label="Email"
            @change:text="checkout_email = $event"
            />
        </div>

        <div class="row"> 
          <NuxtTurnstile ref="turnstile" v-model="token" />
        </div>
        <div class="row"> 
          <UiButton 
            @click="checkout" 
            :disabled="checkout_sending"
            name="Checkout"
            />
        </div>
      </div>

      <div class="notes">
        <div>* SOV - Share of Voice</div>
        <div>** All prices are based on a 12-month agreement</div>
        <div>*** Each plan targets 1 main region: North America or ROW (rest of world)</div>
        <div>**** Platinum plan target both North America and ROW regions (2x Sitewide banner impressions in the main region, 1x Sitewide banner impressions in the additional region).</div>    
      </div>

      <div class="opts">
        <PartnerDescription/>
      </div>

    </div>
  </div> 


</template>

<script setup lang="ts">
 
 const {$api, $ga, $popup} = useNuxtApp()

const checkout_company = ref('')
const checkout_email = ref('')
const checkout_order = ref('')
const checkout_sending = ref(false)
const token = ref(null)


const validate = function() {     
  
  if(!token.value) {    
    $popup.error('Waiting captcha to be filled or refresh page');
    return false;
  }
  
  if(!checkout_company.value) {    
    $popup.error('Company name is required');
    return false;
  }

  if(!checkout_email.value) {    
    $popup.error('Email is required');
    return false;
  }

  return true;
}


const checkout = function() {

  if(!validate()) return false;
  
  checkout_sending.value = true;

  $api.postCheckoutPartner({
    company: checkout_company.value,
    email: checkout_email.value,
    order: checkout_order.value,
    recaptcha: token.value
  }) 
  .then((res) => {                 
    $popup.success('Thank you for your interest. We will contact you soon.');
    checkout_company.value = '';
    checkout_email.value = '';
    checkout_sending.value = false;
  })
  .catch(function (error) { 
    console.log('save error');       
    console.log(error);            
    checkout_sending.value = false;     
  });  

}

</script>

<style scoped>
 

/* HERO */

.mk2 {
  position: relative;
}
.mk2 .warn{
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}
.mk2 .warnc{
  background-color: #fea500;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-shadow: 0px -1px 0px #00000057;
  border-bottom: 1px #b37500 solid;
  max-width: 530px;
  text-align: center;
  width: auto;
  margin: 1rem;
}
.mk2 .hd{
background-position: center;
background-repeat: no-repeat;
/* background-image: url('../mk2/hero_bg.gif'); */
display: flex;
align-items: center;
justify-content: center;
background-size: cover;
/* padding-bottom: 5rem; */
padding-top: 6rem;
/* background-color: gray; */
/* background: rgb(244,244,244); */
background: linear-gradient(0deg, var(--un-background-color-transparent) 0%, var(--un-background-color-gray) 100%);
}
.mk2 .hd .nf{
width: 500px;
}
.mk2 .hd .nf .hnt{font-size: 1.25rem;}
.mk2 .hd .nf .ttl{
font-size: 4rem!important;
line-height: 4rem;
margin-top: 1rem;
}
.mk2 .hd .nf .ttl span{
color: #2a8758!important;
}
.mk2 .hd .psn{
width: 260px;
margin-top: 3rem;
}

@media (max-width: 768px) {
.mk2 .cont{
  padding: 0 2rem;
}
.mk2 .hd{
    display: flex;
    flex-direction: column;
}
.mk2 .hd .nf{
    width: auto;
    text-align: center;
    margin-top: 20px;
}
.mk2 .hd .nf .ttl {
    font-size: 3rem!important;
    line-height: 3rem;
}
}


/*  */



/* ACT */


.act{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 900px;
  margin: 0 auto;
  padding: 2.5rem;
} 
.act .row{
  width: 100%;
  max-width: 300px;
  margin-bottom: 5px;
}
.act .row .input{
width: 100%;
}
.act .row input{
width: 100%;
}
.act .button{
width: 100%;
background-color: #67ad3c!important;
color: white;
}
/*  */

/* thanks */

.thanks{
width: 100%;
text-align: center;
padding: 5rem 2rem;
font-weight: bold;;

}


/* notes */

.notes{
display: flex;
flex-direction: column;
align-items: flex-start;
max-width: 900px;
margin: 0 auto;
padding: 2.5rem;
color: gray;
}
 
</style>
<template>

  <div class="pbf">

    <template v-if="seed_data?.id">

    <BrandHeadProduct 
      :id="seed_data.id"
      :nm="seed_data.name"
      :nmalt="seed_data.name"
      :info="seed_data.description"
      :logo="seed_data.item_brand.logo_s"
      :avatar="seed_data.cover_s"
      :link="seed_data.section"
      :link-shop="seed_data.link_shop"
      :pos="seed_data.item_stat?.rate"
      :rate="seed_data.item_stat?.avg_rate"
      :cnt-reports="seed_data.item_stat?.cnt_reports"
      :cnt-harvests="seed_data.item_stat?.cnt_harvests"
      :weight-plant="seed_data.item_stat?.avg_weight_plant"
      :effects="seed_data.items_harvest_effect"
      :tastes="seed_data.items_harvest_taste"
      :slogan="seed_data.slogan"
      :cnt_followers="seed_data.item_stat?.cnt_followers"
      :isfollow="seed_data?.addon?.follow"
      :type="type"
      :items-photo="seed_data.items_photo"
      :offical_user="seed_data.offical_user"
      :type-gender="seed_data.props.seed_gender_type"
      :type-flowering="seed_data.props.seed_flowering_type"
      :cbd-min="seed_data.props.cbd_cbd_min"
      :cbd-max="seed_data.props.cbd_cbd_max"
      :thc-min="seed_data.props.cbd_thc_min"
      :thc-max="seed_data.props.cbd_thc_max"
      :bread="[
        {name: $t('breeder_view_link_text'), link: '/seedbank'},
        {name: seed_data.item_brand.name, link: '/seedbank/' + seed_data.item_brand.section},
        {name: seed_data.name, link: seed_data.section},
      ]"
      :brand_name="seed_data.item_brand.name"
      :brand_link="'/seedbank/' + seed_data.item_brand.section"
      :strain="seed_data.item_strain"
      @follow="follow(1)"
      @unfollow="follow(0)"
      >

      </BrandHeadProduct>

 

    <div class="pb grid_width">  

    <UiTabs 
      :tabs="getTabs" 
      :acttab="active_tab"
      />    
      <br>

      <NuxtPage 
        :data="seed_data"
        :id="seed_data.id"
        />
    </div>

    
    <BrandContextMenu 
      :is-follow="seed_data?.addon?.follow" 
      @follow="follow(1)"
      @unfollow="follow(0)"
      />

    </template> 
  
  <template v-else>
    <SkBrandOpened />
  </template> 
      
  </div>
</template>

<script setup>

import { watchArray } from '@vueuse/core'

const route = useRoute();
const router = useRouter();
const {$api, $tagsUtil, $ga, $follow, $popup} = useNuxtApp()
var link = ref(route.params['id']);
var link2 = ref(route.params['id2']);
var active_tab = ref('info');
var type = ref('seed');



const getTabs = computed(() => {  

  var items = [];

  items.push({
    id: 'info',
    name: 'Info',
    link: seed_data.value?.link + '',
    count: 0,
    icon: '',
  });

  if(seed_data.value?.item_stat?.cnt_reports){
    items.push({
      id: 'diaries',
      name: 'Diaries',
      link: seed_data.value?.link + '/diaries',
      count: seed_data.value?.item_stat?.cnt_reports,
      icon: '',
    });
  }


  if(seed_data.value?.item_stat?.cnt_photos){
    items.push({
      id: 'gallery',
      name: 'Gallery',
      link: seed_data.value?.link + '/gallery',
      count: seed_data.value?.item_stat?.cnt_photos,
      icon: '',
    });
  }

  if(seed_data.value?.item_stat?.cnt_growers){
    items.push({
      id: 'growers',
      name: 'Growers',
      link: seed_data.value?.link + '/growers',
      count: seed_data.value?.item_stat?.cnt_growers,
      icon: '',
    });
  }

  if(seed_data.value?.item_stat?.cnt_harvests){
    items.push({
      id: 'Harvests',
      name: 'Harvests',
      link: seed_data.value?.link + '/harvests',
      count: seed_data.value?.item_stat?.cnt_harvests,
      icon: '',
    });
  }

  if(seed_data.value?.item_stat?.cnt_reviews){
    items.push({
      id: 'reviews',
      name: 'Reviews',
      link: seed_data.value?.link + '/reviews',
      count: seed_data.value?.item_stat?.cnt_reviews,
      icon: '',
    });
  }

  return items;
 
})
 
const follow = function(state) {  
  $follow.toggle('product', seed_data.value.id, state, {
    type: 'product',
    id: seed_data.value.id,
    avatar: seed_data.value.avatar_small, 
    name: seed_data.value.name, 
    link: seed_data.value.link, 
  })
  .then((response) => {    
    seed_data.value.addon.follow = state;
    if(seed_data.value.item_stat)
      seed_data.value.item_stat.cnt_followers += state ? 1 : -1;
  })
  .catch((error) => {
    $popup.error('Error')
  });
}
 


const loadData = async function(seed, brand) {     
  const response = await $api.getUrl('/ref/products/' + seed, {
    brandsection: brand, 
  })  
  return response;  
}


const { pending: is_loading,  data: seed_data } = await useLazyAsyncData('seed_data_' + link2.value, async () => await loadData(link2.value, link.value))


watch( () => seed_data, () => {
  useHead(useNuxtApp().$head.getSeed(seed_data.value));
}, {immediate: true, deep: true});

watch( () => router.currentRoute, () => {
  useHead(useNuxtApp().$head.getSeed(seed_data.value));
}, {immediate: true, deep: true});



onBeforeUnmount(() => {
  seed_data.value = null;
})

  
</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>

<template>

  <div v-if="parameters.length || countnutrients" class="setup-bx"
      @click="toggleSetup">
  <div class="setup">

    <!-- {{ props.setup }} -->


    <div 
      v-for="(s, ks) in parameters"
      class="setup-item">
        <div :class="s.icon" v-if="s.icon"></div>
        <div class="hinf">
          <div class="vl">
            {{ s.beforeValue }}
            {{ $convert.view(s.unit, s.measure, s.value) }}    
            {{ s.afterValue }} 
            <template v-if="s.unit && s.measure && $constants[s.unit] && $constants[s.unit][s.measure]">
              {{ $constants[s.unit][s.measure] ? $t($constants[s.unit][s.measure]) : '' }}
            </template>
          </div>
          <div class="nm">{{ s.hint }}</div>
        </div>
      </div>
    </div>

    <!-- <div class="dots">
      <i class="icon-dots-more"></i>
    </div> -->

  </div>

  <!-- <div class="setup" v-else @click="toggleSetup">
    <div 
      class="setup-item">
      Show Conditions
    </div>
  </div>

  <div class="setup" @click="toggleNutrients">
    <div 
      class="setup-item">
      Show Nutrients
    </div>
  </div> -->
  
</template>

<script setup>

import { inject } from 'vue';
  
const { t } = useI18n();
const { $constants, $convert } = useNuxtApp();

// const emits = defineEmits(['click.comment'])

const props = defineProps({
  setup: {
    type: Object,
    required: true
  },
  nutrients: {
    type: Array,
    required: false
  }
})


const actionsToggleNutrientsEvent = inject('actionsToggleNutrientsEvent');
const toggleNutrients = () => {
  actionsToggleNutrientsEvent({  });
};


const actionsToggleSetupEvent = inject('actionsToggleSetupEvent');
const toggleSetup = () => {
  actionsToggleSetupEvent({  });
};

// const actionsToggleNutrientsEvent = inject('actionsToggleNutrientsEvent');

// const toggleNutrients = () => {

//   actionsToggleNutrientsEvent({  });

// };


const countnutrients = computed(() => {
  return props.nutrients?.length;
});

const parameters = computed(() => {
  var list = [];

  // var week = {
  //   wiki: false,
  //   hint: props.faza == 0 ? t('universal_type_faza_veg') : t('universal_type_faza_flo'),
  //   value: props.days,
  //   beforeValue: t('conditions_week'),
  //   afterValue: null,
  //   ico: 'week',
  //   unit: null,
  //   measure: null,
  //   onChange: null
  // };
  // list.push(week);


  if(!props.setup) return [];

  if(props.setup.height){
    var height = {
      hint: t('conditions_height'),
      value: props.setup.height,
      // vl: $convert.universal('length', useAuth().getLength(), props.setup.height)
      beforeValue: null,
      afterValue: null,
      ico: 'height',
      icon: 'icon-height',
      id: 'height',
      unit: 'length',
      measure: useAuth().getLength(),
      onChange: useAuth().setLength
    };
    list.push(height);
  }

  if(props.setup.light){
    var light = {
      hint: t('conditions_light_schedule'),
      value: props.setup.light,
      beforeValue: null,
      afterValue: t('set_hrs'),
      ico: 'light',
      icon: 'icon-light-schedule',
      id: 'light',
      unit: null,
      measure: null,
      onChange: null
    };
    list.push(light);
  }

  if(props.setup.air_temp){
    var air_temp = {
      hint: t('conditions_day_air_temperature'),
      value: props.setup.air_temp,
      beforeValue: null,
      afterValue: null,
      ico: 'air_temp',
      icon: 'icon-air-temperature',
      id: 'air-temp',
      unit: 'temperature',
      measure: useAuth().getTemperature(),
      onChange: useAuth().setTemperature
    };
    list.push(air_temp);
  }

  if(props.setup.ph){
    var ph = {
      hint: t('conditions_ph'),
      value: props.setup.ph,
      beforeValue: null,
      afterValue: null,
      ico: 'ph',
      icon: 'icon-ph',
      id: 'ph',
      unit: null,
      measure: null,
      onChange: null
    };
    list.push(ph);
  }

  if(props.setup.smell){
    var smell = {
      hint: t('conditions_smell'),
      value: null,
      beforeValue: t($constants.postSmell[props.setup.smell]),
      afterValue: null,
      ico: 'smell',
      icon: 'icon-smell',
      id: 'smell',
      unit: null,
      measure: null,
      onChange: null
    };
    list.push(smell);
  }

  if(props.setup.ppm){
    var ppm = {
      hint: useAuth().getTds() == 'ppm' ? t('conditions_tds') : t('conditions_ec'),
      value: props.setup.ppm,
      beforeValue: null,
      afterValue: null,
      ico: 'ppm',
      id: 'ppm',
      unit: 'tds',
      measure: useAuth().getTds(),
      onChange: useAuth().setTds
    };
    list.push(ppm);
  }

  if(props.setup.air_hum){
    var air_hum = {
      hint: t('conditions_humidity'),
      value: props.setup.air_hum,
      beforeValue: null,
      afterValue: '%',
      ico: 'air_hum',
      icon: 'icon-air-humidity',
      id: 'air-hum',
      unit: null,
      measure: null,
      onChange: null
    };

    list.push(air_hum);
  }

  if(props.setup.solution_temp){
    var solution_temp = {
      hint: t('conditions_solution_temp'),
      value: props.setup.solution_temp,
      beforeValue: null,
      afterValue: null,
      ico: 'solution_temp',
      icon: 'icon-solution-temperature',
      id: 'solution-temp',
      unit: 'temperature',
      measure: useAuth().getTemperature(),
      onChange: useAuth().setTemperature
    };
    list.push(solution_temp);
  }

  if(props.setup.substrate_temp){
    var substrate_temp = {
      hint: t('conditions_substrate_temp'),
      value: props.setup.substrate_temp,
      beforeValue: null,
      afterValue: null,
      ico: 'substrate_temp',
      icon: 'icon-substrate-temperature',
      id: 'substrate-temp',
      unit: 'temperature',
      measure: useAuth().getTemperature(),
      onChange: useAuth().setTemperature
    };
    list.push(substrate_temp);
  }

  if(props.setup.night_air_temp){
    var night_air_temp = {
      hint: t('conditions_night_air_temp'),
      value: props.setup.night_air_temp,
      beforeValue: null,
      afterValue: null,
      ico: 'night_air_temp',
      icon: 'icon-night-air-temperature',
      id: 'night-air-temp',
      unit: 'temperature',
      measure: useAuth().getTemperature(),
      onChange: useAuth().setTemperature
    };
    list.push(night_air_temp);
  }

  if(props.setup.pot_size){
    var pot_size = {
      hint: t('conditions_pot_size'),
      value: props.setup.pot_size,
      beforeValue: null,
      afterValue: null,
      ico: 'pot_size',
      icon: 'icon-pot-size',
      id: 'pot-size',
      unit: 'volume',
      measure: useAuth().getVolume(),
      onChange: useAuth().setVolume
    };
    list.push(pot_size);
  }

  if(props.setup.watering_volume){
    var watering_volume = {
      hint: t('conditions_watering_volume'),
      value: props.setup.watering_volume,
      beforeValue: null,
      afterValue: null,
      icon: 'setup_ico setup_ico_watering_volume',
      id: 'watering-volume',
      unit: 'volume',
      measure: useAuth().getVolume(),
      onChange: useAuth().setVolume
    };
    list.push(watering_volume);
  }

  if(props.setup.lamp_distance){
    var lamp_distance = {
      hint: t('conditions_lamp_to_plant'),
      value: props.setup.lamp_distance,
      beforeValue: null,
      afterValue: null,
      icon: 'setup_ico setup_ico_lamp_distance', 
      id: 'lamp-distance',
      unit: 'length',
      measure: useAuth().getLength(),
      onChange: useAuth().setLength
    };
    list.push(lamp_distance);
  }

  if(props.setup.co2){
    var co2 = {
      hint: t('conditions_co2'),
      value: props.setup.co2,
      beforeValue: null,
      afterValue: 'PPM',
      icon: 'setup_ico setup_ico_co2',
      id: 'co2',
      unit: 'ppm',
      measure: null,
      onChange: null
    };
    list.push(co2);
  }






  if(props.setup.harvest_weight){
    list.push({
      tophint: t('harvest_view_parameters_whats_on_scales'),
      hint: t('harvest_view_parameters_bud_dry_weight'),
      value: props.setup.harvest_weight,
      beforeValue: null,
      afterValue: null,
      icon: 'setup_ico setup_ico_n_weight',
      id: 'n_weight',
      unit: 'weight',
      measure: useAuth().getWeight(),
      onChange: useAuth().setWeight
    });
  }

  if(props.setup.harvest_wet_weight){
    list.push({
      tophint: t('harvest_view_parameters_whats_on_scales'),
      hint: t('harvest_view_parameters_bud_wet_weight'),
      value: props.setup.harvest_wet_weight,
      beforeValue: null,
      afterValue: null,
      icon: 'setup_ico setup_ico_n_wet_weight',
      id: 'n_wet_weight',
      unit: 'weight',
      measure: useAuth().getWeight(),
      onChange: useAuth().setWeight
    });
  }

  if(props.setup.harvest_plant){
    list.push({
      tophint: t('harvest_view_parameters_harvested'),
      // hint: t('harvest_view_parameters_number_of_plants'), //too long
      hint: 'Plants',
      value: props.setup.harvest_plant,
      beforeValue: null,
      afterValue: null,
      icon: 'setup_ico setup_ico_n_plant',
      id: 'n_plant',
      unit: 'plant',
      measure: null,
      onChange: null
    });
  }

  if(props.setup.harvest_space){
    list.push({
      tophint: t('harvest_view_parameters_grow_room'),
      hint: t('harvest_view_parameters_grow_room_size'),
      value: props.setup.harvest_space,
      beforeValue: null,
      afterValue: null,
      icon: 'setup_ico setup_ico_n_space',
      id: 'n_space',
      unit: 'square',
      measure: useAuth().getLength(),
      onChange: useAuth().setLength
    });
  }








 

// if(props.setup.harvest_weight){
//   let w = $convert.universal('weight', useAuth().getWeight(), props.setup.harvest_weight);
//   let s = props.setup.harvest_watt;
//   if(w){
//     list.push({
//       tophint: null,
//       hint: 'Weight per Watt',
//       value: Math.round(w/s*100)/100,
//       beforeValue: null,
//       afterValue: t($constants.weight[useAuth().getWeight()]) + '/' + t('universal_unit_watt'),
//       icon: 'setup_ico setup_ico_weight_watt',
//       ico: null,
//       id: 'gw',
//       unit: null,
//     });
//   }
// }
 
// if(props.setup.harvest_weight && props.setup.harvest_space){
//   let w = $convert.universal('weight', useAuth().getWeight(), props.setup.harvest_weight);
//   let s = $convert.universal('square', useAuth().getLength(), props.setup.harvest_space);
//   if(w && s){
//     list.push({
//       tophint: null,
//       hint: 'Weight per Space',
//       value: Math.round(w/s*100)/100,
//       beforeValue: null,
//       afterValue: t($constants.weight[useAuth().getWeight()]) + '/' + t($constants.square[useAuth().getLength()]),
//       icon: 'setup_ico setup_ico_weight_space',
//       ico: null,
//       id: 'gm',
//       unit: null,
//     });
//   }
// }
 

// if(props.setup.harvest_weight && props.setup.harvest_plant){
//   let w = $convert.universal('weight', useAuth().getWeight(), props.setup.harvest_weight);
//   let s = props.setup.harvest_plant;
//   if(w){
//     list.push({
//       tophint: null,
//       hint: 'Weight per Plant',
//       value: Math.round(w/s*100)/100,
//       beforeValue: null,
//       afterValue: t($constants.weight[useAuth().getWeight()]) + '/' + t('universal_unit_plant'),
//       icon: 'setup_ico setup_ico_weight_plant',
//       ico: null,
//       id: 'gp',
//       unit: null,
//     });
//   }
// }
 

// if(props.setup.harvest_plant && props.setup.harvest_space){
//   let w = props.setup.harvest_plant;
//   let s = $convert.universal('square', useAuth().getLength(), props.setup.harvest_space);
//   if(s){
//     list.push({
//       tophint: null,
//       hint:  'Plant per Space',
//       value: Math.round(w/s*100)/100,
//       beforeValue: null,
//       afterValue: t('universal_unit_plant') + '/' + t($constants.square[useAuth().getLength()]),
//       icon: 'setup_ico setup_ico_plant_space',
//       ico: null,
//       id: 'ps',
//       unit: null,
//     });
//   }
// }
 

// if(props.setup.harvest_watt && props.setup.harvest_space){
//   let w = props.setup.harvest_watt;
//   let s = $convert.universal('square', useAuth().getLength(), props.setup.harvest_space);
//   if(s){
//     list.push({
//       tophint: null,
//       hint: 'Watt per Space',
//       value: Math.round(w/s*100)/100,
//       beforeValue: null,
//       afterValue: t('universal_unit_watt') + '/' + t($constants.square[useAuth().getLength()]),
//       icon: 'setup_ico setup_ico_watt_space',
//       ico: null,
//       id: 'ws',
//       unit: null,
//     });
//   }
// }






  return list;


});

</script>




<style scoped>
 .setup-bx{
  position: relative;
 }
 .week_comment{
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 10px 0;
}
.date{
  font-size: 0.8rem;
  color: #999;
}

/* flex, overflow-x */
.setup{
  display: flex;
  gap: 0.5rem 1rem;
  overflow-x: auto;
  margin-top: 10px;
  position: relative;
  flex-wrap: wrap;
}

.setup::-webkit-scrollbar {
  display: none;
}

.setup-item{
  /* padding: 6px 9px; */
  /* border: 1px var(--un-background-color-second) solid; */
  /* background-color: var(--un-background-color-gray); */
  /* border-radius: 5px; */
  flex-shrink: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  min-height: 40px;
}
.hinf{}
.hinf .vl{
  font-weight: bold;
  font-size: 1.2rem;
}
.hinf .nm{
  /* font-size: 0.8rem; */
  /* color: var(--un-text-color-gray); */
  line-height: 0.8rem;
}

.setup-item i{
  font-size: 1.2rem;
  vertical-align: middle;
}
/* .setup-item::after{
  content: '·';
  margin-left: 7px;
  margin-right: 5px;
  color: var(--un-text-color-gray);

} */

.setup-item:last-child::after{
  content: '';
}

.setup_ico{
  width: 40px;
  height: 40px;
  background-size: contain;
  aspect-ratio: 1/1;
}

.dots{
  padding: 6px 0;
    color: var(--un-text-color);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    background-color: var(--un-background-color-gray);
    width: 52px;
    height: 35px;
    text-align: center;
    margin-top: -2px;
    font-size: 2rem;
    line-height: 0.9rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@container pc (max-width: 600px) {

  .setup{
    gap: 0.5rem 0.5rem;
  }

  .setup-item{
    width: calc(50% - 0.5rem);
  }
 
}



</style>

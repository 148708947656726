<template>


  <div :class="{room: true, worked: worked}" v-if="props.room">
     
    <div class="title">

      <ChatClose
        @close="close"
        />
        
      <div class="with">Chat with</div>
      
      <NuxtLink 
        v-if="!props.room.is_group"
        :to="'/grower/' + props.room.name" 
        :class="{'name': true}" @click="close();emits('closeChat')">
        <img class="avatar" :src="props.room.avatar">
        <span>{{ props.room.name }}</span>
        <div v-if="props.room.is_online" class="online" title="online"></div>
      </NuxtLink>
      
      <div 
        v-else
        class="name">
        <span>{{ props.room.name }}</span>  
      </div>
      
      <!-- <div class="btn_close" @click="close"><i class="icon-remove"></i></div> -->
 
      <div class="options" v-if="useAuth().isAuth() && useAuth().isVerifiedEmail()">
                
        <div class="remove" @click="remove"><i class="icon-trash"></i> Remove chat</div>
        <template v-if="props.room.is_admin">
          <div v-if="props.room.is_group" class="manage" @click="$emit('manage')"><i class="icon-setting"></i> Manage group</div>
          <div v-if="props.room.is_group" class="manage" @click="$emit('invite')"><i class="icon-users"></i> Invite users</div>
        </template>
        <template v-else>
          <div class="warning" @click="complain"><i class="icon-warning"></i> Complain</div>
          <div class="ban" @click="ban"><i class="icon-ban"></i> Ignore</div>
        </template>
        
      </div>
    </div>


    <div class="messages" ref="el_messages">
      <div v-show="lastLoadedCount >= limit" class="preload" @click="preloadMessages">Load older messages</div>
      <div class="messages_items">


        <template
          v-for="(opt, opt_key) in messages"
          >

          <div :class="'message_item' + (useAuth().getId() == opt.user_id || opt.guest_id ? ' is_my ' : '')">

            <div :class="'avatar'">
              <img class="avatar_img" :src="opt.user_avatar" alt="">
            </div>

            <div class="text" v-html="opt.text"></div>
            
            <div class="bot">
              <NuxtLink :to="'/grower/' + opt.user_name" class="user_name">
                {{ opt.user_name }}                
              </NuxtLink>
              <div data-time="1573229865" class="time">
                {{ $dayjs.utc($dayjs.unix(opt.add_date)).fromNow() }}
              </div>
            </div>

          </div>
          
        </template>

    
        
      </div>
    </div>


    <UserNotVerificated 
        v-if="!allowedMessageSend"/>

    <div v-else class="form">
      <div class="smile_btn">


      <div class="emoji_popup ui button icon"><i class="icon-smile"></i></div>
      <div class="ui flowing popup top  transition hidden">
        <div class="ui one column divided center aligned grid">
          <div class="column">
            <p class="emoji_popup_icons" data-area-id="chat_message_unput" style="font-family: Segoe UI Emoji; font-size: 1.5em">
            
            </p>
          </div>
        </div>
      </div>


      </div>

 
        <div class="input">  
          <label for="chat_message_unput" class="hlabel">Message</label> 
          <textarea 
            :style="'height: ' + calc_height + 'px'"
            placeholder="Your message..." 
            v-model="message"
            @keydown.enter.exact.prevent="send"
            @keydown.enter.shift.exact.prevent="message += '\n'"
            ></textarea>

          <div class="calcheight" ref="el_calc_height">
            {{ message }}
          </div>
        </div>
        <div class="send_btn" @click="send">
          <div class="is_loading"></div>
          <i class="icon-message is_ready"></i>
        </div>
 

    </div>
  </div>
  
</template>


<script setup>

import { watch } from 'vue';

const { $dayjs, $api, $observer, $popup } = useNuxtApp();
const emits = defineEmits(['closeRoom', 'closeChat', 'complain', 'ban', 'remove'])
const props = defineProps({
  room: {
    type: Object
  },
  worked: {
    type: Boolean
  },
})
const el_messages = ref(null);
const el_calc_height = ref(null)
const calc_height = ref(40)

const limit = ref(20);
const opened = ref(false);
const message = ref('');
const messages = ref([]);
// const preload = ref(null);
const preloadFirst = ref(null);
const preloadLast = ref(null);
const lastLoadedCount = ref(0);

const close = function(){
  opened.value = false;
  clear();
  emits('closeRoom');
}

const allowedMessageSend = computed(() => {
  console.log('room');
  console.log(props.room);
  if(useAuth().isAuth()){
    if(!useAuth().isVerifiedEmail() && !props.room.allowed_guest){
      return false;
    }
  }else{
    if(!props.room.allowed_guest){
      return false;
    }
  }
  return true;
})
 
const autoHeightField = () => {

  if(import.meta.server) 
    calc_height.value = 40;

  
  let height = el_calc_height.value.offsetHeight;


  if(height < 40 || message.value == '')
    calc_height.value = 40;
  else if(height > 100)
    calc_height.value = 100;
  else
    calc_height.value = height + 0;


  console.log('height: '  + height);
  
}

watch(() => message, (newId, oldId) => {
  console.log('message: ' + message.value)
  setTimeout(() => {
    autoHeightField();
  }, 100);
}, { deep: true })


const time = function(vl){
  var today = new Date(vl*1000);
  var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
  var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + ' ' + time;
  return $dayjs.utc(dateTime).fromNow();
}


const save = function(){
  $api.postChatRoomMessage(props.room.id, message.value, props.room.address)
  .then((res) => {                               
    console.log(res);
  })
  .catch((err) => {
    console.log(err);
  });
}

const send = function(){

  if(!allowedMessageSend.value){
    $popup.error('You need to verify your email');
    return false;
  }




  if(!message.value) return false;

  messages.value.push({
    id: 0,
    add_date: Date.now().toString().substr(0, 10),
    text: message.value,
    user_id: useAuth().getId(),
    guest_id: 0,
    user_name: useAuth().getName(),
    user_status: useAuth().getStatus(),
    user_visit: Date.now(),
    user_avatar: useAuth().getAvatarLittle(),
    is_my: 1,
    is_new: 1
  }); 

  save();

  message.value = ''; 
  scrollToEnd();       

}





const clear = function(){
  messages.value = [];
  preloadFirst.value = null;
  preloadLast.value = null;
}

const complain = function(){       
  $api.postChatRoomCompain(props.room.id).then((res) => {                               
    clear();
    emits('complain');
    $ga.eventGA('user_complain');
  });     
}

const ban = function(){       
  $api.postChatRoomBan(props.room.id).then((res) => {                               
    clear();
    emits('ban');
  });     
}

const remove = function(){      
  $api.deleteChatRoom(props.room.id).then((res) => {                               
    clear();
    emits('remove');
  });            
}

const scrollToEnd = function(){       
  console.log('scrolled');
  console.log(el_messages.value);
  if(import.meta.client)
    setTimeout(() =>{
      el_messages.value.scrollTop = el_messages.value.scrollHeight; 
    }, 100)
}

const fillFirstLast = function(){
  if(messages.value.length == 0) return false;
  preloadFirst.value = messages.value[messages.value.length - 1].id;
  preloadLast.value = messages.value[0].id;
}

const loadChatMessages = async function(){
  if(!props.room) return false;
  var data = await $api.getChatRoomMessages(props.room.id, preloadFirst.value, preloadLast.value, 0, props.room.address)
  lastLoadedCount.value = data?.length ?? 0;
  messages.value.push(...data);   
  scrollToEnd();        
  fillFirstLast();  
}


const preloadMessages = async function(){
  if(!props.room) 
    return false;
  var data = await $api.getChatRoomMessages(props.room.id, preloadFirst.value, preloadLast.value, 1, props.room.address)
  lastLoadedCount.value = data?.length ?? 0;
  // messages.value.push(...data);   
  // prepend data 
  messages.value.unshift(...data);
  fillFirstLast();
}


watch(() => props.room.id, (newId, oldId) => {
  clear();
  loadChatMessages();
  scrollToEnd();
}, { deep: true })

// watchArray([props.room.id], async ([new_room], added, removed) => {
//   console.log(props.room);
//   loadChatMessages();
//   scrollToEnd();
// }, {deep: true})

clear();
loadChatMessages();
scrollToEnd();

 


// onMounted(() => {
//   if(import.meta.client){
//     $observer(preload.value, function(){
//       console.log('unobserve');
//       preloadMessages();
//     });
//   }
// })

onBeforeUnmount(() => {
  clear();


})

</script>
 


<style scoped>
  

/* ROOM */
.room {
  display: inline-block;
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
 

.room>.title>.options>.warning,
.room>.title>.options>.remove,
.room>.title>.options>.leave,
.room>.title>.options>.manage,
.room>.title>.options>.ban {
  font-size: 0.75rem;
  color: gray;
  cursor: pointer;
  display: inline-block;
  margin: 5px 10px 0px 10px;
}

.room>.title>.options>.manage:hover {
  color: var(--un-text-color);
}

.room>.title>.options>.warning:hover .room>.title>.options>.leave:hover,
.room>.title>.options>.ban:hover {
  color: red;
}

.room>.title>.options>.remove:hover {
  color: var(--un-firm);
}


.room>.title {
  text-align: center;
  padding: 15px 20px;
  font-size: 1.3rem;
  position: relative;
  flex-shrink: 0;
}

.room>.title .with {
  display: block;
  vertical-align: middle;
  margin-right: 10px;
}

.room>.title .avatar {
  width: 25px;
  border-radius: 30px;
  vertical-align: middle;
  margin-right: 10px;
} 
.room>.title .name {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin-left: 10px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.room>.title .name span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.room>.title .btn_close {
  display: inline-block;
  position: absolute;
  right: 6px;
  top: 15px;
  cursor: pointer;
  font-size: 0.8rem;
}

/* FORM */
.room>.form {
  width: 100%;
  min-height: 50px;
  /* position: absolute; */
  bottom: 0px;
  pointer-events: none;
  opacity: 0.5;
  display: flex;
  align-items: flex-end;
}

.message_sidebar.worked>.room>.form {
  pointer-events: all;
  opacity: 1;
  flex-shrink: 0;
}

.room>.form .smile_btn {
  display: inline-block;
  width: 46px;
  text-align: center;
  font-size: 1.4rem;
  padding: 5px 0px 4px 0px;
  vertical-align: middle;
  cursor: pointer;
}

.room>.form .emoji_popup {
  background-color: transparent;
  display: inline-block;
  /* width: 47px; */
  /* height: 47px; */
  padding: 0px;
  min-height: 43px;
  position: relative;
  margin-bottom: 0px !important;
  border: 0px;
  margin-top: 0px !important;
  line-height: 38px !important;
  vertical-align: bottom;
  font-size: 0.85re
}

.room>.form .emoji_popup .icon.smile {}

.room>.form .smile_btn .ui.popup {
  width: 100% !important;
}

.room>.form .send_btn {
  display: inline-block;
  width: 46px;
  text-align: center;
  font-size: 1.4rem;
  padding: 5px 0px 12px 0px;
  vertical-align: middle;
  cursor: pointer;
  position: relative;
}

.room>.form .send_btn .is_loading {
  display: none;
  position: absolute;
  content: '';
  top: -8px;
  left: 50%;
  margin: -0.64285714em 0em 0em -0.64285714em;
  width: 1.48571429em;
  height: 1.48571429em;
  border-radius: 500rem;
  background-image: url(/images/preloader.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 1;
}

.room>.form .send_btn .is_ready {
  display: inline-block;
}

.room>.form .send_btn.loading .is_loading {
  display: inline-block;
}

.room>.form .send_btn.loading .is_ready {
  display: none;
}

.room>.form .input {
  display: inline-block;
  width: calc(100% - 104px);
  position: relative;
}

.room>.form .input textarea {
  padding: 10px;
  border: 0px;
  width: 100%;
  height: 41px;
  resize: none;
    max-height: 150px;
} 
/* MESSAGES */
.room>.messages {
  padding: 20px;
  overflow-y: auto;
  height: 100%;
}

.room>.messages .preload,
.room>.messages .user_typing {
  text-align: center;
  color: var(--gd-link-hover-color);
  margin-bottom: 1rem;
  cursor: pointer;
}

.room>.messages .message_item {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.room>.messages .avatar {
  display: inline-block;
  vertical-align: top;
  margin-right: 16px;
}

.room>.messages .avatar.same {
  opacity: 0;
}
.room>.messages .avatar_img {
  width: 40px;
  border-radius: 30px;
}

.room>.messages .text {
  display: inline-block;
  background-color: var(--un-background-color-gray);
  padding: 10px 15px;
  border-radius: 5px;
  position: relative;
  max-width: calc(100% - 90px);
  word-wrap: break-word;
}

.room>.messages .text:before {
  content: "\A";
  border-style: solid;
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--un-background-color-gray) transparent transparent;
  position: absolute;
  left: -10px;
  top: 11px;
}

.room>.messages .message_item .user_name {
  display: inline-block;
  min-width: 40px;
  text-align: center;
  color: var(--un-text-color);
}

.room>.messages .message_item .user_name:hover {
  color: black;
}

.room>.messages .message_item .time {
  display: inline-block;
  margin-right: 13px;
  margin-left: 13px;
  color: transparent;
}

.room>.messages .message_item:hover .time {
  color: #a0a0a0;
}

.room>.messages .message_item .bot {
  text-align: left;
  font-size: 0.75rem;
  color: var(--un-text-color);
    width: 100%;
    display: flex;
}

.room>.messages .message_item.is_my .bot {
  text-align: right;
    flex-direction: row-reverse;
}

.room>.messages .message_item.is_my {
  text-align: right;
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.room>.messages .message_item.is_my .avatar {
  margin-right: 0px;
}

.room>.messages .message_item.is_my .avatar_img {}

.room>.messages .message_item.is_my .text {
  margin-right: 10px;
  word-wrap: break-word;
    margin-left: auto;
    white-space: pre-wrap;
}

.room>.messages .message_item .text.is_new {
  background-color: #edf9e6;
  color: #494949;
}

.room>.messages .message_item.is_my .text:before {
  /* right: -10px; */
  /* border-width: 10px 0px 10px 20px; */
  /* border-color: transparent rgb(247, 247, 247) transparent transparent; */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent var(--un-background-color-gray);
  left: 100%;
}
  

.calcheight{
  width: 100%;
  height: fit-content;
  visibility: hidden;  
  position: absolute;
  white-space: pre-wrap;
  word-wrap: break-word;
  top: 0;
  left: -9999px;
    font-size: 16px!important;
    font-family: monospace;
    padding: 10px;
    line-height: 1.2142em;
}


@media (min-width: 768px) {
  .room.worked {
    height: calc(100% - 75px);
    width: 49%;
    position: absolute;
    top: 75px;
    right: 0;
  }

  .room {
    display: none;
  }

  .room.worked {
    display: flex;
  }

}

@media (max-width: 768px) {
  
  .room>.form .smile_btn {
    display: none !important;
  }

  .room>.form .input {
    width: calc(100% - 64px);
    margin-left: 10px;
  }
}
</style>
<template>
  <div class="navbar" v-click-outside.prevent="outsideToggleNavbar">
    <div class="group">
      <NuxtLink :class="{'lnk': true, 'active': isActive('/')}" to="/" @click.stop="closeNavbar">
        <i class="icon-home"/>
        <span class="nm">{{ $t('main_menu_home') }}</span>
      </NuxtLink>

      <NuxtLink :class="{'lnk': true, 'active': isActive('/explore') || isActive('/grow-journals')}" to="/explore" @click.stop="closeNavbar">
        <i class="icon-search"/>
        <span class="nm">{{ $t('main_menu_diaries') }}</span>
      </NuxtLink>

      <NuxtLink :class="{'lnk': true, 'active': isActive('/shorts')}" to="/shorts" @click.stop="closeNavbar">
        <i class="icon-shorts"/>
        <span class="nm">{{ $t('menu_title_shorts') }}</span>
      </NuxtLink>

      <NuxtLink :class="{'lnk': true, 'active': isActive('/grow-questions')}" to="/grow-questions" @click.stop="closeNavbar">
        <i class="icon-question"/>
        <span class="nm">{{ $t('main_menu_questions') }}</span>
      </NuxtLink>

      <NuxtLink :class="{'lnk': true, 'active': isActive('/giveaways')}" to="/giveaways" @click.stop="closeNavbar">
        <i class="icon-cup"/>
        <span class="nm">{{ $t('main_menu_giveaways') }}</span>
      </NuxtLink>

      <NuxtLink :class="{'lnk': true, 'active': isActive('/growers')}" to="/growers" @click.stop="closeNavbar">
        <i class="icon-grower"/>
        <span class="nm">{{ $t('main_menu_growers') }}</span>
      </NuxtLink>

      <NuxtLink :class="{'lnk': true, 'active': isActive('/strains')}" to="/strains" @click.stop="closeNavbar">
        <i class="icon-leaf"/>
        <span class="nm">{{ $t('menu_title_strains') }}</span>
      </NuxtLink>

      <NuxtLink v-if="useAuth().getAccess('view_extra')" :class="{'lnk': true, 'new-label': true, 'active': isActive('/strains2')}" to="/strains2" @click.stop="closeNavbar">
        <i class="icon-leaf"/>
        <span class="nm">{{ $t('menu_title_strains') }}</span>
      </NuxtLink>

      <NuxtLink :class="{'lnk': true, 'active': isActive('/seedbank')}" to="/seedbank" @click.stop="closeNavbar">
        <i class="icon-gen"/>
        <span class="nm">{{ $t('main_menu_breeders') }}</span>
      </NuxtLink>

      <NuxtLink :class="{'lnk': true, 'active': isActive('/nutrients')}" to="/nutrients" @click.stop="closeNavbar">
        <i class="icon-nutrient"/>
        <span class="nm">{{ $t('main_menu_nutrients') }}</span>
      </NuxtLink>

      <NuxtLink :class="{'lnk': true, 'active': isActive('/grow-lights')}" to="/grow-lights" @click.stop="closeNavbar">
        <i class="icon-lamp"/>
        <span class="nm">{{ $t('main_menu_lamps') }}</span>
      </NuxtLink>

      <NuxtLink :class="{'lnk': true, 'new-label': true, 'active': ['/equipment', '/grow-tents', '/grow-boxes', '/ventilation-fans', '/air-filters', '/air-conditioners', '/drip-systems', '/hydroponic-systems', '/controllers', '/co2-generators'].some(isActive)}" to="/equipment" @click.stop="closeNavbar">
        <i class="icon-equip-tractor  "/>
        <span class="nm">{{ $t('equipment_view_title') }}</span>
      </NuxtLink>

      <NuxtLink :class="{'lnk': true, 'active': isActive('/journal')}" to="/journal" @click.stop="closeNavbar">
        <i class="icon-blog"/>
        <span class="nm">{{ $t('main_menu_blog') }}</span>
      </NuxtLink>

      <NuxtLink class="lnk" to="/brands-manager" @click.stop="closeNavbar" v-if="useAuth().isB2B()">
        <i class="icon-portfolio"/>
        <span class="nm">{{ $t('main_menu_brand_manager') }}</span>
      </NuxtLink>

 
      <NuxtLink v-if="useAuth().getAccess('view_extra')" :class="{'lnk': true, 'active': isActive('/st')}" to="/st" @click.stop="closeNavbar">
        <i class="icon-wizard"/>
        <span class="nm">Experiments</span>
      </NuxtLink>

      <div class="lang lnk" @click="toggleNavbarLang(); isShowLocale = !isShowLocale">
        <img class="icon_lang" :src="flagLocale" :alt="getLocaleName(locale)" data-not-lazy>
        <span class="nm">{{ getLocaleName(locale) }}</span>
        <i class="icon-caret-up-down"/>
      </div>

      <template
        v-for="l in locales"
        :key="l"
      >
        <div
          v-show="isShowLocale"
          v-if="((l.code != locale && l.code !== 'en-tr') || (l.code === 'en-tr' && useAuth().getAccess('view_translation_keys')))"
          class="lang sub"
          @click.prevent.stop="setLocale(l.code); dayjs.locale(l.code); closeNavbar; isShowLocale = false;"
        >
          <img loading="lazy" class="icon_lang" :src="'/images/locales/' + l.code + '_2x.png'" :alt="l.name">
          <span class="nm">{{ l.name }}</span>
        </div>
      </template>

      <div class="navbar_cab">
        <template v-if="!useAuth().isAuth()">
          <NuxtLink :class="{'lnk': true, 'active': isActive('/auth/signin')}" to="/auth/signin" @click.stop="closeNavbar">
            <i class="icon-lock"/>
            <span class="nm">{{ $t('main_menu_login') }}</span>
          </NuxtLink>

          <NuxtLink :class="{'lnk': true, 'active': isActive('/auth/signup')}" to="/auth/signup" @click.stop="closeNavbar">
            <i class="icon-diary"/>
            <span class="nm">{{ $t('main_menu_start_diary') }}</span>
          </NuxtLink>

          <NuxtLink :class="{'lnk': true, 'active': isActive('/auth/signup')}" to="/auth/signup" @click.stop="closeNavbar">
            <i class="icon-plus"/>
            <span class="nm">{{ $t('main_menu_signup') }}</span>
          </NuxtLink>
      </template>
      <template v-else>
        <NuxtLink
          v-if="useAuth().isAuth() && useAuth().getLink()"
            class="lnk user"
            @click.stop="closeNavbar"
            :title="useAuth().getName()"
            :to="useAuth().getLink()">
            <img loading="lazy" class="img" :src="useAuth().getAvatarLittle()" alt="">
            <span class="nm">{{ useAuth().getName() }}</span>
          </NuxtLink>

          <NuxtLink class="lnk" :class="{'active': isActive('/notifications')}" to="/notifications" @click.stop="closeNavbar">
            <i class="icon-alarm"/>
            <span class="nm">{{ $t('main_menu_notifications') }}</span>
            <div v-if="useUserUpdatesNotifications().value" class="label-new">
              {{ useUserUpdatesNotifications().value }}
            </div>
          </NuxtLink>

          <NuxtLink class="lnk" :class="{'active': isActive('/diaries/edit')}" to="/diaries/edit/new" @click.stop="closeNavbar">
            <i class="icon-plus"/>
            <span class="nm">{{ $t('main_menu_start_diary') }}</span>
          </NuxtLink>
        </template>
      </div>
    </div>

    <Following
      v-if="useAuth().isAuth() && navbarOpened"
      @click:link="closeNavbar"
    />

    <template v-if="!useAuth().isAuth()">
      <FollowingNoAuth v-show="navbarOpened"/>
    </template>

    <Footer
      v-if="navbarOpened"
      @click:link="closeNavbar"
    />
  </div>
</template>

<script setup lang="ts">

const { hooks } = useNuxtApp()
const $route = useRouter()
const dayjs = useDayjs()
const { locale, locales, setLocale, t } = useI18n()
const navbarOpened = ref(useNavbarOpened());
const isShowLocale = ref(false);
const flagLocale = computed(() => '/images/locales/' + locale.value + '_2x.png' );

const getLocaleName = function(vl) {
  var sel = locales.value.filter(i => i.code == vl);
  return sel[0]?.name;
}
const outsideToggleNavbar = function() {
  if(window.outerWidth < 768)
    navbarOpened.value = false;
}
const closeNavbar = (isLink: boolean) => {
  if (import.meta.client && window.outerWidth < 768) {
    navbarOpened.value = false;
  }

  if (isLink) clickLink('feed')
}

const toggleNavbarLang = async function(){
  if (!navbarOpened.value) {
    navbarOpened.value = true;
    if(!useFollowCommon().followCommon){
      await useFollowCommon().load()
      await useFollowBookmark().load()
    }
  }
}


const isActive2 = function(vl) {
  if(vl == '/') return $route.currentRoute.value.path == vl;
  return $route.currentRoute.value.path.indexOf(vl) == 0;
}

const isActive = function(vl) {
  if (vl === '/') return $route.currentRoute.value.path === vl;
  const path = $route.currentRoute.value.path;
  const pattern = new RegExp(`^${vl}(\\/|$)`);
  return pattern.test(path);
}

const clickLink = (link) => {
  hooks.callHook('nuxtlink:click:' + link);
}
</script>

<style scoped>
.navbar {
  position: fixed;
  top: 52px;
  height: calc(100% - 52px);
  width: 235px;
  left: 0;
  background-color: var(--gd-navbar-back-color);
  z-index: 100;
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 76px;
  flex-direction: column;
}
[data-os="windows"] .navbar::-webkit-scrollbar {
  width: 4px;
}

.navbar .group{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.navbar_cab{
  display: none;
}
.navbar .group .lnk{
  color: var(--gd-link-color);
  padding: 9px 12px;
  margin: 0 10px;
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}
.navbar .group .lnk.active i{
  color: var(--gd-navbar-active-icon-color);
}
.navbar .group .lnk.active .nm{
  font-weight: bold;
}
.navbar .group .lnk.active,
.navbar .group .lnk:hover{
  background-color: var(--gd-navbar-back-hover-color);
}

.navbar .group .lnk i{
  font-size: 1.23rem;
  width: 22px;
  height: 22px;
  text-align: left;
  color: var(--gd-navbar-icon-color);
  margin-right: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar .group .lnk .img{
  width: 22px;
  height: 22px;
  border-radius: 50%;
  text-align: left;
  color: var(--gd-link-color);
  margin-right: 9px;
}
.navbar .group .lang i{
  margin-left: auto;
}
.navbar .group .lang.sub{
  margin: 0 20px 0 30px;
  display: flex;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;
}

.navbar .group .lang .icon_lang{
  width: 17px;
  margin-left: 3px;
  text-align: left;
  margin-right: 13px;
}
.offnav .navbar .group .lang .icon_lang{
  margin-right: 2px;
}
.new-label{
  position: relative;

}
.new-label::after{
  content: 'NEW';
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 0.4rem;
  display: block;
  position: absolute;
  bottom: 4px;
  color: #e31111;
  padding: 0 2px;
  border-radius: 2px;
  line-height: 0.6rem;
  font-style: normal;
}
.onnav .new-label::after{
  right: 10px;
  bottom: 14px;
  font-size: 0.5rem;
  padding: 1px 3px;
}

.offnav .navbar .group .lang i{
  display: none;
}

@media (min-width: 768px) {
  .offnav .navbar{
    width: 65px;
  }
  .offnav .navbar .group .lnk{
    text-align: center;
    margin: 0;
    padding: 9px 10px;
    justify-content: center;
  }

  .offnav .navbar .group .lnk i{
    margin: 0;
    text-align: center;
  }
  .offnav .navbar .group .lnk .nm{
    display: none;
  }
  .offnav .navbar .group .lang {
    margin: 0 13px;
  }
  .offnav .navbar .group .lang .nm {
    display: none;
  }
  .offnav .navbar .group .lang.sub {
    display: none;
  }
}

@media (max-width: 768px) {
  .offnav .navbar{
    display: none;
  }
  .navbar_cab{
    display: block;
  }
}


/* warning top */

.warn ~ .navbar{
  transition: padding-top 0.2s ease-in-out;
}

.bd:not(.scrolled) .warn ~ .navbar{
  padding-top: 30px;
}
@media (max-width: 768px) {

  .bd:not(.scrolled) .warn ~ .navbar{
    padding-top: 50px;
  }
}

.label-new{
  top: 5px;
  right: 0;
  background-color: var(--un-firm);
  color: white;
  font-size: 0.7rem;
  padding: 1px 5px;
  border-radius: 15px;
  min-width: 15px;
  font-weight: bold;
  margin-left: 0.5rem;
  line-height: 1rem;
  margin-right: 5px;
  pointer-events: none;
}
</style>
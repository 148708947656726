<template>
  <div class="faq-item">
    <div class="faq-title" @click="toggleExpanded">
      <h2>{{ name }}</h2>
      <span class="arrow">
        <i v-if="isOpen" class="icon-arrow-top"></i>
        <i v-else class="icon-arrow-bottom"></i>
      </span>
    </div>

    <div v-if="isOpen" class="cnt" v-html="text">
    </div>
  </div>
</template>

<script setup lang="ts">

interface FaqProps {
  name: string
  text: string
  isExpanded: boolean
}

const props = withDefaults(defineProps<FaqProps>(), {
  isExpanded: false
})

// Управляемое состояние развёрнуто/свёрнуто
const isOpen = ref(props.isExpanded)

// Следим за внешним изменением пропса
watch(
  () => props.isExpanded,
  (newVal) => {
    isOpen.value = newVal
  }
)

// Метод переключения
function toggleExpanded() {
  isOpen.value = !isOpen.value
}
</script>

<style scoped>
.faq-item {
  border: 1px solid var(--un-element-color-gray);
  margin-bottom: 1rem;
  border-radius: 4px;
  overflow: hidden;
}

.faq-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--un-background-color-gray-light);
  cursor: pointer;
  padding: 0.75rem 1rem;
}

.faq-title h3 {
  margin: 0;
  font-size: 1rem;
}

.arrow i{
  color: var(--un-firm);
}

.cnt {
  padding: 0.75rem 1rem;
}

.arrow {
  font-size: 0.8rem;
  margin-left: 1rem;
}
.cnt:deep(p){
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.cnt:deep(a){
  color: var(--un-firm);
  text-decoration: underline;
}


</style>
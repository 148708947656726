<template>
  
  <ClientOnly>
    <Teleport to="#app">

      <div v-if="props.photos" class="lb">
        <div class="scrl" ref="scrl">

          <div 
            v-for="(photo, index) in props.photos"
            :ref="el => { if (el) setPhotoRef(el, photo.id) }"
            :class="{'lc': true, 'zoom': zoomed}"
            >

            <iframe loading="lazy"
              v-if="photo.type == 'iframe'"
              class="iframe"
              :src="photo.iframe">
            </iframe>
            
            <img loading="lazy" 
              v-else
              class="image"
              :style="calcStyle(photo)"
              :src="zoomed ? photo.base : photo.zoom" 
            >

            <div class="gloading"></div>

          </div> 

        </div>


        <div class="close-btn" @click="closeLightbox">
          <i class="icon-close"></i>
        </div>

        <div class="bglb" 
          @click="closeLightbox"
          ></div>

      </div>
    </Teleport>
  </ClientOnly>
  
</template>

<script setup lang="ts">

import type { PhotoGallery } from '@/types/photo';

interface Props {
  photoId: number | null;
  photos: Array<PhotoGallery>;
}
const props = defineProps<Props>();

const emits = defineEmits(['close']);

var zoomed = ref(false);
var current = ref(props.photoId);
const photoRefs = ref<HTMLElement[]>([])
const scrl = ref<HTMLElement | null>(null);


const setPhotoRef = (el: HTMLElement, id: number) => {
  photoRefs.value[id] = el;
}
 
const calcStyle = computed(() => {
  return (photo: PhotoGallery) => {
    if(!photo.width || !photo.height){
      return {};
    }
    return {
      'aspect-ratio': `${photo.width}/${photo.height}`,
    }
  }
});

const closeLightbox = () => {
  emits('close');
};

const scrollToPhoto = () => {
 
  if(current.value){

    console.log(photoRefs.value);
    console.log(current.value);
    console.log(photoRefs.value[current.value]);

    if(!photoRefs.value[current.value]?.getBoundingClientRect){
      return;
    }

    const elementRect = photoRefs.value[current.value].getBoundingClientRect();
    const elementTop = elementRect.top;

    if(!scrl.value){
      return;
    }

    scrl.value.scrollTo({
      top: elementTop - 50, 
    });
  }

};
 

 


onMounted(() => {
  nextTick(() => {
    scrollToPhoto();
  });
});

onUnmounted(() => {

});
 
 
</script>

<style scoped>
.lb {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}
.lb .scrl {
  overflow-y: auto;
  max-height: calc(100vh - 0px);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  z-index: 1;
  width: 100%;
  overscroll-behavior: contain;
}
.bglb {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgb(0 0 0 / 92%);
}
.lc {
  position: relative;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
    height: 90vh; 
    min-height: 90vh;
}
.lc .gloading::after{
  display: none;
}
.lc img {
  /* max-height: 80vh;
  max-width: 80vw; */
  object-fit: cover;
  /* transition: transform 0.2s ease-in-out; */
  cursor: pointer;
  transform: translateX(var(--translateX));
  user-drag: none;  
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: 11;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  font-size: 1.1rem;
  z-index: 1;
}

.info{
  position: absolute;
  bottom: 10px;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  font-size: 1.1rem;
  width: 100%;
  max-width: 580px;
  font-size: 0.8rem;
  z-index: 1;
}
.info .cur{

}
.info .caption{

}
/* zoomed */

.lc.zoom{
  height: 100vh;
  width: 100vw;
  max-width: none;
  max-height: none;
  overflow: hidden;
}
.lc.zoom content {
  cursor: grab;
  object-fit: contain;
}
.lc.zoom .info{
  display: none;
}

/* iframe */

.lc iframe{

  z-index: 11;
border: 0;

height: 100%;
    width: 100%;


}

/* toggler */

.toggler {
  position: absolute;
  z-index: 11111;
  top: calc(50% - 30px);
  font-size: 2rem;
  color: white;
  background-color: #0000005c;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  cursor: pointer;
}
.toggler.left {
  left: 20px;
}
.toggler.right {
  right: 20px;
}
.toggler i{
  margin-top: 4px;
}


@media (max-width: 768px) {
  .lc {
    min-height: auto;
    height: auto;
    width: 100%;
  }
  .lc img {
   
  }
  .lc iframe {
   aspect-ratio: 1/1;
  }
}


</style>

<template>
  
  <ClientOnly>
    <Teleport to="#app">


      <!-- v-touch:release="endDrag" -->
      <div v-if="videos" class="lb">
        <div class="scrl" ref="scrl">

          <div 
            v-for="(video, video_index) in videos"
            :ref="el => { if (el) setVideoContainerRef(el, video) }"
            :class="{'lc': true, 'is-playing': video.source.playing, 'is-current': currentVideo.id == video.id}"
            >

            <div class="video_box">
              <div class="play-state">
                <i v-if="!video.source.playing" 
                  class="icon-play" 
                  @click="togglePlayerPlay(video, video_index)"></i>
              </div>

              <div class="video_box_touch" @click="togglePlayerPlay(video, video_index)">
              </div>
                <video 
                  class="vjs-tech" 
                  :poster="video.cover"
                  playsinline="playsinline"                  
                  :ref="el => { if (el) setVideoRef(el, video) }"
                  >
                  <source :src="video.video" type="video/mp4">      
                </video>

              <div class="player-progress">
                <div class="player-progress-current" :style="'width: ' + video.source.progress + '%'"></div>
              </div>

              <div class="player-duration" v-if="video.source.duration">
                {{ video.source.duration }}
              </div>

              <div :class="{'info': true, 'active': video.source.info}">
                <!-- {{ video.source }} -->
                <div class="user">
                  <img class="ava" :src="video.info.avatar">
                  <div class="source">
                    <div><NuxtLink class="link" :to="video.info.source_link">{{ video.info.source_name }}</NuxtLink></div>
                    <div>By <NuxtLink class="link" :to="video.info.link">@{{ video.info.name }}</NuxtLink></div>
                  </div>
                </div>
                <div class="caption" @click="toggleInfo(video)">
                  {{ video.info.text }}
                </div>
              </div>

            </div>
             

          </div>

        </div>


        <div class="close-btn" @click="closeLightbox">
          <i class="icon-close"></i>
        </div>

        <div class="bglb" 
          @click="closeLightbox"
          ></div>

      </div>
    </Teleport>
  </ClientOnly>
</template>

<script setup>



const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  list: {
    type: Array,
    default: [],
    required: false
  }
});

const emits = defineEmits(['close']);
const { $noscroll, $observer } = useNuxtApp();

const playerPlay = ref(false);
const playerCurrentProgress = ref(0);
const currentVideo = ref(props.item);
const playerCurrentProgressInterval = ref({});
const videoContainerRefs = ref([])
const videos = ref([])
const videoRefs = ref([])
const scrl = ref(null);
const isStopObserver = ref(false);
const currentObservedVideo = ref(null);

const setVideoContainerRef = (el, video) => {
  videoContainerRefs.value[video.id] = el;

  $observer(el, (element) => {
    // console.log(el);
    if(isStopObserver.value) 
      return;


    if(currentObservedVideo.value?.id != video.id)
      currentObservedVideo.value = video;
    
    if(currentVideo.value.id != video.id){
      isStopObserver.value = true;
      stopPlayerVideo(currentVideo.value);

      // scrl.value.scrollTo({
      //   top: scrl.value.scrollTop,
      //   behavior: 'instant'
      // });

      // scrollToVideo(videoContainerRefs.value[video.id]);

      setTimeout(() => {
        playNewVideo(video);

        // scrollToVideo(videoContainerRefs.value[video.id]);

        isStopObserver.value = false;
      }, 1000);
    }
  }, (element) => {
    stopPlayerVideo(video);
  }, 0.2);
 
}

const setVideoRef = (el, video) => {
  videoRefs.value[video.id] = el;

}

const scrollToVideo = (el) => {
   
  //  const elementRect = el.getBoundingClientRect();
  //  const elementTop = elementRect.top;
  //  const scrollTop = scrl.value.scrollTop;
   
   console.log(el);
  //  console.log(elementTop);
  //  console.log(scrollTop);

  let offsetTop = el.offsetTop;


  let parent = el.offsetParent;
  while (parent && parent !== scrl.value) {
    offsetTop += parent.offsetTop;
    parent = parent.offsetParent;
  }

  offsetTop -= 10;


  scrl.value.scrollTo({
    top: offsetTop,
    behavior: 'smooth'
  });
 }
 

const togglePlayerProgressInterval = (state, video) => {
  if(state){
    playerCurrentProgressInterval.value[video.id] = setInterval(() => {
      setPlayerCurrentProgress(video);
    }, 50);
  }else{
    clearInterval(playerCurrentProgressInterval.value[video.id]);
  }
}
  
const stopPlayerVideo = (video) => {
  if(!video.source.playing)
    return false;
  console.log('video stop')
  console.log(video)
  videoRefs.value[video.id].pause();
  videoRefs.value[video.id].currentTime = 0;
  video.source.playing = false;
  togglePlayerProgressInterval(false, video);
}

const playPlayerVideo = (video) => {
  videoRefs.value[video.id].play();
  videoRefs.value[video.id].volume = 0.6;
  video.source.playing = true;
  togglePlayerProgressInterval(true, video);
}

const playNewVideo = (video) => {
  stopPlayerVideo(currentVideo.value)
  currentVideo.value = video;
  playPlayerVideo(video);
}

const togglePlayerPlay = (video, video_index) => {

  // console.log('video');
  // console.log(video);
 
  playerPlay.value = !playerPlay.value;
  if(playerPlay.value){
    stopPlayerVideo(currentVideo.value)
  }else{
    playPlayerVideo(currentVideo.value)
  }

};
 
const formatSeconds = (seconds) => {
  return Math.round(seconds) + 's';
}

const setPlayerCurrentProgress = (video) => {
  var elVideo = videoRefs.value[video.id];
  var progress = (elVideo.currentTime / elVideo.duration) * 100;
  video.source.progress = progress;
  video.source.duration = formatSeconds(elVideo.duration - elVideo.currentTime);

  if(video.source.progress >= 100){
    nextVideo();
  }

  // console.log('progress');
  // console.log(progress);
}

const toggleInfo = (video) => {
  console.log('toggleInfo');
  console.log(video);
  video.source.info = !video.source.info;
}

const nextVideo = (pos) => {
  console.log('nextVideo');
  console.log(currentVideo.value);

  var currentInd = videos.value.findIndex(v => v.id == currentVideo.value.id);
  var nextVideo = videos.value.find((v, ind) => {
    if(pos == 'prev'){
      return ind == currentInd - 1
    }else{
      return ind == currentInd + 1
    }
  });
  
  if(!nextVideo){
    return;
  }
  
  stopPlayerVideo(currentVideo.value)

  console.log('next', nextVideo);

  isStopObserver.value = false;

  scrollToVideo(videoContainerRefs.value[nextVideo.id]);

  setTimeout(() => {
    playPlayerVideo(nextVideo);
    isStopObserver.value = false;
  }, 1000);
};
 

const closeLightbox = () => {
  stopPlayerVideo(currentVideo.value)
  emits('close');
};
 

const topKeyListener = function(e) {
  if(e.key == 'ArrowUp'){
    nextVideo('prev');
  }
}

const bottomKeyListener = function(e) {
  if(e.key == 'ArrowDown'){
    nextVideo('next');
  }
}

const escapeKeyListener = function(e) {
  if(e.key == 'Escape'){
    closeLightbox()
  }
}
 

// const scrollendDetectSafari = () => {
//   var prevScrollpos = scrl.value.scrollTop;
//   var scrollendTimer = null;
//   scrl.value.addEventListener('scroll', function() {
//     var currentScrollPos = scrl.value.scrollTop;

//     clearTimeout(scrollendTimer);

//     scrollendTimer = setTimeout(() => {
//       clearTimeout(scrollendTimer);
//       scrollendTimer = null;
//       if (prevScrollpos == currentScrollPos) {
//         console.log('scrollend');
//         scrolled();
//       }
//     }, 400);

//     prevScrollpos = currentScrollPos;
//   });
// }

 
// const touchend = () => {
//   // stop scrolling 
//   // var currentScrollPos = scrl.value.scrollTop;

//   console.log('touchend!!!!');

//   scrolled();

//   // blockScroll.value = true;

//   // touchMoveEvent.value.preventDefault();

//   // if(e.cancelable){
//     // e.preventDefault();
//     // e.stopPropagation();
//   // }
//   // scrl.value.scrollTo({
//   //   top: currentScrollPos,
//   // });

// }

// const scrolled = () => {

//   if(currentVideo.value.id == currentObservedVideo.value.id){
//     scrollToVideo(videoContainerRefs.value[currentObservedVideo.value.id]);
//     return;
//   }


//   isStopObserver.value = true;

//   stopPlayerVideo(currentVideo.value);
//   scrollToVideo(videoContainerRefs.value[currentObservedVideo.value.id]);
//   setTimeout(() => {
//     playNewVideo(currentObservedVideo.value);

//     isStopObserver.value = false;
//   }, 1000);
// }
 
 

onMounted(() => {


  // createScrollEndEvent();
  

  $noscroll.on();

  nextTick(() => {

    var items = [...[props.item], ...props.list.filter(i => i.id != props.item.id)];
    items.map((i, ind) => {
      i.source = {}
      i.source.playing = false;
      i.source.duration = 0;
      i.source.progress = 0;
      i.source.info = false;
    })

    videos.value = items;

    nextTick(() => {

      playPlayerVideo(videos.value[0]);
    });

    // scrollendDetectSafari();
    
  });

  

});

onUnmounted(() => {
  $noscroll.off();
});

 

onMounted(() => {
  if(import.meta.client){
    document.addEventListener('keydown', escapeKeyListener);
    document.addEventListener('keydown', topKeyListener);
    document.addEventListener('keydown', bottomKeyListener);
  }
})

onUnmounted(() => {
  if(import.meta.client){
    stopPlayerVideo(currentVideo.value);
    document.removeEventListener('keydown', escapeKeyListener);
    document.removeEventListener('keydown', topKeyListener);
    document.removeEventListener('keydown', bottomKeyListener);
  }
})


// useIntersectionObserver(
//   elInfinityScroll,
//   ([{isIntersecting}]) => {
//     if(isIntersecting){
//       console.log('infinity scroll event');        
//       if(!is_loading.value && !isEnded.value)
//         start.value = start.value + limit.value;
//     }
//   },
//   { distance: 10 }
// )
 
 
</script>

<style scoped>
.lb {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
}
.lb .scrl {
  overflow-y: auto;
  max-height: calc(100vh - 0px);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  gap: 1rem;
  scroll-snap-type: y mandatory;
  z-index: 1;
  overscroll-behavior: contain;
}
.lb .scrl::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.bglb {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
}
.lc {
  position: relative;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh; 
  max-height: calc(100vh - 20px);
  margin-top: 10px;
  margin-bottom: 10px;
  scroll-snap-stop: always;
  scroll-snap-align: center;
}

.lc img {
  /* max-height: 80vh;
  max-width: 80vw; */
  object-fit: cover;
  /* transition: transform 0.2s ease-in-out; */
  cursor: pointer;
  transform: translateX(var(--translateX));
  user-drag: none;  
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  z-index: 11;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.video_box{
  aspect-ratio: 9/16;
  height: calc(100dvh - 20px);
  margin: 0 auto;
  position: relative;
}
.video_box_touch{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video_box .play-state{
  left: 10px;
  top: 10px;
  width: 40px;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.video_box .play-state i{

}

.video_box video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  cursor: pointer;
  pointer-events: none;
}

.is-playing video{
  object-fit: contain;
}


.player-progress{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 2;
}

.player-progress-current{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 3px;
  background-color: var(--un-firm);
}

.player-duration{
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
  font-size: 0.8rem;
}


.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  font-size: 1.1rem;
  z-index: 1;
}

.info{
  position: absolute;
  bottom: 0px;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  font-size: 1.1rem;
  width: 100%;
  max-width: 580px;
  font-size: 0.8rem;
  z-index: 1;
}
.info .user{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  z-index: 1;
  position: relative;
}
.info .ava{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  aspect-ratio: 1/1;
}
.info .link{
  font-weight: bold;
  color: white;
}
.info .caption{
  color: rgb(197, 197, 197);
  overflow-y: hidden;
  max-height: 40px;
  position: relative;
  z-index: 1;
}

.info::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
  pointer-events: none;
}


.info.active{
  height: fit-content;
}

.info.active .caption{
  overflow-y: inherit;
  max-height: inherit;
}

@media (max-width: 768px) {
  .lc {
    min-height: auto;
    height: auto;
    width: 100%;
  }
  .lc img {
   
  } 

  .video_box{
    width: 90dvw;
  }
}


</style>

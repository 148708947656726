<template>
  
  <div class="sform">

    <UiTextArea  
      :placeholder="$t('week_view_question_placeholder')"       
      :text="vl"
      @change:text="vl = $event"
      />
      
    <UserNotVerificated 
      v-if="useAuth().isAuth() && !useAuth().isVerifiedEmail()"/>

    <UiButton
      :name="$t('universal_button_send')"
      :type="'primary'"
      :disabled="useAuth().isAuth() && !useAuth().isVerifiedEmail()"
      @click="save"
      :is-loading="isLoading"
      />        

      
     
  </div>
  
</template>


<script setup lang="ts">

import type { Question, QuestionSolution } from '@/types/question';

const emits = defineEmits(['created']);
const { $api, $popup } = useNuxtApp()

interface Props {
  questionId: Number;
  solution?: QuestionSolution;
}

const props = defineProps<Props>();

const max = ref(280);
const current = ref(0);
const vl = ref('');
const isLoading = ref(false);

const save = async () => {
  isLoading.value = true;

  try {
    let res: any;
    if (!props.solution?.id) {
      res = await $api.postQuestionSolution(props.questionId, { text: vl.value });
    } else {
      res = await $api.patchQuestionSolution(props.questionId, props.solution.id, { text: vl.value });
    }
    isLoading.value = false;
    $popup.success('Saved');
    emits('created', res.data);
  } catch (error: any) {
    isLoading.value = false;
    $popup.error(error);
  }
}

</script>


<style scoped>
 
.sform {
  margin-left: 60px;
  margin-top: 20px;
}

</style>

<template>

  <div class="diary" v-if="props.list?.length">

    <h2 class="divider-sec">
      {{ props.title }} 
      <nuxt-link class="h2_show_all" :to="props.link">
        {{ props.showmore }} 
        <!-- {{ props.cnt ? '(' + props.cnt + ')' : '' }} -->
      </nuxt-link>
    </h2>

    <!-- <div class="report_boxs one-row reports_grid" v-if="list">      
      <GeneralDiary 
        v-for="(opt,ind) in props.list"
        :key="ind"  
        :data="opt"      
        >       
      </GeneralDiary>    
    </div> -->

    <UiHorizontalScroll
      :arrows="true"
      :arrows-show-hover="true"
      v-if="list"
      >
      <div class="report_boxs horizontal_all data">      
        <GeneralDiary 
          v-for="(opt,ind) in props.list"
          :key="ind"  
          :data="opt"      
          >       
        </GeneralDiary>    
      </div>
    </UiHorizontalScroll>
  </div>

</template>

<script setup>

const props = defineProps({
  title: {
    type: String,
    default: 'Title'
  },
  cnt: {
    type: Number,
    default: 0
  },
  showmore: {
    type: String,
    default: 'Show more'
  },
  limit: {
    type: Number,
    default: 4
  },
  list: {
    type: Array,
    default: () => []
  },
  link: {
    type: String,
    default: '/diary'
  }
});

</script>

<style scoped>
 
 
 

.proslist {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.proslist.centered {
  justify-content: center;
}


</style>

import type { User } from './User.interface';

export function createUser(): User {
  return {
    id: null,
    name: null,
    link: null,
    status: null,
    avatar: null,
    avatar_small: null,
    avatar_little: null,
  }
}

export default defineNuxtPlugin((nuxtApp) => {

  if (!nuxtApp.$i18n) {
    return
  }

  const i18n = nuxtApp.$i18n

  const currentLocale = i18n.locale.value || i18n.locale

  const currentMessages = i18n.getLocaleMessage(currentLocale)

  const originalT = i18n.t

  i18n.t = (key: string, params?: any) => {
    if (!key) {
      return ''
    }

    if (currentMessages[key] && typeof currentMessages[key] === 'string') {
      return currentMessages[key]
    }

    const result = originalT(key, params, currentLocale)

    if (result === currentLocale) {
      return key
    }

    return typeof result === 'string' ? result : ''

  }
})
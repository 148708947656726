<template>
  <div class="sb">
    <div class="namerw" @click="toggleSearchBox">
      <img class="nut_lg" data-not-lazy src="/images/new_row.svg">

      <div class="nut_info">
        <div class="nut_nm">{{ $t('create_week_select_nutrient') }}</div>
        <div class="nut_brand">{{ $t('create_week_click_to_add') }}</div>
      </div>
    </div>

    <GeneralSelectBoxModal
      v-if="showed"
      :showed="showed"
      :cat="ProductCategoryType.Nutrient"
      :input-placeholder="$t('create_week_select_nutrient')"
      :title-modal="$t('create_week_select_nutrient')"
      @choose="chooseItem"
      @prechoose="prechooseItem"
      @close="showed = false"
      @customize="showedCustom = true"
    />

    <GeneralSelectBoxModalCustom
      v-if="showedCustom"
      :cat="'nutrient'"
      :type="'brand'"
      :input-placeholder="'Custom Brand Name'"
      :title-modal="'Add Brand'"
      :showed="showedCustom"
      :prechoosed="prechoosed"
      @choose="chooseItem"
      @close="showedCustom = false"
    />

      
  </div>
</template>

<script setup lang="ts">

import { ProductCategoryType }  from "@/types/product";

const emits = defineEmits(['choose'])
const id = ref(null)
const brand_id = ref(null)
const name = ref(null)
const avatar = ref(null)
const brand_name = ref(null)
const type = ref(1)
const count = ref(1)
const showed = ref(false)
const showedCustom = ref(false)
const prechoosed = ref(null)

const clear = () => {
  id.value = null
  brand_id.value = null
  name.value = null
  brand_name.value = null
  count.value = 1
  type.value = 1
}
const chooseEvent = () => {
  const item = {
    ref_id: null,
    id: id.value,
    name: name.value,
    type: type.value,
    power: power.value,
    item_brand: {
      avatar: avatar.value,
      id: brand_id.value,
      name: brand_name.value,
    }
  }

  emits('choose', item)
  clear()
}

const prechooseItem = (data: any) => {
  prechoosed.value = data
}

const chooseItem = (data) => {
  emits('choose', data)
  showed.value = false
  showedCustom.value = false
  clear()
}
const changeSeedType = (vl) => {
  type.value = vl;
}
const counter = (vl) => {
  if (count.value + vl > 0) count.value += vl;
}
const toggleSearchBox = () => {
  showed.value = !showed.value;
}
const hideSearchBox = () => {
  showed.value = false;
  showedCustom.value = false;
}
</script>

<style scoped>
.sb{
  position: relative;
  width: 100%;
}
.namerw{
  display: flex;
  align-items: center;
}
.nut_lg{
  width: 60px;
  margin-right: 10px;
  flex-shrink: 0;
  border-radius: 5px;
  height: 35px;
}
.nut_info{}
.nut_nm{

  font-weight: bold;
}


</style>
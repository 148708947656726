<template>

  <div class="effects" v-if="positive?.length || negative?.length || medical?.length">

    <div class="divider">      
      {{ $t('harvest_view_feels_like') }}      
    </div>

    <div class="indica">
 

      <svg viewBox="0 0 36 36" class="pie-chart">

        <circle cx="18" cy="18" r="14" 
          fill="transparent" 
          stroke="#6ebf3c" 
          stroke-width="8" 
          :stroke-dasharray="(indica) + ' 100'" 
          transform="rotate(-90 18 18)"></circle>

        <circle cx="18" cy="18" r="14" 
          fill="transparent" 
          stroke="#ffc100" 
          stroke-width="8" 
          :stroke-dasharray="(100 - indica) + ' 100'" 
          :transform="'rotate(' + ((indica / 100) * 360 - 90) + ' 18 18)'"></circle>
      </svg>


      <!-- <figure 
        :style="'background: radial-gradient(circle closest-side,transparent 100%,white 0), conic-gradient(#6ebf3c 0,#6ebf3c '+indica+'%,#ffc100 0,#ffc100 100%);'"
        class="pie-chart">       
      </figure> -->

      <div class="opts">        
        <span class="pc sat">{{100-indica}}%</span>
        {{ $t('harvest_view_effect_sativa') }}
        <span class="pc ind">{{indica}}%</span>
        {{ $t('harvest_view_effect_indica') }}
      </div>

    </div>


    <div class="effall">

      <div class="efflist" v-if="positive?.length">
        
        <h3>
          {{ $t('harvest_view_effect_have_you_noticed') }}
        </h3>
    
        <div class="lst">
            
          <template v-if="positive">
            <div         
              v-for="(opt, opt_key) in positive"
              :key="opt_key"
              class="effect">
              <img class="img" :src="'/images/effect/' + $constants.effectPositive[opt.id] + '.jpg'">
              <div class="nm">
                {{ opt.name }}
              </div>
            </div>
          </template> 

        </div>
        
      </div>


      <div class="efflist" v-if="negative?.length">
        
        <h3>
          {{ $t('harvest_view_effect_changes_negative') }}
        </h3>
    
        <div class="lst"> 
            
          <template v-if="negative">
            <div       
              v-for="(opt, opt_key) in negative"
              :key="opt_key"
              class="effect">
              <img class="img" :src="'/images/effect/' + $constants.effectNegative[opt.id] + '.jpg'">
              <div class="nm">
                {{ opt.name }}
              </div>
            </div>
          </template> 

        </div>
        
      </div>


      <div class="efflist" v-if="medical?.length">
        
        <h3>
          {{ $t('harvest_view_effect_changes_medical') }}
        </h3>
    
        <div class="lst"> 
            
          <template v-if="medical">
            <div         
              v-for="(opt, opt_key) in medical"
              :key="opt_key"
              class="effect">
              <img class="img" :src="'/images/effect/medical/' + $constants.effectMedical[opt.id] + '.jpg'">
              <div class="nm">
                {{ opt.name }}
              </div>
            </div>
          </template>

        </div>
        
      </div>

    </div>


 
 
  </div>

  
</template>

<script>
 
 

export default {   
  // components: {
  
  // },
  props: [
    'positive',
    'negative',
    'medical',
    'indica',
  ],
  data () {
    return {     
     
    }
  },
  created() {    
    
  },
  methods: {
    convName(name){
      var ret = name.toLowerCase();
      ret = ret.replace(' ', '_');
      return ret;
    }
    
  }
}
</script>

<style scoped>
 
.lst{
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.effect{
display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: 64px;
  text-align: center;
}
  
 
.effect .img{
  width: 100%;
  height: fit-content;
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.effect .nm{
  margin-top: 10px;
}
.efflist{

    width: 33%;
    min-width: 300px;
}
.effall{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
  
.creative{ background-image: url(/images/effect/creative.jpg); }
.dry_eyes{ background-image: url(/images/effect/dry_eyes.jpg); }
.dry_mouth{ background-image: url(/images/effect/dry_mouth.jpg); }
.energetic{ background-image: url(/images/effect/energetic.jpg); }
.euphoric{ background-image: url(/images/effect/euphoric.jpg); }
.giggly{ background-image: url(/images/effect/giggly.jpg); }
.happy{ background-image: url(/images/effect/happy.jpg); }
.help_anxiety{ background-image: url(/images/effect/help_anxiety.jpg); }
.help_depression{ background-image: url(/images/effect/help_depression.jpg); }
.help_insomnia{ background-image: url(/images/effect/help_insomnia.jpg); }
.help_pain{ background-image: url(/images/effect/help_pain.jpg); }
.help_stress{ background-image: url(/images/effect/help_stress.jpg); }
.hungry{ background-image: url(/images/effect/hungry.jpg); }
.insomnia{ background-image: url(/images/effect/insomnia.jpg); }
.paranoia{ background-image: url(/images/effect/paranoia.jpg); }
.relaxed{ background-image: url(/images/effect/relaxed.jpg); }
.sleepy{ background-image: url(/images/effect/sleepy.jpg); }
.talkative{ background-image: url(/images/effect/talkative.jpg); }
.uplifted{ background-image: url(/images/effect/uplifted.jpg); }

h3{
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* SATIVA-IND */

.indica{
  display: flex;
    flex-direction: column;
    align-items: center;
}
.opts{
  font-weight: bold;
  margin-top: 10px;
}
.opts .pc{
  color: white;
  padding: 4px 5px;
  border-radius: 5px;
}
.opts .pc.sat{
      background-color: #edc948;
}
.opts .pc.ind{
  background-color: #6ebf3c;
  margin-left: 11px;
}
.pie-chart {
  
  position: relative;
  width: 100px;
  min-height: 100px;
  margin: 0; 
}   

</style>

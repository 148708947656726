import type { Brand } from './Brand.interface'

export function createBrand(): Brand {
  return {
    id: null,
    name: null,
    section: null,
    link: null,
    logo_s: null,
    logo_m: null,
    logo_l: null,
  }
}

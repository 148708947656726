<template>
  <div>  

    
    <div class="divider">{{ $t('create_week_view_navigation_title') }}</div>
    
    <DiaryEditProgress
      :steps="progress_steps"
      :states="progress_states"
      />

    <div class="divider" ref="elWeekCondition">{{ $t('create_week_view_conditions_title') }}</div>

    <div class="parameters">

      <WeekEditParamWeek 
        :faza="edited_week.faza"
        :num="edited_week.days"     
        :weeks="props.diary.items_week"   
        :is-presaved="edited_week.is_presaved"
        @update:faza="changeFaza"
        @update:num="edited_week.days = $event"
        /> 

      <WeekEditParamNumber        
        :num="edited_week.props.height"
        :num-min="0"
        :num-max="500"
        :unit="'length'"
        :ico="'setup_ico_height'"
        :param="$t('conditions_height')"
        :measure="useAuth().getLength()"
        :units="$constants.units.length"  
        :is-presaved="edited_week.is_presaved"  
        @update:num="edited_week.props.height = $event"
        @change_measure="useAuth().setLength($event)"
        />
    
      <WeekEditParamNumberSlider       
        :num="edited_week.props.light"
        :num-min="0"
        :num-max="24"
        :ico="'setup_ico_light'"
        :param="$t('conditions_light_schedule')"
        :units="[{id: 'hrs', name: 'hrs'}]"
        :is-presaved="edited_week.is_presaved"
        @update:num="edited_week.props.light = $event"
        />

      <WeekEditParamNumber       
        :num="edited_week.props.air_temp"
        :num-min="0"
        :num-max="useAuth().getTemperature() == 'c' ? 50 : 122"
        :unit="'temperature'"
        :ico="'setup_ico_air_temp'"
        :param="$t('conditions_day_air_temperature')"
        :measure="useAuth().getTemperature()"
        :units="$constants.units.temperature"
        :is-presaved="edited_week.is_presaved"
        @update:num="edited_week.props.air_temp = $event"
        @change_measure="useAuth().setTemperature($event)"
        />

      <WeekEditParamNumber       
        :num="edited_week.props.ph"
        :num-min="0"
        :num-max="14"
        :unit="'ph'"
        :ico="'setup_ico_ph'"
        :param="$t('conditions_ph')"
        :units="[{id: '-', name: '-'}]"
        :is-presaved="edited_week.is_presaved"
        @update:num="edited_week.props.ph = $event"
        />

      <WeekEditParamNumber       
        :num="edited_week.props.ppm"
        :num-min="0"
        :num-max="useAuth().getTds() == 'ppm' ? 2500 : 5"
        :unit="'tds'"
        :ico="'setup_ico_ppm'"
        :param="$t('conditions_tds')"
        :measure="useAuth().getTds()"
        :units="$constants.units.tds"
        :is-presaved="edited_week.is_presaved"
        @update:num="edited_week.props.ppm = $event"
        @change_measure="useAuth().setTds($event)"
        />

      <WeekEditParamNumber       
        :num="edited_week.props.air_hum"
        :num-min="0"
        :num-max="100"
        :unit="'hum'"
        :ico="'setup_ico_air_hum'"
        :param="$t('conditions_humidity')"
        :units="[{id: 'pc', name: '%'}]"
        :is-presaved="edited_week.is_presaved"
        @update:num="edited_week.props.air_hum = $event"
        />

      <WeekEditParamSmell       
        :num="edited_week.props.smell"
        :ico="'setup_ico_smell'"
        :param="$t('conditions_smell')"
        :is-presaved="edited_week.is_presaved"
        @update:num="edited_week.props.smell = $event"
        />
  

      <WeekEditParamNumber       
        :num="edited_week.props.solution_temp"
        :num-min="0"
        :num-max="useAuth().getTemperature() == 'c' ? 50 : 122"
        :unit="'temperature'"
        :ico="'setup_ico_solution_temp'"
        :param="$t('conditions_solution_temp')"
        :measure="useAuth().getTemperature()"
        :units="$constants.units.temperature"
        :is-presaved="edited_week.is_presaved"
        @update:num="edited_week.props.solution_temp = $event"
        @change_measure="useAuth().setTemperature($event)"
        />

      <WeekEditParamNumber       
        :num="edited_week.props.substrate_temp"
        :num-min="0"
        :num-max="useAuth().getTemperature() == 'c' ? 50 : 122"
        :unit="'temperature'"
        :ico="'setup_ico_substrate_temp'"
        :param="$t('conditions_substrate_temp')"
        :measure="useAuth().getTemperature()"
        :units="$constants.units.temperature"
        :is-presaved="edited_week.is_presaved"
        @update:num="edited_week.props.substrate_temp = $event"
        @change_measure="useAuth().setTemperature($event)"
        />



      <WeekEditParamNumber       
        :num="edited_week.props.night_air_temp"
        :num-min="0"
        :num-max="useAuth().getTemperature() == 'c' ? 50 : 122"
        :unit="'temperature'"
        :ico="'setup_ico_night_air_temp'"
        :param="$t('conditions_night_air_temp')"
        :measure="useAuth().getTemperature()"
        :units="$constants.units.temperature"
        :is-presaved="edited_week.is_presaved"
        @update:num="edited_week.props.night_air_temp = $event"
        @change_measure="useAuth().setTemperature($event)"
        />


      <WeekEditParamNumber       
        :num="edited_week.props.pot_size"
        :num-min="0"
        :num-max="300"
        :unit="'volume'"
        :ico="'setup_ico_pot_size'"
        :param="$t('conditions_pot_size')"
        :measure="useAuth().getVolume()"
        :units="$constants.units.volume"
        :is-presaved="edited_week.is_presaved"
        @update:num="edited_week.props.pot_size = $event"
        @change_measure="useAuth().setVolume($event)"
        />


      <WeekEditParamNumber       
        :num="edited_week.props.lamp_distance"
        :num-min="0"
        :num-max="200"
        :unit="'length'"
        :ico="'setup_ico_lamp_distance'"
        :param="$t('conditions_lamp_to_plant')"
        :measure="useAuth().getLength()"
        :units="$constants.units.length"
        :is-presaved="edited_week.is_presaved"
        @update:num="edited_week.props.lamp_distance = $event"
        @change_measure="useAuth().setLength($event)"
        />


      <WeekEditParamNumber       
        :num="edited_week.props.co2"
        :num-min="0"
        :num-max="2500"
        :unit="'ppm'"
        :ico="'setup_ico_co2'"
        :param="$t('conditions_co2')"
        :units="[{id: 'ppm', name: 'ppm'}]"    
        :is-presaved="edited_week.is_presaved"  
        @update:num="edited_week.props.co2 = $event"
        />

      <WeekEditParamNumber       
        :num="edited_week.props.watering_volume"
        :num-min="0"
        :num-max="30"
        :unit="'volume'"
        :ico="'setup_ico_watering_volume'"
        :param="$t('conditions_watering_volume')"
        :measure="useAuth().getVolume()"
        :units="$constants.units.volume"
        :is-presaved="edited_week.is_presaved"
        @update:num="edited_week.props.watering_volume = $event"
        @change_measure="useAuth().setVolume($event)"
        />





    </div>


    <div class="divider" ref="elWeekPhotos">{{ $t('photo_video_manager_external_title') }}</div>    

    <WeekEditWarningPhotos/>

    <div class="udr_bx">
      <UiUploader
        :enable-drag-drop="!edited_week.items_photo?.length"
        @changed="changePhotos" 
        @uploaded="uploadedPhotos" 
        @progress="uploadingPhotos" 
        @errored="erroredPhotos"
        />
    </div>
    


 
    <draggable 
      v-model="edited_week.items_photo" 
      item-key="id" 
      v-if="edited_week.items_photo" 
      @start="drag=true"  
      @end="drag=false" 
      class="photos"
      delay-on-touch-only="250"
      delay="250"
      animation="200"
        ghost-class="photos_ghost"
      >

        <template #item="{element}">
          <WeekEditPhoto
            :item="element"
            v-show="element?.is_remove != 1"
            @remove="removePhoto(element.id)"
            @update:item="element = $event"
            @rotate="saveRotate"
            />
        </template>


    </draggable>
     

    <!-- <VueDraggable 
        v-if="edited_week.items_photo" 
        @start="drag=true"  
        @end="drag=false" 
        delay-on-touch-only="250"
        delay="250"
        animation="200"
        class="photos"
        ghost-class="photos_ghost"
        v-model="edited_week.items_photo" 
        item-key="id" 
        > 

          <WeekEditPhoto
            v-for="(element,element_key) in edited_week.items_photo"
            :item="element"
            v-show="element?.is_remove != 1"
            @remove="removePhoto(element.id, element.uploading_id)"
            @update:item="element = $event"
            @rotate="saveRotate"
            />


      </VueDraggable> -->

    <div class="divider" ref="elWeekNutrients">{{ $t('create_week_view_nutrients_title') }}</div>    

    <div class="nutrients">

      <WeekEditNutrient
        v-if="edited_week.item_flowering.items_nutrient"        
        v-for="(opt,opt_key) in edited_week.item_flowering.items_nutrient"
        :key="opt_key"
        :nutrient="opt"
        :val="opt.val"
        :default_measure="useAuth().getNutrient()"        
        :measure="opt.unit"   
        :is-presaved="edited_week.is_presaved"     
        @remove="removeNutrient(opt_key)"        
        @choose="changeUniversal(opt, $event)"
        @update:val="opt.val = $event" 
        @update:measure="opt.unit = $event" 
        />

      <WeekEditNutrientSelectBox    
        @choose="chooseNutrient"
        />

    </div>

    <div class="divider">{{ $t('universal_grow_techniques_title') }}</div>    
 
    <WeekEditSelectTechniqueRadioBox
      @remove="removeTechniques"
      @add="addTechniques"
      :selected="edited_week.item_flowering.items_method"
      /> 
    
  


    <div class="divider" ref="elWeekComment">{{ $t('create_week_view_owner_comment_title') }}</div>    

    <UiTextArea 
      :text="edited_week.text"
      @change:text="edited_week.text = $event"
      />


    <UiButtons>
      <UiButton type="primary" :is-loading="props.isLoadingSave" :name="$t('universal_button_save')" @click="save"/>      
      <UiButton :name="$t('universal_button_cancel')" @click="cancel"/>              
    </UiButtons>


  </div>
</template>


 

<script setup>
import draggable from 'vuedraggable'

const route = useRoute()
const router = useRouter()
const { $progressBarContent, $api, $popup } = useNuxtApp()
const { t } = useI18n()
const emits = defineEmits(['update:faza', 'canceleditweek', 'saveweek'])
const elWeekCondition = ref(null)
const elWeekPhotos = ref(null)
const elWeekNutrients = ref(null)
const elWeekComment = ref(null)

const props = defineProps({
  week: {
    type: Object,
    required: true,
  },
  diary: {
    type: Object,
    required: true,
  },
  isLoadingSave: {
    type: Boolean,
    required: false,
    default: false,
  },
})

var link = ref(route.params['week'])
var edited_week = ref(props.week)
var progress_steps = ref([
  {
    id: 'conditions',
    name: t('create_week_view_navigation_conditions'),
    hintGood: t('create_week_view_navigation_good_to_grow'),
    hintNormal: t('create_week_view_navigation_add_some_more'),
    hintBad: t('create_week_view_navigation_whats_conditions'),
    el: elWeekCondition,
  },
  {
    id: 'photos',
    name: t('create_week_view_navigation_photos_videos'),
    hintGood: t('create_week_view_navigation_now_talking'),
    hintNormal: t('create_week_view_navigation_more_photos_videos'),
    hintBad: t('create_week_view_navigation_some_photos_videos'),
    el: elWeekPhotos,
  }, 
  {
    id: 'nutrients',
    name: t('create_week_view_navigation_nutrients'),
    hintGood: t('create_week_view_navigation_you_rock'),
    hintNormal: t('create_week_view_navigation_feeding_them'),
    hintBad: t('create_week_view_navigation_feeding_them'),
    el: elWeekNutrients,
  },
  {
    id: 'comment',
    name: t('create_week_view_navigation_comment'),
    hintGood: t('create_week_view_navigation_cool_info'),
    hintNormal: t('create_week_view_navigation_wanna_tell'),
    hintBad: t('create_week_view_navigation_share_ideas'),
    el: elWeekComment,
  },
])

const progress_states = computed(() => {
  // console.log('$progressBarContent');
  // console.log($progressBarContent.calcWeekVeg);

  var ret = $progressBarContent.calcWeekFlo(edited_week.value);

  return ret;
})

const addTechniques = function(method_id, custom){  

  if(edited_week.value.item_flowering.items_method == undefined && !edited_week.value.item_flowering.items_method){
    edited_week.value.item_flowering.items_method = [];
  }

  if(method_id < 0){
    edited_week.value.item_flowering.items_method.push({
      id: false,
      method_id:method_id,
      custom: custom,
      is_remove: 0,
    });
  }else{
    edited_week.value.item_flowering.items_method.push({
      id: false,
      method_id:method_id,
      is_remove: 0,
    });
  }
}

const removeTechniques = function(method_id, key){  
  console.log('removeTechniques')
  console.log(method_id, key)
  if(method_id < 0){
    edited_week.value.item_flowering.items_method[key].is_remove = 1;
  }else{
    edited_week.value.item_flowering.items_method.forEach((opt, opt_key) => {
      if(opt.method_id == method_id){
        edited_week.value.item_flowering.items_method[opt_key].is_remove = 1
      }
    });
  } 
}


const changePhotos = function(uploading_id, thumbnail){
  edited_week.value.items_photo = edited_week.value.items_photo || [];
  edited_week.value.items_photo.push({is_loading: true, uploading_id: uploading_id, thumbnail: thumbnail});
}

const findReplaceUploadingPhoto = function(uploading_id, item){
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.uploading_id == uploading_id){
      edited_week.value.items_photo[opt_key] = item; 
    }
  });
}

const uploadedPhotos = function(item, uploading_id){      
  edited_week.value.items_photo = edited_week.value.items_photo || [];
  // edited_week.value.items_photo.push(item);   
  findReplaceUploadingPhoto(uploading_id, item);
}

const findReplaceUploadingPhotoProgress = function(uploading_id, progress){
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.uploading_id == uploading_id){
      edited_week.value.items_photo[opt_key]['progress'] = progress; 
    }
  });
}

const uploadingPhotos = function(progress, uploading_id){      
  edited_week.value.items_photo = edited_week.value.items_photo || [];
  findReplaceUploadingPhotoProgress(uploading_id, progress);
}

const erroredPhotos = function(uploading_id){      
  $popup.error('Error uploading file');
  removePhoto(null, uploading_id);
}

const removePhoto = function(id, uploading_id){            
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.id && opt.id == id){
      edited_week.value.items_photo[opt_key].is_remove = 1; 
    }else if(opt.uploading_id && opt.uploading_id == uploading_id) {
      edited_week.value.items_photo[opt_key].is_remove = 1; 
    }
  });
}

const chooseNutrient = function(item){  
  item.showProduct = true    
  edited_week.value.item_flowering.items_nutrient = edited_week.value.item_flowering.items_nutrient || [];
  edited_week.value.item_flowering.items_nutrient.push(item);      
}

const changeFaza = function(vl){     
  emits('update:faza', edited_week.value);
}

const removeNutrient = function(key){            
  // edited_week.value.item_flowering.items_nutrient.splice(key,1);      
  edited_week.value.item_flowering.items_nutrient[key].is_remove = 1;  
}

const changeUniversal = (old_item, item, category) => {
  if (old_item) {
    edited_week.value.item_flowering.items_nutrient = edited_week.value.item_flowering.items_nutrient.map((el) => {
      if (el === old_item) {
        item.val = old_item.val
        item.unit = old_item.unit
        return item
      }
      return el
    })
  } else {
    diaryData.value.items_equip.push(item)
  }
}

const changeHeight = function(vl){
  // console.log('change num: ' + vl);
  // edited_week.value.item_flowering.height = vl;
}

const cancel = function(){  
  emits('canceleditweek', edited_week.value.id)
}

const save = function(){            
  emits('saveweek', edited_week.value)
}


const setPhotoLoading = function(id, state){
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.id == id){
      console.log('is_loading photo' + id + ' state: ' + state);
      edited_week.value.items_photo[opt_key].is_loading = state;
    }
  });
}

const setPhoto = function(id, item){
  console.log('setPhoto photo' + id + ' item: ' + item.size_m);
  edited_week.value.items_photo.forEach((opt, opt_key) => {
    if(opt.id == id){
      edited_week.value.items_photo[opt_key].size_xl = item.size_xl;
      edited_week.value.items_photo[opt_key].size_l = item.size_l;
      edited_week.value.items_photo[opt_key].size_m = item.size_m;
      edited_week.value.items_photo[opt_key].size_s = item.size_s;
      edited_week.value.items_photo[opt_key].size_xs = item.size_xs;
    }
  });
}

const saveRotate = function(item, rotate_value){

  setPhotoLoading(item.id, true);

  $api.postRotatePhoto(item.id, rotate_value)
  .then((res) => {
    setPhotoLoading(item.id, false);
    setPhoto(item.id, res.data.value.data);
  })
  .catch((err) => {
    setPhotoLoading(item.id, false);
    $popup.error('Error rotate photo');
  })
}



watch(() => props.week, (new_props_week, old_props_week) => {
  edited_week.value = new_props_week;
}, {deep: true});



</script>

<style scoped>

  .parameters{
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
 

  .photos{
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap:15px;
    -webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none;
  }
  .photos_ghost{
  opacity: 0.5;
  background: #bee85d;
  }
  .udr_bx{
    text-align: center;
    margin: 1rem;
  }

</style>

<template>

  <ClientOnly>

    <Teleport to="#context">

      <div class="ctx" @click="activeContext = true">
        <i class="icon-dots"></i>
      </div>
        
      <UiModal
        v-if="activeContext"
        :title="''"
        :close-button-name="'Close'"
        :is-transparent="true"
        :is-auto-height="true"
        :width="'70%'"
        :max-width="'400px'"
        @close="activeContext = false"         
        >        
        

        <div class="context_list">
  
          <div 
            class="actions" 
          >    

            <template v-if="useAuth().isAuth() && useAuth().getId() == props.user.id">

              <UiButton
                tag="NuxtLink"             
                :name="$t('growers_view_button_edit_profile')"
                ico="icon-setting"
                type="float"
                @click.native="activeContext = false" 
                :to="props.user.link + '/settings'"
              />

              <UiButton
                :name="$t('growers_view_button_logout')"
                type="float"
                ico="icon-logout"
                @click="useAuth().logout({redirect: true})" 
              /> 

                
            </template>
            <template v-else>

              <UiButton
                :name="$t('universal_button_follow')"
                type="float"
                v-if="!props.isFollow"
                @click.native="handleFollow" 
              />
  
              <UiButton
                :name="$t('universal_button_unfollow')"
                type="float"
                v-if="props.isFollow"
                @click.native="handleUnfollow" 
              />
              
              <UiButton
                name="Chat"
                type="float"
                ico="icon-message"
                @click="handleToChat" 
              /> 
            
            </template>


            <UiButton
              :name="$t('growers_view_button_copy_link')"
              type="float"
              ico="icon-share"
              @click="handleCopyLink" 
            /> 
                


          </div>
  
        </div>
      </UiModal>
    </Teleport>    
  </ClientOnly>

</template>


<script setup lang="ts">
 
import type { User } from '@/types/user'
import { useClipboard } from '@vueuse/core'

const { text: copyText, copy, copied: copyCopied, isSupported: copyIsSupported } = useClipboard()
const { hooks } = useNuxtApp()
const emits = defineEmits(['follow', 'unfollow']);

interface Props {
  user: User;
  isFollow?: boolean | null;
}

const props = defineProps<Props>();

const activeContext = ref(false)



const handleCopyLink = function(){
  activeContext.value = false;
  copy(document.location.href)
}



const handleFollow = () => {
  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  activeContext.value = false; 
  emits('follow')

}
const handleUnfollow = () => {

  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  activeContext.value = false; 
  emits('unfollow')
  
}


const handleToChat = function() {

  hooks.callHook('chat:start', props.user.id).catch(() => {
    console.log('chat:start hook');    
  })

}

</script>

<style scoped>
  .ctx{        
    min-width: 59px;
    display: flex;
    justify-content: center;
  }
  .ctx > i{    
    padding: 14px 20px 14px 25px;
    cursor: pointer;
  }


  /*  */

  .context_list{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }
 
</style>

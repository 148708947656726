<template>

  <NuxtLink class="it row_item rating_list" :to="link">

      <div class="position" data-popup="Rating">{{ rating }}</div>

      <picture class="photo"> 
        <source :srcset="logo" type="image/webp">
        <source :srcset="logo" type="image/jpeg">               
        <img :src="logo">
      </picture>

      <!-- <div class="sp"></div> -->

      <div class="name">
        {{ nm }}      
      </div>


      <div class="brand">
        <img class="logo_brand" :src="item_brand.logo_s">
        {{ item_brand.name }}
      </div>

      <div class="types">

        <div class="type">
          {{ autof ? 'Autoflower' : 'Feminized' }}
        </div>

        <div v-if="item_brand.country" class="country">
          <img class="logo_country" :src="'/images/country/small/' + getCountryFile(item_brand.country) + '.png'"> 
          <span>{{ item_brand.country }}</span>           
        </div>

        
      </div>


      <div class="stat">        
        
        <div class="item item_gp" v-if="item_stat.avg_weight_plant">
          <div class="value">
            <i class="gp"></i>
            {{ item_stat.avg_weight_plant }}             
          </div>
          <div class="ttl">g/plant</div>
        </div>

        <div class="item reps_value" v-if="item_stat.cnt_reports">
          <div class="value">
            <i class="icon-diary"></i>
            {{ item_stat.cnt_reports }}        
          </div>
          <div class="ttl">Diaries</div>
        </div>
 
        <div class="item big rate_value" v-if="item_stat.avg_rate">
          <span class="star_rate"></span>
          <div class="value">
            {{ item_stat.avg_rate }}
            <span>/{{ rateof }}</span>
          </div>
          <div class="ttl">{{ item_stat.cnt_harvests }} Harvests </div>
        </div>

      </div>





  </NuxtLink>
</template>


<script setup>

const props = defineProps({
  rating: {
    type: Number,
    required: false
  },
  logo: {
    type: String,
    required: false
  },
  link: {
    type: String,
    required: true
  },
  item_stat: {
    type: Object,
    required: false
  },
  item_brand: {
    type: Object,
    required: false
  },
  nm: {
    type: String,
    required: false
  },
  products: {
    type: Number,
    required: false
  },
  gplant: {
    type: Number,
    required: false
  },
  diaries: {
    type: Number,
    required: false
  },
  growers: {
    type: Number,
    required: false
  },
  harvests: {
    type: Number,
    required: false
  },
  rate: {
    type: Number,
    required: false
  },
  rateof: {
    type: Number,
    required: false,
    default: 10
  },
  types: {
    type: Array,
    required: false
  },
  country: {
    type: String,
    required: false
  },
  autof: {
    type: Boolean,
    required: false
  },
})

const getCountryFile = function(country) {
  return country.toLowerCase().replace(/ /g, '-');
}

</script>
   

<style scoped>
   
.it {
  width: 100%;
  display: flex;
  position: relative;
  border-radius: 3px;


  border-bottom: 1px var(--un-element-color-gray) solid;
  content-visibility: auto;
  container: itrow / inline-size;
 

  display: grid;
  width: 100%;
  grid-template-columns: 35px 60px auto fit-content(200px);
  grid-template-areas: 
  "position photo name stat"
  "position photo brand stat"
  "position photo types stat"
  ;
  align-items: center;
  gap: 0 1rem;
  padding: 0.7rem 0rem;
  break-inside: avoid;

}


.it > * {
  display: flex;
  align-items: center; 
}
 
.it .photo {
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  grid-area: photo;

}
.it .photo img{
  aspect-ratio: 1/1.5;
  object-fit: cover;

  border-radius: 3px;
  width: 100%;
}
.it .name {
  font-size: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: auto;

  grid-area: name;
}

 
 

.it .brand{
  display: flex;
  align-items: center;
  gap: 7px;
  width: 100%;
  height: 22px;
  color: gray;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  grid-area: brand;
}
.it .brand .logo_brand{
  width: 26px;
    aspect-ratio: 1/1;
    object-fit: contain;
}
.it .country{
  display: flex;
  align-items: center;
  margin-bottom: auto;
  grid-area: country;
  color: gray;
  gap: 0.4rem;
}
.it .country .logo_country{
  width: 16px;
  vertical-align: bottom;
}
.it .country span{
  display: block;
  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.it .types{
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: auto;
  grid-area: types;
}
.it .type{
  color: gray;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  margin-bottom: auto;

  grid-area: type;
}

.it .sp{

}
.it .icon.circle.online{
    color: var(--un-primary-back-color);
}
.it .icon.circle{
    color: gray;
    font-size: 0.75rem;
}
.it .icon.trophy{
    color: #cccccc;
    display: none;
}
.it .icon.gold{
    color: gold;
}
.it .stat {
  display: flex;
  position: relative;
  padding-right: 10px;
  width: fit-content;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex-shrink: 0;

  grid-area: stat;
}

.it .stat .item {
    display: inline-block;
    text-align: center;
    width: 80px;
}
.it .stat .item.rate_value {
    min-width: 75px;
    text-align: left;
} 


.it .stat .item .value .ic_cost {
        width: 5px;
}
.it .stat .item .value {
    font-size: 1rem;
    color: #4e4e4e;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    letter-spacing: -1px;
}
.it .stat .item .value .gp,
.it .stat .item .value .gw{
    width: 18px;
    height: 18px;
    vertical-align: top;
}

.it .stat .item.rate_value .star_rate {
    float: left;
    margin-right: 7px;
}
.it .stat .item.rate_value .value span{
    font-size: 0.8rem;
    font-weight: bold;
}
.it .stat .item .ttl {
    color: gray;
}

.it .stat .item.likes .value i{
     color: var(--un-primary-back-color);
}



.it .stat .item.empty > *:not(.ttl) {
     opacity: 0.3;
}


.it .stat .item.big .ttl {
    font-size: 0.65rem;
}
.it .stat .item.big .value {
    font-size: 1rem;
}


.it .position{
  display: inline-block;
  width: 24px;
  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;

  border-radius: 30px;
  font-size: 1rem;
  font-weight: bold;
  color: #bbbbbb;
  display: inline-block;
  height: 24px;
  grid-area: position;
}
  

.catalog_content{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


@container pb (max-width: 700px) {
  .it{
    gap: 0 0.5rem; 
    grid-template-columns: 25px 55px auto fit-content(200px);
    grid-template-areas: 
    "position photo name name"
    "position photo brand stat"
    "position photo types stat"
    ;
  }
}

@container itrow (max-width: 750px) {
  .it .stat .growers_value{
    display: none;
  }
  .it .photo{
    max-width: 55px;
    padding: 2px;
  }
  .it .photo img{
    max-width: 44px;
    padding: 0;
  }
  .it{
    gap: 0 0.5rem;
  }
  .it .stat .item.rate_value{
    min-width: 75px;
    text-align: left;
  }
  .it .stat{
    padding-right: 0;
  }
  .it .country span{
    display: none;
  }
}

@container itrow (max-width: 500px) {
  .it .stat .reps_value{
    display: none;
  }
}

@container itrow (max-width: 400px) {
  .it_types{
    display: none;
  } 
}

@container itrow (max-width: 370px) { 
  .it .stat .item{
    width: 70px;
  }
  .it .stat .item_gp{
    display: none;
  }
}




</style>

<template>


  <div class="pb">
  
    <div class="headerow"> 

      <h1>
        Shorts
      </h1> 

      <GeneralSearchKeyword
        placeholder="Search Breeders & Nutrients"
        @choose="chooseTagKeyword"
      /> 
 
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="[
          {id:'create', name: 'Create'},
          {id:'likes', name: 'Likes'},      
        ]"
        />

      <GeneralSearchTags 
        :tags="tags" 
        :tagsselected="tags_selected" 
        :tagsdefault="tags_default" 
        :tagscount="shorts_data?.items_tag"    
        :tag-as-link="true"
        :tag-link="'/shorts'"
        :modify-path="true"
        :is-show-counts="false"
        @choose="chooseTags"
        @unchoose="unchooseTags"
        />

    </div>

    <br>

    <SkShorts v-if="is_loading && start == 0" />
    

    <div 
      v-else-if="shorts_data?.items?.length"
      class="videolist">
        <GeneralVideoShortLink v-for="(opt, ind) in shorts_data.items" 
          :key="ind"
          :cover="opt.size_m"
          :name="opt.item_diary.name"
          :faza="opt.item_week.faza"
          :week="opt.item_week.days"
          :link="'/shorts/'+ opt.video_id"
          :video-id="opt.video_id"
          @click="click($event, ind)"
          />
    </div>

    <UiEmpty
      v-else
      />
 

    <ClientOnly>        
      <InfinityScroll 
        v-if="shorts_data?.items?.length >= limit"
        :is-loading="is_loading"
        ref="elInfinityScroll"/>
    </ClientOnly>
 

    <ClientOnly>
      <Teleport to="#app"> 
        <div 
          v-if="isShowPlayer"
          class="over-player-pc">
          <div            
            class="over-player"    
            >        
              <GeneralShortsPageWithPlayer
                :list="shorts_data?.items.slice(playerStartFrom)"
                :close-type="'close'"
                @close="hidePlayer"
                @loadMore="loadMore"
              />
          </div>
        </div>
      </Teleport>
    </ClientOnly>

    

  </div>
</template>



<script setup>

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const route = useRoute();
const start = ref(0)
const limit = ref(20)
const isEnded = ref(false)
const {$api, $adv, $tagsUtil, $ga, $patcher} = useNuxtApp()
const { t } = useI18n()
const elInfinityScroll = ref(null)
const sort = ref('create')
const tags = ref($tagsUtil.tagsShorts)
const tags_selected = ref(route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : []))
const tags_default = ref($tagsUtil.tagsShorts)
const isShowPlayer = ref(false)
const playerStartFrom = ref(0)


const click = function(e, ind){
  showPlayer();
  playerStartFrom.value = ind
}

const setUrl = () => {
  if(import.meta.client)
    window.history.pushState({}, null, '/shorts');
}
  
const showPlayer = function(){
  isShowPlayer.value = true;
  useTopbarHiddenMobile().value = true;
  useWarningMigrateHidden().value = true;
  useNavbarThemeRequired().value = 'dark';
}

const hidePlayer = function(){
  isShowPlayer.value = false;
  useTopbarHiddenMobile().value = false;
  useWarningMigrateHidden().value = false;
  useNavbarThemeRequired().value = null;
  setUrl();
}


// onMounted(() => {
//   useTopbarHiddenMobile().value = true;
//   useWarningMigrateHidden().value = true;
//   useNavbarThemeRequired().value = 'dark';
// })

// onBeforeUnmount(() => {
//   useTopbarHiddenMobile().value = false;
//   useWarningMigrateHidden().value = false;
//   useNavbarThemeRequired().value = null;
//   if(shorts_data.value){
//     shorts_data.value.items = [];
//     shorts_data.value = null;
//   }
// })

const clearPagination = function(){     
  start.value = 0;  
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}


const chooseTagKeyword = function(tag){     
  if(tags_selected.value.indexOf('all') != -1)
    tags_selected.value = [];
  // tags.value[tag.tag] = tag;
  tags_selected.value.push(tag.tag)  
  clearPagination();        

  console.log(tag);
  // $ga.searchFullTextExplore($tagsUtil.getTagsKeyword(tags.value, tags_selected.value));
} 


const unchooseTags = function(selected){  
  console.log('unchooseTags');
  console.log(selected);
  console.log(tags_selected.value);
  tags_selected.value = tags_selected.value.filter((item) => item != selected);
  console.log(tags_selected.value);
}        

const chooseTags = function(selected){          
  tags_selected.value.push(selected);      
  clearPagination();    
  // $ga.eventGA('explore', 'tag', $tagsUtil.getTagsSimple(tags.value, tags_selected.value));
}


const loadData = async function() {   
 
  const response = await $api.getShorts({
    start: start.value,
    limit: limit.value,
    sortable: sort.value,
    q: $tagsUtil.getTagsKeyword(tags.value, tags_selected.value),
    tagsall: $tagsUtil.getTagsKeys(tags.value),
    tags: $tagsUtil.getTagsSimple(tags.value, tags_selected.value)
  })  
 
  return response;  
}


const loadMore = async function() {    
  start.value += limit.value;
}


const { pending: is_loading,  data: shorts_data } = await useLazyAsyncData('shorts_data', async () => await loadData())
 

watchArray([start, sort, tags_selected], async ([new_start, new_sort, new_tags_selected], added, removed) => {
  console.log('watcher');
  
  $patcher.change('/shorts', {
    isOnlyQuery: true,
    tags: new_tags_selected,
    sort: new_sort,
  });

  // alert(new_tags_selected.join(', '))

  is_loading.value = true;
  var dt = await loadData();

    
  if(dt.length == 0)
    isEnded.value = true;  


  if(new_start == 0){
    shorts_data.value.items = [];
    isEnded.value = false;
  }

  shorts_data.value.items = [...shorts_data.value.items, ...dt.items]
  if(dt.items_tag?.length) shorts_data.value.items_tag = dt.items_tag;
  is_loading.value = false;

  // $adv.apply();
  
  useHead(useNuxtApp().$head.getGrowQuestions( ($tagsUtil.getTagsSimpleTrKey(tags.value, tags_selected.value).map((x) => t(x))).join(', ') ));

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

useHead(useNuxtApp().$head.getGrowQuestions( ($tagsUtil.getTagsSimpleTrKey(tags.value, tags_selected.value).map((x) => t(x))).join(', ') ));


onUnmounted(() => {
  useTopbarHiddenMobile().value = false;
  useWarningMigrateHidden().value = false;
  useNavbarThemeRequired().value = null;
});
  


 
</script>






<style scoped>

h1{
  margin-right: auto;
}

.add_filter_box{
  display: flex;
  margin-left: auto;
}

.headerow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

@container pb (max-width: 700px) {
  .headerow{

  }
  .headerow > .search{
    order: 1;
    width: 100%;
  }
  .headerow > .stags{
    order: 0;
    margin: 0;
  }
  .headerow > .sort{
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }
}


/*  */

.videolist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: hidden;
  justify-content: left;
  gap: 1rem;
  transition: all 0.3s ease;  
}

@container pb (max-width: 560px) { 
  .videolist{
    justify-content: space-between;
  }

  .videolist:deep(.video_short){
    width: calc(100%/2 - 0.5rem);
  }
}


/* over-player */

.over-player-pc{
  container: pc / inline-size;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11100;
  height: 100dvh;
  width: 100%;
}
.over-player{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0 0 0 / 84%);
  z-index: 11100;
  height: 100dvh;
  width: 100%;
  padding-top: 40px;    
}
@container pc (max-width: 500px) { 
  .over-player{
    padding-top: 0;
  }
}

</style>
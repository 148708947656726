<template>


  <!-- HERO -->

  <div class="hr">

    <img loading="lazy" class="htii" src="/images/awards/grow-awards-2025.svg" alt="The Grow Awards 2025">

    <div class="icons">
      <img loading="lazy" 
        v-for="icon in icons" :key="icon"
        :src="icon" 
        class="icon"
      >
    </div>

  </div>

  
</template>


<script setup lang="ts">
 
const icons = ref([
  '/images/awards/icons/icon-1.svg', 
  '/images/awards/icons/icon-2.svg', 
  '/images/awards/icons/icon-3.svg',
  '/images/awards/icons/icon-4.svg',
  '/images/awards/icons/icon-5.svg',
  '/images/awards/icons/icon-6.svg',
  '/images/awards/icons/icon-7.svg',
  '/images/awards/icons/icon-8.svg',
  '/images/awards/icons/icon-9.svg',
  '/images/awards/icons/icon-10.svg',
  '/images/awards/icons/icon-11.svg',
  '/images/awards/icons/icon-12.svg',
  '/images/awards/icons/icon-13.svg',
  '/images/awards/icons/icon-14.svg',
  '/images/awards/icons/icon-15.svg',
  '/images/awards/icons/icon-16.svg',
  '/images/awards/icons/icon-17.svg',
])

</script>
 


<style scoped>
 
.hr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  gap: 2rem;
}
 .htii {
  max-width: 350px;
  height: auto;
  margin: 4rem auto 1rem auto;
  aspect-ratio: 349/205;
  width: 100%;
 }

 .icons{  
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  overflow-x: hidden;
}

.icon{
  width: 50px;
  aspect-ratio: 1/1;
  flex-shrink: 0;
  max-width: 100px;
  margin: 0 10px;
}

@container pc (max-width: 600px) {
  .htii {
    max-width: 270px;
  }
}

</style>
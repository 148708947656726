<template>

  <div class="gallery" v-if="data.items_photo">

    <div class="divider">
      {{ $t('diary_info_photos_video') }}
    </div>
 

    <div class="slider">
      <UiCarousel :slides="preparedPhotos" @slideEnd="slideEnd">
        <template #slide="{ data, index }">
          <div class="slide-content">
            
            <div v-if="data.is_video" class="video_play"></div>
            <div v-if="data.is_video" class="media_duration">{{ sec(data.duration) }}</div>

            <div class="media_comment" v-if="data.comment">
                {{ data.comment }}      
            </div>

            <div class="is-smoke" v-if="data.is_smoke" title="Smoke review">
                <i class="icon-smoke"></i>
            </div>

            <img 
              :src="data.size_m"                     
              height="700" 
              width="700" 
              
              alt="" 
              @click="openGallery(index)"
              :data-id="data.id" 
              class="image-thumb tooltip-toggle" 
              >


            <template v-if="preparedPhotos.length < props.data.items_photo.length && photo_key == preparedPhotos.length-1">
              <div class="other_login" @click.stop="useAuth().goToLogin()">
                <div class="other_photos_count">
                  <span class="other_photos_count_text">{{ props.data.items_photo.length - preparedPhotos.length }}+</span>
                  <div class="other_wlg">
                    <b>{{ props.data.items_photo.length - preparedPhotos.length }}+ photos after</b>
                    <span class="login btn primary small">Login</span>
                  </div> 
                </div>      
              </div>      
            </template>
                  

          </div>
        </template>
      </UiCarousel>
 

    </div>

    
    <div class="zoomer">

      <!-- https://photoswipe.com/ -->

      <div :class="{'photos': true, 'single': data.items_photo.length == 1}" id="gallery">      

        <div
          v-for="(photo, photo_key) in preparedPhotos" 
          :key="photo_key"
          class="photo"
          data-gallery="" 
          :data-gallery-id="photo.id" 
          data-type="photo" 
          data-caption="" 
          :href="photo.size_big"
          :data-pswp-width="2400"
          :data-pswp-height="3200"
          target="_blank"
          rel="noreferrer"          
          @click="openGallery(photo_key)"
          >

          <div class="media_comment" v-if="photo.comment">
              {{ photo.comment }}      
          </div>

          <div class="is-smoke" v-if="photo.is_smoke" title="Smoke review">
              <i class="icon-smoke"></i>
          </div>


          <div v-if="photo.is_video" class="video_play"></div>
          <div v-if="photo.is_video" class="media_duration">{{ sec(photo.duration) }}</div>
          <img 
            :src="data.items_photo.length == 1 ? photo.size_xl : photo.size_m"                     
            height="300" 
            width="300" 
            alt="" 
            @click="gallery_index = photo_key"
            data-id="2984" 
            class="image-thumb tooltip-toggle" 
            >


            

          <template v-if="preparedPhotos.length < data.items_photo.length && photo_key == preparedPhotos.length-1">
            <NuxtLink class="other_login" to="/auth/signin" @click.stop="">
              <div class="other_photos_count">
                <span class="other_photos_count_text">{{ data.items_photo.length - preparedPhotos.length }}+</span>
                <div class="other_wlg">
                  <b>{{ data.items_photo.length - preparedPhotos.length }}+ photos after</b>
                  <span class="login btn primary small">Login</span>
                </div> 
              </div>      
            </NuxtLink>      
          </template>

        </div>   

    
      </div>
    </div>


    <GeneralLightbox 
      v-if="lightboxShow"
      :index="lightboxIndex" 
      :photos="lightboxPhotos" 
      @close="lightboxShow = false" 
      />

 
  </div>

  
</template>

<script setup>

const tttttt = 1;
   
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
})

var lightboxShow = ref(false);
var lightboxIndex = ref(0);

const preparedPhotos = computed(() => {
  if(useAuth().isAuth() || props.data.items_photo.length <= 3){
    return props.data.items_photo;
  }else{
    return props.data.items_photo.length > 2 ? props.data.items_photo.slice(0,3) : []
  }
})


const lightboxPhotos = computed(() => {
  var items = [];
  for(var i of preparedPhotos.value){
    let photo = {};
    if(i.is_video){
      photo.type = 'iframe';
      photo.iframe = '/video/' + i.video_id;
    }          
    photo.comment = i.comment;
    photo.base = i.file ? i.file : i.size_big;
    photo.zoom = i.size_big;
    photo.caption = i.comment;
    items.push(photo);        
  }
  return items;
})


const sec = function(d){      
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + ":" : "";
  var mDisplay = m + ":";
  var sDisplay = s + "";
  return hDisplay + (mDisplay < 10 ? "0" + mDisplay : mDisplay) + (sDisplay < 10 ? "0" + sDisplay : sDisplay); 
}

const openGallery = function(key){
  lightboxShow.value = true;
  lightboxIndex.value = key;
}

const slideEnd = function(key){
  const scrollContainer = document.querySelector('.carousel__pagination');


  const activeElement = document.querySelector('.carousel__pagination-button--active');

  if (scrollContainer?.getBoundingClientRect && activeElement?.getBoundingClientRect) {
    const containerRect = scrollContainer.getBoundingClientRect();
    const activeRect = activeElement.getBoundingClientRect();


    const scrollLeftPos = activeRect.left + scrollContainer.scrollLeft - containerRect.left - scrollContainer.offsetWidth / 2 + activeRect.width / 2;


    scrollContainer.scrollTo({ left: scrollLeftPos, behavior: 'smooth' });
  }

}


</script>

<style scoped>
 
 
.photos{
  display: flex;justify-content: center;flex-wrap: wrap;
  gap: 5px;
}
.photos .photo{
  max-width: calc(100%/4 - 0.5rem);
  position: relative;
  width: 100%;
}
.photos.single .photo{
  max-width: calc(100%/2 - 0.5rem);
}
.photos .photo img{
  /* width: 100%; */
  border-radius: 5px;    
  background-color: var(--un-background-color-gray);
  aspect-ratio: 1/1;
  width: 100%;
  object-fit: cover;
}
.photos .photo img.isLoading{  
  object-position: -99999px 99999px;
}

.media_duration{
background: rgb(0, 0, 0);
/* margin-bottom: 10px; */
padding: 4px;
/* max-width: 720px; */
display: block;
margin-top: 0px;
margin-left: 0px;
/* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.53); */
border-radius: 4px;
width: 58px;
text-align: left;
margin-bottom: 0px;
position: absolute;
/* max-height: 100%; */
overflow: hidden;
line-height: 16px;
z-index: 1;
max-width: 70%;
color: white;
font-size: 0.85rem;
opacity: 0.8;
text-align: center;
right: 6px;
bottom: 5px;
}

.is-smoke{
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  text-shadow: 0 0 10px black;
  font-size: 1rem;
  opacity: 0.8;
}
.is-smoke i{

}
.media_comment{
background: rgb(247, 247, 247);
margin-bottom: 10px;
padding: 10px 10px 10px 10px;
/* max-width: 720px; */
display: block;
margin-top: 0px;
margin-left: 0px;
/* box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.53); */
border-radius: 4px 0px 4px 0px;
width: 167px;
text-align: left;
margin-bottom: 0px;
position: absolute;
max-height: 100%;
overflow: hidden;
line-height: 16px;
z-index: 1;
max-width: 70%;
color: black;
font-size: 0.9rem;
opacity: 0.8;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
    top: 0;
    left: 0;
}
.media_comment:hover{
opacity: 1;
}
.video_play {
  width: 56px;
  height: 56px;
  z-index: 1;
  position: absolute;
  background-image: url(/images/play_button.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 62px;
  left: calc(50% - 28px);
  top: calc(50% - 28px);
  pointer-events: none;
}

.media_duration {
  background: rgb(0, 0, 0);
  /* margin-bottom: 10px; */
  padding: 4px;
  /* max-width: 720px; */
  display: block;
  margin-top: 0px;
  margin-left: 0px;
  /* box-shadow: 0px 0px 4px rgb(0 0 0 / 53%); */
  border-radius: 4px;
  width: 58px;
  text-align: left;
  margin-bottom: 0px;
  position: absolute;
  /* max-height: 100%; */
  overflow: hidden;
  line-height: 16px;
  z-index: 1;
  max-width: 70%;
  color: white;
  font-size: 0.85rem;
  opacity: 0.8;
  text-align: center;
  right: 6px;
  bottom: 5px;
}


.zoomer{}

.slider{
  display: none;
}

.slider img{
  width:100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.slider .slide{
  width: 100% !important;
  aspect-ratio: 1 / 1;
  flex-shrink: 0;
}

@container pb (max-width: 600px) {
  .zoomer{
    display: none;
  }
  .slider{
    display: block;
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}



.slide {
  aspect-ratio: 1/1;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 5px;
}
.carousel__slide  img{
  border-radius: 5px;
}

.carousel__pagination.limited_dots{
  max-width: 100px;
  overflow-x: hidden;
  margin: 0px auto;
    justify-content: flex-start;
} 



/* photos login */


.other_login {
  overflow: hidden;
  font-size: 0.9rem;
}

.other_login:hover {
  color: inherit;
}
.other_login .login{

  margin-left: 5px;
}
.other_login img {
  filter: blur(5px);
}

.other_login .other_photos_count {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffd1;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.other_login .other_photos_count .other_photos_count_text {
  font-size: 2.1rem;
  margin: auto;
  background-color: #ffffffed;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  font-weight: 500;
  box-shadow: 0px 0px 20px white;
  height: 75px;
}

.other_login .other_photos_count .other_wlg {
  margin-top: -40px;
  height: 43px;
  flex-shrink: 0;
}

.other_login .other_photos_count .other_wlg .button {
  margin-left: 5px;
}


</style>

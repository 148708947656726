<template>
  
  <div 
    class="bd"
    :theme="navbar_theme"
    >

    <Debug />  

    <Popup />

    <ChatWidget />

    <div class="cont">
      
        <NuxtLink to="/">     
          <img
            class="lg"
            src="/logo/logo_ver.svg"
            alt="Growdiaries"
          />
        </NuxtLink>

        <NuxtPage/>

    </div>


    <div id="teleport"></div>
 
  </div>

</template>

<script setup>
  
const navbar_theme = useNavbarTheme();

const { $i18n } = useNuxtApp();
 
useHead({ 
  htmlAttrs: {
    lang: $i18n.locale
  }
})

</script>


<style scoped>

.cont {
  display: flex;
  flex-direction: column;
  justify-content: center;  
  margin: auto;
  max-width: 50%;
  align-items: center;
  min-height: 100vh;
}

.cont .lg{
  max-width: 180px;
  margin: 0 auto;
  display: block;
  margin-bottom: 2rem;
  width: 100%;
}

.bd{
  min-height: 100vh;
}
@media (max-width: 768px) {
  .cont .lg{
    margin-top: 2rem;
  }
  .cont {
    max-width: 100%;
  }
}
</style>

import { ref, Ref, onMounted, onUnmounted } from 'vue';
import { useRuntimeConfig, useNuxtApp, useCookie } from 'nuxt/app';
import { appendResponseHeader, H3Event } from 'h3';

interface CustomFetchOptions {
  method?: string;
  body?: any;
  headers?: Record<string, string>;
  onUploadProgress?: (event: ProgressEvent) => void;
  onResponse?: (xhr: XMLHttpRequest) => void;
  onResponseError?: (xhr: XMLHttpRequest) => void;
  domain?: string;
}

export function useCustomXMLHttpReq<T>(initialUrl: string, initialOptions: CustomFetchOptions = {}) {

  
  const data: Ref<T | null> = ref(null);
  const error: Ref<any> = ref(null);
  const loading: Ref<boolean> = ref(false);


  const configOrigin = ref('');
  const configApiSecret = ref('');
  const config = useRuntimeConfig();
  const { hooks, $uniq } = useNuxtApp();


  const debugFor = useDebugListFor();

  const event = useRequestEvent();
  const userAuth = useCookie('auth:token',{maxAge: 60 * 60 * 24 * 31 * 6});
  const guestAuth = useCookie('auth:guest',{maxAge: 60 * 60 * 24 * 31 * 6})
  const apiKey = useCookie('auth:api',{maxAge: 60 * 60 * 24 * 31 * 6}).value ? useCookie('auth:api',{maxAge: 60 * 60 * 24 * 31 * 6}) : useApiSecret();



  if(import.meta.server){
    configOrigin.value = config.origin ?? ''
    configApiSecret.value = config.apiSecret ?? ''
  }

  // console.log(import.meta.server);
  // console.log(config.public);

  const xhr = new XMLHttpRequest();


  const getCookieString = () => {
    const unitVolume = useCookie('unit_volume');
    const unitTemperature = useCookie('unit_temperature');
    const unitWeight = useCookie('unit_weight');
    const unitTds = useCookie('unit_tds');
    const unitLength = useCookie('unit_length');
    const details = useCookie('details');
    var string = '';

    if(unitVolume.value)
      string += `unit_volume=${unitVolume.value}; `;
    if(unitTemperature.value)
      string += `unit_temperature=${unitTemperature.value}; `;
    if(unitWeight.value)
      string += `unit_weight=${unitWeight.value}; `;
    if(unitTds.value)
      string += `unit_tds=${unitTds.value}; `;
    if(unitLength.value)
      string += `unit_length=${unitLength.value}; `;
    if(details.value)
      string += `details=${details.value}; `;

      // console.log('cookies string');
      // console.log(string);
    return string;
  };

  // 'X-Uniq': uniq ?? '',
  // 'X-Rniq': rniq ?? '',
  
  var uniq = '';
  var rniq = '';
  if(import.meta.client){
    let tmpUniq = $uniq.code();
    uniq = tmpUniq.code;
    rniq = tmpUniq.rnd;
  }
  


  let url = initialUrl.replace(/^\/api/, '');

  if (import.meta.server) {
    url = config.public.baseAPILocal + url;
  } else {
    if (initialOptions.domain === 'UPLOAD') {
      url = config.public.baseAPIUpload + url;
    } else {
      url = config.public.baseAPI + url;
    }
  }
  
  const sendRequest = () => {
    loading.value = true;

    xhr.open(initialOptions.method || 'GET', url);

    xhr.withCredentials = true;

    // xhr.setRequestHeader('Cookie', getCookieString());
    
    xhr.setRequestHeader('Authorization', userAuth.value ? `Bearer ${userAuth.value}` : '');
    xhr.setRequestHeader('X-Origin', configOrigin.value ?? '');
    xhr.setRequestHeader('X-Access', apiKey.value ?? '');
    if (import.meta.server) {
      xhr.setRequestHeader('X-Pass', configApiSecret.value ?? '');
    }
    xhr.setRequestHeader('X-Guest', guestAuth.value ?? '');
    xhr.setRequestHeader('X-Uniq', uniq ?? '');
    xhr.setRequestHeader('X-Rniq', rniq ?? '');

    // if(initialOptions.method == 'POST'){
    //   xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    // }



    // xhr.setRequestHeader('Cookie', getCookieString());

    if (initialOptions.headers) {
      for (const key in initialOptions.headers) {
        xhr.setRequestHeader(key, initialOptions.headers[key]);
      }
    }

    xhr.onload = () => {
      console.log('xhr.onload');
      if (xhr.status >= 200 && xhr.status < 300) {
        try {
          data.value = JSON.parse(xhr.responseText);
          // console.log('JSON.parse(xhr.responseText)')
          // console.log(JSON.parse(xhr.responseText))
          initialOptions.onResponse && initialOptions.onResponse(data.value);

          if(data.value?.details){
            debugFor.value.push(data.value); 
          }
          
          
        } catch (e) {
          error.value = e;
        }
      } else {
        error.value = new Error('Server responded with an error');
        initialOptions.onResponseError && initialOptions.onResponseError(xhr);
      }
      loading.value = false;

      
    };

    xhr.onerror = () => {
      error.value = new Error('Network error');
      initialOptions.onResponseError && initialOptions.onResponseError(xhr);
      loading.value = false;
    };

    xhr.upload.onprogress = initialOptions.onUploadProgress;

    xhr.send(initialOptions.body);
  };

  sendRequest();

  // onMounted(sendRequest);
  // onUnmounted(() => xhr.abort());

  return { data, error, loading };
}

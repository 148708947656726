import CryptoJS from 'crypto-js'
import { useAdvStat } from '@/composables/useAdvStat'
import { useAdvHeader } from '@/composables/useAdvHeader'
import { useAdvCpm } from '@/composables/useAdvCpm'
import { useAdvBox } from '@/composables/useAdvBox'
import type { AdvPackage, AdvBannerHeader, AdvBannerCpm, AdvBannerBox, AdvAction } from '~/types/adv';

export default defineNuxtPlugin(async nuxtApp => {  

  const { $api } = useNuxtApp();

  var adv = {

    loaded: false, 
    timer: null,
    sendStat: async function(){  
      var stat = useAdvStat();
      
      if(!stat.value?.length){
        return false;
      }

      var data = stat.value.map((item: AdvAction) => {
        return {
          i: item.id,
          t: item.type,
          s: item.section,
          a: item.action,
          h: CryptoJS.AES.encrypt(item.id + item.type + item.section + item.action, useAuth().getGuest(), {iv: ''}).toString()
        };
      });


      stat.value = [];
      useNuxtApp().$api.postAdvStat(data);    
    },
    apply: async function(){  
      useAdvCpm().apply();
      useAdvBox().apply()
      // advSidebar.applySidebar() 
    },  
    applyTimer: null,
    clearApplyTimer: function(){
      if(adv.applyTimer){
        clearTimeout(adv.applyTimer);
        adv.applyTimer = null
      }
    },
  }
 

  const { data: advData } = await useLazyAsyncData('advData', async () => await $api.getAdvAll())

  useAdvHeader().setList(advData.value?.items_header as AdvPackage<AdvBannerHeader>[]);
  useAdvHeader().setIgnore(advData.value?.ignore_sections);
  
  useAdvCpm().setList(advData.value?.items_cpm as AdvPackage<AdvBannerCpm>[]);
  useAdvCpm().setIgnore(advData.value?.ignore_sections);

  useAdvBox().setList(advData.value?.items_box as AdvPackage<AdvBannerBox>[]);
  useAdvBox().setIgnore(advData.value?.ignore_sections);
  
  

  if(import.meta.client){

    nuxtApp.hook('page:finish', () => {

      adv.clearApplyTimer();

      adv.applyTimer = setTimeout(() => {
        
        useAdvBox().prepare();
        useAdvBox().apply();

        useAdvCpm().prepare();
        useAdvCpm().apply();
        // adv.apply();
        adv.clearApplyTimer();
      },500)
    }) 

    nuxtApp.hook('custom:fetch:get', () => {
      if(adv.applyTimer) return;

      adv.applyTimer = setTimeout(() => {
        adv.apply();
        adv.clearApplyTimer();
      }, 200);

    });


    if(adv.timer){
      clearInterval(adv.timer);
    }

    adv.timer = setInterval(() => {
      adv.sendStat();
    }, 10000);


    window.addEventListener("beforeunload", function(event) {
      adv.sendStat();
    });

  

  }


  return {
    provide: {
      adv: adv,
    },
  };

})
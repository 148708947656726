<template>


  <div class="result">


    <template
      v-if="props.data.question_type == 'diagnosis'"
    >
      <div class="main_issue">
        {{ props.data.main_issue }}
      </div>

      <div class="current_symptoms">
        {{ props.data.current_symptoms }}
      </div>

      <div class="diagnosis_options">
        <div class="diagnosis_option" v-for="option in props.data.diagnosis_options">
          <div class="diagnosis_title">
            {{ option.diagnosis_title }}
          </div>
          <div class="probability">
            Probability: {{ option.probability }}
          </div>
          <div class="symptomsList">
            Symptoms: 
            <ul>
              <li v-for="symptom in option.symptomsList">
                {{ symptom }}
              </li>
            </ul>
          </div>
          <div class="resolveList">
            Resolve: 
            <ul>
              <li v-for="resolve in option.resolveList">
                {{ resolve }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="conclusion_advice">
        {{ props.data.conclusion_advice }}
      </div>

    </template>
    <template v-else>
      <div class="main_issue">
        {{ props.data.analysis_text }}
      </div>
      <div class="conclusion_advice">
        {{ props.data.conclusion_advice }}
      </div>      
    </template>


    

  </div>


</template>

<script setup>

const { $api } = useNuxtApp()

const props = defineProps({
  data: {
    type: Object,
    // required: true
  },
})


// {
//     "main_issue": "Crowding at the top of the plant and leaf burn.",
//     "current_symptoms": "Leaves are burning, and flowers are being kept off the light due to crowding at the top.",
//     "diagnosis_options": [
//         {
//             "probability": 0.8,
//             "diagnosis_title": "Heat Stress",
//             "symptomsList": [
//                 "Leaf burning",
//                 "Crowding at the top"
//             ],
//             "resolveList": [
//                 "Increase the distance between the lamp and plant",
//                 "Ensure adequate ventilation",
//                 "Consider trimming back the crowded areas to improve airflow."
//             ]
//         },
//         {
//             "probability": 0.6,
//             "diagnosis_title": "Light Burn",
//             "symptomsList": [
//                 "Leaves are burning",
//                 "Crowding at the top"
//             ],
//             "resolveList": [
//                 "Increase the distance between the light and plant",
//                 "Adjust light intensity if possible",
//                 "Trim back leaves that are too close to the light."
//             ]
//         },
//         {
//             "probability": 0.5,
//             "diagnosis_title": "Nutrient Imbalance",
//             "symptomsList": [
//                 "Leaf burning"
//             ],
//             "resolveList": [
//                 "Check and adjust nutrient concentrations",
//                 "Ensure pH levels are within optimal range (5.5-6.5 for hydroponics)."
//             ]
//         }
//     ],
//     "conclusion_advice": "The primary issue seems to be heat stress or light burn due to the close proximity of the lamp to the top of the plant, causing leaf burn and crowding. It is advised to increase the distance between the lamp and the plant and ensure proper ventilation. Additionally, consider trimming back the crowded areas to allow better light distribution and airflow. Regularly monitor nutrient levels and pH to prevent further stress on the plant."
// }
  


</script>

<style scoped>
 

.result {
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 4px;
}
.main_issue {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.current_symptoms {
  margin-bottom: 1rem;
}
.diagnosis_options {
  margin-bottom: 1rem;
}
.diagnosis_option {
  padding: 0.5rem 0;
  border-bottom: 1px solid #ccc;
}
.diagnosis_title {
  font-weight: bold;
}
.probability {
  color: #888;
}
.symptomsList,
.resolveList {
  margin: 0.5rem 0;
}
.conclusion_advice {
  font-style: italic;
}

</style>




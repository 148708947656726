<template>



  <GeneralSearchTags  
    :class="{filters: true}"
    :tags="tags" 
    :tagsselected="tags_selected" 
    :tagsdefault="tags_default" 
    :tagscount="giveaways_data?.tags_list"      
    :tag-as-link="true"
    :tag-link="'/giveaways'"
    :modify-path="false"
    @choose="chooseTags"
    @unchoose="unchooseTags"
    />
 
    <div class="completed" v-if="giveaways_data">

    <GiveawayRow 
      v-for="(data, item_key) in giveaways_data.items_giveaway" 
      :key="item_key" 
      :data="data">
      </GiveawayRow>
      
    </div>
 

    <ClientOnly>        
      <InfinityScroll 
        v-if="giveaways_data?.items_giveaway?.length >= limit"
        :is-loading="is_loading"
        ref="elInfinityScroll"/>
    </ClientOnly>
 
</template>


<script setup>

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const { t } = useI18n()
const route = useRoute()
const start = ref(0)
const limit = ref(20)
const isEnded = ref(false)
const more = ref([])
const { $api, $ga, $tagsUtil } = useNuxtApp()
const elInfinityScroll = ref(null)
const tags = ref($tagsUtil.tagsGiveaway)
const tags_selected = ref(route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : []))
const tags_default = ref($tagsUtil.tagsGiveaway)

 

const clearPagination = function(){     
  start.value = 0;  
}

const unchooseTags = function(selected){  
  tags_selected.value = tags_selected.value.filter((item) => item != selected);
}

const chooseTags = function(selected){          
  tags_selected.value.push(selected);     
  // console.log(tags_selected.value); 
  // console.log(selected); 
  clearPagination();    
}



const loadDataCompleted = async function() {     

  let tmp_tags = $tagsUtil.getTagsSimple(tags.value, tags_selected.value);
  tmp_tags = tmp_tags ? 'completed,' + tmp_tags : 'completed';

  console.log('tmp_tags');
  console.log(tmp_tags);

  return await $api.getGiveaways(
    start.value,
    limit.value,
    tmp_tags
  )  
}



const { pending: is_loading,  data: giveaways_data } = await useLazyAsyncData('giveaways_data', loadDataCompleted)


watchArray([start, tags_selected], async ([new_start, new_tags_selected], added, removed) => {
  console.log('watcher');
  is_loading.value = true;
  var dt = await loadDataCompleted();

  if(new_start == 0){
    giveaways_data.value.items_giveaway = [];
    isEnded.value = false;
  }
    
  if(dt.length == 0)
    isEnded.value = true;  

  if(dt.tags_list?.length)
  giveaways_data.value.tags_list = dt.tags_list;

  giveaways_data.value.items_giveaway = [...giveaways_data.value.items_giveaway, ...dt.items_giveaway]
  is_loading.value = false;
}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)

 


</script>









<style scoped>

.completed{
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem 1.5rem;
  margin-top: 1rem;
}


@container pb (max-width: 600px) {
  .running{
    flex-direction: column;
  }
}


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.header_row{
  margin-bottom: 1rem;
}

.mansory{
  column-gap: 3%;  
  column-count: 2;
}
.mansory > *{
  width: 100%;
  display: inline-block;
}
</style>

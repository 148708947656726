<template>
  <div class="slider-container">


    <div class="names">
      <div class="name">Indica</div>
      <div class="name">Hybrid</div>
      <div class="name">Sativa</div>
    </div>

    <div class="bars">
      <div
        v-for="(barColor, index) in barColors"
        :key="index"
        class="bar"
        :style="{ backgroundColor: barColor }"
      />
    </div>

    <div 
      class="thumb"
      :style="thumbStyle"
      @mousedown.prevent="startDrag"
      @touchstart.prevent="startDrag"
    >
      <div class="triangle_up"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed } from 'vue'


const props = defineProps({
  modelValue: {
    type: Number,
    default: 0
  }
})

const emit = defineEmits(['update:modelValue'])

const stepsCount = 30

const internalValue = ref(props.modelValue)


watch(
  () => props.modelValue,
  newVal => {
    internalValue.value = newVal
  }
)


const barColors = [
  '#1a5c1a','#1e681a','#22731b','#268f1c','#2ca31d','#30b01e',
  '#38be25','#3ecc2c','#4fd33f','#60d852','#6ddf60','#7ce56f',
  '#8eea80','#9eed90','#afe2aa','#c2eca0','#d1f4a6','#e2fab2',
  '#f2febf','#fffbaf','#ffed9f','#ffdd84','#fec76e','#fdb05d',
  '#fb9b4d','#f8883d','#f57732','#f26628','#ed581f','#ea4b18'
]


const thumbStyle = computed(() => {
  return {
    left: `${100-internalValue.value}%`
  }
})


let isDragging = false


const startDrag = (e) => {
  isDragging = true
  addListeners()
  moveThumb(e)
}


const moveThumb = (e) => {
  if (!isDragging) return

  const clientX = e.touches ? e.touches[0].clientX : e.clientX

  const slider = document.querySelector('.slider-container')
  if (!slider) return

  const rect = slider.getBoundingClientRect()
  const offsetX = clientX - rect.left

  let newValue = (offsetX / rect.width) * 100
  newValue = Math.min(100, Math.max(0, newValue))

  internalValue.value = Math.round(newValue)
  emit('update:modelValue', internalValue.value)
}


const stopDrag = () => {
  isDragging = false
  removeListeners()
}

function addListeners() {
  document.addEventListener('mousemove', moveThumb)
  document.addEventListener('mouseup', stopDrag)
  document.addEventListener('touchmove', moveThumb)
  document.addEventListener('touchend', stopDrag)
}

function removeListeners() {
  document.removeEventListener('mousemove', moveThumb)
  document.removeEventListener('mouseup', stopDrag)
  document.removeEventListener('touchmove', moveThumb)
  document.removeEventListener('touchend', stopDrag)
}

onMounted(() => {
  internalValue.value = props.modelValue
})

onBeforeUnmount(() => {
  removeListeners()
})
</script>

<style scoped>

.slider-container {
  position: relative;
  width: 100%;
  max-width: 500px; 
  width: 350px;
  user-select: none;
  margin-top: 0.5rem;
  pointer-events: none;
}


.bars {
  display: flex;
  height: 20px;
  width: 100%;
  gap: 0.4rem;
}

.bar {
  flex: 1 1 auto;
  border-radius: 10px;
}


.thumb {
  position: absolute;
  bottom: -4px;
  width: 13px;
  height: 30px;
  cursor: pointer;
  transform: translateX(-50%);
  border: 2px var(--un-background-color-invert) solid;
  border-radius: 4px;

}

.names{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.names .name{
  width: 33.33%;
  text-align: center;
  font-weight: bold;
}

.names .name:first-child{
  text-align: left;
}

.names .name:last-child{
  text-align: right;
}

.triangle_up{
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--un-background-color-invert);
    position: absolute;
    bottom: 0px;
    left: 0;
}

@container pb (max-width: 600px) {
  .slider-container {
    width: 100%;
  }
}


  
</style>

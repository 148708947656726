<template>
 

  <div :class="{eff: true, 'disabled': isMaxSelected}">
    <div 
      v-for="(w, wkey) in props.list"
      @click="toggle(wkey)"
      :class="{'eff_item': true, 'selected': selected?.length && selected.indexOf(Number(wkey)) >=0 ? true : false}"
    >
      <img loading="lazy" :src="w.img" :title="t(w.tr)" class="img">

      <div class="name">
        {{ t(w.tr) }}
      </div>
      
    </div>
 
  </div>


</template>



<script setup lang="ts">


const route = useRoute()
const router = useRouter()
const { $progressBarContent, $api } = useNuxtApp()
const { t } = useI18n()
const emits = defineEmits(['select'])

interface PageProps {
  selected: Array<number>,
  list: any,
  imagePath: string,
  max: number
}

const props = defineProps<PageProps>()

var selected = ref(props.selected);

const isMaxSelected = computed(() => {
  if(!selected.value) return false;
  const selectedKeys = Object.keys(props.list).filter(key => selected.value.includes(Number(key)));
  return selectedKeys.length >= props.max;
})
 

watch(props, (new_props) => {
  selected.value = new_props.selected;
},{deep: true})

const toggle = function(key: any){
  emits('select', key)
}
 
 





 
</script>

<style scoped>
  
 .eff{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
 }

.eff .img{
    width: 100px;
    cursor: pointer;
    border-radius: 70px;
    border:6px white solid;
    margin: 5px;
}
.eff .eff_item{
  
}
.eff .name{

}
.eff .img:hover
{
    border: 6px #E6E6E6 solid;
    opacity: 0.5 !important;
}

.eff_item.selected .img{
  border:6px var(--gd-button-primary-back-color) solid;
}

/* disabled */
.eff.disabled .eff_item{
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.eff.disabled .eff_item.selected{
  opacity: 1;
  cursor: pointer;
  pointer-events: auto;
}

 


</style>

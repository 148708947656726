<template>

  <div :class="'similar ' + props.type" v-if="list && list.length">

    <h2 class="divider-sec">
      {{ title }}       
    </h2>


    <UiHorizontalScroll
      :arrows="true"
      :arrows-show-hover="true"
      v-if="list"
      >

      <div class="list">
        <template v-for="pro in list">
          <NuxtLink class="item" :to="pro.section">
            <div class="photo">
                <img :src="pro.cover_s" :alt="pro.name">
            </div>
            <div class="name">
              {{ pro.name }}     
            </div>
          </NuxtLink>
        </template>
      </div>
    </UiHorizontalScroll>


  </div>

</template>

<script setup>
  

const props = defineProps({
  title: {
    type: String,
    default: 'Similar'
  },
  type: {
    type: String,
    default: 'brand'
  },
  list: {
    type: Array,
    default: []
  },
});
 
</script>

<style scoped>
 
.similar {}

.similar .list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
}

.similar .list .item {
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

.similar .list .item .photo {
  margin-bottom: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;

}

.similar .list .item .photo img {
  width: 100%;
  border-radius: 5px;
}


.similar .list .item .photo img {
  width: 80%;
  border-radius: 5px;
  aspect-ratio: 1/1;
  margin: auto;
  object-fit: contain;
}

.similar.product .list .item .photo img {
  width: 100%;
  border-radius: 5px;
  aspect-ratio: 100/140;
  background-color: var(--un-background-color-gray);
  object-fit: contain;
}

.similar .list .item .name {
  text-align: center;
  font-size: 0.85rem;
  line-height: 1rem;
  color: #717171;
}


</style>

<template>

  <UiHorizontalScroll
    :arrows="true"
    :arrows-show-hover="true"
    :platform="'mobile'"
    >
    <GeneralShowMoreFeed 
      :platform="'desktop'"
      :max-height="450"
      >
      <div class="videolist">
        <GeneralVideoShortLink v-for="(opt, ind) in lightboxItems" 
          :key="ind"
          :cover="opt.cover"
          :name="opt.name"
          :week="opt.week"
          :faza="opt.faza"
          :link="'/shorts/'+ opt.id"
          :video-id="opt.id"          
          @click="useRouter().push('/shorts/'+ opt.id)"
          />

        <!-- <GeneralVideoShort v-for="(opt, ind) in lightboxItems" 
          :key="ind"
          :cover="opt.cover"
          :name="opt.name"
          @click.stop.prevent="openGallery(opt)" 
          /> -->
      </div>
    </GeneralShowMoreFeed>


    <GeneralLightboxShorts 
      v-if="lightboxShow"
      :item="lightboxItem" 
      :list="lightboxItems" 
      :blackBackground="true"
      @close="lightboxShow = false" 
      />

  </UiHorizontalScroll>
  
</template>

<script setup>


const lightboxShow = ref(false);
const lightboxItem = ref(null);

const props = defineProps({
  list: {
    type: Array,
    default: []
  },
})


const lightboxItems = computed(() => {
  var items = [];
  for(var i of props.list){
    let photo = {};  
    photo.id = i.video_id;
    // photo.video_id = i.video_id;
    // photo.cover = i.size_big; 
    photo.cover = i.size_m; 
    photo.name = i.item_diary.name; 
    photo.week = i.item_week.days; 
    photo.faza = i.item_week.faza; 
    photo.video = i.video_converted; 
    photo.info = {};
    photo.info.avatar = i.item_user.avatar_little;
    photo.info.name = i.item_user.name;
    photo.info.link = i.item_user.link;
    photo.info.text = i.item_week.text;
    photo.info.source_name = i.item_diary.name + ' - Week ' + i.item_week.days;
    photo.info.source_link = i.item_diary.link + '/week/' + i.item_week.id;

    items.push(photo);        
  }
  return items;
})
 

const openGallery = function(opt){
  console.log('open');
  // lightboxItem.value = lightboxItems.value[id];
  lightboxItem.value = opt;
  lightboxShow.value = true;
}
 


</script>



<style scoped>

/* videolist */

.videolist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: hidden;
  justify-content: left;
  gap: 1rem;
  transition: all 0.3s ease;  
}

@container pb (max-width: 1540px) {
  .videolist {    
    max-height: 364px;
  }
}

@container pb (max-width: 1340px) {
  .videolist {
    max-height: 364px;
  }
}

@container pb (max-width: 1140px) {
  .videolist {
    max-height: 364px;
  }
}

@container pb (max-width: 950px) {
  .videolist {
    max-height: 364px;
    
  }
}

@container pb (max-width: 750px) {
  .videolist {
    max-height: 274px;
    
  }
}

@container pb (max-width: 550px) {
  .videolist {
    max-height: 364px;
    
  }
}

@container pb (max-width: 500px) {
  .videolist {
    flex-wrap: nowrap;
  }
}


.show.is-more .videolist {
  max-height: 4000px;
}

</style>

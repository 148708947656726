<template>


  <!-- CATEGORY -->

  <a name="categories"></a>

  <div class="cg wd">

    <h2>Nominations</h2>

    <div class="ds desc">
      Please vote only one time in each nomination.
    </div>

    <div class="ct">
      <AwardCategory 
        v-for="category in props.categories" :key="category.id"
        :category="category"
        :cup-id="props.cupId"
        :is-finished="props.isFinished"
        :can-vote="props.canVote"
        />
    </div>

  </div>

  
</template>


<script setup lang="ts">

import type { AwardCategory } from '@/types/award'

interface Props {
  cupId: number
  categories: AwardCategory[]
  isFinished: boolean
  canVote?: boolean
}

const props = defineProps<Props>()

// const votedList = ref({})
// const canVoting = ref(false)


// const voteModal = (categoryId, target) => {
//   // Implement vote modal logic
// }

// const isVoted = (categoryId) => {
//   return votedList.value[categoryId]
// }

</script>
 


<style scoped>
 

.cg{
}
.cg .ds{
    margin-bottom: 2rem;
}
.cg .ct{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
 

@container pc (max-width: 600px) {

  .cg{}
  .cg .ds{}
  .cg .nam{

  }
  .cg .ct{
    flex-wrap: nowrap;
    overflow-x: auto;
    width: calc(100% + 3rem);
    margin-left: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: -2rem;
    gap: 1rem;
  }
  .cg .ct::-webkit-scrollbar {
    display: none;
  } 
}

</style>
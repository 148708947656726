<template>


  <SkBasicForm 
    v-if="userCookiesDataStatus === 'loading'"
    />
 
  <div v-else-if="userCookiesData?.id" class="modal_user_content">

    <div class="image content content_profile" id="modal_user_content">

      <div class="settings_description">

        <div class="row">
          <div class="label">
            {{ $t('profile_menu_edit_profile_ewc') }} 
          </div>
          <div class="field_text">
            {{ $t('profile_menu_edit_profile_ewc_text') }} 
          </div>
          <div class="field_checkbox"> 
          <UiToggle 
            v-model="enabled_global_cookies"
            :disabled="true"
            @update:modelValue="handleSavePrivate"
            />
          </div>
        </div>

        <div class="row">
          <div class="label">
            {{ $t('profile_menu_edit_profile_pfc') }} 
          </div>
          <div class="field_text">
            {{ $t('profile_menu_edit_profile_pfc_text') }} 
          </div>
          <div class="field_checkbox">
            <UiToggle 
              v-model="userCookiesData.enable_secure_performance"
              @update:modelValue="userCookiesData.enable_secure_performance = $event"
            />
          </div>
        </div>

        <div class="row">
          <div class="label">
            {{ $t('profile_menu_edit_profile_acc') }} 
          </div>
          <div class="field_text">
            {{ $t('profile_menu_edit_profile_acc_text') }} 
          </div>
          <div class="field_checkbox">
            <UiToggle 
              v-model="userCookiesData.enable_secure_analytics"
              @update:modelValue="userCookiesData.enable_secure_analytics = $event"
            />
          </div>
        </div>

        <div class="row"  v-if="userCookiesData.has_address">
          <div class="label">
            {{ $t('profile_menu_edit_profile_mpd') }} 
          </div>
          <div class="field_text">
            
            {{ $t('profile_menu_edit_profile_mpd_text') }} 
            
            <div class="presonal_acts">
              <span @click="showModalPassword = true" class="presonal_act">
                <i class="icon-eye"></i>
                Show me
              </span>
              <span @click="handleDeletePersonal" class="presonal_act delete">
                <i class="icon-remove"></i>
                Delete
              </span>
            </div>
                
            <div class="pesonal_list">

              <div  class="personal_row">
                <div class="personal_label">
                  Address 
                </div>
                <div class="personal_val">{{ personalData?.address ?? '●●●●●●●' }}</div>
              </div>

              <div class="personal_row">
                <div class="personal_label">
                  Full Name 
                </div>
                <div class="personal_val">{{ personalData?.fullname ?? '●●●●●●●' }}</div>
              </div>

              <div class="personal_row">
                <div class="personal_label">
                  Phone
                </div>
                <div class="personal_val">{{ personalData?.phone ?? '●●●●●●●' }}</div>
              </div>

            </div>



            <ClientOnly>

              <Teleport to="#app" v-if="showModalPassword">

                <UiModal
                  :title="'Getting access to your personal data'"
                  :close-button-name="'Close'"
                  :primary-button-name="'Continue'"
                  :primary-button-is-loading="isLoadinghandleShowPersonal"
                  :is-auto-height="true"
                  :width="'100%'"
                  :max-width="'400px'"
                  @primary="handleShowPersonal"         
                  @close="showModalPassword = false"         
                  >        


                <UiInput
                  :label="$t('signup_view_password')"
                  :text="password"
                  id="password"
                  type="password"
                  :name="'password'"
                  :autocomplete="'current-password'"
                  :error="errorPassword"
                  @change:text="password = $event"
                  @keyup.enter="handleShowPersonal"
                  />

                   
                </UiModal>
              </Teleport>    
            </ClientOnly>



            
            
          </div>
        </div>
 

        <div class="row">
          <div class="label">
            {{ $t('profile_menu_edit_profile_security_measures') }} 
          </div>
          <div class="field_text">
            
            <ul>
              <li>- {{ $t('profile_menu_edit_profile_mpd_subtext1') }}</li>
              <li>- {{ $t('profile_menu_edit_profile_mpd_subtext2') }}</li>
              <li>- {{ $t('profile_menu_edit_profile_mpd_subtext3') }}</li>
              <li>- {{ $t('profile_menu_edit_profile_mpd_subtext4') }}</li>
              <li>- {{ $t('profile_menu_edit_profile_mpd_subtext5') }}</li>
            </ul>
          </div>
        </div>

        <br />


        <div class="btn primary" @click="handleSaveCookie" type="is-primary">{{ $t('universal_button_save') }}</div>  

      </div>

    </div>

  </div>


</template>

<script setup lang="ts">

import type { User } from '@/types/user'

const { t } = useI18n();
const route = useRoute();

const { $api, $popup } = useNuxtApp()

// const id = ref(route.params['id']);
const enabled_global_cookies = ref(true);
const personalData = ref(null);
const password = ref(null);
const errorPassword = ref(false);
const showModalPassword = ref(null);
const isLoadinghandleShowPersonal = ref(false);

interface Props {
  user: User,
  userId: number,
}

const props = defineProps<Props>();

const handleSavePrivate = function(){

}

const handleSaveCookie = function(){
  $api.patchProfileCookie(userCookiesData.value.id, {
    enable_secure_performance: userCookiesData.value.enable_secure_performance ? 1 : 0,
    enable_secure_analytics: userCookiesData.value.enable_secure_analytics ? 1 : 0
  })
  .then((res) => {
    $popup.success('Saved!')
  })
  .catch(function (error) {

  });
}


const handleShowPersonal = function(){
  isLoadinghandleShowPersonal.value = true;

  $api.postProfilePersonalWithPassword(userCookiesData.value.id, {
    password: password.value
  })
  .then((res) => {
    isLoadinghandleShowPersonal.value = false;
    showModalPassword.value = false;
    personalData.value = res.data.value.data;
  })
  .catch(function (error) {
    isLoadinghandleShowPersonal.value = false;
    $popup.success('Error')
  });
}

const handleDeletePersonal = function(){

  if(!confirm('Are you sure?')){
    return;
  }
  
  useLoadingIndicator().start();
  
  $api.deleteProfilePersonal(userCookiesData.value.id)
  .then((res) => {
    userCookiesData.value.has_address = false;
    $popup.success('Deleted')
    useLoadingIndicator().finish();
  })
  .catch(function (error) {
    useLoadingIndicator().finish();
    $popup.error('Error')
  });
}

const loadData = async function(id: number) {   
  const response = await $api.getGrowerSettings(id, 'cookies')  
  return response;  
}


const { status: userCookiesDataStatus,  data: userCookiesData } = await useLazyAsyncData('userCookiesData', async () => await loadData(props.userId))





</script>

<style scoped>

.content_profile {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
}

.modal_user_content .settings_description {
    display: inline-block;
    vertical-align: middle;
    margin-right: auto;
}

.modal_user_content .settings_description .row{
    display: flex;
    width: 100%;
    margin-top: 15px;
    gap: 1rem;
}

.modal_user_content .settings_description .label{
    display: inline-block;
    min-width: 200px;
    font-weight: bold;
}

.modal_user_content .settings_description .field_text{
    display: inline-block;
    width: 100%;
}

.modal_user_content .settings_description .field_checkbox{

  display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    min-width: 80px;
}
 

@container pb (max-width: 768px) {

  .modal_user_content .ava{
    justify-content: center;
    margin: 0 auto;
  }
  .content_profile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .modal_user_content .settings_avatar{

      margin-left: auto;
      margin-right: auto;
  }

  .modal_user_content .settings_description{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 20px;
  }

  .modal_user_content .settings_description .row{
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .modal_user_content .settings_description .field_checkbox{
    top: 0;
    position: absolute;
    right: 0;
  }

}



/* personal */


.personal_list{
  
}
.personal_row{
  position: relative;
  background: #5353531d;
  padding: 1em 1em;
  line-height: 1.4285em;
  border-radius: 0.28571429rem;
  /* box-shadow: 0px 0px 0px 1px rgb(34 36 38 / 22%) inset, 0px 0px 0px 0px rgb(0 0 0 / 0%); */
  margin-bottom: 0.6rem;
  margin-top: 0.6rem;

  display: flex;
}
.presonal_acts{
  margin-top: 10px;
}
.presonal_act{
  margin-right: 10px;
  cursor: pointer;
  color: gray;
}
.presonal_act.delete{
  color: red;
}

.personal_label{
  width: 100px;
  flex-shrink: 0;
}

.personal_val{
  width: 100%;
}


/*  */


</style>

import { useNuxtApp } from '#app'
import type { Diary, Week, DiaryEquip, DiaryMethod, DiaryNutrient, DiaryMedium, DiaryProgressSteps } from '@/types/diary'
import { DiaryTypeWatering, DiaryTypeRoom, DiaryTypeRoomImage, DiaryTypeMethodShortTr, DiaryTypeMedium, DiaryTypeMediumTr } from '@/types/diary'
import { ProductCategoryImage, ProductCategoryType, ProductLampType, ProductLampTypeImage, ProductLampFaza, ProductCategorySort, ProductLampTypeTrShort } from '@/types/product';
import type { DiaryConditionItemPageProps } from '@/types/other'
import { ProgressStep, UnitVolumeTr } from '@/types/other'


export function useDiary() {
  // const { t } = useI18n()
  const { $api, $popup, $follow, $i18n, $convert } = useNuxtApp()

  const diary = ref<Diary | null>(null)
 
  function setDiary(newDiary: Diary) {
    diary.value = newDiary
  }
 
  async function load(diaryId: number) {
    const data = await $api.getDiary(diaryId)
    diary.value = data
    return diary.value
  }

  async function remove(diaryId: number): Promise<void> {
    if (!confirm('Delete diary?')) return
    try {
      await $api.deleteDiary(diaryId)
      $popup.success('Success')
    } catch (error) {
      $popup.error('Error')
    }
  }
 

  async function removeWeekFromItems(diary: Diary, week: Week) {
    const idx = diary.items_week.findIndex(w => w.id === week.id)
    if (idx !== -1) diary.items_week.splice(idx, 1)
  }

  async function removeWeek(diary: Diary, week: Week) {
    try {
      const confirmed = await useWeek().remove(diary.id, week.id)
      if (!confirmed) {
        return
      }  
      removeWeekFromItems(diary, week)
    } catch (error) {
      $popup.error('Error')  
    }
  }
 
  async function publish(diary: Diary) {

    diary.is_hidden = 0
    try {
      await $api.patchDiary(diary.id, { is_hidden: diary.is_hidden })
      $popup.success('Success')      
    } catch (error) {
      diary.is_hidden = diary.is_hidden ? 0 : 1
      $popup.error('Error')
    }
  }

  async function unpublish(diary: Diary) {
    diary.is_hidden = 1
    try {
      await $api.patchDiary(diary.id, { is_hidden: diary.is_hidden })
      $popup.success('Success')
    } catch (error) {
      diary.is_hidden = diary.is_hidden ? 0 : 1
      $popup.error('Error')
    }
  }
 
  async function follow(diary: Diary) {
    let state = 1
    try {
      await $follow.toggle('diary', diary.id, state, {
        type: 'diary',
        id: diary.id,
        avatar: diary.avatar_small,
        name: diary.name,
        link: diary.link
      })

      diary.addon.follow = state ? 1 : 0
      diary.cnt_followers += state ? 1 : -1
    } catch (error) {
      $popup.error('Error')
    }
  }

  async function unfollow(diary: Diary) {
    let state = 0
    try {
      await $follow.toggle('diary', diary.id, state, {
        type: 'diary',
        id: diary.id,
        avatar: diary.avatar_small,
        name: diary.name,
        link: diary.link
      })

      diary.addon.follow = state ? 1 : 0
      diary.cnt_followers += state ? 1 : -1
    } catch (error) {
      $popup.error('Error')
    }
  }


  function getWeek(diary: Diary, weekId: number | null): Week | null {
    if(!weekId) return null
    console.log('diary.items_week');
    console.log(typeof weekId);
    console.log(weekId);
    console.log('diary.items_week', diary.items_week);
    // console.log(diary.items_week.find(w => w.id == weekId));
    return diary.items_week.find(w => w.id === weekId) || null
  }



  function calculateProgress(diary: Diary): DiaryProgressSteps{
    const seedList = diary.items_equip?.filter((item: DiaryEquip) => item?.category === ProductCategoryType.Seed && item.is_remove !== 1)
    const lampVeg = diary.items_equip?.filter((item: DiaryEquip) => item && item.props?.faza === ProductLampFaza.Vegetation && item?.category === ProductCategoryType.Lamp && item.is_remove !== 1)
    const lampFlo = diary.items_equip?.filter((item: DiaryEquip) => item && item.props?.faza === ProductLampFaza.Flowering && item?.category === ProductCategoryType.Lamp && item.is_remove !== 1)
    const extraEqups = diary.items_equip?.filter((item: DiaryEquip) => item && item?.category !== ProductCategoryType.Lamp && item?.category !== ProductCategoryType.Seed && item.is_remove !== 1)

    let ret = {
      name: ProgressStep.Bad,
      strains: ProgressStep.Bad,
      setup: ProgressStep.Bad,
    } as DiaryProgressSteps;

    if (diary.name) ret.name = ProgressStep.Good;

    if (seedList?.length) ret.strains = ProgressStep.Good;

    let setup = 0;

    if (diary.items_medium?.length) setup++

    if ((diary.type_room === DiaryTypeRoom.Indoor || diary.type_room === DiaryTypeRoom.Greenhouse) && (lampVeg?.length || lampFlo?.length)) {
      setup++
    }

    if ((diary.type_room === DiaryTypeRoom.Indoor || diary.type_room === DiaryTypeRoom.Greenhouse) && extraEqups?.length) {
      setup++
    }

    if (diary.type_room) {
      diary.type_room === DiaryTypeRoom.Outdoor ? setup += 4 : setup += 2
    }

    if (setup < 1) ret.setup = ProgressStep.Bad;
    else if (setup >= 1 && setup <= 4) ret.setup = ProgressStep.Normal;
    else ret.setup = ProgressStep.Good;

    return ret;
  }


  function getItemsEquip(diary: Diary, categoryType: ProductCategoryType, faza: number | undefined = undefined): DiaryEquip[] {
    
    if (!diary?.items_equip?.length && !diary?.items_medium) return []

    if (categoryType === ProductCategoryType.Lamp && faza === ProductLampFaza.Flowering) {
      return diary.items_equip?.filter((item: DiaryEquip) => item?.props?.faza === ProductLampFaza.Flowering && item?.category === categoryType && !item.is_remove)
    }

    if (categoryType === ProductCategoryType.Lamp && faza === ProductLampFaza.Vegetation) {
      return diary.items_equip?.filter((item: DiaryEquip) => item?.props?.faza === ProductLampFaza.Vegetation && item?.category === categoryType && !item.is_remove)
    }

    return diary?.items_equip
      ? diary.items_equip?.filter((item: DiaryEquip) => item?.category === categoryType && !item.is_remove)
      : []

  }

  function deleteItemsEquip(diary: Diary, item: DiaryEquip, category: ProductCategoryType) : void {
    diary.items_equip.forEach((el: DiaryEquip) => {
      if (el === item) el.is_remove = 1
    })
  }

  function addItemsEquip(diary: Diary, item: DiaryEquip, category: ProductCategoryType) : void {
    item.showProduct = true
    diary.items_equip.push(item)
  }
 
  
  function changeItemsEquip(diary: Diary, old_item: DiaryEquip, item: DiaryEquip, category: ProductCategoryType) : void {
    if (old_item) {
      diary.items_equip = diary.items_equip.map((el: DiaryEquip) => {
        if (el === old_item) {
          return item
        }
        return el
      })
    } else {
      diary.items_equip.push(item)
    }
  }

 
  function cloneEquip(diary: Diary, item: DiaryEquip) {
    let item_c = JSON.parse(JSON.stringify(item))
    item_c.ref_id = null
    diary.items_equip.push(item_c)
  }

  function copyVegetationLamp(diary: Diary) {
    const itemsToCopy = diary.items_equip
      .filter((item: DiaryEquip) => item?.props?.faza === ProductLampFaza.Vegetation && item?.category === ProductCategoryType.Lamp)
      .map((item: DiaryEquip) => {
        const itemCopy = { ...item, props: { ...item.props, faza: ProductLampFaza.Flowering }, ref_id: null };
        return itemCopy
      })

    diary.items_equip.push(...itemsToCopy);
  }

  function changeItemsMedium(diary: Diary, old_item: DiaryMedium, item: DiaryMedium) : void {
    if (old_item) {
      diary.items_medium = diary.items_medium.map((el: DiaryMedium) => {
        if (el === old_item) {
          return item
        }
        return el
      })
    } else {
      diary.items_medium.push(item)
    }
  }

  function addItemsMedium(diary: Diary, item: DiaryMedium) : void {
    item.showProduct = false
    diary.items_medium.push(item)
  }

  function deleteItemsMedium(diary: Diary, item: DiaryMedium) : void {
    diary.items_medium.forEach((el: DiaryMedium) => {
      if (el === item) el.is_remove = 1
    })
  }
  

  function getItemsMedium(diary: Diary): DiaryMedium[] {

    if (!diary?.items_medium) return []

    return diary?.items_medium
      ? diary.items_medium?.filter((item: DiaryMedium) => !item.is_remove)
      : []
  }

  function changeTypeRoom (diary: Diary, type: DiaryTypeRoom) {
    diary.type_room = Number(type)
  }

  function changeTypeWatering (diary: Diary, type: DiaryTypeWatering) {
    diary.type_watering = Number(type)
  }

  function calculateTotalMedium(diary: Diary): number {
    let total = 0;
    const arr = diary.items_medium

    if (!arr) return total;

    for (var i of arr) {
      total += Number(i.value);
    }

    return total;
  }
 
  function isValidSeedList(diary: Diary): boolean {
    return diary.items_equip?.filter((item: DiaryEquip) => item?.category === ProductCategoryType.Seed && !item.is_remove && (item.id || item.name))?.length > 0
  }

  function fillEmptyProductsName(diary: Diary) {
    diary.items_equip = diary.items_equip.map((item: DiaryEquip) => {
      if (item?.category === ProductCategoryType.Seed && !item.is_remove && !item.id && !item.name) {
        item.name = 'Custom'
      }
      return item
    })
  }
 



  function sortEquips(list: DiaryConditionItemPageProps[]): DiaryConditionItemPageProps[] {
    return list.sort((a: DiaryConditionItemPageProps, b: DiaryConditionItemPageProps) => {
      return ProductCategorySort.indexOf(a.category) - ProductCategorySort.indexOf(b.category);
    });
  }



  function getDiaryWateringVolume (diary: Diary): number {

    if(diary.type_watering !== DiaryTypeWatering.Manual){
      return 0;
    }
    
    var lastVolume = 0;
  
    diary.items_week.forEach((item: Week) => {
      if(item?.props?.watering_volume){
        lastVolume = item.props.watering_volume;
      }
    });
  
    return lastVolume;
  }

  function getDiaryPotSize (diary: Diary): number {

    let maxSize: number = 0;

    diary.items_week.forEach((item: Week) => {
      if(item?.props?.pot_size){
        if(item.props.pot_size > maxSize)
        maxSize = item.props.pot_size;
      }
    });

    return maxSize;
  }
  

  function getLampEquipName (item: DiaryEquip): string {
    if(item.category !== ProductCategoryType.Lamp){
      return '';
    }

    let typeNumber: number = Number(item.props?.type);
    let powerNumber: number = Number(item.props?.power);

    if(isNaN(typeNumber) || isNaN(powerNumber)){
      return '';
    }

    let power = powerNumber ? '/' + powerNumber + 'W' : '';    
    let type = $i18n?.t(ProductLampTypeTrShort[typeNumber as ProductLampType]);
    return ' ' + type + power;
  }

  

  function groupEquips (list: DiaryConditionItemPageProps[]): DiaryConditionItemPageProps[] {

    const grouped: Record<string, DiaryConditionItemPageProps> = {}
    list.forEach(item => {
      const groupKey =
        (item.name || '') +
        (item.name_link || '') +
        (item.hint || '') +
        (item.faza || '') +
        (item.percent || '') +
        (item.hint_link || '') +
        (item.logo || '')
      if (!grouped[groupKey]) {
        grouped[groupKey] = { ...item, count: item.count ?? 1 }
      } else {
        grouped[groupKey].count = (grouped[groupKey].count ?? 1) + (item.count ?? 1)
      }
    })
    list = Object.values(grouped)

    return list

  }

  function headDiarySetup (d: Diary): DiaryConditionItemPageProps[] {



    let list: DiaryConditionItemPageProps[] = [];

    d.items_equip.forEach((item: DiaryEquip) => {

      let logo = item.item_brand.logo_s 
      let name = (item.name ?? '') + getLampEquipName(item)
      let name_link = item.link
      let hint = item.item_brand.name
      let hint_link = item.item_brand.link
      
      if(!item.id && !name){
        logo = item.item_brand.logo_s
        name = item.item_brand.name
        name_link = item.item_brand.link
        hint = $i18n?.t('category_' + item.category)
        hint_link = null
      } 

      let category = item.category
      let count = item.props?.cnt
      let dataProps = item.props
      let faza = item.props?.faza ?? null
      let ref_id = item.ref_id

      list.push(<DiaryConditionItemPageProps>{
        ref_id: ref_id,
        logo: logo,
        name: name,
        name_link: name_link,
        hint: hint,
        hint_link: hint_link,
        category: category,
        count: count,
        dataProps: dataProps,
        faza: faza
      })
    })


    // nutrients
    d.items_nutrient.forEach((item: DiaryNutrient) => {
      list.push(<DiaryConditionItemPageProps>{
        logo: item.logo_s,
        name: item.name,
        name_link: item.link,
        hint: $i18n?.t('diary_info_nutrients'),
        hint_link: item.link,
        category: 'nutrient',
        faza: null,
      })
    })
  
    list = groupEquips(list);
    list = sortEquips(list);
  
    // awards
    d.items_award.forEach((item) => {
      list.push(<DiaryConditionItemPageProps>{
        logo: '/images/trophy/' + item.icon + '.svg',
        name: item.name,
        name_link: item.link,
        hint: item.place,
        faza: null,
      })
    })
  
    // type rooms
    if(d.type_room === DiaryTypeRoom.Indoor){
      list.push(<DiaryConditionItemPageProps>{
        logo: DiaryTypeRoomImage[DiaryTypeRoom.Indoor],
        name: $i18n?.t('universal_type_room_indoor'),
        hint: $i18n?.t('universal_room_type_title'),
        faza: null,
      })
    }
  
    if(d.type_room === DiaryTypeRoom.Outdoor){
      list.push(<DiaryConditionItemPageProps>{
        logo: DiaryTypeRoomImage[DiaryTypeRoom.Outdoor],
        name: $i18n?.t('universal_type_room_outdoor'),
        hint: $i18n?.t('universal_room_type_title'),
        faza: null,
      })
    }
  
    if(d.type_room === DiaryTypeRoom.Greenhouse){
      list.push(<DiaryConditionItemPageProps>{
        logo: DiaryTypeRoomImage[DiaryTypeRoom.Greenhouse],
        name: $i18n?.t('universal_type_room_greenhouse'),
        hint: $i18n?.t('universal_room_type_title'),
        faza: null,
      })
    }
  
    // type methods
    d.items_method.forEach((item: DiaryMethod) => {
      list.push(<DiaryConditionItemPageProps>{
        icon: 'ic ic-method-' + item.id,
        name: DiaryTypeMethodShortTr[item.id] ? $i18n?.t(DiaryTypeMethodShortTr[item.id]) : item.custom,
        hint: $i18n?.t('create_week_view_number_plural') + ' ' + weeksCroped(item?.weeks),
        faza: null,
      })
    })
  
    // mediums
    d.items_medium.forEach((item: DiaryMedium) => {
      list.push(<DiaryConditionItemPageProps>{
        icon: 'ic ic-medium-' + item.id,
        name: item.id === DiaryTypeMedium.Other && item.name ? item.name : $i18n?.t(DiaryTypeMediumTr[item.id]),
        hint: $i18n?.t('diary_info_grow_medium'),
        percent: item.value,
        faza: null,
      })
    })
  
    // pot size
  
    let maxSize = useDiary().getDiaryPotSize(d);
    if(maxSize){
      list.push(<DiaryConditionItemPageProps>{
        icon: 'ic ic-pot-size',
        name: $convert.view('volume', useAuth().getVolume(), maxSize, 0) + ' ' + $i18n?.t(UnitVolumeTr[useAuth().getVolume()]),
        hint: $i18n?.t('conditions_pot_size'),
        faza: null,
      })
    }
    
    // watering volume
    let volume = useDiary().getDiaryWateringVolume(d);
    if(volume){
      list.push(<DiaryConditionItemPageProps>{
        icon: 'ic ic-watering-manual',
        name: $convert.view('volume', useAuth().getVolume(), volume) + ' ' + $i18n?.t(UnitVolumeTr[useAuth().getVolume()]),
        hint: 'Watering',
        faza: null,
      })
    } 

    
    
    return list
  }
 
  
  function weeksCroped(arr: string[]): string {

    if(!arr.length){
      return $i18n?.t('diary_info_technique');
    }

    if(!arr[0]){
      return $i18n?.t('diary_info_technique');
    }

    arr = arr.filter((item) => {
      return item
    });

    arr = arr.map((item) => {
      return parseInt(item);
    });

    arr = arr.sort((a, b) => a - b);

    let result = [];
    let start = arr[0];

    for (let i = 1; i <= arr.length; i++) {
      if (arr[i] !== arr[i - 1] + 1 || i === arr.length) {
        if (start !== arr[i - 1]) {
          result.push(`${start}-${arr[i - 1]}`);
        } else {
          result.push(`${start}`);
        }
        start = arr[i];
      }
    }

    return result.join(', ');
  }


  function getEquipCover(name: string | null, type: string, subtype?: number | null): string {
    const lampSubtype = Number(subtype);
    let ret = '';

    name = name ?? '';

    if (!name?.length) {
      name = 'Cs';
    }

    if (type === ProductCategoryType.Seed) {
      const baseUrl = 'https://bucket.growdiaries.com/static/ct-lg/';
      let sanitized = name.replace(/[-\s]/g, '');
      sanitized = sanitized.replace(/([A-Z])/g, '_$1').replace(/^_/, '');
      const parts = sanitized.split('_').map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase());
      let formattedName = parts.join('').substring(0, 2);
      formattedName = formattedName.charAt(0).toUpperCase() + formattedName.slice(1).toLowerCase();
      const fileName = `${formattedName}_m.webp`;
      ret = `${baseUrl}${fileName}`;
    } else if (type === ProductCategoryType.Lamp) {
      ret = isNaN(lampSubtype) ? ProductLampTypeImage[ProductLampType.Fl] : ProductLampTypeImage[lampSubtype as ProductLampType];
    } else if (type in ProductCategoryImage) {
      ret = ProductCategoryImage[type as ProductCategoryType];
    }

    return ret;
  }



 
  return {

    diary,
    setDiary,
    load,

    calculateTotalMedium,
    isValidSeedList,
    fillEmptyProductsName,
    getEquipCover,
    getLampEquipName,
    headDiarySetup,

    changeTypeRoom,
    changeTypeWatering,

    getItemsEquip,
    addItemsEquip,
    changeItemsEquip,
    deleteItemsEquip,
    cloneEquip,
    copyVegetationLamp,

    getItemsMedium,
    addItemsMedium,
    changeItemsMedium,
    deleteItemsMedium,
    sortEquips,
    getDiaryPotSize,
    getDiaryWateringVolume,

    calculateProgress,
    getWeek,
    remove,
    removeWeek,
    publish,
    unpublish,
    follow,
    unfollow
  }
}
<template>

  <div class="pros" v-if="products && products.length">

    <h2 class="divider-sec">
      {{ title }} 
      <nuxt-link class="h2_show_all" :to="link">{{ showmore }}</nuxt-link>
    </h2>

    <div class="proslist" v-if="products">
      <template v-for="pro in products">
        <BrandProductItem
          :nm="pro.name"
          :nmalt="pro.name"
          :avatar="pro.cover_s"
          :link="pro.link"
          :rate="pro.avg_rate"
          :rateof="props.rateof"
          :cnt_harvests="pro.cnt_harvests"
          :cnt_diaries="pro.cnt_diaries"
          />
      </template>
    </div>
  </div>

</template>

<script setup>

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  showmore: {
    type: String,
    required: true
  },
  link: {
    type: String,
    required: true
  },
  products: {
    type: Array,
    required: true
  },
  rateof: {
    type: Number,
    required: false,
    default: 10
  }
})
  



 
</script>

<style scoped>
 
 
 

.proslist {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.proslist.centered {
  justify-content: center;
}


</style>

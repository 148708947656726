export default defineNuxtPlugin(nuxtApp => {  

  const t = nuxtApp.$i18n.t;
  
  const plurals = {
 
      sets: {
        "award": [
          t('universal_plurals_award'),
          t('universal_plurals_awards'),
          t('universal_plurals_awards2')
        ],
        "seed": [
          t('universal_plurals_seed'),
          t('universal_plurals_seeds'),
          t('universal_plurals_seeds2')
        ],
        "week": [
          t('universal_plurals_week'),
          t('universal_plurals_weeks'),
          t('universal_plurals_weeks2')
        ],
        "diary": [
          t('universal_plurals_diary'),
          t('universal_plurals_diaries'),
          t('universal_plurals_diaries2')
        ],
        "grower": [
          t('universal_plurals_grower'),
          t('universal_plurals_growers'),
          t('universal_plurals_growers2')
        ],
        "harvest": [
          t('universal_plurals_harvest'),
          t('universal_plurals_harvests'),
          t('universal_plurals_harvests2')
        ],
        "strain": [
          t('universal_plurals_strain'),
          t('universal_plurals_strains'),
          t('universal_plurals_strains2')
        ],
        "tent": [
          t('universal_plurals_tent'),
          t('universal_plurals_tents'),
          t('universal_plurals_tents2')
        ],
        "review": [
          t('universal_plurals_review'),
          t('universal_plurals_reviews'),
          t('universal_plurals_reviews2')
        ],
        "comment": [
          t('universal_plurals_comment'),
          t('universal_plurals_comments'),
          t('universal_plurals_comments2')
        ],
        "like": [
          t('universal_plurals_like'),
          t('universal_plurals_likes'),
          t('universal_plurals_likes2')
        ],
        "plant": [
          t('universal_plurals_plant'),
          t('universal_plurals_plants'),
          t('universal_plurals_plants2')
        ],
        "product": [
          t('universal_plurals_product'),
          t('universal_plurals_products'),
          t('universal_plurals_products2')
        ],
        "conversation": [
          t('universal_plurals_conversation'),
          t('universal_plurals_conversations'),
          t('universal_plurals_conversations2')
        ],
        "notification": [
          t('universal_plurals_notification'),
          t('universal_plurals_notifications'),
          t('universal_plurals_notifications2')
        ],
        "second": [
          t('universal_plurals_second'),
          t('universal_plurals_seconds'),
          t('universal_plurals_seconds2')
        ],
        "minute": [
          t('universal_plurals_minute'),
          t('universal_plurals_minutes'),
          t('universal_plurals_minutes2')
        ],
        "hour": [
          t('universal_plurals_hour'),
          t('universal_plurals_hours'),
          t('universal_plurals_hours2')
        ],
        "day": [
          t('universal_plurals_day'),
          t('universal_plurals_days'),
          t('universal_plurals_days2')
        ],
        "month": [
          t('universal_plurals_month'),
          t('universal_plurals_months'),
          t('universal_plurals_months2')
        ],
        "year": [
          t('universal_plurals_year'),
          t('universal_plurals_years'),
          t('universal_plurals_years2')
        ],
        "decade": [
          t('universal_plurals_decade'),
          t('universal_plurals_decades'),
          t('universal_plurals_decades2')
        ],
        "solution": [
          t('universal_plurals_solution'),
          t('universal_plurals_solutions'),
          t('universal_plurals_solutions2')
        ],
        "vote": [
          t('universal_plurals_vote'),
          t('universal_plurals_votes'),
          t('universal_plurals_votes2')
        ]
      },
   
      get2FormId: function(num: number) {
        if(!num) return 2;
        return (num > 1) ? 1 : 0;
      },

      get3FormId: function(num: number) {

        num = Math.abs(num) % 100;
        var num_x = num % 10;
        if(num > 10 && num < 20) return 2;
        if(num_x > 1 && num_x < 5) return 1;
        if(num_x == 1) return 0;
        return 2;

      },

      get: function(word: string, number: string | number, toUpper?: boolean) {

        number = Number(number) || 0;

        toUpper = toUpper ? true : false;
        

        toUpper = toUpper ? true : false;

        var lang = t.locale;
        var res = word;

        if(this.sets[word] != undefined){
          if(lang === 'cz' || lang === 'de' || lang === 'ru') res = this.sets[word][this.get3FormId(number)];
          else res = this.sets[word][this.get2FormId(number)];
        }


        if(toUpper) res = res.toUpperCase()



        return res;

      },
      
      getPlaceEN: function(place: number) {
        var p = ['', 'st', 'nd', 'rd'];
        if(p[place] != undefined) return p[place];
          else return 'th';
      },

      getPlaceRU: function(place: number) {
        if(place >= 11 && place <= 19) return 'ый';
        var num_x = place % 10;
        if([0,1,4,5,9].includes(num_x)) return 'ый';
        if([2,6,7,8].includes(num_x)) return 'ой';
        if([3].includes(num_x)) return 'ий';
        return 'й';
      },

      getPlaces: function(place: number) {
        var lang = t.locale;
        var pl = '';
        if(lang == 'ru') pl = place + this.getPlaceRU(place);
          else pl = place + this.getPlaceEN(place);

        return pl;
      }
 

  }



  return {
    provide: {
      plurals: plurals,
    },
  };

})
<template>

  <div class="prd_edit">

    <div class="modal_user_content" v-if="edit_data">

        <div class="settings_description">

            <div expanded>
              <NuxtLink to="/brands-manager/banners" exact class="right_link">&lt; {{ $t('brand_manager_view_tab_banners_back_link') }}</NuxtLink>
            </div>
            <h2>{{ edit_data.id ? $t('brand_manager_view_tab_banners_edit_header_banner_title') : $t('brand_manager_view_tab_banners_new_header_banner_title') }}</h2>

          </div>

          <div style="clear:both">&nbsp;</div>
    
          <form id="form_product">

            <div class="nr">

                <div class="av_header">
                  <img class="main_image_item" :src="edit_data.s_back" data-not-lazy>
                </div>

            </div>

            <div class="nr">

                <div class="nm">

                  <div class="field fup">

                    <n-upload
                      ref="uploadCoverList"
                      :custom-request="submitBanner"
                      :show-file-list="false"
                      >
                      <UiButton
                        :name="$t('brand_manager_view_tab_banners_header_background')"
                        />
                    </n-upload>

                  </div>

                </div>

            </div>

            <div style="clear:both">&nbsp;</div>

            <n-alert v-if="validationErrors.logo_header"
              title="" 
              :show-icon="false"
              :type="validationErrors.logo_header ? 'error' : 'default'"
             >
              {{validationErrors.logo_header ? validationErrors.logo_header.message : ''}}
            </n-alert>

            <div class="nr">

                <div :class="{'av_header_mobile': true, 'is-new-format': isNewFormat}">
                  <img class="main_mobile_image_item" :src="edit_data.s_mob_back" data-not-lazy>
                </div>

            </div>

            <div class="nr">

                <div class="nm">

                  <div class="field fup">
                        
                    <n-upload
                      ref="uploadCoverMobileList"
                      :custom-request="submitBannerMobile"
                      :show-file-list="false"
                      >
                      <UiButton
                        :name="isNewFormat ? ' Creative mobile background (800x500px)' : $t('brand_manager_view_tab_banners_header_mobile_background') "
                        />
                    </n-upload>

                  </div>

                </div>

            </div>

            <div style="clear:both">&nbsp;</div>

            <n-alert v-if="validationErrors.logo_header_mobile"
              title="" 
              :show-icon="false"
              :type="validationErrors.logo_header_mobile ? 'error' : 'default'"
             >
              {{validationErrors.logo_header_mobile ? validationErrors.logo_header_mobile.message : ''}}
            </n-alert>

            <div style="clear:both">&nbsp;</div>


            <h2>{{ $t('brand_manager_view_tab_banners_features') }}</h2>

            <div class="two_fld">

               <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_banners_link') }}</div>
                  <n-input 
                    v-model:value="edit_data.s_link" 
                    type="text" 
                    :value="edit_data.s_link" 
                    @input="validateLink" 
                    :placeholder="$t('brand_manager_view_tab_banners_link')" 
                    :status="validationErrors.link_header ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.link_header"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.link_header ? 'error' : 'default'"
                   >
                    {{validationErrors.link_header ? validationErrors.link_header.message : ''}}
                  </n-alert>
                  
               </div>
                  
               <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_banners_link_mobile') }}</div>
                  <n-input 
                    v-model:value="edit_data.s_mob_link" 
                    type="text" 
                    :value="edit_data.s_mob_link" 
                    @input="validateLinkMobile" 
                    :placeholder="$t('brand_manager_view_tab_banners_link_mobile')" 
                    :status="validationErrors.link_header_mobile ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.link_header_mobile"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.link_header_mobile ? 'error' : 'default'"
                   >
                    {{validationErrors.link_header_mobile ? validationErrors.link_header_mobile.message : ''}}
                  </n-alert>
                  
               </div>

            </div>

            <div class="two_fld">

               <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_banners_region') }}</div>
                  <ClientOnly>
                    <n-select
                      v-model:value="edit_data.items_region"
                      :status="validationErrors.region ? 'is-danger' : ''"
                      filterable
                      multiple
                      :placeholder="$t('brand_manager_view_tab_banners_region')"
                      :options="edit_data.items_allow_region"
                      value-field="name"
                      label-field="name"
                      @update:value="validateRegion()"
                    />
                  </ClientOnly>

                  <n-alert v-if="validationErrors.region"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.region ? 'error' : 'default'"
                   >
                    {{validationErrors.region ? validationErrors.region.message : ''}}
                  </n-alert>
                  
               </div>

               <div class="group50">
                  
                  <div class="label" v-if="edit_data.items_allow_section">{{ $t('brand_manager_view_tab_banners_section') }}</div>
                  <ClientOnly>
                  <n-select v-if="edit_data.items_allow_section"
                    v-model:value="edit_data.items_section"
                    :status="validationErrors.section ? 'is-danger' : ''"
                    filterable
                    multiple
                    :placeholder="$t('brand_manager_view_tab_banners_section')"
                    :options="edit_data.items_allow_section"
                    value-field="id"
                    label-field="name"
                    @update:value="validateSection()"
                  />
                </ClientOnly>

                  <n-alert v-if="validationErrors.section"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.section ? 'error' : 'default'"
                   >
                    {{validationErrors.section ? validationErrors.section.message : ''}}
                  </n-alert>
                  
               </div>

            </div>

            <div class="two_fld">

               <div class="group50">
                  
                  <div class="label">Start Date</div>
                  <input 
                  type="datetime-local" 
                  v-model="edit_data.start_date"
                  />

                  <n-alert v-if="validationErrors.start_date"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.start_date ? 'error' : 'default'"
                   >
                    {{validationErrors.start_date ? validationErrors.start_date.message : ''}}
                  </n-alert>
                  
               </div>
                  
               <div class="group50">

                  <div class="label">End Date</div>
                  <ClientOnly>
                  <input 
                  type="datetime-local" 
                  v-model="edit_data.end_date"
                  />
                 </ClientOnly>

                  <n-alert v-if="validationErrors.start_date"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.start_date ? 'error' : 'default'"
                   >
                    {{validationErrors.start_date ? validationErrors.start_date.message : ''}}
                  </n-alert>
                  
               </div>

            </div>

            <div style="clear:both">&nbsp;</div>

            <div style="display: flex; gap:0.5rem;">
              <UiButton
                :name="$t('universal_button_save')"
                :is-loading="isLoading"
                :type="'primary'"
                @click="saveBanner()"
                />

              <UiButton
                :name="$t('brand_manager_view_tab_banners_preview')"
                :disabled="!edit_data.id && !edit_data.tmp_id"
                @click="isPreviewModalActive = true"
                />

              <UiButton
                :name="$t('brand_manager_view_tab_banners_preview_mobile')"
                :disabled="!edit_data.id && !edit_data.tmp_id"
                @click="isPreviewModalMobileActive = true" 
                />

              <UiButton                
                :tag="'NuxtLink'"
                :to="'/brands-manager/banners'"
                :name="$t('universal_button_cancel')"
                />
  
              <span v-if="edit_data.change_cnt" class="changeset">&nbsp; {{ edit_data.change_cnt + ' ' + $t('brand_manager_view_moderation_mark')}}</span>
            </div>

          </form>

        </div>

        <n-modal
          v-model:show="isPreviewModalActive"
          :mask-closable="false"
          preset="dialog"
          :title="$t('brand_manager_view_tab_banners_preview')"
          content="Are you sure?"
          negative-text="Cancel"
          @negative-click="onNegativeClick"
          style="width: 80%"
        >
          <div class="media-img">
              <figure class="image box" style="width: 100%">
                  <img :src="edit_data.s_back" alt="">
              </figure>
          </div>

          <template #footer>
            
          </template>
        </n-modal>

        <n-modal
          v-model:show="isPreviewModalMobileActive"
          :mask-closable="false"
          preset="dialog"
          :title="$t('brand_manager_view_tab_banners_preview')"
          content="Are you sure?"
          negative-text="Cancel"
          @negative-click="onNegativeClick"
          style="width: 800px"
        >
          <div class="media-img">
              <figure class="image box" style="width: 100%">
                  <img :src="edit_data.s_mob_back" alt="">
              </figure>
          </div>

          <template #footer>
            
          </template>
        </n-modal>

    </div>

</template>

<script setup>

import { NButton, NSelect, NInput, NAlert, NUpload, NUploadDragger, useMessage, NMessageProvider, NModal } from 'naive-ui'
import editBanner from '/pages/brands-manager/func/editBanner.js'
import { useI18n } from "vue-i18n"

const {$api} = useNuxtApp()
const { t } = useI18n()
const message = useMessage()
const route = useRoute();

const props = defineProps({
  edit_data: {
    type: Object
  }
});


const banner = ref('')
const bannerMobile = ref('')
const uploadCoverList = ref(null)
const uploadCoverMobileList = ref(null)
const validationErrors = ref({})
const edit_data = ref(props.edit_data)
const isNewFormat = ref(!edit_data.value?.id || edit_data.value?.add_date > '2024-12-26 00:00:00')
const isLoading = ref(false)
const isPreviewModalActive = ref(false)
const isPreviewModalMobileActive = ref(false)

const submitBanner = (data) => {
  let cover_obj = data.file.file;

  if(cover_obj.type != 'image/jpeg' && cover_obj.type != 'image/png' && cover_obj.type != 'image/heicd'  && cover_obj.type != 'image/webp'){
    message.error(t('brand_manager_view_tab_banners_cover_format_error'));
    uploadCoverList.value.clear();
    return false;
  }

  let reader = new FileReader();
  reader.readAsDataURL(cover_obj);
  reader.onload = evt => {
    let img = new Image();
    img.onload = () => {

      if(img.height  != '700' || img.width != '2700') {
        message.error(t('brand_manager_view_tab_banners_header_background_size_error'));
        uploadCoverList.value.clear();
        return false;
      }
      
      uploadBanner(cover_obj);
    }
    img.src = evt.target.result;
  }

  reader.onerror = evt => {
    console.error(evt);
  }
}

const uploadBanner = async (cover_obj) => {
  let formData = new FormData(); 
  formData.append('file', cover_obj);

  await $api.getB2BUploadAvatar(formData).then(res => {
    if(res){
      banner.value = res.path;
      edit_data.value.s_back = res.full_path;
      uploadCoverList.value.clear();
    }
  })
}

const submitBannerMobile = (data) => {
  let cover_obj = data.file.file;

  if(cover_obj.type != 'image/jpeg' && cover_obj.type != 'image/png' && cover_obj.type != 'image/heicd'  && cover_obj.type != 'image/webp'){
    message.error(t('brand_manager_view_tab_banners_cover_format_error'));
    uploadCoverMobileList.value.clear();
    return false;
  }

  let reader = new FileReader();
  reader.readAsDataURL(cover_obj);
  reader.onload = evt => {
    let img = new Image();
    img.onload = () => {

      if(isNewFormat.value){
        if(img.height != '500' || img.width != '800') {
          message.error('Creative mobile background should be at least 800x500px');
          uploadCoverMobileList.value.clear();
          return false;
        }
      }else{
        if(img.height != '800' || img.width != '800') {
          message.error(t('brand_manager_view_tab_banners_header_background_mobile_size_error'));
          uploadCoverMobileList.value.clear();
          return false;
        }
      }

      
      
      uploadBannerMobile(cover_obj);
    }
    img.src = evt.target.result;
  }

  reader.onerror = evt => {
    console.error(evt);
  }
}

const uploadBannerMobile = async (cover_obj) => {
  let formData = new FormData(); 
  formData.append('file', cover_obj);

  await $api.getB2BUploadAvatar(formData).then(res => {
    if(res){
      bannerMobile.value = res.path;
      edit_data.value.s_mob_back = res.full_path;
      uploadCoverMobileList.value.clear();
    }
  })
}

const validateLink = () => {
  if(!editBanner.validateLink(edit_data.value.s_link)){
    validationErrors.value['link_header'] = { message: t('brand_manager_view_tab_banners_link_format_error') };
  }else{
    delete validationErrors.value['link_header'];
  }
}

const validateLinkMobile = () => {
  if(!editBanner.validateLink(edit_data.value.s_mob_link)){
    validationErrors.value['link_header_mobile'] = { message: t('brand_manager_view_tab_banners_link_format_error') };
  }else{
    delete validationErrors.value['link_header_mobile'];
  }
}

const validateRegion = () => {
  if(!editBanner.validateRegion(edit_data.value.items_region)){
    validationErrors.value['region'] = { message: 'This field must have at least 1 choices' };
  }else{
    delete validationErrors.value['region'];
  }
}

const validateDate = () => {
  if(!editBanner.validateDate(edit_data.value.start_date,edit_data.value.end_date)){
    validationErrors.value['start_date'] = { message: 'Incorrect dates' };
  }else{
    delete validationErrors.value['start_date'];
  }
}

const validateSection = () => {
  if(edit_data.value.items_allow_section?.length){
    if(!editBanner.validateSection(edit_data.value.items_section)){
      validationErrors.value['section'] = { message: 'This field must have at least 1 choices' };
    }else{
      delete validationErrors.value['section'];
    }
  }
}

const validateBanner = () => {
  if(!editBanner.validateBanner(edit_data.value.s_back,banner.value)){
    validationErrors.value['logo_box'] = { message: t('brand_manager_view_tab_banners_cover_format_error') };
  }else{
    delete validationErrors.value['logo_box'];
  }
}

const validateBannerMobile = () => {
  if(!editBanner.validateBanner(edit_data.value.s_mob_back,bannerMobile.value)){
    validationErrors.value['logo_box'] = { message: t('brand_manager_view_tab_banners_cover_format_error') };
  }else{
    delete validationErrors.value['logo_box'];
  }
}

const saveBanner = () => {

  validateBanner();
  validateBannerMobile();
  validateLink();
  validateLinkMobile();
  validateRegion();
  validateDate();
  validateSection();

  if(Object.keys(validationErrors.value).length>0){
    message.error(t('brand_manager_view_generic_error'));
    return false;
  }
  
  var params = {};
  params.id = route.params['id'] || 0;
  params.tmp_id = edit_data.value.tmp_id || 0;
  params.s_back = banner.value;
  params.s_mob_back = bannerMobile.value;
  params.link = edit_data.value.s_link;
  params.link_mobile = edit_data.value.s_mob_link;
  params.start_date = edit_data.value.start_date;
  params.end_date = edit_data.value.end_date;

  params.items_region = [];
  for(var k of edit_data.value.items_region){
    params.items_region.push({name:k});
  }

  params.items_section = [];
  if(edit_data.value.items_allow_section?.length)
    for(var k of edit_data.value.items_section){
      params.items_section.push({name:k});
    }

  saveBannerSend(params);
  //console.log(params);

}

/*const saveBannerSend = async (params) => {
  isLoading.value = true;
  await $api.postB2BSaveBanner(params, 'header').then(res => {
    if(res && res.status == 'success'){
      message.success(t('brand_manager_view_tab_products_save_success'));
      if(params.id>0 || params.tmp_id>0){
        window.setTimeout('location.reload()', 1000);
      }else{
        window.setTimeout(function(){document.location.href='?action=edit_item&tmp_id=' + res.data.tmp_id ;}, 1000);
      }
      
      isLoading.value = false;
    }
  })
}*/

const saveBannerSend = async (params) => {
  isLoading.value = true;
  $api.postB2BSaveBanner(params, 'header').then(res => {
    message.success(t('brand_manager_view_tab_products_save_success'));
    if(res && res.data.value.data.new_id){
      edit_data.value.id = res.data.value.data.new_id;
      window.history.pushState({}, null, res.data.value.data.new_id);
    }else if(res && res.data.value.data.tmp_id){
      edit_data.value.tmp_id = res.data.value.data.tmp_id;
      window.history.pushState({}, null, '0?action=edit_item&tmp_id=' + res.data.value.data.tmp_id);
    }
    isLoading.value = false;
  }).catch(function (error) {
     isLoading.value = false;
     message.error(t('brand_manager_view_generic_error'));
  });
}

/*
import editBanner from '/pages/brands-manager/func/editBanner.js'
import Utils from '/pages/brands-manager/func/utils.js'
  
export default { 
  props: {
    edit_data: {
      type: Object
    }
  },
  data() {
    return { 
      bannerupload: null,
      banner: '',
      banner_mobile: '',
      multipleRegionSelections: [],
      validationErrors: {},
      hasError: 0,
      isLoading: false,
      isPreviewModalActive: false,
      isPreviewModalActiveMobile: false
    }
  },
  created() {

    this.edit_data.s_color = this.edit_data.s_color != '' ? this.edit_data.s_color : 'white';

  },
  methods: {   
    submitBanner: editBanner.submitBanner,
    uploadBanner: editBanner.uploadBanner,
    showNotification: Utils.showNotification,
    showNotificationText: Utils.showNotificationText,
    showErrorNotification: Utils.showErrorNotification,
    showErrorSubNotification: Utils.showErrorSubNotification,
    validateBanner: editBanner.validateBanner,
    validateLink: editBanner.validateLink,
    validateRegion: editBanner.validateRegion,
    validateSection: editBanner.validateSection,
    validate(){
      this.hasError = 0;
      this.validationErrors = {};

      this.validateBanner('header');
      this.validateBanner('header_mobile');
      this.validateLink('header');
      this.validateLink('header_mobile');
      this.validateRegion();
      this.validateSection();
    },
    saveBanner(){

      this.validate();

      if(this.hasError){
        this.showErrorNotification('brand_manager_view_generic_error');
        return false;
      }

      var params = {};
      params.id = this.$route.params.id || 0;
      params.tmp_id = this.edit_data.tmp_id || 0;
      params.s_back = this.banner;
      params.s_mob_back = this.banner_mobile;
      params.link = this.edit_data.s_link;
      params.link_mobile = this.edit_data.s_mob_link;
      params.s_color = this.edit_data.s_color;

      params.items_region = [];
      for(var t in this.edit_data.items_region){
        params.items_region.push({name:this.edit_data.items_region[t]});
      }

      params.items_section = [];
      for(var t in this.edit_data.items_section){
        params.items_section.push({name:this.edit_data.items_section[t]});
      }

      this.isLoading = true;

      var _this = this;
      this.$axios.post('/brands-manager/banners/' + this.$route.params.id + '/header/edit', params)
        .then((res) => {
           if(res.data.code == 200) {
             this.showNotification('brand_manager_view_tab_products_save_success');

             if(res.data.data.new_id){
               this.showNotificationText('Banner uploaded');
               window.setTimeout(function(){document.location.href='/brands-manager/banners/edit/header/' + res.data.data.new_id ;}, 1000);
             }else if(this.$route.params.id>0 || params.tmp_id>0){
               window.setTimeout('location.reload()', 1000);
             }else{
               window.setTimeout(function(){document.location.href='?action=edit_item&tmp_id=' + res.data.data.tmp_id ;}, 1000);
             }

             this.isLoading = false;

           }else{ 
              this.showErrorNotification(); 
              this.isLoading = false;       

           }
        }).catch((error) => {
          //console.log(error);
          //console.log('FAILURE!!');
          _this.showErrorNotification();
          _this.isLoading = false;
        });

    }
  }
}*/
</script>

<style scoped>



/**/
 
</style>

<style>
 
 /* BOX BANNER */

.prd_edit {
  padding: 20px;
  margin-bottom: 100px;
}

.prd_edit .nr {
  display: flex;
}

.prd_edit .nr .av {
  width: 360px;
  margin-right: 2rem;
}

.prd_edit .nr .av img {
  width: 100%;
}

.prd_edit .nr .fup {
  position: relative;
}

.prd_edit .two_fld {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
}

.prd_edit .two_fld .ind_sat {
  margin-bottom: 1rem!important;
}

.prd_edit .two_fld>.field {
  width: 100%;
}


.right_link {
  float: right;
  color: black;
}

.left_link {
  color: #4183C4;
}

.right_link:hover{
  color: black;
}

.changeset {
  color: #FF0000;
  display: inline-flex;
  align-items: center;
}

.words{
  float: right;
  font-weight: bold;
  margin-top: 6px;
}

.av_header {
  width: 800px;
  margin-right: 2rem;
  margin-bottom: 10px;
  top: 2px;
  height: 207px;
}

.main_image_item{
  width: 800px;
  height: 100%;
  border: 1px #cecece solid;
}

.av_header_mobile{
  width: 410px;
  margin-right: 2rem;
  margin-bottom: 10px;
  top: 2px;
  aspect-ratio: 800/800;
}
.av_header_mobile.is-new-format{
  aspect-ratio: 800/500;
}
.main_mobile_image_item{
  width: 410px;
  border: 1px #cecece solid;
  height: 100%;
  object-fit: contain;
}

.center {
  text-align: center;
}

.media-img {
  justify-content: center;
  text-align: center;
  display: flex;
  width: 100%;
}

.header {
  width: 100%;
  box-shadow: none;
}

.header_mobile {
  width: 100%;
  box-shadow: none;
}

.media-img_mobile {
  justify-content: center;
  text-align: center;
  display: flex;
  width: 400px;
  margin: 0 auto;
}

/* UPLOADER */


.load_box{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}
.load_box.background{
  min-height: 200px;
}
.load_box::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}
.load_box input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

.load_box_photos{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}

.load_box_photos::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}
.load_box_photos input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

/* UPLOADER PHOTOS*/

.upload_photos .item{
  float: left;
  width: 100px;
  height: 100px;
  margin-left: 10px;
}

.upload_photos .item_table{
  display: table;
  width: 100px;
  height: 100px;
}

.upload_photos .item_td{
  display: table-cell;
  width: 100px;
  height: 100px;
  vertical-align: middle;
  text-align: center;
}

.upload_photos .item img{
  display: inline-block;
  max-height: 100px;
  max-width: 100px;
}

.upload_photos .delete{
  background-color: rgb(252, 83, 83);
  display: inline-block;
  position: absolute;
  padding: 5px;
  border-radius: 15px;
  margin-top: -5px;
  margin-left: -5px;
  color: white;
  border: 1px white solid;
  cursor: pointer;
}

.two_fld{
  
}

.prd_edit .two_fld>.group50 {
  width: 50%;
}

.prd_edit .two_fld>.group50:first-child {
  padding-right: 20px;
}

.prd_edit .group50>.subgroup50 {
  width: 50%;
  float: left;
}

.prd_edit .group50>.subgroup50:first-child {
  padding-right: 10px;
}

.prd_edit .two_fld .select,.prd_edit .two_fld .select select{
  width: 100%!important;
}

.prd_edit .two_fld .button {
  width: 100%!important;
}



</style>
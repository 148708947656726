<template>

  <!-- ABOUT -->

  <a name="about"></a>

  <div class="ab" v-html="props.description"></div>

  
</template>


<script setup lang="ts">


interface Props {
  description: string
}

const props = defineProps<Props>()

</script>
 


<style scoped>
  


.ab {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 1rem;
}
.ab:deep(p){
padding: 0;
margin: 0;
}
 
@container pc (max-width: 600px) {
  
  .ab {
    
  }

  .ab .about1 {

  }

  .ab .about2 {

  } 
}
 

/* special short-desc class */


.ab:deep(.short-desc){
  display: flex;
  gap: 0.5rem 1.5rem;
}
.ab:deep(.short-desc p){
  width: 50%;
}
@container pc (max-width: 600px) {
  
  .ab:deep(.short-desc){
    flex-direction: column;
  }
  .ab:deep(.short-desc p){
    width: 100%;
  }
}

</style>
<template>

  <NuxtLink 
    :to="props.link" 
    :class="{'video_short': true, 'after': classResult}" 
    
    >
    <div @click.stop.prevent="click">

    <!-- 
      <div :class="'acts ' + (actsLoading ? 'gloading' : '')" v-if="useAuth().getId() == 26">
      <div class="act" 
        @click.stop.prevent="rotate(90)"
        @mouseover="prerotate = 90"
        @mouseleave="prerotate = 0"
        >
        <i class="icon-angle-left"></i>
      </div>
      <div class="act" @click.stop.prevent="rescreen">
        <i class="icon-refresh"></i>
      </div>
      <div class="act" 
        @click.stop.prevent="rotate(-90)"
        @mouseover="prerotate = -90"
        @mouseleave="prerotate = 0"
        >
        <i class="icon-angle-right"></i>
      </div>
    </div> 
    -->

    <img 
      class="cover" 
      :style="{objectFit: (prerotate ? 'contain' : 'cover'), transform: 'rotate(' + (prerotate*-1) + 'deg)'}"
      :src="coverVideo" :alt="name">

    <div class="source">
      <div class="name">{{ name }}</div>
      <div class="week">Week {{ week }}</div>
    </div> 
    </div> 

  </NuxtLink>
  
</template>

<script setup>


const emits = defineEmits(['click']);

const props = defineProps({
  cover: {
    type: String
  },
  videoId: {
    type: Number
  },
  name: {
    type: String
  },
  link: {
    type: String
  },
  week: {
    type: Number
  },
  faza: {
    type: Number
  }
})

const { $api, $popup } = useNuxtApp();

const actsLoading = ref(false);
const coverVideo = ref(props.cover + '?t=' + new Date().getDay());
const prerotate = ref(0);
const classResult = ref(false);

const click = (e) => {
  // alert(7)
  emits('click', e);
}

const rescreen = () => {

  actsLoading.value = true;

  $api.serviceRescreen({
    id: props.videoId,
    type: 'rescreen'
  })
  .then((res) => {
    actsLoading.value = false;
    classResult.value = true;
    coverVideo.value = props.cover + '?t=' + new Date().getTime();
    $popup.success('success');

  })
  .catch(function (error) {
    actsLoading.value = false;
    $popup.error(error);
  });
  
}

const rotate = (angle) => {

  actsLoading.value = true;

  $api.serviceRescreen({
    id: props.videoId,
    type: 'rotate',
    angle: angle
  })
  .then((res) => {
    actsLoading.value = false;
    classResult.value = true;
    coverVideo.value = props.cover + '?t=' + new Date().getTime();
    $popup.success('success');

  })
  .catch(function (error) {
    actsLoading.value = false;
    $popup.error(error);
  });

}

</script>

<style scoped>
  
.video_short{
  width: 230px;
  /* max-width: 230px; */
  /* height: 450px; */
  aspect-ratio: 230/480;
  position: relative;
  flex-shrink: 0;
    contain: paint;
}
.cover{
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  aspect-ratio: 230/400;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: var(--gd-diary-cover-color);
}

.source{
  margin-top: 5px;
  min-height: 42px;
  word-wrap: break-word;
}
.source .name{
  font-weight: bold;

  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.source .week{    
  color: var(--gd-diary-sub-color);
  font-size: 0.8rem;
}
.by{
  font-size: 12px;
  color: #999;
}
.clplay{
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  opacity: 0.56;
}
.clplay i{
  margin-top: 5px;
  text-shadow: 0 0 10px #000000b8;
}

@container pb (max-width: 1540px) {
  .video_short{
    width: calc(100%/7 - 1rem);
  }
}

@container pb (max-width: 1340px) {
  .video_short{
    width: calc(100%/6 - 1rem);
  }
}

@container pb (max-width: 1140px) {
  .video_short{
    width: calc(100%/5 - 1rem);
  }
}

@container pb (max-width: 950px) {
  .video_short{
    width: calc(100%/4 - 1rem);
  }
}

@container pb (max-width: 750px) {
  .video_short{
    width: calc(100%/4 - 1rem);
  }
}

@container pb (max-width: 550px) {
  .video_short{
    width: 150px;
  }
}
.video_short.after .cover{
  border: 4px dashed #f00;
}
.acts{
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  gap: 1px;
  border-radius: 15px;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease;
  width: 100%;
}
.video_short .acts.gloading,
.video_short:hover .acts{
  opacity: 1;
}
.act{
  padding: 5px;
  width: 40px;
  background: #fff;
  cursor: pointer;
  text-align: center;
  color: #000;
  width: 100%;
}
.act:hover{
  background: #f0f0f0;
}
</style>

<template>


    <div ref="odo" class="odo">
      <div
        class="char"
        v-for="(char, char_key) in characters"
        :ref="el => { if (el) setChars(el, char_key) }"
        >
        <div class="rng">&#8226;</div>
        <div
          v-for="(rng, rng_key) in [0,1,2,3,4,5,6,7,8,9]"
          :ref="el => { if (el) setCharsRng(el, char_key, rng_key) }"
          :class="{'rng': true, 'active': rng == char}"
          >
          {{ rng }}
        </div>
      </div>
    </div>


</template>

<script setup>
 

const props = defineProps({
  start: {
    type: Number,
    default: 0
  }
})

const chars = ref([])
const charsRng = ref([])
const odo = ref(null)
const start = ref(props.start)

const characters = computed(() => {
  let string = start.value.toString()
  let list = string.split('')
  list.map((item, index) => {
    list[index] = Number(item)
  })
  return list;
});


const setChars = (el, char_key) => {
  chars.value[char_key] = el
}

const setCharsRng = (el, char_key, rng_key) => {
  charsRng.value[char_key] = charsRng.value[char_key] || []
  charsRng.value[char_key][rng_key] = el
}


const scrollToActive = (el, el_key) => { 
  var active = el.querySelector('.active')
  if(!active?.getBoundingClientRect){
    return
  }
  var activeRect = active.getBoundingClientRect();
  var activeTop = activeRect.top;
  var elRect = el.getBoundingClientRect();
  var elTop = elRect.top;
  var scrollTop = el.scrollTop;

  el.scrollTo({
    top:  activeTop - elTop + scrollTop, 
    behavior: 'smooth'
  });
}

const runScroll = () => {

  chars.value.map((el, el_key) => {
    scrollToActive(el, el_key)
  })
 
}
if(import.meta.client){
setTimeout(() => {
  runScroll()
}, 100)
}

watch(() => props.start, (new_start) => {
  start.value = new_start
  runScroll()
  setTimeout(() => {
    runScroll()
  }, 100)
})
  
</script>

<style scoped>
  

.odo {
  display: flex;
    background-color: var(--un-background-color);
    height: 36px;
    padding: 0 3px;
}

.char{
  display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;
}
.rng{
  padding: 100% 0;
}



</style>

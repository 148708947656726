import type { 
    Diary, 
    DiaryEquip,
    Week,
    WeekProps,
    WeekGermination,
    WeekVegetation,
    WeekHarvest,
    WeekHarvestReview, 
    WeekHarvestReviewSeed, 
} from './Diary.interface'
import { createBrand } from '../brand/Brand.factory'
import { createUser } from '../user/User.factory'
import { createProduct } from '../product/Product.factory'


export function createWeekHarvestReviewSeed(): WeekHarvestReviewSeed {
  return {
    id: null,
    ref_id: null,
    text: null,
    n_general: null,
    props: {},
    item_product: createProduct(),
    item_btand: createBrand(),
  }
}

export function createWeekHarvestReview(): WeekHarvestReview {
  return {
    id: null,
    ref_id: null,
    text: null,
    n_general: null,
    props: {},
    item_product: createProduct(),
    item_btand: createBrand(),
    items_review: [],
  }
}

export function createWeekHarvest(): WeekHarvest {
  return {
    item_review_seed: createWeekHarvestReview(),
    items_review: [],
  }
}

export function createWeekGermination(): WeekGermination {
  return {
    germ_method: { id: null },
    items_seed: [],
    items_nutrient: [],
  }
}

export function createWeekVegetation(): WeekVegetation {
  return {
    items_nutrient: [],
    items_method: [],
  }
}

export function createWeekProps(): WeekProps {
  return {
    germ_date: '',
    height: null,
    ph: null,
    ppm: null,
    air_hum: null,
    co2: null,
    light: null,
    smell: null,
    air_temp: null,
    solution_temp: null,
    night_air_temp: null,
    substrate_temp: null,
    pot_size: null,
    lamp_distance: null,
    light_intensity: null,
    watering_volume: null,
    feeding_schedule: null,
    harvest_weight: null,
    harvest_wet_weight: null,
    harvest_plant: null,
    harvest_watt: null,
    harvest_space: null,
    harvest_smoke: null,
  }
}

export function createWeek(): Week {
  return {
    id: null,
    add_date: null,
    update_date: null,
    days: null,
    faza: null,
    text: null,
    cnt_comments: null,
    cnt_likes: null,
    is_remove: null,
    is_video: null,
    items_photo: [],
    item_germination: createWeekGermination(),
    item_vegetation: createWeekVegetation(),
    item_flowering: createWeekVegetation(),
    item_harvest: createWeekHarvest(),
    props: createWeekProps(),
  }
}

export function createDiary(): Diary {
  return {
    id: null,
    name: null,
    link: null,
    add_date: null,
    update_date: null,
    avatar: null,
    avatar_small: null,
    avatar_little: null,
    max_faza: null,
    is_harvest: null,
    is_video: null,
    n_progress_head: null,
    n_progress: null,
    locale: null,
    cnt_days: null,
    cnt_views: null,
    cnt_seeds: null,
    is_ban: null,
    is_moderate: null,
    date_active: null,
    is_hidden: null,
    is_remove: null,
    is_verify: null,
    is_lock: null,
    cnt_likes: null,
    cnt_followers: null,
    cnt_comments: null,
    type_room: null,
    type_watering: null,
    approved: [],
    items_equip: [],
    items_seed: [],
    items_nutrient: [],
    items_lamp_veg: [],
    items_lamp_flo: [],
    items_tent: [],
    items_medium: [],
    item_user: createUser(),
    items_week: [],
  }
}

<template>
  <nav class="pagination" v-if="totalPages > 1">
    <button
      class="pagination__button"
      :disabled="page <= 1"
      @click="goToPage(page - 1)"
    >
      Prev
    </button>


    <button
      v-if="showFirstPage"
      class="pagination__button"
      :class="{ 'is-active': page === 1 }"
      @click="goToPage(1)"
    >
      1
    </button>
    <span v-if="showLeftEllipsis" class="pagination__ellipsis">…</span>


    <button
      v-for="p in visiblePages"
      :key="p"
      class="pagination__button"
      :class="{ 'is-active': page === p }"
      @click="goToPage(p)"
    >
      {{ p }}
    </button>

    <span v-if="showRightEllipsis" class="pagination__ellipsis">…</span>

    <button
      v-if="showLastPage"
      class="pagination__button"
      :class="{ 'is-active': page === totalPages }"
      @click="goToPage(totalPages)"
    >
      {{ totalPages }}
    </button>

    <button
      class="pagination__button"
      :disabled="page >= totalPages"
      @click="goToPage(page + 1)"
    >
      Next
    </button>
  </nav>
</template>

<script setup lang="ts">

import { computed } from 'vue'
import { defineProps, defineEmits } from 'vue'

interface PaginationProps {
  page: number,
  perPage: number,
  totalItems: number,
  maxVisiblePages: number
}

const props = withDefaults(defineProps<PaginationProps>(), {
  page: 1,
  perPage: 10,
  totalItems: 0,
  maxVisiblePages: 5
})
 
const emits = defineEmits(['update:page'])

const totalPages = computed(() => {
  return props.perPage > 0
    ? Math.ceil(props.totalItems / props.perPage)
    : 1
})


function goToPage(pageNumber: number) {
  const validPage = Math.max(1, Math.min(pageNumber, totalPages.value))
  emits('update:page', validPage)
}


const visiblePages = computed(() => {
  const pages: number[] = []

  const half = Math.floor(props.maxVisiblePages / 2)
  let start = props.page - half
  let end = props.page + half

  if (start < 1) {
    end += 1 - start
    start = 1
  }
  if (end > totalPages.value) {
    start -= end - totalPages.value
    end = totalPages.value
  }


  start = Math.max(start, 1)
  end = Math.min(end, totalPages.value)

  for (let i = start; i <= end; i++) {
    pages.push(i)
  }
  return pages
})

const showFirstPage = computed(() => {
  return visiblePages.value.length && visiblePages.value[0] > 1
})

const showLeftEllipsis = computed(() => {
  return showFirstPage.value && (visiblePages.value[0] > 2)
})

const showLastPage = computed(() => {
  const lastVisible = visiblePages.value[visiblePages.value.length - 1]
  return lastVisible && lastVisible < totalPages.value
})

const showRightEllipsis = computed(() => {
  const lastVisible = visiblePages.value[visiblePages.value.length - 1]
  return showLastPage.value && (lastVisible < (totalPages.value - 1))
})

const page = computed({
  get: () => props.page,
  set: (val: number) => emits('update:page', val)
})
</script>

<style scoped>
.pagination {
  display: inline-flex;
  gap: 4px;
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0rem 0 2rem 0;
}

.pagination__button {
  padding: 0.5rem 0.7rem;
  border: 1px solid var(--un-element-color-gray);
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
}

.pagination__button.is-active {
  background-color: var(--un-firm);
  color: #fff;
  border-color: transparent;
}

.pagination__button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination__ellipsis {
  padding: 6px 10px;
}
</style>
<template>

  <div class="prd_edit">

    <div class="modal_user_content" v-if="edit_data">

        <div expanded>
          <NuxtLink to="/brands-manager/schemes" exact class="right_link">&lt; {{ $t('brand_manager_view_tab_schemes_back_link') }}</NuxtLink>
        </div>
        <h2>{{ $t('brand_manager_view_tab_schemes_edit_scheme') }} - {{ edit_data.name ? edit_data.name : 'New' }}</h2>

        <div style="clear:both">&nbsp;</div>

        <div class="two_fld">

          <div class="label">{{ $t('brand_manager_view_tab_schemes_name') }}</div>
          <n-input
            v-model:value="edit_data.name" 
            type="text" 
            :value="edit_data.name" 
            @input="validateSchemeName" 
            :placeholder="$t('brand_manager_view_tab_schemes_name')" 
            :status="validationErrors.name ? 'error' : ''"
          />

          <n-alert v-if="validationErrors.name"
            title="" 
            :show-icon="false"
            :type="validationErrors.name ? 'error' : 'default'"
           >
            {{validationErrors.name ? validationErrors.name.message : ''}}
          </n-alert>

        </div>

        <div class="two_fld">

          <div v-for="item in edit_data.items_product">

            <n-table :bordered="true" :single-line="false">
      
              <thead>
                <tr>
                  <th>Weeks</th>
                  <th v-for="week of 12">{{ week }}</th>
                  <th></th>
                </tr>
              </thead>
        
              <tr>

                <td class="select-nutrients">
                
                <ClientOnly>
                <n-select
                  v-model:value="item.product_id"
                  filterable
                  :options="edit_data.items_nutrient"
                  value-field="id"
                  label-field="name"
                  @update:value="validateSchemeProducts()"
                />
              </ClientOnly>
                
                </td>
                <td v-for="week of 12">
                   <n-input 
                    v-model:value="item['w'+week]" 
                    type="text"
                    @input="validateSchemeProducts"
                    placeholder=""
                  />
                </td>

                <td>

                  <UiButton
                    :ico="'icon-remove'"
                    :type="'red'"
                    @click="removeSchemeItem(item)"
                    />
                    
                </td>

              </tr>

            </n-table>

          </div>

          <n-alert v-if="validationErrors.products"
            title="" 
            :show-icon="false"
            :type="validationErrors.products ? 'error' : 'default'"
           >
            {{validationErrors.products ? validationErrors.products.message : ''}}
          </n-alert>

        </div>

        <div style="clear:both">&nbsp;</div>

        <div class="is-right">
          
          <UiButton
            :name="$t('brand_manager_view_tab_schemes_add_product_button')"
            @click="addNewScheme()"
            />
             
        </div>

        <div style="clear:both">&nbsp;</div>

        <div class="right_floated">*{{ $t('nut_state_label_mll') }}&nbsp;</div>

        <div style="clear:both">&nbsp;</div>

        <div style="display: flex; gap: 0.5rem;">
          <UiButton
            :name="$t('universal_button_save')"
            :is-loading="isLoading"
            :type="'primary'"
            @click="saveScheme()"
            />

          <UiButton                
            :tag="'NuxtLink'"
            :to="'/brands-manager/schemes'"
            :name="$t('universal_button_cancel')"
            />
              
          <span v-if="edit_data.change_cnt" class="changeset">&nbsp; {{ edit_data.change_cnt + ' ' + $t('brand_manager_view_moderation_mark')}}</span>
        </div>

    </div>

  </div>

</template>

<script setup>

import { NButton, NSelect, NInput, NTable, NAlert, useMessage, NMessageProvider } from 'naive-ui'
import editFuncs from '/pages/brands-manager/func/editScheme.js'
import { useI18n } from "vue-i18n"

const {$api} = useNuxtApp()
const { t } = useI18n()
const message = useMessage()
const route = useRoute();

const props = defineProps({
  edit_data: {
    type: Object
  }
});

const validationErrors = ref({})
const isLoading = ref(false)
const edit_data = ref(props.edit_data)


const addNewScheme = () => {
  var item = {
    id: 0,
    product_id: '',
    w1: '',
    w2: '',
    w3: '',
    w4: '',
    w5: '',
    w6: '',
    w7: '',
    w8: '',
    w9: '',
    w10: '',
    w11: '',
    w12: ''
  }
  edit_data.value.items_product.push(item);
}

const removeSchemeItem = (item) => {
  var index = edit_data.value.items_product.indexOf(item);
  if (index !== -1) {
    edit_data.value.items_product.splice(index, 1);
  }
}

const validateSchemeName = () => {
  var error = editFuncs.validateSchemeName(edit_data.value.name);
  if(error == 1){
    validationErrors.value['name'] = { message: t('brand_manager_view_tab_schemes_name_length_error') };
  }else if(error == 2){
    validationErrors.value['name'] = { message: t('brand_manager_view_tab_schemes_name_format_error') };
  }else{
    delete validationErrors.value['name'];
  }
}

const validateSchemeProducts = () => {
  var error = editFuncs.validateSchemeProducts(edit_data.value.items_product);
  if(error == 1){
    validationErrors.value['products'] = { message: 'This field must have at least 1 choices' };
  }else if(error == 2){
    validationErrors.value['products'] = { message: 'Product must have at least 1 choices' };
  }else if(error == 3){
    validationErrors.value['products'] = { message: t('brand_manager_view_tab_schemes_product_input_error') };
  }else if(error == 4){
    validationErrors.value['products'] = { message: t('brand_manager_view_tab_schemes_product_input_error') };
  }else{
    delete validationErrors.value['products'];
  }
}

const saveScheme = () => {
  
  validateSchemeName();
  validateSchemeProducts();

  if(Object.keys(validationErrors.value).length>0){
    message.error(t('brand_manager_view_generic_error'));
    return false;
  }

  var params = {};
  params.id = route.params['id'] || 0;
  params.tmp_id = edit_data.value.tmp_id || 0;
  params.name = edit_data.value.name;
  params.items_product = edit_data.value.items_product;
  
  saveSchemeSend(params);
  //console.log(params);

}

/*const saveSchemeSend = async (params) => {
  isLoading.value = true;
  await $api.postB2BSaveProductScheme(params.id, params).then(res => {
    if(res && res.status == 'success'){
      message.success(t('brand_manager_view_tab_products_save_success'));
      if(params.id>0 || params.tmp_id>0){
        window.setTimeout('location.reload()', 1000);
      }else{
        window.setTimeout(function(){document.location.href='?action=edit_item&tmp_id=' + res.data.tmp_id ;}, 1000);
      }
      
      isLoading.value = false;
    }
  })
}*/

const saveSchemeSend = async (params) => {
  isLoading.value = true;
  $api.postB2BSaveProductScheme(params.id, params).then(res => {
    message.success(t('brand_manager_view_tab_products_save_success'));
    if(res && res.data.value.data.tmp_id){
      edit_data.value.tmp_id = res.data.value.data.tmp_id;
      window.history.pushState({}, null, '0?action=edit_item&tmp_id=' + res.data.value.data.tmp_id);
    }
    isLoading.value = false;
  }).catch(function (error) {
     isLoading.value = false;
     message.error(t('brand_manager_view_generic_error'));
  });
}

/*
import editFuncs from '/pages/brands-manager/func/editScheme.js'
import Utils from '/pages/brands-manager/func/utils.js'
  
export default { 
  props: {
    edit_data: {
      type: Object
    }
  },
  data() {
    return { 
      validationErrors: {},
      hasError: 0,
      isLoading: false
    }
  },
  created() {

  },
  methods: {
    showNotification: Utils.showNotification,
    showErrorNotification: Utils.showErrorNotification,
    showErrorSubNotification: Utils.showErrorSubNotification,
    validateSchemeName: editFuncs.validateSchemeName,
    validateSchemeProducts: editFuncs.validateSchemeProducts,
    removeSchemeItem: editFuncs.removeSchemeItem,
    addNewScheme: editFuncs.addNewScheme,
    validate(){
      this.hasError = 0;
      this.validationErrors = {};

      this.validateSchemeName();
      this.validateSchemeProducts();
    },
    saveScheme(){

      this.validate();

      if(this.hasError){
        this.showErrorNotification('brand_manager_view_generic_error');
        return false;
      }

      var params = {};
      params.id = this.$route.params.id || 0;
      params.tmp_id = this.edit_data.tmp_id || 0;
      params.name = this.edit_data.name;
      params.items_product = this.edit_data.items_product;

      //console.log(params);return;

      this.isLoading = true;

      var _this = this;
      this.$axios.post('/brands-manager/schemes/edit/' + this.$route.params.id, params )
        .then((res) => {
           if(res.data.code == 200) {   
             this.showNotification('brand_manager_view_tab_products_save_success');

             if(this.$route.params.id>0 || params.tmp_id>0){
               window.setTimeout('location.reload()', 1000);
             }else{
               window.setTimeout(function(){document.location.href='?action=edit_item&tmp_id=' + res.data.data.tmp_id ;}, 1000);
             }
             
             this.isLoading = false;

           }else{ 
              this.showErrorNotification(); 
              this.isLoading = false;

           }
        }).catch((error) => {
          //console.log(error);
          //console.log('FAILURE!!');
          _this.showErrorNotification();
          _this.isLoading = false;
        });

    }
  }
}*/
</script>

<style >

.bold{
  font-weight: bold;
  font-size: 20px!important;
}

/**/
 
</style>

<style>

.prd_edit {
  padding: 20px;
  margin-bottom: 100px;
}

.prd_edit .nr {
  display: flex;
}

.prd_edit .nr .av {
  width: 110px;
  margin-right: 2rem;
  min-height: 110px;
  border: 2px #cecece dashed;
  border-radius: 7px;
  padding: 10px;
}

.prd_edit .nr .av img {
  width: 100%;
}

.prd_edit .nr .nm {
  width: 250px;
}

.prd_edit .nr .fup {
  position: relative;
}

.prd_edit .two_fld {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
}

.prd_edit .two_fld .ind_sat {
  margin-bottom: 1rem!important;
}

.prd_edit .two_fld>.field {
  width: 100%;
}
 

.right_link {
  float: right;
  color: black;
}

.left_link {
  color: #4183C4;
}

.right_link:hover{
  color: black;
}

.right_floated{
  float: right!important;
}

.changeset {
  color: #FF0000;
  display: inline-flex;
  align-items: center;
}

.select-nutrients{
  width: 250px;
}

.is-right{
  float: right;
}

</style>
<template> 



    <UiHorizontalScroll
        :arrows="true"
        :arrows-show-hover="true"
    >  
        <div class="list">


            <!-- <NuxtLink
                v-if="activeCategory"
                :to="RStrainCategoriesLinks[activeCategory]"
                :class="{cat: true, active: true}"
            >
                <img class="ic" :src="RStrainCategoriesImages[activeCategory]" :alt="categoryName(RStrainCategoriesNames[activeCategory])">

                <div class="nm">
                    {{ categoryName(RStrainCategoriesNames[activeCategory]) }}
                </div>
            </NuxtLink> -->


            <NuxtLink
                v-for="(cat) in RPreferredStrainCategories"
                :to="RStrainCategoriesLinks[cat]"
                      class="catl"          
            >
            <div
              :class="{cat: true, active: activeCategory == cat}"
              @click.stop="handleClick($event, cat)"
              >

            
                <img class="ic" :src="RStrainCategoriesImages[cat]" :alt="categoryName(RStrainCategoriesNames[cat])">

                <div class="nm">
                    {{ categoryName(RStrainCategoriesNames[cat]) }}
                    <!-- {{ cat }} -->
                </div>
                </div>
            </NuxtLink>
        </div>

    </UiHorizontalScroll>

</template>


<script setup>

import { strains } from '~/lang/strains/en.js'

import { EStrainCategories, EStrainsFilters, RStrainCategoriesImages, RStrainCategoriesNames, RStrainCategoriesLinks, RPreferredStrainCategories } from '@/types/strain'

const props = defineProps({
    categoryId: {
        type: String,
        required: false,
    },
    categoryVal: {
        type: String,
        required: false,
    },
    isCategorySelected: {
        type: Boolean,
        required: false,
        default: false,
    },
}) 


const emits = defineEmits(['select'])
// const activeCategory = ref(props.categoryId ? props.categoryId + ':' + props.categoryVal : null);


const activeCategory = computed(() => {
  if(!props.categoryId) return 'all:all';
  // if(!props.isCategorySelected) {
  //   return null;
  // }
    return props.categoryId ? props.categoryId + ':' + props.categoryVal : null;
})

const categoryName = (key) => {
    if(!key) return 'All';
    let el = strains?.find(item => item.id == key);
    return el?.name ?? '';
  }


const handleClick = (e, cat) => {
    
  e.preventDefault()
  e.stopPropagation()

  if(activeCategory.value == cat) {
    return;
  }

  emits('select', cat)


}



</script>
 


<style scoped>



.list {
  display: flex;
  align-items: center;
  gap: 0.7rem; 
  padding: 0.4rem;
  margin-bottom: 1rem;
}

.catl {

}

.cat {
  flex: 0 0 auto;            
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 80/100;

  width: 6.5rem;           
  padding: 0.5rem;
  border: 1px solid var(--un-element-color-gray-light);  
  border-radius: 12px;       
  background-color: var(--un-background-color-gray);    
  text-align: center;
  /* box-shadow: 0 0 0 rgba(0,0,0,0);  */
  /* transition: box-shadow 0.2s ease, transform 0.2s ease; */
  cursor: pointer;
  order: 1;
}


.cat:hover {

  /* box-shadow: 0 3px 6px rgba(25, 25, 25, .16); */
  transform: translateY(-2px);
}


.cat.active {
  border-color: var(--un-element-color-gray-light);
  box-shadow: 0 0 0 2px rgba(100, 200, 100, 0.2); 
  transform: scale(1.05); 
  width: 8rem;
}


.ic {  
    width: 3.5rem;
    margin-bottom: 8px;
    aspect-ratio: 1 / 1;
}
.cat.active .ic {
    width: 4rem;
}


.nm {
  font-weight: 600;
  color: #333;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 0.8rem;
}

.cat.active:first-child {
  order: 0;
}



@container pb (max-width: 600px) {
  .list{
    gap: 0.3rem;
  }
  .cat.active {        
    width: 5rem;
    margin: 0 0.2rem;    
  }
  .cat .nm{
    font-size: 0.6rem;
  }
  .cat.active .nm {
    font-size: 0.6rem;
  }
  .cat{
    width: 4.6rem;
  }
  .cat .ic {
    width: 2rem;
  }
  .cat.active .ic {
      width: 3rem;
  }

}

</style>
<template>
  
  <DiaryViewPage
    v-if="!useExperimentsDiaryWide().value"
    />
  <DiarywideViewPage
    v-else
    />

</template>




<script setup>

 
</script>


<style scoped>

 
</style>
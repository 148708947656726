<template>
  <div class="overfl">
    <!-- Plans block -->
    <div class="plans">
      <!-- Currency switcher -->
      <div class="option">
        <div class="currency">
          <div>EUR</div>
          <UiToggle @update:modelValue="switchCurrency" v-model="currency_mode" />
          <div>USD</div>
        </div>
      </div>

      <div class="price">Cost of advertising without plan</div>

      <!-- List of plans -->
      <div
        v-for="(plan, plan_key) in data.plans"
        :key="plan_key"
        :class="{ plan: true, active: choosed_plan.id === plan.id }"
        :style="'background-color: ' + plan.color + ';'"
        @click="choosePlan(plan)"
      >
        <div class="nm">{{ plan.name }}</div>

        <div v-if="plan.discount" class="off">
          -{{ plan.discount }}%
        </div>

        <div class="price">
          <div class="vl">{{ convert(plan.price) }}</div>
          <div class="per">Month</div>
        </div>

        <div class="desc">{{ plan.desc }}</div>

        <div class="btn">Choose plan</div>
      </div>
    </div>

    <!-- Table of groups/options -->
    <div class="groups">
      <div
        v-for="(group, group_key) in groups_calculated"
        :key="group_key"
        class="group"
      >
        <div class="ttl" v-if="group.options?.length">
          <h2>{{ group.name }}</h2>
          <div class="desc">{{ group.desc }}</div>
        </div>

        <div class="tbl">
          <div
            v-for="(option, option_key) in group.options"
            :key="option_key"
            :class="{ option: true, active: option.is_checked_manual }"
          >
            <div :class="{ name: true, clickable: option.ref }">
              {{ option.name }}
              <i
                v-if="option.ref"
                @click="scrollTo(option.ref)"
                class="icon-question"
              ></i>
            </div>

            <div class="price">
              <span v-if="option.price_old !== option.price_new" class="old">
                {{ option.price_old ? convert(option.price_old) : '' }}
              </span>
              <span v-if="option.price_new" class="new">
                {{ convert(option.price_new) }}
              </span>
            </div>

            <!-- Each plan's "period" cells -->
            <template v-for="(plan, plan_key2) in data.plans" :key="plan_key2">
              <div
                class="period"
                :style="'background-color: ' + plan.color + '40;'"
              >
                {{ getPlanOptionPeriod(plan, option) }}
              </div>
            </template>

            <!-- Example for adding manual plus/minus logic:
            <div class="period">
              {{ option.period_name }}
              <div v-if="option.is_enabled_plus" class="bplus" @click="plusChoosedOption(option)">
                Add
              </div>
              <div v-if="option.is_enabled_minus" class="bminus" @click="minusChoosedOption(option)">
                -
              </div>
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Totals block -->
  <div class="total">
    <div class="row subtotal">
      <div class="nm">Monthly:</div>
    </div>

    <div class="row subtotal">
      <div class="nm">{{ choosed_plan.name }}</div>
      <div class="vl">{{ convert(totals_calculated.plan) }}</div>
    </div>

    <div v-if="totals_calculated.advanced" class="row advanced">
      <div class="nm">Options</div>
      <div class="vl">{{ convert(totals_calculated.advanced) }}</div>
    </div>

    <div v-if="totals_calculated.discounted" class="row discounted">
      <div class="nm">Discounted</div>
      <div class="vl">-{{ convert(totals_calculated.discounted) }}</div>
    </div>

    <div class="row all">
      <div class="nm">Total</div>
      <div class="vl">
        {{ convert(totals_calculated.plan + totals_calculated.advanced) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, defineEmits } from 'vue';

/**
 * Interfaces for data structures
 */
interface PlanOption {
  id: string;
  count: number;          // If count < 0, it may indicate special logic (e.g., -1 => "Unlimited")
  period?: string;        // e.g., 'months', 'year' etc.
}

interface Plan {
  id: string;
  name: string;
  color: string;
  price: number;
  discount: number;       // Discount percentage
  months: number;
  desc: string;
  is_edited: boolean;
  options: PlanOption[];
}

interface Group {
  id: string;
  name: string;
  desc: string;
  options: string[];      // Array of option IDs
}

interface BasicOption {
  id: string;
  ref?: string;
  name: string;
  max: number;            // Maximum count
  period: string;         // e.g., "day", "months", "year"
  price: number;
  supported_plan: string[]; // Array of plan IDs that support this option
}

/**
 * ChoosedOptions keeps track of manually added options.
 * key = option ID, value = { count: number }
 */
interface ChoosedOptions {
  [optionId: string]: {
    count: number;
  };
}

/**
 * ExtendedOption is used for displaying in the table (merged logic from BasicOption)
 */
interface ExtendedOption extends BasicOption {
  is_checked?: boolean;            // If the option is included (plan or manual)
  is_checked_plan?: PlanOption;    // If the option is part of the chosen plan
  is_checked_manual?: { count: number }; // If the option is manually added
  is_enabled_plus?: boolean;       // Can we add more?
  is_enabled_minus?: boolean;      // Can we remove some?
  price_old?: number;              // Price before discount or quantity changes
  price_new?: number;              // Price after discount or quantity changes
  period_name?: string;            // A readable period label, e.g., "3 months"
}

/**
 * GroupCalculated is a group with all its ExtendedOptions
 */
interface GroupCalculated {
  id: string;
  name: string;
  desc: string;
  options: ExtendedOption[];
}

/**
 * TotalsCalculated is for summary calculations
 */
interface TotalsCalculated {
  plan: number;       // Base plan price
  discounted: number; // Total discount
  advanced: number;   // Sum for additional options
}

/**
 * CheckoutOrder is for each line in the "cart" or final order
 */
interface CheckoutOrder {
  name: string;
  count: number;
  price: number;
  discount?: number; // Price - discounted
  total: number;     // Final total after discount
}

/**
 * Define emits (TypeScript)
 */
const emits = defineEmits<{
  (e: 'change', order: CheckoutOrder[]): void;
}>();

/**
 * Main data: plans, groups, options
 */
const data = ref({
  plans: [
    {
      id: "trial",
      name: "Trial - 1 month",
      color: "#efd586",
      price: 849,
      discount: 0,
      months: 3,
      desc: "Efficient three-month plan to try out all the main advertising tools.",
      is_edited: false,
      options: [
        { id: "sitewide", count: 3 },
        { id: "email_newsletter", count: 1 },
        { id: "chat_newsletter", count: 1 },
        { id: "gd_business_trial", count: 3, period: "months" },
        { id: "ad_free_trial", count: 3, period: "months" },
        { id: "header_main_trial", count: 7, period: "day" },
        { id: "diary_main_trial", count: 7, period: "day" }
      ]
    },
    {
      id: "lite",
      name: "Lite",
      color: "#82a34f",
      price: 599,
      discount: 0,
      months: 12,
      desc: "Ideal for getting started with basic banners and other adverts.",
      is_edited: true,
      options: [
        { id: "sitewide", count: 12 },
        { id: "email_newsletter", count: 3 },
        { id: "chat_newsletter", count: 3 },
        { id: "gd_business", count: 1 },
        { id: "ad_free", count: 1 },
        { id: "header_group_low", count: 1 },
        { id: "header_group_mid", count: 1 }
      ]
    },
    {
      id: "advanced",
      name: "Advanced",
      color: "#f27420",
      price: 1149,
      discount: 0,
      months: 12,
      desc: "Great variation of essential adverts suitable for any cannabis company.",
      is_edited: true,
      options: [
        { id: "sitewide", count: 12 },
        { id: "email_newsletter", count: 6 },
        { id: "chat_newsletter", count: 6 },
        { id: "gd_business", count: 1 },
        { id: "ad_free", count: 1 },
        { id: "media_tab", count: 1 },
        { id: "custom_contest", count: 4 },
        { id: "article_link", count: 1 },
        { id: "banner_design", count: 1 },
        { id: "header_strain_low", count: 1 },
        { id: "header_group_low", count: 1 },
        { id: "header_strain_mid", count: 1 },
        { id: "header_group_mid", count: 1 }
      ]
    },
    {
      id: "gold",
      name: "Gold",
      color: "#cc9933",
      price: 2499,
      discount: 0,
      months: 12,
      desc: "The best package in terms of number of banners and price for growing companies.",
      is_edited: true,
      options: [
        { id: "sitewide", count: 12 },
        { id: "email_newsletter", count: 9 },
        { id: "chat_newsletter", count: 9 },
        { id: "gd_business", count: 1 },
        { id: "ad_free", count: 1 },
        { id: "media_tab", count: 1 },
        { id: "custom_contest", count: 10 },
        { id: "article_link", count: 2 },
        { id: "banner_design", count: 2 },
        { id: "buy_button", count: 1 },
        { id: "featured_brand", count: 1 },
        { id: "box_banner_recommended", count: 1 },
        { id: "header_diaries_low", count: 1 },
        { id: "header_grower_low", count: 1 },
        { id: "header_breeder_low", count: 1 },
        { id: "header_strain_low", count: 1 },
        { id: "header_group_low", count: 1 },
        { id: "header_diaries_mid", count: 1 },
        { id: "header_grower_mid", count: 1 },
        { id: "header_breeder_mid", count: 1 },
        { id: "header_breeder_high", count: 1 },
        { id: "header_strain_high", count: 1 }
      ]
    },
    {
      id: "platinum",
      name: "Platinum",
      color: "#7c9899",
      price: 3699,
      discount: 0,
      months: 12,
      desc: "Recommended choice for running a vast advertising company.",
      is_edited: true,
      options: [
        // Example: if you have a custom "double" field, add it if needed
        { id: "sitewide", count: 12, period: "months" },
        { id: "email_newsletter", count: 12 },
        { id: "chat_newsletter", count: 12 },
        { id: "gd_business", count: 1 },
        { id: "ad_free", count: 1 },
        { id: "media_tab", count: 1 },
        { id: "custom_contest", count: -1 }, // -1 => Unlimited, as an example
        { id: "article_link", count: 3 },
        { id: "banner_design", count: 3 },
        { id: "buy_button", count: 1 },
        { id: "featured_brand", count: 1 },
        { id: "top_brand", count: 1 },
        { id: "box_banner_recommended", count: 1 },
        { id: "header_diaries_low", count: 1 },
        { id: "header_grower_low", count: 1 },
        { id: "header_breeder_low", count: 1 },
        { id: "header_strain_low", count: 1 },
        { id: "header_group_low", count: 1 },
        { id: "header_diaries_mid", count: 1 },
        { id: "header_grower_mid", count: 1 },
        { id: "header_breeder_mid", count: 1 },
        { id: "header_strain_mid", count: 1 },
        { id: "header_group_mid", count: 1 },
        { id: "header_diaries_high", count: 1 },
        { id: "header_grower_high", count: 1 },
        { id: "header_breeder_high", count: 1 },
        { id: "header_strain_high", count: 1 },
        { id: "header_group_high", count: 1 }
      ]
    }
  ],
  groups: [
    {
      id: "plan",
      name: "Plan Options",
      desc: "",
      options: [
        "sitewide",
        "email_newsletter",
        "chat_newsletter",
        "gd_business",
        "ad_free",
        "media_tab",
        "custom_contest",
        "article_link",
        "banner_design",
        "buy_button",
        "featured_brand",
        "top_brand",
        "box_banner_recommended",
        "gd_business_trial",
        "ad_free_trial",
        "header_main_trial",
        "diary_main_trial"
      ]
    },
    {
      id: "low",
      name: "Low season options",
      desc: "January / July / August / September / October",
      options: [
        "header_main_low",
        "header_grower_low",
        "header_group_low",
        "header_strain_low",
        "header_diaries_low",
        "header_breeder_low"
      ]
    },
    {
      id: "mid",
      name: "Mid season options",
      desc: "February / June / November / December",
      options: [
        "header_main_mid",
        "header_grower_mid",
        "header_group_mid",
        "header_strain_mid",
        "header_diaries_mid",
        "header_breeder_mid"
      ]
    },
    {
      id: "high",
      name: "High season options",
      desc: "March / April / May",
      options: [
        "header_main_high",
        "header_grower_high",
        "header_group_high",
        "header_strain_high",
        "header_diaries_high",
        "header_breeder_high"
      ]
    }
  ],
  options: [
    {
      id: "header_main_trial",
      ref: "header_main",
      name: "Header banner - main page [TRIAL]",
      max: 1,
      period: "day",
      price: 0,
      supported_plan: ["trial"]
    },
    {
      id: "diary_main_trial",
      ref: "diary_main",
      name: "Header banner - diary page [TRIAL]",
      max: 1,
      period: "day",
      price: 0,
      supported_plan: ["trial"]
    },
    {
      id: "gd_business_trial",
      ref: "gd_business",
      name: "GD Business [TRIAL]",
      max: 3,
      period: "months",
      price: 0,
      supported_plan: ["trial"]
    },
    {
      id: "ad_free_trial",
      ref: "ad_free",
      name: "Brand Page Ad Free [TRIAL]",
      max: 3,
      period: "months",
      price: 0,
      supported_plan: ["trial"]
    },
    {
      id: "sitewide",
      ref: "sitewide",
      name: "Sitewide banners",
      max: 12,
      period: "months",
      price: 399,
      supported_plan: [
        "trial",
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "email_newsletter",
      ref: "email_newsletter",
      name: "Email Newsletter",
      max: 12,
      period: "times",
      price: 200,
      supported_plan: [
        "trial",
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "chat_newsletter",
      ref: "",
      name: "Chat Newsletter",
      max: 12,
      period: "times",
      price: 200,
      supported_plan: [
        "trial",
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "gd_business",
      ref: "gd_business",
      name: "GD Business",
      max: 1,
      period: "year",
      price: 1500,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "ad_free",
      ref: "ad_free",
      name: "Brand Page Ad Free",
      max: 1,
      period: "year",
      price: 0,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "media_tab",
      ref: "media_tab",
      name: "Brand Page Photos & Videos Tab",
      max: 1,
      period: "year",
      price: 1200,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "custom_contest",
      ref: "custom_contest",
      name: "Custom contest + Sidebar banner",
      max: 12,
      period: "months",
      price: 400,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "article_link",
      ref: "article_link",
      name: "Article + SEO link",
      max: 2,
      period: "half-year",
      price: 800,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "banner_design",
      ref: "",
      name: "Banner Designs",
      max: 1,
      period: "set",
      price: 150,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "buy_button",
      ref: "buy_button",
      name: "Buy Button",
      max: 1,
      period: "year",
      price: 1000,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "top_brand",
      ref: "top_brand",
      name: "Top in the list of brand categories",
      max: 1,
      period: "year",
      price: 700,
      supported_plan: ["platinum", "platinum_plus"]
    },
    {
      id: "featured_brand",
      ref: "featured_brand",
      name: "Featured Brand",
      max: 1,
      period: "year",
      price: 1000,
      supported_plan: ["gold", "platinum", "platinum_plus"]
    },
    {
      id: "box_banner_recommended",
      ref: "box_banner_recommended",
      name: "Box banner on main page in the recommended",
      max: 1,
      period: "year",
      price: 1000,
      supported_plan: ["gold", "platinum", "platinum_plus"]
    },
    {
      id: "header_main_low",
      ref: "header_main",
      name: "Header banner - main page",
      max: 5,
      period: "months",
      price: 1200,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_main_mid",
      ref: "header_main",
      name: "Header banner - main page",
      max: 4,
      period: "months",
      price: 2500,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_main_high",
      ref: "header_main",
      name: "Header banner - main page",
      max: 3,
      period: "months",
      price: 3500,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_diaries_low",
      ref: "header_main",
      name: "Header banner - diaries",
      max: 5,
      period: "months",
      price: 1800,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_diaries_mid",
      ref: "header_main",
      name: "Header banner - diaries",
      max: 4,
      period: "months",
      price: 3600,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_diaries_high",
      ref: "header_main",
      name: "Header banner - diaries",
      max: 3,
      period: "months",
      price: 5000,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_grower_low",
      ref: "header_main",
      name: "Header banner - growers",
      max: 5,
      period: "months",
      price: 900,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_grower_mid",
      ref: "header_main",
      name: "Header banner - growers",
      max: 4,
      period: "months",
      price: 1800,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_grower_high",
      ref: "header_main",
      name: "Header banner - growers",
      max: 3,
      period: "months",
      price: 2500,
      supported_plan: [
        "lite",
        "advanced",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_group_low",
      ref: "header_main",
      name: "Header banner - 5 pages: questions, journal, nutrients, tents, lights",
      max: 5,
      period: "months",
      price: 500,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_group_mid",
      ref: "header_main",
      name: "Header banner - 5 pages: questions, journal, nutrients, tents, lights",
      max: 4,
      period: "months",
      price: 700,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_group_high",
      ref: "header_main",
      name: "Header banner - 5 pages: questions, journal, nutrients, tents, lights",
      max: 3,
      period: "months",
      price: 1500,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_strain_low",
      ref: "header_main",
      name: "Header banner - strains",
      max: 5,
      period: "months",
      price: 1200,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_strain_mid",
      ref: "header_main",
      name: "Header banner - strains",
      max: 4,
      period: "months",
      price: 2500,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_strain_high",
      ref: "header_main",
      name: "Header banner - strains",
      max: 3,
      period: "months",
      price: 3500,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_breeder_low",
      ref: "header_main",
      name: "Header banner - category Breeder",
      max: 5,
      period: "months",
      price: 900,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_breeder_mid",
      ref: "header_main",
      name: "Header banner - category Breeder",
      max: 4,
      period: "months",
      price: 1800,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    },
    {
      id: "header_breeder_high",
      ref: "header_main",
      name: "Header banner - category Breeder",
      max: 3,
      period: "months",
      price: 2500,
      supported_plan: [
        "lite",
        "advanced",
        "gold",
        "platinum",
        "platinum_plus"
      ]
    }
  ]
} as {
  plans: Plan[];
  groups: Group[];
  options: BasicOption[];
});

/**
 * The currently selected plan
 */
const choosed_plan = ref<Plan>(data.value.plans[4]);

/**
 * Manually chosen options (additional to the plan)
 */
const choosed_options = ref<ChoosedOptions>({});

/**
 * Currency mode (0 = EUR, 1 = USD)
 */
const currency_mode = ref<number>(0);

/**
 * The current currency string ("EUR" or "USD")
 */
const currency = ref<string>('EUR');

/**
 * Convert price based on the chosen currency
 */
function convert(price: number): string {
  const rate = currency.value === 'USD' ? 1.1 : 1;
  const newPrice = Math.ceil(price * rate);
  return newPrice + (currency.value === 'USD' ? '$' : '€');
}

/**
 * Switch currency when toggled
 */
function switchCurrency(val: boolean): void {
  currency_mode.value = val ? 1 : 0;
  currency.value = currency_mode.value === 0 ? 'EUR' : 'USD';
}

/**
 * Plan selection
 */
function choosePlan(plan: Plan): void {
  choosed_plan.value = plan;
  changeEvent();
}

/**
 * Retrieve a BasicOption by its ID
 */
function getOptionById(id: string): BasicOption | undefined {
  return data.value.options.find((option) => option.id === id);
}

/**
 * Retrieve an option from the chosen plan by ID
 */
function getPlanOptionById(id: string): PlanOption | undefined {
  return choosed_plan.value.options.find((o) => o.id === id);
}

/**
 * Remove a manually chosen option
 */
function deleteChoosedOption(option: BasicOption): void {
  if (choosed_options.value[option.id]) {
    delete choosed_options.value[option.id];
    changeEvent();
  }
}

/**
 * Decrease the count of a manually chosen option
 */
function minusChoosedOption(option: BasicOption): void {
  if (!choosed_options.value[option.id]) return;
  
  const currentCount = choosed_options.value[option.id].count;
  if (currentCount <= 1) {
    delete choosed_options.value[option.id];
  } else {
    choosed_options.value[option.id].count = currentCount - 1;
  }
  changeEvent();
}

/**
 * Increase the count of a manually chosen option
 */
function plusChoosedOption(option: BasicOption): void {
  if (!choosed_options.value[option.id]) {
    choosed_options.value[option.id] = { count: 1 };
  } else {
    choosed_options.value[option.id].count++;
  }
  changeEvent();
}

/**
 * Toggle the manual choice of an option
 */
function toggleChoosedOption(option: BasicOption): void {
  if (choosed_options.value[option.id]) {
    deleteChoosedOption(option);
  } else {
    plusChoosedOption(option);
  }
  changeEvent();
}

/**
 * Calculate discounted price
 */
function getDiscountedPrice(discount: number, price: number): number {
  return price - (price * discount) / 100;
}

/**
 * Emit 'change' event with the final checkout order
 */
function changeEvent(): void {
  emits('change', checkout_order.value);
}

/**
 * Scroll to an element with the given refc
 */
function scrollTo(refName: string): void {
  const el = document.querySelector('*[refc="' + refName + '"]');
  if (el) {
    el.scrollIntoView({ block: 'start' });
    window.scrollBy(0, -60);
  }
}

/**
 * Compute groups and their extended options for display
 */
const groups_calculated = computed<GroupCalculated[]>(() => {
  const result: GroupCalculated[] = [];

  for (const group of data.value.groups) {
    const options: ExtendedOption[] = [];

    for (const optionId of group.options) {
      const opt = getOptionById(optionId);
      if (!opt) continue; // Option not found in the global list

      const opt_plan = getPlanOptionById(optionId);
      const opt_choosed = choosed_options.value[opt.id];

      // Calculate total count = count from plan + count from manual choice
      let total_count = (opt_plan ? opt_plan.count : 0) + (opt_choosed ? opt_choosed.count : 0);

      // If plan has a negative count, treat it as special unlimited logic
      if (opt_plan && opt_plan.count < 0 && opt.max) {
        total_count = opt.max;
      }

      // Generate a period name for display
      let periodName = '';
      if (opt_choosed) {
        periodName = ((opt_plan?.count || 0) + opt_choosed.count) + ' ' + opt.period;
      } else if (opt_plan?.count === -1) {
        periodName = 'Unlimited';
      } else if (opt_plan?.count === -2) {
        periodName = 'Forever';
      } else if (opt_plan?.count) {
        periodName = opt_plan.count + ' ' + (opt_plan.period ?? opt.period);
      } else {
        // default to "1 <period>" if nothing else
        periodName = '1 ' + opt.period;
      }

      // Determine if plus/minus is enabled
      const isEnabledPlus = choosed_plan.value.is_edited && opt.max > total_count;
      const isEnabledMinus = !!opt_choosed;

      // Price calculations (old/new)
      let priceOld: number | undefined;
      let priceNew: number | undefined;
      if (opt_choosed) {
        const totalManual = opt.price * opt_choosed.count;
        priceOld = totalManual;
        priceNew = getDiscountedPrice(choosed_plan.value.discount, totalManual);
      } else {
        priceOld = opt.price;
        priceNew = getDiscountedPrice(choosed_plan.value.discount, opt.price);
      }

      // Create extended option object
      const extendedOpt: ExtendedOption = {
        ...opt,
        is_checked: !!opt_plan || !!opt_choosed,
        is_checked_plan: opt_plan,
        is_checked_manual: opt_choosed,
        is_enabled_plus: isEnabledPlus,
        is_enabled_minus: isEnabledMinus,
        price_old: priceOld,
        price_new: priceNew,
        period_name: periodName
      };

      options.push(extendedOpt);
    }

    result.push({
      id: group.id,
      name: group.name,
      desc: group.desc,
      options
    });
  }

  return result;
});

/**
 * Calculate total amounts: plan price, discount, and advanced options
 */
const totals_calculated = computed<TotalsCalculated>(() => {
  const totals: TotalsCalculated = {
    plan: choosed_plan.value.price,
    discounted: 0,
    advanced: 0
  };

  // If plan discount > 0, apply the logic to calculate plan discount
  if (choosed_plan.value.discount > 0) {
    // The original code uses this formula:
    // discounted += Math.round(100 * planPrice / (100 - discount%) - planPrice);
    totals.discounted += Math.round(
      (100 * choosed_plan.value.price) / (100 - choosed_plan.value.discount) -
        choosed_plan.value.price
    );
  }

  // Calculate additional options cost
  for (const id in choosed_options.value) {
    const option = getOptionById(id);
    if (!option) continue;

    const count = choosed_options.value[id].count;
    const price = option.price * count;
    const discountedPrice = getDiscountedPrice(choosed_plan.value.discount, price);

    totals.advanced += discountedPrice;
    totals.discounted += price - discountedPrice;
  }

  return totals;
});

/**
 * Build the final checkout order
 */
const checkout_order = computed<CheckoutOrder[]>(() => {
  const order: CheckoutOrder[] = [];

  // Add the chosen plan itself
  order.push({
    name: choosed_plan.value.name,
    count: 1,
    price: choosed_plan.value.price,
    total: choosed_plan.value.price
  });

  // Add each manually chosen option
  for (const id in choosed_options.value) {
    const option = getOptionById(id);
    if (!option) continue;

    const count = choosed_options.value[id].count;
    const basePrice = option.price;
    const sum = basePrice * count;
    const totalWithDiscount = getDiscountedPrice(choosed_plan.value.discount, sum);

    order.push({
      name: option.name + ' (' + option.id + ')',
      count,
      price: basePrice,
      discount: sum - totalWithDiscount,
      total: totalWithDiscount
    });
  }

  return order;
});

/**
 * Retrieve the period name for a specific plan-option pair
 */
function getPlanOptionPeriod(plan: Plan, option: BasicOption): string {
  const planOpt = plan.options.find((o) => o.id === option.id);
  if (!planOpt) return '';

  if (planOpt.count === -1) return 'Unlimited';
  if (planOpt.count === -2) return 'Forever';
  if (planOpt.count && option.period) {
    return `${planOpt.count} ${option.period}`;
  }
  return '';
}

/**
 * Trigger the initial change event
 */
changeEvent();
</script>
 
 


<style scoped>

.overfl{
  overflow-x: auto;
  margin: 2.5rem;
  margin-top: 0rem; 
}
.currency{
  display: flex;
  gap: 5px;
}
.currency:deep(.toggle label){
  background-color: var(--un-firm)!important
}
/* PLANS */

.plans{
  display: flex;
  /* align-items: center; */
  /* margin: 0 auto; */
  width: fit-content;
}

.plans .option{
  width: 300px;
  flex-shrink: 0;
  position: sticky;
  left: 0;

  z-index: 1;

  display: flex;
  align-items: center;

  background-color: var(--un-background-color);
}
.plans .price{
  width: 100px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.plans .plan{
width: 180px;
display: flex;
flex-wrap: wrap;
position: relative;
padding: 1.5rem;
cursor: pointer;
color: white;
}
.plans .plan .nm{
width: 100%;
font-weight: bold;
font-size: 1rem;
}
.plans .plan .off{
position: absolute;
top: 2rem;
right: 2rem;
background-color: orange;
color: white;
border-radius: 20px;
padding: 0px 10px;
font-weight: bold;
font-size: 0.8rem;
    margin-top: -7px;
}
.plans .plan .price{
width: 100px;
display: flex;
align-items: center;
margin-top: 10px;
}
.plans .plan .desc{
margin-bottom: auto;
margin-top: 1rem;
/* height: 70px; */
}
.plans .plan .price .vl{
font-size: 1.6rem;
font-weight: bold;
}
.plans .plan .price .per{
margin-left: 10px;
color: white;
opacity: 0.8;
}
.plans .plan .btn{
border: 2px white solid;
border-radius: 10px;
color: white;
width: 100%;
display: flex;
align-items: center;
justify-content: center;
padding: 10px;
font-weight: bold;
cursor:pointer;
margin-top: 1rem;
background-color: transparent;
}

.plans .plan:hover{

}
/* PLANS ACTIVE */

.plans .plan.active{
  background-color: #2a8858;
  color: white;
}

.plans .plan.active .per{
  color:#ffffff8c;
}
.plans .plan.active .btn{
  background-color:white;
  border: 2px transparent solid;
  color: black;
}

@container pc (max-width: 600px) {
  
}

/* GROUPS */

.groups{
  width: fit-content;
}
.groups .group{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.groups .group .ttl{
  display: flex;
  align-items: flex-end;
  margin-bottom: 1rem;
  width: fit-content;
  position: sticky;
  left: 0;
  background-color: var(--un-background-color);
  z-index: 1;
}
.groups .group .ttl h2{
margin-bottom: 0;
}
.groups .group .ttl .desc{
  margin-left: 1rem;
  color: gray;
}
.groups .group .tbl{
  display: flex;
  flex-direction: column;
}
.groups .group .tbl .option{
  display: flex;
  width: 100%;
}
.groups .group .tbl .option > *{
  padding: 5px;
}
.groups .group .tbl .option:not(:last-child){
border-bottom: 2px var(--un-background-color) solid; 
}
.groups .group .tbl .option .ckb{
  width: 70px;
  flex-shrink: 0;
}
.groups .group .tbl .option .name{
  width: 300px; 
  flex-shrink: 0;
  position: sticky;
  left: 0;
  background-color: var(--un-background-color);
  z-index: 1;
}
.groups .group .tbl .option .name.clickable{   
  cursor: pointer;
}
.groups .group .tbl .option .name .icon{
margin-left: 4px;
display: none;
}

.groups .group .tbl .option .name.clickable .icon{   
display: inline-block;
}
.groups .group .tbl .option .period{
  width: 180px;
  flex-shrink: 0;
  position: relative;
}
.groups .group .tbl .option .price{
  width: 100px;
  flex-shrink: 0;
}
.groups .group .tbl .option .price .new{
  color: var(--un-firm);
  font-weight: bold;
}
.groups .group .tbl .option .price .old{
  text-decoration: line-through;
  margin-right: 5px;
}
.groups .group .tbl .option.active .price{
opacity:1;
}

.groups .group .tbl .option .bplus{
display: inline-block;
background-color: var(--un-background-color-gray-light);
border-radius: 20px;
padding: 2px 6px;
font-size: 0.7rem;
margin-left: 1rem;
cursor: pointer;
user-select: none;
    margin-bottom: -1px;
    margin-top: -1px;
}
.groups .group .tbl .option .bminus{
  display: inline-block;
    background-color: var(--un-background-color-gray-light);
    border-radius: 20px;
    padding: 0px 6px 2px 6px;
    font-size: 1rem;
    height: 20px;
    margin-left: 0.3rem;
    min-width: 20px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    position: absolute;
    left: -32px;
    line-height: 1rem;
}

.groups .group .tbl .option .bminus:hover,
.groups .group .tbl .option .bplus:hover{
background-color: #2a8858;
color: white;
}

.groups .ui.toggle.checkbox input:checked ~ .box:before, .ui.toggle.checkbox input:checked ~ label:before{

background-color: #2a8858 !important;
}

@container pc (max-width: 600px) {
  
}

/* TOTAL */

.total{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 900px;
  margin: 0 auto;
  padding: 2.5rem;
}
.total .row{
  display: flex;
  min-width: 300px;
}
.total .row .nm{
width:100%;
}

.total .row .vl{
width:100px;
flex-shrink:0;
}
.total .row.all{
font-weight:bold;
font-size: 1.4rem;
margin-top: 0.5rem;
}


.total .row.discounted{
color: #67ad3c;
font-weight: bold;
}
/*  */






@container pc (min-width: 1400px) {
  .overfl{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

@container pc (max-width: 600px) {
  .overfl{
    margin: 0;
    display: inherit;
  }

  .groups .group .tbl .option .name{
    width: 150px;
  }

  .plans .option{
    width: 150px;
  }
}


</style>
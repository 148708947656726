// import { useAuthUser, useAuth, fetchCurrentUser } from "~/composables/useAuth";

export default defineNuxtPlugin(async () => {
  
  
  // const auth_cookie = useCookie("auth:token",{maxAge: 60 * 60 * 24 * 31 * 6});
  
  // if(!auth_cookie.value) {
  //   useAuth().setDefaultUnit();
  //   return;
  // }
    

  // const user = useAuthUser();

  // if (user.value?.id) return;


  // user.value = await fetchCurrentUser();
});
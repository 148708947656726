<template>

  <div class="pros" v-if="list && list.length">

    <h2 class="divider-sec">
      {{ title }} 
      <nuxt-link class="h2_show_all" :to="link">
        {{ showmore }} 
        <!-- ({{ cnt }}) -->
      </nuxt-link>
    </h2>

    <div class="proslist" v-if="list">
      <template v-for="pro in list">
        <GeneralReview
          :id="pro.id" 
          :strain_comment="pro.strain_comment" 
          :comment="pro.comment" 
          :strain-general="pro.strain_general" 
          :post-id="pro.post_id" 
          :add-date="pro.add_date" 
          :strain-resistance="pro.strain_resistance" 
          :strain-growing="pro.strain_growing" 
          :item-diary="pro.item_diary"
          :item-user="pro.item_user"
          :items-product="pro.items_nutrient"
          :item-product="pro.item_product"
        />
      </template>
    </div>
  </div>

</template>

<script>
  
export default {   
  components: {   
  
  },
  props: [
    'title',
    'showmore',
    'limit',
    'list',
    'cnt',
    'link'
  ],
  data () {
    return {            
    }
  },
  computed:{
    firstPhoto(){
      return this.items_photo && this.items_photo.length ? this.items_photo[0] : null;
    }
  },
  created() {    
    // this.query = this.$route.query.q;    
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
 
 

.proslist {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}


.proslist.centered {
  justify-content: center;
}


</style>

<template>



    <div 
      ref="rootEl"
      :data-id="props.week.id"
      :class="'day_item day_faza_' + week.faza + '  post_day_' + week.id + (weekActive?.id == week.id ? ' active ' : '')"                                               
    >

      <!-- <WeekwideViewHarvestTitle
        v-if="props.week.item_harvest"
        :harvest-name="props.week.item_harvest?.item_review_seed.item_product.name"
        :harvest-brand="props.week.item_harvest?.item_review_seed.item_brand.name"
        />  -->


      <div class="week__header">
        <WeekwideViewWeekName 
          :days="props.week.days"
          :faza="props.week.faza"
          :week-id="props.week.id"
        />
        <WeekwideViewActionsEdit 
          :week="props.week" 
          :owner="props.diary.item_user.id" 
          :diary-link="props.diary.link" 
          :is-harvest="props.week.item_harvest ? true : false"
          @remove-week="handleRemoveWeek"
        />
      </div>
 


      <WeekwideViewReview
        :data="props.week.item_harvest"
        :setup="props.week.props"
      /> 




      <WeekwideViewGallerySlider 
        :week="week" 
        :diary="diary"
      />

 

      
      <WeekwideViewCommentSetup
        v-if="props.week.faza != 2"
        :setup="props.week.props"    
        @click="setupShow = true" 
      /> 

      <WeekwideViewNutrientsSetup
        :nutrients="props.week.item_germination?.items_nutrient || week.item_vegetation?.items_nutrient || week.item_flowering?.items_nutrient"
        @click="nutrientsShow = true"
      /> 

      <WeekwideViewHarvestProductReviews
        v-if="props.week.item_harvest?.items_review?.length"
        :data="props.week.item_harvest.items_review"
      />



      <!-- <WeekwideViewComment 
        :week="week" 
        :weeks="diary.items_week"
        :user="diary.item_user"
        :comments="props.comments"
        @click="commentShow = true"
        /> -->


      <WeekwideViewAuthorComment
        :week="props.week"
        :item-user="diary.item_user"
        :week-id="props.week.id"
        :comments="props.comments"
        @click="commentShow = true"
      />

      
      <WeekwideViewGermMethod 
        :item-method="props.week.item_germination?.germ_method" 
      />

      <WeekwideViewMethods 
        :items-method="props.week.item_vegetation?.items_method || props.week.item_flowering?.items_method" 
      />


      <div 
        v-if="problems?.length"
        class="dot-ttl"
      >
          {{ $t('week_view_grow_questions') }}
      </div>
  
      <DiaryViewQuestionsList
        :list="problems"
      />
 

    </div>



    <!--  -->

    <ClientOnly>
      <Teleport to="#teleport"> 

        <CommentWidgetFlowWeek
          v-if="commentShow"
          :title="$t('journal_view_post_comments')"
          :type="'diary'"
          :content="props.week.id"
          :week-text="props.week.text"
          :week-author="props.diary.item_user"
          @close="commentShow = false"
        />              

            
        <WeekwideViewSetupFlow
          v-if="setupShow"
          :title="'Setup'"
          :week="props.week"
          @close="setupShow = false"
        />

            
        <WeekwideViewReviewFlow
          v-if="reviewShow"
          :title="'Review'"
          :week="props.week"
          @close="reviewShow = false"
        />


        <WeekwideViewNutrientsFlow
          v-if="nutrientsShow"
          :title="'Nutrients'"
          :week-nutrients="props.week.item_germination?.items_nutrient || props.week.item_vegetation?.items_nutrient || props.week.item_flowering?.items_nutrient"
          @close="nutrientsShow = false"
        />
          
      </Teleport>
    </ClientOnly>
 
  
</template>

<script setup lang="ts"> 

import type { Diary, Week } from '@/types/diary'

interface PageProps {
  diary: Diary,
  week: Week,
  weekActive: Week | null,
  problems: any,
  comments: any
}
const props = defineProps<PageProps>()
 
const rootEl = ref<HTMLElement | null>(null)
const commentShow = ref(false);
const nutrientsShow = ref(false);
const setupShow = ref(false);
const reviewShow = ref(false);

const emits = defineEmits(['removeWeek', 'updateWeek']);

defineExpose({
  rootEl
})


const handleRemoveWeek = (week: Week) => {

  emits('removeWeek', week)
}

</script>

<style scoped>

.week__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>

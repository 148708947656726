<template>
  <div class="pb grid_width">

    <div class="headerow">  

      <h1 class="ui header" v-if="strain?.name">
        {{ strain.name }} Grow Journals
      </h1>
      <h1 class="ui header" v-else>
        {{ $t('diaries_view_title') }}
      </h1>

      <div v-if="useAuth().isAuth()">
        <div class="btn" v-if="isFollow == 1" @click="handlePresetSave">
          {{ $t('universal_button_follow') }}
        </div> 
        <div class="btn" v-if="isFollow == 2" @click="handlePresetRemove">
          {{ $t('universal_button_unfollow') }}
        </div>
      </div>
 
      <GeneralSearchKeyword
        placeholder="Search Breeders & Nutrients"
        @choose="chooseTagKeyword"
        />

      <ExplorePresets v-if="useAuth().isAuth() || presetName || presetLink"
        :preset-name="presetName"
        :preset-link="presetLink"
        />
      

      <GeneralSearchTags  
        class="filters"
        :tags="tags" 
        :tagsselected="tagsSelected" 
        :tagsdefault="tagsDefault" 
        :tagscount="exploreData?.items_tags"
        :tag-as-link="true"
        :tag-link="'/explore'"
        :modify-path="true"
        :is-show-counts="true"
        :selected-all-by-default="!strain?.id"
        @choose="chooseTags"
        @unchoose="unchooseTags"
        ref="elSearchTags"
        />
 
    </div>


    <div class="sortb">
      <UiSort 
        @choose="chooseSort"
        :val="sort"    
        :options="[
          {id:'update', name: $t('universal_sort_last_update')},      
          {id:'score', name: $t('universal_sort_grow_score')},      
          {id:'likes', name: $t('universal_sort_likes2')},      
          {id:'create', name: $t('universal_sort_creation_date')},      
        ]"
        />
    </div>

      
    <SkExploreDiaries
      v-if="isLoading && !exploreData?.items?.length"
      />
      
    <div v-else-if="exploreData?.items?.length" class="report_boxs reports_grid">      
      <GeneralDiary       
        v-for="(opt,ind) in exploreData.items"    
        :key="ind"    
        :data="opt"      
        />          
      <ClientOnly>        
        <InfinityScroll 
          v-if="exploreData?.items?.length >= limit"
          :is-loading="isLoading"
          ref="elInfinityScroll"/>
      </ClientOnly>
    </div>

    <UiEmpty
      v-else-if="!exploreData?.items?.length"
      />

    <SkExploreDiaries
      v-else
      />
  

  </div>
</template>



<script setup lang="ts">

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

import type { Strain }  from "@/types/strain"

interface PropsPage {
  strain?: Strain
  strainFolder?: string
}

const props = withDefaults(defineProps<PropsPage>(), {
  strainFolder: 'explore'
})

const route = useRoute();
const router = useRouter();
const start = ref<number>(0)
const limit = ref<number>(20)
const isEnded = ref<boolean>(false)
const {$api, $adv, $tagsUtil, $ga, $popup, $patcher} = useNuxtApp()
const { t } = useI18n()
const elInfinityScroll = ref(null)
const elSearchTags = ref(null)

const tags = ref(JSON.parse(JSON.stringify($tagsUtil.tagsExplore)))

const folder = ref(props.strainFolder ?? 'explore')
const strain = ref<Strain | undefined>(props.strain)

const sort = ref(strain.value ? 'likes' : 'update')

const presetName = ref<string>('')
const presetLink = ref<string>('')
const tagsSelected = ref(route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : []))
const tagsDefault = ref($tagsUtil.tagsExplore)
const tagsPermanent = ref([])

const isFollow = ref(0)


if(strain.value?.id){  
  tags.value[strain.value?.section] = {
      id: strain.value?.section,
      name: strain.value?.name,
      trkey: '',
      group: 'g999999',
      is_temp: true,
      is_active: true,
      not_countable: true,
  };
  tagsPermanent.value.push(strain.value?.section);
}


const clearPagination = function(){     
  start.value = 0;  
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;    
  clearPagination();  
}

const chooseTagKeyword = function(tag){     
  if(tagsSelected.value.indexOf('all') != -1)
    tagsSelected.value = [];
  tagsSelected.value.push(tag.tag)
  clearPagination();        
  $ga.searchFullTextExplore($tagsUtil.getTagsKeyword(tags.value, tagsSelected.value));
}

const unchooseTags = function(selected){    
  tagsSelected.value = tagsSelected.value.filter((item) => item != selected);
  clearPagination();
}

const chooseTags = function(selected){   
  if(selected == strain.value?.section){
    strain.value = null;
    folder.value = 'explore'
    tagsPermanent.value = [];
    clearPagination();    
  }else{
    tagsSelected.value.push(selected);      
    clearPagination();    
  }
  $ga.eventGA('explore', 'tag', $tagsUtil.getTagsSimple(tags.value, tagsSelected.value));
}
 

const togglePreset = function(){
  isFollow.value = !tagsSelected.value || tagsSelected.value[0] == 'all' ? 0 : 1;
}


const loadData = async function() {
  
  const response = await $api.getExplore({
    start: start.value,
    limit: limit.value,     
    sortable: sort.value,
    tagsall: 'all', 
    // tags: tagsSelected.value.join(',')
    tags: $tagsUtil.getTagsSimple(tags.value, [...tagsSelected.value, ...tagsPermanent.value]),
    // tagsper: $tagsUtil.getTagsSimple(tags.value, tagsPermanent.value),
  })  
  return response;  
}

const handlePresetRemove = async function() {

}

const handlePresetSave = async function() {
 
  var name = elSearchTags.value.getActiveTagsName()
  var url = '/explore?tags=' + tagsSelected.value.join(',');
  if(name && url){
    $api.postGrowerPresetsExplore(useAuth().getId(), {
      name: name,
      url: url
    })
    .then((res) => {
      $popup.success('Saved');
    })
    .catch(function (error) {
      $popup.success('Something went wrong. Please contact tech support');
    });
   
  }
}

const { pending: isLoading,  data: exploreData } = await useLazyAsyncData('exploreData', async () => await loadData())


watch( () => route.query, (new_route, old_route) => {
  // week_id.value = new_week_id;
  console.log('new_route.params'); 

  tagsSelected.value = route.params['tag']?.length ? route.params['tag'] : ( route.query?.tags ? route.query.tags.split(",") : ['all']);
  // useHead(useNuxtApp().$head.getExplore( ($tagsUtil.getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));
}, {deep: true})


// watchArray([route], async ([new_route], added, removed) => {
//   console.log('watcher route');

//   tagsSelected.value = new_route.params['tag']?.length ? new_route.params['tag'] : ( new_route.query?.tags ? new_route.query.tags.split(",") : ['all']);
//   isFollow.value = 0;
  
// useHead(useNuxtApp().$head.getExplore( ($tagsUtil.getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));

// }, {deep: true})


watchArray([start, sort, tagsSelected, folder], async ([new_start, new_sort, new_tagsSelected, new_folder], added, removed) => {
  console.log('watcher');

  if(new_start == 0){
    exploreData.value.items = [];
    isEnded.value = false;
  } 


  $patcher.change('/' + folder.value, {
    tags: new_tagsSelected,
    sort: new_sort,
  });
  
  isLoading.value = true;
  var dt = await loadData();

  if(dt.length == 0)
    isEnded.value = true;  

  exploreData.value.items = [...exploreData.value.items, ...dt.items]
  if(dt.items_tags?.length)
    exploreData.value.items_tags = dt.items_tags;

  isLoading.value = false;
 
  togglePreset();

}, {deep: true})



useIntersectionObserver(
  elInfinityScroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!isLoading.value && !isEnded.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)



if(strain.value)
  useHead(useNuxtApp().$head.getGrowJournals( strain.value, 1));
else
  useHead(useNuxtApp().$head.getExplore( ($tagsUtil.getTagsSimpleTrKey(tags.value, tagsSelected.value).map((x) => t(x))).join(', ') ));

  

onBeforeUnmount(() => {
  exploreData.value = null;
})


</script>

<style scoped>

.header_row{
  position: relative;
}
.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.sortb{
  margin-left: auto;
  align-self: baseline;
  margin-right: 0;
  display: flex;
  justify-self: flex-end;
  width: fit-content;
}
.filter_icon{
  display: none;
}
.filters{
  display: flex;
}

.headerow{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.headerow > .search{
  margin-left: auto;
}
@media (max-width: 768px) {
  .add_filter_box{
    width: 100%;
  }
.headerow > .search_preset{
  margin-left: auto;
}
  .add_filter_box .search{
    width: 100%;
  }
  .filter_icon{
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1rem;
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  } 

  .headerow{

  }
  .headerow > .search{
    order: 1;
    width: 100%;
  }
  .headerow > .stags{
    order: 0;
    margin: 0;
  }
  .headerow > .sort{
    order: 2;
    justify-content: flex-end;
    width: 100%;
    margin: 0;
  }
   
}

</style>

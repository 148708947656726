<template>
    <div class="filter-icon-buttons">

        <div class="head" @click="emits('close')">
            <div class="name">
                {{ props.name }} 
                <i class="icon-caret-up"></i>
            </div>
        </div>


        <div class="filter-icon-buttons__list">

            <div
                v-for="tag in tags"
                :key="tag.id"
                class="filter-icon-buttons__btn"
                :class="{ 'is-active': isSelected(tag.id) }"
                @click="onSelect(tag.id)"
            >
                <i 
                    v-if="tag.icon" 
                    :class="{'filter-icon-buttons__icon': true, [tag.icon] : true}"
                ></i>
                <img 
                    v-else-if="tag.image" 
                    class="filter-icon-buttons__image"
                    :src="tag.image" 
                    :alt="tag.label" 
                />
                <span class="filter-icon-buttons__label">
                    {{ tag.label }}
                </span>
            </div>
        </div>

    </div>
</template>
  
  <script setup>

  
  const props = defineProps({
    name: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    },
  })
  
  const emits = defineEmits(['update:selected', 'close'])
  
  const isSelected = (tagId) => {
    return props.selected.includes(tagId)
  }
  
  const onSelect = (tagId) => {
    let newSelected = [...props.selected]
    if (isSelected(tagId)) {
        console.log('newSelected-before', newSelected);
        newSelected = newSelected.filter(id => id !== tagId)
        console.log('newSelected-after', newSelected);
        console.log('tagId', tagId);
    } else {
        newSelected.push(tagId)
    }

    
    emits('update:selected', newSelected)
  }

  </script>
  
  <style scoped>
  .filter-icon-buttons {
    /* display: flex;
    gap: 0.5rem;
    flex-wrap: wrap; */
  }
.filter-icon-buttons__list {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    max-height: 180px;
    overflow-y: auto;
    scroll-behavior: smooth;
    padding: 0.2rem;
}
  .filter-icon-buttons__btn {
    border: 1px solid var(--un-element-color-gray-light);
    /* background-color: #fff; */
    cursor: pointer;
    padding: 0.2rem;
    text-align: center;
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
    width: 70px;
    height: 70px;
    border-radius: 5px;    
    background-color: var(--un-background-color-gray);
    
  }
  .filter-icon-buttons__btn.is-active {
    box-shadow: 0 0 0 2px rgba(100, 200, 100, 0.2);
  }
  .filter-icon-buttons__icon {

    margin-bottom: 0.5rem;
  }

  .filter-icon-buttons__image {
    width: 25px;
    aspect-ratio: 1/1;
    object-fit: cover;
    margin-bottom: 0.4rem;
  }


  .filter-icon-buttons__label {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.7rem;
  }


.head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
}

.head .name{
    /* font-weight: bold; */
}


  </style>
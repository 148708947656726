<template>


  <div v-if="solution && solution.id" :class="'solution_item ' + (solution.is_resolve ? ' is_resolve ' : '')">

    <div class="avatar_col">
      <NuxtLink class="avatar" :to="solution.item_user.link">
        <img loading="lazy" :src="solution.item_user.avatar_small" :alt="solution.item_user.name">
        <div :class="'user_range u_range_' + solution.item_user.status"></div>
        <div class="round"></div>
      </NuxtLink>
    </div>
    
    <div class="content_col">
      <div class="top">
        <NuxtLink class="avatar" :to="solution.item_user.link">
          {{ solution.item_user.name }}        
        </NuxtLink>        
        <span class="date">{{ $t('week_view_question_answered_grow_question') }} {{ $dayjs.utc(solution.add_date).fromNow() }}</span>
      </div>

      
      <div class="body"
        ref="refProblemSolutionText"
        v-html="formatCommonText(solution.text)"
        >
      </div>


 

      <div class="bottom"> 
        <div class="status">

          <div :class="{'reply': true, 'active': refIsLiked, 'gloading': likeLoading}" @click="like(solution.id)">
            <i class="icon-leaf"></i> 
            {{ solution.cnt_likes ? solution.cnt_likes : '' }} 
            {{ $plurals.get('like', solution.cnt_likes) }}     
          </div>
          
          <div 
            v-if="useAuth().getId() != solution.item_user.id"
            class="reply" 
            @click="complain(solution.id)">
            <i class="icon-warning-round"></i>
            {{ $t('week_view_question_complain') }}           
          </div>
           
          <div 
            v-if="useAuth().getId() == solution.item_user.id"
            :class="{'reply': true, 'gloading': removeLoading}" 
            @click="remove(solution.id)"
            >
            <i class="icon-remove"></i>
            {{ $t('week_view_question_remove') }}           
          </div>

          <a v-if="solution.is_resolve" class="reply">
            <i class="icon-star"></i>
            {{ $t('week_view_question_selected_by_owener') }}
          </a>      

          <a 
            v-if="!question.is_resolved && useAuth().getId() == question.item_user.id && !solution.is_resolve && useAuth().isAuth() && useAuth().getId() != solution.item_user.id" 
            class="reply" 
            @click="selectSolution(solution)"
            >
            <i class="icon-star"></i>
            {{ $t('week_view_question_select_as_answer') }}
          </a>      
          
          
          <GeneralTranslateButton
            v-if="solution.text && refProblemSolutionText"
            :el="refProblemSolutionText"
            :text="solution.text"
            :id="solution.id"
            :type="'problem_solution'"
            :locale="$i18n.locale"
            />
          
        </div>
      </div>


    </div>
  </div>

</template>

<script setup lang="ts">

import { formatCommonText } from '@/types/other';

const { t } = useI18n();
const { $api, $likes, $popup, $complains } = useNuxtApp()
const emit = defineEmits(['select']);

const props = defineProps({
  solution: {
    type: Object,
    required: true
  },
  question: {
    type: Object,
    required: true
  },
  isLiked: {
    type: Number,
    default: 0
  }
})
const removeLoading = ref(false);
const refProblemSolutionText = ref(null);
const likeLoading = ref(false);
const refIsLiked = ref(props.isLiked);
const solutionEdit = ref(props.solution);

const complain = function(id){

  if(!useAuth().isAuth())
    return useAuth().goToLogin();
  

  if(!confirm(t('chat_complain_confirm_text')))
    return;

   $complains.add('solution', id)
  .then((res) => {                 
    $popup.success('success');
  })
  .catch(function (error) { 
          
  });  
}


const remove = function(id){
  
  if(!useAuth().isAuth())
    return useAuth().goToLogin();

  removeLoading.value = true;

  $api.deleteQuestionSolution(props.question.id, id)
  .then((res) => {          
    $popup.success('Deleted');
    removeLoading.value = false;
    // router.push('/grow-questions');

    props.solution.id = null;
    
  })
  .catch(function (error) { 
    $popup.error('Error deleting');
  });  
}

const selectSolution = function(data){
  emit('select', data);
}
 
 

const like = function(id){

  if(!useAuth().isAuth())
    return useAuth().goToLogin();
  
  likeLoading.value = true;
  $likes.toggle('solution', id, refIsLiked.value)
  .then((res) => {       
    likeLoading.value = false;
    console.log('liked');       
    console.log(res);        
    if(refIsLiked.value){
      props.solution.cnt_likes--;
      refIsLiked.value = 0;
    }else{
      props.solution.cnt_likes++;
      refIsLiked.value = 1;
    }
  })
  .catch(function (error) { 
    likeLoading.value = false;
    console.log('like error');       
    console.log(error);  
    props.solution.cnt_likes--;               
  });  
  
}





</script>

<style scoped>
 
 


.solution_item{
  width: 100%;
}

.solution_item .avatar img{
    border-radius: 500rem!important;
    width: 50px;
    height: 50px;
}



.solution_item {
    background-color: var(--gd-question-back-solution-color);;
    border-radius: 10px;
    padding: 20px 0;
    display: flex;
    align-items: flex-start;
}

.solution_item.is_resolve .question {

}
.solution_item.is_resolve {
  background-color: #ffcc004f;
    padding: 1rem;
}
.solution_item .avatar_col{
    width: 70px;
}
.solution_item .avatar_col .avatar{
  position: relative;
  width: 50px;
  display: inline-block;
  height: 50px;
}
.solution_item .user_range {
        float: left;
    font-size: 1.3rem;
    margin-right: 15px;
    position: absolute;
    /* margin-left: -28px; */
    bottom: -3px;
    right: -19px;
}
.solution_item .content_col{
    width: calc(100% - 100px);
}
.solution_item .content_col .top{
    margin-bottom: 5px;
}
.solution_item .content_col .top .user_name{
    font-weight: bold;
}
.solution_item .content_col .top .date{
    color: var(--gd-question-text-gray-color);
    font-size: 0.875em;
    margin-left: 10px;
}
.solution_item .content_col .body{

  word-wrap: break-word;
    white-space: pre-wrap;

}

.solution_item .content_col .bottom{
    margin-top: 5px;
}
.solution_item .translate,
.solution_item .add_complain,
.solution_item .like_comment,
.solution_item .remove_solution,
.solution_item .pick_solution{
  cursor: pointer;
  display: inline-block;
  margin: 0em 0.75em 0em 0em;
  color:  var(--gd-question-text-gray-color);
  font-size: 0.875em;
}
.solution_item.is_resolve .pick_solution{
  pointer-events: none;
    background-color: #edfae7;
    border-radius: 3px;
    padding: 3px 8px;
    color: var(--gd-question-text-color);
}
.solution_item .unset_resolve{
  pointer-events: auto;
  position: relative;
  left: 6px;
}
.solution_item .status[data-v-51eb01ae] {
    width: 100%;
    font-size: 0.875em;
    margin-top: 1rem;
}
 .solution_item .status > *{
  cursor: pointer;
  display: inline-block;
  margin: 0em 0.75em 0em 0em;
  color: var(--gd-button-small-color);
  position: relative;
}
.solution_item .status > *.active{
  color: var(--gd-button-small-active-color);
}
</style>

<template>

  <div 
    :class="'show ' + (platform) + ' ' + (isMore ? ' is-more' : '') + (isActive ? ' active' : '')" 
    :style="'max-height: ' + (props.maxHeight + (platform == 'none' ? 0 : 30)) + 'px;'"
    ref="elShow"
    >

    <div ref="elShowCont" class="cont">
      <slot></slot>
    </div>
    <div v-if="!isMore" @click="toggle" class="toggler more">Show more <i class="icon-caret-down"></i></div>
    <div v-else @click="toggle" class="toggler less">Show less <i class="icon-caret-up"></i></div>
  </div>
  
</template>

<script setup>


 
const props = defineProps({
  platform: {
    type: String,
    required: false,
    default: 'all'
  },
  maxHeight: {
    type: Number,
    required: false,
    default: 220
  },
  // aspectRatio: {
  //   type: String,
  //   required: false,
  //   default: '730/210'
  // },
});

const isActive = ref(false);
const isMore = ref(false);
const elShow = ref(null);
const elShowCont = ref(null);
 
const toggle = () => {
  isMore.value = !isMore.value;
  if(!isMore.value){
    scrollToBack();
  }
}


// const getHeightFirstChild = computed (() => {
//   // if(elShow.value){
//     if(import.meta.client){
//       return elShowCont.value.children[0].clientHeight;
//     }else{
//       return 100;
//     }
//   // }
//   // return 0;
// })

const scrollToBack = () => {
  if(import.meta.client){

    if(!elShow.value?.getBoundingClientRect){
      return;
    }

    setTimeout(() => {
      // elShow.value.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      const offset = 100;
      const elementPosition = elShow.value.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });

    }, 100)

  }
}



// onMounted(() => {
//   document.documentElement.style.setProperty('--show-more-height', getHeightFirstChild.value + 'px')
// })


</script>

<style scoped>

/* none */


.show .toggler{
  display: none;
}

.show.none{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.show.none .cont{
  position: relative;
  overflow: initial;
  height: 100%;
} 
.show.none .toggler{
  display: none;
}

/* desktop */


@container pb (min-width: 500px){
    
  .show.desktop{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: max-height 0.5s ease-in-out;
  }
  .show.desktop .toggler{
    margin-top: 0.8rem;
    margin-bottom: 0.5rem;
    right: 0;
    display: none;
    text-align: right;
    font-size: 0.8rem;
    cursor: pointer;
    border: 1px var(--un-element-color-gray) solid;
    border-radius: 130px;
    padding: 6px 10px;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    position: initial;
    width: 200px;
    text-align: center;
  }
  .show.desktop .toggler:hover{
    background-color: var(--un-element-color-gray);
    color: black;
  }
  .show.desktop .toggler::before{
    content: '';
    position: absolute;
    left: 0;
    width: calc(50% - 100px);
    height: 1px;
    background: var(--un-element-color-gray);
    margin-top: 9px;
  }
  .show.desktop .toggler::after{
    content: '';
    position: absolute;
    right: 0;
    width: calc(50% - 100px);
    height: 1px;
    background: var(--un-element-color-gray);
    margin-top: 9px;
  }

  .show.desktop .cont{
    position: relative;
    overflow: hidden;
    /* max-height: 140px; */
    height: 100%;
  } 
  .show.desktop.is-more{
    /* aspect-ratio: inherit!important; */
    max-height: 3000px!important;
  }

  .show.desktop .toggler{
    display: block;
  }
  .toggler.more{
    position: relative;
    z-index: 1;
  }


  .show.none .cont{    
    overflow: hidden;
  } 
}


/*  */

</style>


export function getRandomString(length: number): string {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}


export function isValidUrl(str: string, type: string): boolean {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[@-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  if(!pattern.test(str)) {
    return false;
  } else {
    if(str.indexOf(type) < 0){
      return false;
    }else{
      return true;
    }

  }
}



import { ColorPaletteRandom } from '@/types/other/Other.types';


export const getColorByNumber = (num: string | number | null | undefined): string => {
  const index = parseInt(String(num ?? 0).slice(-2), 10);
  return ColorPaletteRandom[index] || ColorPaletteRandom[0];
};




import { AllowedDomainLinks } from '@/types/other/Other.types';

export const formatAllowedLinks = (text: string, allowAllDomains: boolean = false) => {
 
  let domainPattern = AllowedDomainLinks.join('|').replace(/\./g, '\\.');

  if (allowAllDomains) {
    domainPattern = '[^\\s]+'; 
  }

  const pattern = new RegExp(`\\b(?:https?:\\/\\/)?(?:${domainPattern})\\S*`, 'gi');

  return text.replace(pattern, (url) => {

    if (!/^https?:\/\//.test(url)) {
      url = 'http://' + url;
    }

    const urlObj = new URL(url);
    const host = urlObj.host;

    if (allowAllDomains || AllowedDomainLinks.includes(host)) {
      const displayUrl = host + urlObj.pathname; 
      return `<a href="${url}">${displayUrl}</a>`;
    }

    return url; 
  });
}
  

export const formatUserMentions = (text: string) => {
  if(text && text.length){
    return text.replace(/@([a-zA-Z0-9_]+)/g, '<a href="/grower/$1">@$1</a>');
  }else{
    return '';
  }
}

export const formatCommonText = (text: string) => {
  text = formatAllowedLinks(text);
  text = formatUserMentions(text);
  return text;
}

export const formatCroppedText = (text: string, limit: number = 200) => {
  if(text && text.length > limit){
    return text.substring(0, limit) + '...';
  }else{
    return text;
  }
}

export const formatStrainsLink = (text: string) => {
  return text.replace(/\/strains/g, '/strains2');
}


export const shortCounter = (num: number) => {
  if(num > 999999)
    return  (Math.floor(num/1000000)) + 'M+'
  else if(num > 999)
    return  (Math.floor(num/1000)) + 'K+'
  else if(num > 300)
    return (Math.floor(num/100)) + '00+'
  return num;
}



export function sliceQuestionAnswerTextRegex(html: string): { title: string; text: string }[] {
  const pattern = /<h2>(.*?)<\/h2>([\s\S]*?)(?=<h2>|$)/g;
  const results: { title: string; text: string }[] = [];
  
  let match;
  while ((match = pattern.exec(html)) !== null) {
    const title = match[1].trim();
    const text = match[2].trim();
    results.push({ title, text });
  }
  
  return results;
}
export enum EStrainCategories {
    All = 'all:all',
    // Genes
    GenesIndica = 'genes:indica',
    GenesHybrid = 'genes:hybrid',
    GenesSativa = 'genes:sativa',
  
    // Medical
    MedicalAddAdhd = 'medical:add-adhd',
    MedicalAlzheimers = 'medical:alzheimers',
    MedicalAnorexia = 'medical:anorexia',
    MedicalAnxiety = 'medical:anxiety',
    MedicalArthritis = 'medical:arthritis',
    MedicalAsthma = 'medical:asthma',
    MedicalBipolarDisorder = 'medical:bipolar-disorder',
    MedicalCachexia = 'medical:cachexia',
    MedicalCancer = 'medical:cancer',
    MedicalCramps = 'medical:cramps',
    MedicalCrohnsDisease = 'medical:crohns-disease',
    MedicalDepression = 'medical:depression',
    MedicalEpilepsy = 'medical:epilepsy',
    MedicalEyePressure = 'medical:eye-pressure',
    MedicalFatigue = 'medical:fatigue',
    MedicalFibromyalgia = 'medical:fibromyalgia',
    MedicalGastrointestinalDisorder = 'medical:gastrointestinal-disorder',
    MedicalGlaucoma = 'medical:glaucoma',
    MedicalHeadaches = 'medical:headaches',
    MedicalHivAids = 'medical:hiv-aids',
    MedicalHypertension = 'medical:hypertension',
    MedicalInflammation = 'medical:inflammation',
    MedicalInsomnia = 'medical:insomnia',
    MedicalLackOfAppetite = 'medical:lack-of-appetite',
    MedicalMigraines = 'medical:migraines',
    MedicalMultipleSclerosis = 'medical:multiple-sclerosis',
    MedicalMuscleSpasms = 'medical:muscle-spasms',
    MedicalMuscularDystrophy = 'medical:muscular-dystrophy',
    MedicalNausea = 'medical:nausea',
    MedicalPain = 'medical:pain',
    MedicalParkinsons = 'medical:parkinsons',
    MedicalPhantomLimbPain = 'medical:phantom-limb-pain',
    MedicalPms = 'medical:pms',
    MedicalPtsd = 'medical:ptsd',
    MedicalSeizures = 'medical:seizures',
    MedicalSpasticity = 'medical:spasticity',
    MedicalSpinalCordInjury = 'medical:spinal-cord-injury',
    MedicalStress = 'medical:stress',
    MedicalTinnitus = 'medical:tinnitus',
    MedicalTourettesSyndrome = 'medical:tourettes-syndrome',
  
    // Tastes
    TastesAmmonia = 'tastes:ammonia',
    TastesApple = 'tastes:apple',
    TastesApricot = 'tastes:apricot',
    TastesBerry = 'tastes:berry',
    TastesBlueCheese = 'tastes:blue-cheese',
    TastesBlueberry = 'tastes:blueberry',
    TastesButter = 'tastes:butter',
    TastesCheese = 'tastes:cheese',
    TastesChemical = 'tastes:chemical',
    TastesChestnut = 'tastes:chestnut',
    TastesCitrus = 'tastes:citrus',
    TastesCoffee = 'tastes:coffee',
    TastesCream = 'tastes:cream',
    TastesDiesel = 'tastes:diesel',
    TastesEarthy = 'tastes:earthy',
    TastesFlowery = 'tastes:flowery',
    TastesFruity = 'tastes:fruity',
    TastesGrape = 'tastes:grape',
    TastesGrapefruit = 'tastes:grapefruit',
    TastesHoney = 'tastes:honey',
    TastesLavender = 'tastes:lavender',
    TastesLemon = 'tastes:lemon',
    TastesLime = 'tastes:lime',
    TastesMango = 'tastes:mango',
    TastesMenthol = 'tastes:menthol',
    TastesMint = 'tastes:mint',
    TastesNutty = 'tastes:nutty',
    TastesOrange = 'tastes:orange',
    TastesPeach = 'tastes:peach',
    TastesPear = 'tastes:pear',
    TastesPepper = 'tastes:pepper',
    TastesPine = 'tastes:pine',
    TastesPineapple = 'tastes:pineapple',
    TastesPlum = 'tastes:plum',
    TastesPungent = 'tastes:pungent',
    TastesRose = 'tastes:rose',
    TastesSage = 'tastes:sage',
    TastesSkunk = 'tastes:skunk',
    TastesSour = 'tastes:sour',
    TastesSpicyHerbal = 'tastes:spicy-herbal',
    TastesStrawberry = 'tastes:strawberry',
    TastesSweet = 'tastes:sweet',
    TastesTar = 'tastes:tar',
    TastesTea = 'tastes:tea',
    TastesTobacco = 'tastes:tobacco',
    TastesTreeFruit = 'tastes:tree-fruit',
    TastesTropical = 'tastes:tropical',
    TastesVanilla = 'tastes:vanilla',
    TastesViolet = 'tastes:violet',
    TastesWoody = 'tastes:woody',
  
    // Effects Positive
    PositiveEffectsAroused = 'positive-effects:aroused',
    PositiveEffectsCreative = 'positive-effects:creative',
    PositiveEffectsEnergetic = 'positive-effects:energetic',
    PositiveEffectsEuphoric = 'positive-effects:euphoric',
    PositiveEffectsFocused = 'positive-effects:focused',
    PositiveEffectsGiggly = 'positive-effects:giggly',
    PositiveEffectsHappy = 'positive-effects:happy',
    PositiveEffectsHungry = 'positive-effects:hungry',
    PositiveEffectsRelaxed = 'positive-effects:relaxed',
    PositiveEffectsSleepy = 'positive-effects:sleepy',
    PositiveEffectsTalkative = 'positive-effects:talkative',
    PositiveEffectsTingly = 'positive-effects:tingly',
    PositiveEffectsUplifted = 'positive-effects:uplifted',
  
    // Effects Negative
    NegativeEffectsAnxious = 'negative-effects:anxious',
    NegativeEffectsDizzy = 'negative-effects:dizzy',
    NegativeEffectsDryEyes = 'negative-effects:dry-eyes',
    NegativeEffectsDryMouth = 'negative-effects:dry-mouth',
    NegativeEffectsHeadache = 'negative-effects:headache',
    NegativeEffectsParanoid = 'negative-effects:paranoid',
  
    // Difficulty
    DifficultyDifficult = 'difficulty:difficult',
    DifficultyEasy = 'difficulty:easy',
    DifficultyModerate = 'difficulty:moderate',
  
    // Height
    HeightMedium = 'height:medium',
    HeightTall = 'height:tall',
    HeightShort = 'height:short',
  
    // Yield
    YieldHigh = 'yield:high',
    YieldLow = 'yield:low',
    YieldMedium = 'yield:medium',
  
    // thc
    ThcHigh = 'thc:high',
    ThcLow = 'thc:low',
    ThcMedium = 'thc:medium',
  }
  
  export const RStrainCategoriesImages: Record<EStrainCategories, string> = {
    [EStrainCategories.All]: '/images/strain-categories/all.svg',
    // Genes
    [EStrainCategories.GenesIndica]: '/images/strain-categories/genes-indica.svg',
    [EStrainCategories.GenesHybrid]: '/images/strain-categories/genes-hybrid.svg',
    [EStrainCategories.GenesSativa]: '/images/strain-categories/genes-sativa.svg',
  
    // Medical
    [EStrainCategories.MedicalAddAdhd]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalAlzheimers]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalAnorexia]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalAnxiety]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalArthritis]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalAsthma]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalBipolarDisorder]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalCachexia]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalCancer]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalCramps]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalCrohnsDisease]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalDepression]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalEpilepsy]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalEyePressure]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalFatigue]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalFibromyalgia]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalGastrointestinalDisorder]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalGlaucoma]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalHeadaches]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalHivAids]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalHypertension]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalInflammation]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalInsomnia]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalLackOfAppetite]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalMigraines]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalMultipleSclerosis]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalMuscleSpasms]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalMuscularDystrophy]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalNausea]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalPain]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalParkinsons]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalPhantomLimbPain]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalPms]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalPtsd]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalSeizures]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalSpasticity]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalSpinalCordInjury]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalStress]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalTinnitus]: '/images/strain-categories/medical.svg',
    [EStrainCategories.MedicalTourettesSyndrome]: '/images/strain-categories/medical.svg',
  
    // Tastes
    [EStrainCategories.TastesAmmonia]: '/images/strain-categories/tastes-ammonia.svg',
    [EStrainCategories.TastesApple]: '/images/strain-categories/tastes-apple.svg',
    [EStrainCategories.TastesApricot]: '/images/strain-categories/tastes-apricot.svg',
    [EStrainCategories.TastesBerry]: '/images/strain-categories/tastes-berry.svg',
    [EStrainCategories.TastesBlueCheese]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesBlueberry]: '/images/strain-categories/tastes-blueberry.svg',
    [EStrainCategories.TastesButter]: '/images/strain-categories/tastes-butter.svg',
    [EStrainCategories.TastesCheese]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesChemical]: '/images/strain-categories/tastes-chemical.svg',
    [EStrainCategories.TastesChestnut]: '/images/strain-categories/tastes-chestnut.svg',
    [EStrainCategories.TastesCitrus]: '/images/strain-categories/tastes-citrus.svg',
    [EStrainCategories.TastesCoffee]: '/images/strain-categories/tastes-coffee.svg',
    [EStrainCategories.TastesCream]: '/images/strain-categories/tastes-cream.svg',
    [EStrainCategories.TastesDiesel]: '/images/strain-categories/tastes-diesel.svg',
    [EStrainCategories.TastesEarthy]: '/images/strain-categories/tastes-earthy.svg',
    [EStrainCategories.TastesFlowery]: '/images/strain-categories/tastes-flowery.svg',
    [EStrainCategories.TastesFruity]: '/images/strain-categories/tastes-fruity.svg',
    [EStrainCategories.TastesGrape]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesGrapefruit]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesHoney]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesLavender]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesLemon]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesLime]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesMango]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesMenthol]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesMint]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesNutty]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesOrange]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesPeach]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesPear]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesPepper]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesPine]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesPineapple]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesPlum]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesPungent]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesRose]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesSage]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesSkunk]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesSour]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesSpicyHerbal]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesStrawberry]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesSweet]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesTar]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesTea]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesTobacco]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesTreeFruit]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesTropical]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesVanilla]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesViolet]: '/images/strain-categories/tastes.svg',
    [EStrainCategories.TastesWoody]: '/images/strain-categories/tastes.svg',
  
  
    // [EStrainCategories.TastesAmmonia]: '/images/strain-categories/tastes-ammonia.svg',
    // [EStrainCategories.TastesApple]: '/images/strain-categories/tastes-apple.svg',
    // [EStrainCategories.TastesApricot]: '/images/strain-categories/tastes-apricot.svg',
    // [EStrainCategories.TastesBerry]: '/images/strain-categories/tastes-berry.svg',
    // [EStrainCategories.TastesBlueCheese]: '/images/strain-categories/tastes-blue-cheese.svg',
    // [EStrainCategories.TastesBlueberry]: '/images/strain-categories/tastes-blueberry.svg',
    // [EStrainCategories.TastesButter]: '/images/strain-categories/tastes-butter.svg',
    // [EStrainCategories.TastesCheese]: '/images/strain-categories/tastes-cheese.svg',
    // [EStrainCategories.TastesChemical]: '/images/strain-categories/tastes-chemical.svg',
    // [EStrainCategories.TastesChestnut]: '/images/strain-categories/tastes-chestnut.svg',
    // [EStrainCategories.TastesCitrus]: '/images/strain-categories/tastes-citrus.svg',
    // [EStrainCategories.TastesCoffee]: '/images/strain-categories/tastes-coffee.svg',
    // [EStrainCategories.TastesCream]: '/images/strain-categories/tastes-cream.svg',
    // [EStrainCategories.TastesDiesel]: '/images/strain-categories/tastes-diesel.svg',
    // [EStrainCategories.TastesEarthy]: '/images/strain-categories/tastes-earthy.svg',
    // [EStrainCategories.TastesFlowery]: '/images/strain-categories/tastes-flowery.svg',
    // [EStrainCategories.TastesFruity]: '/images/strain-categories/tastes-fruity.svg',
    // [EStrainCategories.TastesGrape]: '/images/strain-categories/tastes-grape.svg',
    // [EStrainCategories.TastesGrapefruit]: '/images/strain-categories/tastes-grapefruit.svg',
    // [EStrainCategories.TastesHoney]: '/images/strain-categories/tastes-honey.svg',
    // [EStrainCategories.TastesLavender]: '/images/strain-categories/tastes-lavender.svg',
    // [EStrainCategories.TastesLemon]: '/images/strain-categories/tastes-lemon.svg',
    // [EStrainCategories.TastesLime]: '/images/strain-categories/tastes-lime.svg',
    // [EStrainCategories.TastesMango]: '/images/strain-categories/tastes-mango.svg',
    // [EStrainCategories.TastesMenthol]: '/images/strain-categories/tastes-menthol.svg',
    // [EStrainCategories.TastesMint]: '/images/strain-categories/tastes-mint.svg',
    // [EStrainCategories.TastesNutty]: '/images/strain-categories/tastes-nutty.svg',
    // [EStrainCategories.TastesOrange]: '/images/strain-categories/tastes-orange.svg',
    // [EStrainCategories.TastesPeach]: '/images/strain-categories/tastes-peach.svg',
    // [EStrainCategories.TastesPear]: '/images/strain-categories/tastes-pear.svg',
    // [EStrainCategories.TastesPepper]: '/images/strain-categories/tastes-pepper.svg',
    // [EStrainCategories.TastesPine]: '/images/strain-categories/tastes-pine.svg',
    // [EStrainCategories.TastesPineapple]: '/images/strain-categories/tastes-pineapple.svg',
    // [EStrainCategories.TastesPlum]: '/images/strain-categories/tastes-plum.svg',
    // [EStrainCategories.TastesPungent]: '/images/strain-categories/tastes-pungent.svg',
    // [EStrainCategories.TastesRose]: '/images/strain-categories/tastes-rose.svg',
    // [EStrainCategories.TastesSage]: '/images/strain-categories/tastes-sage.svg',
    // [EStrainCategories.TastesSkunk]: '/images/strain-categories/tastes-skunk.svg',
    // [EStrainCategories.TastesSour]: '/images/strain-categories/tastes-sour.svg',
    // [EStrainCategories.TastesSpicyHerbal]: '/images/strain-categories/tastes-spicy-herbal.svg',
    // [EStrainCategories.TastesStrawberry]: '/images/strain-categories/tastes-strawberry.svg',
    // [EStrainCategories.TastesSweet]: '/images/strain-categories/tastes-sweet.svg',
    // [EStrainCategories.TastesTar]: '/images/strain-categories/tastes-tar.svg',
    // [EStrainCategories.TastesTea]: '/images/strain-categories/tastes-tea.svg',
    // [EStrainCategories.TastesTobacco]: '/images/strain-categories/tastes-tobacco.svg',
    // [EStrainCategories.TastesTreeFruit]: '/images/strain-categories/tastes-tree-fruit.svg',
    // [EStrainCategories.TastesTropical]: '/images/strain-categories/tastes-tropical.svg',
    // [EStrainCategories.TastesVanilla]: '/images/strain-categories/tastes-vanilla.svg',
    // [EStrainCategories.TastesViolet]: '/images/strain-categories/tastes-violet.svg',
    // [EStrainCategories.TastesWoody]: '/images/strain-categories/tastes-woody.svg',
  
    // Effects Positive
    [EStrainCategories.PositiveEffectsAroused]: '/images/strain-categories/positive-effects-aroused.svg',
    [EStrainCategories.PositiveEffectsCreative]: '/images/strain-categories/positive-effects-creative.svg',
    [EStrainCategories.PositiveEffectsEnergetic]: '/images/strain-categories/positive-effects-energetic.svg',
    [EStrainCategories.PositiveEffectsEuphoric]: '/images/strain-categories/positive-effects-euphoric.svg',
    [EStrainCategories.PositiveEffectsFocused]: '/images/strain-categories/positive-effects-focused.svg',
    [EStrainCategories.PositiveEffectsGiggly]: '/images/strain-categories/positive-effects-giggly.svg',
    [EStrainCategories.PositiveEffectsHappy]: '/images/strain-categories/positive-effects-happy.svg',
    [EStrainCategories.PositiveEffectsHungry]: '/images/strain-categories/positive-effects-hungry.svg',
    [EStrainCategories.PositiveEffectsRelaxed]: '/images/strain-categories/positive-effects-relaxed.svg',
    [EStrainCategories.PositiveEffectsSleepy]: '/images/strain-categories/positive-effects-sleepy.svg',
    [EStrainCategories.PositiveEffectsTalkative]: '/images/strain-categories/positive-effects-talkative.svg',
    [EStrainCategories.PositiveEffectsTingly]: '/images/strain-categories/positive-effects-tingly.svg',
    [EStrainCategories.PositiveEffectsUplifted]: '/images/strain-categories/positive-effects-uplifted.svg',
  
    // Effects Negative
    [EStrainCategories.NegativeEffectsAnxious]: '/images/strain-categories/negative-effects-anxious.svg',
    [EStrainCategories.NegativeEffectsDizzy]: '/images/strain-categories/negative-effects-dizzy.svg',
    [EStrainCategories.NegativeEffectsDryEyes]: '/images/strain-categories/negative-effects-dry-eyes.svg',
    [EStrainCategories.NegativeEffectsDryMouth]: '/images/strain-categories/negative-effects-dry-mouth.svg',
    [EStrainCategories.NegativeEffectsHeadache]: '/images/strain-categories/negative-effects-headache.svg',
    [EStrainCategories.NegativeEffectsParanoid]: '/images/strain-categories/negative-effects-paranoid.svg',
  
    // Difficulty
    [EStrainCategories.DifficultyDifficult]: '/images/strain-categories/difficulty-difficult.svg',
    [EStrainCategories.DifficultyEasy]: '/images/strain-categories/difficulty-easy.svg',
    [EStrainCategories.DifficultyModerate]: '/images/strain-categories/difficulty-moderate.svg',
  
    // Height
    [EStrainCategories.HeightMedium]: '/images/strain-categories/height-medium.svg',
    [EStrainCategories.HeightTall]: '/images/strain-categories/height-tall.svg',
    [EStrainCategories.HeightShort]: '/images/strain-categories/height-short.svg',
  
    // Yield
    [EStrainCategories.YieldHigh]: '/images/strain-categories/yield-high.svg',
    [EStrainCategories.YieldLow]: '/images/strain-categories/yield-low.svg',
    [EStrainCategories.YieldMedium]: '/images/strain-categories/yield-medium.svg',
  
    // thc
    [EStrainCategories.ThcHigh]: '/images/strain-categories/thc-high.svg',
    [EStrainCategories.ThcLow]: '/images/strain-categories/thc-low.svg',
    [EStrainCategories.ThcMedium]: '/images/strain-categories/thc-medium.svg',
  };
  
  
  export const RStrainCategoriesNames: Record<EStrainCategories, string> = {
    [EStrainCategories.All]: 'all:all',
    // ────────────── Genes ──────────────
    [EStrainCategories.GenesIndica]: 'genes:indica',
    [EStrainCategories.GenesHybrid]: 'genes:hybrid',
    [EStrainCategories.GenesSativa]: 'genes:sativa',
  
    // ────────────── Medical ──────────────
    [EStrainCategories.MedicalAddAdhd]: 'medical:add-adhd',
    [EStrainCategories.MedicalAlzheimers]: 'medical:alzheimers',
    [EStrainCategories.MedicalAnorexia]: 'medical:anorexia',
    [EStrainCategories.MedicalAnxiety]: 'medical:anxiety',
    [EStrainCategories.MedicalArthritis]: 'medical:arthritis',
    [EStrainCategories.MedicalAsthma]: 'medical:asthma',
    [EStrainCategories.MedicalBipolarDisorder]: 'medical:bipolar-disorder',
    [EStrainCategories.MedicalCachexia]: 'medical:cachexia',
    [EStrainCategories.MedicalCancer]: 'medical:cancer',
    [EStrainCategories.MedicalCramps]: 'medical:cramps',
    [EStrainCategories.MedicalCrohnsDisease]: 'medical:crohns-disease',
    [EStrainCategories.MedicalDepression]: 'medical:depression',
    [EStrainCategories.MedicalEpilepsy]: 'medical:epilepsy',
    [EStrainCategories.MedicalEyePressure]: 'medical:eye-pressure',
    [EStrainCategories.MedicalFatigue]: 'medical:fatigue',
    [EStrainCategories.MedicalFibromyalgia]: 'medical:fibromyalgia',
    [EStrainCategories.MedicalGastrointestinalDisorder]: 'medical:gastrointestinal-disorder',
    [EStrainCategories.MedicalGlaucoma]: 'medical:glaucoma',
    [EStrainCategories.MedicalHeadaches]: 'medical:headaches',
    [EStrainCategories.MedicalHivAids]: 'medical:hiv-aids',
    [EStrainCategories.MedicalHypertension]: 'medical:hypertension',
    [EStrainCategories.MedicalInflammation]: 'medical:inflammation',
    [EStrainCategories.MedicalInsomnia]: 'medical:insomnia',
    [EStrainCategories.MedicalLackOfAppetite]: 'medical:lack-of-appetite',
    [EStrainCategories.MedicalMigraines]: 'medical:migraines',
    [EStrainCategories.MedicalMultipleSclerosis]: 'medical:multiple-sclerosis',
    [EStrainCategories.MedicalMuscleSpasms]: 'medical:muscle-spasms',
    [EStrainCategories.MedicalMuscularDystrophy]: 'medical:muscular-dystrophy',
    [EStrainCategories.MedicalNausea]: 'medical:nausea',
    [EStrainCategories.MedicalPain]: 'medical:pain',
    [EStrainCategories.MedicalParkinsons]: 'medical:parkinsons',
    [EStrainCategories.MedicalPhantomLimbPain]: 'medical:phantom-limb-pain',
    [EStrainCategories.MedicalPms]: 'medical:pms',
    [EStrainCategories.MedicalPtsd]: 'medical:ptsd',
    [EStrainCategories.MedicalSeizures]: 'medical:seizures',
    [EStrainCategories.MedicalSpasticity]: 'medical:spasticity',
    [EStrainCategories.MedicalSpinalCordInjury]: 'medical:spinal-cord-injury',
    [EStrainCategories.MedicalStress]: 'medical:stress',
    [EStrainCategories.MedicalTinnitus]: 'medical:tinnitus',
    [EStrainCategories.MedicalTourettesSyndrome]: 'medical:tourettes-syndrome',
  
    // ────────────── Tastes ──────────────
    [EStrainCategories.TastesAmmonia]: 'tastes:ammonia',
    [EStrainCategories.TastesApple]: 'tastes:apple',
    [EStrainCategories.TastesApricot]: 'tastes:apricot',
    [EStrainCategories.TastesBerry]: 'tastes:berry',
    [EStrainCategories.TastesBlueCheese]: 'tastes:blue-cheese',
    [EStrainCategories.TastesBlueberry]: 'tastes:blueberry',
    [EStrainCategories.TastesButter]: 'tastes:butter',
    [EStrainCategories.TastesCheese]: 'tastes:cheese',
    [EStrainCategories.TastesChemical]: 'tastes:chemical',
    [EStrainCategories.TastesChestnut]: 'tastes:chestnut',
    [EStrainCategories.TastesCitrus]: 'tastes:citrus',
    [EStrainCategories.TastesCoffee]: 'tastes:coffee',
    [EStrainCategories.TastesCream]: 'tastes:cream',
    [EStrainCategories.TastesDiesel]: 'tastes:diesel',
    [EStrainCategories.TastesEarthy]: 'tastes:earthy',
    [EStrainCategories.TastesFlowery]: 'tastes:flowery',
    [EStrainCategories.TastesFruity]: 'tastes:fruity',
    [EStrainCategories.TastesGrape]: 'tastes:grape',
    [EStrainCategories.TastesGrapefruit]: 'tastes:grapefruit',
    [EStrainCategories.TastesHoney]: 'tastes:honey',
    [EStrainCategories.TastesLavender]: 'tastes:lavender',
    [EStrainCategories.TastesLemon]: 'tastes:lemon',
    [EStrainCategories.TastesLime]: 'tastes:lime',
    [EStrainCategories.TastesMango]: 'tastes:mango',
    [EStrainCategories.TastesMenthol]: 'tastes:menthol',
    [EStrainCategories.TastesMint]: 'tastes:mint',
    [EStrainCategories.TastesNutty]: 'tastes:nutty',
    [EStrainCategories.TastesOrange]: 'tastes:orange',
    [EStrainCategories.TastesPeach]: 'tastes:peach',
    [EStrainCategories.TastesPear]: 'tastes:pear',
    [EStrainCategories.TastesPepper]: 'tastes:pepper',
    [EStrainCategories.TastesPine]: 'tastes:pine',
    [EStrainCategories.TastesPineapple]: 'tastes:pineapple',
    [EStrainCategories.TastesPlum]: 'tastes:plum',
    [EStrainCategories.TastesPungent]: 'tastes:pungent',
    [EStrainCategories.TastesRose]: 'tastes:rose',
    [EStrainCategories.TastesSage]: 'tastes:sage',
    [EStrainCategories.TastesSkunk]: 'tastes:skunk',
    [EStrainCategories.TastesSour]: 'tastes:sour',
    [EStrainCategories.TastesSpicyHerbal]: 'tastes:spicy-herbal',
    [EStrainCategories.TastesStrawberry]: 'tastes:strawberry',
    [EStrainCategories.TastesSweet]: 'tastes:sweet',
    [EStrainCategories.TastesTar]: 'tastes:tar',
    [EStrainCategories.TastesTea]: 'tastes:tea',
    [EStrainCategories.TastesTobacco]: 'tastes:tobacco',
    [EStrainCategories.TastesTreeFruit]: 'tastes:tree-fruit',
    [EStrainCategories.TastesTropical]: 'tastes:tropical',
    [EStrainCategories.TastesVanilla]: 'tastes:vanilla',
    [EStrainCategories.TastesViolet]: 'tastes:violet',
    [EStrainCategories.TastesWoody]: 'tastes:woody',
  
    // ────────────── Effects Positive ──────────────
    [EStrainCategories.PositiveEffectsAroused]: 'positive-effects:aroused',
    [EStrainCategories.PositiveEffectsCreative]: 'positive-effects:creative',
    [EStrainCategories.PositiveEffectsEnergetic]: 'positive-effects:energetic',
    [EStrainCategories.PositiveEffectsEuphoric]: 'positive-effects:euphoric',
    [EStrainCategories.PositiveEffectsFocused]: 'positive-effects:focused',
    [EStrainCategories.PositiveEffectsGiggly]: 'positive-effects:giggly',
    [EStrainCategories.PositiveEffectsHappy]: 'positive-effects:happy',
    [EStrainCategories.PositiveEffectsHungry]: 'positive-effects:hungry',
    [EStrainCategories.PositiveEffectsRelaxed]: 'positive-effects:relaxed',
    [EStrainCategories.PositiveEffectsSleepy]: 'positive-effects:sleepy',
    [EStrainCategories.PositiveEffectsTalkative]: 'positive-effects:talkative',
    [EStrainCategories.PositiveEffectsTingly]: 'positive-effects:tingly',
    [EStrainCategories.PositiveEffectsUplifted]: 'positive-effects:uplifted',
  
    // ────────────── Effects Negative ──────────────
    [EStrainCategories.NegativeEffectsAnxious]: 'negative-effects:anxious',
    [EStrainCategories.NegativeEffectsDizzy]: 'negative-effects:dizzy',
    [EStrainCategories.NegativeEffectsDryEyes]: 'negative-effects:dry-eyes',
    [EStrainCategories.NegativeEffectsDryMouth]: 'negative-effects:dry-mouth',
    [EStrainCategories.NegativeEffectsHeadache]: 'negative-effects:headache',
    [EStrainCategories.NegativeEffectsParanoid]: 'negative-effects:paranoid',
  
    // ────────────── Difficulty ──────────────
    [EStrainCategories.DifficultyDifficult]: 'difficulty:difficult',
    [EStrainCategories.DifficultyEasy]: 'difficulty:easy',
    [EStrainCategories.DifficultyModerate]: 'difficulty:moderate',
  
    // ────────────── Height ──────────────
    [EStrainCategories.HeightMedium]: 'height:medium',
    [EStrainCategories.HeightTall]: 'height:tall',
    [EStrainCategories.HeightShort]: 'height:short',
  
    // ────────────── Yield ──────────────
    [EStrainCategories.YieldHigh]: 'yield:high',
    [EStrainCategories.YieldLow]: 'yield:low',
    [EStrainCategories.YieldMedium]: 'yield:medium',
  
    // ────────────── THC ──────────────
    [EStrainCategories.ThcHigh]: 'thc:high',
    [EStrainCategories.ThcLow]: 'thc:low',
    [EStrainCategories.ThcMedium]: 'thc:medium',
  };
  
  
  
  
  export const RPreferredStrainCategories: EStrainCategories[] = [
  
    EStrainCategories.All,
  
    EStrainCategories.GenesIndica,
    EStrainCategories.GenesSativa,
    EStrainCategories.GenesHybrid,
  
    // EStrainCategories.TastesApple,
    EStrainCategories.ThcLow,
    EStrainCategories.ThcMedium,
    EStrainCategories.ThcHigh,
    
    EStrainCategories.HeightTall,
    EStrainCategories.HeightMedium,
    EStrainCategories.HeightShort,

    EStrainCategories.YieldHigh,
    EStrainCategories.YieldMedium,
    EStrainCategories.YieldLow,
  
    // EStrainCategories.MedicalAnxiety,
    // EStrainCategories.MedicalPain,
    // EStrainCategories.MedicalStress,
    // EStrainCategories.MedicalDepression,
    // EStrainCategories.MedicalInsomnia,
  
    EStrainCategories.PositiveEffectsEuphoric,
    EStrainCategories.PositiveEffectsHappy,
    EStrainCategories.PositiveEffectsRelaxed,
    EStrainCategories.PositiveEffectsUplifted,
    EStrainCategories.PositiveEffectsEnergetic,
    EStrainCategories.PositiveEffectsCreative,
    EStrainCategories.PositiveEffectsGiggly,



    EStrainCategories.TastesAmmonia,
    EStrainCategories.TastesApple,
    EStrainCategories.TastesApricot,
    EStrainCategories.TastesBerry,

    EStrainCategories.TastesBlueberry,
    EStrainCategories.TastesButter,
    // EStrainCategories.TastesCheese,
    EStrainCategories.TastesChemical,
    EStrainCategories.TastesChestnut,
    EStrainCategories.TastesCitrus,
    EStrainCategories.TastesCoffee,
    EStrainCategories.TastesCream,
    EStrainCategories.TastesDiesel,
    EStrainCategories.TastesEarthy,
    EStrainCategories.TastesFlowery,
    EStrainCategories.TastesFruity,
  
  
  
    // EStrainCategories.GenesIndica,
    // EStrainCategories.GenesSativa,
    // EStrainCategories.GenesHybrid,
  
  
    // EStrainCategories.MedicalAnxiety,
    // EStrainCategories.MedicalPain,
    // EStrainCategories.MedicalStress,
    // EStrainCategories.MedicalDepression,
    // EStrainCategories.MedicalInsomnia,
    // EStrainCategories.MedicalCancer,
    // EStrainCategories.MedicalMigraines,
    // EStrainCategories.MedicalNausea,
    // EStrainCategories.MedicalLackOfAppetite,
    // EStrainCategories.MedicalInflammation,
  
  
    // EStrainCategories.PositiveEffectsEuphoric,
    // EStrainCategories.PositiveEffectsHappy,
    // EStrainCategories.PositiveEffectsRelaxed,
    // EStrainCategories.PositiveEffectsUplifted,
    // EStrainCategories.PositiveEffectsEnergetic,
    // EStrainCategories.PositiveEffectsCreative,
    // EStrainCategories.PositiveEffectsGiggly,
  
  
    // EStrainCategories.TastesFruity,
    // EStrainCategories.TastesSweet,
    // EStrainCategories.TastesCitrus,
    // EStrainCategories.TastesEarthy,
    // EStrainCategories.TastesDiesel,
    // EStrainCategories.TastesBerry,
    // EStrainCategories.TastesSkunk,
    // EStrainCategories.TastesStrawberry,
    // EStrainCategories.TastesMenthol,
    // EStrainCategories.TastesPine,
  ];
  
  
  
  
  
  export const RStrainCategoriesLinks: Record<EStrainCategories, string> = {
    [EStrainCategories.All]: '/strains2',
    // ────────── Genes ──────────
    [EStrainCategories.GenesIndica]: '/strains2/category/genes/indica',
    [EStrainCategories.GenesHybrid]: '/strains2/category/genes/hybrid',
    [EStrainCategories.GenesSativa]: '/strains2/category/genes/sativa',
  
    // ────────── Medical ──────────
    [EStrainCategories.MedicalAddAdhd]: '/strains2/category/medical/add-adhd',
    [EStrainCategories.MedicalAlzheimers]: '/strains2/category/medical/alzheimers',
    [EStrainCategories.MedicalAnorexia]: '/strains2/category/medical/anorexia',
    [EStrainCategories.MedicalAnxiety]: '/strains2/category/medical/anxiety',
    [EStrainCategories.MedicalArthritis]: '/strains2/category/medical/arthritis',
    [EStrainCategories.MedicalAsthma]: '/strains2/category/medical/asthma',
    [EStrainCategories.MedicalBipolarDisorder]: '/strains2/category/medical/bipolar-disorder',
    [EStrainCategories.MedicalCachexia]: '/strains2/category/medical/cachexia',
    [EStrainCategories.MedicalCancer]: '/strains2/category/medical/cancer',
    [EStrainCategories.MedicalCramps]: '/strains2/category/medical/cramps',
    [EStrainCategories.MedicalCrohnsDisease]: '/strains2/category/medical/crohns-disease',
    [EStrainCategories.MedicalDepression]: '/strains2/category/medical/depression',
    [EStrainCategories.MedicalEpilepsy]: '/strains2/category/medical/epilepsy',
    [EStrainCategories.MedicalEyePressure]: '/strains2/category/medical/eye-pressure',
    [EStrainCategories.MedicalFatigue]: '/strains2/category/medical/fatigue',
    [EStrainCategories.MedicalFibromyalgia]: '/strains2/category/medical/fibromyalgia',
    [EStrainCategories.MedicalGastrointestinalDisorder]: '/strains2/category/medical/gastrointestinal-disorder',
    [EStrainCategories.MedicalGlaucoma]: '/strains2/category/medical/glaucoma',
    [EStrainCategories.MedicalHeadaches]: '/strains2/category/medical/headaches',
    [EStrainCategories.MedicalHivAids]: '/strains2/category/medical/hiv-aids',
    [EStrainCategories.MedicalHypertension]: '/strains2/category/medical/hypertension',
    [EStrainCategories.MedicalInflammation]: '/strains2/category/medical/inflammation',
    [EStrainCategories.MedicalInsomnia]: '/strains2/category/medical/insomnia',
    [EStrainCategories.MedicalLackOfAppetite]: '/strains2/category/medical/lack-of-appetite',
    [EStrainCategories.MedicalMigraines]: '/strains2/category/medical/migraines',
    [EStrainCategories.MedicalMultipleSclerosis]: '/strains2/category/medical/multiple-sclerosis',
    [EStrainCategories.MedicalMuscleSpasms]: '/strains2/category/medical/muscle-spasms',
    [EStrainCategories.MedicalMuscularDystrophy]: '/strains2/category/medical/muscular-dystrophy',
    [EStrainCategories.MedicalNausea]: '/strains2/category/medical/nausea',
    [EStrainCategories.MedicalPain]: '/strains2/category/medical/pain',
    [EStrainCategories.MedicalParkinsons]: '/strains2/category/medical/parkinsons',
    [EStrainCategories.MedicalPhantomLimbPain]: '/strains2/category/medical/phantom-limb-pain',
    [EStrainCategories.MedicalPms]: '/strains2/category/medical/pms',
    [EStrainCategories.MedicalPtsd]: '/strains2/category/medical/ptsd',
    [EStrainCategories.MedicalSeizures]: '/strains2/category/medical/seizures',
    [EStrainCategories.MedicalSpasticity]: '/strains2/category/medical/spasticity',
    [EStrainCategories.MedicalSpinalCordInjury]: '/strains2/category/medical/spinal-cord-injury',
    [EStrainCategories.MedicalStress]: '/strains2/category/medical/stress',
    [EStrainCategories.MedicalTinnitus]: '/strains2/category/medical/tinnitus',
    [EStrainCategories.MedicalTourettesSyndrome]: '/strains2/category/medical/tourettes-syndrome',
  
    // ────────── Tastes ──────────
    [EStrainCategories.TastesAmmonia]: '/strains2/category/tastes/ammonia',
    [EStrainCategories.TastesApple]: '/strains2/category/tastes/apple',
    [EStrainCategories.TastesApricot]: '/strains2/category/tastes/apricot',
    [EStrainCategories.TastesBerry]: '/strains2/category/tastes/berry',
    [EStrainCategories.TastesBlueCheese]: '/strains2/category/tastes/blue-cheese',
    [EStrainCategories.TastesBlueberry]: '/strains2/category/tastes/blueberry',
    [EStrainCategories.TastesButter]: '/strains2/category/tastes/butter',
    [EStrainCategories.TastesCheese]: '/strains2/category/tastes/cheese',
    [EStrainCategories.TastesChemical]: '/strains2/category/tastes/chemical',
    [EStrainCategories.TastesChestnut]: '/strains2/category/tastes/chestnut',
    [EStrainCategories.TastesCitrus]: '/strains2/category/tastes/citrus',
    [EStrainCategories.TastesCoffee]: '/strains2/category/tastes/coffee',
    [EStrainCategories.TastesCream]: '/strains2/category/tastes/cream',
    [EStrainCategories.TastesDiesel]: '/strains2/category/tastes/diesel',
    [EStrainCategories.TastesEarthy]: '/strains2/category/tastes/earthy',
    [EStrainCategories.TastesFlowery]: '/strains2/category/tastes/flowery',
    [EStrainCategories.TastesFruity]: '/strains2/category/tastes/fruity',
    [EStrainCategories.TastesGrape]: '/strains2/category/tastes/grape',
    [EStrainCategories.TastesGrapefruit]: '/strains2/category/tastes/grapefruit',
    [EStrainCategories.TastesHoney]: '/strains2/category/tastes/honey',
    [EStrainCategories.TastesLavender]: '/strains2/category/tastes/lavender',
    [EStrainCategories.TastesLemon]: '/strains2/category/tastes/lemon',
    [EStrainCategories.TastesLime]: '/strains2/category/tastes/lime',
    [EStrainCategories.TastesMango]: '/strains2/category/tastes/mango',
    [EStrainCategories.TastesMenthol]: '/strains2/category/tastes/menthol',
    [EStrainCategories.TastesMint]: '/strains2/category/tastes/mint',
    [EStrainCategories.TastesNutty]: '/strains2/category/tastes/nutty',
    [EStrainCategories.TastesOrange]: '/strains2/category/tastes/orange',
    [EStrainCategories.TastesPeach]: '/strains2/category/tastes/peach',
    [EStrainCategories.TastesPear]: '/strains2/category/tastes/pear',
    [EStrainCategories.TastesPepper]: '/strains2/category/tastes/pepper',
    [EStrainCategories.TastesPine]: '/strains2/category/tastes/pine',
    [EStrainCategories.TastesPineapple]: '/strains2/category/tastes/pineapple',
    [EStrainCategories.TastesPlum]: '/strains2/category/tastes/plum',
    [EStrainCategories.TastesPungent]: '/strains2/category/tastes/pungent',
    [EStrainCategories.TastesRose]: '/strains2/category/tastes/rose',
    [EStrainCategories.TastesSage]: '/strains2/category/tastes/sage',
    [EStrainCategories.TastesSkunk]: '/strains2/category/tastes/skunk',
    [EStrainCategories.TastesSour]: '/strains2/category/tastes/sour',
    [EStrainCategories.TastesSpicyHerbal]: '/strains2/category/tastes/spicy-herbal',
    [EStrainCategories.TastesStrawberry]: '/strains2/category/tastes/strawberry',
    [EStrainCategories.TastesSweet]: '/strains2/category/tastes/sweet',
    [EStrainCategories.TastesTar]: '/strains2/category/tastes/tar',
    [EStrainCategories.TastesTea]: '/strains2/category/tastes/tea',
    [EStrainCategories.TastesTobacco]: '/strains2/category/tastes/tobacco',
    [EStrainCategories.TastesTreeFruit]: '/strains2/category/tastes/tree-fruit',
    [EStrainCategories.TastesTropical]: '/strains2/category/tastes/tropical',
    [EStrainCategories.TastesVanilla]: '/strains2/category/tastes/vanilla',
    [EStrainCategories.TastesViolet]: '/strains2/category/tastes/violet',
    [EStrainCategories.TastesWoody]: '/strains2/category/tastes/woody',
  
    // ────────── Effects Positive ──────────
    [EStrainCategories.PositiveEffectsAroused]: '/strains2/category/positive-effects/aroused',
    [EStrainCategories.PositiveEffectsCreative]: '/strains2/category/positive-effects/creative',
    [EStrainCategories.PositiveEffectsEnergetic]: '/strains2/category/positive-effects/energetic',
    [EStrainCategories.PositiveEffectsEuphoric]: '/strains2/category/positive-effects/euphoric',
    [EStrainCategories.PositiveEffectsFocused]: '/strains2/category/positive-effects/focused',
    [EStrainCategories.PositiveEffectsGiggly]: '/strains2/category/positive-effects/giggly',
    [EStrainCategories.PositiveEffectsHappy]: '/strains2/category/positive-effects/happy',
    [EStrainCategories.PositiveEffectsHungry]: '/strains2/category/positive-effects/hungry',
    [EStrainCategories.PositiveEffectsRelaxed]: '/strains2/category/positive-effects/relaxed',
    [EStrainCategories.PositiveEffectsSleepy]: '/strains2/category/positive-effects/sleepy',
    [EStrainCategories.PositiveEffectsTalkative]: '/strains2/category/positive-effects/talkative',
    [EStrainCategories.PositiveEffectsTingly]: '/strains2/category/positive-effects/tingly',
    [EStrainCategories.PositiveEffectsUplifted]: '/strains2/category/positive-effects/uplifted',
  
    // ────────── Effects Negative ──────────
    [EStrainCategories.NegativeEffectsAnxious]: '/strains2/category/negative-effects/anxious',
    [EStrainCategories.NegativeEffectsDizzy]: '/strains2/category/negative-effects/dizzy',
    [EStrainCategories.NegativeEffectsDryEyes]: '/strains2/category/negative-effects/dry-eyes',
    [EStrainCategories.NegativeEffectsDryMouth]: '/strains2/category/negative-effects/dry-mouth',
    [EStrainCategories.NegativeEffectsHeadache]: '/strains2/category/negative-effects/headache',
    [EStrainCategories.NegativeEffectsParanoid]: '/strains2/category/negative-effects/paranoid',
  
    // ────────── Difficulty ──────────
    [EStrainCategories.DifficultyDifficult]: '/strains2/category/difficulty/difficult',
    [EStrainCategories.DifficultyEasy]: '/strains2/category/difficulty/easy',
    [EStrainCategories.DifficultyModerate]: '/strains2/category/difficulty/moderate',
  
    // ────────── Height ──────────
    [EStrainCategories.HeightMedium]: '/strains2/category/height/medium',
    [EStrainCategories.HeightTall]: '/strains2/category/height/tall',
    [EStrainCategories.HeightShort]: '/strains2/category/height/short',
  
    // ────────── Yield ──────────
    [EStrainCategories.YieldHigh]: '/strains2/category/yield/high',
    [EStrainCategories.YieldLow]: '/strains2/category/yield/low',
    [EStrainCategories.YieldMedium]: '/strains2/category/yield/medium',
  
    // ────────── THC ──────────
    [EStrainCategories.ThcHigh]: '/strains2/category/thc/high',
    [EStrainCategories.ThcLow]: '/strains2/category/thc/low',
    [EStrainCategories.ThcMedium]: '/strains2/category/thc/medium',
  };
  
  
  
   
  export const EStrainsFilters = ref([
    // ────────────── GENES ──────────────
    {
      id: 'genes',
      name: 'Genes',
      type: 'icon-buttons',
      tags: [
        {
          id: EStrainCategories.GenesIndica,
          image: RStrainCategoriesImages[EStrainCategories.GenesIndica],
          label: 'Indica',
        },
        {
          id: EStrainCategories.GenesHybrid,
          image: RStrainCategoriesImages[EStrainCategories.GenesHybrid],
          label: 'Hybrid',
        },
        {
          id: EStrainCategories.GenesSativa,
          image: RStrainCategoriesImages[EStrainCategories.GenesSativa],
          label: 'Sativa',
        },
      ],
    },
  
    // ────────────── MEDICAL (Helps with...) ──────────────
    {
      id: 'medical',
      name: 'Helps with',
      type: 'checkboxes',
      searchable: true,
      tags: [
        {
          id: EStrainCategories.MedicalAddAdhd,
          label: 'ADD/ADHD',
        },
        {
          id: EStrainCategories.MedicalAlzheimers,
          label: 'Alzheimer’s',
        },
        {
          id: EStrainCategories.MedicalAnorexia,
          label: 'Anorexia',
        },
        {
          id: EStrainCategories.MedicalAnxiety,
          label: 'Anxiety',
        },
        {
          id: EStrainCategories.MedicalArthritis,
          label: 'Arthritis',
        },
        {
          id: EStrainCategories.MedicalAsthma,
          label: 'Asthma',
        },
        {
          id: EStrainCategories.MedicalBipolarDisorder,
          label: 'Bipolar disorder',
        },
        {
          id: EStrainCategories.MedicalCachexia,
          label: 'Cachexia',
        },
        {
          id: EStrainCategories.MedicalCancer,
          label: 'Cancer',
        },
        {
          id: EStrainCategories.MedicalCramps,
          label: 'Cramps',
        },
        {
          id: EStrainCategories.MedicalCrohnsDisease,
          label: 'Crohn’s disease',
        },
        {
          id: EStrainCategories.MedicalDepression,
          label: 'Depression',
        },
        {
          id: EStrainCategories.MedicalEpilepsy,
          label: 'Epilepsy',
        },
        {
          id: EStrainCategories.MedicalEyePressure,
          label: 'Eye pressure',
        },
        {
          id: EStrainCategories.MedicalFatigue,
          label: 'Fatigue',
        },
        {
          id: EStrainCategories.MedicalFibromyalgia,
          label: 'Fibromyalgia',
        },
        {
          id: EStrainCategories.MedicalGastrointestinalDisorder,
          label: 'Gastrointestinal disorder',
        },
        {
          id: EStrainCategories.MedicalGlaucoma,
          label: 'Glaucoma',
        },
        {
          id: EStrainCategories.MedicalHeadaches,
          label: 'Headaches',
        },
        {
          id: EStrainCategories.MedicalHivAids,
          label: 'HIV/AIDS',
        },
        {
          id: EStrainCategories.MedicalHypertension,
          label: 'Hypertension',
        },
        {
          id: EStrainCategories.MedicalInflammation,
          label: 'Inflammation',
        },
        {
          id: EStrainCategories.MedicalInsomnia,
          label: 'Insomnia',
        },
        {
          id: EStrainCategories.MedicalLackOfAppetite,
          label: 'Lack of appetite',
        },
        {
          id: EStrainCategories.MedicalMigraines,
          label: 'Migraines',
        },
        {
          id: EStrainCategories.MedicalMultipleSclerosis,
          label: 'Multiple sclerosis',
        },
        {
          id: EStrainCategories.MedicalMuscleSpasms,
          label: 'Muscle spasms',
        },
        {
          id: EStrainCategories.MedicalMuscularDystrophy,
          label: 'Muscular dystrophy',
        },
        {
          id: EStrainCategories.MedicalNausea,
          label: 'Nausea',
        },
        {
          id: EStrainCategories.MedicalPain,
          label: 'Pain',
        },
        {
          id: EStrainCategories.MedicalParkinsons,
          label: 'Parkinson’s',
        },
        {
          id: EStrainCategories.MedicalPhantomLimbPain,
          label: 'Phantom limb pain',
        },
        {
          id: EStrainCategories.MedicalPms,
          label: 'PMS',
        },
        {
          id: EStrainCategories.MedicalPtsd,
          label: 'PTSD',
        },
        {
          id: EStrainCategories.MedicalSeizures,
          label: 'Seizures',
        },
        {
          id: EStrainCategories.MedicalSpasticity,
          label: 'Spasticity',
        },
        {
          id: EStrainCategories.MedicalSpinalCordInjury,
          label: 'Spinal cord injury',
        },
        {
          id: EStrainCategories.MedicalStress,
          label: 'Stress',
        },
        {
          id: EStrainCategories.MedicalTinnitus,
          label: 'Tinnitus',
        },
        {
          id: EStrainCategories.MedicalTourettesSyndrome,
          label: 'Tourette’s syndrome',
        },
      ],
    },
  
    // ────────────── TASTES ──────────────
    {
      id: 'tastes',
      name: 'Tastes',
      type: 'checkboxes',
      searchable: true,
      tags: [
        {
          id: EStrainCategories.TastesAmmonia,
          label: 'Ammonia',
        },
        {
          id: EStrainCategories.TastesApple,
          label: 'Apple',
        },
        {
          id: EStrainCategories.TastesApricot,
          label: 'Apricot',
        },
        {
          id: EStrainCategories.TastesBerry,
          label: 'Berry',
        },
        {
          id: EStrainCategories.TastesBlueCheese,
          label: 'Blue cheese',
        },
        {
          id: EStrainCategories.TastesBlueberry,
          label: 'Blueberry',
        },
        {
          id: EStrainCategories.TastesButter,
          label: 'Butter',
        },
        {
          id: EStrainCategories.TastesCheese,
          label: 'Cheese',
        },
        {
          id: EStrainCategories.TastesChemical,
          label: 'Chemical',
        },
        {
          id: EStrainCategories.TastesChestnut,
          label: 'Chestnut',
        },
        {
          id: EStrainCategories.TastesCitrus,
          label: 'Citrus',
        },
        {
          id: EStrainCategories.TastesCoffee,
          label: 'Coffee',
        },
        {
          id: EStrainCategories.TastesCream,
          label: 'Cream',
        },
        {
          id: EStrainCategories.TastesDiesel,
          label: 'Diesel',
        },
        {
          id: EStrainCategories.TastesEarthy,
          label: 'Earthy',
        },
        {
          id: EStrainCategories.TastesFlowery,
          label: 'Flowery',
        },
        {
          id: EStrainCategories.TastesFruity,
          label: 'Fruity',
        },
        {
          id: EStrainCategories.TastesGrape,
          label: 'Grape',
        },
        {
          id: EStrainCategories.TastesGrapefruit,
          label: 'Grapefruit',
        },
        {
          id: EStrainCategories.TastesHoney,
          label: 'Honey',
        },
        {
          id: EStrainCategories.TastesLavender,
          label: 'Lavender',
        },
        {
          id: EStrainCategories.TastesLemon,
          label: 'Lemon',
        },
        {
          id: EStrainCategories.TastesLime,
          label: 'Lime',
        },
        {
          id: EStrainCategories.TastesMango,
          label: 'Mango',
        },
        {
          id: EStrainCategories.TastesMenthol,
          label: 'Menthol',
        },
        {
          id: EStrainCategories.TastesMint,
          label: 'Mint',
        },
        {
          id: EStrainCategories.TastesNutty,
          label: 'Nutty',
        },
        {
          id: EStrainCategories.TastesOrange,
          label: 'Orange',
        },
        {
          id: EStrainCategories.TastesPeach,
          label: 'Peach',
        },
        {
          id: EStrainCategories.TastesPear,
          label: 'Pear',
        },
        {
          id: EStrainCategories.TastesPepper,
          label: 'Pepper',
        },
        {
          id: EStrainCategories.TastesPine,
          label: 'Pine',
        },
        {
          id: EStrainCategories.TastesPineapple,
          label: 'Pineapple',
        },
        {
          id: EStrainCategories.TastesPlum,
          label: 'Plum',
        },
        {
          id: EStrainCategories.TastesPungent,
          label: 'Pungent',
        },
        {
          id: EStrainCategories.TastesRose,
          label: 'Rose',
        },
        {
          id: EStrainCategories.TastesSage,
          label: 'Sage',
        },
        {
          id: EStrainCategories.TastesSkunk,
          label: 'Skunk',
        },
        {
          id: EStrainCategories.TastesSour,
          label: 'Sour',
        },
        {
          id: EStrainCategories.TastesSpicyHerbal,
          label: 'Spicy/Herbal',
        },
        {
          id: EStrainCategories.TastesStrawberry,
          label: 'Strawberry',
        },
        {
          id: EStrainCategories.TastesSweet,
          label: 'Sweet',
        },
        {
          id: EStrainCategories.TastesTar,
          label: 'Tar',
        },
        {
          id: EStrainCategories.TastesTea,
          label: 'Tea',
        },
        {
          id: EStrainCategories.TastesTobacco,
          label: 'Tobacco',
        },
        {
          id: EStrainCategories.TastesTreeFruit,
          label: 'Tree fruit',
        },
        {
          id: EStrainCategories.TastesTropical,
          label: 'Tropical',
        },
        {
          id: EStrainCategories.TastesVanilla,
          label: 'Vanilla',
        },
        {
          id: EStrainCategories.TastesViolet,
          label: 'Violet',
        },
        {
          id: EStrainCategories.TastesWoody,
          label: 'Woody',
        },
      ],
    },
  
    // ────────────── POSITIVE EFFECTS ──────────────
    {
      id: 'positive_effects',
      name: 'Effects',
      type: 'icon-buttons',
      tags: [
        {
          id: EStrainCategories.PositiveEffectsAroused,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsAroused],
          label: 'Aroused',
        },
        {
          id: EStrainCategories.PositiveEffectsCreative,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsCreative],
          label: 'Creative',
        },
        {
          id: EStrainCategories.PositiveEffectsEnergetic,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsEnergetic],
          label: 'Energetic',
        },
        {
          id: EStrainCategories.PositiveEffectsEuphoric,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsEuphoric],
          label: 'Euphoric',
        },
        {
          id: EStrainCategories.PositiveEffectsFocused,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsFocused],
          label: 'Focused',
        },
        {
          id: EStrainCategories.PositiveEffectsGiggly,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsGiggly],
          label: 'Giggly',
        },
        {
          id: EStrainCategories.PositiveEffectsHappy,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsHappy],
          label: 'Happy',
        },
        {
          id: EStrainCategories.PositiveEffectsHungry,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsHungry],
          label: 'Hungry',
        },
        {
          id: EStrainCategories.PositiveEffectsRelaxed,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsRelaxed],
          label: 'Relaxed',
        },
        {
          id: EStrainCategories.PositiveEffectsSleepy,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsSleepy],
          label: 'Sleepy',
        },
        {
          id: EStrainCategories.PositiveEffectsTalkative,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsTalkative],
          label: 'Talkative',
        },
        {
          id: EStrainCategories.PositiveEffectsTingly,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsTingly],
          label: 'Tingly',
        },
        {
          id: EStrainCategories.PositiveEffectsUplifted,
          image: RStrainCategoriesImages[EStrainCategories.PositiveEffectsUplifted],
          label: 'Uplifted',
        },
      ],
    },
  
    // ────────────── NEGATIVE EFFECTS ──────────────
    {
      id: 'negative_effects',
      name: 'Effects to avoid',
      type: 'icon-buttons',
      tags: [
        {
          id: EStrainCategories.NegativeEffectsAnxious,
          image: RStrainCategoriesImages[EStrainCategories.NegativeEffectsAnxious],
          label: 'Anxious',
        },
        {
          id: EStrainCategories.NegativeEffectsDizzy,
          image: RStrainCategoriesImages[EStrainCategories.NegativeEffectsDizzy],
          label: 'Dizzy',
        },
        {
          id: EStrainCategories.NegativeEffectsDryEyes,
          image: RStrainCategoriesImages[EStrainCategories.NegativeEffectsDryEyes],
          label: 'Dry eyes',
        },
        {
          id: EStrainCategories.NegativeEffectsDryMouth,
          image: RStrainCategoriesImages[EStrainCategories.NegativeEffectsDryMouth],
          label: 'Dry mouth',
        },
        {
          id: EStrainCategories.NegativeEffectsHeadache,
          image: RStrainCategoriesImages[EStrainCategories.NegativeEffectsHeadache],
          label: 'Headache',
        },
        {
          id: EStrainCategories.NegativeEffectsParanoid,
          image: RStrainCategoriesImages[EStrainCategories.NegativeEffectsParanoid],
          label: 'Paranoid',
        },
      ],
    },
  
    // ────────────── DIFFICULTY ──────────────
    {
      id: 'difficulty',
      name: 'Difficulty',
      type: 'checkboxes',
      tags: [
        {
          id: EStrainCategories.DifficultyEasy,
          label: 'Easy',
        },
        {
          id: EStrainCategories.DifficultyModerate,
          label: 'Moderate',
        },
        {
          id: EStrainCategories.DifficultyDifficult,
          label: 'Difficult',
        },
      ],
    },
  
    // ────────────── HEIGHT ──────────────
    {
      id: 'height',
      name: 'Height',
      type: 'icon-buttons',
      tags: [
        {
          id: EStrainCategories.HeightShort,
          image: RStrainCategoriesImages[EStrainCategories.HeightShort],
          label: 'Short',
        },
        {
          id: EStrainCategories.HeightMedium,
          image: RStrainCategoriesImages[EStrainCategories.HeightMedium],
          label: 'Medium',
        },
        {
          id: EStrainCategories.HeightTall,
          image: RStrainCategoriesImages[EStrainCategories.HeightTall],
          label: 'Tall',
        },
      ],
    },
  
    // ────────────── YIELD ──────────────
    {
      id: 'yield',
      name: 'Yield',
      type: 'icon-buttons',
      tags: [
        {
          id: EStrainCategories.YieldLow,
          image: RStrainCategoriesImages[EStrainCategories.YieldLow],
          label: 'Low Yeld',
        },
        {
          id: EStrainCategories.YieldMedium,
          image: RStrainCategoriesImages[EStrainCategories.YieldMedium],
          label: 'Medium Yeld',
        },
        {
          id: EStrainCategories.YieldHigh,
          image: RStrainCategoriesImages[EStrainCategories.YieldHigh],
          label: 'High Yeld',
        },
      ],
    },
  
    // ────────────── THC ──────────────
    {
      id: 'thc',
      name: 'THC level',
      type: 'icon-buttons',
      tags: [
        {
          id: EStrainCategories.ThcLow,
          image: RStrainCategoriesImages[EStrainCategories.ThcLow],
          label: 'Low THC',
        },
        {
          id: EStrainCategories.ThcMedium,
          image: RStrainCategoriesImages[EStrainCategories.ThcMedium],
          label: 'Medium THC',
        },
        {
          id: EStrainCategories.ThcHigh,
          image: RStrainCategoriesImages[EStrainCategories.ThcHigh],
          label: 'High THC',
        },
      ],
    },
  ])

export default defineNuxtPlugin(nuxtApp => {

  var constants = {
    
    effectPositive:{
      "1": "creative",
      "2": "energetic",
      "3": "euphoric",
      "4": "giggly",
      "5": "happy",
      "6": "hungry",
      "7": "relaxed",
      "8": "sleepy",
      "9": "talkative",
      "10": "uplifted"
    },

    effectNegative:{
      "11": "dry_eyes",
      "12": "dry_mouth",
      "13": "insomnia",
      "14": "paranoia",
    },

    effectMedical:{
      "18": "anxiety",
      "19": "depression",
      "17": "insomnia",
      "16": "pain",
      "15": "stress",
    },

    taste:{
      "1": "berries",
      "12": "caramel",
      "2": "cheese",
      "13": "chocolate",
      "3": "citrus",
      "14": "cream",
      "4": "diesel",
      "5": "earthy",
      "6": "flowery",
      "15": "fruity",
      "16": "herbs",
      "7": "mint",
      "8": "nutty",
      "17": "pine",
      "9": "sweet",
      "10": "tropical",
      "11": "woody",
      "18": "pungent",
      "19": "sour",
    },


    medium:{
      "1": 'universal_type_medium_soil',
      "2": 'universal_type_medium_perlite',
      "3": 'universal_type_medium_vermiculite',
      "4": 'universal_type_medium_expanded_clay',
      "5": 'universal_type_medium_coco_coir',
      "6": 'universal_type_medium_mineral_wool',
      "7": 'universal_type_medium_other',
    },


    mediumSections:{
      "1": 'soil',
      "2": 'perlite',
      "3": 'vermiculite',
      "4": 'expanded-clay',
      "5": 'coco-coir',
      "6": 'mineral-wool',
      "7": 'other',
    },


    nutState:{
      "0": '',
      "1": 'nut_state_mll',
      "2": 'nut_state_gl',
      "3": 'nut_state_other',
    },
    nutStateLabel:{
      "0": '',
      "1": 'nut_state_label_mll',
      "2": 'nut_state_label_gl',
      "3": '',
    },
    nutType:{
      "0": '',
      "1": 'nut_type_classic',
      "2": 'nut_type_technical',
      "3": 'nut_type_experimental',
    },
    nutStyle:{
      "0": '',
      "1": 'nut_style_solution_constant',
      "2": 'nut_style_solution_one_time',
      "3": 'nut_style_spray_on_leafs',
      "4": 'nut_style_dissolve_in_substrate',
      "5": 'nut_style_injection',
    },
    maxVote:{
      "1": 1,
      "2": 1,
      "3": 1,
      "4": 1,
      "5": 1,
      "100": 0,
    },
    feedingSchedule:{
      "0":'-',
      "1":'universal_feeding_schedule_1',
      "2":'universal_feeding_schedule_2',
      "3":'universal_feeding_schedule_3',
      "4":'universal_feeding_schedule_4',
      "5":'universal_feeding_schedule_5',
      "6":'universal_feeding_schedule_6',
      "7":'universal_feeding_schedule_7',
      "8":'universal_feeding_schedule_8',
      "9":'universal_feeding_schedule_9',
      "10":'universal_feeding_schedule_10',
      "11":'universal_feeding_schedule_11',
    },
    typeRoom:{
      "1":'universal_type_room_indoor',
      "2":'universal_type_room_outdoor',
      "3":'universal_type_room_greenhouse',
    },
    // typeRoomImg:{
    //   "1": {
    //     tr: 'universal_type_room_indoor',
    //     img: '/images/setup_diary/setup_diary_icons-01.svg',
    //     desc: 'Indoor – Suitable for interior spaces, providing a controlled and comfortable environment for various activities.',
    //   },
    //   "2": {
    //     tr: 'universal_type_room_outdoor',
    //     img: '/images/setup_diary/setup_diary_icons-02.svg',
    //     desc: 'Outdoor – Ideal for exterior areas, designed for open-air use and interactions with nature.',
    //   },
    //   "3": {
    //     tr: 'universal_type_room_greenhouse',
    //     img: '/images/setup_diary/setup_diary_icons-19.svg',
    //     desc: 'Greenhouse – Perfect for cultivating plants in a protected, climate-controlled environment to optimize growth.',
    //   },
    // },
    // typeLampImg:{
    //   "1": {
    //     tr: 'universal_type_light_fl',
    //     img: '/images/setup_diary/setup_diary_icons-06.svg',
    //     desc: 'Fluorescent – Energy-efficient lighting option that emits a soft, diffused light suitable for seedlings and clones.',
    //   },
    //   "2": {
    //     tr: 'universal_type_light_hid',
    //     img: '/images/setup_diary/setup_diary_icons-07.svg',
    //     desc: 'HID – High-intensity discharge lighting system that produces bright, intense light for optimal plant growth and flowering.',
    //   },
    //   "3": {
    //     tr: 'universal_type_light_led',
    //     img: '/images/setup_diary/setup_diary_icons-08.svg',
    //     desc: 'LED – Light-emitting diode technology that provides a full spectrum of light for efficient and effective plant growth.',
    //   },
    // },
    postSmell:{
      "1":'set_nosmell',
      "2":'set_weak',
      "3":'set_normal',
      "4":'set_strong',
    },
    typeSoil:{
      "1":'universal_type_soil_soil',
      "2":'universal_type_soil_hydro',
      "3":'universal_type_soil_soilless',
    },
    typeFood:{
      //not uni   not use!!!
      "1":'Leaf',
      "2":'Roots',
      "3":'Mix',
    },
    typeWatering:{
      "1": "universal_type_watering_manual",
      "2": "universal_type_watering_drip",
      "3": "universal_type_watering_hydroponics",
      "4": "universal_type_watering_aeroponics",
    },
    // typeWateringImg:{
    //   "1": {
    //     tr: 'universal_type_watering_manual',
    //     img: '/images/setup_diary/watering_manual.svg',
    //     desc: 'Manual Watering – Traditional method involving manually delivering water to plants, allowing precise control over moisture levels.',
    //   },
    //   "2": {
    //     tr: 'universal_type_watering_drip',
    //     img: '/images/setup_diary/watering_drip.svg',
    //     desc: 'Drip Watering – An irrigation system that delivers water directly to plant roots through a network of tubes and emitters, ensuring efficient and consistent moisture distribution.',
    //   },
    //   "3": {
    //     tr: 'universal_type_watering_hydroponics',
    //     img: '/images/setup_diary/watering_hydroponics.svg',
    //     desc: 'Hydroponics – Soilless cultivation method where plants grow in a nutrient-rich water solution, enabling faster growth and higher yields.',
    //   },
    //   "4": {
    //     tr: 'universal_type_watering_aeroponics',
    //     img: '/images/setup_diary/watering_aeroponics.svg',
    //     desc: 'Aeroponics – Advanced irrigation technique where plant roots are suspended in the air and misted with a nutrient solution, promoting rapid growth and efficient nutrient absorption.',
    //   },
    // },
    // typeMethod:{
    //   //"1":'No Technique',
    //   "2":'universal_type_method_lst',
    //   "3":'universal_type_method_hst',
    //   "4":'universal_type_method_sog',
    //   "5":'universal_type_method_scrog',
    //   "6":'universal_type_method_topping',
    //   "7":'universal_type_method_fiming',
    //   "8":'universal_type_method_main_lining',
    //   "9":'universal_type_method_defoliation',
    //   //"10":'Manifolding',
    //   "11":'universal_type_method_12_12',
    //   "12":'universal_type_transplantation',
    // },
    // typeMethodShort:{

    //   //"1":'No Technique',
    //   "2":'universal_type_method_short_lst',
    //   "3":'universal_type_method_short_hst',
    //   "4":'universal_type_method_short_sog',
    //   "5":'universal_type_method_short_scrog',
    //   "6":'universal_type_method_short_topping',
    //   "7":'universal_type_method_short_fiming',
    //   "8":'universal_type_method_short_main_lining',
    //   "9":'universal_type_method_short_defoliation',
    //   //"10":'Manifolding',
    //   "11":'universal_type_method_short_12_12',
    //   "12":'universal_type_method_short_transplantation',
    // },
    // typeMethodCode:{
    //   //"1":'No Technique',
    //   "2":'lst',
    //   "3":'hst',
    //   "4":'sog',
    //   "5":'scrog',
    //   "6":'topping',
    //   "7":'fiming',
    //   "8":'main',
    //   "9":'defoliation',
    //   "10":'manifolding',
    //   "11":'1212',
    //   "12":'transplantation',
    // },
    typeGermMethodShort:{
      "1":'universal_type_germ_method_paper',
      "2":'universal_type_germ_method_glass',
      // "3":'universal_type_germ_method_chamber',
      "4":'universal_type_germ_method_cube',
      "5":'universal_type_germ_method_pellet',
      "6":'universal_type_germ_method_substrate',
      "7":'universal_type_germ_method_other',
    },
    typeGermMethodSuccess:{
      "1": 93,
      "2": 93,
      "3": 0,
      "4": 92,
      "5": 92,
      "6": 93,
      "7": 93,
    },
    typeGermMethodPopularity:{
      "1": 40,
      "2": 23,
      "3": 0,
      "4": 4,
      "5": 7,
      "6": 20,
      "7": 6,
    },

    typeLight:{
      "1":'universal_type_light_fl',
      "2":'universal_type_light_hid',
      "3":'universal_type_light_led',
    },

    typeLightShort:{
      "1":'universal_type_light_short_fl',
      "2":'universal_type_light_short_hid',
      "3":'universal_type_light_short_led',
    },

    typeLightWatt:{
      "1": 150,
      "2": 250,
      "3": 400,
      "4": 600,
      "5": 1000,
    },
    typeLampType: [
      {
        active: false,
        name: 'universal_type_light_short_fl',
      },
      {
        active: false,
        name: 'universal_type_light_short_hid',
      },
      {
        active: false,
        name: 'universal_type_light_short_led',
      }
    ],
    typeControllersControls: [
      {
        active: true,
        name: 'humidifiers'
      },
      {
        active: false,
        name: 'dehumidifiers'
      },
      {
        active: false,
        name: 'CO2'
      },
      {
        active: false,
        name: 'ventilation'
      },
      {
        active: false,
        name: 'air_conditioners'
      },
      {
        active: false,
        name: 'lights'
      },
    ],

    typeHydroponicSystems: [
      {
        active: true,
        name: 'universal_type_drip_system'
      },
      {
        active: false,
        name: 'universal_type_wick_system'
      },
      {
        active: false,
        name: 'universal_type_dwc'
      },
      {
        active: false,
        name: 'universal_type_ebb'
      },
      {
        active: false,
        name: 'universal_type_nft'
      },
      {
        active: false,
        name:  'universal_type_aeroponics'
      }
    ],
    typeControllersSensors: [
      {
        active: true,
        name: 'air_temperature'
      },
      {
        active: false,
        name: 'water_temperature'
      },
      {
        active: false,
        name: 'soil_temperature'
      },
      {
        active: false,
        name: 'humidity'
      },
      {
        active: false,
        name: 'CO2'
      },
      {
        active: false,
        name: 'par'
      },
      {
        active: false,
        name: 'water_ph'
      },
      {
        active: false,
        name: 'water_ec_tds'
      },
    ],
    typeControllersTypes: [
      {
        active: true,
        name: 'environment'
      },
      {
        active: false,
        name: 'watering'
      },
    ],
    typeBoxSizes: [
      {
        active: true,
        name: 'universal_type_size_cm'
      },
      {
        active: false,
        name: 'universal_type_size_inch'
      }
    ],

    typeDripSystems: [
      {
        active: true,
        name: 'universal_type_liter'
      },
      {
        active: false,
        name: 'universal_type_gallon'
      }
    ],
    typeAirFilters: [
      {
        active: true,
        name: 'universal_type_intake'
      },
      {
        active: false,
        name: 'universal_type_exhaust'
      }
    ],
    typeLightHigh:{
      "150W":'150W',
      "250W":'250W',
      "400W":'400W',
      "600W":'600W',
      "1000W":'1000W',
    },
    harvestResistance:{
      "1":'universal_harvest_resistance_weak',
      "2":'universal_harvest_resistance_neutral',
      "3":'universal_harvest_resistance_strong',
    },
    harvestGrowing:{
      "1":'universal_harvest_growing_easy',
      "2":'universal_harvest_growing_normal',
      "3":'universal_harvest_growing_hard',
    },
    typeFaza:{
      "-1":'universal_type_faza_ger',
      "0":'universal_type_faza_veg',
      "1":'universal_type_faza_flo',
      "2":'universal_type_faza_har',
    },
    // typeFazaShort:{
    //   "-1":'universal_type_faza_short_ger',
    //   "0":'universal_type_faza_short_veg',
    //   "1":'universal_type_faza_short_flo',
    //   "2":'universal_type_faza_short_har',
    // },
    typeSeed:{

      "fem":'universal_type_seed_fem',
      "reg":'universal_type_seed_reg',
      "auto":'universal_type_seed_auto',
      "mix":'universal_type_seed_mix',
      "clone":'universal_type_seed_clone',
      "test":'universal_category_test',
    },

    typeSeedIco:{
      "fem":'<span class="icn_par icon-fem"></span>',
      "reg":'<span class="icn_par icon-reg"></span>',
      "auto":'<span class="icn_par icon-auto"></span>',
      "mix":'<span class="icn_par icon-mix"></span>',
      "clone":'<span class="icn_par icon-mix"></span>',
    },

    typeGen:{
      "1":'Indica',
      "2":'Sativa',
      "3":'More indica',
      "4":'More sativa',
      "5":'Sativa/Indica',
      "6":'Sativa/Ruderalis',
      "7":'Indica/Ruderalis',
      "8":'Sativa/Indica/Ruderalis',
    },
    typeGens:{
      //not uni ?  not use!!!
      "I":'Indica',
      "S":'Sativa',
      "mI":'More indica',
      "mS":'More sativa',
      "SI":'Sativa/Indica',
      "SR":'Sativa/Ruderalis',
      "IR":'Indica/Ruderalis',
      "SIR":'Sativa/Indica/Ruderalis',
    },

    units:{
      length: [
        { id: 'metric', name: 'set_cm' },
        { id: 'english', name: 'set_inch' },
      ],
      height: [
        { id: 'metric', name: 'set_cm' },
        { id: 'english', name: 'set_inch' },
      ],
      square: [
        { id: 'metric', name: 'set_sqm' },
        { id: 'english', name: 'set_sqft' },
      ],
      weight: [
        { id: 'metric', name: 'set_gram_full' },
        { id: 'english', name: 'set_ounce_full' },
      ],
      volume: [
        { id: 'l', name: 'set_lilers' },
        { id: 'gal', name: 'set_gallons' },
      ],
      tds: [
        { id: 'ec', name: 'set_ms_cm' },
        { id: 'ppm', name: 'set_ppm' },
      ],
      temperature: [
        { id: 'c', name: 'set_celsius' },
        { id: 'f', name: 'set_fahrenheit' },
      ],
      nutrient: [
        { id: 'mll', name: 'set_mll' },
        { id: 'mlgal', name: 'set_mlgal' },
        { id: 'tspgal', name: 'set_tspgal' },
      ],


    },


    // dayHeight:{
    //   "metric": 'cm',
    //   "english": 'ft',
    // },
    // dayInch:{
    //   "metric": 'set_cm',
    //   "english": 'set_inch',
    // },
    weight:{
      "metric": 'set_gram',
      "english": 'set_ounce',
    },
    // dayHeightFull:{
    //   "metric": 'centimeter',
    //   "english": 'foot',
    // },
    // dayInchFull:{
    //   "metric": 'centimeter',
    //   "english": 'inches',
    // },
    // weightFull:{
    //   "metric": 'set_gram_full',
    //   "english": 'set_ounce_full',
    // },
    temperature:{
      "f": 'set_fahrenheit',
      "c": 'set_celsius',
    },
    length:{
      "metric": 'set_cm',
      "english": 'set_inch',
    },
    tds:{
      "ec": 'set_ms_cm',
      "ppm": 'set_ppm',
    },
    volume:{
      "gal": 'set_gallons',
      "l": 'set_lilers',
    },
    nutrientsUnit:{
      "mll": 'set_mll',
      "mlgal": 'set_mlgal',
      "tspgal": 'set_tspgal',
    },
    metric:{
      //not use!!!
      "metric": 'm',
      "english": 'ft',
    },
    metricSquare:{
      "metric": 'set_sqm',
      "english": 'set_sqft',
      //"metric": 'm²',
      //"english": 'ft²',
    },
    square:{
      "metric": 'set_sqm',
      "english": 'set_sqft',
    },
    metricSquareFull:{
      //not use!!!
      "metric": 'meter²',
      "english": 'foot²',
    },
    status:{

      "-1": '-',
      "0": '-',
      "1": 'universal_status_newbie',
      "2": 'universal_status_apprentice',
      "3": 'universal_status_master',
      "4": 'universal_status_grandmaster',
      "5": 'universal_status_guru',
      "10": 'universal_status_admin',
      "11": 'universal_status_shop',
      "100": 'universal_status_official_representative',
      "101": 'universal_status_official_representative',
    },
    growScoreText:{
      "frown": 'universal_grow_score_text_frown',
      "meh": 'universal_grow_score_text_meh',
      "smile": 'universal_grow_score_text_smile',
    },
    contactTopic:{
      "1": 'Marketing/PR',
      "2": 'Legal',
      "3": 'Sales',
      "4": 'Customer support',
      "5": 'General questions',
    },
    bankTypes:{

      //not uni
      "1": 'universal_bank_types_autoflowering',
      "2": 'universal_bank_types_feminized',
      "3": 'universal_bank_types_regular',
      "4": 'universal_bank_types_clones',
      "5": 'universal_bank_types_autofeminized',
      "6": 'universal_bank_types_autoregular',
    },
    bankTypesShort:{
      //not uni
      "1": 'universal_bank_types_short_autoflowering',
      "2": 'universal_bank_types_short_feminized',
      "3": 'universal_bank_types_short_regular',
      "4": 'universal_bank_types_short_clones',
      "5": 'universal_bank_types_short_autofeminized',
      "6": 'universal_bank_types_short_autoregular',
    },
    bankTypesIco:{
      "1": '<i></i>',
      "2": '<i class="icon-fem"></i>',
      "3": '<i class="icon-reg"></i>',
      "4": '<i></i>',
      "5": '<i class="icon-auto-fem"></i>',
      "6": '<i class="icon-auto-reg"></i>',
    },
    plantSymptomsType:{

      "1": 'universal_plant_symptoms_type_1',//Buds
      "2": 'universal_plant_symptoms_type_2',//Leaves
      "3": 'universal_plant_symptoms_type_3',//Plant
      "4": 'universal_plant_symptoms_type_4',//Roots
      "5": 'universal_plant_symptoms_type_5',//Setup
      "6": 'universal_plant_symptoms_type_6',//Feeding
      "7": 'universal_plant_symptoms_type_7',//Techniques
      "8": 'universal_plant_symptoms_type_8',//Other
      "9": 'universal_plant_symptoms_type_9',//Germination
    },
    advNotifications:{
      "1": 'balance_up',         //
      "2": 'balance_end_1',
      "3": 'balance_end_2',
      "4": 'close',              //
      "5": 'position_down',      //
      "6": 'balance_end',        //
      "7": 'bitcoin_invoice',        //
      "8": 'waretransfer_invoice',        //
    },
    giveawayStatus:{
      //not use!!!
      "0": '',
      "1": 'Vote',
      "2": 'Join',
      "3": 'Coming',
      "4": 'Closed',
    },
    lampTypes:{
      "1": 'FL',
      "2": 'HID',
      "3": 'LED',
    },
    lampSubTypes:{

      "1": 'MH',
      "2": 'HPS',
      "3": 'REGULAR',
      "4": 'COB',
      "5": 'Ceramic MH (CMH)',
    },
    tentTypes:{

      "1": 'universal_type_tent_stealth',//Stealth
      "2": 'universal_type_tent_micro',//Micro
      "3": 'universal_type_tent_regular',//Regular
      "4": 'universal_type_tent_big',//Big
    },
    problemStatus:{
      //not use!!!
      "0": 'Not Resolved',
      "1": 'Resolved',
    },
    typeGiveaways:{
      "1": 'Diary of the Month',
      "3": 'Common giveaway',
      "5": 'Newbie of the Month',
      "6": 'Grower of the Month',
      "7": 'Photo of the Month',
    },
    typeContentGiveaways:{

      "1": 'Diary',
      "2": 'User',
      "3": 'Photo from Diary',
      "4": 'Video from Diary',
      "5": 'Uploaded Photo',
      "6": 'Uploaded Video',
    },

    symptoms: {

      symptom_top_1: {
        id: "symptom_top_1",
        trkey: "universal_plant_symptoms_type_1",

        childs: {
          symptom_1: {
            id: "symptom_1",
            trkey: "universal_plant_symptom_1",
            full_trkey: "universal_plant_symptoms_type_1. Not fattening"
          },
          symptom_2: {
            id: "symptom_2",
            trkey: "universal_plant_symptom_2",
            full_trkey: "universal_plant_symptoms_type_1. Too few"
          },
          symptom_3: {
            id: "symptom_3",
            trkey: "universal_plant_symptom_3",
            full_trkey: "universal_plant_symptoms_type_1. Other"
          }
        }
      },
      symptom_top_2: {
        id: "symptom_top_2",
        trkey: "universal_plant_symptoms_type_2",

        childs: {
          symptom_24: {
            id: "symptom_24",
            trkey: "universal_plant_symptom_24",
            full_trkey: "universal_plant_symptoms_type_2. Color - Black or grey"
          },
          symptom_23: {
            id: "symptom_23",
            trkey: "universal_plant_symptom_23",
            full_trkey: "universal_plant_symptoms_type_2. Color - Dark-brown"
          },
          symptom_22: {
            id: "symptom_22",
            trkey: "universal_plant_symptom_22",
            full_trkey: "universal_plant_symptoms_type_2. Color - Dark-purple"
          },
          symptom_25: {
            id: "symptom_25",
            trkey: "universal_plant_symptom_25",
            full_trkey: "universal_plant_symptoms_type_2. Color - Mottling"
          },
          symptom_21: {
            id: "symptom_21",
            trkey: "universal_plant_symptom_21",
            full_trkey: "universal_plant_symptoms_type_2. Color - Pale"
          },
          symptom_20: {
            id: "symptom_20",
            trkey: "universal_plant_symptom_20",
            full_trkey: "universal_plant_symptoms_type_2. Color - Red or pink"
          },
          symptom_19: {
            id: "symptom_19",
            trkey: "universal_plant_symptom_19",
            full_trkey: "universal_plant_symptoms_type_2. Color - Yellow"
          },
          symptom_11: {
            id: "symptom_11",
            trkey: "universal_plant_symptom_11",
            full_trkey: "universal_plant_symptoms_type_2. Curl down"
          },
          symptom_10: {
            id: "symptom_10",
            trkey: "universal_plant_symptom_10",
            full_trkey: "universal_plant_symptoms_type_2. Curl up"
          },
          symptom_12: {
            id: "symptom_12",
            trkey: "universal_plant_symptom_12",
            full_trkey: "universal_plant_symptoms_type_2. Dropping off"
          },
          symptom_6: {
            id: "symptom_6",
            trkey: "universal_plant_symptom_6",
            full_trkey: "universal_plant_symptoms_type_2. Edges burnt"
          },
          symptom_7: {
            id: "symptom_7",
            trkey: "universal_plant_symptom_7",
            full_trkey: "universal_plant_symptoms_type_2. Tips - Burnt"
          },
          symptom_8: {
            id: "symptom_8",
            trkey: "universal_plant_symptom_8",
            full_trkey: "universal_plant_symptoms_type_2. Tips - Die"
          },
          symptom_9: {
            id: "symptom_9",
            trkey: "universal_plant_symptom_9",
            full_trkey: "universal_plant_symptoms_type_2. Tips - Thick"
          },
          symptom_5: {
            id: "symptom_5",
            trkey: "universal_plant_symptom_5",
            full_trkey: "universal_plant_symptoms_type_2. Too few"
          },
          symptom_4: {
            id: "symptom_4",
            trkey: "universal_plant_symptom_4",
            full_trkey: "universal_plant_symptoms_type_2. Too many"
          },
          symptom_13: {
            id: "symptom_13",
            trkey: "universal_plant_symptom_13",
            full_trkey: "universal_plant_symptoms_type_2. Twisted"
          },
          symptom_18: {
            id: "symptom_18",
            trkey: "universal_plant_symptom_18",
            full_trkey: "universal_plant_symptoms_type_2. Veins - stay green"
          },
          symptom_17: {
            id: "symptom_17",
            trkey: "universal_plant_symptom_17",
            full_trkey: "universal_plant_symptoms_type_2. Veins - yellow between"
          },
          symptom_14: {
            id: "symptom_14",
            trkey: "universal_plant_symptom_14",
            full_trkey: "universal_plant_symptoms_type_2. Webbing"
          },
          symptom_16: {
            id: "symptom_16",
            trkey: "universal_plant_symptom_16",
            full_trkey: "universal_plant_symptoms_type_2. White powder"
          },
          symptom_15: {
            id: "symptom_15",
            trkey: "universal_plant_symptom_15",
            full_trkey: "universal_plant_symptoms_type_2. Wilting"
          },
          symptom_26: {
            id: "symptom_26",
            trkey: "universal_plant_symptom_26",
            full_trkey: "universal_plant_symptoms_type_2. Other"
          }
        }
      },
      symptom_top_3: {
        id: "symptom_top_3",
        trkey: "universal_plant_symptoms_type_3",

        childs: {
          symptom_36: {
            id: "symptom_36",
            trkey: "universal_plant_symptom_36",
            full_trkey: "universal_plant_symptoms_type_3. Spaces between nodes"
          },
          symptom_40: {
            id: "symptom_40",
            trkey: "universal_plant_symptom_40",
            full_trkey: "universal_plant_symptoms_type_3. Stem - Red or purple"
          },
          symptom_39: {
            id: "symptom_39",
            trkey: "universal_plant_symptom_39",
            full_trkey: "universal_plant_symptoms_type_3. Stem - Weak"
          },
          symptom_34: {
            id: "symptom_34",
            trkey: "universal_plant_symptom_34",
            full_trkey: "universal_plant_symptoms_type_3. Too short"
          },
          symptom_35: {
            id: "symptom_35",
            trkey: "universal_plant_symptom_35",
            full_trkey: "universal_plant_symptoms_type_3. Too tall"
          },
          symptom_37: {
            id: "symptom_37",
            trkey: "universal_plant_symptom_37",
            full_trkey: "universal_plant_symptoms_type_3. Twisted"
          },
          symptom_38: {
            id: "symptom_38",
            trkey: "universal_plant_symptom_38",
            full_trkey: "universal_plant_symptoms_type_3. Wilting"
          },
          symptom_41: {
            id: "symptom_41",
            trkey: "universal_plant_symptom_41",
            full_trkey: "universal_plant_symptoms_type_3. Other"
          }
        }
      },
      symptom_top_4: {
        id: "symptom_top_4",
        trkey: "universal_plant_symptoms_type_4",

        childs: {
          symptom_42: {
            id: "symptom_42",
            trkey: "universal_plant_symptom_42",
            full_trkey: "universal_plant_symptoms_type_4. Color - Brown"
          },
          symptom_43: {
            id: "symptom_43",
            trkey: "universal_plant_symptom_43",
            full_trkey: "universal_plant_symptoms_type_4. Mushy"
          },
          symptom_45: {
            id: "symptom_45",
            trkey: "universal_plant_symptom_45",
            full_trkey: "universal_plant_symptoms_type_4. Smelly"
          },
          symptom_44: {
            id: "symptom_44",
            trkey: "universal_plant_symptom_44",
            full_trkey: "universal_plant_symptoms_type_4. Too few"
          },
          symptom_46: {
            id: "symptom_46",
            trkey: "universal_plant_symptom_46",
            full_trkey: "universal_plant_symptoms_type_4. Other"
          }
        }
      },
      symptom_top_5: {
        id: "symptom_top_5",
        trkey: "universal_plant_symptoms_type_5",

        childs: {
          symptom_50: {
            id: "symptom_50",
            trkey: "universal_plant_symptom_50",
            full_trkey: "universal_plant_symptoms_type_5. Clones"
          },
          symptom_53: {
            id: "symptom_53",
            trkey: "universal_plant_symptom_53",
            full_trkey: "universal_plant_symptoms_type_5. Lighting"
          },
          symptom_56: {
            id: "symptom_56",
            trkey: "universal_plant_symptom_56",
            full_trkey: "universal_plant_symptoms_type_5. Outdoor"
          },
          symptom_55: {
            id: "symptom_55",
            trkey: "universal_plant_symptom_55",
            full_trkey: "universal_plant_symptoms_type_5. Seedling"
          },
          symptom_49: {
            id: "symptom_49",
            trkey: "universal_plant_symptom_49",
            full_trkey: "universal_plant_symptoms_type_5. Seeds"
          },
          symptom_52: {
            id: "symptom_52",
            trkey: "universal_plant_symptom_52",
            full_trkey: "universal_plant_symptoms_type_5. Sensors"
          },
          symptom_48: {
            id: "symptom_48",
            trkey: "universal_plant_symptom_48",
            full_trkey: "universal_plant_symptoms_type_5. Strain - Autoflowering"
          },
          symptom_47: {
            id: "symptom_47",
            trkey: "universal_plant_symptom_47",
            full_trkey: "universal_plant_symptoms_type_5. Strains - Photoperiod"
          },
          symptom_51: {
            id: "symptom_51",
            trkey: "universal_plant_symptom_51",
            full_trkey: "universal_plant_symptoms_type_5. Substrates"
          },
          symptom_54: {
            id: "symptom_54",
            trkey: "universal_plant_symptom_54",
            full_trkey: "universal_plant_symptoms_type_5. Ventilation"
          },
          symptom_57: {
            id: "symptom_57",
            trkey: "universal_plant_symptom_57",
            full_trkey: "universal_plant_symptoms_type_5. Other"
          }
        }
      },
      symptom_top_6: {
        id: "symptom_top_6",
        trkey: "universal_plant_symptoms_type_6",

        childs: {
          symptom_58: {
            id: "symptom_58",
            trkey: "universal_plant_symptom_58",
            full_trkey: "universal_plant_symptoms_type_6. Automatic systems"
          },
          symptom_60: {
            id: "symptom_60",
            trkey: "universal_plant_symptom_60",
            full_trkey: "universal_plant_symptoms_type_6. Chemical composition"
          },
          symptom_61: {
            id: "symptom_61",
            trkey: "universal_plant_symptom_61",
            full_trkey: "universal_plant_symptoms_type_6. Deficiences"
          },
          symptom_59: {
            id: "symptom_59",
            trkey: "universal_plant_symptom_59",
            full_trkey: "universal_plant_symptoms_type_6. Schedule"
          },
          symptom_62: {
            id: "symptom_62",
            trkey: "universal_plant_symptom_62",
            full_trkey: "universal_plant_symptoms_type_6. Other"
          }
        }
      },
      symptom_top_7: {
        id: "symptom_top_7",
        trkey: "universal_plant_symptoms_type_7",

        childs: {
          symptom_65: {
            id: "symptom_65",
            trkey: "universal_plant_symptom_65",
            full_trkey: "universal_plant_symptoms_type_7. 12-12 from seeds"
          },
          symptom_71: {
            id: "symptom_71",
            trkey: "universal_plant_symptom_71",
            full_trkey: "universal_plant_symptoms_type_7. Defoliation"
          },
          symptom_69: {
            id: "symptom_69",
            trkey: "universal_plant_symptom_69",
            full_trkey: "universal_plant_symptoms_type_7. FIMing"
          },
          symptom_64: {
            id: "symptom_64",
            trkey: "universal_plant_symptom_64",
            full_trkey: "universal_plant_symptoms_type_7. HST"
          },
          symptom_63: {
            id: "symptom_63",
            trkey: "universal_plant_symptom_63",
            full_trkey: "universal_plant_symptoms_type_7. LST"
          },
          symptom_70: {
            id: "symptom_70",
            trkey: "universal_plant_symptom_70",
            full_trkey: "universal_plant_symptoms_type_7. Main-lining"
          },
          symptom_67: {
            id: "symptom_67",
            trkey: "universal_plant_symptom_67",
            full_trkey: "universal_plant_symptoms_type_7. ScrOG"
          },
          symptom_66: {
            id: "symptom_66",
            trkey: "universal_plant_symptom_66",
            full_trkey: "universal_plant_symptoms_type_7. SoG"
          },
          symptom_68: {
            id: "symptom_68",
            trkey: "universal_plant_symptom_68",
            full_trkey: "universal_plant_symptoms_type_7. Topping"
          },
          symptom_72: {
            id: "symptom_72",
            trkey: "universal_plant_symptom_72",
            full_trkey: "universal_plant_symptoms_type_7. Other"
          }
        }
      },
      symptom_top_8: {
        id: "symptom_top_8",
        trkey: "universal_plant_symptoms_type_8",

        childs: {
          symptom_27: {
            id: "symptom_27",
            trkey: "universal_plant_symptom_27",
            full_trkey: "universal_plant_symptoms_type_8. Bugs"
          },
          symptom_32: {
            id: "symptom_32",
            trkey: "universal_plant_symptom_32",
            full_trkey: "universal_plant_symptoms_type_8. General questions"
          },
          symptom_30: {
            id: "symptom_30",
            trkey: "universal_plant_symptom_30",
            full_trkey: "universal_plant_symptoms_type_8. Harvest - Curing"
          },
          symptom_29: {
            id: "symptom_29",
            trkey: "universal_plant_symptom_29",
            full_trkey: "universal_plant_symptoms_type_8. Harvest - Drying"
          },
          symptom_31: {
            id: "symptom_31",
            trkey: "universal_plant_symptom_31",
            full_trkey: "universal_plant_symptoms_type_8. Harvest - Smoking"
          },
          symptom_28: {
            id: "symptom_28",
            trkey: "universal_plant_symptom_28",
            full_trkey: "universal_plant_symptoms_type_8. Mold"
          },
          symptom_33: {
            id: "symptom_33",
            trkey: "universal_plant_symptom_33",
            full_trkey: "universal_plant_symptoms_type_8. Other"
          }
        }
      },
    },
    equipmentCategoryList: [
      {
        title: 'All',
        active: false,
        link: '/equipment'
      },
      {
        title: 'Grow Tents',
        active: false,
        link: '/grow-tents'
      },
      {
        title: 'Grow Boxes',
        active: false,
        link: '/grow-boxes'
      },
      {
        title: 'Ventilation Fans',
        active: false,
        link: '/ventilation-fans'
      },
      {
        title: 'Air Filters',
        active: false,
        link: '/air-filters'
      },
      {
        title: 'Air Conditioners',
        active: false,
        link: '/air-conditioners'
      },
      {
        title: 'Drip Systems',
        active: false,
        link: '/drip-systems'
      },
      {
        title: 'Hydroponic Systems',
        active: false,
        link: '/hydroponic-systems'
      },
      {
        title: 'Controllers',
        active: false,
        link: '/controllers'
      },
      {
        title: 'CO2 Generators',
        active: false,
        link: '/co2-generators'
      }
    ],

    categoryShort: {
      "tent": 'Tent',
      "grow_box": 'Box',
      "ventilation_fan": 'Fan',
      "air_filter": 'Filt',
      "air_conditioner": 'Cond',
      "drip_system": 'Drip',
      "hydroponic_system": 'Hydro',
      "controller": 'Contr',
      "co2_generator": 'CO2',
    },



  }

  return {
    provide: {
      constants: constants,
    },
  };

})
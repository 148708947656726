export default defineNuxtPlugin(nuxtApp => {  

  const router  = useRouter();

  const patcher = {
    path: '',
    scheme: null,
    isOnlyQuery: false,
    list: {},
    tags: [],
    keyword: '',
    change(path, setup, scheme) {
      this.scheme = scheme;
      this.path = path;
      this.list = setup.list;
      this.tags = setup.tags ?? [];
      this.keyword = setup.keyword ?? ''; 
      this.isOnlyQuery = setup.isOnlyQuery ?? false;
      this.modify();
    },
    setTags(tags) {
      this.tags = tags;
      this.modify();
    },
    setKeyword(keyword) {
      this.keyword = keyword;
      this.modify();
    },
    modify() {

      let url = '';
      let query = {};

      if(this.scheme){
        if(this.tags.length > 1)
          query.tags = this.tags.join(',');
        url = this.scheme(this);
      }else if(this.tags.length > 1 || this.isOnlyQuery){        
        query.tags = this.tags.join(',');
        url = this.path + '';
      }else if(this.tags.length && this.tags.indexOf('all') == -1){
        url = this.path + '/' + this.tags.join(',');
      }else{
        url = this.path + '';
      }

      // console.log('this.tags');
      // console.log(url);
      // console.log(query);
      // router.push({ path: url , query: query});
      let queryString = Object.keys(query)?.length && query.tags ? Object.keys(query).map(key => key + '=' + query[key]).join('&') : '';

      // console.log(query);

      if(this.keyword){
        queryString += '&keyword=' + this.keyword;
        query.keyword = this.keyword;
      }

      if(queryString){
        history.pushState(
          {},
          '',
          `${url}?${queryString}`
        );

        // router.replace({ 
        //   path: url,
        //   query: query
        // }) 
      }else {
        history.pushState(
          {},
          '',
          `${url}`
        );

        // router.replace({ 
        //   path: url,
        // })
      }

        
    }, 
  }
   
  return {
    provide: {
      patcher: patcher,
    },
  };

})
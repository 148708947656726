<template>


  <div class="result">



    <div class="anomalies">
      <div class="main_issue">
        {{ props.data.summary }}
      </div>


      <template v-if="props.data.diary_stage != 'harvested' && props.data.anomalies?.length">
        
        <div class="current_symptoms">
          Current anomalies:
        </div>

        <div class="diagnosis_options">
          <div class="diagnosis_option" v-for="anomaly in props.data.anomalies">
            <div class="diagnosis_title">
              {{ anomaly.param }}. 
              <template v-if="anomaly.week">
                <b>Week</b>: {{ anomaly.week }}
              </template>
            </div>
            <div class="issue">
              {{ anomaly.issue }}
            </div>
            <div class="solution">
              <b>Solution</b>: {{ anomaly.solution }}
            </div>
          </div>
        </div>

      </template>

      <template v-if="props.data.next_week_prediction">
        <div class="main_issue_other">
          <b>Next week prediction:</b>
          {{ props.data.next_week_prediction }}
        </div>
      </template>

      <template v-if="props.data.setup_evaluation">
        <div class="main_issue_other">
          <b>Diary setup:</b>
          {{ props.data.setup_evaluation }}
        </div>
      </template>

      <template v-if="props.data.recommendations">
        <div class="main_issue_other">
          <b>Recommendations:</b>
          {{ props.data.recommendations }}
        </div>
      </template>

      <template v-if="props.data.final_results">
        <div class="main_issue_other">
          <b>Final:</b>
          {{ props.data.final_results }}
        </div>
      </template>

    </div>

 


 
    

  </div>


</template>

<script setup>

const { $api } = useNuxtApp()

const props = defineProps({
  data: {
    type: Object,
    // required: true
  },
})


// "result": {
//       "anomalies": [
//         {
//           "param": "Air Temperature",
//           "issue": "Air temperature is unusually high at 79.7°C, which can harm plant growth.",
//           "week": 1,
//           "solution": "Reduce air temperature to optimal levels around 20-30°C."
//         },
//         {
//           "param": "Night Air Temperature",
//           "issue": "Night air temperature is extremely high at 32°C, which can affect plant respiration and stress levels.",
//           "week": 1,
//           "solution": "Lower night air temperature to a range of 15-25°C."
//         },
//         {
//           "param": "Solution Temperature",
//           "issue": "Solution temperature is very high at 32°C, which might cause root damage or nutrient uptake issues.",
//           "week": 1,
//           "solution": "Cool down the solution to a safer range, ideally between 18-22°C."
//         },
//         {
//           "param": "Substrate Temperature",
//           "issue": "Substrate temperature is also high at 32°C, potentially affecting root health.",
//           "week": 1,
//           "solution": "Maintain substrate temperature between 20-26°C."
//         },
//         {
//           "param": "Air Temperature",
//           "issue": "Air temperature remains high at 79.7°C for subsequent weeks, which is not ideal.",
//           "week": 2,
//           "solution": "Continue efforts to reduce air temperature."
//         },
//         {
//           "param": "Night Air Temperature",
//           "issue": "Night air temperature remains high at 32°C, requiring adjustment.",
//           "week": 2,
//           "solution": "Adjust night air temperature to a cooler range."
//         },
//         {
//           "param": "Solution Temperature",
//           "issue": "Solution temperature continues to be high at 32°C.",
//           "week": 2,
//           "solution": "Implement cooling methods for the solution."
//         },
//         {
//           "param": "Substrate Temperature",
//           "issue": "High substrate temperature persists at 32°C.",
//           "week": 2,
//           "solution": "Ensure proper temperature control for the substrate."
//         },
//         {
//           "param": "Air Temperature",
//           "issue": "Persistently high air temperature at 79.7°C.",
//           "week": 3,
//           "solution": "Urgently address temperature conditions."
//         },
//         {
//           "param": "Night Air Temperature",
//           "issue": "Consistently high night air temperature at 32°C.",
//           "week": 3,
//           "solution": "Immediate adjustment needed for night temperatures."
//         },
//         {
//           "param": "Solution Temperature",
//           "issue": "Solution temperature still too high at 32°C.",
//           "week": 3,
//           "solution": "Find ways to cool down the solution effectively."
//         },
//         {
//           "param": "Substrate Temperature",
//           "issue": "Substrate temperature remains high at 32°C.",
//           "week": 3,
//           "solution": "Consider alternative cooling methods for substrate."
//         },
//         {
//           "param": "Air Temperature",
//           "issue": "Continuous issue with high air temperature at 79.7°C.",
//           "week": 4,
//           "solution": "Sustained efforts needed to manage air temperature."
//         },
//         {
//           "param": "Night Air Temperature",
//           "issue": "Persistently high night air temperature at 32°C.",
//           "week": 4,
//           "solution": "Adjust cooling systems for night conditions."
//         },
//         {
//           "param": "Solution Temperature",
//           "issue": "Solution temperature is still elevated at 32°C.",
//           "week": 4,
//           "solution": "Further action required to cool down solution."
//         },
//         {
//           "param": "Substrate Temperature",
//           "issue": "High substrate temperature persists at 32°C.",
//           "week": 4,
//           "solution": "Implement consistent cooling strategy for substrate."
//         }
//       ],
//       "summary": "The grow diary indicates consistent issues with extremely high temperatures (air, night, solution, and substrate) across all weeks. These conditions are not conducive for plant growth and can lead to stress or damage. Immediate corrective actions are needed to bring all temperature parameters to optimal levels."
//     }
//   }

  


</script>

<style scoped>
 
.result {
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 4px;
}
.main_issue {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.main_issue_other{
  font-weight: 500;
  margin-top: 1rem;
}
.current_symptoms {
  margin-top: 1rem;
  font-weight: bold;
}
.diagnosis_options {
  margin-bottom: 1rem;
}
.diagnosis_option {
  padding: 0.5rem 0;

}
.diagnosis_title {
  font-weight: bold;
}
.probability {
  color: #888;
}
.symptomsList,
.resolveList {
  margin: 0.5rem 0;
}
.conclusion_advice {
  font-style: italic;
}
.summary {
  margin-top: 1rem;
  font-weight: 500;
}

</style>




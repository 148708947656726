<template>

  <div class="qform">

    <div class="hint limit_char">
      {{ max-current }} {{ $t('week_view_question_characters_left') }}      
    </div>

    <UiTextArea  
      :placeholder="$t('week_view_question_placeholder2')" type="textarea"      
      :text="vl"
      :rows="4"
      :max="max"
      @change:text="vl = $event"
      />
 
    <UserNotVerificated 
      v-if="!useAuth().isVerifiedEmail()"/>


    <UiButton
      :name="$t('week_edit_question_continue')"
      :type="'secondary'"
      :disabled="!useAuth().isVerifiedEmail()"
      @click="create" 
      />        
 
  
    
 
  </div>
  
</template>

<script>
  

export default {  
  components: {   
  },   
  props: {
    diary:{
      type: Number
    },
    week:{
      type: Number
    },
  },
  data () {
    return {        
      max: 280,
      // current: 0,
      vl: ''
    }
  }, 
  created() {    
    // this.query = this.$route.query.q;    
  },
  computed: {    
    current: {
      get() {
        return this.vl.length;
      },
      set(value) {
        this.current = value;
      }
    }
  },
  methods: {
    create(){               

      const router = useRouter();
 
      router.push({ 
        name: 'grow-questions-edit-id', 
        params:{
          id: this.$rand.str(4), 
        },
        query: { 
          text: this.vl, 
          test: this.vl, 
          diary: this.diary, 
          week: this.week,  
        },
      });
    },    
    countChar(data){       
      this.current = data.length;
    }
  }
}
</script>

<style scoped>
 
.limit_char {
    margin-bottom: 5px;
    font-size: 0.85rem;
    color: #9e9e9e;
    font-style: italic;
}

</style>

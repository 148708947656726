<template>



  <div class="germ_item" data-key="0">

    
    <div class="germ_name">

      <img :src="edited_item.item_brand.avatar_little" class="germ_logo">

      <div class="germ_info"> 
        <div class="germ_nm">{{ edited_item.name }}</div>
        <div class="germ_brand">{{ edited_item.item_brand.name }}</div> 
      </div>


    </div>

    <div class="germ_graph">
      <UiProgressLine
        :percent="progress"
        />
    </div>

    <div class="germ_val">
      <input class="germ_edit_input" @keyup="change" type="text" v-model="edited_item.cnt_germ">
    </div>

    <div class="germ_unit"> 
      {{ $t('create_week_view_germ_of') }} 
      {{ edited_item.props.cnt }}
      <template v-if="edited_item.props.type == 2">
        {{ $t('universal_type_seed_clones') }}
      </template>
      <template v-else>
        {{ $t('universal_type_seed_seeds') }}
      </template>
    </div> 
  </div>

      

  
</template>


<script>
 
export default {
  components: {         
    
  },  
  props:{
    item: {
      type: Object
    }
  },
  data() {
    return { 
      edited_item: this.item,
      units: []
    };
  }, 

  mounted () {
   
  },
  computed: {
    progress(){
 
      if(!this.edited_item.cnt_germ)
        return 0;
      if(!this.edited_item.props.cnt)
        return 0;
      // return Math.round(this.edited_item.cnt_germ / this.edited_item.cnt_seeds * 100);
      return Math.round( this.edited_item.cnt_germ*100/this.edited_item.props.cnt);
    }
  },
  methods: {
    change(){

      if(isNaN(this.edited_item.cnt_germ))
        this.edited_item.cnt_germ = '';

      if(this.edited_item.cnt_germ > this.edited_item.props.cnt)
        this.edited_item.cnt_germ = this.edited_item.props.cnt;

      this.$emit('update:item', this.edited_item)
    }
  }
}
</script>
 

 
<style scoped>

.germ_item {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 50px;
  padding-top: 10px;
  gap: 10px;
}

.germ_item:not(:first-child){
  margin-top: 10px;
}
.germ_item .germ_name{
  width: 100%;
}
.germ_item .germ_name{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.germ_item .germ_name .germ_logo{
  width: 40px;
}
.germ_item .germ_name .germ_info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.germ_item .germ_name .germ_info .germ_nm{
    font-weight: bold;
}
.germ_item .germ_name .germ_info .germ_brand{}


.germ_graph{width: 38%;margin-left: auto;}
.germ_graph .ui.progress{
    margin: 0px;
    background-color: #f9f9f9;
}
.germ_graph .ui.progress .bar{
    height: 28px;
}
.germ_val{
    margin-left: 1%;
    width: 98px;
}
.germ_val .germ_edit_input {
    border: 0px;
    text-align: center;
    width: 100%;
    background-color: rgba(226, 226, 226, 0.2);
    border-radius: 3px;
    padding: 5px 10px;
    border-bottom: 1px var(--un-primary-back-color) solid;
}

/* Chrome, Safari, Edge, Opera */
.germ_val .germ_edit_input::-webkit-outer-spin-button,
.germ_val .germ_edit_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.germ_val .germ_edit_input {
  -moz-appearance: textfield;
}
.germ_unit{
  text-align: left;
  width: 180px;
  padding-left: 10px;
}


.germ_self{
  background: url(/images/product-categories/nutrient.svg);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 24px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0.8;
  margin-right: 5px;
}

.germ_graph .germ_sel_vl{
  display: inline-block;
  position: absolute;
  margin-left: 5px;
  margin-top: 4px;
  color: white;
}

@container pb (max-width: 768px) {

  .germ_item{
    height: auto;
    text-align: left;
    padding-top: 15px;
  }

  
  .germ_items .germ_graph{
    display: block;
    margin-left: 0;
    width: calc(100% - 190px);
  }
  .germ_val{
    width: 51px;
  }
  .germ_unit{
    width: 160px;
  }
  .germ_remove{
    display: inline-block;
    padding-left: 0px;
    margin-left: auto;
  }
  .germ_graph{
    display:none;
  }

  .germ_item .germ_edit_input{
    padding: 5px;
    font-size: 1rem;
    min-width: 60px;
  }
  .btn_add_germ_row{
    display: block;
    width: 100%;
  }
  .dropdown_fert .label_add_fert{
    display: block;
    width: 100%;
  }
  .dropdown_fert{
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
  }
  .dropdown_germ_menu{
    width: 100%;
  }
  
  #photoadd{
    width: 100%;
  }
}

</style>
<template>

  <div class="methods" v-if="props.itemsMethod?.length">

    <div class="divider">      
      {{ $t('week_view_methods_title') }}
    </div>

    
    <div class="lst">
          
      <div 
        v-for="m in props.itemsMethod"
        class="method">
        <i v-if="m.method_id > -1" :class="'ic-method ic-method-' + m.method_id"></i>
        <div class="info">
          <div class="name">{{ m.method_id < 0 ? m.custom :  $t(DiaryTypeMethodShortTr[m.method_id]) }}</div>
          <div class="title">{{ $t('diary_info_technique') }}</div>
        </div>
      </div>

    </div>


 
 
  </div>

  
</template>

<script setup lang="ts">
 
 import { DiaryTypeMethodShortTr }  from "@/types/diary"
 
 const props = defineProps({
    itemsMethod: {
      type: Array,
      default: () => [],
    },
  })

  
  
</script>

<style scoped>
 
 
.methods .lst {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  /* height: 50px; */
  align-items: flex-start;
  position: relative;
  /* padding-right: 30px; */
  /* margin-top: 10px; */
  justify-content: center;
}

.methods .method {
  cursor: help;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
}

.methods .method .info {
  display: flex;
  flex-direction: column;
  /*width: calc(100% - 46px);*/
}

.methods .method .info .name {
  color: var(--un-text-color);
  /*max-width: 120px;*/
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.methods .method .info .title {
  color: gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /*max-width: 120px;*/
}



</style>

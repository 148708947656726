import { ref, reactive, computed } from 'vue'
import { useNuxtApp, useCookie, useRouter } from '#imports'
import { useAdvStat } from '@/composables/useAdvStat'
import type { AdvPackage, AdvAction, AdvBannerHeader } from '@/types/adv'

const list = ref<AdvPackage<AdvBannerHeader>[]>([])
const listIgnore = ref<string[]>([])
const packageImpressions = ref<Record<number, number>>({})
const bannerImpressions = ref<Record<number, number>>({})

export function useAdvHeader() {
  const cookieNameViewedPackages = 'ah_v_packages'
  const cookieNameViewedPackagesBanners = 'ah_v_packages_banners'

  function isReadyApply(): boolean {
    return !document.querySelector('.header_add')
  }

  function filterPackageBannerBySection(
    packages: AdvPackage<AdvBannerHeader>[],
    section: string
  ): AdvPackage<AdvBannerHeader>[] {
    return packages.filter((itemPackage) => {
      const banners = itemPackage.banners?.filter((itemBanner) => {
        if (isBannerHidden('header', itemBanner.id)) return false
        return itemBanner.sections.indexOf(section) >= 0
      })
      return banners && banners.length > 0
    })
  }

  function click(id: number) {
    const action: AdvAction = {
      id,
      type: 'header',
      section: getSection(),
      action: 'click',
    }
    useAdvStat().value.push(action)

    useNuxtApp().$ga.clickBanner('header')
  }

  function view(id: number) {
    const action: AdvAction = {
      id,
      type: 'header',
      section: getSection(),
      action: 'view',
    }
    useAdvStat().value.push(action)

    addImpression(id)
    addViewedBanner(id)
    addViewedPackage(getPackageIdByBannerId(id))
  }

  function addImpression(id: number) {
    if (!list.value.length) return
    const pack = list.value.find((item) =>
      item.banners?.some((banner) => banner.id === id)
    )
    if (!pack) return
    pack.sys_impression++

    const itemBanner = pack.banners?.find((banner) => banner.id === id)
    if (!itemBanner) return
    itemBanner.sys_impression++
  }

  function getPackagesIds(): (number | null)[] {
    return list.value.map((itemPackage) => itemPackage.id)
  }

  function getPackageIdByBannerId(id: number): number | null {
    for (const itemPackage of list.value) {
      for (const itemBanner of itemPackage.banners) {
        if (itemBanner.id === id) {
          return itemPackage.id
        }
      }
    }
    return null
  }

  function isCurrentPackagesAndViewedChanged(): boolean {
    const packages = getPackagesIds().filter((i) => i !== null) as number[]
    const viewed = getViewedPackages()
    if (packages.length !== viewed.length) return true
    for (const id of packages) {
      if (!viewed.includes(id)) return true
    }
    return false
  }

  function getViewedPackages(): number[] {
    const raw = useCookie(cookieNameViewedPackages, {
      maxAge: 60 * 60 * 24 * 31 * 6,
    }).value ?? ''
    const val = (raw + '').split(',').filter((v) => !!v)
    return val.map((item) => Number(item))
  }

  function setViewedPackages(listIds: number[]) {
    if (!listIds?.length) return
    useCookie(cookieNameViewedPackages, {
      maxAge: 60 * 60 * 24 * 31 * 6,
    }).value = listIds.join(',')
  }

  function getViewedPackagesBanners(): number[] {
    const raw = useCookie(cookieNameViewedPackagesBanners, {
      maxAge: 60 * 60 * 24 * 31 * 6,
    }).value ?? ''
    const val = (raw + '').split(',').filter((v) => !!v)
    return val.map((item) => Number(item))
  }

  function setViewedPackagesBanners(listIds: number[]) {
    if (!listIds?.length) return
    useCookie(cookieNameViewedPackagesBanners, {
      maxAge: 60 * 60 * 24 * 31 * 6,
    }).value = listIds.join(',')
  }

  function removeViewedPackage(id: number, listIds: number[]): number[] {
    return listIds.filter((item) => item !== id)
  }

  function addViewedPackage(id: number | null) {
    if (!id) return
    const currentViewed = getViewedPackages()
    const updated = removeViewedPackage(id, currentViewed)
    updated.push(id)
    setViewedPackages(updated)
  }

  function addViewedBanner(id: number) {
    const currentViewed = getViewedPackagesBanners()
    const updated = removeViewedPackage(id, currentViewed)
    updated.push(id)
    setViewedPackagesBanners(updated)
  }

  function isBannerHidden(type: string, id: number): boolean {
    const hidden =
      useCookie('adv_hidden_' + type, {
        maxAge: 60 * 60 * 24 * 31 * 6,
      }).value ?? ''
    if (!hidden.length) return false
    return hidden.split(',').some((item) => Number(item) === id)
  }

  function getSection(): string {
    const router = useRouter()
    const path = router.currentRoute.value.fullPath.split('/')
    return path[1] ? path[1] : 'index'
  }

  function filterBannerBySection(
    packages: AdvPackage<AdvBannerHeader>[],
    section: string
  ): AdvPackage<AdvBannerHeader>[] {
    return packages.map((itemPackage) => {
      itemPackage.banners = itemPackage.banners?.filter((itemBanner) => {
        if (isBannerHidden('header', itemBanner.id)) return false
        return itemBanner.sections.indexOf(section) >= 0
      }) || []
      return itemPackage
    })
  }

  function sortPackageByImpressions(
    packages: AdvPackage<AdvBannerHeader>[]
  ): AdvPackage<AdvBannerHeader>[] {
    return packages.sort((a, b) => a.sys_impression - b.sys_impression)
  }

  function sortPackageBannersByViews(
    banners: AdvBannerHeader[]
  ): AdvBannerHeader[] {
    const viewed = getViewedPackagesBanners()
    if (!viewed.length) return banners
    return banners.sort((a, b) => viewed.indexOf(a.id) - viewed.indexOf(b.id))
  }

  function sortPackageByViews(
    packages: AdvPackage<AdvBannerHeader>[]
  ): AdvPackage<AdvBannerHeader>[] {
    const viewed = getViewedPackages()
    if (!viewed.length) return packages
    return packages.sort((a, b) => {
      return viewed.indexOf(a.id || 0) - viewed.indexOf(b.id || 0)
    })
  }

  function sortBannersByImpressions(
    packages: AdvPackage<AdvBannerHeader>[]
  ): AdvPackage<AdvBannerHeader>[] {
    return packages.map((itemPackage) => {
      itemPackage.banners = itemPackage.banners.sort((a, b) => {
        return (bannerImpressions[a.id] ?? 0) - (bannerImpressions[b.id] ?? 0)
      })
      return itemPackage
    })
  }

  function addBannerHidden(type: string, id: number) {
    const hidden = useCookie('adv_hidden_' + type, {
      maxAge: 60 * 60 * 24 * 31 * 6,
    })
    const prev = hidden.value ?? ''
    hidden.value = prev ? `${prev},${id}` : `${id}`
  }

  function setList(listParam: AdvPackage<AdvBannerHeader>[]) {
    // console.log('listParam', listParam)
    list.value = listParam
  }

  function getBanner(): AdvBannerHeader | null {
    if (isIgnoreSection()) return null
    if (isIgnorePages()) return null
    const section = getSection()
    if (!list.value) return null

    let packagesSection = filterPackageBannerBySection(list.value, section)
    packagesSection = filterBannerBySection(packagesSection, section)
    packagesSection = sortPackageByImpressions(packagesSection)
    packagesSection = sortPackageByViews(packagesSection)
    packagesSection = sortBannersByImpressions(packagesSection)

    if (!packagesSection.length) return null
    if (packagesSection[0] && packagesSection[0].banners[0]) {
      const hPackage = packagesSection[0]
      hPackage.banners = sortPackageBannersByViews(hPackage.banners)
      const hBanner = hPackage.banners[0]
      bannerImpressions[hBanner.id] = (bannerImpressions[hBanner.id] ?? 0) + 1
      if (hPackage.id) {
        packageImpressions[hPackage.id] =
          (packageImpressions[hPackage.id] ?? 0) + 1
      }
      return hBanner
    }
    return null
  }


  function setIgnore(l: string[]) {
    listIgnore.value = l
  }


  function isIgnorePages(): boolean {
    const router = useRouter()
    const path = router.currentRoute.value.fullPath
    const ignore = ['diaries/[\\w-]+/edit/week', 'grower/[\\w-]+/settings', 'diaries/edit', 'brands-manager']
    const patterns = ignore.map((item) => new RegExp(item))
    return patterns.some((regex) => regex.test(path))
  }

  function isIgnoreSection(): boolean {
    const router = useRouter()
    const path = router.currentRoute.value.fullPath

    if (!listIgnore.value) return false
    for (const ignoreSection of listIgnore.value) {
      if (path.includes(ignoreSection)) return true
    }
    return false
  }

  return {
    list,
    packageImpressions,
    bannerImpressions,
    isReadyApply,
    filterPackageBannerBySection,
    click,
    view,
    addImpression,
    getPackagesIds,
    getPackageIdByBannerId,
    isCurrentPackagesAndViewedChanged,
    getViewedPackages,
    setViewedPackages,
    getViewedPackagesBanners,
    setViewedPackagesBanners,
    removeViewedPackage,
    addViewedPackage,
    addViewedBanner,
    isBannerHidden,
    getSection,
    filterBannerBySection,
    sortPackageByImpressions,
    sortPackageBannersByViews,
    sortPackageByViews,
    sortBannersByImpressions,
    addBannerHidden,
    getBanner,
    isIgnorePages,
    isIgnoreSection,
    setList,
    setIgnore,
  }
}
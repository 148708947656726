<template>
  <NuxtLink to="/awards/2025" class="warn">
    Vote Now 🏆 for the Grow Awards 2025! 
    <i class="icon-angle-right"></i>
  </NuxtLink>
</template>


<script setup>
 

</script>

  

<style scoped>
 .warn{
  width: 100%;
  text-align: center;
  background-color: #ffd606;
  font-weight: bold;
  font-size: 0.7rem;
  padding: 4px 34px;
  color: #404040;
  position: relative;
  z-index: 2;
 }
 .warn a{
  color: #000;
  text-decoration: underline;
 }

</style>

<template>

    
    <div class="filter-checkbox-search">

        <div class="head" @click="emits('close')">
            <div class="name">
                {{ props.name }} 
                <i class="icon-caret-up"></i>
            </div>

            <!-- <i 
                class="icon-search search"
                @click="isSearchable = !isSearchable"
            ></i> -->


        </div>

        <!-- {{ selected }} -->


        <div v-if="isSearchable || 1" class="filter-checkbox-search__search">
            <input
            type="text"
            placeholder="Filtering by Name"
            v-model="searchQuery"
            />
        </div>


      <div class="filter-checkbox-search__list">
        <label
          v-for="tag in filteredTags"
          :key="tag.id"
          class="filter-checkbox-search__item"
        >
          <input
            type="checkbox"
            :value="tag.id"
            :checked="isSelected(tag.id)"
            @change="onSelect(tag.id)"
          />
          <span class="filter-checkbox-search__label">{{ tag.label }}</span>
        </label>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue'
  
  const props = defineProps({
    name: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
        default: () => []
    },
    searchable: {
      type: Boolean,
      default: false
    }
  })
  
  const emits = defineEmits(['update:selected', 'close'])
  
  const searchQuery = ref('')
  const isSearchable = ref(props.searchable)
  

  const filteredTags = computed(() => {
    if (!searchQuery.value.trim()) {
      return props.tags
    }

    return props.tags.filter(tag =>
      tag.label.toLowerCase().includes(searchQuery.value.toLowerCase())
    )
  })
  
  
  const isSelected = (tagId) => {
    return props.selected.includes(tagId)
  }
  
  const onSelect = (tagId) => {
    let newSelected = props.selected
    if (isSelected(tagId)) {
        newSelected = props.selected.filter(id => id !== tagId)
    } else {
        newSelected.push(tagId)
    }
    emits('update:selected', newSelected)
  }

  </script>
  
  <style scoped>
  .filter-checkbox-search {

  }
  
  .filter-checkbox-search__search {
    margin-bottom: 1rem;
  }
  .filter-checkbox-search__search input{
    padding: 0.4rem 1rem;
    border-radius: 2rem;
  }
  .filter-checkbox-search__list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: 180px;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
  
  .filter-checkbox-search__item {
    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: center;

  }
  
  .filter-checkbox-search__label {
    margin-left: 0.5rem;
  }




.head{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0.5rem;
}

.head .name{
    /* font-weight: bold; */
    
}

.head .search{
    display: flex;
    align-items: center;
    aspect-ratio: 1 / 1;
    width: 1.9rem;
    justify-content: center;
    margin-top: -0.4rem;
    margin-bottom: 0.4rem;
    cursor: pointer;
}


@container pb (max-width: 768px) {
   
}

</style>
export enum AwardCategoryType {
  Brand = 'brand',
  Product = 'product',
};
export enum AwardRuleType {
  Auth = 'auth',
  Diary = 'diary',
  Permit = 'permit',
  Vote = 'vote',
};



export const AwardRuleTypeImage: Record<AwardRuleType, string> = {
  [AwardRuleType.Auth]: '/images/awards/rules/auth.svg',
  [AwardRuleType.Diary]: '/images/awards/rules/diary.svg',
  [AwardRuleType.Permit]: '/images/awards/rules/permit.svg',
  [AwardRuleType.Vote]: '/images/awards/rules/vote.svg',
};

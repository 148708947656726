export const useNavbarOpened = () => useState('opened', () => {
  // if(typeof useCookie('navbar-opened').value == 'undefined'){
  //   console.log('useCookie(navbar-opened)');
  //     console.log(useCookie('navbar-opened').value);
    
  //     useCookie('navbar-opened').value = true;
  // }
  
  return useCookie('navbar-opened',{maxAge: 60 * 60 * 24 * 31 * 6});
})

export const useTopbarHiddenMobile = () => useState('useTopbarHiddenMobile', () => false)

export const useWarningMigrate = () => useState('useWarningMigrate', () => false)
export const useWarningMigrateHidden = () => useState('useWarningMigrateHidden', () => false)


// export const useUser = () => useState('user', () => useAuth())




export const useNavbarThemeRequired = () => useState('useNavbarThemeRequired', () => null)

export const useNavbarTheme = () => {
  var storedValue = useCookie("ui:theme",{maxAge: 60 * 60 * 24 * 31 * 6}).value
  const theme = useState('ui:theme', () => storedValue ?? 'light');

  watch(theme, (newValue) => {
    useCookie("ui:theme",{maxAge: 60 * 60 * 24 * 31 * 6}).value = newValue;
  });

  return theme;
}
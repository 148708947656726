

export enum ProductCategoryType {
  AirConditioner = 'air_conditioner',
  AirFilter = 'air_filter',
  CO2Generator = 'co2_generator',
  Controller = 'controller',
  DripSystem = 'drip_system',
  GrowBox = 'grow_box',
  HydroponicSystem = 'hydroponic_system',
  Lamp = 'lamp',
  Medium = 'medium',
  Nutrient = 'nutrient',
  Seed = 'seed',
  Tent = 'tent',
  VentilationFan = 'ventilation_fan',
}



export const ProductCategoryDescription: Record<ProductCategoryType, string> = {
  [ProductCategoryType.AirConditioner]: 'Air Conditioner – Devices that regulate temperature and humidity, maintaining optimal climate conditions for plant growth in controlled environments.',
  [ProductCategoryType.AirFilter]: 'Air Filter – Systems that purify the air by removing contaminants, ensuring a clean and healthy environment for plants and cultivation spaces.',
  [ProductCategoryType.CO2Generator]: 'CO2 Generator – Equipment that generates carbon dioxide to enhance photosynthesis, promoting healthier and faster plant growth in enclosed spaces.',
  [ProductCategoryType.Controller]: 'Controller – Centralized systems that manage and automate various aspects of the growing environment, including temperature, humidity, lighting, and ventilation.',
  [ProductCategoryType.DripSystem]: 'Drip System – Irrigation systems that deliver water and nutrients directly to plant roots through a network of tubes and emitters, ensuring efficient and consistent moisture distribution.',
  [ProductCategoryType.GrowBox]: 'Grow Box – Compact, all-in-one systems for indoor cultivation, featuring integrated lighting, ventilation, and often hydroponic setups to provide a self-contained environment for plant growth.',
  [ProductCategoryType.HydroponicSystem]: 'Hydroponic System – Soilless cultivation systems that deliver nutrients directly to plant roots through a water-based solution, enabling efficient and scalable plant growth.',
  [ProductCategoryType.Lamp]: 'Lamp – High-performance lighting solutions designed to provide the necessary light spectrum and intensity for photosynthesis, essential for indoor plant growth.',
  [ProductCategoryType.Medium]: 'Medium – Substrates used to support plant roots, providing structure and retaining moisture and nutrients in soilless or soil-based cultivation systems.',
  [ProductCategoryType.Nutrient]: 'Nutrient – Specialized formulations that supply essential minerals and compounds necessary for plant growth, ensuring healthy and vigorous development.',
  [ProductCategoryType.Seed]: 'Strains – High-quality plant seeds, the foundation of cultivation, chosen for their genetics and suitability to the growing environment.',
  [ProductCategoryType.Tent]: 'Tent – Portable, reflective enclosures that create a controlled growing environment by containing light, temperature, and humidity for optimal plant cultivation.',
  [ProductCategoryType.VentilationFan]: 'Ventilation Fan – Fans and ventilation systems that ensure proper air circulation, removing excess heat and humidity while supplying fresh air to maintain a healthy growing environment.',
};


export const ProductCategoryImage: Record<ProductCategoryType, string> = {
  [ProductCategoryType.AirConditioner]: '/images/product-categories/air_conditioner.svg',
  [ProductCategoryType.AirFilter]: '/images/product-categories/air_filter.svg',
  [ProductCategoryType.CO2Generator]: '/images/product-categories/co2_generator.svg',
  [ProductCategoryType.Controller]: '/images/product-categories/controller.svg',
  [ProductCategoryType.DripSystem]: '/images/product-categories/drip_system.svg',
  [ProductCategoryType.GrowBox]: '/images/product-categories/grow_box.svg',
  [ProductCategoryType.HydroponicSystem]: '/images/product-categories/hydroponic_system.svg',
  [ProductCategoryType.Lamp]: '/images/product-categories/lamp.svg',
  [ProductCategoryType.Medium]: '/images/product-categories/medium.svg',
  [ProductCategoryType.Nutrient]: '/images/product-categories/nutrient.svg',
  [ProductCategoryType.Seed]: '/images/product-categories/seed.svg',
  [ProductCategoryType.Tent]: '/images/product-categories/tent.svg',
  [ProductCategoryType.VentilationFan]: '/images/product-categories/ventilation_fan.svg',
};


export const ProductCategorySort = [
  ProductCategoryType.Seed,
  ProductCategoryType.Nutrient,
  ProductCategoryType.Lamp,
  ProductCategoryType.Tent,
  ProductCategoryType.GrowBox,
  ProductCategoryType.VentilationFan,
  ProductCategoryType.AirFilter,
  ProductCategoryType.AirConditioner,
  ProductCategoryType.Controller,
  ProductCategoryType.DripSystem,
  ProductCategoryType.HydroponicSystem,
  ProductCategoryType.CO2Generator
]




export enum ProductLampType {
  Fl = 1,
  Hid = 2,
  Led = 3
}

export const ProductLampTypeImage: Record<ProductLampType, string> = {
  [ProductLampType.Fl]: '/images/product-categories/lamp_1.svg',
  [ProductLampType.Hid]: '/images/product-categories/lamp_2.svg',
  [ProductLampType.Led]: '/images/product-categories/lamp_3.svg'
};


export const ProductLampTypeTrShort: Record<ProductLampType, string> = {
  [ProductLampType.Fl]: 'universal_type_light_fl',
  [ProductLampType.Hid]: 'universal_type_light_hid',
  [ProductLampType.Led]: 'universal_type_light_led',
};

 
export enum ProductLampFaza {
  Vegetation = 0,
  Flowering = 1
}



export const ProductLampTypeInfo: Record<ProductLampType, { tr: string, img: string, desc: string }> = {
  [ProductLampType.Fl]: {
    tr: 'universal_type_light_fl',
    img: '/images/setup_diary/setup_diary_icons-06.svg',
    desc: 'Fluorescent – Energy-efficient lighting option that emits a soft, diffused light suitable for seedlings and clones.',
  },
  [ProductLampType.Hid]: {
    tr: 'universal_type_light_hid',
    img: '/images/setup_diary/setup_diary_icons-07.svg',
    desc: 'HID – High-intensity discharge lighting system that produces bright, intense light for optimal plant growth and flowering.',
  },
  [ProductLampType.Led]: {
    tr: 'universal_type_light_led',
    img: '/images/setup_diary/setup_diary_icons-08.svg',
    desc: 'LED – Light-emitting diode technology that provides a full spectrum of light for efficient and effective plant growth.',
  },
};



export const ProductCategoryTypeNameShort: Record<ProductCategoryType, string> = {
  [ProductCategoryType.Tent]: 'Tent',
  [ProductCategoryType.GrowBox]: 'Box',
  [ProductCategoryType.VentilationFan]: 'Fan',
  [ProductCategoryType.AirFilter]: 'Filt',
  [ProductCategoryType.AirConditioner]: 'Cond',
  [ProductCategoryType.DripSystem]: 'Drip',
  [ProductCategoryType.HydroponicSystem]: 'Hydro',
  [ProductCategoryType.Controller]: 'Contr',
  [ProductCategoryType.CO2Generator]: 'CO2',
  [ProductCategoryType.Lamp]: 'Lamp',
  [ProductCategoryType.Medium]: 'Med',
  [ProductCategoryType.Nutrient]: 'Nutr',
  [ProductCategoryType.Seed]: 'Seed',
};
  



// Localization keys

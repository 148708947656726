import { ref, createApp } from 'vue'
import { useNuxtApp, useCookie, useRouter } from '#app'
import { useAdvStat } from '@/composables/useAdvStat'
import AdvCpmComponent from '@/components/adv/Cpm.vue'
import type { AdvPackage, AdvAction, AdvBannerCpm } from '@/types/adv'

const list = ref<AdvPackage<AdvBannerCpm>[]>([])
const listIgnore = ref<string[]>([])
const prepared = ref<AdvBannerCpm[]>([])
const insertCount = ref(0)
const lastNum = ref(0)
const lastRow = ref(0)
const elements = ref<HTMLElement[]>([])

export function useAdvCpm() {

  const cookieNameViewedPackages = 'ac_v_packages'
  const cookieNameViewedPackagesBanners = 'ac_v_packages_banners'
  const packageImpressions = ref<{ [key: number]: number }>({})
  const bannerImpressions = ref<{ [key: number]: number }>({})

  function getSection() {
    const router = useRouter()
    const path = router.currentRoute.value.fullPath.split('/')
    return path[1] ? path[1] : 'index'
  }


  function setIgnore(l: string[]) {
    listIgnore.value = l
  }


  function isIgnorePages() {
    const router = useRouter()
    const path = router.currentRoute.value.fullPath
    const ignore = [
      'diaries/[\\w-]+/edit/week',
      'grower/[\\w-]+/settings',
      'diaries/edit',
      'brands-manager',
    ].map((item) => new RegExp(item))
    for (const item of ignore) {
      if (item.test(path)) return true
    }
    return false
  }

  function isIgnoreSection() {
    const router = useRouter()
    const path = router.currentRoute.value.fullPath
    if (!listIgnore.value) return false
    for (const ignore_section of listIgnore.value) {
      if (path.indexOf(ignore_section) >= 0) return true
    }
    return false
  }

  function getRandomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  function isMobile() {
    return window.innerWidth <= 768
  }

  function view(id: number) {

    const action: AdvAction = {
      id,
      type: 'cpm',
      section: getSection(),
      action: 'view',
    }
    useAdvStat().value.push(action)

    addImpression(id)
    addViewedBanner(id)
    addViewedPackage(getPackageIdByBannerId(id))
  }

  function click(id: number) {

    const action: AdvAction = {
      id,
      type: 'cpm',
      section: getSection(),
      action: 'click',
    }
    useAdvStat().value.push(action)
    
    useNuxtApp().$ga.clickBanner('cpm')
  }

  function addImpression(id: number) {
    if (!list.value.length) return false
    const pack = list.value.find(
      (p) => p.banners?.find((b) => b.id === id)
    )
    if (!pack) return false
    pack.sys_impression++
    const banner = pack.banners.find((b) => b.id === id)
    if (!banner) return false
    banner.sys_impression++
  }

  function getPackageIdByBannerId(id: number) {
    for (const p of list.value) {
      for (const b of p.banners) {
        if (b.id === id) return p.id
      }
    }
    return null
  }

  function clear() {
    insertCount.value = 0
    lastNum.value = 0
    elements.value = []
  }

  function isReadyApply() {
    return true
  }

  function setLastNum(num: number) {
    lastNum.value = num
  }

  function setLastRow(num: number) {
    lastRow.value = num
  }

  function getViewedPackages() {
    const val = useCookie(cookieNameViewedPackages, { maxAge: 60 * 60 * 24 * 31 * 6 }).value
    if (!val) return []
    const viewedStr = String(val)
    if (viewedStr.indexOf(',') < 0) return [Number(viewedStr)]
    return viewedStr.split(',').map((i) => Number(i))
  }

  function setViewedPackages(ids: number[]) {
    if (!ids?.length) return
    useCookie(cookieNameViewedPackages, { maxAge: 60 * 60 * 24 * 31 * 6 }).value = ids.join(',')
  }

  function getViewedPackagesBanners() {
    const val = useCookie(cookieNameViewedPackagesBanners, { maxAge: 60 * 60 * 24 * 31 * 6 }).value
    if (!val) return []
    const viewedStr = String(val)
    if (viewedStr.indexOf(',') < 0) return [Number(viewedStr)]
    return viewedStr.split(',').map((i) => Number(i))
  }

  function setViewedPackagesBanners(ids: number[]) {
    if (!ids?.length) return
    useCookie(cookieNameViewedPackagesBanners, { maxAge: 60 * 60 * 24 * 31 * 6 }).value = ids.join(',')
  }

  function removeViewedPackage(id: number, arr: number[]) {
    return arr.filter((item) => item !== id)
  }

  function addViewedPackage(id: number | null) {
    if (!id) return
    const num = Number(id)
    let viewed = getViewedPackages()
    viewed = removeViewedPackage(num, viewed)
    viewed.push(num)
    setViewedPackages(viewed)
  }

  function addViewedBanner(id: number) {
    const num = Number(id)
    let viewed = getViewedPackagesBanners()
    viewed = removeViewedPackage(num, viewed)
    viewed.push(num)
    setViewedPackagesBanners(viewed)
  }

  function sortPackageBannersByViews(banners: AdvBannerCpm[]) {
    const viewed = getViewedPackagesBanners()
    if (!viewed.length) return banners
    return banners.sort((a, b) => viewed.indexOf(a.id) - viewed.indexOf(b.id))
  }

  function sortPackageByViews(packages: AdvPackage<AdvBannerCpm>[]) {
    const viewed = getViewedPackages()
    if (!viewed.length) return packages
    return packages.sort((a, b) => viewed.indexOf(a.id!) - viewed.indexOf(b.id!))
  }

  function sortPackageByImpressions(packages: AdvPackage<AdvBannerCpm>[]) {
    return packages.sort((a, b) => (a.sys_impression / a.boost) - (b.sys_impression / b.boost))
  }

  function sortBannersByImpressions(packs: AdvPackage<AdvBannerCpm>[]) {
    return packs.map((p) => {
      p.banners = p.banners.sort((a, b) => (bannerImpressions.value[a.id] ?? 0) - (bannerImpressions.value[b.id] ?? 0))
      return p
    })
  }

  function flattenBanners(packs: AdvPackage<AdvBannerCpm>[]) {
    if (!packs.length) return []
    const copy = JSON.parse(JSON.stringify(packs)) as AdvPackage<AdvBannerCpm>[]
    const maxCount = copy.sort((a, b) => b.banners.length - a.banners.length)[0].banners.length
    for (const p of packs) {
      if (p.banners.length < maxCount) {
        for (let i = p.banners.length; i < maxCount; i++) {
          p.banners = [...p.banners, ...p.banners]
        }
        p.banners = p.banners.slice(0, maxCount)
      }
    }
    const result: AdvBannerCpm[] = []
    for (let i = 0; i < maxCount; i++) {
      for (const p of packs) {
        if (p.banners[i]) result.push(p.banners[i])
      }
    }
    return result
  }

  function sortAndFilter() {
    const packages = JSON.parse(JSON.stringify(list.value)) as AdvPackage<AdvBannerCpm>[]
    if (getSection() === 'index') {
      const boosted = packages.filter((p) => p.boosted)
      const others = packages.filter((p) => !p.boosted)
      boosted.sort((a, b) => b.sys_impression - a.sys_impression)
      const boostedSorted = sortPackageByViews(boosted)
      others.sort((a, b) => (a.sys_impression / a.boost) - (b.sys_impression / b.boost))
      const othersSorted = sortPackageByViews(others)
      const boostedBanners = flattenBanners(boostedSorted)
      const otherBanners = flattenBanners(othersSorted)
      let banners: AdvBannerCpm[] = []
      let boosted_banner_1: AdvBannerCpm | null = null
      let boosted_banner_2: AdvBannerCpm | null = null
      if (boostedSorted.length && boostedSorted[0].banners.length) {
        boosted_banner_1 = boostedSorted[0].banners[0]
        if (boostedSorted[0].banners.length > 1) {
          boosted_banner_2 = boostedSorted[0].banners[1]
        }
      }
      if (boosted_banner_1) banners.push(boosted_banner_1)
      banners = [...banners, ...otherBanners]
      if (boosted_banner_2) banners.push(boosted_banner_2)
      banners = [...banners, ...boostedBanners]
      prepared.value = banners
    } else {
      packages.sort((a, b) => (a.sys_impression / a.boost) - (b.sys_impression / b.boost))
      const sorted = sortPackageByViews(packages)
      prepared.value = flattenBanners(sorted)
    }
    return prepared.value
  }

  function setList(newList: AdvPackage<AdvBannerCpm>[]) {
    list.value = newList
  }


  function getNext() {
    if (lastNum.value > prepared.value.length - 1) {
      lastNum.value = 0
    }
    const ret = prepared.value[lastNum.value]
    lastNum.value++
    insertCount.value++
    return ret
  }

  function prepare() {
    setLastNum(0)
    setLastRow(0)
    sortAndFilter()
  }

  function applyWithRedraw() {
    applyPlaceholder(true)
  }

  function getContainer(containers?: string[]) {
    const arr = containers || [
      '.feed_items',
      '.reviews_boxs',
      '.harvests_boxs',
      '.products_boxs',
      '.brands_boxs',
      '.gallery_rows',
      '.report_boxs',
      '.comments',
      '.growers_boxs',
      '.catalog_content',
    ]
    for (const c of arr) {
      const el = document.querySelector(c)
      if (el) return el
    }
  }

  function getCountInContainer(container: Element, cls: string) {
    if (!container.querySelector('.' + cls)) return 0
    return container.querySelectorAll('.' + cls).length
  }

  function getCountInRowContainer(container: Element, cls: string) {
    const el = container.querySelector('.' + cls) as HTMLElement | null
    if (!el) return 0
    const width = el.offsetWidth
    const width_list = (container as HTMLElement).offsetWidth
    return Math.round(width_list / width)
  }

  function getStepContainer(container: Element) {
    const ret = { step: 3, count: 0, items_class: '*' }
    if (getCountInContainer(container, 'video_item')) {
      const cnt_row = getCountInRowContainer(container, 'video_item')
      ret.step = 3 * cnt_row
      ret.count = getCountInContainer(container, 'video_item')
      ret.items_class = '.video_item'
    } else if (
      getCountInContainer(container, 'row_item') &&
      document.location.pathname === '/' &&
      isMobile()
    ) {
      ret.step = 4
      ret.count = getCountInContainer(container, 'row_item')
      ret.items_class = '.row_item'
    } else if (
      getCountInContainer(container, 'report_box_item') &&
      document.location.pathname.indexOf('explore') >= 0 &&
      isMobile()
    ) {
      const cnt_row = getCountInRowContainer(container, 'report_box_item')
      ret.step = 4 * cnt_row - 1
      ret.count = getCountInContainer(container, 'report_box_item')
      ret.items_class = '.report_box_item'
    } else if (
      getCountInContainer(container, 'report_box_item') &&
      document.location.pathname.indexOf('explore') < 0 &&
      document.location.pathname.indexOf('/') >= 0
    ) {
      const cnt_row = getCountInRowContainer(container, 'report_box_item')
      ret.step = 3 * cnt_row
      ret.count = getCountInContainer(container, 'report_box_item')
      ret.items_class = '.report_box_item'
    } else if (getCountInContainer(container, 'review_item')) {
      ret.step = isMobile() ? 6 : 6
      ret.count = getCountInContainer(container, 'review_item')
      ret.items_class = '.review_item'
    } else if (getCountInContainer(container, 'comment')) {
      ret.step = isMobile() ? 7 : 7
      ret.count = getCountInContainer(container, 'comment')
      ret.items_class = '.comment'
    } else if (getCountInContainer(container, 'solution_item')) {
      ret.step = isMobile() ? 7 : 7
      ret.count = getCountInContainer(container, 'solution_item')
      ret.items_class = '.solution_item'
    } else if (getCountInContainer(container, 'row_item')) {
      ret.step = isMobile() ? 10 : 20
      ret.count = getCountInContainer(container, 'row_item')
      ret.items_class = '.row_item'
    } else if (getCountInContainer(container, 'grower_item')) {
      ret.step = isMobile() ? 10 : 20
      ret.count = getCountInContainer(container, 'grower_item')
      ret.items_class = '.grower_item'
    } else if (getCountInContainer(container, 'breeder_item')) {
      ret.step = isMobile() ? 10 : 20
      ret.count = getCountInContainer(container, 'breeder_item')
      ret.items_class = '.breeder_item'
    } else if (getCountInContainer(container, 'harvest_row')) {
      ret.step = isMobile() ? 10 : 20
      ret.count = getCountInContainer(container, 'harvest_row')
      ret.items_class = '.harvest_row'
    } else if (getCountInContainer(container, 'item')) {
      ret.step = isMobile() ? 10 : 20
      ret.count = getCountInContainer(container, 'item')
      ret.items_class = '.item'
    } else if (getCountInContainer(container, 'event')) {
      ret.step = isMobile() ? 20 : 40
      ret.count = getCountInContainer(container, 'event')
      ret.items_class = '.event'
    }
    return ret
  }

  function getNextAll(element: Element, cls: string) {
    const nextElements: Element[] = []
    let nextEl: Element | null = element
    while (nextEl?.nextElementSibling) {
      if (nextEl.nextElementSibling.classList.contains(cls)) {
        nextElements.push(nextEl.nextElementSibling)
      }
      nextEl = nextEl.nextElementSibling
    }
    return nextElements
  }

  function applyList(containers?: string[]) {
    const container = getContainer(containers)
    if (!container) return false
    const count_box = container.querySelectorAll('.cpm').length
    const steps = getStepContainer(container)
    const ready_box = Math.floor(steps.count / steps.step)
    if (ready_box > count_box) {
      for (let i = 0; i < ready_box - count_box; i++) {
        const boxes = container.querySelectorAll('.cpm')
        const lastBox = boxes.length ? boxes[boxes.length - 1] : null
        const nextEl =
          lastBox &&
          lastBox.nextElementSibling &&
          lastBox.nextElementSibling.classList.contains(steps.items_class.substr(1))
            ? lastBox.nextElementSibling
            : container.querySelector('* > ' + steps.items_class)
        if (!nextEl) return
        const nextAll = getNextAll(nextEl, steps.items_class.substr(1))
        const afterBox = nextAll[steps.step - 1] ? nextAll[steps.step] : nextEl
        if (nextAll.length < steps.step) return
        if (afterBox) {
          const data_box = getNext()
          const mountNode = document.createElement('div')
          mountNode.className = 'cpm'
          const app = createApp(AdvCpmComponent, {
            data: data_box
          })
          app.mount(mountNode)
          afterBox.before(mountNode)
        }
      }
    }
  }

  function applyPlaceholder(redraw?: boolean) {
    if (typeof redraw !== 'undefined' && redraw) {
      prepare()
      document.querySelectorAll('.cpm').forEach((el) => {
        el.classList.add('save-size')
      })
      document.querySelectorAll('.cpm .cpm_item').forEach((el) => {
        el.remove()
      })
    }
    const nodes = document.querySelectorAll('.cpm')
    for (const node of nodes) {
      if (node.querySelectorAll('.cpm_item').length) continue
      const data_box = getNext()
      const app = createApp(AdvCpmComponent, {
        data: data_box
      })
      app.mount(node)
    }
  }

  function apply() {
    if (isIgnoreSection()) return false
    if (isIgnorePages()) return false
    if (!isReadyApply()) return false
    applyPlaceholder()
    applyList()
    applyList(['.comments'])
  }

  return {
    list,
    prepared,
    insertCount,
    lastNum,
    lastRow,
    elements,
    cookieNameViewedPackages,
    cookieNameViewedPackagesBanners,
    packageImpressions,
    bannerImpressions,
    getSection,
    isIgnorePages,
    isIgnoreSection,
    getRandomInt,
    isMobile,
    view,
    click,
    addImpression,
    getPackageIdByBannerId,
    clear,
    isReadyApply,
    setLastNum,
    setLastRow,
    getViewedPackages,
    setViewedPackages,
    getViewedPackagesBanners,
    setViewedPackagesBanners,
    removeViewedPackage,
    addViewedPackage,
    addViewedBanner,
    sortPackageBannersByViews,
    sortPackageByViews,
    sortPackageByImpressions,
    sortBannersByImpressions,
    flattenBanners,
    sortAndFilter,
    setList,
    getNext,
    prepare,
    applyWithRedraw,
    apply,
    getContainer,
    getStepContainer,
    getCountInContainer,
    getCountInRowContainer,
    getNextAll,
    applyList,
    applyPlaceholder,
    setIgnore,
  }
}
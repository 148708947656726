<template>

  <div class="prd_edit">

    <div class="modal_user_content" v-if="edit_data">

        <div class="settings_description">

            <div expanded>
              <NuxtLink to="/brands-manager/products" exact class="right_link">&lt; {{ $t('brand_manager_view_tab_products_back_link') }}</NuxtLink>
            </div>
            <h2>{{ $t('brand_manager_view_tab_products_edit_product') }} - {{ edit_data.name ? edit_data.name : 'New' }}</h2>

          </div>

          <div style="clear:both">&nbsp;</div>
    
          <form id="form_product">

            <div class="nr">

                <div class="av">
                  <img class="avatar_user avatar_item" :src="edit_data.cover_s ? edit_data.cover_s : '/images/product-empty-avatar.png'" data-not-lazy>
                </div>

                <div class="nm">
                  <div class="field">

                    <div class="label">{{ $t('brand_manager_view_tab_products_name') }}</div>
                    <n-input
                      v-model:value="edit_data.name" 
                      type="text" 
                      :value="edit_data.name" 
                      @input="validateName" 
                      :placeholder="$t('brand_manager_view_tab_products_name')" 
                      :status="validationErrors.name ? 'error' : ''"
                    />

                    <n-alert v-if="validationErrors.name"
                      title="" 
                      :show-icon="false"
                      :type="validationErrors.name ? 'error' : 'default'"
                     >
                      {{validationErrors.name ? validationErrors.name.message : ''}}
                    </n-alert>

                  </div>

                  <div style="clear:both">&nbsp;</div>

                  <div class="field fup">
                    <n-upload
                      ref="uploadLogoList"
                      :custom-request="submitAvatar"
                      :show-file-list="false"
                      >
                      <UiButton
                        :name="$t('brand_manager_view_tab_products_cover')"
                        />
                    </n-upload>
                  </div>

                </div>

            </div>

            <div style="clear:both">&nbsp;</div>

            <n-alert v-if="validationErrors.logo"
              title="" 
              :show-icon="false"
              :type="validationErrors.logo ? 'error' : 'default'"
             >
              {{validationErrors.logo ? validationErrors.logo.message : ''}}
            </n-alert>


            <h2>{{ $t('brand_manager_view_tab_banners_features') }}</h2>

            <div class="two_fld">

               <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_ventilation_fan_diameter') }}</div>
                  <n-input 
                    v-model:value="edit_data.diameter" 
                    type="text"
                    @input="validateVentilationFanDiameter" 
                    :placeholder="$t('brand_manager_view_tab_products_ventilation_fan_diameter')" 
                    :status="validationErrors.diameter ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.diameter"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.diameter ? 'error' : 'default'"
                   >
                    {{validationErrors.diameter ? validationErrors.diameter.message : ''}}
                  </n-alert>
                  
               </div>
                  
               <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_ventilation_fan_airflow_capacity') }}</div>
                  <n-input 
                    v-model:value="edit_data.airflow_capacity" 
                    type="text"
                    @input="validateVentilationFanAirflowCapacity()" 
                    :placeholder="$t('brand_manager_view_tab_products_ventilation_fan_airflow_capacity')" 
                    :status="validationErrors.airflow_capacity ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.airflow_capacity"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.airflow_capacity ? 'error' : 'default'"
                   >
                    {{validationErrors.airflow_capacity ? validationErrors.airflow_capacity.message : ''}}
                  </n-alert>
                  
               </div>

            </div>

            <div class="two_fld">

               <div class="group50">
                  
                  <div class="label">{{ $t('brand_manager_view_tab_products_release_year') }}</div>
                  <ClientOnly>
                  <n-select
                    v-model:value="release_year"
                    filterable
                    :placeholder="$t('brand_manager_view_tab_products_release_year')"
                    :options="multipleReleaseYearAvaliable"
                    value-field="id"
                    label-field="name"
                    @update:value="validateReleaseYear"
                  />
                </ClientOnly>

                  <n-alert v-if="validationErrors.release_year"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.release_year ? 'error' : 'default'"
                   >
                    {{validationErrors.release_year ? validationErrors.release_year.message : ''}}
                  </n-alert>
                  
               </div>
                  
               <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_products_link') }}</div>
                  <n-input 
                    v-model:value="edit_data.url_website" 
                    type="text" 
                    :value="edit_data.url_website" 
                    @input="validateWebLink" 
                    :placeholder="$t('brand_manager_view_tab_products_link')" 
                    :status="validationErrors.url_website ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.url_website"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.url_website ? 'error' : 'default'"
                   >
                    {{validationErrors.url_website ? validationErrors.url_website.message : ''}}
                  </n-alert>

               </div>

            </div>

            <div class="two_fld">

              <div class="label">{{ $t('brand_manager_view_tab_products_desc') }}</div>
              <n-input 
                v-model:value="edit_data.description" 
                type="textarea" 
                :value="edit_data.description" 
                @input="validateDesc"
                :placeholder="$t('brand_manager_view_tab_products_desc')" 
                :status="validationErrors.description ? 'error' : ''"
                :has-counter="hasCounter"
                :rows="8"
              />

              <div class="field-words"><div class="words">{{ $t('brand_manager_view_description_word_count') }}: <span>{{ wordsCount }}</span></div></div>

              <n-alert v-if="validationErrors.description"
                title="" 
                :show-icon="false"
                :type="validationErrors.description ? 'error' : 'default'"
               >
                {{validationErrors.description ? validationErrors.description.message : ''}}
              </n-alert>

            </div>
            
            <div class="two_fld">

              <div class="label">{{ $t('brand_manager_view_tab_products_photos') }}</div>
              <n-upload
                ref="uploadPhotoList"
                :custom-request="submitPhoto"
                :show-file-list="false"
                :disabled = "isUploading"
                multiple="true"
                >
                <n-upload-dragger class="no-padding">
                  <div class="load_box_photos">
                    <label>{{ $t('brand_manager_view_tab_brand_click_to_upload') }}</label>
                  </div>
                </n-upload-dragger>
              </n-upload>

             <div style="clear:both">&nbsp;</div>

             <div class="upload_photos" expanded>
                <draggable v-model="edit_data.items_photo" item-key="tmp_id" v-if="edit_data.items_photo" @start="drag=true"  @end="drag=false" >

                    <template #item="{element}">
                      <div class="item">
                        <div class="item_table">
                          <div class="item_td">
                            <div class="delete" @click="removePhoto(element)">
                              <b-icon pack="fas" icon="circle" size="is-small"></b-icon>
                            </div>
                            <img :src="element.url_s || element.temp">
                          </div>
                        </div>
                      </div>
                    </template>

                </draggable>
             </div>

            </div>

            <div style="clear:both">&nbsp;</div>

            <n-alert v-if="validationErrors.photos"
              title="" 
              :show-icon="false"
              :type="validationErrors.photos ? 'error' : 'default'"
             >
              {{validationErrors.photos ? validationErrors.photos.message : ''}}
            </n-alert>

            <div style="clear:both">&nbsp;</div>

            <div style="display: flex; gap:0.5rem">
              <UiButton
                :name="$t('universal_button_save')"
                :is-loading="isLoading"
                :type="'primary'"
                @click="saveProduct()"
                />
              <span v-if="edit_data.change_cnt" class="changeset">&nbsp; {{ edit_data.change_cnt + ' ' + $t('brand_manager_view_moderation_mark')}}</span>
            </div>

          </form>

        </div>

        <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

    </div>

</template>

<script setup>

import { NButton, NSelect, NInput, NAlert, NUpload, NUploadDragger, useMessage, NMessageProvider } from 'naive-ui'
import draggable from 'vuedraggable'
import editFuncs from '/pages/brands-manager/func/editProduct.js'
import Utils from '/pages/brands-manager/func/utils.js'
import { useI18n } from "vue-i18n"

const {$api} = useNuxtApp()
const { t } = useI18n()
const message = useMessage()
const route = useRoute();

const props = defineProps({
  edit_data: {
    type: Object
  }
});

const avatar = ref('')
const hasCounter = ref(false)
const wordsCount = ref(0)
const validationErrors = ref({})
const isLoading = ref(false)
const drag = ref(false)
const edit_data = ref(props.edit_data)
const multipleReleaseYearAvaliable = ref([])
const release_year = ref([{id:0,name:''}])
const uploadLogoList = ref(null)
const uploadPhotoList = ref(null)
const isUploading = ref(false)

const editDataClone = Utils.makeClone(edit_data.value);

// init release year 
for(var k of edit_data.value.items_release_year_avaliable){
  if(k.id == 0) multipleReleaseYearAvaliable.value.push({id:k.id, name:''});
  else if(k.id == 1) multipleReleaseYearAvaliable.value.push({id:k.id, name:t(k.name)});
  else if(k.id == 2) multipleReleaseYearAvaliable.value.push({id:k.id, name:t(k.name)});
  else multipleReleaseYearAvaliable.value.push({id:k.id, name:k.name});
}

for(var k of edit_data.value.items_release_year_avaliable){
  if(k.id == 0 && edit_data.value.release_year == 0 && edit_data.value.is_test){
    release_year.value = k.id;
    break;
  }else if(k.id == 1 && !edit_data.value.is_test && edit_data.value.release_year != 0 && edit_data.value.release_year != 2 && !edit_data.value.discontinued && (edit_data.value.release_year == '' || edit_data.value.release_year < ((new Date()).getFullYear()-3) || !edit_data.value.id)){
    release_year.value = k.id;
    break;
  }else if(k.id == 2 && (edit_data.value.release_year == 2 || edit_data.value.discontinued)){
    release_year.value = k.id;
    break;
  }else if(k.id == edit_data.value.release_year){
    release_year.value = k.id;
    break;
  }
}

// init other 
edit_data.value.ventilation_diameter = edit_data.value.ventilation_diameter ? edit_data.value.ventilation_diameter : '';
edit_data.value.airflow_capacity = edit_data.value.airflow_capacity ? edit_data.value.airflow_capacity : '';

if(!edit_data.value.items_photo) edit_data.value.items_photo = [];

const submitAvatar = (data) => {
  let avatar_obj = data.file.file;

  if(avatar_obj.type != 'image/jpeg' && avatar_obj.type != 'image/png' && avatar_obj.type != 'image/heicd'  && avatar_obj.type != 'image/webp'){
    message.error(t('brand_manager_view_tab_products_cover_format_error'));
    uploadLogoList.value.clear();
    return false;
  }

  let reader = new FileReader();
  reader.readAsDataURL(avatar_obj);
  reader.onload = evt => {
    let img = new Image();
    img.onload = () => {

      if(img.height  != '1400' || img.width != '1000') {
        message.error(t('brand_manager_view_tab_products_cover_size_error'));
        uploadLogoList.value.clear();
        return false;
      }
      
      uploadAvatar(avatar_obj);
    }
    img.src = evt.target.result;
  }

  reader.onerror = evt => {
    console.error(evt);
  }
}

const uploadAvatar = async (avatar_obj) => {
  let formData = new FormData(); 
  formData.append('file', avatar_obj);

  await $api.getB2BUploadAvatar(formData).then(res => {
    if(res){
      avatar.value = res.path;
      edit_data.value.cover_s = res.full_path;
      uploadLogoList.value.clear();
    }else {
      uploadLogoList.value.clear();
    }
  }).catch(function (error) {
     uploadLogoList.value.clear();
  });
}

const submitPhoto = (data) => {
  uploadPhoto(data.file.file);
}

const uploadPhoto = async (photo_obj) => {
  if(photo_obj.type != 'image/jpeg' && photo_obj.type != 'image/png' && photo_obj.type != 'image/heicd'  && photo_obj.type != 'image/webp'){
    message.warning(t('brand_manager_view_tab_products_photo_format_error'));
    uploadPhotoList.value.clear();
    return false;
  }

  isUploading.value = true;

  let formData = new FormData(); 
  formData.append('file', photo_obj);

  await $api.getB2BUploadAvatar(formData).then(res => {
    if(res){
      edit_data.value.items_photo.push({
         id:0,
         sort_id: Math.floor(Math.random() * 10000000000000000000),
         path:res.path,
         temp:res.full_path
      });
      validatePhotos();
      uploadPhotoList.value.clear();
      isUploading.value = false;
    }else {
      uploadPhotoList.value.clear();
      isUploading.value = false;
    }
  }).catch(function (error) {
     uploadPhotoList.value.clear();
     isUploading.value = false;
  });
}

const removePhoto = (item) => {
  var index = edit_data.value.items_photo.indexOf(item);
  if (index !== -1) {
    edit_data.value.items_photo.splice(index, 1);
  }
  validatePhotos();
}

const validateLogo = () => {
  if(!editFuncs.validateLogo(edit_data.value.cover_s,avatar.value,edit_data.value.is_test)){
    validationErrors.value['logo'] = { message: t('brand_manager_view_tab_products_cover_format_error') };
  }else{
    delete validationErrors.value['logo'];
  }
}

const validateName = () => {
  var error = editFuncs.validateName(edit_data.value.name);
  if(error == 1){
    validationErrors.value['name'] = { message: t('brand_manager_view_tab_brand_name_length_error') };
  }else if(error == 2){
    validationErrors.value['name'] = { message: t('brand_manager_view_tab_brand_name_format_error') };
  }else{
    delete validationErrors.value['name'];
  }
}

const validateVentilationFanDiameter = () => {
  if(!editFuncs.validateVentilationFanDiameter(edit_data.value.diameter)){
    validationErrors.value['diameter'] = { message: 'Diameter must be an integer' };
  }else{
    delete validationErrors.value['diameter'];
  }
}

const validateVentilationFanAirflowCapacity = () => {
  if(!editFuncs.validateVentilationFanAirflowCapacity(edit_data.value.airflow_capacity)){
    validationErrors.value['airflow_capacity'] = { message: 'Airflow capacity must be an integer' };
  }else{
    delete validationErrors.value['airflow_capacity'];
  }
}

const validateReleaseYear = () => {
  var error = editFuncs.validateReleaseYear(release_year.value,edit_data.value.is_test);
  if(error == 1){
    validationErrors.value['release_year'] = { message: 'This field must have at least 1 choices' };
  }else if(error == 2){
    validationErrors.value['release_year'] = { message: t('brand_manager_view_tab_products_release_year_error') };
  }else{
    delete validationErrors.value['release_year'];
  }
}

const validateWebLink = () => {
  if(!editFuncs.validateWebLink(edit_data.value.url_website)){
    validationErrors.value['url_website'] = { message: t('brand_manager_view_tab_products_link_format_error') };
  }else{
    delete validationErrors.value['url_website'];
  }
}

const validateDesc = () => {
  wordCounter();
  if(!editFuncs.validateDesc(edit_data.value.description,editDataClone.description)){
    validationErrors.value['description'] = { message: t('brand_manager_view_tab_products_desc_length_error') };
  }else{
    delete validationErrors.value['description'];
  }
}

const validatePhotos = () => {
  if(!editFuncs.validatePhotos(edit_data.value.items_photo)){
    validationErrors.value['photos'] = { message: t('brand_manager_view_tab_products_photos_count_error') };
  }else{
    delete validationErrors.value['photos'];
  }
}

const wordCounter = () => {
  wordsCount.value = editFuncs.wordCounter(edit_data.value.description);
}

wordCounter();


const saveProduct = () => {
  
  validateLogo();
  validateName();
  validateVentilationFanDiameter();
  validateVentilationFanAirflowCapacity();
  validateReleaseYear();
  validateWebLink();
  validateDesc();
  validatePhotos();

  if(Object.keys(validationErrors.value).length>0){
    message.error(t('brand_manager_view_generic_error'));
    return false;
  }

  var params = {};
  params.id = route.params['id'] || 0;
  params.tmp_id = edit_data.value.tmp_id || 0;
  params.avatar = avatar.value;
  params.name = edit_data.value.name;
  params.diameter = edit_data.value.diameter;
  params.airflow_capacity = edit_data.value.airflow_capacity;
  params.release_year = release_year.value;
  params.description = edit_data.value.description;
  params.url_website = edit_data.value.url_website;

  // photos 
  var _array = [];
  for(var t2 in editDataClone.items_photo){
    _array.push(editDataClone.items_photo[t2]);
  }

  params.items_photo = [];
  params.items_remove_photo = [];

  if(JSON.stringify(edit_data.value.items_photo) != JSON.stringify(_array)){
    for(var t2 in edit_data.value.items_photo){
      params.items_photo.push(
      {
        id:edit_data.value.items_photo[t2].id,
        sort_id: Math.floor(Math.random() * 10000000000000000000),
        temp:edit_data.value.items_photo[t2].path
      }
      );
    }

    for(var t2 in editDataClone.items_photo){
      var hasPhoto = false;
      for(var t3 in edit_data.value.items_photo){
        if(editDataClone.items_photo[t2].id == edit_data.value.items_photo[t3].id) 
          hasPhoto = true;
      }
      if(!hasPhoto) params.items_remove_photo.push(
      {
        id:editDataClone.items_photo[t2].id,
        temp:editDataClone.items_photo[t2].path
      }
      );
    }
  }
  
  saveProductSend(params);
  //console.log(params);

}

const saveProductSend = async (params) => {
  isLoading.value = true;
  $api.postB2BSaveProduct(params.id, {'ventilation_fan': params}).then(res => {
    message.success(t('brand_manager_view_tab_products_save_success'));
    if(res && res.data.value.data.tmp_id){
      edit_data.value.tmp_id = res.data.value.data.tmp_id;
      window.history.pushState({}, null, '0?action=edit_item&tmp_id=' + res.data.value.data.tmp_id);
    }
    isLoading.value = false;
  }).catch(function (error) {
     isLoading.value = false;
     message.error(t('brand_manager_view_generic_error'));
  });
}


</script>

<style scoped>



/**/
 
</style>

<style>
 
 /* PRODUCT BRAND */

.prd_edit {
  padding: 20px;
  margin-bottom: 100px;
}

.prd_edit .nr {
  display: flex;
}

.prd_edit .nr .av {
  width: 110px;
  margin-right: 2rem;
  min-height: 110px;
  border: 2px #cecece dashed;
  border-radius: 7px;
  padding: 10px;
}

.prd_edit .nr .av img {
  width: 100%;
}

.prd_edit .nr .nm {
  width: 250px;
}

.prd_edit .nr .fup {
  position: relative;
}

.prd_edit .two_fld {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
}

.prd_edit .two_fld .ind_sat {
  margin-bottom: 1rem!important;
}

.prd_edit .two_fld>.field {
  width: 100%;
}


.right_link {
  float: right;
  color: black;
}

.left_link {
  color: #4183C4;
}

.right_link:hover{
  color: black;
}
.changeset {
  color: #FF0000;
  display: inline-flex;
  align-items: center;
}

.words{
  float: right;
  font-weight: bold;
  margin-top: 6px;
}

.indica{
  float: left;
  width: 250px;
}

.sativa{
  float: right;
  width: 250px;
  text-align: right;
}
/* UPLOADER */


.load_box{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}
.load_box.background{
  min-height: 200px;
}
.load_box::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}
.load_box input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

.load_box_photos{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}

.load_box_photos::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}
.load_box_photos input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

/* UPLOADER PHOTOS*/

.upload_photos .item{
  float: left;
  width: 100px;
  height: 100px;
  margin-left: 10px;
}

.upload_photos .item_table{
  display: table;
  width: 100px;
  height: 100px;
}

.upload_photos .item_td{
  display: table-cell;
  width: 100px;
  height: 100px;
  vertical-align: middle;
  text-align: center;
}

.upload_photos .item img{
  display: inline-block;
  max-height: 100px;
  max-width: 100px;
}

.upload_photos .delete{
  background-color: rgb(252, 83, 83);
  display: inline-block;
  position: absolute;
  padding: 5px;
  border-radius: 15px;
  margin-top: -5px;
  margin-left: -5px;
  color: white;
  border: 1px white solid;
  cursor: pointer;
}

.two_fld{
  
}

.prd_edit .two_fld>.group50 {
  width: 50%;
}

.prd_edit .two_fld>.group50:first-child {
  padding-right: 20px;
}

.prd_edit .group50>.subgroup50 {
  width: 50%;
  float: left;
}

.prd_edit .group50>.subgroup50:first-child {
  padding-right: 10px;
}

.prd_edit .two_fld .select,.prd_edit .two_fld .select select{
  width: 100%!important;
}

.prd_edit .two_fld .button {
  width: 100%!important;
}



</style>
<template>

  <div class="prd_edit">

    <div class="modal_user_content" v-if="edit_data">

        <div class="settings_description">

            <div expanded>
              <NuxtLink to="/brands-manager/banners" exact class="right_link">&lt; {{ $t('brand_manager_view_tab_banners_back_link') }}</NuxtLink>
            </div>
            <h2>{{ $t('brand_manager_view_tab_banners_edit_box_banner_title') }} - {{ edit_data.title ? edit_data.title : $t('brand_manager_view_tab_banners_new_box_banner_title') }}</h2>

          </div>

          <div style="clear:both">&nbsp;</div>
    
          <form id="form_product">

            <div class="nr">

                <div class="av_box">
                  <img class="avatar_user image_item_box" :src="edit_data.url" data-not-lazy>
                </div>

            </div>

            <div class="nr">

                <div class="nm">

                  <div class="field fup">

                    <n-upload
                      ref="uploadCoverList"
                      :custom-request="submitBanner"
                      :show-file-list="false"
                      >
                      <UiButton
                        :name="$t('brand_manager_view_tab_banners_box_cover')"
                        />
                    </n-upload>

                  </div>

                </div>

            </div>

            <div style="clear:both">&nbsp;</div>

            <n-alert v-if="validationErrors.logo_box"
              title="" 
              :show-icon="false"
              :type="validationErrors.logo_box ? 'error' : 'default'"
             >
              {{validationErrors.logo_box ? validationErrors.logo_box.message : ''}}
            </n-alert>


            <h2>{{ $t('brand_manager_view_tab_banners_features') }}</h2>

            <div class="two_fld">

               <div class="group50">
                  
                  <div class="label">{{ $t('brand_manager_view_tab_banners_title') }}</div>
                  <n-input 
                    v-model:value="edit_data.title" 
                    type="text" 
                    :value="edit_data.title" 
                    @input="validateTitle" 
                    :placeholder="$t('brand_manager_view_tab_banners_title')" 
                    :status="validationErrors.title ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.title"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.title ? 'error' : 'default'"
                   >
                    {{validationErrors.title ? validationErrors.title.message : ''}}
                  </n-alert>
                  
               </div>
                  
               <div class="group50">
                  
                  <div class="label">{{ $t('brand_manager_view_tab_banners_button') }}</div>
                  <n-input 
                    v-model:value="edit_data.button" 
                    type="text" 
                    :value="edit_data.button" 
                    @input="validateButton" 
                    :placeholder="$t('brand_manager_view_tab_banners_button')" 
                    :status="validationErrors.button ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.button"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.button ? 'error' : 'default'"
                   >
                    {{validationErrors.button ? validationErrors.button.message : ''}}
                  </n-alert>
                  
               </div>

            </div>

            <div class="two_fld">

               <div class="group50">
                  
                  <div class="label">{{ $t('brand_manager_view_tab_banners_link') }}</div>
                  <n-input 
                    v-model:value="edit_data.link" 
                    type="text" 
                    :value="edit_data.link" 
                    @input="validateLink" 
                    :placeholder="$t('brand_manager_view_tab_banners_link')" 
                    :status="validationErrors.link ? 'error' : ''"
                  />

                  <n-alert v-if="validationErrors.link"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.link ? 'error' : 'default'"
                   >
                    {{validationErrors.link ? validationErrors.link.message : ''}}
                  </n-alert>
                  
               </div>
                  
               <div class="group50">

                  <div class="label">{{ $t('brand_manager_view_tab_banners_region') }}</div>
                  <ClientOnly>
                  <n-select
                    v-model:value="edit_data.items_region"
                    :status="validationErrors.region ? 'is-danger' : ''"
                    filterable
                    multiple
                    :placeholder="$t('brand_manager_view_tab_banners_region')"
                    :options="edit_data.items_allow_region"
                    value-field="name"
                    label-field="name"
                    @update:value="validateRegion()"
                  />
                </ClientOnly>

                  <n-alert v-if="validationErrors.region"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.region ? 'error' : 'default'"
                   >
                    {{validationErrors.region ? validationErrors.region.message : ''}}
                  </n-alert>
                  
               </div>

            </div>

            <div class="two_fld">

               <div class="group50">
                  
                  <div class="label">Start Date</div>
                  <input 
                  type="datetime-local" 
                  v-model="edit_data.start_date"
                  />

                  <n-alert v-if="validationErrors.start_date"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.start_date ? 'error' : 'default'"
                   >
                    {{validationErrors.start_date ? validationErrors.start_date.message : ''}}
                  </n-alert>
                  
               </div>
                  
               <div class="group50">

                  <div class="label">End Date</div>
                  <ClientOnly>
                  <input 
                  type="datetime-local" 
                  v-model="edit_data.end_date"
                  />
                 </ClientOnly>

                  <n-alert v-if="validationErrors.start_date"
                    title="" 
                    :show-icon="false"
                    :type="validationErrors.start_date ? 'error' : 'default'"
                   >
                    {{validationErrors.start_date ? validationErrors.start_date.message : ''}}
                  </n-alert>
                  
               </div>

            </div>

            <div style="clear:both">&nbsp;</div>

            <div style="display: flex; gap: 1rem;">

              <UiButton
                :name="$t('universal_button_save')"
                :is-loading="isLoading"
                :type="'primary'"
                @click="saveBanner()"
                />

              <UiButton
                
                :name="$t('brand_manager_view_tab_banners_preview')"
                :disabled="!edit_data.id && !edit_data.tmp_id"
                @click="isPreviewModalActive = true"
                />

              <UiButton                
                :tag="'NuxtLink'"
                :to="'/brands-manager/banners'"
                :name="$t('universal_button_cancel')"
                />

              <span v-if="edit_data.change_cnt" class="changeset">&nbsp; {{ edit_data.change_cnt + ' ' + $t('brand_manager_view_moderation_mark')}}</span>
            </div>

          </form>

        </div>

        <n-modal
          v-model:show="isPreviewModalActive"
          :mask-closable="false"
          preset="dialog"
          :title="$t('brand_manager_view_tab_banners_preview')"
          content="Are you sure?"
          negative-text="Cancel"
          @negative-click="onNegativeClick"
        >
          <div class="media-img">
              <figure class="image box">
                  <img :src="edit_data.url" alt="">
              </figure>
          </div>

          <div class="banner_title">
            <div>{{ edit_data.title }}</div>
            <div class="action_btn">
              <a :href="edit_data.link" target="_blank">{{ edit_data.button }}</a>
            </div>
          </div>
          <template #footer>
            
          </template>
        </n-modal>

    </div>

</template>

<script setup>

import { NButton, NSelect, NInput, NAlert, NUpload, NUploadDragger, useMessage, NMessageProvider, NModal } from 'naive-ui'
import editBanner from '/pages/brands-manager/func/editBanner.js'
import { useI18n } from "vue-i18n"

const {$api} = useNuxtApp()
const { t } = useI18n()
const message = useMessage()
const route = useRoute();

const props = defineProps({
  edit_data: {
    type: Object
  }
});


const banner = ref('')
const uploadCoverList = ref(null)
const validationErrors = ref({})
const edit_data = ref(props.edit_data)
const isLoading = ref(false)
const isPreviewModalActive = ref(false)


const submitBanner = (data) => {
  let cover_obj = data.file.file;

  if(cover_obj.type != 'image/jpeg' && cover_obj.type != 'image/png' && cover_obj.type != 'image/heicd'  && cover_obj.type != 'image/webp'  && cover_obj.type != 'image/gif'){
    message.error(t('brand_manager_view_tab_banners_cover_format_error'));
    uploadCoverList.value.clear();
    return false;
  }

  let reader = new FileReader();
  reader.readAsDataURL(cover_obj);
  reader.onload = evt => {
    let img = new Image();
    img.onload = () => {

      if(img.height  != '248' || img.width != '360') {
        message.error(t('brand_manager_view_tab_banners_box_cover_size_error'));
        uploadCoverList.value.clear();
        return false;
      }
      
      uploadBanner(cover_obj);
    }
    img.src = evt.target.result;
  }

  reader.onerror = evt => {
    console.error(evt);
  }
}

const uploadBanner = async (cover_obj) => {
  let formData = new FormData(); 
  formData.append('file', cover_obj);

  await $api.getB2BUploadAvatar(formData).then(res => {
    if(res){
      banner.value = res.path;
      edit_data.value.url = res.full_path;
      uploadCoverList.value.clear();
    }
  })
}

const validateTitle = () => {
  if(!editBanner.validateTitle(edit_data.value.title)){
    validationErrors.value['title'] = { message: t('brand_manager_view_tab_banners_title_format_error') };
  }else{
    delete validationErrors.value['title'];
  }
}

const validateButton = () => {
  if(!editBanner.validateButton(edit_data.value.button)){
    validationErrors.value['button'] = { message: t('brand_manager_view_tab_banners_button_format_error') };
  }else{
    delete validationErrors.value['button'];
  }
}

const validateLink = () => {
  if(!editBanner.validateLink(edit_data.value.link)){
    validationErrors.value['link'] = { message: t('brand_manager_view_tab_banners_link_format_error') };
  }else{
    delete validationErrors.value['link'];
  }
}

const validateRegion = () => {
  if(!editBanner.validateRegion(edit_data.value.items_region)){
    validationErrors.value['region'] = { message: 'This field must have at least 1 choices' };
  }else{
    delete validationErrors.value['region'];
  }
}

const validateDate = () => {
  if(!editBanner.validateDate(edit_data.value.start_date,edit_data.value.end_date)){
    validationErrors.value['start_date'] = { message: 'Incorrect dates' };
  }else{
    delete validationErrors.value['start_date'];
  }
}

const validateBanner = () => {
  if(!editBanner.validateBanner(edit_data.value.url,banner.value)){
    validationErrors.value['logo_box'] = { message: t('brand_manager_view_tab_banners_cover_format_error') };
  }else{
    delete validationErrors.value['logo_box'];
  }
}

const saveBanner = () => {

  validateBanner();
  validateTitle();
  validateButton();
  validateLink();
  validateRegion();
  validateDate();

  if(Object.keys(validationErrors.value).length>0){
    message.error(t('brand_manager_view_generic_error'));
    return false;
  }
  
  var params = {};
  params.id = route.params['id'] || 0;
  params.tmp_id = edit_data.value.tmp_id || 0;
  params.url = banner.value;
  params.title = edit_data.value.title;
  params.button = edit_data.value.button;
  params.link = edit_data.value.link;
  params.start_date = edit_data.value.start_date;
  params.end_date = edit_data.value.end_date;

  params.items_region = [];
  for(var k of edit_data.value.items_region){
    params.items_region.push({name:k});
  }

  saveBannerSend(params);
  //console.log(params);

}

/*const saveBannerSend = async (params) => {
  isLoading.value = true;
  await $api.postB2BSaveBanner(params, 'box').then(res => {
    if(res && res.status == 'success'){
      message.success(t('brand_manager_view_tab_products_save_success'));
      if(params.id>0 || params.tmp_id>0){
        window.setTimeout('location.reload()', 1000);
      }else{
        window.setTimeout(function(){
          document.location.href='?action=edit_item&tmp_id=' + res.data.tmp_id;
        }, 1000);
      }
      
      isLoading.value = false;
    }
  })
}*/

const saveBannerSend = async (params) => {
  isLoading.value = true;
  $api.postB2BSaveBanner(params, 'box').then(res => {
    message.success(t('brand_manager_view_tab_products_save_success'));
    if(res && res.data.value.data.tmp_id){
      edit_data.value.tmp_id = res.data.value.data.tmp_id;
      window.history.pushState({}, null, '0?action=edit_item&tmp_id=' + res.data.value.data.tmp_id);
    }
    isLoading.value = false;
  }).catch(function (error) {
     isLoading.value = false;
     message.error(t('brand_manager_view_generic_error'));
  });
}

/*import editBanner from '/pages/brands-manager/func/editBanner.js'
import Utils from '/pages/brands-manager/func/utils.js'
  
export default { 
  props: {
    edit_data: {
      type: Object
    }
  },
  data() {
    return { 
      bannerupload: null,
      banner: '',
      multipleRegionSelections: [],
      validationErrors: {},
      hasError: 0,
      isLoading: false,
      isPreviewModalActive: false,
    }
  },
  created() {

  },
  methods: {   
    submitBanner: editBanner.submitBanner,
    uploadBanner: editBanner.uploadBanner,
    showNotification: Utils.showNotification,
    showErrorNotification: Utils.showErrorNotification,
    showErrorSubNotification: Utils.showErrorSubNotification,
    validateBanner: editBanner.validateBanner,
    validateTitle: editBanner.validateTitle,
    validateButton: editBanner.validateButton,
    validateLink: editBanner.validateLink,
    validateRegion: editBanner.validateRegion,
    validate(){
      this.hasError = 0;
      this.validationErrors = {};

      this.validateBanner('box');
      this.validateTitle();
      this.validateButton();
      this.validateLink('box');
      this.validateRegion();
    },
    saveBanner(){

      this.validate();

      if(this.hasError){
        this.showErrorNotification('brand_manager_view_generic_error');
        return false;
      }

      var params = {};
      params.id = this.$route.params.id || 0;
      params.tmp_id = this.edit_data.tmp_id || 0;
      params.url = this.banner;
      params.title = this.edit_data.title;
      params.button = this.edit_data.button;
      params.link = this.edit_data.link;

      params.items_region = [];
      for(var t in this.edit_data.items_region){
        params.items_region.push({name:this.edit_data.items_region[t]});
      }

      this.isLoading = true;

      var _this = this;
      this.$axios.post('/brands-manager/banners/' + this.$route.params.id + '/box/edit', params)
        .then((res) => {
           if(res.data.code == 200) {
             this.showNotification('brand_manager_view_tab_products_save_success');

             if(this.$route.params.id>0 || params.tmp_id>0){
               window.setTimeout('location.reload()', 1000);
             }else{
               window.setTimeout(function(){document.location.href='?action=edit_item&tmp_id=' + res.data.data.tmp_id ;}, 1000);
             }

             this.isLoading = false;

           }else{ 
              this.showErrorNotification(); 
              this.isLoading = false;       

           }
        }).catch((error) => {
          //console.log(error);
          //console.log('FAILURE!!');
          _this.showErrorNotification();
          _this.isLoading = false;
        });

    }
  }
}*/
</script>

<style scoped>



/**/
 
</style>

<style>
 
 /* BOX BANNER */

.prd_edit {
  padding: 20px;
  margin-bottom: 100px;
}

.prd_edit .nr {
  display: flex;
}

.prd_edit .nr .av {
  width: 360px;
  margin-right: 2rem;
}

.prd_edit .nr .av img {
  width: 100%;
}

.prd_edit .nr .fup {
  position: relative;
}

.prd_edit .two_fld {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
}

.prd_edit .two_fld .ind_sat {
  margin-bottom: 1rem!important;
}

.prd_edit .two_fld>.field {
  width: 100%;
}


.right_link {
  float: right;
  color: black;
}

.left_link {
  color: #4183C4;
}

.right_link:hover{
  color: black;
}

.changeset {
  color: #FF0000;
  display: inline-flex;
  align-items: center;
}

.words{
  float: right;
  font-weight: bold;
  margin-top: 6px;
}

.av_box {
  width: 360px;
}

.image_item_box{
  border: 1px #cecece solid;
  min-width: 360px;
  min-height: 248px;
}

.center {
  text-align: center;
}

.media-img {
  justify-content: center;
  text-align: center;
  display: flex;
  width: 100%;
}

.box {
  width: 213px;
  box-shadow: none;
  margin: 0px;
  padding: 0px;
}

.banner_title {
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
  width: 100%;
  font-weight: bold;
  justify-content: center;
  flex-direction: column;
}

.action_btn {
  display: block;
  padding: 5px 19px;
  background-color: #f5be0a;
  border-radius: 23px;
  margin-top: 6px;
  font-weight: bold;
  font-size: 0.85rem;
  margin-bottom: 8px;
}

/* UPLOADER */


.load_box{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}
.load_box.background{
  min-height: 200px;
}
.load_box::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}
.load_box input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

.load_box_photos{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}

.load_box_photos::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}
.load_box_photos input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

/* UPLOADER PHOTOS*/

.upload_photos .item{
  float: left;
  width: 100px;
  height: 100px;
  margin-left: 10px;
}

.upload_photos .item_table{
  display: table;
  width: 100px;
  height: 100px;
}

.upload_photos .item_td{
  display: table-cell;
  width: 100px;
  height: 100px;
  vertical-align: middle;
  text-align: center;
}

.upload_photos .item img{
  display: inline-block;
  max-height: 100px;
  max-width: 100px;
}

.upload_photos .delete{
  background-color: rgb(252, 83, 83);
  display: inline-block;
  position: absolute;
  padding: 5px;
  border-radius: 15px;
  margin-top: -5px;
  margin-left: -5px;
  color: white;
  border: 1px white solid;
  cursor: pointer;
}

.two_fld{
  
}

.prd_edit .two_fld>.group50 {
  width: 50%;
}

.prd_edit .two_fld>.group50:first-child {
  padding-right: 20px;
}

.prd_edit .group50>.subgroup50 {
  width: 50%;
  float: left;
}

.prd_edit .group50>.subgroup50:first-child {
  padding-right: 10px;
}

.prd_edit .two_fld .select,.prd_edit .two_fld .select select{
  width: 100%!important;
}

.prd_edit .two_fld .button {
  width: 100%!important;
}



</style>
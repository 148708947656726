<template>

  <div v-if="props.list" class="lb">

    <div class="actions-video">
      <div class="act" @click="nextVideo('prev')">
        <i class="icon-arrow-top"></i>
      </div>
      <div class="act" @click="nextVideo('next')">
        <i class="icon-arrow-bottom"></i>
      </div> 
    </div>

    <div class="scrl" ref="scrl">

      <GeneralShortsPlayer
        v-for="(video, video_index) in props.list"
        :video="video"
        :key="video.id"
        :is-muted="isMuted"
        :is-active="currentIndex == video_index"
        :is-next="currentIndex+1 == video_index"
        :close-type="props.closeType"
        @observe="observeVideo(video, video_index)"
        @unobserve="unobserveVideo(video, video_index)"
        @prev="nextVideo('prev')"
        @next="nextVideo('next')"
        @close="closeLightbox"
        @mute="mutePlayerVideo"
        @unmute="unmutePlayerVideo"
        />  
 

    </div>



    <div class="bglb" 
      @click="closeLightbox"
      ></div>

  </div>

</template>
  
<script setup>
  
  // import { useIntersectionObserver } from '@vueuse/core'
  // import { useClipboard } from '@vueuse/core'
  import { watchArray } from '@vueuse/core'
  
  const props = defineProps({
    list: {
      type: Array,
      default: [],
      required: false
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: false
    },
    isEnded: {
      type: Boolean,
      default: false,
      required: false
    },
    closeType: {
      type: String,
      default: '',
      required: false
    }
  });
  
  
  
  // const { text: copyText, copy, copied: copyCopied, isSupported: copyIsSupported } = useClipboard()
  
  const emits = defineEmits(['loadMore', 'close']);
  const { t } = useI18n();
  const { $noscroll, $observer, $popup, $likes, $follow, $complains, $head } = useNuxtApp();
  const router = useRouter();

  const elInfinityScroll = ref(null)
  const playerPlay = ref(false);
  const playerCurrentProgress = ref(0);
  const playerCurrentProgressInterval = ref({});
  const videoContainerRefs = ref(null);
  const isMuted = useCookie('shorts_muted');

  const currentVideo = ref(props.list[0]);
  const currentIndex = ref(0);
  const scrl = ref(null);
  const referenceUrl = ref(null)
 
  const mutePlayerVideo = () => {
    isMuted.value = true;
  }

  const unmutePlayerVideo = () => {
    isMuted.value = false;
  }

  const observeVideo = (video, index) => {
    console.log('observeVideo');
    console.log(video);

    currentVideo.value = video;
    currentIndex.value = index;

    checkLoadMore();
  }

  const unobserveVideo = (video) => {
    console.log('unobserveVideo');
    console.log(video);
  }

  const nextVideo = (pos, index) => {

    console.log('nextVideo')
    console.log('nextVideo')
    if(pos == 'prev'){
      currentIndex.value = props.list.findIndex(v => v.id == currentVideo.value.id) - 1;
      currentVideo.value = props.list[props.list.findIndex(v => v.id == currentVideo.value.id) - 1];
    }else{
      currentIndex.value = props.list.findIndex(v => v.id == currentVideo.value.id) + 1;
      currentVideo.value = props.list[props.list.findIndex(v => v.id == currentVideo.value.id) + 1];
    }


    // console.log(scrl.value.querySelectorAll('.ls'));
    console.log(scrl.value.querySelectorAll('.lc')[currentIndex.value]);

    scrollToVideo(scrl.value.querySelectorAll('.lc')[currentIndex.value]);
  }
   
  
  const scrollToVideo = (el) => {
      
  
    let offsetTop = el.offsetTop;
  
    
    let parent = el.offsetParent;
    while (parent && parent !== scrl.value) {
      offsetTop += parent.offsetTop;
      parent = parent.offsetParent;
    }
  
    offsetTop -= 10;
  
    
    scrl.value.scrollTo({
      top: offsetTop,
      behavior: 'smooth'
    });
   }
   
   
  const setReferenceUrl = () => {
    referenceUrl.value = '/';
    console.log('referenceUrl.value');
    console.log(referenceUrl.value);
  }
 
  
  const closeLightbox = () => {
    // stopPlayerVideo(currentVideo.value)
    emits('close');
  };
   
  
  const topKeyListener = function(e) {
    if(e.key == 'ArrowUp'){
      nextVideo('prev');
    }
  }
  
  const bottomKeyListener = function(e) {
    if(e.key == 'ArrowDown'){
      nextVideo('next');
    }
  }
  
  const escapeKeyListener = function(e) {
    if(e.key == 'Escape'){
      closeLightbox()
    }
  }
   
    
   

  
  onMounted(() => {
    console.log('onMounted');
    
    // setReferenceUrl();
    
    $noscroll.on();     
    
    if(import.meta.client){
      document.addEventListener('keydown', escapeKeyListener);
      document.addEventListener('keydown', topKeyListener);
      document.addEventListener('keydown', bottomKeyListener);
    }
  })
   
  onUnmounted(() => {
    console.log('onUnmounted');

    $noscroll.off();

    if(import.meta.client){
      document.removeEventListener('keydown', escapeKeyListener);
      document.removeEventListener('keydown', topKeyListener);
      document.removeEventListener('keydown', bottomKeyListener);
    }
  });
  


  const checkLoadMore = function(){
  
    if(currentIndex.value >= props.list.length - 3){  
      if(!props.isLoading && !props.isEnded){
        emits('loadMore');
      }
    }
  }
  

  

   
  // watchArray([props], async ([new_list], added, removed) => {
  //   console.log('watcher');
    
  //   props.list = new_list.list;
  
  // }, {deep: true})
  
  
</script>
   
  
<style scoped>
 
  .lb {
    --shorts-height: calc(100dvh - 90px);
    --shorts-height-item: calc(100dvh - 90px);
    position: relative;
    width: 100%;
  }

  @container pc (max-width: 500px) {
    .lb {
      --shorts-height: calc(100dvh - 0px);
      --shorts-height-item: calc(100dvh - 0px);
    }

  }

  .lb {
    /* position: fixed; */
    top: 0;
    left: 0;
    height: var(--shorts-height);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 111;
  }
  .lb .scrl {
    overflow-y: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    gap: 1rem;
    scroll-snap-type: y mandatory;
    z-index: 1;
    width: 100%;
  }
  .lb .scrl::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
  .bglb {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
  }
     
      
   
  
  /* actions video */
  
  .actions-video{
    position: absolute;
    right: 10px;
    bottom: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    z-index: 100;
  }
  .actions-video .act{
    border-radius: 50%;
    background-color: var(--gd-shorts-act-back-color);
    aspect-ratio: 1/1;
    width: 60px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .actions-video .act:hover{
    background-color: var(--gd-shorts-act-back-hover-color);
  }
  .actions-video .act i{
  
  }
  
  .is-sidebar-showed .actions-video{
    display: none;
  }
  
  @container pc (max-width: 900px) {
    .actions-video{
      display: none;
    }
  }
    
  
  </style>
  
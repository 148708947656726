<template>
  

  <div class="mx">
  
    Page in progress

  </div>
  
</template>

<script setup lang="ts">

useHead({
  title: 'About',
  meta: [
    {
      name: 'description',
      content: 'About page'
    }
  ]
})
</script>



<style scoped>


.mx{
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 2rem;
  text-align: center;
}
 

</style>
